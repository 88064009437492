import React, { Component } from "react";
import { Image } from "react-bootstrap";

class NoResultFound extends Component {
  render() {
    return (
      <div className="no-data-found-sec">
        <div className="no-data-found-img-sec text-center">
          <Image
            src={window.location.origin + "/assets/img/no-result-found.svg"}
            className="no-data-found-img"
          />
          <h5 className="text-no-data mt-5">No Result Found</h5>
        </div>
      </div>
    );
  }
}

export default NoResultFound;
