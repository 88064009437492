import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Container, Row, Col } from "react-bootstrap";
import "./BroadCasting.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BroadcastHelper from "./BroadcastHelper";
import VideoConnecting from "./VideoConnecting";
import api from "../../Environment";
import io from "socket.io-client";
import config from "react-global-configuration";
import { Redirect } from "react-router-dom";
import BroadcastChat from "./BroadcastChat";
import { connect } from "react-redux";
import {
    endLSOwnerStart,
    fetchSingleLSOwnerStart,
} from "../../store/actions/LiveStreamingAction";
import VideoTag from "./VideoTag";
import WebRTCAdaptor from './webrtc_adaptor';

let chatSocket;

class BroadCastPublish extends Component {
    webRTCAdaptor = null;

    state = {
        mediaConstraints: {
            video: true,
            audio: true
        },
        streamName: 'stream1',
        token: '',
        pc_config: {
            'iceServers': [{
                'urls': 'stun:stun.l.google.com:19302'
            }]
        },
        sdpConstraints: {
            OfferToReceiveAudio: false,
            OfferToReceiveVideo: false
        },
        websocketURL: "wss://ant-media.startstreaming.co:5443/WebRTCAppEE/websocket",
        isShow: false,
        subscribers: [],
        chatSocket: false,
        customizeData: {
            audioMute: true,
            audioUnmute: false,
            videoMute: true,
            videoUnmute: false,
            recordStatus: false,
        },
        snapshot: null,
        startBroadcastInputData: {},
        modelLoadingContent: null,
        modelButtonDisabled: false,
        liveVideoData: {
            live_video_id: 242452,
        },
        loadingLiveVideo: true,
        liveVideoSuggesstion: null,
        loadingLiveVideoSuggesstion: true,
        chatData: null,
        loadingChatData: true,
        chatInputMessage: "",
        loadMoreButtonDisable: false,
        loadingContent: null,
        skipCount: 0,
        viewerCount: 0,
        streamingStatus: false,
        viewerProfileRedirect: false,
        streamingType: null,
        mainStreamer: false,
        loadingStreamingPage: true,
        apiCallstatus: false,
        startBroadcastButtonDisable: false,
        startBroadcastButtonLoadingContent: null,
    };

    async componentDidMount() {

        await this.props.dispatch(
            fetchSingleLSOwnerStart({
                live_video_id: this.props.match.params.live_video_id,
            })
        );

        let videox = document.querySelector("#localVideo");

        if (navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: true })
                .then(function (stream) {
                    console.log("stream", stream)
                    videox.srcObject = stream;
                })
                .catch(function (err0r) {
                    console.log("Something went wrong!");
                });
        };
        this.webRTCAdaptor = this.initiateWebrtc();
        this.setState({
            isShow: true
        });
        this.onStartPublishing();
    }

    onStartPublishing = () => {
        setTimeout(() => {
            this.webRTCAdaptor.publish(this.props.match.params.live_video_id, this.state.token);
        }, 2000);

    }

    initiateWebrtc = () => {
        let thiz = this;
        return new WebRTCAdaptor({
            websocket_url: this.state.websocketURL,
            mediaConstraints: this.state.mediaConstraints,
            peerconnection_config: this.state.pc_config,
            sdp_constraints: this.state.sdpConstraints,
            localVideoId: "localVideo",
            debug: true,
            bandwidth: 900,
            callback: function (info, obj) {
                if (info == "initialized") {
                    console.log("initialized");

                } else if (info == "publish_started") {
                    //stream is being published
                    console.log("publish started");
                    thiz.setState({
                        isShow: false
                    });

                } else if (info == "publish_finished") {
                    //stream is being finished
                    console.log("publish finished");
                    thiz.setState({
                        isShow: true
                    });

                    window.location.replace("/");

                } else if (info == "closed") {
                    //console.log("Connection closed");
                    if (typeof obj != "undefined") {
                        console.log("Connecton closed: "
                            + JSON.stringify(obj));
                    }
                } else if (info == "streamInformation") {


                } else if (info == "ice_connection_state_changed") {
                    console.log("iceConnectionState Changed: ", JSON.stringify(obj));
                } else if (info == "updated_stats") {
                    //obj is the PeerStats which has fields
                    //averageIncomingBitrate - kbits/sec
                    //currentIncomingBitrate - kbits/sec
                    //packetsLost - total number of packet lost
                    //fractionLost - fraction of packet lost
                    console.log("Average incoming kbits/sec: " + obj.averageIncomingBitrate
                        + " Current incoming kbits/sec: " + obj.currentIncomingBitrate
                        + " packetLost: " + obj.packetsLost
                        + " fractionLost: " + obj.fractionLost
                        + " audio level: " + obj.audioLevel);

                } else if (info == "data_received") {
                    console.log("Data received: " + obj.event.data + " type: " + obj.event.type + " for stream: " + obj.streamId);
                } else if (info == "bitrateMeasurement") {
                    console.log(info + " notification received");

                    console.log(obj);
                } else {
                    console.log(info + " notification received");
                }
            },
            callbackError: function (error) {
                //some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError

                console.log("error callback: " + JSON.stringify(error));
                alert(JSON.stringify(error));
            }
        });
    }

    takePhoto = (video) => {
        let canvas = document.createElement("canvas");
        canvas.width = video.videoWidth || video.clientWidth;
        canvas.height = video.videoHeight || video.clientHeight;

        let context = canvas.getContext("2d");
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        this.setState({ snapshot: canvas.toDataURL("image/png") });
        setTimeout(() => {
            this.snapShotAPI();
        }, 50000);
    };

    snapShotAPI = () => {
        const inputData = {
            snapshot: this.state.snapshot,
            live_video_id: this.props.singleLSOwner.data.live_video_id,
        };
        api.postMethod("live_videos_snapshot_save", inputData).then((response) => {
            if (response.data.success) {
                this.setState({
                    snapshot: null,
                });
            } else {
            }
        });
    };

    stopBroadcastAPI = () => {
        this.props.dispatch(
            endLSOwnerStart({
                live_video_id: this.props.match.params.live_video_id,
            })
        );
    };

    viewerUpdateAPI = () => {
        const inputData = {
            live_video_id: this.props.singleLSOwner.data.live_video_id,
        };
        api.postMethod("live_videos_viewer_update", inputData).then((response) => {
            if (response.data.success) {
                //Notificaiton
            } else {
                //Notificaiton
                if (
                    response.data.error_code == 169 ||
                    response.data.error_code == 170 ||
                    response.data.error_code == 171
                ) {
                    this.props.history.push("/");
                }
            }
        });
    };

    getLiveVideoChatDetails = () => {
        const inputData = {
            live_video_id: this.props.singleLSOwner.data.live_video_id,
            skip: this.state.skipCount,
        };
        this.getLiveVideoChatAPI(inputData);
    };

    loadMore = (event) => {
        event.preventDefault();
        this.setState({
            loadMoreButtonDisable: true,
            loadingContent: "Loading...",
        });
        const inputData = {
            live_video_id: this.props.singleLSOwner.data.live_video_id,
            skip: this.state.skipCount,
        };

        this.getLiveVideoChatAPI(inputData);
    };

    getLiveVideoChatAPI = (inputData) => {
        let items;
        api.postMethod("live_videos_messages", inputData).then((response) => {

            if (response.data.success) {
                if (this.state.chatData != null) {
                    items = [...this.state.chatData, ...response.data.data.messages];
                } else {
                    items = [...response.data.data.messages];
                }
                items = items.reverse();
                this.setState({
                    chatData: items,
                    loadingChatData: false,
                    skipCount: response.data.data.length + this.state.skipCount,
                    loadMoreButtonDisable: false,
                    loadingContent: null,
                });
            } else {
            }
        });
    };

    chatSocketConnect = () => {

        // check the socket url is configured
        let chatSocketUrl = config.get("configData.chat_socket_url");
        console.log("chatSocketUrl" + chatSocketUrl);
        if (chatSocketUrl && Object.keys(this.props.singleLSOwner.data).length > 0) {
            chatSocket = io(chatSocketUrl, {
                query: `room: '` + this.props.singleLSOwner.data.unique_id + `'`,
            });

            chatSocket.emit("update sender", {
                room: this.props.singleLSOwner.data.unique_id,
            });
            let chatContent;
            chatSocket.on("message", (newData) => {
                let content = [];
                content.push(newData);
                chatContent = [...this.state.chatData, ...content];
                this.setState({ chatData: chatContent });
            });
        }
    };

    handleChatSubmit = (event) => {
        console.log(this.props.singleLSOwner.data);
        event.preventDefault();
        const type =
            this.props.singleLSOwner.data.user_id == localStorage.getItem("userId")
                ? "uv"
                : "vu";
        let chatData = [
            {
                live_video_id: this.props.singleLSOwner.data.live_video_id,
                user_id: this.props.singleLSOwner.data.user_id,
                live_video_viewer_id: localStorage.getItem("userId"),
                message: this.state.chatInputMessage,
                type: type,
                user_name: localStorage.getItem("username"),
                user_picture: localStorage.getItem("user_picture"),
                username: localStorage.getItem("username"),
                userpicture: localStorage.getItem("user_picture"),
            },
        ];
        chatSocket.emit("message", chatData[0]);
        let messages;
        if (this.state.chatData != null) {
            messages = [...this.state.chatData, ...chatData];
        } else {
            messages = [...chatData];
        }
        this.setState({
            chatData: messages,
            chatInputMessage: "",
        });
    };

    stopLiveStreaming = () => {
        this.webRTCAdaptor.stop(this.state.liveVideoData.virtual_id)
        this.stopBroadcastAPI();
    }

    // render() {
    //     return (
    //         <video id="localVideo" autoPlay muted controls playsInline></video>
    //     );
    // }

    render() {
        const {
            loadingContent,
            loadMoreButtonDisable,
            chatData,
            loadingChatData,
        } = this.state;
        return (
            <>
                <div className="broadcasting-sec">
                    <Container>

                        <Row>
                            <Col sm={12} md={12}>
                                <h2 className="section-title">Broadcast Video</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12}>
                                <div className="broadcasting-card">
                                    <Row>

                                        <Col sm={12} md={7}>
                                            <div className="video-casting-sec">
                                                <div className="video-image-sec">
                                                    <video id="localVideo" autoPlay muted controls playsInline></video>
                                                    <button
                                                        className="btn"
                                                        type="button"
                                                        onClick={this.stopLiveStreaming}
                                                    > Stop Streaming </button>
                                                    <button
                                                        className="btn"
                                                        type="button"
                                                        onClick={() => this.webRTCAdaptor.turnOffLocalCamera()}
                                                    > Off camera </button>
                                                    <button
                                                        className="btn"
                                                        type="button"
                                                        onClick={() => this.webRTCAdaptor.turnOnLocalCamera()}
                                                    > on camera </button>
                                                    <button
                                                        className="btn"
                                                        type="button"
                                                        onClick={() => this.webRTCAdaptor.muteLocalMic()}
                                                    > Mute</button>
                                                    <button
                                                        className="btn"
                                                        type="button"
                                                        onClick={() => this.webRTCAdaptor.unmuteLocalMic()}
                                                    > Unmute</button>
                                                </div>
                                                <div className="border-bottom-2"></div>
                                                <div className="video-flex-data">
                                                    <div className="video-info">
                                                        <Button className="free-btn">Free</Button>
                                                        <Button className="view-btn">
                                                            <FontAwesomeIcon
                                                                icon={["fas", "eye"]}
                                                                className="menu-icons clear-icons"
                                                            />
                                                            &nbsp;<span id="viewers_cnt">0</span> Views
                                                        </Button>
                                                        <div className="bg-facebook">
                                                            <Link to="#" target="_blank" className="">
                                                                <FontAwesomeIcon
                                                                    icon={["fab", "facebook"]}
                                                                    className="menu-icons clear-icons"
                                                                />
                                                            </Link>
                                                        </div>
                                                        <div className="bg-twitter">
                                                            <Link to="#">
                                                                <FontAwesomeIcon
                                                                    icon={["fab", "twitter"]}
                                                                    className="menu-icons clear-icons"
                                                                />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="video-action-btn">
                                                        <Button
                                                            type="button"
                                                            className="stop-btn"
                                                            onClick={this.stopStreaming}
                                                        >
                                                            Stop
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.props.singleLSOwner.loading ? (
                                                "Loading...."
                                            ) : (
                                                <div className="video-content">
                                                    <h4 className="title">
                                                        {this.props.singleLSOwner.data.title}
                                                    </h4>
                                                    <div className="small">
                                                        Streaming By User From{" "}
                                                        {this.props.singleLSOwner.data.start_time}
                                                    </div>
                                                    <p className="desc">
                                                        {this.props.singleLSOwner.data.description}
                                                    </p>
                                                </div>

                                            )}
                                        </Col>
                                        <BroadcastChat
                                            loadMore={this.loadMore}
                                            chatData={chatData}
                                            loadingChatData={loadingChatData}
                                            loadingContent={loadingContent}
                                            loadMoreButtonDisable={loadMoreButtonDisable}
                                            chatInputMessage={this.state.chatInputMessage}
                                            chatInputChange={this.chatInputChange}
                                            handleChatSubmit={this.handleChatSubmit}
                                        />
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}

const mapStateToPros = (state) => ({
    singleLSOwner: state.liveStreaming.singleLSOwner,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}


export default connect(mapStateToPros, mapDispatchToProps)(BroadCastPublish);
