import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  fetchPPVHistoryFailure,
  fetchPPVHistorySuccess,
  PPVPayPayPalFailure,
  PPVPayPayPalSuccess,
  PPVPayStripeFailure,
  PPVPayStripeSuccess,
} from "../actions/PPVAction";
import {
  PPV_PAY_PAYPAL_START,
  FETCH_PPV_HISTORY_START,
  PPV_PAY_STRIPE_START,
} from "../actions/ActionConstant";

function* fetchPPVHistoryAPI() {
  try {
    const response = yield api.postMethod("ppv_payments");
    if (response.data.success) {
      yield put(fetchPPVHistorySuccess(response.data.data));
    } else {
      yield put(fetchPPVHistoryFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchPPVHistoryFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* ppvPayStripeAPI() {
  try {
    const inputData = yield select((state) => state.ppv.ppvPayStripe.inputData);
    const response = yield api.postMethod("ppv_payment_by_stripe", inputData);
    if (response.data.success) {
      yield put(PPVPayStripeSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign(`/single-video/${inputData.video_tape_id}`);
    } else {
      yield put(PPVPayStripeFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(PPVPayStripeFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* ppvPayPayPal() {
  try {
    const inputData = yield select((state) => state.ppv.ppvPayPayPal.inputData);
    const response = yield api.postMethod("ppv_payment_by_paypal", inputData);
    if (response.data.success) {
      yield put(PPVPayPayPalSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign(`/single-video/${inputData.video_tape_id}`);
    } else {
      yield put(PPVPayPayPalFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(PPVPayPayPalFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_PPV_HISTORY_START, fetchPPVHistoryAPI)]);
  yield all([yield takeLatest(PPV_PAY_STRIPE_START, ppvPayStripeAPI)]);
  yield all([yield takeLatest(PPV_PAY_PAYPAL_START, ppvPayPayPal)]);
}
