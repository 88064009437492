import {
  FETCH_PPV_HISTORY_START,
  FETCH_PPV_HISTORY_SUCCESS,
  FETCH_PPV_HISTORY_FAILURE,
  PPV_PAY_STRIPE_START,
  PPV_PAY_STRIPE_SUCCESS,
  PPV_PAY_STRIPE_FAILURE,
  PPV_PAY_PAYPAL_START,
  PPV_PAY_PAYPAL_SUCCESS,
  PPV_PAY_PAYPAL_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  ppvHistory: {
    data: {},
    loading: true,
    error: false,
  },
  ppvPayStripe: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  ppvPayPayPal: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
};

const PPVReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PPV_HISTORY_START:
      return {
        ...state,
        ppvHistory: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_PPV_HISTORY_SUCCESS:
      return {
        ...state,
        ppvHistory: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_PPV_HISTORY_FAILURE:
      return {
        ...state,
        ppvHistory: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case PPV_PAY_STRIPE_START:
      return {
        ...state,
        ppvPayStripe: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case PPV_PAY_STRIPE_SUCCESS:
      return {
        ...state,
        ppvPayStripe: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case PPV_PAY_STRIPE_FAILURE:
      return {
        ...state,
        ppvPayStripe: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case PPV_PAY_PAYPAL_START:
      return {
        ...state,
        ppvPayPayPal: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case PPV_PAY_PAYPAL_SUCCESS:
      return {
        ...state,
        ppvPayPayPal: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case PPV_PAY_PAYPAL_FAILURE:
      return {
        ...state,
        ppvPayPayPal: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    default:
      return state;
  }
};

export default PPVReducer;
