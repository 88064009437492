import {
  FETCH_VIEWER_PLAYLIST_START,
  FETCH_VIEWER_PLAYLIST_SUCCESS,
  FETCH_VIEWER_PLAYLIST_FAILURE,
  CREATE_VIEWER_PLAYLIST_START,
  CREATE_VIEWER_PLAYLIST_SUCCESS,
  CREATE_VIEWER_PLAYLIST_FAILURE,
  ADD_VIDEO_VIEWER_PLAYLIST_START,
  ADD_VIDEO_VIEWER_PLAYLIST_SUCCESS,
  ADD_VIDEO_VIEWER_PLAYLIST_FAILURE,
  REMOVE_VIDEO_VIEWER_PLAYLIST_START,
  REMOVE_VIDEO_VIEWER_PLAYLIST_SUCCESS,
  REMOVE_VIDEO_VIEWER_PLAYLIST_FAILURE,
  FETCH_SINGLE_VIEWER_PLAYLIST_START,
  FETCH_SINGLE_VIEWER_PLAYLIST_SUCCESS,
  FETCH_SINGLE_VIEWER_PLAYLIST_FAILURE,
  DELETE_VIEWER_PLAYLIST_START,
  DELETE_VIEWER_PLAYLIST_SUCCESS,
  DELETE_VIEWER_PLAYLIST_FAILURE,
} from "./ActionConstant";

// fetch viewer playlist action.

export function fetchViewerPlaylistStart(data) {
  return {
    type: FETCH_VIEWER_PLAYLIST_START,
    data,
  };
}

export function fetchViewerPlaylistSuccess(data) {
  return {
    type: FETCH_VIEWER_PLAYLIST_SUCCESS,
    data,
  };
}

export function fetchViewerPlaylistFailure(error) {
  return {
    type: FETCH_VIEWER_PLAYLIST_FAILURE,
    error,
  };
}

// create viewer playlist action.

export function createViewerPlaylistStart(data) {
  return {
    type: CREATE_VIEWER_PLAYLIST_START,
    data,
  };
}

export function createViewerPlaylistSuccess(data) {
  return {
    type: CREATE_VIEWER_PLAYLIST_SUCCESS,
    data,
  };
}

export function createViewerPlaylistFailure(error) {
  return {
    type: CREATE_VIEWER_PLAYLIST_FAILURE,
    error,
  };
}

// add video ViewerPlaylist action.

export function addVideoViewerPlaylistStart(data) {
  return {
    type: ADD_VIDEO_VIEWER_PLAYLIST_START,
    data,
  };
}

export function addVideoViewerPlaylistSuccess(data) {
  return {
    type: ADD_VIDEO_VIEWER_PLAYLIST_SUCCESS,
    data,
  };
}

export function addVideoViewerPlaylistFailure(error) {
  return {
    type: ADD_VIDEO_VIEWER_PLAYLIST_FAILURE,
    error,
  };
}

// remove video ViewerPlaylist action.

export function removeVideoViewerPlaylistStart(data) {
  return {
    type: REMOVE_VIDEO_VIEWER_PLAYLIST_START,
    data,
  };
}

export function removeVideoViewerPlaylistSuccess(data) {
  return {
    type: REMOVE_VIDEO_VIEWER_PLAYLIST_SUCCESS,
    data,
  };
}

export function removeVideoViewerPlaylistFailure(error) {
  return {
    type: REMOVE_VIDEO_VIEWER_PLAYLIST_FAILURE,
    error,
  };
}

// fetch single ViewerPlaylist action.

export function fetchSingleViewerPlaylistStart(data) {
  return {
    type: FETCH_SINGLE_VIEWER_PLAYLIST_START,
    data,
  };
}

export function fetchSingleViewerPlaylistSuccess(data) {
  return {
    type: FETCH_SINGLE_VIEWER_PLAYLIST_SUCCESS,
    data,
  };
}

export function fetchSingleViewerPlaylistFailure(error) {
  return {
    type: FETCH_SINGLE_VIEWER_PLAYLIST_FAILURE,
    error,
  };
}

// delete viewer playlist action.

export function deleteViewerPlaylistStart(data) {
  return {
    type: DELETE_VIEWER_PLAYLIST_START,
    data,
  };
}

export function deleteViewerPlaylistSuccess(data) {
  return {
    type: DELETE_VIEWER_PLAYLIST_SUCCESS,
    data,
  };
}

export function deleteViewerPlaylistFailure(error) {
  return {
    type: DELETE_VIEWER_PLAYLIST_FAILURE,
    error,
  };
}
