import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Image,
  Modal,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createViewerPlaylistStart } from "../../store/actions/ViewerPlayListAction";
import { disableSubscriptionAutoRenewalStart } from "../../store/actions/SubscriptionAction";

const CancelModel = (props) => {
  const [reason, setReason] = useState("");

  const cancelSubscription = (event) => {
    event.preventDefault();
    props.dispatch(
      disableSubscriptionAutoRenewalStart({
        cancel_reason: reason,
      })
    );
    setTimeout(() => {
      props.closeCancelModal();
    }, 1000);
  };

  return (
    <Modal
      className="edit-playlist-modal"
      size="md"
      show={props.cancelModal}
      onHide={props.closeCancelModal}
    >
      <Form onSubmit={cancelSubscription}>
        <Modal.Header closeButton>
          <Modal.Title>Subscription Cancel Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="exampleForm.ControlInput1" className="mb-0">
            <Form.Control
              as="textarea"
              rows="3"
              placeholder="Reason"
              value={reason}
              name="cancel_reason"
              onChange={(event) => setReason(event.currentTarget.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Link to="#">
            <Button
              className="save-btn"
              type="submit"
              onClick={cancelSubscription}
            >
              Submit
            </Button>
          </Link>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(CancelModel);
