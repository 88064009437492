import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image, Badge } from "react-bootstrap";
import "./PPVHistory.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import PPVLoader from "../Loader/PPVLoader";

import { fetchPPVHistoryStart } from "../../store/actions/PPVAction";


const PPVHistory = (props) => {
  useEffect(() => {
    if (props.videos.loading) props.dispatch(fetchPPVHistoryStart());
  }, []);
  const { videos } = props;
  return (
    <>
      <div className="ppv-history-sec">
        <Container>
          <Row>
            <Col sm={12} md={12}>
              <h2 className="section-title">Purchased Videos</h2>
            </Col>
          </Row>
          <Row>
            {videos.loading
              ? <PPVLoader />
              : videos.data.length > 0
              ? videos.data.map((data) => (
                  <Col sm={12} md={12} xl={4}>
                    <div className="ppv-history-box">
                      <div className="ppv-history-img-sec">
                        <Image
                          src={data.video_image}
                          className="ppv-history-img"
                        />
                        <div className="flex-space-1">
                          <div className="img-caption">
                            <h3>{data.title}</h3>
                          </div>
                          <div className="amount-display">
                            <h4>{data.amount_formatted}</h4>
                          </div>
                        </div>
                      </div>
                      <div className="ppv-history-details">
                        <div className="ppv-history-content flex-space">
                          <h6>Status</h6>
                          <Badge pill variant="success">
                            {data.status_formatted}
                          </Badge>{" "}
                        </div>
                        <div className="ppv-history-content">
                          <h6>Is Coupon Code Applied</h6>
                          <p>{data.is_coupon_applied ? "Yes" : "No"}</p>
                        </div>
                        <div className="ppv-history-content">
                          <h6>Video Amount</h6>
                          <p>{data.ppv_amount_formatted}</p>
                        </div>
                        <div className="ppv-history-content">
                          <h6>Transaction Id</h6>
                          <p>{data.payment_id}</p>
                        </div>
                        <div className="ppv-history-content">
                          <h6>Payment Mode</h6>
                          <p>{data.payment_mode}</p>
                        </div>
                        <div className="ppv-history-content mb-0">
                          <h6>Paid At</h6>
                          <p>{new Date(data.updated_at).toDateString()}</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))
              : <div className="no-data-found-sec">
              <Row>
                <Col sm={12} md={12}>
                  <div className="no-data-found-img-sec">
                    <Image
                      src={
                        window.location.origin +
                        "/assets/img/no-result-found.svg"
                      }
                      className="no-data-found-img"
                    />
                    <p className="text-no-data">
                      No Video Purchased yet
                    </p>
                  </div>
                </Col>
              </Row>
            </div>}
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  videos: state.ppv.ppvHistory,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(PPVHistory);
