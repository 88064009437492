import {
  FETCH_CHANNEL_PLAYLIST_START,
  FETCH_CHANNEL_PLAYLIST_SUCCESS,
  FETCH_CHANNEL_PLAYLIST_FAILURE,
  CREATE_CHANNEL_PLAYLIST_START,
  CREATE_CHANNEL_PLAYLIST_SUCCESS,
  CREATE_CHANNEL_PLAYLIST_FAILURE,
  ADD_VIDEO_CHANNEL_PLAYLIST_START,
  ADD_VIDEO_CHANNEL_PLAYLIST_SUCCESS,
  ADD_VIDEO_CHANNEL_PLAYLIST_FAILURE,
  REMOVE_VIDEO_CHANNEL_PLAYLIST_START,
  REMOVE_VIDEO_CHANNEL_PLAYLIST_SUCCESS,
  REMOVE_VIDEO_CHANNEL_PLAYLIST_FAILURE,
  FETCH_SINGLE_CHANNEL_PLAYLIST_START,
  FETCH_SINGLE_CHANNEL_PLAYLIST_SUCCESS,
  FETCH_SINGLE_CHANNEL_PLAYLIST_FAILURE,
  DELETE_CHANNEL_PLAYLIST_START,
  DELETE_CHANNEL_PLAYLIST_SUCCESS,
  DELETE_CHANNEL_PLAYLIST_FAILURE,
} from "./ActionConstant";

// fetch channel playlist action.

export function fetchChannelPlaylistStart(data) {
  return {
    type: FETCH_CHANNEL_PLAYLIST_START,
    data,
  };
}

export function fetchChannelPlaylistSuccess(data) {
  return {
    type: FETCH_CHANNEL_PLAYLIST_SUCCESS,
    data,
  };
}

export function fetchChannelPlaylistFailure(error) {
  return {
    type: FETCH_CHANNEL_PLAYLIST_FAILURE,
    error,
  };
}

// create channel playlist action.

export function createChannelPlaylistStart(data) {
  return {
    type: CREATE_CHANNEL_PLAYLIST_START,
    data,
  };
}

export function createChannelPlaylistSuccess(data) {
  return {
    type: CREATE_CHANNEL_PLAYLIST_SUCCESS,
    data,
  };
}

export function createChannelPlaylistFailure(error) {
  return {
    type: CREATE_CHANNEL_PLAYLIST_FAILURE,
    error,
  };
}

// add video ChannelPlaylist action.

export function addVideoChannelPlaylistStart(data) {
  return {
    type: ADD_VIDEO_CHANNEL_PLAYLIST_START,
    data,
  };
}

export function addVideoChannelPlaylistSuccess(data) {
  return {
    type: ADD_VIDEO_CHANNEL_PLAYLIST_SUCCESS,
    data,
  };
}

export function addVideoChannelPlaylistFailure(error) {
  return {
    type: ADD_VIDEO_CHANNEL_PLAYLIST_FAILURE,
    error,
  };
}

// remove video ChannelPlaylist action.

export function removeVideoChannelPlaylistStart(data) {
  return {
    type: REMOVE_VIDEO_CHANNEL_PLAYLIST_START,
    data,
  };
}

export function removeVideoChannelPlaylistSuccess(data) {
  return {
    type: REMOVE_VIDEO_CHANNEL_PLAYLIST_SUCCESS,
    data,
  };
}

export function removeVideoChannelPlaylistFailure(error) {
  return {
    type: REMOVE_VIDEO_CHANNEL_PLAYLIST_FAILURE,
    error,
  };
}

// fetch single ChannelPlaylist action.

export function fetchSingleChannelPlaylistStart(data) {
  return {
    type: FETCH_SINGLE_CHANNEL_PLAYLIST_START,
    data,
  };
}

export function fetchSingleChannelPlaylistSuccess(data) {
  return {
    type: FETCH_SINGLE_CHANNEL_PLAYLIST_SUCCESS,
    data,
  };
}

export function fetchSingleChannelPlaylistFailure(error) {
  return {
    type: FETCH_SINGLE_CHANNEL_PLAYLIST_FAILURE,
    error,
  };
}

// delete channel playlist action.

export function deleteChannelPlaylistStart(data) {
  return {
    type: DELETE_CHANNEL_PLAYLIST_START,
    data,
  };
}

export function deleteChannelPlaylistSuccess(data) {
  return {
    type: DELETE_CHANNEL_PLAYLIST_SUCCESS,
    data,
  };
}

export function deleteChannelPlaylistFailure(error) {
  return {
    type: DELETE_CHANNEL_PLAYLIST_FAILURE,
    error,
  };
}
