import {
  FETCH_REDEEMS_START,
  FETCH_REDEEMS_SUCCESS,
  FETCH_REDEEMS_FAILURE,
  SEND_REDEEM_START,
  SEND_REDEEM_SUCCESS,
  SEND_REDEEM_FAILURE,
  CANCEL_REDEEM_START,
  CANCEL_REDEEM_SUCCESS,
  CANCEL_REDEEM_FAILURE,
} from "./ActionConstant";

// fetch redeem action.

export function fetchRedeemsStart(data) {
  return {
    type: FETCH_REDEEMS_START,
    data,
  };
}

export function fetchRedeemsSuccess(data) {
  return {
    type: FETCH_REDEEMS_SUCCESS,
    data,
  };
}

export function fetchRedeemsFailure(error) {
  return {
    type: FETCH_REDEEMS_FAILURE,
    error,
  };
}

export function sendRedeemStart(data) {
  return {
    type: SEND_REDEEM_START,
    data,
  };
}

export function sendRedeemSuccess(data) {
  return {
    type: SEND_REDEEM_SUCCESS,
    data,
  };
}

export function sendRedeemFailure(error) {
  return {
    type: SEND_REDEEM_FAILURE,
    error,
  };
}

export function cancelRedeemStart(data) {
  return {
    type: CANCEL_REDEEM_START,
    data,
  };
}

export function cancelRedeemSuccess(data) {
  return {
    type: CANCEL_REDEEM_SUCCESS,
    data,
  };
}

export function cancelRedeemFailure(error) {
  return {
    type: CANCEL_REDEEM_FAILURE,
    error,
  };
}
