import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Tabs,
  Tab,
  Dropdown,
  DropdownButton,
  Image,
} from "react-bootstrap";
import "./PlayListView.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class PlayListView extends Component {
  render() {
    return (
      <>
        <div className="play-list-view-sec">
          <Container>
            <Row>
              <Col sm={12} md={12}>
                <h2 className="section-title">Playlist View</h2>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12}>
                 <div className="new-history-right-sec">
                        <div className="new-history-right-card">
                          <div className="new-history-right-card-body">
                            <Row>
                              <Col xs={5} sm={5} md={4} xl={3}>
                                <div className="new-history-right-card-img">
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/img/video-2.jpg"
                                    }
                                    className="video-img"
                                  />
                                </div>
                                <div className="time-count">
                                  <h6>15:19</h6>
                                </div>
                              </Col>
                              <Col
                                xs={5}
                                sm={5}
                                md={5}
                                xl={6}
                                className="resp-pd-left-zero"
                              >
                                <div className="new-history-right-card-details">
                                  <h4 className="title">Gitaar</h4>
                                  <p className="desc">
                                    <span className="view-count">29 views</span>
                                    <span className="view-count">
                                      . 276 512
                                    </span>
                                  </p>
                                  <p className="desc">
                                    <span className="percentage-count green-clr">
                                      <FontAwesomeIcon
                                        icon="circle"
                                        className="mr-1"
                                      />
                                      93%
                                    </span>
                                  </p>
                                </div>
                              </Col>
                              <Col xs={2} sm={2} md={3} xl={3}>
                                <Link to="#">
                                  <div className="clear-icon-sec">
                                    <FontAwesomeIcon
                                      icon={["fas", "times"]}
                                      className="menu-icons clear-icons mr-2"
                                    />
                                  </div>
                                </Link>
                              </Col>
                            </Row>
                          </div>
                          <div className="new-history-right-card-body">
                            <Row>
                              <Col xs={5} sm={5} md={4} xl={3}>
                                <div className="new-history-right-card-img">
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/img/video-3.jpg"
                                    }
                                    className="video-img"
                                  />
                                </div>
                                <div className="time-count">
                                  <h6>15:19</h6>
                                </div>
                              </Col>
                              <Col
                                xs={5}
                                sm={5}
                                md={5}
                                xl={6}
                                className="resp-pd-left-zero"
                              >
                                <div className="new-history-right-card-details">
                                  <h4 className="title">Gitaar</h4>
                                  <p className="desc">
                                    <span className="view-count">29 views</span>
                                    <span className="view-count">
                                      . 276 512
                                    </span>
                                  </p>
                                  <p className="desc">
                                    <span className="percentage-count green-clr">
                                      <FontAwesomeIcon
                                        icon="circle"
                                        className="mr-1"
                                      />
                                      93%
                                    </span>
                                  </p>
                                </div>
                              </Col>
                              <Col xs={2} sm={2} md={3} xl={3}>
                                <Link to="#">
                                  <div className="clear-icon-sec">
                                    <FontAwesomeIcon
                                      icon={["fas", "times"]}
                                      className="menu-icons clear-icons mr-2"
                                    />
                                  </div>
                                </Link>
                              </Col>
                            </Row>
                          </div>
                          <div className="new-history-right-card-body">
                            <Row>
                              <Col xs={5} sm={5} md={4} xl={3}>
                                <div className="new-history-right-card-img">
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/img/video-4.jpg"
                                    }
                                    className="video-img"
                                  />
                                </div>
                                <div className="time-count">
                                  <h6>15:19</h6>
                                </div>
                              </Col>
                              <Col
                                xs={5}
                                sm={5}
                                md={5}
                                xl={6}
                                className="resp-pd-left-zero"
                              >
                                <div className="new-history-right-card-details">
                                  <h4 className="title">Gitaar</h4>
                                  <p className="desc">
                                    <span className="view-count">29 views</span>
                                    <span className="view-count">
                                      . 276 512
                                    </span>
                                  </p>
                                  <p className="desc">
                                    <span className="percentage-count green-clr">
                                      <FontAwesomeIcon
                                        icon="circle"
                                        className="mr-1"
                                      />
                                      93%
                                    </span>
                                  </p>
                                </div>
                              </Col>
                              <Col xs={2} sm={2} md={3} xl={3}>
                                <Link to="#">
                                  <div className="clear-icon-sec">
                                    <FontAwesomeIcon
                                      icon={["fas", "times"]}
                                      className="menu-icons clear-icons mr-2"
                                    />
                                  </div>
                                </Link>
                              </Col>
                            </Row>
                          </div>
                          <div className="new-history-right-card-body">
                            <Row>
                              <Col xs={5} sm={5} md={4} xl={3}>
                                <div className="new-history-right-card-img">
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/img/video-5.jpg"
                                    }
                                    className="video-img"
                                  />
                                </div>
                                <div className="time-count">
                                  <h6>15:19</h6>
                                </div>
                              </Col>
                              <Col
                                xs={5}
                                sm={5}
                                md={5}
                                xl={6}
                                className="resp-pd-left-zero"
                              >
                                <div className="new-history-right-card-details">
                                  <h4 className="title">Gitaar</h4>
                                  <p className="desc">
                                    <span className="view-count">29 views</span>
                                    <span className="view-count">
                                      . 276 512
                                    </span>
                                  </p>
                                  <p className="desc">
                                    <span className="percentage-count green-clr">
                                      <FontAwesomeIcon
                                        icon="circle"
                                        className="mr-1"
                                      />
                                      93%
                                    </span>
                                  </p>
                                </div>
                              </Col>
                              <Col xs={2} sm={2} md={3} xl={3}>
                                <Link to="#">
                                  <div className="clear-icon-sec">
                                    <FontAwesomeIcon
                                      icon={["fas", "times"]}
                                      className="menu-icons clear-icons mr-2"
                                    />
                                  </div>
                                </Link>
                              </Col>
                            </Row>
                          </div>
                          <div className="new-history-right-card-body">
                            <Row>
                              <Col xs={5} sm={5} md={4} xl={3}>
                                <div className="new-history-right-card-img">
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/img/video-6.jpg"
                                    }
                                    className="video-img"
                                  />
                                </div>
                                <div className="time-count">
                                  <h6>15:19</h6>
                                </div>
                              </Col>
                              <Col
                                xs={5}
                                sm={5}
                                md={5}
                                xl={6}
                                className="resp-pd-left-zero"
                              >
                                <div className="new-history-right-card-details">
                                  <h4 className="title">Gitaar</h4>
                                  <p className="desc">
                                    <span className="view-count">29 views</span>
                                    <span className="view-count">
                                      . 276 512
                                    </span>
                                  </p>
                                  <p className="desc">
                                    <span className="percentage-count green-clr">
                                      <FontAwesomeIcon
                                        icon="circle"
                                        className="mr-1"
                                      />
                                      93%
                                    </span>
                                  </p>
                                </div>
                              </Col>
                              <Col xs={2} sm={2} md={3} xl={3}>
                                <Link to="#">
                                  <div className="clear-icon-sec">
                                    <FontAwesomeIcon
                                      icon={["fas", "times"]}
                                      className="menu-icons clear-icons mr-2"
                                    />
                                  </div>
                                </Link>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                      <div className="align-right">
                        <Link to="#">
                          <Button className="loadmore-btn">Load More</Button>
                        </Link>
                      </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default PlayListView;
