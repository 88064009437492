import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Tabs,
  Tab,
  Dropdown,
  DropdownButton,
  Image,
  Media,
} from "react-bootstrap";
import "./BrowseCategories.css";
import { connect } from "react-redux";
import { fetchCategoriesStart } from "../../store/actions/CategoryAction";
import BrowseCategoriesLoader from "../Loader/BrowseCategoriesLoader";

const BrowseCategories = (props) => {
  useEffect(() => {
    if (props.category.loading) props.dispatch(fetchCategoriesStart());
  }, []);
  return (
    <>
      <div className="browse-categories-sec">
        <Container>
          <Row>
            <Col sm={12} md={12}>
              <div className="browse-channels-header">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="browse-all-categories"
                >
                  <Row>
                    <Col sm={12} md={12}>
                      <Nav variant="pills">
                        <Nav.Item>
                          <Nav.Link eventKey="browse-all-categories">
                            All Categories
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col sm={12} md={12}>
                      {props.category.loading ? (
                        <BrowseCategoriesLoader/>
                      ) : props.category.data.length > 0 ? (
                        <Tab.Content>
                          <Tab.Pane eventKey="browse-all-categories">
                            <div className="categories-video-sec">
                              <Row>
                                {props.category.data.map((data) => (
                                  <Col xs={6} sm={6} md={4} xl={2}>
                                    <Link to="/single-video-simplified">
                                      <div className="categories-video-card">
                                        <div className="cartegories-video-img-sec">
                                          <Image
                                            src={
                                              window.location.origin +
                                              "/assets/img/video-1.jpg"
                                            }
                                            className="categories-vertical-img"
                                          />
                                        </div>
                                        <div className="categories-video-details">
                                          <h4 className="title">
                                            {data.category_name}
                                          </h4>
                                          <p className="desc">
                                            {data.total_videos} Videos
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </Col>
                                ))}
                              </Row>
                              <div className="space-lg"></div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      ) : (
                        <div className="no-data-found-sec">
                          <Row>
                            <Col sm={12} md={12}>
                              <div className="no-data-found-img-sec">
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/img/no-result-found.svg"
                                  }
                                  className="no-data-found-img"
                                />
                                <p className="text-no-data">No Result Found</p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </Col>
          </Row>
          <div className="d-flex d-sm-none resp-arrow-bottom-sec">
            <ul className="list-unstyled">
              <Media as="li">
                <Image
                  src={window.location.origin + "/assets/img/mobile-arrow.png"}
                  className="resp-arrow-bottom"
                />
              </Media>
            </ul>
          </div>
          <div className="border-bottom-1 d-flex d-sm-none"></div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  category: state.category.categories,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(BrowseCategories);
