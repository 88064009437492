import React, { Component } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Tab,
  Image,
  Media,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ChannelOwnerPlaylistModal = (props) => {
  return (
    <Modal
      className="add-play-list-modal"
      size="lg"
      centered
      show={props.addplaylistModal}
      onHide={props.closeAddPlayListModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add to</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="add-footer-content">
          <label onClick={props.handleToggleAddPlayListForm}>
            {props.addplaylistForm ? "" : ""}
            <FontAwesomeIcon
              icon={["fas", "plus"]}
              className="modal-icon mr-2"
            />
            Create Playlist
          </label>
        </div>
        {props.addplaylistForm && (
          <div className="add-play-list-form">
            <Form>
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  type="name"
                  placeholder="Enter playlist name..."
                />
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Videos</Form.Label>
                <Form.Control type="name" placeholder="Select videos" />
              </Form.Group>
              <Button className="btn create-btn">Create</Button>
            </Form>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ChannelOwnerPlaylistModal;
