import {
  FETCH_SUBSCRIPTION_START,
  FETCH_SUBSCRIPTION_SUCCESS,
  FETCH_SUBSCRIPTION_FAILURE,
  FETCH_MY_SUBSCRIPTION_START,
  FETCH_MY_SUBSCRIPTION_SUCCESS,
  FETCH_MY_SUBSCRIPTION_FAILURE,
  FETCH_SINGLE_SUBSCRIPTION_START,
  FETCH_SINGLE_SUBSCRIPTION_SUCCESS,
  FETCH_SINGLE_SUBSCRIPTION_FAILURE,
  SUBSCRIPTION_PAYMENT_START,
  SUBSCRIPTION_PAYMENT_SUCCESS,
  SUBSCRIPTION_PAYMENT_FAILURE,
  SUBSCRIPTION_PAYMENT_PAYPAL_START,
  SUBSCRIPTION_PAYMENT_PAYPAL_SUCCESS,
  SUBSCRIPTION_PAYMENT_PAYPAL_FAILURE,
  CHECK_COUPON_CODE_START,
  CHECK_COUPON_CODE_SUCCESS,
  CHECK_COUPON_CODE_FAILURE,
  ENABLE_SUBSCRIPTION_AUTORENEWAL_START,
  ENABLE_SUBSCRIPTION_AUTORENEWAL_SUCCESS,
  ENABLE_SUBSCRIPTION_AUTORENEWAL_FAILURE,
  DISABLE_SUBSCRIPTION_AUTORENEWAL_START,
  DISABLE_SUBSCRIPTION_AUTORENEWAL_SUCCESS,
  DISABLE_SUBSCRIPTION_AUTORENEWAL_FAILURE,
} from "./ActionConstant";

// Get subscription actions.

export function fetchSubscriptionStart(data) {
  return {
    type: FETCH_SUBSCRIPTION_START,
    data,
  };
}

export function fetchSubscriptionSuccess(data) {
  return {
    type: FETCH_SUBSCRIPTION_SUCCESS,
    data,
  };
}

export function fetchSubscriptionFailure(error) {
  return {
    type: FETCH_SUBSCRIPTION_FAILURE,
    error,
  };
}

// Get My subscription actions.

export function fetchMySubscriptionStart(data) {
  return {
    type: FETCH_MY_SUBSCRIPTION_START,
    data,
  };
}

export function fetchMySubscriptionSuccess(data) {
  return {
    type: FETCH_MY_SUBSCRIPTION_SUCCESS,
    data,
  };
}

export function fetchMySubscriptionFailure(error) {
  return {
    type: FETCH_MY_SUBSCRIPTION_FAILURE,
    error,
  };
}

// Get single subscription actions.

export function fetchSingleSubscriptionStart(data) {
  return {
    type: FETCH_SINGLE_SUBSCRIPTION_START,
    data,
  };
}

export function fetchSingleSubscriptionSuccess(data) {
  return {
    type: FETCH_SINGLE_SUBSCRIPTION_SUCCESS,
    data,
  };
}

export function fetchSingleSubscriptionFailure(error) {
  return {
    type: FETCH_SINGLE_SUBSCRIPTION_FAILURE,
    error,
  };
}

// Subscription Payment actions.

export function subscriptionPaymentStart(data) {
  return {
    type: SUBSCRIPTION_PAYMENT_START,
    data,
  };
}

export function subscriptionPaymentSuccess(data) {
  return {
    type: SUBSCRIPTION_PAYMENT_SUCCESS,
    data,
  };
}

export function subscriptionPaymentFailure(error) {
  return {
    type: SUBSCRIPTION_PAYMENT_FAILURE,
    error,
  };
}

// Subscription Payment by PayPal.

export function subscriptionPaymentPayPalStart(data) {
  return {
    type: SUBSCRIPTION_PAYMENT_PAYPAL_START,
    data,
  };
}

export function subscriptionPaymentPayPalSuccess(data) {
  return {
    type: SUBSCRIPTION_PAYMENT_PAYPAL_SUCCESS,
    data,
  };
}

export function subscriptionPaymentPayPalFailure(error) {
  return {
    type: SUBSCRIPTION_PAYMENT_PAYPAL_FAILURE,
    error,
  };
}

// Check coupon code

export function checkCouponCodeStart(data) {
  return {
    type: CHECK_COUPON_CODE_START,
    data,
  };
}

export function checkCouponCodeSuccess(data) {
  return {
    type: CHECK_COUPON_CODE_SUCCESS,
    data,
  };
}

export function checkCouponCodeFailure(error) {
  return {
    type: CHECK_COUPON_CODE_FAILURE,
    error,
  };
}

// Get enable autorenewal actions.

export function enableSubscriptionAutoRenewalStart(data) {
  return {
    type: ENABLE_SUBSCRIPTION_AUTORENEWAL_START,
    data,
  };
}

export function enableSubscriptionAutoRenewalSuccess(data) {
  return {
    type: ENABLE_SUBSCRIPTION_AUTORENEWAL_SUCCESS,
    data,
  };
}

export function enableSubscriptionAutoRenewalFailure(error) {
  return {
    type: ENABLE_SUBSCRIPTION_AUTORENEWAL_FAILURE,
    error,
  };
}

// disable auto renewal actions.

export function disableSubscriptionAutoRenewalStart(data) {
  return {
    type: DISABLE_SUBSCRIPTION_AUTORENEWAL_START,
    data,
  };
}

export function disableSubscriptionAutoRenewalSuccess(data) {
  return {
    type: DISABLE_SUBSCRIPTION_AUTORENEWAL_SUCCESS,
    data,
  };
}

export function disableSubscriptionAutoRenewalFailure(error) {
  return {
    type: DISABLE_SUBSCRIPTION_AUTORENEWAL_FAILURE,
    error,
  };
}
