import React from "react"
import ContentLoader from "react-content-loader"

const MyPlansLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1200}
    height={700}
    viewBox="0 0 1200 700"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="15" rx="5" ry="5" width="1200" height="56" /> 
    <rect x="0" y="108" rx="10" ry="10" width="150" height="39" /> 
    <rect x="1075" y="108" rx="10" ry="10" width="120" height="39" /> 
    <rect x="970" y="180" rx="5" ry="5" width="87" height="30" /> 
    <rect x="1085" y="180" rx="5" ry="5" width="109" height="30" /> 
    <rect x="0" y="230" rx="5" ry="5" width="1200" height="89" /> 
    <rect x="0" y="344" rx="5" ry="5" width="1200" height="89" /> 
    <rect x="0" y="454" rx="5" ry="5" width="1200" height="89" /> 
    <rect x="0" y="576" rx="10" ry="10" width="1200" height="65" />
  </ContentLoader>
)

export default MyPlansLoader;
