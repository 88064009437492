import React from "react"
import ContentLoader from "react-content-loader"

const SingleChannelsLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1350}
    height={1100}
    viewBox="0 0 1200 1100"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="8" rx="5" ry="5" width="1200" height="56" /> 
    <rect x="0" y="71" rx="0" ry="0" width="1200" height="105" /> 
    <circle cx="90" cy="178" r="59" /> 
    <rect x="162" y="198" rx="0" ry="0" width="64" height="8" /> 
    <rect x="230" y="198" rx="0" ry="0" width="30" height="8" /> 
    <rect x="350" y="198" rx="0" ry="0" width="100" height="8" /> 
    <rect x="480" y="198" rx="0" ry="0" width="100" height="8" /> 
    <rect x="610" y="198" rx="0" ry="0" width="100" height="8" /> 
    <rect x="740" y="198" rx="0" ry="0" width="100" height="8" /> 
    <rect x="1090" y="192" rx="2" ry="2" width="50" height="21" /> 
    <rect x="1150" y="192" rx="2" ry="2" width="50" height="21" /> 
    <rect x="870" y="198" rx="0" ry="0" width="100" height="8" /> 
    <rect x="0" y="253" rx="0" ry="0" width="58" height="9" /> 
    <rect x="980" y="253" rx="0" ry="0" width="80" height="9" /> 
    <rect x="1080" y="248" rx="0" ry="0" width="25" height="16" /> 
    <rect x="1150" y="248" rx="0" ry="0" width="50" height="16" /> 
    <rect x="0" y="286" rx="5" ry="5" width="180" height="111" /> 
    <rect x="0" y="412" rx="0" ry="0" width="106" height="11" /> 
    <rect x="0" y="431" rx="0" ry="0" width="80" height="11" /> 
    <rect x="350" y="286" rx="5" ry="5" width="180" height="111" /> 
    <rect x="350" y="412" rx="0" ry="0" width="106" height="11" /> 
    <rect x="350" y="431" rx="0" ry="0" width="80" height="11" /> 
    <rect x="680" y="286" rx="5" ry="5" width="180" height="111" /> 
    <rect x="680" y="412" rx="0" ry="0" width="106" height="11" /> 
    <rect x="680" y="431" rx="0" ry="0" width="80" height="11" /> 
    <rect x="1020" y="286" rx="5" ry="5" width="180" height="111" /> 
    <rect x="1020" y="412" rx="0" ry="0" width="106" height="11" /> 
    <rect x="1020" y="431" rx="0" ry="0" width="80" height="11" /> 
    <rect x="0" y="460" rx="5" ry="5" width="180" height="111" /> 
    <rect x="0" y="586" rx="0" ry="0" width="106" height="11" /> 
    <rect x="0" y="605" rx="0" ry="0" width="80" height="11" /> 
    <rect x="350" y="460" rx="5" ry="5" width="180" height="111" /> 
    <rect x="350" y="586" rx="0" ry="0" width="106" height="11" /> 
    <rect x="350" y="605" rx="0" ry="0" width="80" height="11" /> 
    <rect x="680" y="460" rx="5" ry="5" width="180" height="111" /> 
    <rect x="680" y="586" rx="0" ry="0" width="106" height="11" /> 
    <rect x="680" y="605" rx="0" ry="0" width="80" height="11" /> 
    <rect x="1020" y="460" rx="5" ry="5" width="180" height="111" /> 
    <rect x="1020" y="586" rx="0" ry="0" width="106" height="11" /> 
    <rect x="1020" y="605" rx="0" ry="0" width="80" height="11" /> 
    <rect x="0" y="637" rx="5" ry="5" width="180" height="111" /> 
    <rect x="0" y="763" rx="0" ry="0" width="106" height="11" /> 
    <rect x="0" y="782" rx="0" ry="0" width="80" height="11" /> 
    <rect x="350" y="637" rx="5" ry="5" width="180" height="111" /> 
    <rect x="350" y="763" rx="0" ry="0" width="106" height="11" /> 
    <rect x="350" y="782" rx="0" ry="0" width="80" height="11" /> 
    <rect x="680" y="637" rx="5" ry="5" width="180" height="111" /> 
    <rect x="680" y="763" rx="0" ry="0" width="106" height="11" /> 
    <rect x="680" y="782" rx="0" ry="0" width="80" height="11" /> 
    <rect x="1020" y="637" rx="5" ry="5" width="180" height="111" /> 
    <rect x="1020" y="763" rx="0" ry="0" width="106" height="11" /> 
    <rect x="1020" y="782" rx="0" ry="0" width="80" height="11" /> 
    <rect x="0" y="811" rx="5" ry="5" width="180" height="111" /> 
    <rect x="0" y="937" rx="0" ry="0" width="106" height="11" /> 
    <rect x="0" y="956" rx="0" ry="0" width="80" height="11" /> 
    <rect x="350" y="811" rx="5" ry="5" width="180" height="111" /> 
    <rect x="350" y="937" rx="0" ry="0" width="106" height="11" /> 
    <rect x="350" y="956" rx="0" ry="0" width="80" height="11" /> 
    <rect x="680" y="811" rx="5" ry="5" width="180" height="111" /> 
    <rect x="680" y="937" rx="0" ry="0" width="106" height="11" /> 
    <rect x="680" y="956" rx="0" ry="0" width="80" height="11" /> 
    <rect x="1020" y="811" rx="5" ry="5" width="180" height="111" /> 
    <rect x="1020" y="937" rx="0" ry="0" width="106" height="11" /> 
    <rect x="1020" y="956" rx="0" ry="0" width="80" height="11" /> 
    <circle cx="300" cy="1038" r="30" /> 
    <circle cx="420" cy="1038" r="30" /> 
    <circle cx="560" cy="1038" r="30" /> 
    <circle cx="710" cy="1038" r="30" /> 
    <circle cx="850" cy="1038" r="30" /> 
  </ContentLoader>
)

export default SingleChannelsLoader;