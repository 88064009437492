import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Tabs,
  Tab,
  Dropdown,
  DropdownButton,
  Image,
  Media,
} from "react-bootstrap";
import "./SubscriptionList.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchSubscriptionStart } from "../../store/actions/SubscriptionAction";
import { connect } from "react-redux";
import SubscriptionListLoader from "../Loader/SubscriptionListLoader";

class SubscriptionList extends Component {
  componentDidMount() {
    if (this.props.subscriptions.subscription.loading)
      this.props.dispatch(fetchSubscriptionStart());
  }

  handleSubmit = (event, subscription) => {
    event.preventDefault();
    this.props.history.push(
      `/subscriptions-invoice/${subscription.subscription_id}`,
      subscription
    );
  };

  render() {
    const { subscription } = this.props.subscriptions;
    return (
      <>
        <div className="subscription-list-box">
          <Container>
            <Row>
              <Col sm={12} md={12}>
                <h2 className="section-title mb-0">
                  Choose Your Plan & Start Earning Money!
                </h2>
              </Col>
            </Row>
            <Row>
              {subscription.loading
                ? <SubscriptionListLoader/>
                : subscription.data.length > 0
                ? subscription.data.map((sub) => (
                    <Col
                      sm={12}
                      md={6}
                      xl={4}
                      className="resp-mrg-btm-lg-device resp-mrg-btm-xs sm-margin-top"
                    >
                      <div className="pricing-table">
                        <div className="head">
                          <h4 className="title">{sub.title}</h4>
                        </div>
                        <div className="content">
                          <div className="price">
                            <h1>
                              <span className="amount">
                                {sub.amount_formatted}
                              </span>
                            </h1>
                            <p>
                              <span class="period text-capitalize">
                                {sub.plan_formatted}
                              </span>
                            </p>
                          </div>
                          <ul className="list-unstyled">
                            <Media as="li">{sub.description}</Media>
                          </ul>
                          <div className="choose-plan-sec">
                            <Link
                              to={{
                                pathname: `/plan-invoice/${sub.subscription_id}`,
                                state: sub,
                              }}
                              className="choose-btn bordered radius"
                            >
                              Choose Plan
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))
                : <div className="no-data-found-sec">
                    <Row>
                      <Col sm={12} md={12}>
                        <div className="no-data-found-img-sec">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/img/no-result-found.svg"
                            }
                            className="no-data-found-img"
                          />
                          <p className="text-no-data">No Result Found</p>
                        </div>
                      </Col>
                    </Row>
                  </div>}
              {/* <Col
                sm={12}
                md={6}
                xl={4}
                className="resp-mrg-btm-lg-device resp-mrg-btm-xs"
              >
                <div className="pricing-table standard-pricing-table">
                  <div className="head">
                    <h4 className="title">Standard</h4>
                  </div>
                  <div className="content">
                    <div className="price">
                      <h1>
                        <span className="pr-1 sign">$</span>
                        <span className="amount">999</span>
                      </h1>
                      <p>
                        <span class="period">12 month</span>
                      </p>
                    </div>
                    <ul className="list-unstyled">
                      <Media as="li">
                        On no twenty spring of in esteem spirit likely estate.
                        Continue new you declared differed learning bringing
                        honoured.
                      </Media>
                    </ul>
                    <div className="choose-plan-sec">
                      <Link to="#" className="choose-btn bordered radius">
                        Choose Plan
                      </Link>
                    </div>
                  </div>
                </div>
              </Col> */}
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToPros = (state) => ({
  subscriptions: state.subscriptions,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SubscriptionList);
