import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  fetchSingleTagFailure,
  fetchSingleTagSuccess,
  fetchTagsFailure,
  fetchTagsSuccess,
  fetchTagVideoFailure,
  fetchTagVideoSuccess,
} from "../actions/TagsAction";
import {
  FETCH_SINGLE_TAG_START,
  FETCH_TAGS_START,
  FETCH_TAG_VIDEOS_START,
} from "../actions/ActionConstant";

function* fetchTagsAPI() {
  try {
    const response = yield api.postMethod("video_tapes_tags");
    if (response.data.success) {
      yield put(fetchTagsSuccess(response.data.data));
    } else {
      yield put(fetchTagsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchTagsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchTagVideoAPI() {
  try {
    const inputData = yield select((state) => state.tag.tagVideo.inputData);
    const response = yield api.postMethod("video_tapes_history_add", inputData);
    if (response.data.success) {
      yield put(fetchTagVideoSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(fetchTagVideoFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchTagVideoFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchSingleTagAPI() {
  try {
    const inputData = yield select((state) => state.tag.singleTag.inputData);
    const response = yield api.postMethod(
      "video_tapes_history_remove",
      inputData
    );
    if (response.data.success) {
      yield put(fetchSingleTagSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(fetchSingleTagFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSingleTagFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_TAGS_START, fetchTagsAPI)]);
  yield all([yield takeLatest(FETCH_TAG_VIDEOS_START, fetchTagVideoAPI)]);
  yield all([yield takeLatest(FETCH_SINGLE_TAG_START, fetchSingleTagAPI)]);
}
