import {
  FETCH_CHANNEL_PLAYLIST_START,
  FETCH_CHANNEL_PLAYLIST_SUCCESS,
  FETCH_CHANNEL_PLAYLIST_FAILURE,
  CREATE_CHANNEL_PLAYLIST_START,
  CREATE_CHANNEL_PLAYLIST_SUCCESS,
  CREATE_CHANNEL_PLAYLIST_FAILURE,
  ADD_VIDEO_CHANNEL_PLAYLIST_START,
  ADD_VIDEO_CHANNEL_PLAYLIST_SUCCESS,
  ADD_VIDEO_CHANNEL_PLAYLIST_FAILURE,
  REMOVE_VIDEO_CHANNEL_PLAYLIST_START,
  REMOVE_VIDEO_CHANNEL_PLAYLIST_SUCCESS,
  REMOVE_VIDEO_CHANNEL_PLAYLIST_FAILURE,
  FETCH_SINGLE_CHANNEL_PLAYLIST_START,
  FETCH_SINGLE_CHANNEL_PLAYLIST_SUCCESS,
  FETCH_SINGLE_CHANNEL_PLAYLIST_FAILURE,
  DELETE_CHANNEL_PLAYLIST_START,
  DELETE_CHANNEL_PLAYLIST_SUCCESS,
  DELETE_CHANNEL_PLAYLIST_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  channelPlaylist: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  createCP: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  addVideoCP: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  removeVideoCP: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  singleCP: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  deleteCP: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
};

const ChannelPlayListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CHANNEL_PLAYLIST_START:
      return {
        ...state,
        channelPlaylist: {
          inputData: action.data,
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_CHANNEL_PLAYLIST_SUCCESS:
      return {
        ...state,
        channelPlaylist: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_CHANNEL_PLAYLIST_FAILURE:
      return {
        ...state,
        channelPlaylist: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
      };
    case CREATE_CHANNEL_PLAYLIST_START:
      return {
        ...state,
        createCP: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case CREATE_CHANNEL_PLAYLIST_SUCCESS:
      return {
        ...state,
        createCP: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case CREATE_CHANNEL_PLAYLIST_FAILURE:
      return {
        ...state,
        createCP: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case ADD_VIDEO_CHANNEL_PLAYLIST_START:
      return {
        ...state,
        addVideoCP: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case ADD_VIDEO_CHANNEL_PLAYLIST_SUCCESS:
      return {
        ...state,
        addVideoCP: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case ADD_VIDEO_CHANNEL_PLAYLIST_FAILURE:
      return {
        ...state,
        addVideoCP: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case REMOVE_VIDEO_CHANNEL_PLAYLIST_START:
      return {
        ...state,
        removeVideoCP: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case REMOVE_VIDEO_CHANNEL_PLAYLIST_SUCCESS:
      return {
        ...state,
        removeVideoCP: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case REMOVE_VIDEO_CHANNEL_PLAYLIST_FAILURE:
      return {
        ...state,
        removeVideoCP: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case FETCH_SINGLE_CHANNEL_PLAYLIST_START:
      return {
        ...state,
        singleCP: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_SINGLE_CHANNEL_PLAYLIST_SUCCESS:
      return {
        ...state,
        singleCP: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_SINGLE_CHANNEL_PLAYLIST_FAILURE:
      return {
        ...state,
        singleCP: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
      };

    case DELETE_CHANNEL_PLAYLIST_START:
      return {
        ...state,
        deleteCP: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case DELETE_CHANNEL_PLAYLIST_SUCCESS:
      return {
        ...state,
        deleteCP: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case DELETE_CHANNEL_PLAYLIST_FAILURE:
      return {
        ...state,
        deleteCP: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    default:
      return state;
  }
};

export default ChannelPlayListReducer;
