import React from "react"
import ContentLoader from "react-content-loader"

const PPVLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1350}
    height={800}
    viewBox="0 0 1200 800"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="30" rx="5" ry="5" width="1200" height="58" /> 
    <rect x="0" y="114" rx="5" ry="5" width="300" height="280" /> 
    <rect x="450" y="113" rx="5" ry="5" width="300" height="280" /> 
    <rect x="900" y="114" rx="5" ry="5" width="300" height="280" /> 
    <rect x="0" y="415" rx="5" ry="5" width="300" height="280" /> 
    <rect x="450" y="414" rx="5" ry="5" width="300" height="280" /> 
    <rect x="900" y="415" rx="5" ry="5" width="300" height="280" /> 
    <rect x="0" y="728" rx="5" ry="5" width="1200" height="58" />
  </ContentLoader>
)

export default PPVLoader;