import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Tabs,
  Tab,
  Dropdown,
  DropdownButton,
  Image,
} from "react-bootstrap";
import "./ChannelList.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { fetchOwnerChannelListStart } from "../../store/actions/ChannelAction";
import ImageLoader from "../helper/ImageLoader";
import NoResultFound from "../NoResultFound/NoResultFound";

const ChannelList = (props) => {
  useEffect(() => {
    props.dispatch(fetchOwnerChannelListStart());
  }, []);

  return (
    <>
      <div className="channel-list-sec">
        <Container>
          <Row>
            <Col sm={12} md={12}>
              <h2 className="section-title">My Channels</h2>
            </Col>
          </Row>
          <div className="">
            <div className="channel-list-card">
              {props.channelOwner.loading
                ? "Loading..."
                : props.channelOwner.channels.length > 0
                ? props.channelOwner.channels.map((channel) => (
                    <Row>
                      <Col xs={5} sm={5} md={4} xl={3}>
                        <div className="channel-list-card-img">
                          <Link to={`/my-channel/${channel.channel_id}`}>
                            <ImageLoader
                              image={channel.channel_image}
                              className="channel-list-img"
                            />
                          </Link>
                        </div>
                        <div className="video-count">
                          <h6>{channel.no_of_videos} Videos</h6>
                        </div>
                      </Col>
                      <Col
                        xs={5}
                        sm={5}
                        md={5}
                        xl={5}
                        className="resp-pd-left-zero"
                      >
                        <div className="channel-list-card-details">
                          <h4 className="title">{channel.channel_name}</h4>
                          <p class="desc">
                            <span class="view-count">
                              {channel.channel_description}
                            </span>
                          </p>
                        </div>
                      </Col>
                      <Col xs={2} sm={2} md={3} xl={4} className="align-right">
                        <Link to="#">
                          <Button className="unsubscribe-btn">
                            {channel.no_of_subscribers}{" "}
                            {/* <FontAwesomeIcon
                            icon={["fas", "times"]}
                            className="unsubscribed-icon mr-2"
                          /> */}
                            Subscribers
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  ))
                : <NoResultFound/>}
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  channelOwner: state.channel.channelOwner,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ChannelList);
