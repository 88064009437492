import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  VimePlayer,
  VimeVideo,
  VimeDefaultUi,
  VimeDefaultControls,
  VimeSettings,
  VimeMenuItem,
  VimeSubmenu,
  VimeMenuRadio,
  VimeMenuRadioGroup,
  VimeYoutube,
  VimeEmbed,
} from "@vime/react";

const VideoPlayerSec = (props) => {
  const [value, setValue] = useState("1");
  const onCheck = (event) => {
    console.log("Chedk.....");
  };

  const onMenuItem1Click = (event) => {
    console.log("chagsdfsdf");
  };

  const onMenuItem2Click = (event) => {
    console.log("chagsdfsdf");
  };

  return (
    <div className="video-ratio-sec-card">
      <div className="main-video-sec">
        <VimePlayer controls>
          {props.video.video_type === 3 ? (
            <VimeYoutube videoId={props.video.video.split("=")[1]} />
          ) : (
            <>
              <VimeVideo poster={props.video.video_image}>
                {/* These are passed directly to the underlying HTML5 `<video>` element. */}
                {/* Why `data-src`? Lazy loading, you can always use `src` if you prefer.  */}
                <source data-src={props.video.video} type="video/mp4" />
                {/* <track
              default
              kind="subtitles"
              src="https://media.vimejs.com/subs/english.vtt"
              srcLang="en"
              label="English"
            /> */}
              </VimeVideo>
              <VimeDefaultUi noSettings>
                <VimeSettings>
                  <VimeMenuItem
                    label="Menu Item 1"
                    badge="BADGE"
                    onClick={onMenuItem1Click}
                  />

                  <VimeMenuItem
                    label="Menu Item 2"
                    hint="Hint"
                    onClick={onMenuItem2Click}
                  />

                  <VimeSubmenu label="Submenu 1" hint={value}>
                    <VimeMenuRadioGroup value={value} onVCheck={onCheck}>
                      <VimeMenuRadio label="Option 1" value="1" />
                      <VimeMenuRadio label="Option 2" value="2" />
                      <VimeMenuRadio label="Option 3" value="3" />
                    </VimeMenuRadioGroup>
                  </VimeSubmenu>

                  <VimeSubmenu label="Submenu 2">
                    Random content in here.
                  </VimeSubmenu>
                </VimeSettings>
              </VimeDefaultUi>
            </>
          )}
          {/* <VimeDefaultUi noControls>

              <VimeDefaultControls hideOnMouseLeave activeDuration={2000} />
            </VimeDefaultUi> */}
        </VimePlayer>
      </div>
    </div>
  );
};

export default VideoPlayerSec;

// import React, { useState } from "react";
// import {
//   VimePlayer,
//   VimeDefaultUi,
//   VimeSettings,
//   VimeMenuItem,
//   VimeSubmenu,
//   VimeMenuRadio,
//   VimeMenuRadioGroup,
//   VimeVideo,
// } from "@vime/react";

// const VideoPlayerSec = () => {
//   const [value, setValue] = useState("1");

//   const onMenuItem1Click = () => {
//     console.log("Clicked menu item 1");
//   };

//   const onMenuItem2Click = () => {
//     console.log("Clicked menu item 2");
//   };

//   const onCheck = (event) => {
//     console.log("Chedk.....");
//   };

//   return (
//     <VimePlayer>
//       {/* ... */}
//       <VimeVideo crossOrigin="" poster="https://media.vimejs.com/poster.png">
//         {/* These are passed directly to the underlying HTML5 `<video>` element. */}
//         {/* Why `data-src`? Lazy loading, you can always use `src` if you prefer.  */}
//         <source data-src="https://media.vimejs.com/720p.mp4" type="video/mp4" />
//         <track
//           default
//           kind="subtitles"
//           src="https://media.vimejs.com/subs/english.vtt"
//           srcLang="en"
//           label="English"
//         />
//       </VimeVideo>

//       <VimeDefaultUi noSettings>
//         <VimeSettings>
//           <VimeMenuItem
//             label="Menu Item 1"
//             badge="BADGE"
//             onClick={onMenuItem1Click}
//           />

//           <VimeMenuItem
//             label="Menu Item 2"
//             hint="Hint"
//             onClick={onMenuItem2Click}
//           />

//           <VimeSubmenu label="Submenu 1" hint={value}>
//             <VimeMenuRadioGroup value={value} onVCheck={onCheck}>
//               <VimeMenuRadio label="Option 1" value="1" />
//               <VimeMenuRadio label="Option 2" value="2" />
//               <VimeMenuRadio label="Option 3" value="3" />
//             </VimeMenuRadioGroup>
//           </VimeSubmenu>

//           <VimeSubmenu label="Submenu 2">Random content in here.</VimeSubmenu>
//         </VimeSettings>
//       </VimeDefaultUi>
//     </VimePlayer>
//   );
// };

// export default VideoPlayerSec;
