import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Image,
  Modal,
} from "react-bootstrap";
import "./PlayListSingle.css";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  fetchSingleViewerPlaylistStart,
  removeVideoViewerPlaylistStart,
} from "../../store/actions/ViewerPlayListAction";
import ImageLoader from "../helper/ImageLoader";
import EditPlayListModal from "../helper/EditPlayListModal";
import NoResultFound from "../NoResultFound/NoResultFound";

const PlayListSingle = (props) => {
  const [editModal, setEditModal] = useState(false);

  useEffect(() => {
    props.dispatch(
      fetchSingleViewerPlaylistStart({
        playlist_id: props.match.params.id,
      })
    );
  }, []);

  const showEditModal = () => {
    setEditModal(true);
  };

  const closeEditModal = () => {
    setEditModal(false);
  };

  const { singleVP } = props;

  return (
    <>
      <div className="playlist-single-view-sec">
        <Container>
          <Row>
            <Col sm={12} md={12}>
              {singleVP.loading ? (
                "Loading..."
              ) : (
                <div className="palylist-single-view-box">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="new-history-sec">
                        <div className="new-history-card">
                          <Link to="#">
                            <div className="video-image-sec">
                              <Image
                                src={singleVP.data.picture}
                                className="video-img"
                              />
                            </div>
                          </Link>
                          <div className="new-history-details">
                            <h3 className="title">{singleVP.data.title}</h3>
                            <div className="new-history-count-sec">
                              <div className="total-count">
                                <p className="desc">
                                  {" "}
                                  Total Videos : {singleVP.data.total_videos}
                                </p>
                                <p className="desc">
                                  Last updated on : {singleVP.data.created}
                                </p>
                              </div>
                              <div className="play-all-btn-sec">
                                <Button type="button" className="play-all-btn">
                                  Play All
                                </Button>
                              </div>
                            </div>
                            <div className="user-details">
                              <div className="user-img-sec">
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/img/profile-img.jpg"
                                  }
                                  className="user-img"
                                />
                                <p className="user-name">
                                  {singleVP.data.title}
                                </p>
                              </div>
                              <Link
                                to="#"
                                className="edit-playlist-name"
                                onClick={showEditModal}
                              >
                                <FontAwesomeIcon
                                  icon={["fas", "edit"]}
                                  className="menu-icons clear-icons"
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8 small-padding-left">
                      <div className="new-history-right-sec">
                        <div className="new-history-right-card">
                          <div className="new-history-right-card-header">
                            <h4>Playlist Videos - {singleVP.data.title}</h4>
                          </div>
                          {singleVP.data.video_tapes.length > 0
                            ? singleVP.data.video_tapes.map((video) => (
                                <div className="new-history-right-card-body">
                                  <Row>
                                    <Col xs={5} sm={5} md={4} xl={3}>
                                      <div className="new-history-right-card-img">
                                        <ImageLoader
                                          image={video.video_image}
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="time-count">
                                        <h6>{video.duration}</h6>
                                      </div>
                                    </Col>
                                    <Col
                                      xs={5}
                                      sm={5}
                                      md={5}
                                      xl={6}
                                      className="resp-pd-left-zero"
                                    >
                                      <div className="new-history-right-card-details">
                                        <h4 className="title">{video.title}</h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            {video.watch_count} views
                                          </span>
                                        </p>
                                        <p className="desc">
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-1"
                                            />
                                            {video.publish_time}
                                          </span>
                                        </p>
                                      </div>
                                    </Col>
                                    <Col xs={2} sm={2} md={3} xl={3}>
                                      <Link
                                        to="#"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          props.dispatch(
                                            removeVideoViewerPlaylistStart({
                                              playlist_id:
                                                singleVP.data.playlist_id,
                                              video_tape_id:
                                                video.video_tape_id,
                                            })
                                          );
                                        }}
                                      >
                                        <div className="clear-icon-sec">
                                          <FontAwesomeIcon
                                            icon={["fas", "times"]}
                                            className="menu-icons clear-icons mr-2"
                                          />
                                        </div>
                                      </Link>
                                    </Col>
                                  </Row>
                                </div>
                              ))
                            : <NoResultFound/>}
                        </div>
                      </div>
                      <div className="align-right">
                        <Link to="#">
                          <Button className="loadmore-btn">Load More</Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <EditPlayListModal
                    showEditModal={showEditModal}
                    closeEditModal={closeEditModal}
                    editModal={editModal}
                    playlist={singleVP.data}
                  />
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  singleVP: state.viewerPlaylist.singleVP,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(PlayListSingle);
