import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Container, Row, Col } from "react-bootstrap";
import "./BroadCasting.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RTCMultiConnection from "rtcmulticonnection";
import DetectRTC from "../../../node_modules/detectrtc/DetectRTC";
import RecordRTC from "../../../node_modules/recordrtc/RecordRTC";
import BroadcastHelper from "./BroadcastHelper";
import VideoConnecting from "./VideoConnecting";
import api from "../../Environment";
import io from "socket.io-client";
import config from "react-global-configuration";
import { Redirect } from "react-router-dom";
import BroadcastChat from "./BroadcastChat";
import { connect } from "react-redux";
import {
  endLSOwnerStart,
  fetchSingleLSOwnerStart,
} from "../../store/actions/LiveStreamingAction";

let connection = new RTCMultiConnection();

// by default, socket.io server is assumed to be deployed on your own URL
// connection.socketURL = "https://rtcmulticonnection.herokuapp.com:443/";

connection.socketURL = "https://tubenow.bytecollar.com:3002/";

const $ = window.$;
let chatSocket;

class BroadCasting extends BroadcastHelper {
  state = {
    subscribers: [],
    chatSocket: false,
    customizeData: {
      audioMute: true,
      audioUnmute: false,
      videoMute: true,
      videoUnmute: false,
      recordStatus: false,
    },
    snapshot: null,
    startBroadcastInputData: {},
    modelLoadingContent: null,
    modelButtonDisabled: false,
    liveVideoData: {
      live_video_id: 242452,
    },
    loadingLiveVideo: true,
    liveVideoSuggesstion: null,
    loadingLiveVideoSuggesstion: true,
    chatData: null,
    loadingChatData: true,
    chatInputMessage: "",
    loadMoreButtonDisable: false,
    loadingContent: null,
    skipCount: 0,
    viewerCount: 0,
    streamingStatus: false,
    viewerProfileRedirect: false,
    streamingType: null,
    mainStreamer: false,
    loadingStreamingPage: true,
    apiCallstatus: false,
    startBroadcastButtonDisable: false,
    startBroadcastButtonLoadingContent: null,
  };

  async componentDidMount() {
    console.log("componentDidMount");
    this.oneToManyConfiguration(connection);

    await this.props.dispatch(
      fetchSingleLSOwnerStart({
        live_video_id: this.props.match.params.live_video_id,
      })
    );

    if (this.props.location.state !== undefined) {
      this.watchStreaming();
    } else this.startStreaming();

    // setTimeout(() => {
    //   if (this.props.location.state !== undefined) {
    //     this.watchStreaming();
    //   } else this.startStreaming();
    // }, 2000);
  }

  nextConnection = () => {
    connection.onstream = (event) => {
      let subscriber = event;
      let subscribers = this.state.subscribers;
      subscribers.push(subscriber);

      this.setState({
        subscribers: subscribers,
        streamingStatus: true,
        loadingStreamingPage: false,
      });

      // to keep room-id in cache
      localStorage.setItem(connection.socketMessageEvent, connection.sessionid);

      if (event.type === "local") {
        connection.socket.on("disconnect", () => {
          if (!connection.getAllParticipants().length) {
            alert(
              "Broadcast is ended. We will reload this page to clear the cache..."
            );
            this.stopBroadcastAPI();
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        });
        this.takePhoto(event.mediaElement);
      }
    };

    connection.onEntireSessionClosed = (event) => {
      console.log("Entire session is closed: ", event.sessionid, event.extra);
    };

    connection.onstreamended = (event) => {
      console.log("Connection ended check 1");
      let array = [...this.state.subscribers]; // make a separate copy of the array
      let index = array.indexOf(event);
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({ subscribers: array });
      }

      if (event.type === "local") {
        if (event.userid === connection.sessionid && !connection.isInitiator) {
          alert(
            "Broadcast is ended. We will reload this page to clear the cache."
          );
          this.stopBroadcastAPI();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      } else {
        // notificaiton

        setTimeout(() => {
          window.location.replace("/");
        }, 1000);
      }
    };

    connection.onMediaError = (e) => {
      console.log("Connection ended check 2");
      if (e.message === "Concurrent mic process limit.") {
        if (DetectRTC.audioInputDevices.length <= 1) {
          alert(
            "Please select external microphone. Check github issue number 483."
          );
          return;
        }

        var secondaryMic = DetectRTC.audioInputDevices[1].deviceId;
        connection.mediaConstraints.audio = {
          deviceId: secondaryMic,
        };
        connection.join(connection.sessionid);
      }
    };

    connection.onclose = (event) => {
      console.log("Connection ended check 3");
      alert("data connection closed between you and ");
      window.location.reload();
    };

    connection.onleave = (event) => {
      if (event.type === "remote") {
        alert(" left you.");
        window.location.replace("/");
      }
      if (event.type === "local") {
        alert(" left you.");
        window.location.reload();
      }
    };
  };

  addStreamStopListener = (stream, callback) => {
    console.log("Connection ended check 4");
    stream.addEventListener(
      "ended",
      function () {
        callback();
        callback = function () { };
      },
      false
    );
    stream.addEventListener(
      "inactive",
      function () {
        callback();
        callback = function () { };
      },
      false
    );
    stream.getTracks().forEach(function (track) {
      track.addEventListener(
        "ended",
        function () {
          callback();
          callback = function () { };
        },
        false
      );
      track.addEventListener(
        "inactive",
        function () {
          callback();
          callback = function () { };
        },
        false
      );
    });
  };

  startStreaming = () => {
    connection.sdpConstraints.mandatory = {
      OfferToReceiveAudio: false,
      OfferToReceiveVideo: false,
    };
    setTimeout(() => {
      let roomid = this.props.singleLSOwner.data.unique_id;
      connection.open(roomid);
      this.nextConnection();
      this.getLiveVideoChatDetails();
      this.chatSocketConnect();
    }, 2000);
  };

  watchStreaming = () => {
    let roomid = this.props.singleLSOwner.data.virtual_id;
    connection.sdpConstraints.mandatory = {
      OfferToReceiveAudio: true,
      OfferToReceiveVideo: true,
    };
    connection.checkPresence(roomid, (isRoomEists, roomid) => {
      if (isRoomEists) {
        connection.join(roomid, (isRoomJoined, roomid, error) => {
          if (error) {
            alert(error);
            window.location.replace("/");
          }
        });
        this.chatSocketConnect();
      } else {
        alert("Live streaming stopped");
        this.stopBroadcastAPI();
        setTimeout(() => {
          window.location.replace("/");
        }, 1000);
      }
    });
    this.viewerUpdateAPI();
    this.getLiveVideoChatDetails();
    this.nextConnection();
  };

  muteVideo = () => {
    let localStream = connection.attachStreams[0];

    let videoTracks = localStream.getVideoTracks();
    if (videoTracks[0]) {
      videoTracks[0].enabled = false;
    }
    // console.log("mutte sdfd", connection.attachStreams);
    // localStream.mute("video");
    const customizeData = { ...this.state.customizeData };
    customizeData["videoMute"] = false;
    customizeData["videoUnmute"] = true;
    this.setState({ customizeData });
    console.log("video muted");
  };
  unmuteVideo = () => {
    let localStream = connection.attachStreams[0];
    let videoTracks = localStream.getVideoTracks();
    if (videoTracks[0]) {
      videoTracks[0].enabled = true;
    }
    // localStream.unmute("video");
    const customizeData = { ...this.state.customizeData };
    customizeData["videoMute"] = true;
    customizeData["videoUnmute"] = false;
    this.setState({ customizeData });
    console.log("video muted");
  };

  muteAudio = () => {
    let localStream = connection.attachStreams[0];
    // localStream
    //   .getTracks()
    //   .forEach((track) => (track.enabled = !track.enabled));

    console.log("localstream data", localStream);

    let audioTracks = localStream.getAudioTracks();
    if (audioTracks[0]) {
      audioTracks[0].enabled = false;
    }

    const customizeData = { ...this.state.customizeData };
    customizeData["audioMute"] = false;
    customizeData["audioUnmute"] = true;
    this.setState({ customizeData });
    console.log("video muted");
  };

  unmuteAudio = () => {
    let localStream = connection.attachStreams[0];
    let audioTracks = localStream.getAudioTracks();
    if (audioTracks[0]) {
      audioTracks[0].enabled = true;
    }

    const customizeData = { ...this.state.customizeData };
    customizeData["audioMute"] = true;
    customizeData["audioUnmute"] = false;
    this.setState({ customizeData });
    console.log("video muted");
  };

  leaveStreaming = (event, inputIndex) => {
    let array = [...this.state.subscribers]; // make a separate copy of the array
    let index = array.indexOf(event);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ subscribers: array });
      let localStream = connection.attachStreams[inputIndex];
      localStream.stop();
    }
  };

  stopStreaming = (event) => {
    // disconnect with all users

    connection.getAllParticipants().forEach(function (pid) {
      connection.disconnectWith(pid);
    });

    // stop all local cameras
    connection.attachStreams.forEach(function (localStream) {
      localStream.stop();
    });

    // close socket.io connection
    connection.closeSocket();
    this.setState({ subscribers: [] });
    this.stopBroadcastAPI();
  };

  takePhoto = (video) => {
    let canvas = document.createElement("canvas");
    canvas.width = video.videoWidth || video.clientWidth;
    canvas.height = video.videoHeight || video.clientHeight;

    let context = canvas.getContext("2d");
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    this.setState({ snapshot: canvas.toDataURL("image/png") });
    setTimeout(() => {
      this.snapShotAPI();
    }, 50000);
  };

  snapShotAPI = () => {
    const inputData = {
      snapshot: this.state.snapshot,
      live_video_id: this.props.singleLSOwner.data.live_video_id,
    };
    api.postMethod("live_videos_snapshot_save", inputData).then((response) => {
      if (response.data.success) {
        this.setState({
          snapshot: null,
        });
      } else {
      }
    });
  };

  stopBroadcastAPI = () => {
    this.props.dispatch(
      endLSOwnerStart({
        live_video_id: this.props.match.params.live_video_id,
      })
    );
  };

  viewerUpdateAPI = () => {
    const inputData = {
      live_video_id: this.props.singleLSOwner.data.live_video_id,
    };
    api.postMethod("live_videos_viewer_update", inputData).then((response) => {
      if (response.data.success) {
        //Notificaiton
      } else {
        //Notificaiton
        if (
          response.data.error_code == 169 ||
          response.data.error_code == 170 ||
          response.data.error_code == 171
        ) {
          this.props.history.push("/");
        }
      }
    });
  };

  getLiveVideoChatDetails = () => {
    const inputData = {
      live_video_id: this.props.singleLSOwner.data.live_video_id,
      skip: this.state.skipCount,
    };
    this.getLiveVideoChatAPI(inputData);
  };

  loadMore = (event) => {
    event.preventDefault();
    this.setState({
      loadMoreButtonDisable: true,
      loadingContent: "Loading...",
    });
    const inputData = {
      live_video_id: this.props.singleLSOwner.data.live_video_id,
      skip: this.state.skipCount,
    };

    this.getLiveVideoChatAPI(inputData);
  };

  getLiveVideoChatAPI = (inputData) => {
    let items;
    api.postMethod("live_videos_messages", inputData).then((response) => {

      if (response.data.success) {
        if (this.state.chatData != null) {
          items = [...this.state.chatData, ...response.data.data.messages];
        } else {
          items = [...response.data.data.messages];
        }
        items = items.reverse();
        this.setState({
          chatData: items,
          loadingChatData: false,
          skipCount: response.data.data.length + this.state.skipCount,
          loadMoreButtonDisable: false,
          loadingContent: null,
        });
      } else {
      }
    });
  };

  chatSocketConnect = () => {

    // check the socket url is configured
    let chatSocketUrl = config.get("configData.chat_socket_url");
    console.log("chatSocketUrl" + chatSocketUrl);
    if (chatSocketUrl && Object.keys(this.props.singleLSOwner.data).length > 0) {
      chatSocket = io(chatSocketUrl, {
        query: `room: '` + this.props.singleLSOwner.data.unique_id + `'`,
      });

      chatSocket.emit("update sender", {
        room: this.props.singleLSOwner.data.unique_id,
      });
      let chatContent;
      chatSocket.on("message", (newData) => {
        let content = [];
        content.push(newData);
        chatContent = [...this.state.chatData, ...content];
        this.setState({ chatData: chatContent });
      });
    }
  };

  handleChatSubmit = (event) => {
    console.log(this.props.singleLSOwner.data);
    event.preventDefault();
    const type =
      this.props.singleLSOwner.data.user_id == localStorage.getItem("userId")
        ? "uv"
        : "vu";
    let chatData = [
      {
        live_video_id: this.props.singleLSOwner.data.live_video_id,
        user_id: this.props.singleLSOwner.data.user_id,
        live_video_viewer_id: localStorage.getItem("userId"),
        message: this.state.chatInputMessage,
        type: type,
        user_name: localStorage.getItem("username"),
        user_picture: localStorage.getItem("user_picture"),
        username: localStorage.getItem("username"),
        userpicture: localStorage.getItem("user_picture"),
      },
    ];
    chatSocket.emit("message", chatData[0]);
    let messages;
    if (this.state.chatData != null) {
      messages = [...this.state.chatData, ...chatData];
    } else {
      messages = [...chatData];
    }
    this.setState({
      chatData: messages,
      chatInputMessage: "",
    });
  };

  chatInputChange = ({ currentTarget: input }) => {
    this.setState({ chatInputMessage: input.value });
  };

  render() {
    const {
      loadingContent,
      loadMoreButtonDisable,
      chatData,
      loadingChatData,
    } = this.state;
    return (
      <>
        <div className="broadcasting-sec">
          <Container>
            <Row>
              <Col sm={12} md={12}>
                <h2 className="section-title">Broadcast Video</h2>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12}>
                <div className="broadcasting-card">
                  <Row>
                    {this.props.singleLSOwner.loading ? (
                      "Loading...."
                    ) : (
                      <Col sm={12} md={7}>
                        <div className="video-casting-sec">
                          <div className="video-image-sec">
                            {this.state.subscribers.length > 0 ? (
                              <div className="relative">
                                {this.state.subscribers.map((sub, i) => (
                                  <div key={i}>
                                    <VideoConnecting
                                      streamManager={sub}
                                      muteVideo={this.muteVideo}
                                      unmuteVideo={this.unmuteVideo}
                                      unmuteAudio={this.unmuteAudio}
                                      muteAudio={this.muteAudio}
                                      stopStreaming={this.stopStreaming}
                                      leaveStreaming={this.leaveStreaming}
                                      videoIndex={i}
                                      customizeData={this.state.customizeData}
                                      stopRecordingVideo={
                                        this.stopRecordingVideo
                                      }
                                      takePhoto={this.takePhoto}
                                      liveVideoData={this.state.liveVideoData}
                                      viewerCount={this.state.viewerCount}
                                    />
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div className="relative">
                                <img
                                  src="/assets/img/stream.jpg"
                                  className="img-responsive broadcast-img"
                                />
                                <div className="broadcast-btn">
                                  <button
                                    className="btn"
                                    type="button"
                                    // onClick={this.startStreaming}
                                    data-toggle="modal"
                                    data-target="#start_broadcast"
                                    disabled={
                                      this.state.startBroadcastButtonDisable
                                    }
                                  >
                                    {this.state
                                      .startBroadcastButtonLoadingContent !=
                                      null
                                      ? this.state
                                        .startBroadcastButtonLoadingContent
                                      : "START BROADCASTING"}
                                  </button>
                                </div>
                              </div>
                            )}
                            {/* <Image
                            src="assets/img/video-1.jpg"
                            className="video-img"
                          /> */}
                          </div>
                          <div className="border-bottom-2"></div>
                          <div className="video-flex-data">
                            <div className="video-info">
                              <Button className="free-btn">Free</Button>
                              <Button className="view-btn">
                                <FontAwesomeIcon
                                  icon={["fas", "eye"]}
                                  className="menu-icons clear-icons"
                                />
                                &nbsp;<span id="viewers_cnt">0</span> Views
                              </Button>
                              <div className="bg-facebook">
                                <Link to="#" target="_blank" className="">
                                  <FontAwesomeIcon
                                    icon={["fab", "facebook"]}
                                    className="menu-icons clear-icons"
                                  />
                                </Link>
                              </div>
                              <div className="bg-twitter">
                                <Link to="#">
                                  <FontAwesomeIcon
                                    icon={["fab", "twitter"]}
                                    className="menu-icons clear-icons"
                                  />
                                </Link>
                              </div>
                            </div>
                            <div className="video-action-btn">
                              <Button
                                type="button"
                                className="stop-btn"
                                onClick={this.stopStreaming}
                              >
                                Stop
                              </Button>
                            </div>
                          </div>
                        </div>

                        <div className="video-content">
                          <h4 className="title">
                            {this.props.singleLSOwner.data.title}
                          </h4>
                          <div className="small">
                            Streaming By User From{" "}
                            {this.props.singleLSOwner.data.start_time}
                          </div>
                          <p className="desc">
                            {this.props.singleLSOwner.data.description}
                          </p>
                        </div>
                      </Col>
                    )}
                    <BroadcastChat
                      loadMore={this.loadMore}
                      chatData={chatData}
                      loadingChatData={loadingChatData}
                      loadingContent={loadingContent}
                      loadMoreButtonDisable={loadMoreButtonDisable}
                      chatInputMessage={this.state.chatInputMessage}
                      chatInputChange={this.chatInputChange}
                      handleChatSubmit={this.handleChatSubmit}
                    />
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToPros = (state) => ({
  singleLSOwner: state.liveStreaming.singleLSOwner,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(BroadCasting);
