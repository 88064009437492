import {
  FETCH_LIVETV_START,
  FETCH_LIVETV_SUCCESS,
  FETCH_LIVETV_FAILURE,
  FETCH_SINGLE_LIVETV_START,
  FETCH_SINGLE_LIVETV_SUCCESS,
  FETCH_SINGLE_LIVETV_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  livetv: {
    data: {},
    loading: true,
    error: false,
  },
  singleLivetv: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
};

const LiveTvReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LIVETV_START:
      return {
        ...state,
        livetv: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_LIVETV_SUCCESS:
      return {
        ...state,
        livetv: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_LIVETV_FAILURE:
      return {
        ...state,
        livetv: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case FETCH_SINGLE_LIVETV_START:
      return {
        ...state,
        singleLivetv: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_SINGLE_LIVETV_SUCCESS:
      return {
        ...state,
        singleLivetv: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_SINGLE_LIVETV_FAILURE:
      return {
        ...state,
        singleLivetv: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
      };

    default:
      return state;
  }
};

export default LiveTvReducer;
