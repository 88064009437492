import React, { useEffect, useState } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Image,
  Media,
  Badge,
  Modal,
  ModalFooter,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import {
  addVideoViewerPlaylistStart,
  createViewerPlaylistStart,
  fetchViewerPlaylistStart,
} from "../../store/actions/ViewerPlayListAction";
import NoResultFoundSmall from "../NoResultFoundSmall/NoResultFoundSmall";

const AddtoPlaylistModal = (props) => {
  useEffect(() => {
    if (props.viewerPlaylist.viewerPlaylist.loading)
      props.dispatch(fetchViewerPlaylistStart());
  }, []);

  const [playlistName, setPlaylistName] = useState("");

  const createPlaylist = (event) => {
    event.preventDefault();
    props.dispatch(createViewerPlaylistStart({ title: playlistName }));
    props.handleToggleCreateForm();
  };

  const addVideoToPlaylist = (event, playlist) => {
    props.dispatch(
      addVideoViewerPlaylistStart({
        playlist_id: playlist.playlist_id,
        video_tape_id: props.video.video_tape_id,
      })
    );
    setTimeout(() => {
      props.closeAddToModal();
    }, 1000);
  };

  const { createVP, viewerPlaylist } = props.viewerPlaylist;

  return (
    <Modal
      className="add-to-modal"
      size="md"
      centered
      show={props.addtoModal}
      onHide={props.closeAddToModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add to</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul className="list-unstyled add-to-check">
          {viewerPlaylist.data.length > 0
            ? viewerPlaylist.data.map((playlist) => (
                <Media as="li">
                  <Form.Check
                    type="checkbox"
                    id={playlist.playlist_id}
                    label={playlist.title}
                    custom
                    onChange={(event) => addVideoToPlaylist(event, playlist)}
                  />
                </Media>
              ))
            : <NoResultFoundSmall/>}
        </ul>
      </Modal.Body>
      <ModalFooter>
        <div className="add-footer-content">
          <label onClick={props.handleToggleCreateForm}>
            {props.createForm ? "" : ""}
            <FontAwesomeIcon
              icon={["fas", "plus"]}
              className="modal-icon mr-2"
            />
            Create Playlist
          </label>
        </div>
      </ModalFooter>
      {props.createForm && (
        <div className="create-playlist-form">
          <Form onSubmit={createPlaylist}>
            <Form.Group controlId="formBasicEmail">
              <Form.Control
                type="name"
                placeholder="Enter playlist name..."
                value={playlistName}
                name="title"
                onChange={(event) => setPlaylistName(event.target.value)}
              />
            </Form.Group>
            <Button
              className="btn create-btn"
              type="submit"
              disabled={createVP.buttonDisable}
            >
              {createVP.loadingButtonContent != null
                ? createVP.loadingButtonContent
                : "Create"}
            </Button>
          </Form>
        </div>
      )}
    </Modal>
  );
};

const mapStateToPros = (state) => ({
  viewerPlaylist: state.viewerPlaylist,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AddtoPlaylistModal);
