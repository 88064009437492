import {
  FETCH_REDEEMS_START,
  FETCH_REDEEMS_SUCCESS,
  FETCH_REDEEMS_FAILURE,
  SEND_REDEEM_START,
  SEND_REDEEM_SUCCESS,
  SEND_REDEEM_FAILURE,
  CANCEL_REDEEM_START,
  CANCEL_REDEEM_SUCCESS,
  CANCEL_REDEEM_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  redeems: {
    data: {},
    loading: true,
    error: false,
  },
  sendRedeem: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  cancelRedeem: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  buttonDisable: false,
  loadingButtonContent: null,
};

const RedeemReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REDEEMS_START:
      return {
        ...state,
        redeems: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_REDEEMS_SUCCESS:
      return {
        ...state,
        redeems: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_REDEEMS_FAILURE:
      return {
        ...state,
        redeems: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case SEND_REDEEM_START:
      return {
        ...state,
        sendRedeem: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
        buttonDisable: true,
        loadingButtonContent: "Loading...",
      };
    case SEND_REDEEM_SUCCESS:
      return {
        ...state,
        sendRedeem: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
        buttonDisable: false,
        loadingButtonContent: null,
      };
    case SEND_REDEEM_FAILURE:
      return {
        ...state,
        sendRedeem: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
        buttonDisable: false,
        loadingButtonContent: null,
      };

    case CANCEL_REDEEM_START:
      return {
        ...state,
        cancelRedeem: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
        buttonDisable: true,
        loadingButtonContent: "Loading...",
      };
    case CANCEL_REDEEM_SUCCESS:
      return {
        ...state,
        cancelRedeem: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
        buttonDisable: false,
        loadingButtonContent: null,
      };
    case CANCEL_REDEEM_FAILURE:
      return {
        ...state,
        cancelRedeem: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
        buttonDisable: false,
        loadingButtonContent: null,
      };

    default:
      return state;
  }
};

export default RedeemReducer;
