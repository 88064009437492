import {
  FETCH_VIDEOS_VM_START,
  FETCH_VIDEOS_VM_SUCCESS,
  FETCH_VIDEOS_VM_FAILURE,
  FETCH_SINGLE_VIDEO_VM_START,
  FETCH_SINGLE_VIDEO_VM_SUCCESS,
  FETCH_SINGLE_VIDEO_VM_FAILURE,
  SAVE_VIDEO_VM_START,
  SAVE_VIDEO_VM_SUCCESS,
  SAVE_VIDEO_VM_FAILURE,
  APPROVE_DECLINE_VIDEO_VM_START,
  APPROVE_DECLINE_VIDEO_VM_SUCCESS,
  APPROVE_DECLINE_VIDEO_VM_FAILURE,
  SET_REMOVE_PPV_VIDEO_VM_START,
  SET_REMOVE_PPV_VIDEO_VM_SUCCESS,
  SET_REMOVE_PPV_VIDEO_VM_FAILURE,
  DELETE_VIDEO_VM_START,
  DELETE_VIDEO_VM_SUCCESS,
  DELETE_VIDEO_VM_FAILURE,
  SINGLE_VIDEO_REVENUE_START,
  SINGLE_VIDEO_REVENUE_SUCCESS,
  SINGLE_VIDEO_REVENUE_FAILURE,
  AD_ENABLE_DISABLE_VM_START,
  AD_ENABLE_DISABLE_VM_SUCCESS,
  AD_ENABLE_DISABLE_VM_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  videoVM: {
    data: {},
    loading: true,
    error: false,
  },
  singleVideoVM: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  saveVideoVM: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  singleVideoRevenueVM: {
    data: {},
    loading: true,
    error: false,
    skip: 0,
    inputData: {},
  },
  appDeclineVM: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  setRemovePPVVM: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  deleteVideoVM: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  adEnableDisableVM: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
};

const VideoManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VIDEOS_VM_START:
      return {
        ...state,
        videoVM: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_VIDEOS_VM_SUCCESS:
      return {
        ...state,
        videoVM: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_VIDEOS_VM_FAILURE:
      return {
        ...state,
        videoVM: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case FETCH_SINGLE_VIDEO_VM_START:
      return {
        ...state,
        singleVideoVM: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_SINGLE_VIDEO_VM_SUCCESS:
      return {
        ...state,
        singleVideoVM: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_SINGLE_VIDEO_VM_FAILURE:
      return {
        ...state,
        singleVideoVM: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
      };

    case SAVE_VIDEO_VM_START:
      return {
        ...state,
        saveVideoVM: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading.. Please wait.",
        },
      };
    case SAVE_VIDEO_VM_SUCCESS:
      return {
        ...state,
        saveVideoVM: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case SAVE_VIDEO_VM_FAILURE:
      return {
        ...state,
        saveVideoVM: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case SINGLE_VIDEO_REVENUE_START:
      return {
        ...state,
        singleVideoRevenueVM: {
          data: {},
          loading: true,
          error: false,
          skip: state.singleVideoRevenueVM.skip,
          inputData: action.data,
        },
      };
    case SINGLE_VIDEO_REVENUE_SUCCESS:
      return {
        ...state,
        singleVideoRevenueVM: {
          data: action.data,
          loading: false,
          error: false,
          skip: action.data.length + state.singleVideoRevenueVM.skip,
          inputData: {},
        },
      };
    case SINGLE_VIDEO_REVENUE_FAILURE:
      return {
        ...state,
        singleVideoRevenueVM: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
      };

    case APPROVE_DECLINE_VIDEO_VM_START:
      return {
        ...state,
        appDeclineVM: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case APPROVE_DECLINE_VIDEO_VM_SUCCESS:
      return {
        ...state,
        appDeclineVM: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case APPROVE_DECLINE_VIDEO_VM_FAILURE:
      return {
        ...state,
        appDeclineVM: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case SET_REMOVE_PPV_VIDEO_VM_START:
      return {
        ...state,
        setRemovePPVVM: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case SET_REMOVE_PPV_VIDEO_VM_SUCCESS:
      return {
        ...state,
        setRemovePPVVM: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case SET_REMOVE_PPV_VIDEO_VM_FAILURE:
      return {
        ...state,
        setRemovePPVVM: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case DELETE_VIDEO_VM_START:
      return {
        ...state,
        deleteVideoVM: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case DELETE_VIDEO_VM_SUCCESS:
      return {
        ...state,
        deleteVideoVM: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case DELETE_VIDEO_VM_FAILURE:
      return {
        ...state,
        deleteVideoVM: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case AD_ENABLE_DISABLE_VM_START:
      return {
        ...state,
        adEnableDisableVM: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case AD_ENABLE_DISABLE_VM_SUCCESS:
      return {
        ...state,
        adEnableDisableVM: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case AD_ENABLE_DISABLE_VM_FAILURE:
      return {
        ...state,
        adEnableDisableVM: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    default:
      return state;
  }
};

export default VideoManagementReducer;
