import React from "react"
import ContentLoader from "react-content-loader"

const SubscribedChannelLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1200}
    height={900}
    viewBox="0 0 1200 900"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="30" rx="5" ry="5" width="1200" height="58" /> 
    <rect x="0" y="800" rx="5" ry="5" width="1200" height="58" /> 
    <rect x="0" y="131" rx="0" ry="0" width="500" height="135" /> 
    <rect x="550" y="146" rx="0" ry="0" width="150" height="11" /> 
    <rect x="550" y="169" rx="0" ry="0" width="91" height="10" /> 
    <rect x="550" y="190" rx="0" ry="0" width="65" height="10" /> 
    <rect x="1050" y="159" rx="5" ry="5" width="123" height="25" /> 
    <rect x="0" y="297" rx="0" ry="0" width="500" height="135" /> 
    <rect x="550" y="312" rx="0" ry="0" width="150" height="11" /> 
    <rect x="550" y="335" rx="0" ry="0" width="91" height="10" /> 
    <rect x="550" y="356" rx="0" ry="0" width="65" height="10" /> 
    <rect x="1050" y="325" rx="5" ry="5" width="123" height="25" /> 
    <rect x="0" y="464" rx="0" ry="0" width="500" height="135" /> 
    <rect x="550" y="479" rx="0" ry="0" width="150" height="11" /> 
    <rect x="550" y="502" rx="0" ry="0" width="91" height="10" /> 
    <rect x="550" y="523" rx="0" ry="0" width="65" height="10" /> 
    <rect x="1050" y="492" rx="5" ry="5" width="123" height="25" /> 
    <rect x="0" y="630" rx="0" ry="0" width="500" height="135" /> 
    <rect x="550" y="645" rx="0" ry="0" width="150" height="11" /> 
    <rect x="550" y="668" rx="0" ry="0" width="91" height="10" /> 
    <rect x="550" y="689" rx="0" ry="0" width="65" height="10" /> 
    <rect x="1050" y="658" rx="5" ry="5" width="123" height="25" />
  </ContentLoader>
)

export default SubscribedChannelLoader;
