import React, { useEffect, useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { saveLSOwnerStart } from "../../store/actions/LiveStreamingAction";

const GoLiveModal = (props) => {
  const [inputData, setInputData] = useState({});

  useEffect(() => {
    setInputData({
      ...inputData,
      channel_id: props.match.params.unique_id,
      history: props.history,
    });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(saveLSOwnerStart(inputData));
  };

  return (
    <Modal
      className="golive-modal"
      size="lg"
      show={props.goliveModal}
      onHide={props.closeGoliveModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Start BroadCast</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group controlId="exampleForm.ControlInput1" className="mb-0">
            <Form.Control
              type="text"
              placeholder="Enter Title"
              name="title"
              value={inputData.title}
              onChange={(event) =>
                setInputData({ ...inputData, title: event.target.value })
              }
            />
          </Form.Group>
          <div className="payment-row">
            <div className="payment-label">
              <Form.Label>Payment</Form.Label>
            </div>
            <div className="payment-btn">
              <Form.Check
                custom
                inline
                label="Free"
                type="radio"
                id="free"
                value={2}
                name="payment_status"
                onChange={(event) =>
                  setInputData({
                    ...inputData,
                    payment_status: event.target.value,
                  })
                }
              />

              <Form.Check
                custom
                inline
                label="Paid"
                type="radio"
                id="paid"
                value={1}
                name="payment_status"
                onChange={(event) =>
                  setInputData({
                    ...inputData,
                    payment_status: event.target.value,
                  })
                }
              />
            </div>
          </div>
          {inputData.payment_status === "1" ? (
            <Form.Group controlId="exampleForm.ControlInput1" className="mb-0">
              <Form.Control
                type="text"
                placeholder="Enter Amount"
                name="amount"
                value={inputData.amount}
                onChange={(event) =>
                  setInputData({ ...inputData, amount: event.target.value })
                }
              />
            </Form.Group>
          ) : null}
          <Form.Group controlId="exampleForm.ControlTextarea1" className="mb-0">
            <Form.Control
              as="textarea"
              placeholder="Description"
              rows={3}
              name="description"
              value={inputData.description}
              onChange={(event) =>
                setInputData({ ...inputData, description: event.target.value })
              }
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Link to="#">
            <Button className="reset-btn">Reset</Button>
          </Link>

          <Button
            className="save-btn"
            type="submit"
            disabled={props.saveLSOwner.buttonDisable}
          >
            {props.saveLSOwner.loadingButtonContent != null
              ? props.saveLSOwner.loadingButtonContent
              : "BroadCast"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const mapStateToPros = (state) => ({
  saveLSOwner: state.liveStreaming.saveLSOwner,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(GoLiveModal);
