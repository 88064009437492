import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import ImageLoader from "../../helper/ImageLoader";
import LiveTvLoader from "../../Loader/LiveTvLoader";
import { fetchLSUserStart } from "../../../store/actions/LiveStreamingAction";
import "./LiveVideosUserIndex.css";

import NoResultFound from "../../NoResultFound/NoResultFound";

const LiveVideosUserIndex = (props) => {
  useEffect(() => {
    if (props.liveVideos.loading) props.dispatch(fetchLSUserStart());
  }, []);
  return (
    <>
      <div className="features-sec live-tv-sec">
        <Container>
          <Row>
            <Col sm={12} md={12}>
              <h2 className="section-title">Live Videos</h2>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12}>
              <div className="section-1">
                <Row>
                  {props.liveVideos.loading ? (
                    <LiveTvLoader />
                  ) : props.liveVideos.data.live_videos.length > 0 ? (
                    props.liveVideos.data.live_videos.map((video) => (
                      <Col xs={12} md={3}>
                        <div className="features-card">
                          <Link to={`/broadcasting/${video.live_video_id}`}>
                            <div className="features-card-img">
                              <ImageLoader
                                image={video.snapshot}
                                className="video-img"
                              />
                            </div>
                            <div className="features-card-details">
                              <h4 className="title">{video.title}</h4>
                              <p className="desc">
                                <span className="percentage-count green-clr">
                                  <FontAwesomeIcon
                                    icon="clock"
                                    className="mr-1"
                                  />
                                  {video.started}
                                </span>
                              </p>
                            </div>
                            <div className="time-count">
                              <h6>Live</h6>
                            </div>
                          </Link>
                        </div>
                      </Col>
                    ))
                  ) : (
                    <NoResultFound />
                  )}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  liveVideos: state.liveStreaming.liveVideos,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(LiveVideosUserIndex);
