import {
  FETCH_BELL_NOTIFICATION_START,
  FETCH_BELL_NOTIFICATION_SUCCESS,
  FETCH_BELL_NOTIFICATION_FAILURE,
  UPDATE_BELL_NOTIFICATION_START,
  UPDATE_BELL_NOTIFICATION_SUCCESS,
  UPDATE_BELL_NOTIFICATION_FAILURE,
  FETCH_NOTIFICATION_COUNT_START,
  FETCH_NOTIFICATION_COUNT_SUCCESS,
  FETCH_NOTIFICATION_COUNT_FAILURE,
} from "./ActionConstant";

// fetch bell notification action.

export function fetchBellNotificationStart(data) {
  return {
    type: FETCH_BELL_NOTIFICATION_START,
    data,
  };
}

export function fetchBellNotificationSuccess(data) {
  return {
    type: FETCH_BELL_NOTIFICATION_SUCCESS,
    data,
  };
}

export function fetchBellNotificationFailure(error) {
  return {
    type: FETCH_BELL_NOTIFICATION_FAILURE,
    error,
  };
}

// update bell notification action.

export function updateNotificationStart(data) {
  return {
    type: UPDATE_BELL_NOTIFICATION_START,
    data,
  };
}

export function updateNotificationSuccess(data) {
  return {
    type: UPDATE_BELL_NOTIFICATION_SUCCESS,
    data,
  };
}

export function updateNotificationFailure(error) {
  return {
    type: UPDATE_BELL_NOTIFICATION_FAILURE,
    error,
  };
}

// fetch notification count action.

export function fetchNotificationCountStart(data) {
  return {
    type: FETCH_NOTIFICATION_COUNT_START,
    data,
  };
}

export function fetchNotificationCountSuccess(data) {
  return {
    type: FETCH_NOTIFICATION_COUNT_SUCCESS,
    data,
  };
}

export function fetchNotificationCountFailure(error) {
  return {
    type: FETCH_NOTIFICATION_COUNT_FAILURE,
    error,
  };
}
