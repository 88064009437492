import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Nav,
  Container,
  Row,
  Col,
  Tab,
  Dropdown,
  Image,
  ListGroup,
  Media,
} from "react-bootstrap";
import "./Home.css";
import { connect } from "react-redux";
import VideoCard from "../helper/VideoCard";
import { fetchPremiumDataStart } from "../../store/actions/CoreDataAction";
import HomeLoader from "../Loader/HomeLoader";
import AddtoPlaylistModal from "../helper/AddtoPlaylistModal";

const Premium = (props) => {
  useEffect(() => {
    if (props.premium.loading) props.dispatch(fetchPremiumDataStart());
  }, []);

  const [addtoModal, setAddtoModal] = useState(false);
  const [createForm, setCreateForm] = useState(false);

  const showAddToModal = () => {
    setAddtoModal(true);
  };

  const closeAddToModal = () => {
    setAddtoModal(false);
  };

  const handleToggleCreateForm = () => {
    setCreateForm(!createForm);
  };

  const { premium } = props;
  return (
    <>
      <div className="updates-subscriptions-sec"></div>

      {premium.loading ? (
        <HomeLoader />
      ) : premium.data.videos.length > 0 ? (
        <div className="features-sec">
          <Container>
            <Row>
              <Col xs={12} md={12}>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Row>
                    <Col md={12}>
                      <Nav variant="pills">
                        <Nav.Item>
                          <Nav.Link eventKey="first">Premium Videos</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col md={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <div className="section-1">
                            <Row>
                              {premium.data.videos.map((video) => (
                                <VideoCard
                                  video={video}
                                  showAddToModal={showAddToModal}
                                  {...props}
                                />
                              ))}
                            </Row>
                            {/*<div className="space-lg"></div>*/}
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Col>
            </Row>
            <div className="d-flex d-sm-none resp-arrow-bottom-sec">
              <ul className="list-unstyled">
                <Media as="li">
                  <Image
                    src="assets/img/mobile-arrow.png"
                    className="resp-arrow-bottom"
                  />
                </Media>
              </ul>
            </div>
            <div className="border-bottom-1"></div>
          </Container>
        </div>
      ) : (
        <div className="no-data-found-sec">
          <Row>
            <Col sm={12} md={12}>
              <div className="no-data-found-img-sec">
                <Image
                  src={
                    window.location.origin + "/assets/img/no-result-found.svg"
                  }
                  className="no-data-found-img"
                />
                <p className="text-no-data">No Result Found</p>
              </div>
            </Col>
          </Row>
        </div>
      )}
      <AddtoPlaylistModal
        showAddToModal={showAddToModal}
        closeAddToModal={closeAddToModal}
        addtoModal={addtoModal}
        handleToggleCreateForm={handleToggleCreateForm}
        createForm={createForm}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  premium: state.core.premium,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Premium);
