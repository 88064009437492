import React from "react"
import ContentLoader from "react-content-loader"

const WishlistLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1350}
    height={900}
    viewBox="0 0 1200 900"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="8" rx="5" ry="5" width="1200" height="56" /> 
    <rect x="0" y="80" rx="0" ry="0" width="108" height="9" /> 
    <rect x="135" y="80" rx="0" ry="0" width="108" height="9" /> 
    <rect x="1090" y="80" rx="0" ry="0" width="108" height="9" /> 
    <rect x="0" y="108" rx="0" ry="0" width="250" height="104" /> 
    <rect x="300" y="116" rx="0" ry="0" width="162" height="10" /> 
    <rect x="300" y="140" rx="0" ry="0" width="105" height="10" /> 
    <rect x="300" y="164" rx="0" ry="0" width="70" height="10" /> 
    <circle cx="1190" cy="132" r="9" /> 
    <rect x="0" y="238" rx="0" ry="0" width="250" height="104" /> 
    <rect x="300" y="246" rx="0" ry="0" width="162" height="10" /> 
    <rect x="300" y="270" rx="0" ry="0" width="105" height="10" /> 
    <rect x="300" y="294" rx="0" ry="0" width="70" height="10" /> 
    <circle cx="1190" cy="262" r="9" /> 
    <rect x="0" y="225" rx="0" ry="0" width="1200" height="2" /> 
    <rect x="0" y="371" rx="0" ry="0" width="250" height="104" /> 
    <rect x="300" y="379" rx="0" ry="0" width="162" height="10" /> 
    <rect x="300" y="403" rx="0" ry="0" width="105" height="10" /> 
    <rect x="300" y="427" rx="0" ry="0" width="70" height="10" /> 
    <circle cx="1190" cy="395" r="9" /> 
    <rect x="0" y="358" rx="0" ry="0" width="1200" height="2" /> 
    <rect x="0" y="504" rx="0" ry="0" width="250" height="104" /> 
    <rect x="300" y="512" rx="0" ry="0" width="162" height="10" /> 
    <rect x="300" y="536" rx="0" ry="0" width="105" height="10" /> 
    <rect x="300" y="560" rx="0" ry="0" width="70" height="10" /> 
    <circle cx="1190" cy="528" r="9" /> 
    <rect x="0" y="491" rx="0" ry="0" width="1200" height="2" /> 
    <rect x="540" y="641" rx="20" ry="20" width="200" height="43" /> 
    <rect x="0" y="710" rx="5" ry="5" width="1200" height="80" />
  </ContentLoader>
)

export default WishlistLoader;