import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Tabs,
  Tab,
  Dropdown,
  DropdownButton,
  Image,
  Media,
  Badge,
  Modal,
  ModalFooter,
} from "react-bootstrap";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../helper/NotificationMessage";

import "./SingleVideoAbout.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import {
  addCommentsStart,
  fetchSingleVideoStart,
  fetchVideoCommentsStart,
  likeVideoStart,
  dislikeVideoStart,
} from "../../store/actions/SingleVideoAction";
import VideoPlayerSec from "./VideoPlayerSec";
import {
  fetchSuggestionDataStart,
  fetchTrendingDataStart,
} from "../../store/actions/CoreDataAction";
import ImageLoader from "../helper/ImageLoader";
import ShareModal from "../helper/ShareModal";
import AddtoPlaylistModal from "../helper/AddtoPlaylistModal";
import SingleVideoCommentSec from "./SingleVideoCommectSec";
import SingleVideoAboutLoader from "../Loader/SingleVideoAboutLoader";
import { wishlistOperationStart } from "../../store/actions/WishListAction";
import SpamModal from "../helper/SpamModal";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TelegramShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
  TelegramIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import configuration from "react-global-configuration";
import { subUnsubChannelStart } from "../../store/actions/ChannelAction";

class SingleVideoAbout extends Component {
  constructor(props) {
    super(props);
    this.handleToggleVisib = this.handleToggleVisib.bind(this);
    this.handleToggleCreateForm = this.handleToggleCreateForm.bind(this);
    this.state = {
      visib: false,
      shareModal: false,
      downloadModal: false,
      jumptoModal: false,
      addtoModal: false,
      createForm: false,
      video: "",
      wishlistButton: "",
      subscribeButton: "",
      activeClassName: "",
      dislikeClassName: "",
      commentInputData: {
        video_tape_id: this.props.match.params.id,
      },
      displaySpamModal: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(
      fetchSingleVideoStart({
        video_tape_id: this.props.match.params.id,
        pathname: this.props.location.pathname,
      })
    );
    this.props.dispatch(
      fetchSuggestionDataStart({ video_tape_id: this.props.match.params.id })
    );
    this.props.dispatch(fetchTrendingDataStart());
    this.props.dispatch(
      fetchVideoCommentsStart({ video_tape_id: this.props.match.params.id })
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      // call the fetch function again
      this.props.dispatch(
        fetchSingleVideoStart({
          video_tape_id: this.props.match.params.id,
          pathname: this.props.location.pathname,
        })
      );
    }
  }

  handleChangeComment = ({ currentTarget: input }) => {
    const commentInputData = { ...this.state.commentInputData };
    commentInputData[input.name] = input.value;
    this.setState({ commentInputData });
  };

  addComment = (event) => {
    event.preventDefault();
    this.props.dispatch(addCommentsStart(this.state.commentInputData));
  };

  showShareModal = () => {
    this.setState({ shareModal: true });
  };

  closeShareModal = () => {
    this.setState({ shareModal: false });
  };

  showAddToModal = () => {
    this.setState({ addtoModal: true, video: this.props.singleVideo.data });
  };

  closeAddToModal = () => {
    this.setState({ addtoModal: false });
  };

  handleToggleVisib() {
    this.setState({ visib: !this.state.visib });
  }

  handleToggleCreateForm() {
    this.setState({ createForm: !this.state.createForm });
  }

  addWishlist = (event) => {
    event.preventDefault();
    this.setState({ wishlistButton: "added" });
    this.props.dispatch(
      wishlistOperationStart({
        video_tape_id: this.props.singleVideo.data.video_tape_id,
      })
    );
  };

  removeWishlist = (event) => {
    event.preventDefault();
    this.setState({ wishlistButton: "removed" });
    this.props.dispatch(
      wishlistOperationStart({
        video_tape_id: this.props.singleVideo.data.video_tape_id,
        clear_all_status: 0,
      })
    );
  };

  subscribeUnsubscribe = (event) => {
    this.setState({ subscribeButton: "subscribed" });
    event.preventDefault();
    this.props.dispatch(
      subUnsubChannelStart({
        channel_id: this.props.singleVideo.data.channel_id,
      })
    );
  };

  unsubscribeSubscribe = (event) => {
    this.setState({ subscribeButton: "unsubscribed" });
    event.preventDefault();
    this.props.dispatch(
      subUnsubChannelStart({
        channel_id: this.props.singleVideo.data.channel_id,
      })
    );
  };

  likeVideo = (event) => {
    this.setState({activeClassName: "like-active" });
    event.preventDefault();
    this.props.dispatch(
      likeVideoStart({
        video_tape_id: this.props.singleVideo.data.video_tape_id,
      })
    );
  };

  dislikeVideo = (event) => {
    this.setState({dislikeClassName: "dislike-active" });
    event.preventDefault();
    this.props.dispatch(
      dislikeVideoStart({
        video_tape_id: this.props.singleVideo.data.video_tape_id,
      })
    );
  };

  showSpamModal = () => {
    this.setState({
      displaySpamModal: true,
      video: this.props.singleVideo.data,
    });
  };

  closeSpamModal = () => {
    this.setState({ displaySpamModal: false });
  };

  render() {
    const { singleVideo } = this.props;
    const onCopy = (event) => {
      const notificationMessage = getSuccessNotificationMessage(
        'Link Copied'
      );
      this.props.dispatch(createNotification(notificationMessage));
    };
    
    return (
      <>
        <div className="single-video-about-sec">
          <Container>
            {singleVideo.loading ? (
              <SingleVideoAboutLoader />
            ) : (
              <Row>
                <Col xs={12} md={8}>
                  <VideoPlayerSec video={singleVideo.data} />
                  <h3 className="video-caption-title">
                    {singleVideo.data.title}
                  </h3>

                  <ul className="list-unstyled mobile-tabs-icons d-flex d-sm-none">
                    <Media as="li">
                      <Link to="#">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/watch-later.png"
                          }
                        />
                      </Link>
                    </Media>
                    <Media as="li">
                      <Link to="#">
                        <Image
                          src={window.location.origin + "/assets/img/like.png"}
                        />
                      </Link>
                    </Media>
                    <Media as="li">
                      <Link to="#">
                        <Image
                          src={window.location.origin + "/assets/img/flag.png"}
                        />
                      </Link>
                    </Media>
                  </ul>

                  <div className="video-bottom-toolbar d-none d-sm-block">
                    <div className="video-user-details">
                      <Image
                        src={
                          window.location.origin + "/assets/img/profile-img.jpg"
                        }
                        className="user-img"
                      />
                    </div>
                    <div className="video-user-info-sec">
                      <div className="resp-padding-left-sm">
                        <p className="video-info">
                          <span className="title">
                            {singleVideo.data.channel_name}
                          </span>
                          <span className="video-count">
                            {singleVideo.data.channel_total_videos} Videos
                          </span>
                        </p>
                        <div className="subscribe-details">
                          <h5 className="subscribe-btn">Subscribers</h5>
                          <p className="subscribe-count">
                            {singleVideo.data.total_channel_subscribers}
                          </p>
                        </div>
                      </div>
                      <div className="column-half">
                        <h3 className="view-head-count">
                          {singleVideo.data.watch_count}{" "}
                          <span className="small">views</span>
                        </h3>
                        <div className="subscribe-details">
                          {this.state.subscribeButton !== "" ? (
                            <>
                              <>
                                {this.state.subscribeButton === "unsubscribed" ? (
                                  <Button className="unsubscribe-btn" onClick={this.subscribeUnsubscribe}>
                                    Unsubscribe
                                  </Button>
                                ) : null}
                                {this.state.subscribeButton === "subscribed" ? (
                                  <Button className="subscribe-btn" onClick={this.unsubscribeSubscribe}>Subscribe</Button>
                                ) : null }
                              </>
                            </>
                          ) : singleVideo.data.is_user_subscribed_the_channel === 1 ? (
                            <Button className="unsubscribe-btn" onClick={this.subscribeUnsubscribe}>
                              Unsubscribe
                            </Button>
                          ) : (
                            <Button className="subscribe-btn" onClick={this.unsubscribeSubscribe}>Subscribe</Button>
                          )}
                          
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="resp-toolbar-about">
                    <div className="video-bottom-mobile-toolbar">
                      <div className="video-user-details">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/profile-img.jpg"
                          }
                          className="user-img"
                        />
                        <div className="video-user-info-sec">
                          <div className="">
                            <p className="video-info">
                              <span className="title">NaughtyDog</span>
                            </p>
                            <p className="video-info">
                              <span className="video-count">52 Videos</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="resp-add-subscription">
                        <FontAwesomeIcon
                          icon={["fas", "plus"]}
                          className="add-icon"
                        />
                      </div>
                    </div>
                    <div className="column-half d-block d-sm-none">
                      <h3 className="view-head-count">
                        2,729,347 <span className="small">views</span>
                      </h3>
                      <div className="video-views-details">
                        <span className="view-percentage-count pink-clr">
                          95%
                        </span>
                        <span className="view-count green-clr">
                          <FontAwesomeIcon icon="circle" className="mr-1" />
                          39,852
                        </span>
                        <span className="view-count-1 green-grey">
                          <FontAwesomeIcon icon="circle" className="mr-1" />
                          852
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="video-menu-list-sec resp-separte-tab">
                    <Nav variant="pills" className="d-flex d-sm-none">
                      <Nav.Item>
                        <Nav.Link className="active">
                          <FontAwesomeIcon
                            icon={["fas", "info-circle"]}
                            className="mr-icon"
                          />
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link onClick={this.showShareModal}>
                          <FontAwesomeIcon
                            icon={["fas", "share"]}
                            className="mr-icon"
                          />
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link onClick={this.showAddToModal}>
                          <FontAwesomeIcon
                            icon={["fas", "plus"]}
                            className="mr-icon"
                          />
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <div className="video-menu-list-sec">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="about"
                    >
                      <Row>
                        <Col sm={12}>
                          <Nav variant="pills" className="d-none d-sm-flex">
                            <Nav.Item>
                              <Nav.Link eventKey="about">
                                <FontAwesomeIcon
                                  icon={["fas", "info-circle"]}
                                  className="mr-icon"
                                />
                                <span>About</span>
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="share">
                                <FontAwesomeIcon
                                  icon={["fas", "info-circle"]}
                                  className="mr-icon"
                                />
                                <span>Share</span>
                              </Nav.Link>
                            </Nav.Item>

                            <Nav.Item onClick={this.showAddToModal}>
                              <Nav.Link>
                                <FontAwesomeIcon
                                  icon={["fas", "plus"]}
                                  className="mr-icon"
                                />
                                <span>Add to</span>
                              </Nav.Link>
                            </Nav.Item>
                            <ul className="list-unstyled tab-right-icons d-none d-sm-flex">
                              <Media as="li">
                                {this.state.activeClassName !== "" ? (
                                  <>
                                    <>
                                      {this.props.likeVideo && this.props.likeVideo.data.is_liked === 1 ? (
                                        <Link
                                          to="#"
                                          onClick={this.likeVideo}
                                        >
                                          <i className={"material-icons playlist-icon-1 like-active"
                                          }>
                                            thumb_up
                                          </i>
                                        </Link>
                                      ) : 
                                        <Link
                                          to="#"
                                          onClick={this.likeVideo}
                                        >
                                          <i className={"material-icons playlist-icon-1"
                                          }>
                                            thumb_up
                                          </i>
                                        </Link>
                                      }
                                      <span className=" like-count">
                                        {this.props.likeVideo ? this.props.likeVideo.data.like_count : singleVideo.data.like_count}
                                      </span>
                                    </>
                                  </> 
                                ) : (
                                  <>
                                    <>
                                      {singleVideo.data.is_liked === 0 ? (
                                        <Link
                                          to="#"
                                          onClick={this.likeVideo}
                                        >
                                          <i className={"material-icons playlist-icon-1"
                                          }>
                                            thumb_up
                                          </i>
                                        </Link>
                                      ) : 
                                        <Link
                                          to="#"
                                          onClick={this.likeVideo}
                                        >
                                          <i className={"material-icons playlist-icon-1 like-active"
                                          }>
                                            thumb_up
                                          </i>
                                        </Link>
                                      }
                                      <span className=" like-count">
                                        {singleVideo.data.like_count}
                                      </span>
                                    </>
                                  </> 
                                )}
                              </Media>
                              <Media as="li">
                                {this.state.dislikeClassName !== "" ? (
                                  <>
                                    <>
                                      {this.props.dislikeVideo && this.props.dislikeVideo.data.is_disliked === 1 ? (
                                        <Link
                                          to="#"
                                          onClick={this.dislikeVideo}
                                        >
                                          <i className={"material-icons playlist-icon-1 dislike-active"
                                          }>
                                            thumb_down
                                          </i>
                                        </Link>
                                      ) : 
                                        <Link
                                          to="#"
                                          onClick={this.dislikeVideo}
                                        >
                                          <i className={"material-icons playlist-icon-1"
                                          }>
                                            thumb_down
                                          </i>
                                        </Link>
                                      }
                                      <span className=" like-count">
                                        {this.props.dislikeVideo ? this.props.dislikeVideo.data.dislike_count : singleVideo.data.dislike_count}
                                      </span>
                                    </>
                                  </> 
                                ) : (
                                  <>
                                    <>
                                      {singleVideo.data.is_disliked === 0 ? (
                                        <Link
                                          to="#"
                                          onClick={this.dislikeVideo}
                                        >
                                          <i className={"material-icons playlist-icon-1"
                                          }>
                                            thumb_down
                                          </i>
                                        </Link>
                                      ) : 
                                        <Link
                                          to="#"
                                          onClick={this.dislikeVideo}
                                        >
                                          <i className={"material-icons playlist-icon-1 dislike-active"
                                          }>
                                            thumb_down
                                          </i>
                                        </Link>
                                      }
                                      <span className="dis-like-count">
                                        {singleVideo.data.dislike_count}
                                      </span>
                                    </>
                                  </> 
                                )}
                              </Media>
                             
                              <Media as="li">
                                {this.state.wishlistButton !== "" ? (
                                  <>
                                    <>
                                      {this.state.wishlistButton === "added" ? (
                                        <Link
                                          to="#"
                                          onClick={this.removeWishlist}
                                        >
                                          <Image
                                            src={
                                              window.location.origin +
                                              "/assets/img/like-active.png"
                                            }
                                          />
                                        </Link>
                                      ) : null}
                                    </>
                                    <>
                                      {this.state.wishlistButton ===
                                      "removed" ? (
                                        <Link to="#" onClick={this.addWishlist}>
                                          <Image
                                            src={
                                              window.location.origin +
                                              "/assets/img/like.png"
                                            }
                                          />
                                        </Link>
                                      ) : null}
                                    </>
                                  </>
                                ) : singleVideo.data.wishlist_status === 0 ? (
                                  <Link to="#" onClick={this.addWishlist}>
                                    <Image
                                      src={
                                        window.location.origin +
                                        "/assets/img/like.png"
                                      }
                                    />
                                  </Link>
                                ) : (
                                  <Link to="#" onClick={this.removeWishlist}>
                                    <Image
                                      src={
                                        window.location.origin +
                                        "/assets/img/like-active.png"
                                      }
                                    />
                                  </Link>
                                )}
                              </Media>
                              <Media as="li">
                                <Link to="#" onClick={this.showSpamModal}>
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/img/flag.png"
                                    }
                                  />
                                </Link>
                              </Media>
                            </ul>
                          </Nav>
                        </Col>
                        <Col sm={12}>
                          <Tab.Content>
                            <Tab.Pane eventKey="about">
                              <div className="video-description-sec">
                                <Row>
                                  <Col
                                    xs={12}
                                    md={12}
                                    className=""
                                  >
                                    <div className="cast-description-details">
                                      {singleVideo.data.cast ? (
                                        <>
                                          <h4 className="title">Cast :</h4>
                                          <p className="desc">
                                            Nathan Drake, Victor Sullivan, Sam
                                            Darke, Elena Fisher
                                          </p>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {singleVideo.data.category_name ? (
                                        <>
                                          <h4 className="title">Category :</h4>
                                          <p className="desc">
                                            {singleVideo.data.category_name}
                                          </p>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      <h4 className="title">About :</h4>
                                      <p
                                        className="desc"
                                        dangerouslySetInnerHTML={{
                                          __html: singleVideo.data.description,
                                        }}
                                      ></p>
                                      {singleVideo.data.tags.length > 0 ? (
                                        <>
                                          <h4 className="title">Tags :</h4>
                                          <p className="desc-badge">
                                            {singleVideo.data.tags.map(
                                              (tag) => (
                                                <Badge className="badge badge-tag">
                                                 {tag.tag_name}
                                                </Badge>
                                              )
                                            )}
                                          </p>
                                        </>
                                      ) : null}
                                      <div className="releases-details">
                                        <h3 className="title">
                                          Release Date :
                                          <span className="sub-desc">
                                            {" "}
                                            {singleVideo.data.publish_time}
                                          </span>
                                        </h3>
                                        <h3 className="title">
                                          License :
                                          <span className="sub-desc">
                                            {singleVideo.data
                                              .is_pay_per_view ? (
                                              <span>
                                                Premium{" "}
                                                {
                                                  singleVideo.data
                                                    .ppv_amount_formatted
                                                }
                                              </span>
                                            ) : (
                                              " Standard"
                                            )}
                                          </span>
                                        </h3>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              <div className="d-flex d-sm-none resp-arrow-bottom-sec">
                                <ul className="list-unstyled">
                                  <Media as="li">
                                    <Image
                                      src={
                                        window.location.origin +
                                        "/assets/img/mobile-arrow-1.png"
                                      }
                                      className="resp-arrow-bottom-1"
                                    />
                                  </Media>
                                </ul>
                              </div>
                              {/* <div className="google-adsense-add-sec">
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/img/video-1.jpg"
                                  }
                                  className="addsense-add-img"
                                />
                              </div> */}
                              <div className="mobile-similar-comment-sec">
                                <Tab.Container
                                  id="left-tabs-example"
                                  defaultActiveKey="similar-videos"
                                >
                                  <Row>
                                    <Col sm={12}>
                                      <Nav variant="pills">
                                        <Nav.Item>
                                          <Nav.Link eventKey="similar-videos">
                                            <FontAwesomeIcon
                                              icon={["fas", "play-circle"]}
                                              className="mr-3 mobile-menu"
                                            />
                                            Similar Videos
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="comments-sec">
                                            <FontAwesomeIcon
                                              icon={["fas", "comment-alt"]}
                                              className="mr-3 mobile-menu"
                                            />
                                            236 Comments
                                          </Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </Col>
                                    <Col sm={12}>
                                      <Tab.Content>
                                        <Tab.Pane
                                          eventKey="similar-videos"
                                          className="up-next-right-sec"
                                        >
                                          <div className="up-next-right-video-sec">
                                            <Link to="#">
                                              <div className="up-next-right-video-card">
                                                <Row>
                                                  <Col xs={6} sm={6} md={6}>
                                                    <div className="up-next-right-video-card-img">
                                                      <Image
                                                        src={
                                                          window.location
                                                            .origin +
                                                          "/assets/img/video-1.jpg"
                                                        }
                                                        className="list-video-img"
                                                      />
                                                    </div>
                                                    <div className="time-count">
                                                      <h6>15:19</h6>
                                                    </div>
                                                  </Col>
                                                  <Col xs={6} sm={6} md={6}>
                                                    <div className="up-next-video-card-details">
                                                      <h4 className="title">
                                                        No Man's Sky: 21 Minutes
                                                        of New Gameplay - IGN
                                                        First
                                                      </h4>
                                                      <p className="desc">
                                                        <span className="view-count">
                                                          27,548 views
                                                        </span>
                                                      </p>
                                                      <p className="desc">
                                                        <span className="percentage-count green-clr">
                                                          <FontAwesomeIcon
                                                            icon="circle"
                                                            className="mr-2"
                                                          />
                                                          93%
                                                        </span>
                                                      </p>
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Link>
                                            <Link to="#">
                                              <div className="up-next-right-video-card">
                                                <Row>
                                                  <Col xs={6} sm={6} md={6}>
                                                    <div className="up-next-right-video-card-img">
                                                      <Image
                                                        src={
                                                          window.location
                                                            .origin +
                                                          "/assets/img/video-1.jpg"
                                                        }
                                                        className="list-video-img"
                                                      />
                                                    </div>
                                                    <div className="time-count">
                                                      <h6>15:19</h6>
                                                    </div>
                                                  </Col>
                                                  <Col xs={6} sm={6} md={6}>
                                                    <div className="up-next-video-card-details">
                                                      <h4 className="title">
                                                        No Man's Sky: 21 Minutes
                                                        of New Gameplay - IGN
                                                        First
                                                      </h4>
                                                      <p className="desc">
                                                        <span className="view-count">
                                                          27,548 views
                                                        </span>
                                                      </p>
                                                      <p className="desc">
                                                        <span className="percentage-count green-clr">
                                                          <FontAwesomeIcon
                                                            icon="circle"
                                                            className="mr-2"
                                                          />
                                                          93%
                                                        </span>
                                                      </p>
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Link>
                                            <Link to="#">
                                              <div className="up-next-right-video-card">
                                                <Row>
                                                  <Col xs={6} sm={6} md={6}>
                                                    <div className="up-next-right-video-card-img">
                                                      <Image
                                                        src={
                                                          window.location
                                                            .origin +
                                                          "/assets/img/video-1.jpg"
                                                        }
                                                        className="list-video-img"
                                                      />
                                                    </div>
                                                    <div className="time-count">
                                                      <h6>15:19</h6>
                                                    </div>
                                                  </Col>
                                                  <Col xs={6} sm={6} md={6}>
                                                    <div className="up-next-video-card-details">
                                                      <h4 className="title">
                                                        No Man's Sky: 21 Minutes
                                                        of New Gameplay - IGN
                                                        First
                                                      </h4>
                                                      <p className="desc">
                                                        <span className="view-count">
                                                          27,548 views
                                                        </span>
                                                      </p>
                                                      <p className="desc">
                                                        <span className="percentage-count green-clr">
                                                          <FontAwesomeIcon
                                                            icon="circle"
                                                            className="mr-2"
                                                          />
                                                          93%
                                                        </span>
                                                      </p>
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Link>
                                            <Link to="#">
                                              <div className="up-next-right-video-card">
                                                <Row>
                                                  <Col xs={6} sm={6} md={6}>
                                                    <div className="up-next-right-video-card-img">
                                                      <Image
                                                        src={
                                                          window.location
                                                            .origin +
                                                          "/assets/img/video-1.jpg"
                                                        }
                                                        className="list-video-img"
                                                      />
                                                    </div>
                                                    <div className="time-count">
                                                      <h6>15:19</h6>
                                                    </div>
                                                  </Col>
                                                  <Col xs={6} sm={6} md={6}>
                                                    <div className="up-next-video-card-details">
                                                      <h4 className="title">
                                                        No Man's Sky: 21 Minutes
                                                        of New Gameplay - IGN
                                                        First
                                                      </h4>
                                                      <p className="desc">
                                                        <span className="view-count">
                                                          27,548 views
                                                        </span>
                                                      </p>
                                                      <p className="desc">
                                                        <span className="percentage-count green-clr">
                                                          <FontAwesomeIcon
                                                            icon="circle"
                                                            className="mr-2"
                                                          />
                                                          93%
                                                        </span>
                                                      </p>
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Link>
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="comments-sec">
                                          <div className="single-video-about-comments-sec resp-mobile-comments-sec">
                                            <div className="single-video-comments-body">
                                              <Row>
                                                <Col xs={3} md={2}>
                                                  <div className="video-user-details">
                                                    <Image
                                                      src={
                                                        window.location.origin +
                                                        "/assets/img/profile-img.jpg"
                                                      }
                                                      className="user-img"
                                                    />
                                                  </div>
                                                </Col>
                                                <Col xs={9} md={10}>
                                                  <Form className="comments-form">
                                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                                      <Form.Control
                                                        as="textarea"
                                                        rows="1"
                                                      />
                                                    </Form.Group>
                                                  </Form>
                                                </Col>
                                              </Row>
                                            </div>
                                            <div className="single-video-comments-sec-tabs resp-mobile-comment-btn">
                                              <Tab.Container
                                                id="left-tabs-example"
                                                defaultActiveKey="popular-comments"
                                              >
                                                <Row>
                                                  <Col sm={12} md={12}>
                                                    <Nav variant="pills">
                                                      <Nav.Item>
                                                        <Nav.Link
                                                          eventKey="popular-comments"
                                                          className="padding-left-zero"
                                                        >
                                                          Popular
                                                        </Nav.Link>
                                                      </Nav.Item>
                                                      <Nav.Item>
                                                        <Nav.Link eventKey="newest-comments">
                                                          Newest
                                                        </Nav.Link>
                                                      </Nav.Item>
                                                    </Nav>
                                                  </Col>
                                                  <Col sm={12} md={12}>
                                                    <Tab.Content>
                                                      <Tab.Pane eventKey="popular-comments">
                                                        <div className="comment-tabs-sec">
                                                          <div className="comments-info">
                                                            <Row>
                                                              <Col
                                                                xs={3}
                                                                sm={3}
                                                                md={2}
                                                              >
                                                                <div className="comments-user-details">
                                                                  <Image
                                                                    src={
                                                                      window
                                                                        .location
                                                                        .origin +
                                                                      "/assets/img/profile-img.jpg"
                                                                    }
                                                                    className="user-img"
                                                                  />
                                                                  <div className="resp-align-center">
                                                                    <Link to="#">
                                                                      <Image
                                                                        src="assets/img/flag.png"
                                                                        className="menu-icons"
                                                                      />
                                                                    </Link>
                                                                  </div>
                                                                </div>
                                                              </Col>
                                                              <Col
                                                                xs={9}
                                                                sm={9}
                                                                md={10}
                                                              >
                                                                <p className="user-details">
                                                                  <span className="user-name">
                                                                    NaughtyDog .
                                                                  </span>
                                                                  <span className="post-date">
                                                                    1 Weeks ago
                                                                  </span>
                                                                </p>
                                                                <p className="user-description">
                                                                  Really great
                                                                  story. You're
                                                                  doing a great
                                                                  job. Keep it
                                                                  up pal.
                                                                </p>
                                                                <p className="user-details-count">
                                                                  <span className="view-count mr-2 green-clr">
                                                                    <FontAwesomeIcon
                                                                      icon="circle"
                                                                      className="mr-1"
                                                                    />
                                                                    39,852
                                                                  </span>
                                                                  <span className="view-count-1 mr-2 green-grey">
                                                                    <FontAwesomeIcon
                                                                      icon="circle"
                                                                      className="mr-1"
                                                                    />
                                                                    852
                                                                  </span>
                                                                  .
                                                                  <span
                                                                    className="reply-link"
                                                                    onClick={
                                                                      this
                                                                        .handleToggleVisib
                                                                    }
                                                                  >
                                                                    Reply
                                                                    {this.state
                                                                      .visib
                                                                      ? ""
                                                                      : ""}
                                                                  </span>
                                                                </p>
                                                              </Col>
                                                            </Row>
                                                          </div>
                                                          <div
                                                            className="comments-info reply-show"
                                                            id="dialog_1"
                                                          >
                                                            {this.state
                                                              .visib && (
                                                              <Row>
                                                                <Col
                                                                  xs={2}
                                                                  sm={2}
                                                                  md={2}
                                                                  className="resp-column-none"
                                                                ></Col>
                                                                <Col
                                                                  xs={10}
                                                                  sm={10}
                                                                  md={10}
                                                                >
                                                                  <Row>
                                                                    <Col
                                                                      xs={3}
                                                                      sm={3}
                                                                      md={3}
                                                                    >
                                                                      <div className="comments-user-details">
                                                                        <Image
                                                                          src={
                                                                            window
                                                                              .location
                                                                              .origin +
                                                                            "/assets/img/profile-img.jpg"
                                                                          }
                                                                          className="user-img"
                                                                        />
                                                                      </div>
                                                                    </Col>
                                                                    <Col
                                                                      xs={9}
                                                                      sm={9}
                                                                      md={9}
                                                                    >
                                                                      <p className="user-details">
                                                                        <span className="user-name">
                                                                          NaughtyDog
                                                                          .
                                                                        </span>
                                                                        <span className="post-date">
                                                                          1
                                                                          Weeks
                                                                          ago
                                                                        </span>
                                                                      </p>
                                                                      <p className="user-description">
                                                                        Really
                                                                        great
                                                                        story.
                                                                        You're
                                                                        doing a
                                                                        great
                                                                        job.
                                                                        Keep it
                                                                        up pal.
                                                                      </p>
                                                                      <p className="user-details-count">
                                                                        <span className="view-count mr-2 green-clr">
                                                                          <FontAwesomeIcon
                                                                            icon="circle"
                                                                            className="mr-1"
                                                                          />
                                                                          39,852
                                                                        </span>
                                                                        <span className="view-count-1 mr-2 green-grey">
                                                                          <FontAwesomeIcon
                                                                            icon="circle"
                                                                            className="mr-1"
                                                                          />
                                                                          852
                                                                        </span>
                                                                        .
                                                                        <span className="reply-link">
                                                                          Reply
                                                                        </span>
                                                                      </p>
                                                                    </Col>
                                                                  </Row>
                                                                </Col>
                                                              </Row>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </Tab.Pane>
                                                      <Tab.Pane eventKey="newest-comments">
                                                        <div className="comment-tabs-sec">
                                                          <div className="comments-info">
                                                            <Row>
                                                              <Col
                                                                xs={3}
                                                                sm={3}
                                                                md={2}
                                                              >
                                                                <div className="comments-user-details">
                                                                  <Image
                                                                    src={
                                                                      window
                                                                        .location
                                                                        .origin +
                                                                      "/assets/img/profile-img.jpg"
                                                                    }
                                                                    className="user-img"
                                                                  />
                                                                  <div className="resp-align-center">
                                                                    <Link to="#">
                                                                      <Image
                                                                        src="assets/img/flag.png"
                                                                        className="menu-icons"
                                                                      />
                                                                    </Link>
                                                                  </div>
                                                                </div>
                                                              </Col>
                                                              <Col
                                                                xs={9}
                                                                sm={9}
                                                                md={10}
                                                              >
                                                                <p className="user-details">
                                                                  <span className="user-name">
                                                                    NaughtyDog .
                                                                  </span>
                                                                  <span className="post-date">
                                                                    1 Weeks ago
                                                                  </span>
                                                                </p>
                                                                <p className="user-description">
                                                                  Really great
                                                                  story. You're
                                                                  doing a great
                                                                  job. Keep it
                                                                  up pal.
                                                                </p>
                                                                <p className="user-details-count">
                                                                  <span className="view-count mr-2 green-clr">
                                                                    <FontAwesomeIcon
                                                                      icon="circle"
                                                                      className="mr-1"
                                                                    />
                                                                    39,852
                                                                  </span>
                                                                  <span className="view-count-1 mr-2 green-grey">
                                                                    <FontAwesomeIcon
                                                                      icon="circle"
                                                                      className="mr-1"
                                                                    />
                                                                    852
                                                                  </span>
                                                                  .
                                                                  <span
                                                                    className="reply-link"
                                                                    onClick={
                                                                      this
                                                                        .handleToggleVisib
                                                                    }
                                                                  >
                                                                    Reply
                                                                    {this.state
                                                                      .visib
                                                                      ? ""
                                                                      : ""}
                                                                  </span>
                                                                </p>
                                                              </Col>
                                                            </Row>
                                                          </div>
                                                          <div
                                                            className="comments-info reply-show"
                                                            id="dialog_1"
                                                          >
                                                            {this.state
                                                              .visib && (
                                                              <Row>
                                                                <Col
                                                                  xs={2}
                                                                  sm={2}
                                                                  md={2}
                                                                  className="resp-column-none"
                                                                ></Col>
                                                                <Col
                                                                  xs={10}
                                                                  sm={10}
                                                                  md={10}
                                                                >
                                                                  <Row>
                                                                    <Col
                                                                      xs={3}
                                                                      sm={3}
                                                                      md={3}
                                                                    >
                                                                      <div className="comments-user-details">
                                                                        <Image
                                                                          src={
                                                                            window
                                                                              .location
                                                                              .origin +
                                                                            "/assets/img/profile-img.jpg"
                                                                          }
                                                                          className="user-img"
                                                                        />
                                                                      </div>
                                                                    </Col>
                                                                    <Col
                                                                      xs={9}
                                                                      sm={9}
                                                                      md={9}
                                                                    >
                                                                      <p className="user-details">
                                                                        <span className="user-name">
                                                                          NaughtyDog
                                                                          .
                                                                        </span>
                                                                        <span className="post-date">
                                                                          1
                                                                          Weeks
                                                                          ago
                                                                        </span>
                                                                      </p>
                                                                      <p className="user-description">
                                                                        Really
                                                                        great
                                                                        story.
                                                                        You're
                                                                        doing a
                                                                        great
                                                                        job.
                                                                        Keep it
                                                                        up pal.
                                                                      </p>
                                                                      <p className="user-details-count">
                                                                        <span className="view-count mr-2 green-clr">
                                                                          <FontAwesomeIcon
                                                                            icon="circle"
                                                                            className="mr-1"
                                                                          />
                                                                          39,852
                                                                        </span>
                                                                        <span className="view-count-1 mr-2 green-grey">
                                                                          <FontAwesomeIcon
                                                                            icon="circle"
                                                                            className="mr-1"
                                                                          />
                                                                          852
                                                                        </span>
                                                                        .
                                                                        <span className="reply-link">
                                                                          Reply
                                                                        </span>
                                                                      </p>
                                                                    </Col>
                                                                  </Row>
                                                                </Col>
                                                              </Row>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </Tab.Pane>
                                                    </Tab.Content>
                                                  </Col>
                                                </Row>
                                              </Tab.Container>
                                            </div>
                                          </div>
                                        </Tab.Pane>
                                      </Tab.Content>
                                    </Col>
                                  </Row>
                                </Tab.Container>
                              </div>

                              <div className="resp-load-more-bottom d-flex d-sm-none">
                                <ul className="list-unstyled">
                                  <Media as="li">
                                    <Image
                                      src="assets/img/mobile-arrow.png"
                                      className="resp-arrow-bottom-2"
                                    />
                                  </Media>
                                </ul>
                              </div>
                              <div className="border-top-1"></div>
                              <div className="resp-mobile-up-next-right-add-sec d-flex d-sm-none">
                                <div>
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/img/video-1.jpg"
                                    }
                                    className="add-sec-img"
                                  />
                                </div>
                              </div>
                              <div className="similar-video-sec d-none d-sm-block">
                                <h6 className="head-title">Similar Videos</h6>
                                <Row>
                                  {this.props.trending.loading
                                    ? "Loading..."
                                    : this.props.trending.data.length > 0
                                    ? this.props.trending.data.map((video) => (
                                        <Col xs={12} md={3}>
                                          <Link
                                            to={`/single-video/${video.video_tape_id}`}
                                          >
                                            <div className="similar-video-card">
                                              <div className="similar-video-card-img">
                                                <ImageLoader
                                                  image={video.video_image}
                                                  className="video-img"
                                                />
                                              </div>
                                              <div className="similar-video-card-details">
                                                <h4 className="title">
                                                  {video.title}
                                                </h4>
                                                <p className="desc">
                                                  <span className="view-count">
                                                    {video.watch_count} views
                                                  </span>
                                                </p>
                                              </div>
                                              <div className="time-count">
                                                <h6>{video.duration}</h6>
                                              </div>
                                            </div>
                                          </Link>
                                        </Col>
                                      ))
                                    : null}
                                </Row>
                              </div>
                              <div className="single-video-about-comments-sec d-none d-sm-block">
                                <div className="single-video-comments-header">
                                  <h4 className="head-title">
                                    <Image
                                      src={
                                        window.location.origin +
                                        "/assets/img/comment.png"
                                      }
                                      className="comments-icons"
                                    />
                                    236
                                    <span className="small-title">
                                      Comments
                                    </span>
                                  </h4>
                                </div>
                                {localStorage.getItem("userLoginStatus") ? (
                                <div className="single-video-comments-body">
                                  <Row>
                                    <Col xs={12} md={2}>
                                      <div className="video-user-details">
                                        <Image
                                          src={localStorage.getItem(
                                            "user_picture"
                                          )}
                                          className="user-img"
                                        />
                                      </div>
                                    </Col>
                                     
                                    <Col xs={12} md={10}>
                                      <Form
                                        className="comments-form"
                                        onSubmit={this.addComment}
                                      >
                                        <Form.Group controlId="exampleForm.ControlTextarea1">
                                          <Form.Control
                                            as="textarea"
                                            rows="3"
                                            name="comment"
                                            value={
                                              this.state.commentInputData
                                                .comment
                                            }
                                            onChange={this.handleChangeComment}
                                          />
                                        </Form.Group>
                                        <div>
                                          <Button
                                            className="login-btn"
                                            type="submit"
                                            disabled={
                                              this.props.addComment
                                                .buttonDisable
                                            }
                                          >
                                            {this.props.addComment
                                              .loadingButtonContent != null
                                              ? this.props.addComment
                                                  .loadingButtonContent
                                              : "Submit"}
                                          </Button>
                                        </div>
                                      </Form>
                                    </Col>
                                    
                                  </Row>
                                </div>
                                ) : ''}
                                {/* Comment section */}

                                <SingleVideoCommentSec
                                  comments={this.props.comments}
                                />
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="share">
                              <div className="video-share-sec">
                                <h4 className="title">Share:</h4>
                                <ul className="list-unstyled share-links">
                                  <Media as="li">
                                    <FacebookShareButton
                                      url={window.location.origin +
                                        "/single-video/" +
                                        singleVideo.data.video_tape_id}
                                      // quote={singleVideo.data.share_message}
                                      className="facebook-bg"
                                    >
                                      <FacebookIcon size={32} round />
                                    </FacebookShareButton>
                                  </Media>
                                  <Media as="li">
                                    <TwitterShareButton
                                      url={window.location.origin +
                                        "/single-video/" +
                                        singleVideo.data.video_tape_id}
                                      // title={data.share_message}
                                      className="twitter-bg"
                                    >
                                      <TwitterIcon size={32} round />
                                    </TwitterShareButton>
                                  </Media>
                                  <Media as="li">
                                    <EmailShareButton
                                      subject={configuration.get(
                                        "configData.site_name"
                                      )}
                                      body={window.location.origin +
                                        "/single-video/" +
                                        singleVideo.data.video_tape_id}
                                      // title={data.share_message}
                                      className="email-bg"
                                    >
                                      <EmailIcon size={32} round />
                                    </EmailShareButton>
                                  </Media>

                                  <Media as="li">
                                    <WhatsappShareButton
                                      url={window.location.origin +
                                        "/single-video/" +
                                        singleVideo.data.video_tape_id}
                                      // title={data.share_message}
                                      className="email-bg"
                                    >
                                      <WhatsappIcon size={32} round />
                                    </WhatsappShareButton>
                                  </Media>

                                  <Media as="li">
                                    <Link to="#">
                                      <TelegramShareButton
                                        url={
                                          window.location.origin +
                                        "/single-video/" +
                                        singleVideo.data.video_tape_id
                                        }
                                      >
                                        <TelegramIcon size={32} round />
                                      </TelegramShareButton>
                                    </Link>
                                  </Media>

                                </ul>
                                <Form className="share-form">
                                  <Form.Row className="align-items-center">
                                    <Col sm={12} md={12}>
                                      <Form.Label
                                        className="title"
                                        htmlFor="inlineFormInput"
                                      >
                                        Link:
                                      </Form.Label>
                                      <Form.Control
                                        className="mb-3"
                                        id="inlineFormInput"
                                        readOnly
                                        placeholder={
                                          window.location.origin +
                                          "/single-video/" +
                                          singleVideo.data.video_tape_id
                                        }
                                      />
                                      <CopyToClipboard
                                        text={
                                          window.location.origin +
                                          "/single-video/" +
                                          singleVideo.data.video_tape_id
                                        }
                                        onCopy={onCopy}
                                      >
                                        <Button className="btn-copy">Copy</Button>
                                      </CopyToClipboard>

                                      
                                    </Col>
                                  </Form.Row>
                                </Form>
                              </div>

                              {/* Same the similar section on the about section added here. Which I removed.  */}
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </div>
                </Col>
                <Col xs={12} md={4} className="d-none d-sm-block">
                  <div className="up-next-right-sec">
                    <div className="up-next-right-header">
                      <h4 className="side-title">Suggestions</h4>
                      {/* <Form className="toggle-switch">
                        <span className="mr-2 switch-title">Auto Play</span>
                        <Form.Check type="switch" id="custom-switch" label="" />
                      </Form> */}
                    </div>

                    <div className="up-next-right-video-sec">
                      {this.props.suggestion.loading
                        ? "Loading..."
                        : this.props.suggestion.data.length > 0
                        ? this.props.suggestion.data.map((video) => (
                            <Link to={`/single-video/${video.video_tape_id}`}>
                              <div className="up-next-right-video-card">
                                <Row>
                                  <Col sm={12} md={6}>
                                    <div className="up-next-right-video-card-img">
                                      <ImageLoader
                                        image={video.video_image}
                                        className="list-video-img"
                                      />
                                    </div>
                                    <div className="time-count">
                                      <h6>{video.duration}</h6>
                                    </div>
                                  </Col>
                                  <Col sm={12} md={6}>
                                    <div className="up-next-video-card-details">
                                      <h4 className="title">{video.title}</h4>
                                      <p className="desc">
                                        <span className="view-count">
                                          {video.watch_count} views
                                        </span>
                                      </p>
                                      {/* <p className="desc">
                                    <span className="percentage-count green-clr">
                                      <FontAwesomeIcon
                                        icon="circle"
                                        className="mr-2"
                                      />
                                      93%
                                    </span>
                                  </p> */}
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Link>
                          ))
                        : null}
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </Container>
        </div>

        <ShareModal
          showShareModal={this.showShareModal}
          closeShareModal={this.closeShareModal}
          shareModal={this.state.shareModal}
        />

        <AddtoPlaylistModal
          showAddToModal={this.showAddToModal}
          closeAddToModal={this.closeAddToModal}
          addtoModal={this.state.addtoModal}
          handleToggleCreateForm={this.handleToggleCreateForm}
          createForm={this.state.createForm}
          video={this.state.video}
        />
        <SpamModal
          closeSpamModal={this.closeSpamModal}
          showSpamModal={this.showSpamModal}
          displaySpamModal={this.state.displaySpamModal}
          video_tape_id={this.state.video.video_tape_id}
        />
      </>
    );
  }
}

const mapStateToPros = (state) => ({
  singleVideo: state.singleVideo.video,
  suggestion: state.core.suggestion,
  trending: state.core.trending,
  comments: state.singleVideo.comments,
  addComment: state.singleVideo.addComment,
  likeVideo: state.singleVideo.likeVideo,
  dislikeVideo: state.singleVideo.dislikeVideo,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SingleVideoAbout);
