import {
  FETCH_HOMEPAGE_DATA_START,
  FETCH_HOMEPAGE_DATA_SUCCESS,
  FETCH_HOMEPAGE_DATA_FAILURE,
  FETCH_TRENDING_DATA_START,
  FETCH_TRENDING_DATA_SUCCESS,
  FETCH_TRENDING_DATA_FAILURE,
  FETCH_TAGS_DATA_START,
  FETCH_TAGS_DATA_SUCCESS,
  FETCH_TAGS_DATA_FAILURE,
  FETCH_CATEGORY_DATA_START,
  FETCH_CATEGORY_DATA_SUCCESS,
  FETCH_CATEGORY_DATA_FAILURE,
  FETCH_SUGGESTION_DATA_START,
  FETCH_SUGGESTION_DATA_SUCCESS,
  FETCH_SUGGESTION_DATA_FAILURE,
  FETCH_SEARCH_DATA_START,
  FETCH_SEARCH_DATA_SUCCESS,
  FETCH_SEARCH_DATA_FAILURE,
  FETCH_PREMIUM_DATA_START,
  FETCH_PREMIUM_DATA_SUCCESS,
  FETCH_PREMIUM_DATA_FAILURE,
} from "./ActionConstant";

// fetch home page data action.

export function fetchHomePageDataStart(data) {
  return {
    type: FETCH_HOMEPAGE_DATA_START,
    data,
  };
}

export function fetchHomePageDataSuccess(data) {
  return {
    type: FETCH_HOMEPAGE_DATA_SUCCESS,
    data,
  };
}

export function fetchHomePageDataFailure(error) {
  return {
    type: FETCH_HOMEPAGE_DATA_FAILURE,
    error,
  };
}

// fetch trending data action.

export function fetchTrendingDataStart(data) {
  return {
    type: FETCH_TRENDING_DATA_START,
    data,
  };
}

export function fetchTrendingDataSuccess(data) {
  return {
    type: FETCH_TRENDING_DATA_SUCCESS,
    data,
  };
}

export function fetchTrendingDataFailure(error) {
  return {
    type: FETCH_TRENDING_DATA_FAILURE,
    error,
  };
}

// fetch tags data action.

export function fetchTagsDataStart(data) {
  return {
    type: FETCH_TAGS_DATA_START,
    data,
  };
}

export function fetchTagsDataSuccess(data) {
  return {
    type: FETCH_TAGS_DATA_SUCCESS,
    data,
  };
}

export function fetchTagsDataFailure(error) {
  return {
    type: FETCH_TAGS_DATA_FAILURE,
    error,
  };
}

// fetch category data action.

export function fetchCategoryDataStart(data) {
  return {
    type: FETCH_CATEGORY_DATA_START,
    data,
  };
}

export function fetchCategoryDataSuccess(data) {
  return {
    type: FETCH_CATEGORY_DATA_SUCCESS,
    data,
  };
}

export function fetchCategoryDataFailure(error) {
  return {
    type: FETCH_CATEGORY_DATA_FAILURE,
    error,
  };
}

// fetch suggesstion data action.

export function fetchSuggestionDataStart(data) {
  return {
    type: FETCH_SUGGESTION_DATA_START,
    data,
  };
}

export function fetchSuggestionDataSuccess(data) {
  return {
    type: FETCH_SUGGESTION_DATA_SUCCESS,
    data,
  };
}

export function fetchSuggestionDataFailure(error) {
  return {
    type: FETCH_SUGGESTION_DATA_FAILURE,
    error,
  };
}

// fetch search data action.

export function fetchSearchDataStart(data) {
  return {
    type: FETCH_SEARCH_DATA_START,
    data,
  };
}

export function fetchSearchDataSuccess(data) {
  return {
    type: FETCH_SEARCH_DATA_SUCCESS,
    data,
  };
}

export function fetchSearchDataFailure(error) {
  return {
    type: FETCH_SEARCH_DATA_FAILURE,
    error,
  };
}

// fetch premium data action.
export function fetchPremiumDataStart(data) {
  return {
    type: FETCH_PREMIUM_DATA_START,
    data,
  };
}

export function fetchPremiumDataSuccess(data) {
  return {
    type: FETCH_PREMIUM_DATA_SUCCESS,
    data,
  };
}

export function fetchPremiumDataFailure(error) {
  return {
    type: FETCH_PREMIUM_DATA_FAILURE,
    error,
  };
}
