import {
  FETCH_CHANNELS_START,
  FETCH_CHANNELS_SUCCESS,
  FETCH_CHANNELS_FAILURE,
  FETCH_SINGLE_CHANNEL_START,
  FETCH_SINGLE_CHANNEL_SUCCESS,
  FETCH_SINGLE_CHANNEL_FAILURE,
  FETCH_CHANNEL_VIDEOS_START,
  FETCH_CHANNEL_VIDEOS_SUCCESS,
  FETCH_CHANNEL_VIDEOS_FAILURE,
  FETCH_CHANNEL_PLAYLISTS_START,
  FETCH_CHANNEL_PLAYLISTS_SUCCESS,
  FETCH_CHANNEL_PLAYLISTS_FAILURE,
  FETCH_SUBSCRIBED_CHANNEL_START,
  FETCH_SUBSCRIBED_CHANNEL_SUCCESS,
  FETCH_SUBSCRIBED_CHANNEL_FAILURE,
  SUBSCRIBE_UNSUBS_CHANNEL_START,
  SUBSCRIBE_UNSUBS_CHANNEL_SUCCESS,
  SUBSCRIBE_UNSUBS_CHANNEL_FAILURE,
  CREATE_CHANNEL_START,
  CREATE_CHANNEL_SUCCESS,
  CREATE_CHANNEL_FAILURE,
  DELETE_CHANNEL_START,
  DELETE_CHANNEL_SUCCESS,
  DELETE_CHANNEL_FAILURE,
  FETCH_OWNER_CHANNEL_LIST_START,
  FETCH_OWNER_CHANNEL_LIST_SUCCESS,
  FETCH_OWNER_CHANNEL_LIST_FAILURE,
  FETCH_SINGLE_CHANNEL_OWNER_START,
  FETCH_SINGLE_CHANNEL_OWNER_SUCCESS,
  FETCH_SINGLE_CHANNEL_OWNER_FAILURE,
  FETCH_SINGLE_VIDEO_CHANNEL_OWNER_START,
  FETCH_SINGLE_VIDEO_CHANNEL_OWNER_SUCCESS,
  FETCH_SINGLE_VIDEO_CHANNEL_OWNER_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  channels: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  singleChannel: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  channelVideos: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  subChannel: {
    data: {},
    loading: true,
    error: false,
    skip: 0,
  },
  subUnsubChannel: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  createChannel: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  deleteChannel: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  channelOwner: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  singleChannelOwner: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  singleChannelOwnerVideo: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
};

const ChannelReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CHANNELS_START:
      return {
        ...state,
        channels: {
          inputData: action.data,
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_CHANNELS_SUCCESS:
      return {
        ...state,
        channels: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_CHANNELS_FAILURE:
      return {
        ...state,
        channels: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
      };
    case FETCH_SINGLE_CHANNEL_START:
      return {
        ...state,
        singleChannel: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_SINGLE_CHANNEL_SUCCESS:
      return {
        ...state,
        singleChannel: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_SINGLE_CHANNEL_FAILURE:
      return {
        ...state,
        singleChannel: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
      };

    case FETCH_CHANNEL_VIDEOS_START:
      return {
        ...state,
        channelVideos: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_CHANNEL_VIDEOS_SUCCESS:
      return {
        ...state,
        channelVideos: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_CHANNEL_VIDEOS_FAILURE:
      return {
        ...state,
        channelVideos: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
      };
    case FETCH_CHANNEL_PLAYLISTS_START:
      return {
        ...state,
        channelPlaylists: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_CHANNEL_PLAYLISTS_SUCCESS:
      return {
        ...state,
        channelPlaylists: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_CHANNEL_PLAYLISTS_FAILURE:
      return {
        ...state,
        channelPlaylists: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
      };

    case FETCH_SUBSCRIBED_CHANNEL_START:
      return {
        ...state,
        subChannel: {
          data: {},
          loading: true,
          error: false,
          skip: action.data != null ? action.data : state.subChannel.skip,
        },
      };
    case FETCH_SUBSCRIBED_CHANNEL_SUCCESS:
      return {
        ...state,
        subChannel: {
          data: action.data,
          loading: false,
          error: false,
          skip: action.data.length + state.subChannel.skip,
        },
      };
    case FETCH_SUBSCRIBED_CHANNEL_FAILURE:
      return {
        ...state,
        subChannel: {
          data: {},
          loading: false,
          error: action.error,
        },
      };

    case SUBSCRIBE_UNSUBS_CHANNEL_START:
      return {
        ...state,
        subUnsubChannel: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case SUBSCRIBE_UNSUBS_CHANNEL_SUCCESS:
      return {
        ...state,
        subUnsubChannel: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case SUBSCRIBE_UNSUBS_CHANNEL_FAILURE:
      return {
        ...state,
        subUnsubChannel: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case CREATE_CHANNEL_START:
      return {
        ...state,
        createChannel: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case CREATE_CHANNEL_SUCCESS:
      return {
        ...state,
        createChannel: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case CREATE_CHANNEL_FAILURE:
      return {
        ...state,
        createChannel: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case DELETE_CHANNEL_START:
      return {
        ...state,
        deleteChannel: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case DELETE_CHANNEL_SUCCESS:
      return {
        ...state,
        deleteChannel: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case DELETE_CHANNEL_FAILURE:
      return {
        ...state,
        deleteChannel: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case FETCH_OWNER_CHANNEL_LIST_START:
      return {
        ...state,
        channelOwner: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_OWNER_CHANNEL_LIST_SUCCESS:
      console.log(action.data);
      return {
        ...state,
        channelOwner: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_OWNER_CHANNEL_LIST_FAILURE:
      return {
        ...state,
        channelOwner: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
      };
    case FETCH_SINGLE_CHANNEL_OWNER_START:
      return {
        ...state,
        singleChannelOwner: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_SINGLE_CHANNEL_OWNER_SUCCESS:
      return {
        ...state,
        singleChannelOwner: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_SINGLE_CHANNEL_OWNER_FAILURE:
      return {
        ...state,
        singleChannelOwner: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
      };

    case FETCH_SINGLE_VIDEO_CHANNEL_OWNER_START:
      return {
        ...state,
        singleChannelOwnerVideo: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case FETCH_SINGLE_VIDEO_CHANNEL_OWNER_SUCCESS:
      return {
        ...state,
        singleChannelOwnerVideo: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case FETCH_SINGLE_VIDEO_CHANNEL_OWNER_FAILURE:
      return {
        ...state,
        singleChannelOwnerVideo: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    default:
      return state;
  }
};

export default ChannelReducer;
