import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Tabs,
  Tab,
  Dropdown,
  DropdownButton,
  ProgressBar,
  Image,
  Media,
} from "react-bootstrap";
import "./UploadVideoEdit.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class UploadVideoEdit extends Component {
  render() {
    return (
      <>
        <div className="upload-video-edit-sec">
            <Container>
                <Row>
                    <Col sm={12} md={12}>
                        <div className="upload-video-edit-details">
                            <h4 className="title-head">Upload Details</h4>
                            <Row>
                                <Col sm={12} md={2}>
                                    <Image
                                    src={
                                        window.location.origin +
                                        "/assets/img/video-1.jpg"
                                      } className="edit-video-img" />
                                </Col>
                                <Col sm={12} md={9}>
                                    <h4 className="title">Rocket League Gameplay 03.08.2020 - 15.13.31.01</h4>
                                    <p className="desc">
                                        <span className="data-size">102.6 MB</span>.
                                        <span className="progress-time">2:13 MIN Remaining</span>
                                    </p>
                                    <div className="video-upload-status-bar">
                                        <ProgressBar variant="success" now={40} />
                                    </div>
                                    <p className="desc d-block d-sm-none">
                                        Your Video is still uploading, please keep this page open until it's done.
                                    </p>
                                </Col>
                                <Col sm={12} md={1}>
                                    <div className="btn-dismiss">
                                        <Button className="btn-close">
                                            <FontAwesomeIcon
                                            icon={["fas", "times"]}
                                            className="menu-icons"
                                            />
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <div className="video-edit-form-sec">
            <Container>
                <Row>
                    <Col sm={12} md={12}>
                        <Form className="video-edit-form">
                            <Form.Row className="align-items-center">
                                <Col sm={12} md={12}>
                                    <Form.Group controlId="exampleForm.ControlInput1">
                                        <Form.Label
                                            className="title"
                                            htmlFor="inlineFormInput"
                                        >
                                        Video Title
                                        </Form.Label>
                                        <Form.Control
                                            className="mb-3"
                                            id="inlineFormInput"
                                            placeholder="Rocket League Pro Championship Gameplay (36 characters remaining)"
                                        />
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <Form.Row className="align-items-center">
                                <Col sm={12} md={12}>
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Label
                                        className="title"
                                        htmlFor="inlineFormInput"
                                        >
                                        About
                                        </Form.Label>
                                        <Form.Control as="textarea" rows="3" 
                                        placeholder="Description"/>
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <Form.Row className="align-items-center">
                                <Col sm={12} md={6} xl={3}>
                                    <Form.Group controlId="formGridState">
                                        <Form.Label className="title">Orientation</Form.Label>
                                        <Form.Control as="select" defaultValue="Choose...">
                                        <option>Choose...</option>
                                        <option>...</option>
                                        </Form.Control>
                                    </Form.Group> 
                                </Col>
                                <Col sm={12} md={6} xl={3}>
                                    <Form.Group controlId="formGridState">
                                        <Form.Label className="title">Privacy Settings</Form.Label>
                                        <Form.Control as="select" defaultValue="Choose...">
                                        <option>Choose...</option>
                                        <option>...</option>
                                        </Form.Control>
                                    </Form.Group> 
                                </Col>
                                <Col sm={12} md={6} xl={3}>
                                    <Form.Group controlId="formGridState">
                                        <Form.Label className="title">Monetize</Form.Label>
                                        <Form.Control as="select" defaultValue="Choose...">
                                        <option>Choose...</option>
                                        <option>...</option>
                                        </Form.Control>
                                    </Form.Group> 
                                </Col>
                                <Col sm={12} md={6} xl={3}>
                                    <Form.Group controlId="formGridState">
                                        <Form.Label className="title">License</Form.Label>
                                        <Form.Control as="select" defaultValue="Choose...">
                                        <option>Choose...</option>
                                        <option>...</option>
                                        </Form.Control>
                                    </Form.Group> 
                                </Col>
                            </Form.Row>
                            <Form.Row className="align-items-center">
                                <Col sm={12} md={6} xl={5}>
                                    <Form.Group controlId="exampleForm.ControlInput1">
                                        <Form.Label
                                            className="title"
                                            htmlFor="inlineFormInput"
                                        >
                                        Tags (13 Tags Remaining)
                                        </Form.Label>
                                        <Form.Control
                                            className="mb-3"
                                            id="inlineFormInput"
                                            placeholder="Gaming, PS4"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={12} md={6} xl={4}>
                                    <Form.Group controlId="exampleForm.ControlInput1">
                                        <Form.Label
                                            className="title"
                                            htmlFor="inlineFormInput"
                                        >
                                        Cast (Optional)
                                        </Form.Label>
                                        <Form.Control
                                            className="mb-3"
                                            id="inlineFormInput"
                                            placeholder="Gaming, PS4"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={12} md={6} xl={3}>
                                    <Form.Group controlId="formGridState">
                                        <Form.Label className="title">Language in Video (Optional)</Form.Label>
                                        <Form.Control as="select" defaultValue="Choose...">
                                        <option>Choose...</option>
                                        <option>...</option>
                                        </Form.Control>
                                    </Form.Group> 
                                </Col>
                            </Form.Row>
                            <h4 className="label-title">Category (you can select upto 6 categories)</h4>
                            <Form.Row>
                                <Col xs={6} sm={6} md={6} xl={2}>
                                    <Form.Group controlId="formBasicCheckbox">
                                        <ul className="list-unstyled video-edit-checkbox-list">
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Abaft"
                                                        type={type}
                                                        id={`custom-inline-${type}-1`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Brick"
                                                        type={type}
                                                        id={`custom-inline-${type}-2`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Purpose"
                                                        type={type}
                                                        id={`custom-inline-${type}-3`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Shallow"
                                                        type={type}
                                                        id={`custom-inline-${type}-4`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Spray"
                                                        type={type}
                                                        id={`custom-inline-${type}-5`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Seashore"
                                                        type={type}
                                                        id={`custom-inline-${type}-6`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Orange"
                                                        type={type}
                                                        id={`custom-inline-${type}-7`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Dynamic"
                                                        type={type}
                                                        id={`custom-inline-${type}-8`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Planes"
                                                        type={type}
                                                        id={`custom-inline-${type}-9`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Comfortable"
                                                        type={type}
                                                        id={`custom-inline-${type}-10`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                        </ul>
                                    </Form.Group>
                                </Col>
                                <Col xs={6} sm={6} md={6} xl={2}>
                                    <Form.Group controlId="formBasicCheckbox">
                                        <ul className="list-unstyled video-edit-checkbox-list">
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Leg"
                                                        type={type}
                                                        id={`custom-inline-${type}-11`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Fall"
                                                        type={type}
                                                        id={`custom-inline-${type}-12`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Pin"
                                                        type={type}
                                                        id={`custom-inline-${type}-13`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Trouble"
                                                        type={type}
                                                        id={`custom-inline-${type}-14`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Cemetery"
                                                        type={type}
                                                        id={`custom-inline-${type}-15`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Sort"
                                                        type={type}
                                                        id={`custom-inline-${type}-16`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Respect"
                                                        type={type}
                                                        id={`custom-inline-${type}-17`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Rice"
                                                        type={type}
                                                        id={`custom-inline-${type}-18`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Disagreeable"
                                                        type={type}
                                                        id={`custom-inline-${type}-19`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Minister"
                                                        type={type}
                                                        id={`custom-inline-${type}-20`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                        </ul>
                                    </Form.Group>
                                </Col>
                                <Col xs={6} sm={6} md={6} xl={2}>
                                    <Form.Group controlId="formBasicCheckbox">
                                        <ul className="list-unstyled video-edit-checkbox-list">
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Quiet"
                                                        type={type}
                                                        id={`custom-inline-${type}-21`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Rescue"
                                                        type={type}
                                                        id={`custom-inline-${type}-22`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Agonizing"
                                                        type={type}
                                                        id={`custom-inline-${type}-23`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Stitch"
                                                        type={type}
                                                        id={`custom-inline-${type}-24`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Scissors"
                                                        type={type}
                                                        id={`custom-inline-${type}-25`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Spiffy"
                                                        type={type}
                                                        id={`custom-inline-${type}-26`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Tin"
                                                        type={type}
                                                        id={`custom-inline-${type}-27`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Rambunctious"
                                                        type={type}
                                                        id={`custom-inline-${type}-28`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Sordid"
                                                        type={type}
                                                        id={`custom-inline-${type}-29`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Wise"
                                                        type={type}
                                                        id={`custom-inline-${type}-30`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                        </ul>
                                    </Form.Group>
                                </Col>
                                <Col xs={6} sm={6} md={6} xl={2}>
                                    <Form.Group controlId="formBasicCheckbox">
                                        <ul className="list-unstyled video-edit-checkbox-list">
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Boy"
                                                        type={type}
                                                        id={`custom-inline-${type}-31`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Fold"
                                                        type={type}
                                                        id={`custom-inline-${type}-32`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Moan"
                                                        type={type}
                                                        id={`custom-inline-${type}-33`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Overjoyed"
                                                        type={type}
                                                        id={`custom-inline-${type}-34`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Obsequious"
                                                        type={type}
                                                        id={`custom-inline-${type}-35`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Heat"
                                                        type={type}
                                                        id={`custom-inline-${type}-36`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Incompetent"
                                                        type={type}
                                                        id={`custom-inline-${type}-37`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Grin"
                                                        type={type}
                                                        id={`custom-inline-${type}-38`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Fat"
                                                        type={type}
                                                        id={`custom-inline-${type}-39`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Gate"
                                                        type={type}
                                                        id={`custom-inline-${type}-40`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                        </ul>
                                    </Form.Group>
                                </Col>
                                <Col xs={6} sm={6} md={6} xl={2}>
                                    <Form.Group controlId="formBasicCheckbox">
                                        <ul className="list-unstyled video-edit-checkbox-list">
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Bells"
                                                        type={type}
                                                        id={`custom-inline-${type}-41`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Symptomatic"
                                                        type={type}
                                                        id={`custom-inline-${type}-42`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Rub"
                                                        type={type}
                                                        id={`custom-inline-${type}-43`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Holiday"
                                                        type={type}
                                                        id={`custom-inline-${type}-44`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Doubtful"
                                                        type={type}
                                                        id={`custom-inline-${type}-45`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Bored"
                                                        type={type}
                                                        id={`custom-inline-${type}-46`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Hot"
                                                        type={type}
                                                        id={`custom-inline-${type}-47`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Tasteful"
                                                        type={type}
                                                        id={`custom-inline-${type}-48`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Plot"
                                                        type={type}
                                                        id={`custom-inline-${type}-49`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Grotesque"
                                                        type={type}
                                                        id={`custom-inline-${type}-50`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                        </ul>
                                    </Form.Group>
                                </Col>
                                <Col xs={6} sm={6} md={6} xl={2}>
                                    <Form.Group controlId="formBasicCheckbox">
                                        <ul className="list-unstyled video-edit-checkbox-list">
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Water"
                                                        type={type}
                                                        id={`custom-inline-${type}-51`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Vessel"
                                                        type={type}
                                                        id={`custom-inline-${type}-52`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Well-to-do"
                                                        type={type}
                                                        id={`custom-inline-${type}-53`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Unequal"
                                                        type={type}
                                                        id={`custom-inline-${type}-54`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Rhetorical"
                                                        type={type}
                                                        id={`custom-inline-${type}-55`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                            <Media as="li">
                                                {["checkbox"].map((type) => (
                                                    <div key={`custom-inline-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label="Limit"
                                                        type={type}
                                                        id={`custom-inline-${type}-56`}
                                                    />
                                                    </div>
                                                ))}
                                            </Media>
                                        </ul>
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                        </Form>
                        <Row>
                            <Col sm={12} md={12}>
                                <div className="btn-sec">
                                    <Button className="btn btn-theme-primary">Save</Button>
                                </div>
                                <div className="upload-video-edit-footer">
                                    <p className="desc">By submitting your videos to circle, 
                                    you acknowledge that you agree to circle's 
                                    <a href="#">Terms of Service</a> and <a href="#">Community Guidelines</a>. 
                                    Please be sure not to violate other's copyright or privacy rights. Learn more</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
      </>
    );
  }
}

export default UploadVideoEdit;
