import {
  FETCH_REFERRALS_START,
  FETCH_REFERRALS_SUCCESS,
  FETCH_REFERRALS_FAILURE,
  CHECK_REFERRAL_START,
  CHECK_REFERRAL_SUCCESS,
  CHECK_REFERRAL_FAILURE,
} from "./ActionConstant";

// fetch referral action.

export function fetchReferralStart(data) {
  return {
    type: FETCH_REFERRALS_START,
    data,
  };
}

export function fetchReferralSuccess(data) {
  return {
    type: FETCH_REFERRALS_SUCCESS,
    data,
  };
}

export function fetchReferralFailure(error) {
  return {
    type: FETCH_REFERRALS_FAILURE,
    error,
  };
}

// Check referral action.

export function checkReferralStart(data) {
  return {
    type: CHECK_REFERRAL_START,
    data,
  };
}

export function checkReferralSuccess(data) {
  return {
    type: CHECK_REFERRAL_SUCCESS,
    data,
  };
}

export function checkReferralFailure(error) {
  return {
    type: CHECK_REFERRAL_FAILURE,
    error,
  };
}
