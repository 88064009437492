import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Container, Row, Col } from "react-bootstrap";
import "./Playlist.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PlayListCard from "../helper/PlayListCard";
import { connect } from "react-redux";
import { fetchViewerPlaylistStart } from "../../store/actions/ViewerPlayListAction";

const PlayList = (props) => {
  useEffect(() => {
    if (props.viewerPlaylist.loading)
      props.dispatch(fetchViewerPlaylistStart());
  }, []);

  return (
    <>
      <div className="popular-playlist-sec playlist-sec">
        <Container>
          <Row>
            <Col sm={12} md={12}>
              <h2 className="section-title">Your Playlists</h2>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12}>
              <div className="section-2">
                <Row>
                  {props.viewerPlaylist.loading
                    ? ""
                    : props.viewerPlaylist.data.map((playlist) => (
                        <PlayListCard playlist={playlist} />
                      ))}
                </Row>
              </div>
            </Col>
          </Row>
          <div className="align-right">
            <Link to="#">
              <Button className="loadmore-btn">Load More</Button>
            </Link>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  viewerPlaylist: state.viewerPlaylist.viewerPlaylist,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(PlayList);
