import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  fetchHomePageDataFailure,
  fetchHomePageDataSuccess,
  fetchSearchDataFailure,
  fetchSearchDataSuccess,
  fetchSuggestionDataFailure,
  fetchSuggestionDataSuccess,
  fetchTagsDataFailure,
  fetchTagsDataSuccess,
  fetchTrendingDataFailure,
  fetchTrendingDataSuccess,
  fetchPremiumDataSuccess,
  fetchPremiumDataFailure,
} from "../actions/CoreDataAction";
import {
  fetchCategoriesFailure,
  fetchCategoriesSuccess,
} from "../actions/CategoryAction";
import {
  FETCH_CATEGORY_DATA_START,
  FETCH_HOMEPAGE_DATA_START,
  FETCH_SEARCH_DATA_START,
  FETCH_SUGGESTION_DATA_START,
  FETCH_TAGS_DATA_START,
  FETCH_TRENDING_DATA_START,
  FETCH_PREMIUM_DATA_START,
} from "../actions/ActionConstant";

function* fetchHomePageAPI() {
  try {
    const response = yield api.postMethod("home");
    if (response.data.success) {
      yield put(fetchHomePageDataSuccess(response.data.data));
    } else {
      yield put(fetchHomePageDataFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchHomePageDataFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchTrendingAPI() {
  try {
    const response = yield api.postMethod("trending");
    if (response.data.success) {
      yield put(fetchTrendingDataSuccess(response.data.data));
    } else {
      yield put(fetchTrendingDataFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchTrendingDataFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchTagsVideoAPI() {
  try {
    const inputData = yield select((state) => state.core.tags.inputData);
    const response = yield api.postMethod("spam_videos_remove", inputData);
    if (response.data.success) {
      yield put(fetchTagsDataSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(fetchTagsDataFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchTagsDataFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchCategoryDataAPI() {
  try {
    const inputData = yield select((state) => state.core.category.inputData);
    const response = yield api.postMethod("spam_reasons");
    if (response.data.success) {
      yield put(fetchCategoriesSuccess(response.data.data));
    } else {
      yield put(fetchCategoriesFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchCategoriesFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchSuggestionAPI() {
  try {
    const inputData = yield select((state) => state.core.suggestion.inputData);
    const response = yield api.postMethod("suggestions", inputData);
    if (response.data.success) {
      yield put(fetchSuggestionDataSuccess(response.data.data));
    } else {
      yield put(fetchSuggestionDataFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSuggestionDataFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchSearchDataAPI() {
  try {
    const inputData = yield select((state) => state.core.search.inputData);
    const response = yield api.postMethod("video_tapes_search", inputData);
    if (response.data.success) {
      yield put(fetchSearchDataSuccess(response.data.data));
    } else {
      yield put(fetchSearchDataFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSearchDataFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}
function* fetchPremiumDataAPI() {
  try {
    const inputData = yield select((state) => state.core.search.inputData);
    const response = yield api.postMethod("premium_videos", inputData);
    if (response.data.success) {
      yield put(fetchPremiumDataSuccess(response.data.data));
    } else {
      yield put(fetchPremiumDataFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchPremiumDataFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_HOMEPAGE_DATA_START, fetchHomePageAPI)]);
  yield all([yield takeLatest(FETCH_TRENDING_DATA_START, fetchTrendingAPI)]);
  yield all([yield takeLatest(FETCH_TAGS_DATA_START, fetchTagsVideoAPI)]);
  yield all([
    yield takeLatest(FETCH_SUGGESTION_DATA_START, fetchSuggestionAPI),
  ]);
  yield all([yield takeLatest(FETCH_SEARCH_DATA_START, fetchSearchDataAPI)]);
  yield all([
    yield takeLatest(FETCH_CATEGORY_DATA_START, fetchCategoryDataAPI),
  ]);
  yield all([yield takeLatest(FETCH_PREMIUM_DATA_START, fetchPremiumDataAPI)]);
}
