import {
  FETCH_CHANNELS_START,
  FETCH_CHANNELS_SUCCESS,
  FETCH_CHANNELS_FAILURE,
  FETCH_SINGLE_CHANNEL_START,
  FETCH_SINGLE_CHANNEL_SUCCESS,
  FETCH_SINGLE_CHANNEL_FAILURE,
  FETCH_CHANNEL_VIDEOS_START,
  FETCH_CHANNEL_VIDEOS_SUCCESS,
  FETCH_CHANNEL_VIDEOS_FAILURE,
  FETCH_CHANNEL_PLAYLISTS_START,
  FETCH_CHANNEL_PLAYLISTS_SUCCESS,
  FETCH_CHANNEL_PLAYLISTS_FAILURE,
  FETCH_SUBSCRIBED_CHANNEL_START,
  FETCH_SUBSCRIBED_CHANNEL_SUCCESS,
  FETCH_SUBSCRIBED_CHANNEL_FAILURE,
  SUBSCRIBE_UNSUBS_CHANNEL_START,
  SUBSCRIBE_UNSUBS_CHANNEL_SUCCESS,
  SUBSCRIBE_UNSUBS_CHANNEL_FAILURE,
  CREATE_CHANNEL_START,
  CREATE_CHANNEL_SUCCESS,
  CREATE_CHANNEL_FAILURE,
  DELETE_CHANNEL_START,
  DELETE_CHANNEL_SUCCESS,
  DELETE_CHANNEL_FAILURE,
  FETCH_OWNER_CHANNEL_LIST_START,
  FETCH_OWNER_CHANNEL_LIST_SUCCESS,
  FETCH_OWNER_CHANNEL_LIST_FAILURE,
  FETCH_SINGLE_CHANNEL_OWNER_START,
  FETCH_SINGLE_CHANNEL_OWNER_SUCCESS,
  FETCH_SINGLE_CHANNEL_OWNER_FAILURE,
  FETCH_SINGLE_VIDEO_CHANNEL_OWNER_START,
  FETCH_SINGLE_VIDEO_CHANNEL_OWNER_SUCCESS,
  FETCH_SINGLE_VIDEO_CHANNEL_OWNER_FAILURE,
} from "./ActionConstant";

// fetch channel action.

export function fetchChannelsStart(data) {
  return {
    type: FETCH_CHANNELS_START,
    data,
  };
}

export function fetchChannelsSuccess(data) {
  return {
    type: FETCH_CHANNELS_SUCCESS,
    data,
  };
}

export function fetchChannelsFailure(error) {
  return {
    type: FETCH_CHANNELS_FAILURE,
    error,
  };
}

// fetch single channel details  action.

export function fetchSingleChannelStart(data) {
  return {
    type: FETCH_SINGLE_CHANNEL_START,
    data,
  };
}

export function fetchSingleChannelSuccess(data) {
  return {
    type: FETCH_SINGLE_CHANNEL_SUCCESS,
    data,
  };
}

export function fetchSingleChannelFailure(error) {
  return {
    type: FETCH_SINGLE_CHANNEL_FAILURE,
    error,
  };
}

// fetch channel video action.

export function fetchChannelVideosStart(data) {
  return {
    type: FETCH_CHANNEL_VIDEOS_START,
    data,
  };
}

export function fetchChannelVideosSuccess(data) {
  return {
    type: FETCH_CHANNEL_VIDEOS_SUCCESS,
    data,
  };
}

export function fetchChannelVideosFailure(error) {
  return {
    type: FETCH_CHANNEL_VIDEOS_FAILURE,
    error,
  };
}
// fetch channel video action.

export function fetchChannelPlaylistsStart(data) {
  return {
    type: FETCH_CHANNEL_PLAYLISTS_START,
    data,
  };
}

export function fetchChannelPlaylistsSuccess(data) {
  return {
    type: FETCH_CHANNEL_PLAYLISTS_SUCCESS,
    data,
  };
}

export function fetchChannelPlaylistsFailure(error) {
  return {
    type: FETCH_CHANNEL_PLAYLISTS_FAILURE,
    error,
  };
}

// fetch subscribed channel action.

export function fetchSubscribedChannelStart(data) {
  return {
    type: FETCH_SUBSCRIBED_CHANNEL_START,
    data,
  };
}

export function fetchSubscribedChannelSuccess(data) {
  return {
    type: FETCH_SUBSCRIBED_CHANNEL_SUCCESS,
    data,
  };
}

export function fetchSubscribedChannelFailure(error) {
  return {
    type: FETCH_SUBSCRIBED_CHANNEL_FAILURE,
    error,
  };
}

// fetch single channel details  action.

export function subUnsubChannelStart(data) {
  return {
    type: SUBSCRIBE_UNSUBS_CHANNEL_START,
    data,
  };
}

export function subUnsubChannelSuccess(data) {
  return {
    type: SUBSCRIBE_UNSUBS_CHANNEL_SUCCESS,
    data,
  };
}

export function subUnsubChannelFailure(error) {
  return {
    type: SUBSCRIBE_UNSUBS_CHANNEL_FAILURE,
    error,
  };
}

// Create Channel action.

export function createChannelStart(data) {
  return {
    type: CREATE_CHANNEL_START,
    data,
  };
}

export function createChannelSuccess(data) {
  return {
    type: CREATE_CHANNEL_SUCCESS,
    data,
  };
}

export function createChannelFailure(error) {
  return {
    type: CREATE_CHANNEL_FAILURE,
    error,
  };
}

// Delete Channel action.

export function deleteChannelStart(data) {
  return {
    type: DELETE_CHANNEL_START,
    data,
  };
}

export function deleteChannelSuccess(data) {
  return {
    type: DELETE_CHANNEL_SUCCESS,
    data,
  };
}

export function deleteChannelFailure(error) {
  return {
    type: DELETE_CHANNEL_FAILURE,
    error,
  };
}

// Fetch owner channel list

export function fetchOwnerChannelListStart(data) {
  return {
    type: FETCH_OWNER_CHANNEL_LIST_START,
    data,
  };
}

export function fetchOwnerChannelListSuccess(data) {
  return {
    type: FETCH_OWNER_CHANNEL_LIST_SUCCESS,
    data,
  };
}

export function fetchOwnerChannelListFailure(error) {
  return {
    type: FETCH_OWNER_CHANNEL_LIST_FAILURE,
    error,
  };
}

// FETCH_SINGLE_CHANNEL_OWNER action

export function fetchSingleChannelOwnerStart(data) {
  return {
    type: FETCH_SINGLE_CHANNEL_OWNER_START,
    data,
  };
}

export function fetchSingleChannelOwnerSuccess(data) {
  return {
    type: FETCH_SINGLE_CHANNEL_OWNER_SUCCESS,
    data,
  };
}

export function fetchSingleChannelOwnerFailure(error) {
  return {
    type: FETCH_SINGLE_CHANNEL_OWNER_FAILURE,
    error,
  };
}

// FETCH_SINGLE_VIDEO_CHANNEL_OWNER action

export function fetchSingleVideoChannelOwnerStart(data) {
  return {
    type: FETCH_SINGLE_VIDEO_CHANNEL_OWNER_START,
    data,
  };
}

export function fetchSingleVideoChannelOwnerSuccess(data) {
  return {
    type: FETCH_SINGLE_VIDEO_CHANNEL_OWNER_SUCCESS,
    data,
  };
}

export function fetchSingleVideoChannelOwnerFailure(error) {
  return {
    type: FETCH_SINGLE_VIDEO_CHANNEL_OWNER_FAILURE,
    error,
  };
}
