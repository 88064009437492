import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Tabs,
  Tab,
  Dropdown,
  DropdownButton,
  Image,
  Table,
  Badge,
  Media,
  Modal,
} from "react-bootstrap";
import "./VideoLists.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SetPPVModal from "../helper/SetPPVModal";
import { connect } from "react-redux";
import { fetchSingleVideoChannelOwnerStart } from "../../store/actions/ChannelAction";
import ImageLoader from "../helper/ImageLoader";
import {
  approveDeclineVideoVMStart,
  deleteVideoVMStart,
  setRemovePPVVideoVMStart,
} from "../../store/actions/VideoManagementAction";
import NoResultFound from "../NoResultFound/NoResultFound";

const VideoLists = (props) => {
  useEffect(() => {
    props.dispatch(
      fetchSingleVideoChannelOwnerStart({
        channel_id: props.match.params.channel_id,
      })
    );
  }, []);

  const [ppvModal, setPpvModal] = useState(false);

  const [ppvData, setPpvData] = useState({});

  const showPPVModal = (video) => {
    setPpvData({
      type_of_subscription: video.type_of_subscription,
      video_tape_id: video.video_tape_id,
      ppv_amount: video.ppv_amount,
      channel_id: props.match.params.channel_id,
    });

    setPpvModal(true);
  };

  const closePPVModal = () => {
    setPpvModal(false);
  };

  const setPPVSubmit = (event) => {
    event.preventDefault();
    props.dispatch(setRemovePPVVideoVMStart({ ...ppvData, status: 1 }));
    setPpvModal(false);
  };

  const removePPVSubmit = (event) => {
    event.preventDefault();
    props.dispatch(setRemovePPVVideoVMStart({ ...ppvData, status: 0 }));
    setPpvModal(false);
  };

  return (
    <>
      <div className="video-list-sec">
        <Container>
          <Row>
            <Col sm={12} md={12}>
              <div className="video-list-card">
                <Row>
                  <Col sm={3} md={3} offset={8} className="mb-2 float-right">
                    <Link to={"/upload-video/" + props.match.params.channel_id}>
                      <Button className="mobile-upload-btn btn-block">
                        <Image
                          src={
                            window.location.origin + "/assets/img/video-2.png"
                          }
                          className="mr-3"
                        />
                        Upload Video
                      </Button>
                    </Link>
                  </Col>
                </Row>
                <div className="video-list-header">
                  <Form>
                    <InputGroup className="">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          <FontAwesomeIcon
                            icon={["fas", "filter"]}
                            className="icons mr-2"
                          />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl placeholder="Filter" />
                    </InputGroup>
                  </Form>
                </div>
                <Table
                  responsive="md"
                  borderedless
                  className="video-list-table"
                >
                  <thead>
                    <tr>
                      <th>
                        <div key="checkboxkey">
                          <Form.Check custom inline label="" id="checid" />
                        </div>
                        <p className="table-title-txt">Video</p>
                      </th>

                      <th>Title</th>
                      <th>Visibility</th>
                      <th>Date</th>
                      <th>Views</th>
                      <th>PPV</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.channelVideos.loading ? (
                      "Loading..."
                    ) : props.channelVideos.data.length > 0 ? (
                      props.channelVideos.data.map((video) => (
                        <tr>
                          <td>
                            <div className="video-list-details">
                              <div key="sdf">
                                <Form.Check custom inline label="" id="sdf" />
                              </div>
                              <div className="user-img-sec">
                                <ImageLoader
                                  image={video.video_image}
                                  className="user-img"
                                />

                                <div className="time-count">
                                  <h6>{video.duration}</h6>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p className="table-desc">{video.title}</p>
                          </td>
                          <td>
                            <div className="visiblity-cell">
                              <FontAwesomeIcon
                                icon={["fas", "file"]}
                                className="icons mr-2"
                              />
                              {video.publish_status === "1"
                                ? "Published"
                                : "Draft"}
                            </div>
                          </td>

                          <td>
                            <p className="table-desc">{video.publish_time}</p>
                            <p className="table-subdesc">Uploaded</p>
                          </td>
                          <td>{video.watch_count}</td>
                          <td>{video.is_pay_per_view === 1 ? "Yes" : "No"}</td>
                          <td>
                            <ul className="list-unstyled video-list-action">
                              <Media as="li">
                                <Link to="#">
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      className="list-action-dropdown"
                                      id="dropdown-basic"
                                    >
                                      Actions
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="list-dropdown-item">
                                      {/* <Dropdown.Item href="#/action-1">
                                          <FontAwesomeIcon
                                            icon={["fas", "pencil-alt"]}
                                            className="icons mr-2"
                                          />
                                          Edit
                                        </Dropdown.Item> */}
                                      <Dropdown.Item
                                        href="#/action-3"
                                        onClick={() =>
                                          props.dispatch(
                                            deleteVideoVMStart({
                                              video_tape_id:
                                                video.video_tape_id,
                                              channel_id:
                                                props.match.params.channel_id,
                                            })
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={["fas", "trash"]}
                                          className="icons mr-2"
                                        />
                                        Delete
                                      </Dropdown.Item>

                                      {/* <Dropdown.Item
                                          href="#/action-3"
                                          onClick={() =>
                                            props.dispatch(
                                              approveDeclineVideoVMStart({
                                                video_tape_id:
                                                  video.video_tape_id,
                                                channel_id:
                                                  props.match.params.channel_id,
                                              })
                                            )
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={["fas", "window-close"]}
                                            className="icons mr-2"
                                          />
                                          Decline Video
                                        </Dropdown.Item> */}
                                      {/* <Dropdown.Item href="#/action-4">
                                          <FontAwesomeIcon
                                            icon={["fas", "download"]}
                                            className="icons mr-2"
                                          />
                                          Download Video
                                        </Dropdown.Item> */}

                                      <Dropdown.Item
                                        href="#/action-5"
                                        onClick={() => showPPVModal(video)}
                                      >
                                        <FontAwesomeIcon
                                          icon={["fas", "eye"]}
                                          className="icons mr-2"
                                        />
                                        Pay Per View
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </Link>
                              </Media>
                            </ul>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <NoResultFound />
                    )}
                  </tbody>
                </Table>
                <Row>
                  <Col sm={12} md={4} className="mx-auto">
                    <div className="loadmore-btn-sec">
                      <Link to="#">
                        <Button className="loadmore-btn">Load More</Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <SetPPVModal
        ppvModal={ppvModal}
        closePPVModal={closePPVModal}
        ppvData={ppvData}
        setPpvData={setPpvData}
        setPPVSubmit={setPPVSubmit}
        removePPVSubmit={removePPVSubmit}
        setRemovePPVVM={props.setRemovePPVVM}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  singleVideoVM: state.videoVM.singleVideoVM,
  channelVideos: state.channel.singleChannelOwnerVideo,
  setRemovePPVVM: state.videoVM.setRemovePPVVM,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(VideoLists);
