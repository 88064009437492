import React from 'react';
import {
    Navbar,
    Nav,
    NavDropdown,
    Form,
    FormControl,
    Button,
    Container,
    Row,
    Col,
    InputGroup,
    Tabs,
    Tab,
    Dropdown,
    DropdownButton,
    Image,
    Media,
    Badge,
    Modal,
    ModalFooter,
  } from "react-bootstrap";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { Link } from "react-router-dom";
import ImageLoader from '../helper/ImageLoader';

const SingleVideoCommentSec = (props) => {
    return ( 
        <div className="single-video-comments-sec-tabs">
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="popular-comments"
        >
          <Row>
            <Col sm={12} md={12}>
              <Nav variant="pills">
                <Nav.Item>
                  <Nav.Link
                    eventKey="popular-comments"
                    className="padding-left-zero"
                  >
                Comments
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={12} md={12}>
                {props.comments.loading?"Loading...":props.comments.data.length>0?
              <Tab.Content>
                <Tab.Pane eventKey="popular-comments">
                  <div className="comment-tabs-sec">
                      {props.comments.data.map(comment => (
                           <div className="comments-info">
                           <Row>
                             <Col sm={12} md={2}>
                               <div className="comments-user-details">
                               <ImageLoader
                                         image={comment.picture}
                                         className="user-img"
                                       />
                             
                               </div>
                             </Col>
                             <Col sm={12} md={9}>
                               <p className="user-details">
                                 <span className="user-name">
                                   {comment.username} .
                                 </span>
                                 <span className="post-date">
                                   {comment.diff_human_time}
                                 </span>
                               </p>
                               <p className="user-description">
                                 {comment.comment}
                               </p>
                       
                             </Col>
                             <Col sm={12} md={1}>
                               <Link to="#">
                                 <Image
                                   src={window.location.origin+"/assets/img/flag.png"}
                                   className="menu-icons"
                                 />
                               </Link>
                             </Col>
                           </Row>
                         </div>
                        
                      ))}
                    <Row>
                      <Col sm={12} md={12}>
                        <div className="load-more-btn">
                          <Button className="btn-load-more">
                            Load more Comments
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Tab.Pane>
              </Tab.Content>
              :<div className="no-data-found-sec">
              <Row>
                <Col sm={12} md={12}>
                  <div className="no-data-found-img-sec">
                    <Image
                      src={
                        window.location.origin +
                        "/assets/img/no-result-found.svg"
                      }
                      className="no-data-found-img-1"
                    />
                    <p className="text-no-data-1">
                     No comments Found
                    </p>
                  </div>
                </Col>
              </Row>
            </div>}
            </Col>
          </Row>
        </Tab.Container>
      </div>
     );
}
 
export default SingleVideoCommentSec;