import React from "react"
import ContentLoader from "react-content-loader"

const ProfileLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1200}
    height={600}
    viewBox="0 0 1200 600"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="15" rx="5" ry="5" width="1200" height="56" /> 
    <rect x="0" y="453" rx="5" ry="5" width="1200" height="65" /> 
    <rect x="15" y="93" rx="0" ry="0" width="5" height="335" /> 
    <rect x="15" y="423" rx="0" ry="0" width="1165" height="5" /> 
    <rect x="1175" y="90" rx="0" ry="0" width="5" height="336" /> 
    <rect x="15" y="89" rx="0" ry="0" width="1165" height="5" /> 
    <rect x="540" y="120" rx="0" ry="0" width="188" height="11" /> 
    <rect x="45" y="160" rx="5" ry="5" width="530" height="220" /> 
    <rect x="615" y="160" rx="5" ry="5" width="530" height="220" />
  </ContentLoader>
)

export default ProfileLoader;
