import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Tabs,
  Tab,
  Dropdown,
  DropdownButton,
  Image,
} from "react-bootstrap";
import "./ForgotPassword.css";
import UseForm from "../helper/UseForm";
import { connect } from "react-redux";
import { forgotPasswordStart } from "../../store/actions/UserAction";

const ForgotPassword = (props) => {
  const { values, handleChange, handleSubmit } = UseForm(forgotPassword);

  function forgotPassword() {
    console.log(values);
    props.dispatch(forgotPasswordStart(values));
  }

  return (
    <>
      <div className="forgot-password-sec">
        <Container>
          <Row>
            <Col sm={12} md={5} xl={6} className="d-none d-sm-block">
              <div className="forgot-password-left-content">
                <h1 className="left-border"></h1>
                <h2 className="left-title">
                  Watch
                  <span className="highlighted-txt">
                    millions of videos
                  </span>{" "}
                  for free.
                </h2>
                <p className="desc">Over 6000 videos uploaded Daily</p>
              </div>
            </Col>
            <Col sm={12} md={7} xl={6}>
              <div className="forgot-password-right-content">
                <div className="forgot-password-box">
                  <div className="forgot-password-header">
                    <h3 className="title">Forgot Password</h3>
                  </div>
                  <div className="forgot-password-body">
                    <Form onSubmit={handleSubmit}>
                      <Form.Group controlId="formGroupEmail">
                        <Form.Label className="label-title">Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="sample@gmail.com"
                          name="email"
                          onChange={handleChange}
                          value={values.email}
                        />
                      </Form.Group>
                      <Row>
                        <Col sm={12} md={6} xl={7}>
                          <Button
                            className="login-btn btn-block"
                            disabled={props.users.buttonDisable}
                            type="submit"
                          >
                            {props.users.loadingButtonContent != null
                              ? props.users.loadingButtonContent
                              : "Submit"}
                          </Button>
                        </Col>
                        <Col sm={12} md={6} xl={5} className="d-none d-sm-flex">
                          <div className="forgot-password-flex-center">
                            <h4 className="or-txt">Or</h4>
                            <Link to="/signup" className="login-link">
                              Sign Up
                            </Link>
                          </div>
                        </Col>
                      </Row>
                      <div className="mobile-footer-sec-1 d-flex d-sm-none">
                        <Link to="/signup">
                          <p className="desc">Sign Up</p>
                        </Link>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  users: state.users,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ForgotPassword);
