import React from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Tabs,
  Tab,
  Dropdown,
  DropdownButton,
  Image,
  Media,
  Badge,
  Modal,
  ModalFooter,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const ShareModal = (props) => {
  return (
    <Modal
      className="share-modal"
      size="sm"
      show={props.shareModal}
      onHide={props.closeShareModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <FontAwesomeIcon
            icon={["fas", "share"]}
            className="modal-icon mr-3"
          />
          Share this video
        </Modal.Title>
        <Button className="close-icon" onClick={props.closeShareModal}>
          <FontAwesomeIcon icon={["fas", "times"]} className="modal-icon" />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div className="mobile-copy-link-sec">
          <Form className="resp-share-form">
            <Form.Group>
              <Form.Label>Copy Link:</Form.Label>
              <Form.Control
                type="email"
                placeholder="http://circle.be/oasdu4asdeJQ"
              />
            </Form.Group>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ul className="list-unstyled resp-social-share">
          <Media as="li">
            <Link to="#" className="facebook-bg">
              <FontAwesomeIcon icon={["fab", "facebook-f"]} />
            </Link>
            <span className="share-list-title">Facebook</span>
          </Media>
          <Media as="li">
            <Link to="#" className="twitter-bg">
              <FontAwesomeIcon icon={["fab", "twitter"]} />
            </Link>
            <span className="share-list-title">Twitter</span>
          </Media>
          <Media as="li">
            <Link to="#" className="google-bg">
              <FontAwesomeIcon icon={["fab", "google-plus-g"]} />
            </Link>
            <span className="share-list-title">Google Plus</span>
          </Media>
        </ul>
      </Modal.Footer>
    </Modal>
  );
};

export default ShareModal;
