import React from "react"
import ContentLoader from "react-content-loader"

const NotificationViewAllLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1200}
    height={700}
    viewBox="0 0 1200 700"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="15" rx="5" ry="5" width="1200" height="56" /> 
    <rect x="0" y="120" rx="5" ry="5" width="1200" height="89" /> 
    <rect x="0" y="240" rx="5" ry="5" width="1200" height="89" /> 
    <rect x="0" y="360" rx="5" ry="5" width="1200" height="89" />
    <rect x="1020" y="500" rx="5" ry="5" width="180" height="30" /> 
    <rect x="0" y="576" rx="10" ry="10" width="1200" height="65" />
  </ContentLoader>
)

export default NotificationViewAllLoader;