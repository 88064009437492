import React from "react"
import ContentLoader from "react-content-loader"

const EditProfileLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1350}
    height={800}
    viewBox="0 0 1200 800"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="15" rx="5" ry="5" width="1200" height="56" /> 
    <rect x="0" y="731" rx="5" ry="5" width="1200" height="65" /> 
    <rect x="14" y="93" rx="0" ry="0" width="5" height="606" /> 
    <rect x="14" y="694" rx="0" ry="0" width="1165" height="5" /> 
    <rect x="1178" y="90" rx="0" ry="0" width="5" height="609" /> 
    <rect x="14" y="89" rx="0" ry="0" width="1165" height="5" /> 
    <rect x="540" y="120" rx="0" ry="0" width="188" height="11" /> 
    <rect x="42" y="294" rx="5" ry="5" width="540" height="220" /> 
    <rect x="612" y="160" rx="5" ry="5" width="540" height="500" />
  </ContentLoader>
)

export default EditProfileLoader;