import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Image,
  Modal,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createViewerPlaylistStart } from "../../store/actions/ViewerPlayListAction";

const EditPlayListModal = (props) => {
  const [title, setTitle] = useState("");

  useEffect(() => {
    setTitle(props.playlist.title);
  }, []);

  const updatePlaylist = (event) => {
    event.preventDefault();
    props.dispatch(
      createViewerPlaylistStart({
        title: title,
        playlist_id: props.playlist.playlist_id,
        channel_id: props.playlist.channel_id,
      })
    );
    setTimeout(() => {
      props.closeEditModal();
    }, 1000);
  };

  return (
    <Modal
      className="edit-playlist-modal"
      size="md"
      show={props.editModal}
      onHide={props.closeEditModal}
    >
      <Form onSubmit={updatePlaylist}>
        <Modal.Header closeButton>
          <Modal.Title>Edit PlayList</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="exampleForm.ControlInput1" className="mb-0">
            <Form.Control
              type="email"
              placeholder="PlayList name"
              value={title}
              onChange={(event) => setTitle(event.currentTarget.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Link to="#">
            <Button className="save-btn" type="submit" onClick={updatePlaylist}>
              Save
            </Button>
          </Link>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(EditPlayListModal);
