import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  fetchBellNotificationSuccess,
  fetchBellNotificationFailure,
  updateNotificationSuccess,
  updateNotificationFailure,
  fetchNotificationCountSuccess,
  fetchNotificationCountFailure,
} from "../actions/BellNotificationAction";
import {
  FETCH_BELL_NOTIFICATION_START,
  UPDATE_BELL_NOTIFICATION_START,
  FETCH_NOTIFICATION_COUNT_START,
} from "../actions/ActionConstant";

function* fetchBellNotificationAPI() {
  try {
    const response = yield api.postMethod("bell_notifications");
    if (response.data.success) {
      yield put(fetchBellNotificationSuccess(response.data.data));
    } else {
      yield put(fetchBellNotificationFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchBellNotificationFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* updateNotificationAPI() {
  try {
    const inputData = yield select(
      (state) => state.notification.updateNot.inputData
    );
    const response = yield api.postMethod(
      "bell_notifications_update",
      inputData
    );
    if (response.data.success) {
      yield put(updateNotificationSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(updateNotificationFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(updateNotificationFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchNotificationCountAPI() {
  try {
    const inputData = yield select(
      (state) => state.notification.notiCount.inputData
    );
    const response = yield api.postMethod(
      "bell_notifications_count",
      inputData
    );
    if (response.data.success) {
      yield put(fetchNotificationCountSuccess(response.data));
    } else {
      yield put(fetchNotificationCountFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchNotificationCountFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_BELL_NOTIFICATION_START, fetchBellNotificationAPI),
  ]);
  yield all([
    yield takeLatest(UPDATE_BELL_NOTIFICATION_START, updateNotificationAPI),
  ]);
  yield all([
    yield takeLatest(FETCH_NOTIFICATION_COUNT_START, fetchNotificationCountAPI),
  ]);
}
