import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Notify } from "react-redux-notify";
import SideBarIndex from "./sidebar/SideBarIndex";
import HeaderIndex from "./header/HeaderIndex";
import FooterIndex from "./footer/FooterIndex";
import "./MainLayout.css";
import { Form,} from "react-bootstrap";

class MainLayout extends Component {

  constructor(props) {
    super(props);
    this.addActiveClass = this.addActiveClass.bind(this);
    this.state = {
      active: false,
    };
  }
  addActiveClass() {
    const currentState = this.state.active;
    this.setState({
      active: !currentState
    });
  };
  
  render() {
    return (
      <>
      <div className={
        this.state.active ? 'dark-mode-bg' : null}>
        <div className="dark-mode">
          {
            this.props.text
          }
          <div className="app-mode-sec">
            <Form className="dark-mode-form">
              <Form.Label>Dark Mode</Form.Label>
              <Form.Check
                type="switch"
                id="custom-switch"
                label=""
                onClick = {  this.addActiveClass }
              />
            </Form>
          </div>
        </div>
        <HeaderIndex {...this.props}/>
        <Notify position="TopRight" />
        <div className="main-content">{React.cloneElement(this.props.children)}</div>
        <FooterIndex />
      </div>
      </>
    );
  }
}

export default MainLayout;
