import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  fetchChannelPlaylistSuccess,
  fetchChannelPlaylistFailure,
  createChannelPlaylistSuccess,
  createChannelPlaylistFailure,
  addVideoChannelPlaylistSuccess,
  addVideoChannelPlaylistFailure,
  removeVideoChannelPlaylistSuccess,
  removeVideoChannelPlaylistFailure,
  fetchSingleChannelPlaylistSuccess,
  fetchSingleChannelPlaylistFailure,
  deleteChannelPlaylistSuccess,
  deleteChannelPlaylistFailure,
} from "../actions/ChannelPlaylistAction";
import {
  FETCH_CHANNEL_PLAYLIST_START,
  CREATE_CHANNEL_PLAYLIST_START,
  ADD_VIDEO_CHANNEL_PLAYLIST_START,
  FETCH_SINGLE_CHANNEL_PLAYLIST_START,
  DELETE_CHANNEL_PLAYLIST_START,
  REMOVE_VIDEO_CHANNEL_PLAYLIST_START,
} from "../actions/ActionConstant";

function* fetchChannelPlaylistAPI() {
  try {
    const inputData = yield select(
      (state) => state.channelPlaylist.channelPlaylist.inputData
    );
    const response = yield api.postMethod("channel_playlists", inputData);
    if (response.data.success) {
      yield put(fetchChannelPlaylistSuccess(response.data.data));
    } else {
      yield put(fetchChannelPlaylistFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchChannelPlaylistFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* createChannelPlaylistAPI() {
  try {
    const inputData = yield select(
      (state) => state.channelPlaylist.createCP.inputData
    );
    const response = yield api.postMethod("spam_videos_add", inputData);
    if (response.data.success) {
      yield put(createChannelPlaylistSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(createChannelPlaylistFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(createChannelPlaylistFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* addVideoCPApi() {
  try {
    const inputData = yield select(
      (state) => state.channelPlaylist.addVideoCP.inputData
    );
    const response = yield api.postMethod("spam_videos_remove", inputData);
    if (response.data.success) {
      yield put(addVideoChannelPlaylistSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(addVideoChannelPlaylistFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(addVideoChannelPlaylistFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* removeVideoCPApi() {
  try {
    const inputData = yield select(
      (state) => state.channelPlaylist.removeVideoCP.inputData
    );
    const response = yield api.postMethod("spam_reasons");
    if (response.data.success) {
      yield put(removeVideoChannelPlaylistSuccess(response.data.data));
    } else {
      yield put(removeVideoChannelPlaylistFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(removeVideoChannelPlaylistFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchSingleCPApi() {
  try {
    const inputData = yield select(
      (state) => state.channelPlaylist.singleCP.inputData
    );
    const response = yield api.postMethod("spam_videos_remove", inputData);
    if (response.data.success) {
      yield put(fetchSingleChannelPlaylistSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(fetchSingleChannelPlaylistFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSingleChannelPlaylistFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* deleteCPApi() {
  try {
    const inputData = yield select(
      (state) => state.channelPlaylist.deleteCP.inputData
    );
    const response = yield api.postMethod("spam_videos_remove", inputData);
    if (response.data.success) {
      yield put(deleteChannelPlaylistSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(deleteChannelPlaylistFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(deleteChannelPlaylistFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_CHANNEL_PLAYLIST_START, fetchChannelPlaylistAPI),
  ]);
  yield all([
    yield takeLatest(CREATE_CHANNEL_PLAYLIST_START, createChannelPlaylistAPI),
  ]);
  yield all([
    yield takeLatest(ADD_VIDEO_CHANNEL_PLAYLIST_START, addVideoCPApi),
  ]);
  yield all([
    yield takeLatest(FETCH_SINGLE_CHANNEL_PLAYLIST_START, fetchSingleCPApi),
  ]);
  yield all([yield takeLatest(DELETE_CHANNEL_PLAYLIST_START, deleteCPApi)]);
  yield all([
    yield takeLatest(REMOVE_VIDEO_CHANNEL_PLAYLIST_START, removeVideoCPApi),
  ]);
}
