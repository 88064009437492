import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Container, Row, Col, Image } from "react-bootstrap";
import "./NotificationViewAll.css";
import { fetchBellNotificationStart } from "../../store/actions/BellNotificationAction";
import { connect } from "react-redux";
import NotificationViewAllLoader from "../Loader/NotificationViewAllLoader";
import NoResultFound from "../NoResultFound/NoResultFound";

const NotificationViewAll = (props) => {
  useEffect(() => {
    if (props.notification.notification.loading)
      props.dispatch(fetchBellNotificationStart());
  }, []);

  const { notification } = props;

  return (
    <>
      <div className="notification-viewall-sec">
        <Container>
          <Row>
            <Col sm={12} md={12}>
              <h2 className="section-title">Notification View All</h2>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12}>
              {notification.notification.loading
                ? <NotificationViewAllLoader />
                : notification.notification.data.length > 0
                ? notification.notification.data.map((noti) => (
                    <Link to="#">
                      <div className="notify-body notification-viewall-card">
                        <div className="user-img-sec">
                          <Image src={noti.picture} className="user-img" />
                        </div>
                        <div className="notify-content">
                          <h5>{noti.message}</h5>
                          <p>{noti.created}</p>
                        </div>
                      </div>
                    </Link>
                  ))
                : <NoResultFound/>}
            </Col>
          </Row>
          <div className="align-right">
            <Link to="#">
              <Button className="loadmore-btn">Load More</Button>
            </Link>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  notification: state.notification,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(NotificationViewAll);
