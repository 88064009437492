import React from "react"
import ContentLoader from "react-content-loader"

const ReferralsLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1250}
    height={600}
    viewBox="0 0 1200 600"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="15" rx="5" ry="5" width="1200" height="56" /> 
    <rect x="0" y="104" rx="5" ry="5" width="1200" height="201" /> 
    <rect x="1010" y="330" rx="0" ry="0" width="77" height="32" /> 
    <rect x="1100" y="330" rx="0" ry="0" width="104" height="32" /> 
    <rect x="0" y="375" rx="0" ry="0" width="1200" height="93" /> 
    <rect x="0" y="494" rx="0" ry="0" width="1200" height="65" />
  </ContentLoader>
)

export default ReferralsLoader;
