import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  fetchLiveTvFailure,
  fetchLiveTvSuccess,
  fetchSingleLiveTvFailure,
  fetchSingleLiveTvSuccess,
} from "../actions/LiveTvAction";
import {
  FETCH_LIVETV_START,
  FETCH_SINGLE_LIVETV_START,
} from "../actions/ActionConstant";

function* fetchLiveTvAPI() {
  try {
    const inputData = yield select(
      (state) => state.livetv.singleLivetv.inputData
    );
    const response = yield api.postMethod("custom_live_videos", inputData);
    if (response.data.success) {
      yield put(fetchLiveTvSuccess(response.data.data));
    } else {
      yield put(fetchLiveTvFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchLiveTvFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchSingleLiveTvAPI() {
  try {
    const inputData = yield select(
      (state) => state.livetv.singleLivetv.inputData
    );
    const response = yield api.postMethod("custom_live_videos_view", inputData);
    if (response.data.success) {
      yield put(fetchSingleLiveTvSuccess(response.data.data));
    } else {
      yield put(fetchSingleLiveTvFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSingleLiveTvFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_LIVETV_START, fetchLiveTvAPI)]);

  yield all([
    yield takeLatest(FETCH_SINGLE_LIVETV_START, fetchSingleLiveTvAPI),
  ]);
}
