import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Tabs,
  Tab,
  Dropdown,
  DropdownButton,
  Image,
  Table,
  Badge,
  Media,
} from "react-bootstrap";
import "./Redeems.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  fetchRedeemsStart,
  sendRedeemStart,
  cancelRedeemStart,
} from "../../store/actions/RedeemAction";
import { connect } from "react-redux";
import RedeemsLoader from "../Loader/RedeemsLoader";

const Redeems = (props) => {
  useEffect(() => {
    if (props.response.redeems.loading) props.dispatch(fetchRedeemsStart());
  }, []);

  const sendRedeemRequest = (event) => {
    event.preventDefault();
    props.dispatch(sendRedeemStart());
  };

  const cancelRedeemRequest = (event, data) => {
    event.preventDefault();
    props.dispatch(
      cancelRedeemStart({
        redeem_request_id: data.redeem_request_id,
      })
    );
  };

  const redeemResponse = props.response.redeems;
  return (
    <>
      {redeemResponse.loading ? (
        <RedeemsLoader />
      ) : (
        <div className="redeems-sec">
          <Container>
            <h2 className="section-title">Redeems</h2>
            <Row>
              <Col sm={12} md={4}>
                <div className="redeems-box">
                  <div className="price-box">
                    <h3 className="price-title">
                      {redeemResponse.data.redeems.remaining_formatted}
                    </h3>
                  </div>
                  <p className="desc">
                    Hey, Do you want to redeem your balance? If so, please click
                    “send Redeem” button. Admin will process your payment ASAP.
                  </p>
                  {redeemResponse.data.redeems.remaining >=
                  redeemResponse.data.redeems.minimum_redeem ? (
                    <Link to="#" onClick={(event) => sendRedeemRequest(event)}>
                      <Button className="submit-btn">Send Redeem</Button>
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
                <div className="redeems-box-1">
                  <p className="sub-desc">
                    Your Paypal Email:{" "}
                    <b>{redeemResponse.data.redeems.paypal_email}</b>
                  </p>
                  <p className="text-muted">
                    The admin will checkout the requested amount to the paypal
                    email address
                  </p>

                  <Link to="/profile">
                    <Button className="submit-btn">Click Here Update</Button>
                  </Link>
                </div>
              </Col>
              <Col sm={12} md={8}>
                {redeemResponse.loading ? (
                  "Loading..."
                ) : (
                  <div className="redeems-box-3">
                    <div className="redeems-sec-card">
                      {redeemResponse.data.redeems_history.length > 0 ? (
                        <ul className="list-unstyled">
                          {redeemResponse.data.redeems_history.map(
                            (redeemHistory) => (
                              <Media as="li" className="redeems-list-card">
                                <Row className="w-100">
                                  <Col sm={12} md={6}>
                                    <p className="list-desc">
                                      Sent Date : {redeemHistory.requested_date}
                                    </p>
                                    <h4 className="list-title">
                                      Redeem Amount
                                      <span className="list-amount">
                                        {redeemHistory.request_amount_formatted}
                                      </span>
                                    </h4>
                                    <p className="list-desc">
                                      Paid Amount:{" "}
                                      {redeemHistory.paid_amount_formatted}
                                    </p>
                                    <p className="list-desc">
                                      Paid Date:{" "}
                                      {redeemHistory.paid_amount > 0
                                        ? redeemHistory.paid_date
                                        : "-"}
                                    </p>
                                    <p className="list-desc">
                                      Status : {redeemHistory.status_text}
                                    </p>
                                    {redeemHistory.cancel_btn_status == 1 ? (
                                      <Link
                                        to="#"
                                        onClick={(event) =>
                                          cancelRedeemRequest(
                                            event,
                                            redeemHistory
                                          )
                                        }
                                      >
                                        <Button className="cancel-btn">
                                          Cancel
                                        </Button>
                                      </Link>
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                  <Col sm={12} md={6}>
                                    <div className="redeems-status-sec">
                                      <Image
                                        src={redeemHistory.status_picture}
                                        className="list-status-img"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Media>
                            )
                          )}
                          <div className="align-right">
                            <Link to="#">
                              <Button className="loadmore-btn">
                                Load More
                              </Button>
                            </Link>
                          </div>
                        </ul>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  response: state.redeem,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Redeems);
