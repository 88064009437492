import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Form,
  FormControl,
  Container,
  Row,
  Col,
  InputGroup,
  Badge,
  Image,
} from "react-bootstrap";
import "./MyPlan.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import {
  fetchMySubscriptionStart,
  enableSubscriptionAutoRenewalStart,
  disableSubscriptionAutoRenewalStart,
} from "../../store/actions/SubscriptionAction";
import SubscriptionList from "../SubscriptionList/SubscriptionList";
import MyPlansLoader from "../Loader/MyPlansLoader";
import CancelModal from "../helper/CancelModal";

class MyPlan extends Component {
  state = {
    cancelModal: false,
    subscription: "",
  };
  componentDidMount() {
    if (this.props.subscriptions.mySubscription.loading) {
      this.props.dispatch(fetchMySubscriptionStart());
    }
  }

  enableSubscriptionAutoRenewal = (event) => {
    event.preventDefault();
    this.props.dispatch(enableSubscriptionAutoRenewalStart());
  };

  disableSubscriptionAutoRenewal = (event, subscription) => {
    event.preventDefault();
    this.setState({ cancelModal: true, subscription: subscription });
  };

  showCancelModal = () => {
    this.setState({ cancelModal: true });
  };

  closeCancelModal = () => {
    this.setState({ cancelModal: false });
  };

  render() {
    const { mySubscription } = this.props.subscriptions;
    return (
      <>
        <div className="my-plan-sec">
          <Container>
            <h2 className="section-title">My Plans</h2>

            <Row>
              <Col sm={12} md={12}>
                <Row>
                  <Col sm={12} md={6}></Col>
                  <Col sm={12} md={6}>
                    <Link
                      to={"/plans"}
                      className="btn btn-success btn-sm float-right"
                    >
                      View Plans
                    </Link>
                    {/* <Form className="my-plan-sec-sub-header">
                      <div className="my-plan-custom-search">
                        <InputGroup>
                          <FormControl
                            placeholder="Search"
                            aria-describedby="basic-addon2"
                          />
                          <InputGroup.Append>
                            <InputGroup.Text id="basic-addon2">
                              <FontAwesomeIcon icon="search" />
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </div>
                    </Form> */}
                  </Col>
                </Row>
                {mySubscription.loading ? (
                  <MyPlansLoader />
                ) : mySubscription.data.length > 0 ? (
                  <>
                    {mySubscription.data.map((subscription) => (
                      <div className="my-plan-sec-card">
                        <Row>
                          <Col sm={12} md={3} xl={2}>
                            <div className="price-sec">
                              <h1 className="title">{subscription.title}</h1>
                              <h2 className="sub-title">
                                <span className="amount">
                                  {subscription.subscription_amount_formatted}
                                </span>
                              </h2>
                              <p className="period">
                                {subscription.plan_formatted}
                              </p>
                            </div>
                          </Col>
                          <Col sm={12} md={3} xl={4}>
                            <div className="amount-details">
                              <h4 className="amount-title">Payment Id</h4>
                              <p className="amount-desc">
                                {subscription.payment_id}
                              </p>
                              <h4 className="amount-title">
                                Is Coupon Applied
                              </h4>
                              <p className="amount-desc">
                                {subscription.is_coupon_applied == 1
                                  ? "Yes"
                                  : "No"}
                              </p>
                            </div>
                          </Col>
                          <Col sm={12} md={3} xl={3}>
                            <div className="amount-details">
                              <h4 className="amount-title">Payment Mode</h4>
                              <p className="amount-desc">
                                {subscription.payment_mode
                                  ? subscription.payment_mode
                                  : "-"}
                              </p>
                              <h4 className="amount-title">Paid Amount</h4>
                              <p className="amount-desc">
                                {subscription.amount_formatted}
                              </p>
                            </div>
                          </Col>
                          <Col sm={12} md={3} xl={3}>
                            <div className="expiry-btn-sec">
                              <Badge className="expiry-btn">
                                <FontAwesomeIcon
                                  icon={["fas", "clock"]}
                                  className="mr-2"
                                />{" "}
                                {subscription.expiry_date}
                              </Badge>
                            </div>
                            {subscription.is_autorenewal == 1 ? (
                              subscription.is_cancelled == 1 ? (
                                <div className="cancel-btn-sec">
                                  <button
                                    className="cancel-btn"
                                    onClick={(event) =>
                                      this.enableSubscriptionAutoRenewal(event)
                                    }
                                  >
                                    Enable Renewal
                                  </button>
                                </div>
                              ) : (
                                <div className="cancel-btn-sec">
                                  <button
                                    className="cancel-btn"
                                    onClick={(event) =>
                                      this.disableSubscriptionAutoRenewal(
                                        event,
                                        subscription
                                      )
                                    }
                                  >
                                    Cancel Renewal
                                  </button>
                                </div>
                              )
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="no-data-found-sec">
                    <Row>
                      <Col sm={12} md={12}>
                        <div className="no-data-found-img-sec">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/img/no-result-found.svg"
                            }
                            className="no-data-found-img"
                          />
                          <p className="text-no-data">No Result Found</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </div>
        <CancelModal
          showCancelModal={this.showCancelModal}
          closeCancelModal={this.closeCancelModal}
          cancelModal={this.state.cancelModal}
          subscription={this.state.subscription}
        />
      </>
    );
  }
}

const mapStateToPros = (state) => ({
  subscriptions: state.subscriptions,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(MyPlan);
