import React from "react"
import ContentLoader from "react-content-loader"

const BrowseCategoriesLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1350}
    height={1100}
    viewBox="0 0 1200 1100"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="8" rx="5" ry="5" width="1200" height="56" /> 
    <rect x="0" y="80" rx="0" ry="0" width="108" height="9" /> 
    <rect x="135" y="80" rx="0" ry="0" width="108" height="9" /> 
    <rect x="1090" y="80" rx="0" ry="0" width="108" height="9" /> 
    <rect x="255" y="111" rx="10" ry="10" width="180" height="148" /> 
    <rect x="0" y="111" rx="10" ry="10" width="180" height="148" /> 
    <rect x="510" y="111" rx="10" ry="10" width="180" height="148" /> 
    <rect x="765" y="111" rx="10" ry="10" width="180" height="148" /> 
    <rect x="1020" y="112" rx="10" ry="10" width="180" height="148" /> 
    <rect x="0" y="296" rx="0" ry="0" width="69" height="9" /> 
    <rect x="0" y="277" rx="0" ry="0" width="50" height="9" /> 
    <rect x="256" y="293" rx="0" ry="0" width="69" height="9" /> 
    <rect x="256" y="274" rx="0" ry="0" width="50" height="9" /> 
    <rect x="512" y="290" rx="0" ry="0" width="69" height="9" /> 
    <rect x="512" y="271" rx="0" ry="0" width="50" height="9" /> 
    <rect x="766" y="291" rx="0" ry="0" width="69" height="9" /> 
    <rect x="766" y="272" rx="0" ry="0" width="50" height="9" /> 
    <rect x="1020" y="291" rx="0" ry="0" width="69" height="9" /> 
    <rect x="1020" y="272" rx="0" ry="0" width="50" height="9" /> 
    <rect x="0" y="321" rx="10" ry="10" width="180" height="148" /> 
    <rect x="255" y="320" rx="10" ry="10" width="180" height="148" /> 
    <rect x="510" y="320" rx="10" ry="10" width="180" height="148" /> 
    <rect x="765" y="320" rx="10" ry="10" width="180" height="148" /> 
    <rect x="1020" y="321" rx="10" ry="10" width="180" height="148" /> 
    <rect x="0" y="505" rx="0" ry="0" width="69" height="9" /> 
    <rect x="0" y="486" rx="0" ry="0" width="50" height="9" /> 
    <rect x="256" y="502" rx="0" ry="0" width="69" height="9" /> 
    <rect x="256" y="483" rx="0" ry="0" width="50" height="9" /> 
    <rect x="512" y="499" rx="0" ry="0" width="69" height="9" /> 
    <rect x="512" y="480" rx="0" ry="0" width="50" height="9" /> 
    <rect x="766" y="500" rx="0" ry="0" width="69" height="9" /> 
    <rect x="766" y="481" rx="0" ry="0" width="50" height="9" /> 
    <rect x="1020" y="500" rx="0" ry="0" width="69" height="9" /> 
    <rect x="1020" y="481" rx="0" ry="0" width="50" height="9" /> 
    <rect x="0" y="963" rx="5" ry="5" width="1200" height="65" /> 
    <rect x="255" y="532" rx="10" ry="10" width="180" height="148" /> 
    <rect x="0" y="532" rx="10" ry="10" width="180" height="148" /> 
    <rect x="510" y="532" rx="10" ry="10" width="180" height="148" /> 
    <rect x="765" y="532" rx="10" ry="10" width="180" height="148" /> 
    <rect x="1020" y="533" rx="10" ry="10" width="180" height="148" /> 
    <rect x="0" y="717" rx="0" ry="0" width="69" height="9" /> 
    <rect x="0" y="698" rx="0" ry="0" width="50" height="9" /> 
    <rect x="256" y="714" rx="0" ry="0" width="69" height="9" /> 
    <rect x="256" y="695" rx="0" ry="0" width="50" height="9" /> 
    <rect x="512" y="711" rx="0" ry="0" width="69" height="9" /> 
    <rect x="512" y="692" rx="0" ry="0" width="50" height="9" /> 
    <rect x="766" y="712" rx="0" ry="0" width="69" height="9" /> 
    <rect x="766" y="693" rx="0" ry="0" width="50" height="9" /> 
    <rect x="1020" y="712" rx="0" ry="0" width="69" height="9" /> 
    <rect x="1020" y="693" rx="0" ry="0" width="50" height="9" /> 
    <rect x="0" y="742" rx="10" ry="10" width="180" height="148" /> 
    <rect x="255" y="743" rx="10" ry="10" width="180" height="148" /> 
    <rect x="510" y="741" rx="10" ry="10" width="180" height="148" /> 
    <rect x="765" y="741" rx="10" ry="10" width="180" height="148" /> 
    <rect x="1020" y="742" rx="10" ry="10" width="180" height="148" /> 
    <rect x="0" y="926" rx="0" ry="0" width="69" height="9" /> 
    <rect x="0" y="907" rx="0" ry="0" width="50" height="9" /> 
    <rect x="256" y="923" rx="0" ry="0" width="69" height="9" /> 
    <rect x="256" y="904" rx="0" ry="0" width="50" height="9" /> 
    <rect x="512" y="920" rx="0" ry="0" width="69" height="9" /> 
    <rect x="512" y="901" rx="0" ry="0" width="50" height="9" /> 
    <rect x="766" y="921" rx="0" ry="0" width="69" height="9" /> 
    <rect x="766" y="902" rx="0" ry="0" width="50" height="9" /> 
    <rect x="1020" y="921" rx="0" ry="0" width="69" height="9" /> 
    <rect x="1020" y="902" rx="0" ry="0" width="50" height="9" />
  </ContentLoader>
)

export default BrowseCategoriesLoader;