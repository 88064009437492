import React from "react"
import ContentLoader from "react-content-loader"

const BrowseChannelLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1200}
    height={1300}
    viewBox="0 0 1200 1300"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="8" rx="5" ry="5" width="1200" height="56" /> 
    <rect x="0" y="85" rx="0" ry="0" width="80" height="9" /> 
    <rect x="100" y="85" rx="0" ry="0" width="70" height="9" /> 
    <rect x="180" y="85" rx="0" ry="0" width="65" height="9" /> 
    <rect x="255" y="85" rx="0" ry="0" width="60" height="9" /> 
    <rect x="325" y="85" rx="0" ry="0" width="40" height="9" /> 
    <rect x="1015" y="75" rx="10" ry="10" width="184" height="24" /> 
    <rect x="0" y="111" rx="0" ry="0" width="1200" height="3" /> 
    <rect x="0" y="124" rx="0" ry="0" width="103" height="8" /> 
    <rect x="1136" y="124" rx="0" ry="0" width="63" height="8" /> 
    <rect x="0" y="145" rx="5" ry="5" width="180" height="150" /> 
    <rect x="350" y="145" rx="5" ry="5" width="180" height="150" /> 
    <rect x="680" y="145" rx="5" ry="5" width="180" height="150" /> 
    <rect x="1020" y="145" rx="5" ry="5" width="180" height="150" /> 
    <rect x="0" y="316" rx="5" ry="5" width="180" height="150" /> 
    <rect x="350" y="316" rx="5" ry="5" width="180" height="150" /> 
    <rect x="680" y="316" rx="5" ry="5" width="180" height="150" /> 
    <rect x="1020" y="316" rx="5" ry="5" width="180" height="150" /> 
    <rect x="0" y="486" rx="0" ry="0" width="1200" height="3" /> 
    <rect x="0" y="499" rx="0" ry="0" width="103" height="8" /> 
    <rect x="1136" y="499" rx="0" ry="0" width="63" height="8" /> 
    <rect x="0" y="520" rx="5" ry="5" width="180" height="150" /> 
    <rect x="350" y="520" rx="5" ry="5" width="180" height="150" /> 
    <rect x="680" y="520" rx="5" ry="5" width="180" height="150" /> 
    <rect x="1020" y="520" rx="5" ry="5" width="180" height="150" /> 
    <rect x="0" y="691" rx="5" ry="5" width="180" height="150" /> 
    <rect x="350" y="691" rx="5" ry="5" width="180" height="150" /> 
    <rect x="680" y="691" rx="5" ry="5" width="180" height="150" /> 
    <rect x="1020" y="691" rx="5" ry="5" width="180" height="150" /> 
    <rect x="0" y="860" rx="0" ry="0" width="1200" height="3" /> 
    <rect x="0" y="873" rx="0" ry="0" width="103" height="8" /> 
    <rect x="1136" y="873" rx="0" ry="0" width="63" height="8" /> 
    <rect x="0" y="894" rx="5" ry="5" width="180" height="150" /> 
    <rect x="350" y="894" rx="5" ry="5" width="180" height="150" /> 
    <rect x="680" y="894" rx="5" ry="5" width="180" height="150" /> 
    <rect x="1020" y="894" rx="5" ry="5" width="180" height="150" /> 
    <rect x="0" y="1065" rx="5" ry="5" width="180" height="150" /> 
    <rect x="350" y="1065" rx="5" ry="5" width="180" height="150" /> 
    <rect x="680" y="1065" rx="5" ry="5" width="180" height="150" /> 
    <rect x="1020" y="1065" rx="5" ry="5" width="180" height="150" /> 
    <rect x="0" y="1231" rx="0" ry="0" width="1200" height="63" />
  </ContentLoader>
)

export default BrowseChannelLoader;
