import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  fetchRedeemsSuccess,
  fetchRedeemsFailure,
  sendRedeemFailure,
  sendRedeemSuccess,
  cancelRedeemSuccess,
  cancelRedeemFailure,
} from "../actions/RedeemAction";
import {
  FETCH_REDEEMS_START,
  SEND_REDEEM_START,
  CANCEL_REDEEM_START,
} from "../actions/ActionConstant";

function* fetchRedeemAPI() {
  try {
    const response = yield api.postMethod("redeems");
    if (response.data.success) {
      yield put(fetchRedeemsSuccess(response.data.data));
    } else {
      yield put(fetchRedeemsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchRedeemsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* sendRedeemAPI() {
  try {
    const inputData = yield select(
      (state) => state.redeem.sendRedeem.inputData
    );
    const response = yield api.postMethod("redeems_request_send", inputData);
    if (response.data.success) {
      yield put(sendRedeemSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.reload();
    } else {
      yield put(sendRedeemFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(sendRedeemFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* cancelRedeemAPI() {
  try {
    const inputData = yield select(
      (state) => state.redeem.cancelRedeem.inputData
    );
    const response = yield api.postMethod("redeems_request_cancel", inputData);
    if (response.data.success) {
      yield put(cancelRedeemSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.reload();
    } else {
      yield put(cancelRedeemFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(cancelRedeemFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_REDEEMS_START, fetchRedeemAPI)]);
  yield all([yield takeLatest(SEND_REDEEM_START, sendRedeemAPI)]);
  yield all([yield takeLatest(CANCEL_REDEEM_START, cancelRedeemAPI)]);
}
