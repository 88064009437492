import { combineReducers } from "redux";

import UserReducer from "./UserReducer";
import ChangePasswordReducer from "./ChangePasswordReducer";
import notifyReducer from "react-redux-notify";
import SubscriptionReducer from "./SubscriptionReducer";
import CardsReducer from "./CardsReducer";
import CommonReducer from "./CommonReducer";
import ErrorReducer from "./ErrorReducer";
import PageReducer from "./PageReducer";
import RedeemReducer from "./RedeemReducer";
import ReferralReducer from "./ReferralReducer";
import WishListReducer from "./WishListReducer";
import SpamReducer from "./SpamReducer";
import VideoHistoryReducer from "./VideoHistoryReducer";
import ChannelReducer from "./ChannelReducer";
import BellNotificationReducer from "./BellNotificationReducer";
import ChannelPlayListReducer from "./ChannelPlayListReducer";
import ViewerPlayListReducer from "./ViewerPlayListReducer";
import VideoManagementReducer from "./VideoManagementReducer";
import PPVReducer from "./PPVReducer";
import SingleVideoReducer from "./SingleVideoReducer";
import TagsReducer from "./TagsReducer";
import CategoryReducer from "./CategoryReducer";
import CoreDataReducer from "./CoreDataReducer";
import LiveTvReducer from "./LiveTvReducer";
import LiveStreamingReducer from "./LiveStreamingReducer";

export default combineReducers({
  users: UserReducer,
  changePassword: ChangePasswordReducer,
  notifications: notifyReducer,
  subscriptions: SubscriptionReducer,
  cards: CardsReducer,
  common: CommonReducer,
  errorDetails: ErrorReducer,
  page: PageReducer,
  redeem: RedeemReducer,
  referral: ReferralReducer,
  wishlist: WishListReducer,
  spam: SpamReducer,
  videoHistory: VideoHistoryReducer,
  channel: ChannelReducer,
  notification: BellNotificationReducer,
  channelPlaylist: ChannelPlayListReducer,
  viewerPlaylist: ViewerPlayListReducer,
  videoVM: VideoManagementReducer,
  ppv: PPVReducer,
  singleVideo: SingleVideoReducer,
  tag: TagsReducer,
  category: CategoryReducer,
  core: CoreDataReducer,
  livetv: LiveTvReducer,
  liveStreaming: LiveStreamingReducer,
});
