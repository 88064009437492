import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Tabs,
  Tab,
  Dropdown,
  DropdownButton,
  Image,
  Media,
  Badge,
} from "react-bootstrap";
import "./VideoWithPlaylist.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class VideoWithPlaylist extends Component {
  constructor(props) {
    super(props);
    this.handleToggleVisib = this.handleToggleVisib.bind(this);
    this.state = {
      visib: false,
    };
  }

  handleToggleVisib() {
    this.setState({ visib: !this.state.visib });
  }

  render() {
    return (
      <>
        <div className="video-with-playlist-sec">
          <Container>
            <Row>
              <Col xs={12} md={8}>
                <div className="video-ratio-sec-card">
                  <div className="main-video-sec">
                    <Image
                      src={window.location.origin + "/assets/img/video-1.jpg"}
                      className="main-video"
                    />
                    <div className="time-count">
                      <h6>15:19</h6>
                    </div>
                    <div className="play-btn">
                      <FontAwesomeIcon
                        icon={["fas", "play"]}
                        className="play-icon"
                      />
                    </div>
                  </div>
                </div>
                <h3 className="video-caption-title">
                  Analyzing the Mass Effect: Andromeda E3 2020 Trailer
                </h3>
                <div className="video-bottom-toolbar d-none d-sm-block">
                  <div className="video-user-details">
                    <Image
                      src={
                        window.location.origin + "/assets/img/profile-img.jpg"
                      }
                      className="user-img"
                    />
                  </div>
                  <div className="video-user-info-sec">
                    <div>
                      <p className="video-info">
                        <span className="title">NaughtyDog</span>
                        <span className="video-count">52 Videos</span>
                      </p>
                      <div className="subscribe-details">
                        <h5 className="subscribe-btn">Subscribe</h5>
                        <p className="subscribe-count">22,548,145</p>
                      </div>
                    </div>
                    <div className="column-half">
                      <h3 className="view-head-count">
                        2,729,347 <span className="small">views</span>
                      </h3>
                      <div className="video-views-details">
                        <span className="view-percentage-count pink-clr">
                          95%
                        </span>
                        <span className="view-count green-clr">
                          <FontAwesomeIcon icon="circle" className="mr-1" />
                          39,852
                        </span>
                        <span className="view-count-1 green-grey">
                          <FontAwesomeIcon icon="circle" className="mr-1" />
                          852
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="video-menu-list-sec d-none d-sm-block">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="about"
                  >
                    <Row>
                      <Col sm={12}>
                        <Nav variant="pills">
                          <Nav.Item>
                            <Nav.Link eventKey="about">
                              <FontAwesomeIcon
                                icon={["fas", "info-circle"]}
                                className="mr-2"
                              />
                              About
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="share">
                              <FontAwesomeIcon
                                icon={["fas", "info-circle"]}
                                className="mr-2"
                              />
                              Share
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="download">
                              <FontAwesomeIcon
                                icon={["fas", "long-arrow-alt-down"]}
                                className="mr-2"
                              />
                              Download
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="jump-to">
                              <FontAwesomeIcon
                                icon={["fas", "ellipsis-h"]}
                                className="mr-2"
                              />
                              Jump to
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="add-to">
                              <FontAwesomeIcon
                                icon={["fas", "plus"]}
                                className="mr-2"
                              />
                              Add to
                            </Nav.Link>
                          </Nav.Item>
                          <ul className="list-unstyled tab-right-icons">
                            <Media as="li">
                              <Link to="#">
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/img/watch-later.png"
                                  }
                                />
                              </Link>
                            </Media>
                            <Media as="li">
                              <Link to="#">
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/img/like.png"
                                  }
                                />
                              </Link>
                            </Media>
                            <Media as="li">
                              <Link to="#">
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/img/flag.png"
                                  }
                                />
                              </Link>
                            </Media>
                          </ul>
                        </Nav>
                      </Col>
                      <Col sm={12}>
                        <Tab.Content>
                          <Tab.Pane eventKey="about">
                            <div className="video-description-sec">
                              <Row>
                                <Col
                                  xs={12}
                                  md={9}
                                  className="padding-right-zero"
                                >
                                  <div className="cast-description-details">
                                    <h4 className="title">Cast :</h4>
                                    <p className="desc">
                                      Nathan Drake, Victor Sullivan, Sam Darke,
                                      Elena Fisher
                                    </p>
                                    <h4 className="title">Category :</h4>
                                    <p className="desc">
                                      Gaming, PS4 Exclusive, Gameplay, 1080p
                                    </p>
                                    <h4 className="title">About :</h4>
                                    <p className="desc">
                                      Three years after the events of Uncharted
                                      3: Darke's Deception, Nathan Drake, now
                                      retired as a fortune hunter, has settled
                                      into a normal life with his wife Elena
                                      Fisher. His world is then turned upside
                                      down when his older brother Sam, long
                                      belived to be dead, suddenly reappears
                                      seeking Drake's help
                                    </p>
                                    <h4 className="title">Tags :</h4>
                                    <p className="desc-badge">
                                      <Badge className="badge badge-tag">
                                        Uncharted 4
                                      </Badge>
                                      <Badge className="badge badge-tag">
                                        Playstation 4
                                      </Badge>
                                      <Badge className="badge badge-tag">
                                        Gameplay
                                      </Badge>
                                      <Badge className="badge badge-tag">
                                        1080P
                                      </Badge>
                                      <Badge className="badge badge-tag">
                                        ps4Share
                                      </Badge>
                                      <Badge className="badge badge-tag">
                                        +6
                                      </Badge>
                                    </p>
                                    <div className="releases-details">
                                      <h3 className="title">
                                        Release Date :{" "}
                                        <span className="sub-desc">
                                          2 Days ago
                                        </span>
                                      </h3>
                                      <h3 className="title">
                                        License :{" "}
                                        <span className="sub-desc">
                                          Standard
                                        </span>
                                      </h3>
                                    </div>
                                    <div className="show-less-btn">
                                      <Button className="btn-show-less">
                                        Show Less
                                      </Button>
                                    </div>
                                  </div>
                                </Col>
                                <Col
                                  xs={12}
                                  md={3}
                                  className="padding-left-zero"
                                >
                                  <div className="purchase-card">
                                    <h4 className="title">Game :</h4>
                                    <p className="desc">Uncharted 4</p>
                                    <div className="purchase-img-sec">
                                      <Image
                                        src={
                                          window.location.origin +
                                          "/assets/img/video-1.jpg"
                                        }
                                        className="purchase-video-img"
                                      />
                                    </div>
                                    <Button className="btn btn-purchase btn-block">
                                      Purchase
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <div className="google-adsense-add-sec">
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/img/video-1.jpg"
                                }
                                className="addsense-add-img"
                              />
                            </div>
                            <div className="similar-video-sec">
                              <h6 className="head-title">Similar Videos</h6>
                              <Row>
                                <Col xs={12} md={3}>
                                  <Link to="#">
                                    <div className="similar-video-card">
                                      <div className="similar-video-card-img">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/video-1.jpg"
                                          }
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="similar-video-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New
                                          Gameplay - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                        </p>
                                        <p className="desc">
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-2"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                                <Col xs={12} md={3}>
                                  <Link to="#">
                                    <div className="similar-video-card">
                                      <div className="similar-video-card-img">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/video-1.jpg"
                                          }
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="similar-video-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New
                                          Gameplay - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                        </p>
                                        <p className="desc">
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-2"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                                <Col xs={12} md={3}>
                                  <Link to="#">
                                    <div className="similar-video-card">
                                      <div className="similar-video-card-img">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/video-1.jpg"
                                          }
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="similar-video-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New
                                          Gameplay - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                        </p>
                                        <p className="desc">
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-2"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                                <Col xs={12} md={3}>
                                  <Link to="#">
                                    <div className="similar-video-card">
                                      <div className="similar-video-card-img">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/video-1.jpg"
                                          }
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="similar-video-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New
                                          Gameplay - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                        </p>
                                        <p className="desc">
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-2"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                              </Row>
                            </div>
                            <div className="single-video-about-comments-sec">
                              <div className="single-video-comments-header">
                                <h4 className="head-title">
                                  <Image
                                    src="assets/img/comment.png"
                                    className="comments-icons"
                                  />
                                  236
                                  <span className="small-title">Comments</span>
                                </h4>
                              </div>
                              <div className="single-video-comments-body">
                                <Row>
                                  <Col xs={12} md={2}>
                                    <div className="video-user-details">
                                      <Image
                                        src={
                                          window.location.origin +
                                          "/assets/img/profile-img.jpg"
                                        }
                                        className="user-img"
                                      />
                                    </div>
                                  </Col>
                                  <Col xs={12} md={10}>
                                    <Form className="comments-form">
                                      <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Control as="textarea" rows="3" />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                </Row>
                              </div>
                              <div className="single-video-comments-sec-tabs">
                                <Tab.Container
                                  id="left-tabs-example"
                                  defaultActiveKey="popular-comments"
                                >
                                  <Row>
                                    <Col sm={12} md={12}>
                                      <Nav variant="pills">
                                        <Nav.Item>
                                          <Nav.Link
                                            eventKey="popular-comments"
                                            className="padding-left-zero"
                                          >
                                            Popular Comments
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="newest-comments">
                                            Newest Comments
                                          </Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </Col>
                                    <Col sm={12} md={12}>
                                      <Tab.Content>
                                        <Tab.Pane eventKey="popular-comments">
                                          <div className="comment-tabs-sec">
                                            <div className="comments-info">
                                              <Row>
                                                <Col sm={12} md={2}>
                                                  <div className="comments-user-details">
                                                    <Image
                                                      src={
                                                        window.location.origin +
                                                        "/assets/img/profile-img.jpg"
                                                      }
                                                      className="user-img"
                                                    />
                                                  </div>
                                                </Col>
                                                <Col sm={12} md={9}>
                                                  <p className="user-details">
                                                    <span className="user-name">
                                                      NaughtyDog .
                                                    </span>
                                                    <span className="post-date">
                                                      1 Weeks ago
                                                    </span>
                                                  </p>
                                                  <p className="user-description">
                                                    Really great story. You're
                                                    doing a great job. Keep it
                                                    up pal.
                                                  </p>
                                                  <p className="user-details-count">
                                                    <span className="view-count mr-2 green-clr">
                                                      <FontAwesomeIcon
                                                        icon="circle"
                                                        className="mr-1"
                                                      />
                                                      39,852
                                                    </span>
                                                    <span className="view-count-1 mr-2 green-grey">
                                                      <FontAwesomeIcon
                                                        icon="circle"
                                                        className="mr-1"
                                                      />
                                                      852
                                                    </span>
                                                    .
                                                    <span className="reply-link">
                                                      Reply
                                                    </span>
                                                  </p>
                                                  <Button
                                                    className="view-all-reply reply-btn-click"
                                                    onClick={
                                                      this.handleToggleVisib
                                                    }
                                                  >
                                                    View all 5 replies
                                                    <FontAwesomeIcon
                                                      icon="chevron-down"
                                                      className="ml-1"
                                                    />
                                                    {this.state.visib ? "" : ""}
                                                  </Button>
                                                </Col>
                                                <Col sm={12} md={1}>
                                                  <Link to="#">
                                                    <Image
                                                      src="assets/img/flag.png"
                                                      className="menu-icons"
                                                    />
                                                  </Link>
                                                </Col>
                                              </Row>
                                            </div>
                                            <div
                                              className="comments-info reply-show"
                                              id="dialog_1"
                                            >
                                              {this.state.visib && (
                                                <Row>
                                                  <Col
                                                    sm={12}
                                                    md={2}
                                                    className="resp-column-none"
                                                  ></Col>
                                                  <Col sm={12} md={10}>
                                                    <Row>
                                                      <Col sm={12} md={2}>
                                                        <div className="comments-user-details">
                                                          <Image
                                                            src={
                                                              window.location
                                                                .origin +
                                                              "/assets/img/profile-img.jpg"
                                                            }
                                                            className="user-img"
                                                          />
                                                        </div>
                                                      </Col>
                                                      <Col sm={12} md={10}>
                                                        <p className="user-details">
                                                          <span className="user-name">
                                                            NaughtyDog .
                                                          </span>
                                                          <span className="post-date">
                                                            1 Weeks ago
                                                          </span>
                                                        </p>
                                                        <p className="user-description">
                                                          Really great story.
                                                          You're doing a great
                                                          job. Keep it up pal.
                                                        </p>
                                                        <p className="user-details-count">
                                                          <span className="view-count mr-2 green-clr">
                                                            <FontAwesomeIcon
                                                              icon="circle"
                                                              className="mr-1"
                                                            />
                                                            39,852
                                                          </span>
                                                          <span className="view-count-1 mr-2 green-grey">
                                                            <FontAwesomeIcon
                                                              icon="circle"
                                                              className="mr-1"
                                                            />
                                                            852
                                                          </span>
                                                          .
                                                          <span className="reply-link">
                                                            Reply
                                                          </span>
                                                        </p>
                                                      </Col>
                                                    </Row>
                                                  </Col>
                                                </Row>
                                              )}
                                            </div>
                                            <Row>
                                              <Col sm={12} md={12}>
                                                <div className="load-more-btn">
                                                  <Button className="btn-load-more">
                                                    Load more Comments
                                                  </Button>
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="newest-comments">
                                          <div className="comment-tabs-sec">
                                            <div className="comments-info">
                                              <Row>
                                                <Col sm={12} md={2}>
                                                  <div className="comments-user-details">
                                                    <Image
                                                      src={
                                                        window.location.origin +
                                                        "/assets/img/profile-img.jpg"
                                                      }
                                                      className="user-img"
                                                    />
                                                  </div>
                                                </Col>
                                                <Col sm={12} md={9}>
                                                  <p className="user-details">
                                                    <span className="user-name">
                                                      NaughtyDog .
                                                    </span>
                                                    <span className="post-date">
                                                      1 Weeks ago
                                                    </span>
                                                  </p>
                                                  <p className="user-description">
                                                    Really great story. You're
                                                    doing a great job. Keep it
                                                    up pal.
                                                  </p>
                                                  <p className="user-details-count">
                                                    <span className="view-count mr-2 green-clr">
                                                      <FontAwesomeIcon
                                                        icon="circle"
                                                        className="mr-1"
                                                      />
                                                      39,852
                                                    </span>
                                                    <span className="view-count-1 mr-2 green-grey">
                                                      <FontAwesomeIcon
                                                        icon="circle"
                                                        className="mr-1"
                                                      />
                                                      852
                                                    </span>
                                                    .
                                                    <span className="reply-link">
                                                      Reply
                                                    </span>
                                                  </p>
                                                  <Button
                                                    className="view-all-reply reply-btn-click"
                                                    onClick={
                                                      this.handleToggleVisib
                                                    }
                                                  >
                                                    View all 5 replies
                                                    <FontAwesomeIcon
                                                      icon="chevron-down"
                                                      className="ml-1"
                                                    />
                                                    {this.state.visib ? "" : ""}
                                                  </Button>
                                                </Col>
                                                <Col sm={12} md={1}>
                                                  <Link to="#">
                                                    <Image
                                                      src="assets/img/flag.png"
                                                      className="menu-icons"
                                                    />
                                                  </Link>
                                                </Col>
                                              </Row>
                                            </div>
                                            <div
                                              className="comments-info reply-show"
                                              id="dialog_1"
                                            >
                                              {this.state.visib && (
                                                <Row>
                                                  <Col
                                                    sm={12}
                                                    md={2}
                                                    className="resp-column-none"
                                                  ></Col>
                                                  <Col sm={12} md={10}>
                                                    <Row>
                                                      <Col sm={12} md={2}>
                                                        <div className="comments-user-details">
                                                          <Image
                                                            src={
                                                              window.location
                                                                .origin +
                                                              "/assets/img/profile-img.jpg"
                                                            }
                                                            className="user-img"
                                                          />
                                                        </div>
                                                      </Col>
                                                      <Col sm={12} md={10}>
                                                        <p className="user-details">
                                                          <span className="user-name">
                                                            NaughtyDog .
                                                          </span>
                                                          <span className="post-date">
                                                            1 Weeks ago
                                                          </span>
                                                        </p>
                                                        <p className="user-description">
                                                          Really great story.
                                                          You're doing a great
                                                          job. Keep it up pal.
                                                        </p>
                                                        <p className="user-details-count">
                                                          <span className="view-count mr-2 green-clr">
                                                            <FontAwesomeIcon
                                                              icon="circle"
                                                              className="mr-1"
                                                            />
                                                            39,852
                                                          </span>
                                                          <span className="view-count-1 mr-2 green-grey">
                                                            <FontAwesomeIcon
                                                              icon="circle"
                                                              className="mr-1"
                                                            />
                                                            852
                                                          </span>
                                                          .
                                                          <span className="reply-link">
                                                            Reply
                                                          </span>
                                                        </p>
                                                      </Col>
                                                    </Row>
                                                  </Col>
                                                </Row>
                                              )}
                                            </div>
                                            <Row>
                                              <Col sm={12} md={12}>
                                                <div className="load-more-btn">
                                                  <Button className="btn-load-more">
                                                    Load more Comments
                                                  </Button>
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Tab.Pane>
                                      </Tab.Content>
                                    </Col>
                                  </Row>
                                </Tab.Container>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="share">
                            <div className="video-share-sec">
                              <h4 className="title">Share:</h4>
                              <ul className="list-unstyled share-links">
                                <Media as="li" className="facebook-bg">
                                  <Link to="#">
                                    <FontAwesomeIcon
                                      icon={["fab", "facebook-f"]}
                                    />
                                  </Link>
                                </Media>
                                <Media as="li" className="twitter-bg">
                                  <Link to="#">
                                    <FontAwesomeIcon
                                      icon={["fab", "twitter"]}
                                    />
                                  </Link>
                                </Media>
                                <Media as="li" className="google-bg">
                                  <Link to="#">
                                    <FontAwesomeIcon
                                      icon={["fab", "google-plus-g"]}
                                    />
                                  </Link>
                                </Media>
                                <Media as="li" className="pinterest-bg">
                                  <Link to="#">
                                    <FontAwesomeIcon
                                      icon={["fab", "pinterest-p"]}
                                    />
                                  </Link>
                                </Media>
                                <Media as="li" className="blogger-bg">
                                  <Link to="#">
                                    <FontAwesomeIcon
                                      icon={["fab", "blogger-b"]}
                                    />
                                  </Link>
                                </Media>
                                <Media as="li" className="tumblr-bg">
                                  <Link to="#">
                                    <FontAwesomeIcon icon={["fab", "tumblr"]} />
                                  </Link>
                                </Media>
                                <Media as="li" className="vk-bg">
                                  <Link to="#">
                                    <FontAwesomeIcon icon={["fab", "vk"]} />
                                  </Link>
                                </Media>
                                <Media as="li" className="no-name-bg">
                                  <Link to="#">
                                    <FontAwesomeIcon
                                      icon={["fab", "facebook-f"]}
                                    />
                                  </Link>
                                </Media>
                                <Media as="li" className="no-name-1-bg">
                                  <Link to="#">
                                    <FontAwesomeIcon
                                      icon={["fab", "facebook-f"]}
                                    />
                                  </Link>
                                </Media>
                                <Media as="li" className="facebook-bg">
                                  <Link to="#">
                                    <FontAwesomeIcon
                                      icon={["fab", "facebook-f"]}
                                    />
                                  </Link>
                                </Media>
                                <Media as="li" className="twitter-bg">
                                  <Link to="#">
                                    <FontAwesomeIcon
                                      icon={["fab", "twitter"]}
                                    />
                                  </Link>
                                </Media>
                                <Media as="li" className="google-bg">
                                  <Link to="#">
                                    <FontAwesomeIcon
                                      icon={["fab", "google-plus-g"]}
                                    />
                                  </Link>
                                </Media>
                                <Media as="li" className="pinterest-bg">
                                  <Link to="#">
                                    <FontAwesomeIcon
                                      icon={["fab", "pinterest-p"]}
                                    />
                                  </Link>
                                </Media>
                                <Media as="li" className="blogger-bg">
                                  <Link to="#">
                                    <FontAwesomeIcon
                                      icon={["fab", "blogger-b"]}
                                    />
                                  </Link>
                                </Media>
                                <Media as="li" className="tumblr-bg">
                                  <Link to="#">
                                    <FontAwesomeIcon icon={["fab", "tumblr"]} />
                                  </Link>
                                </Media>
                                <Media as="li" className="vk-bg">
                                  <Link to="#">
                                    <FontAwesomeIcon icon={["fab", "vk"]} />
                                  </Link>
                                </Media>
                                <Media as="li" className="no-name-bg">
                                  <Link to="#">
                                    <FontAwesomeIcon
                                      icon={["fab", "facebook-f"]}
                                    />
                                  </Link>
                                </Media>
                                <Media as="li" className="no-name-1-bg">
                                  <Link to="#">
                                    <FontAwesomeIcon
                                      icon={["fab", "facebook-f"]}
                                    />
                                  </Link>
                                </Media>
                              </ul>
                              <Form className="share-form">
                                <Form.Row className="align-items-center">
                                  <Col sm={12} md={9}>
                                    <Form.Label
                                      className="title"
                                      htmlFor="inlineFormInput"
                                    >
                                      Link:
                                    </Form.Label>
                                    <Form.Control
                                      className="mb-3"
                                      id="inlineFormInput"
                                    />
                                    <Button className="btn-copy">Copy</Button>
                                  </Col>
                                  <Col sm={12} md={3}>
                                    <Form className="share-check-box-form">
                                      <Form.Group controlId="exampleForm.ControlInput1">
                                        <Form.Label
                                          className="title"
                                          htmlFor="inlineFormInput"
                                        >
                                          Start at:
                                        </Form.Label>
                                        <div className="flex-checkbox">
                                          {["checkbox"].map((type) => (
                                            <div key={`custom-inline-${type}`}>
                                              <Form.Check
                                                custom
                                                inline
                                                label=""
                                                type={type}
                                                id={`custom-inline-${type}-1`}
                                              />
                                            </div>
                                          ))}
                                          <Form.Control
                                            className="mb-3"
                                            id="inlineFormInput"
                                          />
                                        </div>
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                </Form.Row>
                                <Form.Row className="align-items-center">
                                  <Col sm={12} md={12}>
                                    <Form.Group controlId="exampleForm.ControlInput1">
                                      <Form.Label
                                        className="title"
                                        htmlFor="inlineFormInput"
                                      >
                                        Embed:
                                      </Form.Label>
                                      <Form.Control
                                        className="mb-3"
                                        id="inlineFormInput"
                                      />
                                    </Form.Group>
                                  </Col>
                                </Form.Row>
                                <Form.Row className="align-items-center mb-1">
                                  <Col sm={12} md={12}>
                                    <Form.Group controlId="exampleForm.ControlInput1">
                                      <Form.Label
                                        className="title"
                                        htmlFor="inlineFormInput"
                                      >
                                        Video Size
                                      </Form.Label>
                                      <div className="video-size-sec">
                                        <Button className="btn-video-size">
                                          360P
                                        </Button>
                                        <Button className="btn-video-size">
                                          480P
                                        </Button>
                                        <Button className="btn-video-size">
                                          720P
                                        </Button>
                                        <Button className="btn-video-size">
                                          1080P
                                        </Button>
                                        <Button className="btn-video-size">
                                          Custom
                                        </Button>
                                      </div>
                                    </Form.Group>
                                  </Col>
                                </Form.Row>
                                <Form.Row className="align-items-center check-box-share">
                                  <Col sm={12} md={12}>
                                    <Form.Group controlId="exampleForm.ControlInput1">
                                      {["checkbox"].map((type) => (
                                        <div key={`custom-inline-${type}`}>
                                          <Form.Check
                                            custom
                                            inline
                                            label="Show suggested videos when the video finishes"
                                            type={type}
                                            id={`custom-inline-${type}-2`}
                                          />
                                        </div>
                                      ))}
                                      {["checkbox"].map((type) => (
                                        <div key={`custom-inline-${type}`}>
                                          <Form.Check
                                            custom
                                            inline
                                            label="Show player controls"
                                            type={type}
                                            id={`custom-inline-${type}-3`}
                                          />
                                        </div>
                                      ))}
                                      {["checkbox"].map((type) => (
                                        <div key={`custom-inline-${type}`}>
                                          <Form.Check
                                            custom
                                            inline
                                            label="show video title and player actions"
                                            type={type}
                                            id={`custom-inline-${type}-4`}
                                          />
                                        </div>
                                      ))}
                                    </Form.Group>
                                  </Col>
                                </Form.Row>
                              </Form>
                            </div>
                            <div className="google-adsense-add-sec">
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/img/video-1.jpg"
                                }
                                className="addsense-add-img"
                              />
                            </div>
                            <div className="similar-video-sec">
                              <h6 className="head-title">Similar Videos</h6>
                              <Row>
                                <Col xs={12} md={3}>
                                  <Link to="#">
                                    <div className="similar-video-card">
                                      <div className="similar-video-card-img">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/video-1.jpg"
                                          }
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="similar-video-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New
                                          Gameplay - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                        </p>
                                        <p className="desc">
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-2"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                                <Col xs={12} md={3}>
                                  <Link to="#">
                                    <div className="similar-video-card">
                                      <div className="similar-video-card-img">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/video-1.jpg"
                                          }
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="similar-video-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New
                                          Gameplay - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                        </p>
                                        <p className="desc">
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-2"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                                <Col xs={12} md={3}>
                                  <Link to="#">
                                    <div className="similar-video-card">
                                      <div className="similar-video-card-img">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/video-1.jpg"
                                          }
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="similar-video-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New
                                          Gameplay - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                        </p>
                                        <p className="desc">
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-2"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                                <Col xs={12} md={3}>
                                  <Link to="#">
                                    <div className="similar-video-card">
                                      <div className="similar-video-card-img">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/video-1.jpg"
                                          }
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="similar-video-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New
                                          Gameplay - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                        </p>
                                        <p className="desc">
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-2"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                              </Row>
                            </div>
                            <div className="single-video-about-comments-sec">
                              <div className="single-video-comments-header">
                                <h4 className="head-title">
                                  <Image
                                    src="assets/img/comment.png"
                                    className="comments-icons"
                                  />
                                  236
                                  <span className="small-title">Comments</span>
                                </h4>
                              </div>
                              <div className="single-video-comments-body">
                                <Row>
                                  <Col xs={12} md={2}>
                                    <div className="video-user-details">
                                      <Image
                                        src={
                                          window.location.origin +
                                          "/assets/img/profile-img.jpg"
                                        }
                                        className="user-img"
                                      />
                                    </div>
                                  </Col>
                                  <Col xs={12} md={10}>
                                    <Form className="comments-form">
                                      <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Control as="textarea" rows="3" />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                </Row>
                              </div>
                              <div className="single-video-comments-sec-tabs">
                                <Tab.Container
                                  id="left-tabs-example"
                                  defaultActiveKey="popular-comments"
                                >
                                  <Row>
                                    <Col sm={12} md={12}>
                                      <Nav variant="pills">
                                        <Nav.Item>
                                          <Nav.Link
                                            eventKey="popular-comments"
                                            className="padding-left-zero"
                                          >
                                            Popular Comments
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="newest-comments">
                                            Newest Comments
                                          </Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </Col>
                                    <Col sm={12} md={12}>
                                      <Tab.Content>
                                        <Tab.Pane eventKey="popular-comments">
                                          <div className="comment-tabs-sec">
                                            <div className="comments-info">
                                              <Row>
                                                <Col sm={12} md={2}>
                                                  <div className="comments-user-details">
                                                    <Image
                                                      src={
                                                        window.location.origin +
                                                        "/assets/img/profile-img.jpg"
                                                      }
                                                      className="user-img"
                                                    />
                                                  </div>
                                                </Col>
                                                <Col sm={12} md={9}>
                                                  <p className="user-details">
                                                    <span className="user-name">
                                                      NaughtyDog .
                                                    </span>
                                                    <span className="post-date">
                                                      1 Weeks ago
                                                    </span>
                                                  </p>
                                                  <p className="user-description">
                                                    Really great story. You're
                                                    doing a great job. Keep it
                                                    up pal.
                                                  </p>
                                                  <p className="user-details-count">
                                                    <span className="view-count mr-2 green-clr">
                                                      <FontAwesomeIcon
                                                        icon="circle"
                                                        className="mr-1"
                                                      />
                                                      39,852
                                                    </span>
                                                    <span className="view-count-1 mr-2 green-grey">
                                                      <FontAwesomeIcon
                                                        icon="circle"
                                                        className="mr-1"
                                                      />
                                                      852
                                                    </span>
                                                    .
                                                    <span className="reply-link">
                                                      Reply
                                                    </span>
                                                  </p>
                                                  <Button
                                                    className="view-all-reply reply-btn-click"
                                                    onClick={
                                                      this.handleToggleVisib
                                                    }
                                                  >
                                                    View all 5 replies
                                                    <FontAwesomeIcon
                                                      icon="chevron-down"
                                                      className="ml-1"
                                                    />
                                                    {this.state.visib ? "" : ""}
                                                  </Button>
                                                </Col>
                                                <Col sm={12} md={1}>
                                                  <Link to="#">
                                                    <Image
                                                      src="assets/img/flag.png"
                                                      className="menu-icons"
                                                    />
                                                  </Link>
                                                </Col>
                                              </Row>
                                            </div>
                                            <div
                                              className="comments-info reply-show"
                                              id="dialog_1"
                                            >
                                              {this.state.visib && (
                                                <Row>
                                                  <Col
                                                    sm={12}
                                                    md={2}
                                                    className="resp-column-none"
                                                  ></Col>
                                                  <Col sm={12} md={10}>
                                                    <Row>
                                                      <Col sm={12} md={2}>
                                                        <div className="comments-user-details">
                                                          <Image
                                                            src={
                                                              window.location
                                                                .origin +
                                                              "/assets/img/profile-img.jpg"
                                                            }
                                                            className="user-img"
                                                          />
                                                        </div>
                                                      </Col>
                                                      <Col sm={12} md={10}>
                                                        <p className="user-details">
                                                          <span className="user-name">
                                                            NaughtyDog .
                                                          </span>
                                                          <span className="post-date">
                                                            1 Weeks ago
                                                          </span>
                                                        </p>
                                                        <p className="user-description">
                                                          Really great story.
                                                          You're doing a great
                                                          job. Keep it up pal.
                                                        </p>
                                                        <p className="user-details-count">
                                                          <span className="view-count mr-2 green-clr">
                                                            <FontAwesomeIcon
                                                              icon="circle"
                                                              className="mr-1"
                                                            />
                                                            39,852
                                                          </span>
                                                          <span className="view-count-1 mr-2 green-grey">
                                                            <FontAwesomeIcon
                                                              icon="circle"
                                                              className="mr-1"
                                                            />
                                                            852
                                                          </span>
                                                          .
                                                          <span className="reply-link">
                                                            Reply
                                                          </span>
                                                        </p>
                                                      </Col>
                                                    </Row>
                                                  </Col>
                                                </Row>
                                              )}
                                            </div>
                                            <Row>
                                              <Col sm={12} md={12}>
                                                <div className="load-more-btn">
                                                  <Button className="btn-load-more">
                                                    Load more Comments
                                                  </Button>
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="newest-comments">
                                          <div className="comment-tabs-sec">
                                            <div className="comments-info">
                                              <Row>
                                                <Col sm={12} md={2}>
                                                  <div className="comments-user-details">
                                                    <Image
                                                      src={
                                                        window.location.origin +
                                                        "/assets/img/profile-img.jpg"
                                                      }
                                                      className="user-img"
                                                    />
                                                  </div>
                                                </Col>
                                                <Col sm={12} md={9}>
                                                  <p className="user-details">
                                                    <span className="user-name">
                                                      NaughtyDog .
                                                    </span>
                                                    <span className="post-date">
                                                      1 Weeks ago
                                                    </span>
                                                  </p>
                                                  <p className="user-description">
                                                    Really great story. You're
                                                    doing a great job. Keep it
                                                    up pal.
                                                  </p>
                                                  <p className="user-details-count">
                                                    <span className="view-count mr-2 green-clr">
                                                      <FontAwesomeIcon
                                                        icon="circle"
                                                        className="mr-1"
                                                      />
                                                      39,852
                                                    </span>
                                                    <span className="view-count-1 mr-2 green-grey">
                                                      <FontAwesomeIcon
                                                        icon="circle"
                                                        className="mr-1"
                                                      />
                                                      852
                                                    </span>
                                                    .
                                                    <span className="reply-link">
                                                      Reply
                                                    </span>
                                                  </p>
                                                  <Button
                                                    className="view-all-reply reply-btn-click"
                                                    onClick={
                                                      this.handleToggleVisib
                                                    }
                                                  >
                                                    View all 5 replies
                                                    <FontAwesomeIcon
                                                      icon="chevron-down"
                                                      className="ml-1"
                                                    />
                                                    {this.state.visib ? "" : ""}
                                                  </Button>
                                                </Col>
                                                <Col sm={12} md={1}>
                                                  <Link to="#">
                                                    <Image
                                                      src="assets/img/flag.png"
                                                      className="menu-icons"
                                                    />
                                                  </Link>
                                                </Col>
                                              </Row>
                                            </div>
                                            <div
                                              className="comments-info reply-show"
                                              id="dialog_1"
                                            >
                                              {this.state.visib && (
                                                <Row>
                                                  <Col
                                                    sm={12}
                                                    md={2}
                                                    className="resp-column-none"
                                                  ></Col>
                                                  <Col sm={12} md={10}>
                                                    <Row>
                                                      <Col sm={12} md={2}>
                                                        <div className="comments-user-details">
                                                          <Image
                                                            src={
                                                              window.location
                                                                .origin +
                                                              "/assets/img/profile-img.jpg"
                                                            }
                                                            className="user-img"
                                                          />
                                                        </div>
                                                      </Col>
                                                      <Col sm={12} md={10}>
                                                        <p className="user-details">
                                                          <span className="user-name">
                                                            NaughtyDog .
                                                          </span>
                                                          <span className="post-date">
                                                            1 Weeks ago
                                                          </span>
                                                        </p>
                                                        <p className="user-description">
                                                          Really great story.
                                                          You're doing a great
                                                          job. Keep it up pal.
                                                        </p>
                                                        <p className="user-details-count">
                                                          <span className="view-count mr-2 green-clr">
                                                            <FontAwesomeIcon
                                                              icon="circle"
                                                              className="mr-1"
                                                            />
                                                            39,852
                                                          </span>
                                                          <span className="view-count-1 mr-2 green-grey">
                                                            <FontAwesomeIcon
                                                              icon="circle"
                                                              className="mr-1"
                                                            />
                                                            852
                                                          </span>
                                                          .
                                                          <span className="reply-link">
                                                            Reply
                                                          </span>
                                                        </p>
                                                      </Col>
                                                    </Row>
                                                  </Col>
                                                </Row>
                                              )}
                                            </div>
                                            <Row>
                                              <Col sm={12} md={12}>
                                                <div className="load-more-btn">
                                                  <Button className="btn-load-more">
                                                    Load more Comments
                                                  </Button>
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Tab.Pane>
                                      </Tab.Content>
                                    </Col>
                                  </Row>
                                </Tab.Container>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="download">
                            <div className="video-download-sec">
                              <Form className="mb-0">
                                <Form.Row className="align-items-center">
                                  <Col sm={12} md={12}>
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                      <label className="title">Download:</label>
                                      <div className="download-video-size-sec">
                                        <Button className="btn-video-size">
                                          <FontAwesomeIcon
                                            icon={[
                                              "fas",
                                              "long-arrow-alt-down",
                                            ]}
                                            className="download-menu mr-2"
                                          />
                                          360P
                                        </Button>
                                        <Button className="btn-video-size">
                                          <FontAwesomeIcon
                                            icon={[
                                              "fas",
                                              "long-arrow-alt-down",
                                            ]}
                                            className="download-menu mr-2"
                                          />
                                          480P
                                        </Button>
                                        <Button className="btn-video-size">
                                          <FontAwesomeIcon
                                            icon={[
                                              "fas",
                                              "long-arrow-alt-down",
                                            ]}
                                            className="download-menu mr-2"
                                          />
                                          720P
                                        </Button>
                                        <Button className="btn-video-size">
                                          <FontAwesomeIcon
                                            icon={[
                                              "fas",
                                              "long-arrow-alt-down",
                                            ]}
                                            className="download-menu mr-2"
                                          />
                                          1080P
                                        </Button>
                                        <Button className="btn-video-size">
                                          <FontAwesomeIcon
                                            icon={[
                                              "fas",
                                              "long-arrow-alt-down",
                                            ]}
                                            className="download-menu mr-2"
                                          />
                                          UHD4K
                                        </Button>
                                        <Button className="btn-video-size">
                                          <FontAwesomeIcon
                                            icon={[
                                              "fas",
                                              "long-arrow-alt-down",
                                            ]}
                                            className="download-menu mr-2"
                                          />
                                          Mobile
                                        </Button>
                                      </div>
                                    </Form.Group>
                                  </Col>
                                </Form.Row>
                                <Form.Row className="align-items-center check-box-download">
                                  <Col sm={12} md={12}>
                                    {["checkbox"].map((type) => (
                                      <div key={`custom-inline-${type}`}>
                                        <Form.Check
                                          custom
                                          inline
                                          label="By Downloading This video 
                                                I agree that I will not upload this video 
                                                anywhere else without proper permission from the creator"
                                          type={type}
                                          id={`custom-inline-${type}-5`}
                                        />
                                      </div>
                                    ))}
                                  </Col>
                                </Form.Row>
                              </Form>
                            </div>
                            <div className="google-adsense-add-sec">
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/img/video-1.jpg"
                                }
                                className="addsense-add-img"
                              />
                            </div>
                            <div className="similar-video-sec">
                              <h6 className="head-title">Similar Videos</h6>
                              <Row>
                                <Col xs={12} md={3}>
                                  <Link to="#">
                                    <div className="similar-video-card">
                                      <div className="similar-video-card-img">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/video-1.jpg"
                                          }
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="similar-video-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New
                                          Gameplay - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                        </p>
                                        <p className="desc">
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-2"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                                <Col xs={12} md={3}>
                                  <Link to="#">
                                    <div className="similar-video-card">
                                      <div className="similar-video-card-img">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/video-1.jpg"
                                          }
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="similar-video-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New
                                          Gameplay - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                        </p>
                                        <p className="desc">
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-2"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                                <Col xs={12} md={3}>
                                  <Link to="#">
                                    <div className="similar-video-card">
                                      <div className="similar-video-card-img">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/video-1.jpg"
                                          }
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="similar-video-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New
                                          Gameplay - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                        </p>
                                        <p className="desc">
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-2"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                                <Col xs={12} md={3}>
                                  <Link to="#">
                                    <div className="similar-video-card">
                                      <div className="similar-video-card-img">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/video-1.jpg"
                                          }
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="similar-video-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New
                                          Gameplay - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                        </p>
                                        <p className="desc">
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-2"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                              </Row>
                            </div>
                            <div className="single-video-about-comments-sec">
                              <div className="single-video-comments-header">
                                <h4 className="head-title">
                                  <Image
                                    src="assets/img/comment.png"
                                    className="comments-icons"
                                  />
                                  236
                                  <span className="small-title">Comments</span>
                                </h4>
                              </div>
                              <div className="single-video-comments-body">
                                <Row>
                                  <Col xs={12} md={2}>
                                    <div className="video-user-details">
                                      <Image
                                        src={
                                          window.location.origin +
                                          "/assets/img/profile-img.jpg"
                                        }
                                        className="user-img"
                                      />
                                    </div>
                                  </Col>
                                  <Col xs={12} md={10}>
                                    <Form className="comments-form">
                                      <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Control as="textarea" rows="3" />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                </Row>
                              </div>
                              <div className="single-video-comments-sec-tabs">
                                <Tab.Container
                                  id="left-tabs-example"
                                  defaultActiveKey="popular-comments"
                                >
                                  <Row>
                                    <Col sm={12} md={12}>
                                      <Nav variant="pills">
                                        <Nav.Item>
                                          <Nav.Link
                                            eventKey="popular-comments"
                                            className="padding-left-zero"
                                          >
                                            Popular Comments
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="newest-comments">
                                            Newest Comments
                                          </Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </Col>
                                    <Col sm={12} md={12}>
                                      <Tab.Content>
                                        <Tab.Pane eventKey="popular-comments">
                                          <div className="comment-tabs-sec">
                                            <div className="comments-info">
                                              <Row>
                                                <Col sm={12} md={2}>
                                                  <div className="comments-user-details">
                                                    <Image
                                                      src={
                                                        window.location.origin +
                                                        "/assets/img/profile-img.jpg"
                                                      }
                                                      className="user-img"
                                                    />
                                                  </div>
                                                </Col>
                                                <Col sm={12} md={9}>
                                                  <p className="user-details">
                                                    <span className="user-name">
                                                      NaughtyDog .
                                                    </span>
                                                    <span className="post-date">
                                                      1 Weeks ago
                                                    </span>
                                                  </p>
                                                  <p className="user-description">
                                                    Really great story. You're
                                                    doing a great job. Keep it
                                                    up pal.
                                                  </p>
                                                  <p className="user-details-count">
                                                    <span className="view-count mr-2 green-clr">
                                                      <FontAwesomeIcon
                                                        icon="circle"
                                                        className="mr-1"
                                                      />
                                                      39,852
                                                    </span>
                                                    <span className="view-count-1 mr-2 green-grey">
                                                      <FontAwesomeIcon
                                                        icon="circle"
                                                        className="mr-1"
                                                      />
                                                      852
                                                    </span>
                                                    .
                                                    <span className="reply-link">
                                                      Reply
                                                    </span>
                                                  </p>
                                                  <Button
                                                    className="view-all-reply reply-btn-click"
                                                    onClick={
                                                      this.handleToggleVisib
                                                    }
                                                  >
                                                    View all 5 replies
                                                    <FontAwesomeIcon
                                                      icon="chevron-down"
                                                      className="ml-1"
                                                    />
                                                    {this.state.visib ? "" : ""}
                                                  </Button>
                                                </Col>
                                                <Col sm={12} md={1}>
                                                  <Link to="#">
                                                    <Image
                                                      src="assets/img/flag.png"
                                                      className="menu-icons"
                                                    />
                                                  </Link>
                                                </Col>
                                              </Row>
                                            </div>
                                            <div
                                              className="comments-info reply-show"
                                              id="dialog_1"
                                            >
                                              {this.state.visib && (
                                                <Row>
                                                  <Col
                                                    sm={12}
                                                    md={2}
                                                    className="resp-column-none"
                                                  ></Col>
                                                  <Col sm={12} md={10}>
                                                    <Row>
                                                      <Col sm={12} md={2}>
                                                        <div className="comments-user-details">
                                                          <Image
                                                            src={
                                                              window.location
                                                                .origin +
                                                              "/assets/img/profile-img.jpg"
                                                            }
                                                            className="user-img"
                                                          />
                                                        </div>
                                                      </Col>
                                                      <Col sm={12} md={10}>
                                                        <p className="user-details">
                                                          <span className="user-name">
                                                            NaughtyDog .
                                                          </span>
                                                          <span className="post-date">
                                                            1 Weeks ago
                                                          </span>
                                                        </p>
                                                        <p className="user-description">
                                                          Really great story.
                                                          You're doing a great
                                                          job. Keep it up pal.
                                                        </p>
                                                        <p className="user-details-count">
                                                          <span className="view-count mr-2 green-clr">
                                                            <FontAwesomeIcon
                                                              icon="circle"
                                                              className="mr-1"
                                                            />
                                                            39,852
                                                          </span>
                                                          <span className="view-count-1 mr-2 green-grey">
                                                            <FontAwesomeIcon
                                                              icon="circle"
                                                              className="mr-1"
                                                            />
                                                            852
                                                          </span>
                                                          .
                                                          <span className="reply-link">
                                                            Reply
                                                          </span>
                                                        </p>
                                                      </Col>
                                                    </Row>
                                                  </Col>
                                                </Row>
                                              )}
                                            </div>
                                            <Row>
                                              <Col sm={12} md={12}>
                                                <div className="load-more-btn">
                                                  <Button className="btn-load-more">
                                                    Load more Comments
                                                  </Button>
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="newest-comments">
                                          <div className="comment-tabs-sec">
                                            <div className="comments-info">
                                              <Row>
                                                <Col sm={12} md={2}>
                                                  <div className="comments-user-details">
                                                    <Image
                                                      src={
                                                        window.location.origin +
                                                        "/assets/img/profile-img.jpg"
                                                      }
                                                      className="user-img"
                                                    />
                                                  </div>
                                                </Col>
                                                <Col sm={12} md={9}>
                                                  <p className="user-details">
                                                    <span className="user-name">
                                                      NaughtyDog .
                                                    </span>
                                                    <span className="post-date">
                                                      1 Weeks ago
                                                    </span>
                                                  </p>
                                                  <p className="user-description">
                                                    Really great story. You're
                                                    doing a great job. Keep it
                                                    up pal.
                                                  </p>
                                                  <p className="user-details-count">
                                                    <span className="view-count mr-2 green-clr">
                                                      <FontAwesomeIcon
                                                        icon="circle"
                                                        className="mr-1"
                                                      />
                                                      39,852
                                                    </span>
                                                    <span className="view-count-1 mr-2 green-grey">
                                                      <FontAwesomeIcon
                                                        icon="circle"
                                                        className="mr-1"
                                                      />
                                                      852
                                                    </span>
                                                    .
                                                    <span className="reply-link">
                                                      Reply
                                                    </span>
                                                  </p>
                                                  <Button
                                                    className="view-all-reply reply-btn-click"
                                                    onClick={
                                                      this.handleToggleVisib
                                                    }
                                                  >
                                                    View all 5 replies
                                                    <FontAwesomeIcon
                                                      icon="chevron-down"
                                                      className="ml-1"
                                                    />
                                                    {this.state.visib ? "" : ""}
                                                  </Button>
                                                </Col>
                                                <Col sm={12} md={1}>
                                                  <Link to="#">
                                                    <Image
                                                      src="assets/img/flag.png"
                                                      className="menu-icons"
                                                    />
                                                  </Link>
                                                </Col>
                                              </Row>
                                            </div>
                                            <div
                                              className="comments-info reply-show"
                                              id="dialog_1"
                                            >
                                              {this.state.visib && (
                                                <Row>
                                                  <Col
                                                    sm={12}
                                                    md={2}
                                                    className="resp-column-none"
                                                  ></Col>
                                                  <Col sm={12} md={10}>
                                                    <Row>
                                                      <Col sm={12} md={2}>
                                                        <div className="comments-user-details">
                                                          <Image
                                                            src={
                                                              window.location
                                                                .origin +
                                                              "/assets/img/profile-img.jpg"
                                                            }
                                                            className="user-img"
                                                          />
                                                        </div>
                                                      </Col>
                                                      <Col sm={12} md={10}>
                                                        <p className="user-details">
                                                          <span className="user-name">
                                                            NaughtyDog .
                                                          </span>
                                                          <span className="post-date">
                                                            1 Weeks ago
                                                          </span>
                                                        </p>
                                                        <p className="user-description">
                                                          Really great story.
                                                          You're doing a great
                                                          job. Keep it up pal.
                                                        </p>
                                                        <p className="user-details-count">
                                                          <span className="view-count mr-2 green-clr">
                                                            <FontAwesomeIcon
                                                              icon="circle"
                                                              className="mr-1"
                                                            />
                                                            39,852
                                                          </span>
                                                          <span className="view-count-1 mr-2 green-grey">
                                                            <FontAwesomeIcon
                                                              icon="circle"
                                                              className="mr-1"
                                                            />
                                                            852
                                                          </span>
                                                          .
                                                          <span className="reply-link">
                                                            Reply
                                                          </span>
                                                        </p>
                                                      </Col>
                                                    </Row>
                                                  </Col>
                                                </Row>
                                              )}
                                            </div>
                                            <Row>
                                              <Col sm={12} md={12}>
                                                <div className="load-more-btn">
                                                  <Button className="btn-load-more">
                                                    Load more Comments
                                                  </Button>
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Tab.Pane>
                                      </Tab.Content>
                                    </Col>
                                  </Row>
                                </Tab.Container>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="jump-to">
                            <div className="jump-to-sec">
                              <h4 className="title">Jump to:</h4>
                              <div className="jump-to-info">
                                <ul className="list-unstyled jump-to-link">
                                  <Media as="li">
                                    <Link to="#">
                                      <h4 className="sub-title">
                                        Introduction
                                        <span className="count-time">
                                          0:00 - 2:16
                                        </span>
                                      </h4>
                                    </Link>
                                  </Media>
                                  <Media as="li">
                                    <Link to="#">
                                      <h4 className="sub-title">
                                        Gameplay
                                        <span className="count-time">
                                          2:17 - 3:19
                                        </span>
                                      </h4>
                                    </Link>
                                  </Media>
                                  <Media as="li" className="active">
                                    <Link to="#">
                                      <h4 className="sub-title">
                                        Cut Scene
                                        <span className="count-time">
                                          3:20 - 8:33
                                        </span>
                                      </h4>
                                    </Link>
                                  </Media>
                                  <Media as="li">
                                    <Link to="#">
                                      <h4 className="sub-title">
                                        Review
                                        <span className="count-time">
                                          8:34 - 9:27
                                        </span>
                                      </h4>
                                    </Link>
                                  </Media>
                                  <Media as="li">
                                    <Link to="#">
                                      <h4 className="sub-title">
                                        Overall Rating
                                        <span className="count-time">
                                          9:28 - 11:06
                                        </span>
                                      </h4>
                                    </Link>
                                  </Media>
                                </ul>
                              </div>
                            </div>
                            <div className="google-adsense-add-sec">
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/img/video-1.jpg"
                                }
                                className="addsense-add-img"
                              />
                            </div>
                            <div className="similar-video-sec">
                              <h6 className="head-title">Similar Videos</h6>
                              <Row>
                                <Col xs={12} md={3}>
                                  <Link to="#">
                                    <div className="similar-video-card">
                                      <div className="similar-video-card-img">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/video-1.jpg"
                                          }
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="similar-video-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New
                                          Gameplay - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                        </p>
                                        <p className="desc">
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-2"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                                <Col xs={12} md={3}>
                                  <Link to="#">
                                    <div className="similar-video-card">
                                      <div className="similar-video-card-img">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/video-1.jpg"
                                          }
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="similar-video-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New
                                          Gameplay - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                        </p>
                                        <p className="desc">
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-2"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                                <Col xs={12} md={3}>
                                  <Link to="#">
                                    <div className="similar-video-card">
                                      <div className="similar-video-card-img">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/video-1.jpg"
                                          }
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="similar-video-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New
                                          Gameplay - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                        </p>
                                        <p className="desc">
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-2"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                                <Col xs={12} md={3}>
                                  <Link to="#">
                                    <div className="similar-video-card">
                                      <div className="similar-video-card-img">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/video-1.jpg"
                                          }
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="similar-video-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New
                                          Gameplay - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                        </p>
                                        <p className="desc">
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-2"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                              </Row>
                            </div>
                            <div className="single-video-about-comments-sec">
                              <div className="single-video-comments-header">
                                <h4 className="head-title">
                                  <Image
                                    src="assets/img/comment.png"
                                    className="comments-icons"
                                  />
                                  236
                                  <span className="small-title">Comments</span>
                                </h4>
                              </div>
                              <div className="single-video-comments-body">
                                <Row>
                                  <Col xs={12} md={2}>
                                    <div className="video-user-details">
                                      <Image
                                        src={
                                          window.location.origin +
                                          "/assets/img/profile-img.jpg"
                                        }
                                        className="user-img"
                                      />
                                    </div>
                                  </Col>
                                  <Col xs={12} md={10}>
                                    <Form className="comments-form">
                                      <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Control as="textarea" rows="3" />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                </Row>
                              </div>
                              <div className="single-video-comments-sec-tabs">
                                <Tab.Container
                                  id="left-tabs-example"
                                  defaultActiveKey="popular-comments"
                                >
                                  <Row>
                                    <Col sm={12} md={12}>
                                      <Nav variant="pills">
                                        <Nav.Item>
                                          <Nav.Link
                                            eventKey="popular-comments"
                                            className="padding-left-zero"
                                          >
                                            Popular Comments
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="newest-comments">
                                            Newest Comments
                                          </Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </Col>
                                    <Col sm={12} md={12}>
                                      <Tab.Content>
                                        <Tab.Pane eventKey="popular-comments">
                                          <div className="comment-tabs-sec">
                                            <div className="comments-info">
                                              <Row>
                                                <Col sm={12} md={2}>
                                                  <div className="comments-user-details">
                                                    <Image
                                                      src={
                                                        window.location.origin +
                                                        "/assets/img/profile-img.jpg"
                                                      }
                                                      className="user-img"
                                                    />
                                                  </div>
                                                </Col>
                                                <Col sm={12} md={9}>
                                                  <p className="user-details">
                                                    <span className="user-name">
                                                      NaughtyDog .
                                                    </span>
                                                    <span className="post-date">
                                                      1 Weeks ago
                                                    </span>
                                                  </p>
                                                  <p className="user-description">
                                                    Really great story. You're
                                                    doing a great job. Keep it
                                                    up pal.
                                                  </p>
                                                  <p className="user-details-count">
                                                    <span className="view-count mr-2 green-clr">
                                                      <FontAwesomeIcon
                                                        icon="circle"
                                                        className="mr-1"
                                                      />
                                                      39,852
                                                    </span>
                                                    <span className="view-count-1 mr-2 green-grey">
                                                      <FontAwesomeIcon
                                                        icon="circle"
                                                        className="mr-1"
                                                      />
                                                      852
                                                    </span>
                                                    .
                                                    <span className="reply-link">
                                                      Reply
                                                    </span>
                                                  </p>
                                                  <Button
                                                    className="view-all-reply reply-btn-click"
                                                    onClick={
                                                      this.handleToggleVisib
                                                    }
                                                  >
                                                    View all 5 replies
                                                    <FontAwesomeIcon
                                                      icon="chevron-down"
                                                      className="ml-1"
                                                    />
                                                    {this.state.visib ? "" : ""}
                                                  </Button>
                                                </Col>
                                                <Col sm={12} md={1}>
                                                  <Link to="#">
                                                    <Image
                                                      src="assets/img/flag.png"
                                                      className="menu-icons"
                                                    />
                                                  </Link>
                                                </Col>
                                              </Row>
                                            </div>
                                            <div
                                              className="comments-info reply-show"
                                              id="dialog_1"
                                            >
                                              {this.state.visib && (
                                                <Row>
                                                  <Col
                                                    sm={12}
                                                    md={2}
                                                    className="resp-column-none"
                                                  ></Col>
                                                  <Col sm={12} md={10}>
                                                    <Row>
                                                      <Col sm={12} md={2}>
                                                        <div className="comments-user-details">
                                                          <Image
                                                            src={
                                                              window.location
                                                                .origin +
                                                              "/assets/img/profile-img.jpg"
                                                            }
                                                            className="user-img"
                                                          />
                                                        </div>
                                                      </Col>
                                                      <Col sm={12} md={10}>
                                                        <p className="user-details">
                                                          <span className="user-name">
                                                            NaughtyDog .
                                                          </span>
                                                          <span className="post-date">
                                                            1 Weeks ago
                                                          </span>
                                                        </p>
                                                        <p className="user-description">
                                                          Really great story.
                                                          You're doing a great
                                                          job. Keep it up pal.
                                                        </p>
                                                        <p className="user-details-count">
                                                          <span className="view-count mr-2 green-clr">
                                                            <FontAwesomeIcon
                                                              icon="circle"
                                                              className="mr-1"
                                                            />
                                                            39,852
                                                          </span>
                                                          <span className="view-count-1 mr-2 green-grey">
                                                            <FontAwesomeIcon
                                                              icon="circle"
                                                              className="mr-1"
                                                            />
                                                            852
                                                          </span>
                                                          .
                                                          <span className="reply-link">
                                                            Reply
                                                          </span>
                                                        </p>
                                                      </Col>
                                                    </Row>
                                                  </Col>
                                                </Row>
                                              )}
                                            </div>
                                            <Row>
                                              <Col sm={12} md={12}>
                                                <div className="load-more-btn">
                                                  <Button className="btn-load-more">
                                                    Load more Comments
                                                  </Button>
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="newest-comments">
                                          <div className="comment-tabs-sec">
                                            <div className="comments-info">
                                              <Row>
                                                <Col sm={12} md={2}>
                                                  <div className="comments-user-details">
                                                    <Image
                                                      src={
                                                        window.location.origin +
                                                        "/assets/img/profile-img.jpg"
                                                      }
                                                      className="user-img"
                                                    />
                                                  </div>
                                                </Col>
                                                <Col sm={12} md={9}>
                                                  <p className="user-details">
                                                    <span className="user-name">
                                                      NaughtyDog .
                                                    </span>
                                                    <span className="post-date">
                                                      1 Weeks ago
                                                    </span>
                                                  </p>
                                                  <p className="user-description">
                                                    Really great story. You're
                                                    doing a great job. Keep it
                                                    up pal.
                                                  </p>
                                                  <p className="user-details-count">
                                                    <span className="view-count mr-2 green-clr">
                                                      <FontAwesomeIcon
                                                        icon="circle"
                                                        className="mr-1"
                                                      />
                                                      39,852
                                                    </span>
                                                    <span className="view-count-1 mr-2 green-grey">
                                                      <FontAwesomeIcon
                                                        icon="circle"
                                                        className="mr-1"
                                                      />
                                                      852
                                                    </span>
                                                    .
                                                    <span className="reply-link">
                                                      Reply
                                                    </span>
                                                  </p>
                                                  <Button
                                                    className="view-all-reply reply-btn-click"
                                                    onClick={
                                                      this.handleToggleVisib
                                                    }
                                                  >
                                                    View all 5 replies
                                                    <FontAwesomeIcon
                                                      icon="chevron-down"
                                                      className="ml-1"
                                                    />
                                                    {this.state.visib ? "" : ""}
                                                  </Button>
                                                </Col>
                                                <Col sm={12} md={1}>
                                                  <Link to="#">
                                                    <Image
                                                      src="assets/img/flag.png"
                                                      className="menu-icons"
                                                    />
                                                  </Link>
                                                </Col>
                                              </Row>
                                            </div>
                                            <div
                                              className="comments-info reply-show"
                                              id="dialog_1"
                                            >
                                              {this.state.visib && (
                                                <Row>
                                                  <Col
                                                    sm={12}
                                                    md={2}
                                                    className="resp-column-none"
                                                  ></Col>
                                                  <Col sm={12} md={10}>
                                                    <Row>
                                                      <Col sm={12} md={2}>
                                                        <div className="comments-user-details">
                                                          <Image
                                                            src={
                                                              window.location
                                                                .origin +
                                                              "/assets/img/profile-img.jpg"
                                                            }
                                                            className="user-img"
                                                          />
                                                        </div>
                                                      </Col>
                                                      <Col sm={12} md={10}>
                                                        <p className="user-details">
                                                          <span className="user-name">
                                                            NaughtyDog .
                                                          </span>
                                                          <span className="post-date">
                                                            1 Weeks ago
                                                          </span>
                                                        </p>
                                                        <p className="user-description">
                                                          Really great story.
                                                          You're doing a great
                                                          job. Keep it up pal.
                                                        </p>
                                                        <p className="user-details-count">
                                                          <span className="view-count mr-2 green-clr">
                                                            <FontAwesomeIcon
                                                              icon="circle"
                                                              className="mr-1"
                                                            />
                                                            39,852
                                                          </span>
                                                          <span className="view-count-1 mr-2 green-grey">
                                                            <FontAwesomeIcon
                                                              icon="circle"
                                                              className="mr-1"
                                                            />
                                                            852
                                                          </span>
                                                          .
                                                          <span className="reply-link">
                                                            Reply
                                                          </span>
                                                        </p>
                                                      </Col>
                                                    </Row>
                                                  </Col>
                                                </Row>
                                              )}
                                            </div>
                                            <Row>
                                              <Col sm={12} md={12}>
                                                <div className="load-more-btn">
                                                  <Button className="btn-load-more">
                                                    Load more Comments
                                                  </Button>
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Tab.Pane>
                                      </Tab.Content>
                                    </Col>
                                  </Row>
                                </Tab.Container>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="add-to">
                            <div className="add-to-sec">
                              <h4 className="title">Add to:</h4>
                              <div className="add-to-info">
                                <ul className="list-unstyled add-to-link">
                                  <Media as="li">
                                    <Link to="#">
                                      <h4 className="sub-title">
                                        <span className="add-to-icons">
                                          <FontAwesomeIcon
                                            icon={["fas", "clock"]}
                                            className="mr-2"
                                          />
                                          Watch Later
                                        </span>
                                        <span className="count-time">
                                          <FontAwesomeIcon
                                            icon={["fas", "plus"]}
                                          />
                                        </span>
                                      </h4>
                                    </Link>
                                  </Media>
                                  <Media as="li">
                                    <Link to="#">
                                      <h4 className="sub-title">
                                        <span className="add-to-icons">
                                          <FontAwesomeIcon
                                            icon={["fas", "bars"]}
                                            className="mr-2"
                                          />
                                          Gameplay Playlist
                                        </span>
                                        <span className="count-time">
                                          <FontAwesomeIcon
                                            icon={["fas", "plus"]}
                                          />
                                        </span>
                                      </h4>
                                    </Link>
                                  </Media>
                                  <Media as="li" className="active">
                                    <Link to="#">
                                      <h4 className="sub-title">
                                        <span className="add-to-icons">
                                          <FontAwesomeIcon
                                            icon={["fas", "bars"]}
                                            className="mr-2"
                                          />
                                          Review Videos
                                        </span>
                                        <span className="count-time">
                                          <FontAwesomeIcon
                                            icon={["fas", "plus"]}
                                          />
                                        </span>
                                      </h4>
                                    </Link>
                                  </Media>
                                  <Media as="li">
                                    <Link to="#">
                                      <h4 className="sub-title">
                                        <span className="add-to-icons">
                                          <FontAwesomeIcon
                                            icon={["fas", "bars"]}
                                            className="mr-2"
                                          />
                                          Tech Updates
                                        </span>
                                        <span className="count-time">
                                          <FontAwesomeIcon
                                            icon={["fas", "plus"]}
                                          />
                                        </span>
                                      </h4>
                                    </Link>
                                  </Media>
                                  <Media as="li">
                                    <Link to="#">
                                      <h4 className="sub-title">
                                        <span className="add-to-icons">
                                          <FontAwesomeIcon
                                            icon={["fas", "bars"]}
                                            className="mr-2"
                                          />
                                          Create New Playlist
                                        </span>
                                        <span className="count-time">
                                          <FontAwesomeIcon
                                            icon={["fas", "plus"]}
                                          />
                                        </span>
                                      </h4>
                                    </Link>
                                  </Media>
                                </ul>
                              </div>
                            </div>
                            <div className="google-adsense-add-sec">
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/img/video-1.jpg"
                                }
                                className="addsense-add-img"
                              />
                            </div>
                            <div className="similar-video-sec">
                              <h6 className="head-title">Similar Videos</h6>
                              <Row>
                                <Col xs={12} md={3}>
                                  <Link to="#">
                                    <div className="similar-video-card">
                                      <div className="similar-video-card-img">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/video-1.jpg"
                                          }
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="similar-video-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New
                                          Gameplay - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                        </p>
                                        <p className="desc">
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-2"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                                <Col xs={12} md={3}>
                                  <Link to="#">
                                    <div className="similar-video-card">
                                      <div className="similar-video-card-img">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/video-1.jpg"
                                          }
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="similar-video-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New
                                          Gameplay - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                        </p>
                                        <p className="desc">
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-2"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                                <Col xs={12} md={3}>
                                  <Link to="#">
                                    <div className="similar-video-card">
                                      <div className="similar-video-card-img">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/video-1.jpg"
                                          }
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="similar-video-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New
                                          Gameplay - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                        </p>
                                        <p className="desc">
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-2"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                                <Col xs={12} md={3}>
                                  <Link to="#">
                                    <div className="similar-video-card">
                                      <div className="similar-video-card-img">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/video-1.jpg"
                                          }
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="similar-video-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New
                                          Gameplay - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                        </p>
                                        <p className="desc">
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-2"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                              </Row>
                            </div>
                            <div className="single-video-about-comments-sec">
                              <div className="single-video-comments-header">
                                <h4 className="head-title">
                                  <Image
                                    src="assets/img/comment.png"
                                    className="comments-icons"
                                  />
                                  236
                                  <span className="small-title">Comments</span>
                                </h4>
                              </div>
                              <div className="single-video-comments-body">
                                <Row>
                                  <Col xs={12} md={2}>
                                    <div className="video-user-details">
                                      <Image
                                        src={
                                          window.location.origin +
                                          "/assets/img/profile-img.jpg"
                                        }
                                        className="user-img"
                                      />
                                    </div>
                                  </Col>
                                  <Col xs={12} md={10}>
                                    <Form className="comments-form">
                                      <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Control as="textarea" rows="3" />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                </Row>
                              </div>
                              <div className="single-video-comments-sec-tabs">
                                <Tab.Container
                                  id="left-tabs-example"
                                  defaultActiveKey="popular-comments"
                                >
                                  <Row>
                                    <Col sm={12} md={12}>
                                      <Nav variant="pills">
                                        <Nav.Item>
                                          <Nav.Link
                                            eventKey="popular-comments"
                                            className="padding-left-zero"
                                          >
                                            Popular Comments
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="newest-comments">
                                            Newest Comments
                                          </Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </Col>
                                    <Col sm={12} md={12}>
                                      <Tab.Content>
                                        <Tab.Pane eventKey="popular-comments">
                                          <div className="comment-tabs-sec">
                                            <div className="comments-info">
                                              <Row>
                                                <Col sm={12} md={2}>
                                                  <div className="comments-user-details">
                                                    <Image
                                                      src={
                                                        window.location.origin +
                                                        "/assets/img/profile-img.jpg"
                                                      }
                                                      className="user-img"
                                                    />
                                                  </div>
                                                </Col>
                                                <Col sm={12} md={9}>
                                                  <p className="user-details">
                                                    <span className="user-name">
                                                      NaughtyDog .
                                                    </span>
                                                    <span className="post-date">
                                                      1 Weeks ago
                                                    </span>
                                                  </p>
                                                  <p className="user-description">
                                                    Really great story. You're
                                                    doing a great job. Keep it
                                                    up pal.
                                                  </p>
                                                  <p className="user-details-count">
                                                    <span className="view-count mr-2 green-clr">
                                                      <FontAwesomeIcon
                                                        icon="circle"
                                                        className="mr-1"
                                                      />
                                                      39,852
                                                    </span>
                                                    <span className="view-count-1 mr-2 green-grey">
                                                      <FontAwesomeIcon
                                                        icon="circle"
                                                        className="mr-1"
                                                      />
                                                      852
                                                    </span>
                                                    .
                                                    <span className="reply-link">
                                                      Reply
                                                    </span>
                                                  </p>
                                                  <Button
                                                    className="view-all-reply reply-btn-click"
                                                    onClick={
                                                      this.handleToggleVisib
                                                    }
                                                  >
                                                    View all 5 replies
                                                    <FontAwesomeIcon
                                                      icon="chevron-down"
                                                      className="ml-1"
                                                    />
                                                    {this.state.visib ? "" : ""}
                                                  </Button>
                                                </Col>
                                                <Col sm={12} md={1}>
                                                  <Link to="#">
                                                    <Image
                                                      src="assets/img/flag.png"
                                                      className="menu-icons"
                                                    />
                                                  </Link>
                                                </Col>
                                              </Row>
                                            </div>
                                            <div
                                              className="comments-info reply-show"
                                              id="dialog_1"
                                            >
                                              {this.state.visib && (
                                                <Row>
                                                  <Col
                                                    sm={12}
                                                    md={2}
                                                    className="resp-column-none"
                                                  ></Col>
                                                  <Col sm={12} md={10}>
                                                    <Row>
                                                      <Col sm={12} md={2}>
                                                        <div className="comments-user-details">
                                                          <Image
                                                            src={
                                                              window.location
                                                                .origin +
                                                              "/assets/img/profile-img.jpg"
                                                            }
                                                            className="user-img"
                                                          />
                                                        </div>
                                                      </Col>
                                                      <Col sm={12} md={10}>
                                                        <p className="user-details">
                                                          <span className="user-name">
                                                            NaughtyDog .
                                                          </span>
                                                          <span className="post-date">
                                                            1 Weeks ago
                                                          </span>
                                                        </p>
                                                        <p className="user-description">
                                                          Really great story.
                                                          You're doing a great
                                                          job. Keep it up pal.
                                                        </p>
                                                        <p className="user-details-count">
                                                          <span className="view-count mr-2 green-clr">
                                                            <FontAwesomeIcon
                                                              icon="circle"
                                                              className="mr-1"
                                                            />
                                                            39,852
                                                          </span>
                                                          <span className="view-count-1 mr-2 green-grey">
                                                            <FontAwesomeIcon
                                                              icon="circle"
                                                              className="mr-1"
                                                            />
                                                            852
                                                          </span>
                                                          .
                                                          <span className="reply-link">
                                                            Reply
                                                          </span>
                                                        </p>
                                                      </Col>
                                                    </Row>
                                                  </Col>
                                                </Row>
                                              )}
                                            </div>
                                            <Row>
                                              <Col sm={12} md={12}>
                                                <div className="load-more-btn">
                                                  <Button className="btn-load-more">
                                                    Load more Comments
                                                  </Button>
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="newest-comments">
                                          <div className="comment-tabs-sec">
                                            <div className="comments-info">
                                              <Row>
                                                <Col sm={12} md={2}>
                                                  <div className="comments-user-details">
                                                    <Image
                                                      src={
                                                        window.location.origin +
                                                        "/assets/img/profile-img.jpg"
                                                      }
                                                      className="user-img"
                                                    />
                                                  </div>
                                                </Col>
                                                <Col sm={12} md={9}>
                                                  <p className="user-details">
                                                    <span className="user-name">
                                                      NaughtyDog .
                                                    </span>
                                                    <span className="post-date">
                                                      1 Weeks ago
                                                    </span>
                                                  </p>
                                                  <p className="user-description">
                                                    Really great story. You're
                                                    doing a great job. Keep it
                                                    up pal.
                                                  </p>
                                                  <p className="user-details-count">
                                                    <span className="view-count mr-2 green-clr">
                                                      <FontAwesomeIcon
                                                        icon="circle"
                                                        className="mr-1"
                                                      />
                                                      39,852
                                                    </span>
                                                    <span className="view-count-1 mr-2 green-grey">
                                                      <FontAwesomeIcon
                                                        icon="circle"
                                                        className="mr-1"
                                                      />
                                                      852
                                                    </span>
                                                    .
                                                    <span className="reply-link">
                                                      Reply
                                                    </span>
                                                  </p>
                                                  <Button
                                                    className="view-all-reply reply-btn-click"
                                                    onClick={
                                                      this.handleToggleVisib
                                                    }
                                                  >
                                                    View all 5 replies
                                                    <FontAwesomeIcon
                                                      icon="chevron-down"
                                                      className="ml-1"
                                                    />
                                                    {this.state.visib ? "" : ""}
                                                  </Button>
                                                </Col>
                                                <Col sm={12} md={1}>
                                                  <Link to="#">
                                                    <Image
                                                      src="assets/img/flag.png"
                                                      className="menu-icons"
                                                    />
                                                  </Link>
                                                </Col>
                                              </Row>
                                            </div>
                                            <div
                                              className="comments-info reply-show"
                                              id="dialog_1"
                                            >
                                              {this.state.visib && (
                                                <Row>
                                                  <Col
                                                    sm={12}
                                                    md={2}
                                                    className="resp-column-none"
                                                  ></Col>
                                                  <Col sm={12} md={10}>
                                                    <Row>
                                                      <Col sm={12} md={2}>
                                                        <div className="comments-user-details">
                                                          <Image
                                                            src={
                                                              window.location
                                                                .origin +
                                                              "/assets/img/profile-img.jpg"
                                                            }
                                                            className="user-img"
                                                          />
                                                        </div>
                                                      </Col>
                                                      <Col sm={12} md={10}>
                                                        <p className="user-details">
                                                          <span className="user-name">
                                                            NaughtyDog .
                                                          </span>
                                                          <span className="post-date">
                                                            1 Weeks ago
                                                          </span>
                                                        </p>
                                                        <p className="user-description">
                                                          Really great story.
                                                          You're doing a great
                                                          job. Keep it up pal.
                                                        </p>
                                                        <p className="user-details-count">
                                                          <span className="view-count mr-2 green-clr">
                                                            <FontAwesomeIcon
                                                              icon="circle"
                                                              className="mr-1"
                                                            />
                                                            39,852
                                                          </span>
                                                          <span className="view-count-1 mr-2 green-grey">
                                                            <FontAwesomeIcon
                                                              icon="circle"
                                                              className="mr-1"
                                                            />
                                                            852
                                                          </span>
                                                          .
                                                          <span className="reply-link">
                                                            Reply
                                                          </span>
                                                        </p>
                                                      </Col>
                                                    </Row>
                                                  </Col>
                                                </Row>
                                              )}
                                            </div>
                                            <Row>
                                              <Col sm={12} md={12}>
                                                <div className="load-more-btn">
                                                  <Button className="btn-load-more">
                                                    Load more Comments
                                                  </Button>
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Tab.Pane>
                                      </Tab.Content>
                                    </Col>
                                  </Row>
                                </Tab.Container>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="up-next-right-sec d-none d-sm-block">
                  <div className="up-next-right-header">
                    <h4 className="side-title">Uncharted 4 Full Gameplay</h4>
                    <ul className="list-unstyled video-with-playlist-icons">
                      <Media as="li">
                        <Link to="#">
                          <FontAwesomeIcon
                            icon={["fas", "heart"]}
                            className="menu-icon"
                          />
                        </Link>
                      </Media>
                      <Media as="li">
                        <Link to="#">
                          <FontAwesomeIcon
                            icon={["fas", "plus"]}
                            className="menu-icon"
                          />
                        </Link>
                      </Media>
                    </ul>
                  </div>
                  <div className="up-next-right-video-sec">
                    <Link to="#">
                      <div className="up-next-right-video-card">
                        <Row>
                          <Col sm={12} md={1} xl={1}>
                            <h3 className="playlists-count">1</h3>
                          </Col>
                          <Col sm={12} md={5} xl={5}>
                            <div className="up-next-right-video-card-img">
                              <img
                                src={
                                  window.location.origin +
                                  "/assets/img/video-1.jpg"
                                }
                                className="list-video-img"
                              />
                            </div>
                            <div className="time-count">
                              <h6>15:19</h6>
                            </div>
                          </Col>
                          <Col sm={12} md={5} xl={6}>
                            <div className="up-next-video-card-details">
                              <h4 className="title">
                                No Man's Sky: 21 Minutes of New Gameplay - IGN
                                First
                              </h4>
                              <p className="desc">
                                <span className="view-count">27,548 views</span>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Link>
                    <Link to="#">
                      <div className="up-next-right-video-card">
                        <Row>
                          <Col sm={12} md={1} xl={1}>
                            <h3 className="playlists-count">2</h3>
                          </Col>
                          <Col sm={12} md={5} xl={5}>
                            <div className="up-next-right-video-card-img">
                              <img
                                src={
                                  window.location.origin +
                                  "/assets/img/video-1.jpg"
                                }
                                className="list-video-img"
                              />
                            </div>
                            <div className="time-count">
                              <h6>15:19</h6>
                            </div>
                          </Col>
                          <Col sm={12} md={5} xl="6">
                            <div className="up-next-video-card-details">
                              <h4 className="title">
                                No Man's Sky: 21 Minutes of New Gameplay - IGN
                                First
                              </h4>
                              <p className="desc">
                                <span className="view-count">27,548 views</span>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Link>
                    <Link to="#">
                      <div className="up-next-right-video-card">
                        <Row>
                          <Col sm={12} md={1} xl={1}>
                            <h3 className="playlists-count active">
                              <FontAwesomeIcon
                                icon={["fas", "play"]}
                                className="play-icon"
                              />
                            </h3>
                          </Col>
                          <Col sm={12} md={5} xl={5}>
                            <div className="up-next-right-video-card-img">
                              <img
                                src={
                                  window.location.origin +
                                  "/assets/img/video-1.jpg"
                                }
                                className="list-video-img"
                              />
                            </div>
                            <div className="time-count">
                              <h6>15:19</h6>
                            </div>
                          </Col>
                          <Col sm={12} md={5} xl={6}>
                            <div className="up-next-video-card-details">
                              <h4 className="title">
                                No Man's Sky: 21 Minutes of New Gameplay - IGN
                                First
                              </h4>
                              <p className="desc">
                                <span className="view-count">27,548 views</span>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Link>
                    <Link to="#">
                      <div className="up-next-right-video-card">
                        <Row>
                          <Col sm={12} md={1} xl={1}>
                            <h3 className="playlists-count">3</h3>
                          </Col>
                          <Col sm={12} md={5} xl={5}>
                            <div className="up-next-right-video-card-img">
                              <img
                                src={
                                  window.location.origin +
                                  "/assets/img/video-1.jpg"
                                }
                                className="list-video-img"
                              />
                            </div>
                            <div className="time-count">
                              <h6>15:19</h6>
                            </div>
                          </Col>
                          <Col sm={12} md={5} xl={6}>
                            <div className="up-next-video-card-details">
                              <h4 className="title">
                                No Man's Sky: 21 Minutes of New Gameplay - IGN
                                First
                              </h4>
                              <p className="desc">
                                <span className="view-count">27,548 views</span>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Link>
                  </div>
                  <div className="up-next-right-add-sec">
                    <div>
                      <img
                        src={window.location.origin + "/assets/img/video-1.jpg"}
                        className="add-sec-img"
                      />
                    </div>
                  </div>
                </div>
                <div className="up-next-right-sec">
                  <div className="up-next-right-header">
                    <h4 className="side-title d-none d-sm-block">
                      Recomended Videos
                    </h4>
                    {/* <Form className="toggle-switch d-none d-sm-flex">
                      <span className="mr-2 switch-title">Auto Play</span>
                      <Form.Check type="switch" id="custom-switch" label="" />
                    </Form> */}
                  </div>
                  <div className="playlist-resp-btn d-flex d-sm-none">
                    <ul className="list-unstyled">
                      <Media as="li">
                        <Link to="#">
                          <FontAwesomeIcon
                            icon={["fas", "fast-backward"]}
                            className="action-icon"
                          />
                        </Link>
                      </Media>
                      <Media as="li">
                        <Link to="#">
                          <FontAwesomeIcon
                            icon={["fas", "fast-forward"]}
                            className="action-icon"
                          />
                        </Link>
                      </Media>
                      <Media as="li">
                        <Link to="#">
                          <FontAwesomeIcon
                            icon={["fas", "random"]}
                            className="action-icon"
                          />
                        </Link>
                      </Media>
                      <Media as="li">
                        <Link to="#">
                          <FontAwesomeIcon
                            icon={["fas", "sync-alt"]}
                            className="action-icon"
                          />
                        </Link>
                      </Media>
                      <Media as="li">
                        <h4 className="playlist-count">1/56</h4>
                      </Media>
                    </ul>
                  </div>
                  <div className="up-next-right-video-sec border-bottom-2">
                    <Link to="#">
                      <div className="up-next-right-video-card">
                        <Row>
                          <Col xs={6} sm={6} md={6}>
                            <div className="up-next-right-video-card-img">
                              <img
                                src={
                                  window.location.origin +
                                  "/assets/img/video-1.jpg"
                                }
                                className="list-video-img"
                              />
                            </div>
                            <div className="time-count">
                              <h6>15:19</h6>
                            </div>
                          </Col>
                          <Col xs={6} sm={6} md={6}>
                            <div className="up-next-video-card-details">
                              <h4 className="title">
                                No Man's Sky: 21 Minutes of New Gameplay - IGN
                                First
                              </h4>
                              <p className="desc">
                                <span className="view-count">27,548 views</span>
                              </p>
                              <p className="desc">
                                <span className="percentage-count green-clr">
                                  <FontAwesomeIcon
                                    icon="circle"
                                    className="mr-2"
                                  />
                                  93%
                                </span>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Link>
                    <Link to="#">
                      <div className="up-next-right-video-card">
                        <Row>
                          <Col xs={6} sm={6} md={6}>
                            <div className="up-next-right-video-card-img">
                              <img
                                src={
                                  window.location.origin +
                                  "/assets/img/video-1.jpg"
                                }
                                className="list-video-img"
                              />
                            </div>
                            <div className="time-count">
                              <h6>15:19</h6>
                            </div>
                          </Col>
                          <Col xs={6} sm={6} md={6}>
                            <div className="up-next-video-card-details">
                              <h4 className="title">
                                No Man's Sky: 21 Minutes of New Gameplay - IGN
                                First
                              </h4>
                              <p className="desc">
                                <span className="view-count">27,548 views</span>
                              </p>
                              <p className="desc">
                                <span className="percentage-count green-clr">
                                  <FontAwesomeIcon
                                    icon="circle"
                                    className="mr-2"
                                  />
                                  93%
                                </span>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Link>
                    <Link to="#">
                      <div className="up-next-right-video-card">
                        <Row>
                          <Col xs={6} sm={6} md={6}>
                            <div className="up-next-right-video-card-img">
                              <img
                                src={
                                  window.location.origin +
                                  "/assets/img/video-1.jpg"
                                }
                                className="list-video-img"
                              />
                            </div>
                            <div className="time-count">
                              <h6>15:19</h6>
                            </div>
                          </Col>
                          <Col xs={6} sm={6} md={6}>
                            <div className="up-next-video-card-details">
                              <h4 className="title">
                                No Man's Sky: 21 Minutes of New Gameplay - IGN
                                First
                              </h4>
                              <p className="desc">
                                <span className="view-count">27,548 views</span>
                              </p>
                              <p className="desc">
                                <span className="percentage-count green-clr">
                                  <FontAwesomeIcon
                                    icon="circle"
                                    className="mr-2"
                                  />
                                  93%
                                </span>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Link>
                    <Link to="#">
                      <div className="up-next-right-video-card last-card">
                        <Row>
                          <Col xs={6} sm={6} md={6}>
                            <div className="up-next-right-video-card-img">
                              <img
                                src={
                                  window.location.origin +
                                  "/assets/img/video-1.jpg"
                                }
                                className="list-video-img"
                              />
                            </div>
                            <div className="video-watched">
                              <h6>Watched</h6>
                            </div>
                            <div className="time-count">
                              <h6>15:19</h6>
                            </div>
                          </Col>
                          <Col xs={6} sm={6} md={6}>
                            <div className="up-next-video-card-details">
                              <h4 className="title">
                                No Man's Sky: 21 Minutes of New Gameplay - IGN
                                First
                              </h4>
                              <p className="desc">
                                <span className="view-count">27,548 views</span>
                              </p>
                              <p className="desc">
                                <span className="percentage-count green-clr">
                                  <FontAwesomeIcon
                                    icon="circle"
                                    className="mr-2"
                                  />
                                  93%
                                </span>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Link>
                  </div>
                  <div className="resp-load-more-bottom d-flex d-sm-none">
                    <ul className="list-unstyled">
                      <Media as="li">
                        <Image
                          src="assets/img/mobile-arrow.png"
                          className="resp-arrow-bottom-2"
                        />
                      </Media>
                    </ul>
                  </div>
                  <div className="border-top-1 resp-mrg-btm-lg"></div>
                  <div className="show-more-sec d-none d-sm-block">
                    <Link to="#" className="btn btn-show-more">
                      Show more videos
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default VideoWithPlaylist;
