import {
  FETCH_CATEGORIES_START,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
  FETCH_CATEGORY_VIDEOS_START,
  FETCH_CATEGORY_VIDEOS_SUCCESS,
  FETCH_CATEGORY_VIDEOS_FAILURE,
  FETCH_SINGLE_CATEGORY_START,
  FETCH_SINGLE_CATEGORY_SUCCESS,
  FETCH_SINGLE_CATEGORY_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  categories: {
    data: {},
    loading: true,
    error: false,
  },
  categoryVideo: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  singleCategory: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
};

const CategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORIES_START:
      return {
        ...state,
        categories: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_CATEGORIES_FAILURE:
      return {
        ...state,
        categories: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case FETCH_CATEGORY_VIDEOS_START:
      return {
        ...state,
        categoryVideo: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_CATEGORY_VIDEOS_SUCCESS:
      return {
        ...state,
        categoryVideo: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_CATEGORY_VIDEOS_FAILURE:
      return {
        ...state,
        categoryVideo: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
      };

    case FETCH_SINGLE_CATEGORY_START:
      return {
        ...state,
        singleCategory: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_SINGLE_CATEGORY_SUCCESS:
      return {
        ...state,
        singleCategory: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_SINGLE_CATEGORY_FAILURE:
      return {
        ...state,
        singleCategory: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
      };

    default:
      return state;
  }
};

export default CategoryReducer;
