import {
  FETCH_HOMEPAGE_DATA_START,
  FETCH_HOMEPAGE_DATA_SUCCESS,
  FETCH_HOMEPAGE_DATA_FAILURE,
  FETCH_TRENDING_DATA_START,
  FETCH_TRENDING_DATA_SUCCESS,
  FETCH_TRENDING_DATA_FAILURE,
  FETCH_TAGS_DATA_START,
  FETCH_TAGS_DATA_SUCCESS,
  FETCH_TAGS_DATA_FAILURE,
  FETCH_CATEGORY_DATA_START,
  FETCH_CATEGORY_DATA_SUCCESS,
  FETCH_CATEGORY_DATA_FAILURE,
  FETCH_SUGGESTION_DATA_START,
  FETCH_SUGGESTION_DATA_SUCCESS,
  FETCH_SUGGESTION_DATA_FAILURE,
  FETCH_SEARCH_DATA_START,
  FETCH_SEARCH_DATA_SUCCESS,
  FETCH_SEARCH_DATA_FAILURE,
  FETCH_PREMIUM_DATA_START,
  FETCH_PREMIUM_DATA_SUCCESS,
  FETCH_PREMIUM_DATA_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  home: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  trending: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  tags: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  categories: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  suggestion: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  search: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  premium: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
};

const CoreDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HOMEPAGE_DATA_START:
      return {
        ...state,
        home: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_HOMEPAGE_DATA_SUCCESS:
      return {
        ...state,
        home: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_HOMEPAGE_DATA_FAILURE:
      return {
        ...state,
        home: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case FETCH_TRENDING_DATA_START:
      return {
        ...state,
        trending: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_TRENDING_DATA_SUCCESS:
      return {
        ...state,
        trending: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_TRENDING_DATA_FAILURE:
      return {
        ...state,
        trending: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
      };

    case FETCH_TAGS_DATA_START:
      return {
        ...state,
        tags: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_TAGS_DATA_SUCCESS:
      return {
        ...state,
        tags: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_TAGS_DATA_FAILURE:
      return {
        ...state,
        tags: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
      };

    case FETCH_CATEGORY_DATA_START:
      return {
        ...state,
        categories: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_CATEGORY_DATA_SUCCESS:
      return {
        ...state,
        categories: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_CATEGORY_DATA_FAILURE:
      return {
        ...state,
        categories: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
      };

    case FETCH_SUGGESTION_DATA_START:
      return {
        ...state,
        suggestion: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_SUGGESTION_DATA_SUCCESS:
      return {
        ...state,
        suggestion: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_SUGGESTION_DATA_FAILURE:
      return {
        ...state,
        suggestion: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
      };

    case FETCH_SEARCH_DATA_START:
      return {
        ...state,
        search: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case FETCH_SEARCH_DATA_SUCCESS:
      return {
        ...state,
        search: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case FETCH_SEARCH_DATA_FAILURE:
      return {
        ...state,
        search: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case FETCH_PREMIUM_DATA_START:
      return {
        ...state,
        premium: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_PREMIUM_DATA_SUCCESS:
      return {
        ...state,
        premium: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_PREMIUM_DATA_FAILURE:
      return {
        ...state,
        premium: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
      };

    default:
      return state;
  }
};

export default CoreDataReducer;
