import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "./VideoSingle.css";
import {
  Image,
  Navbar,
  Nav,
  Form,
  FormControl,
  Button,
  NavDropdown,
  InputGroup,
  Dropdown,
  Media,
  Row,
  Col,
  Modal,
  ModalFooter,
} from "react-bootstrap";

class VideoSingle extends Component {
  constructor(props) {
    super(props);
    this.handleToggleCreateForm = this.handleToggleCreateForm.bind(this);
    this.state = {
      shareModal: false,
      savetoModal: false,
      mobileSidebar: true,
    };
  }

  showShareModal = () => {
    this.setState({ shareModal: true });
  };

  closeShareModal = () => {
    this.setState({ shareModal: false });
  };

  showSaveToModal = () => {
    this.setState({ savetoModal: true });
  };

  closeSaveToModal = () => {
    this.setState({ savetoModal: false });
  };

  handleToggleCreateForm() {
    this.setState({ createForm: !this.state.createForm });
  }

  toggleMobileSidebar = () => {
    this.setState({
      mobileSidebar: !this.state.mobileSidebar,
    });
  };
  
  render() {
    return (
      <>
        <div className="video-app">
          <header>
            <Navbar
              collapseOnSelect
              expand="lg"
              className="navbar-expand-lg default-nav"
            >
              <div className="nav-icon" onClick={() => this.toggleMobileSidebar()}>
                <FontAwesomeIcon
                  icon={["fas", "align-left"]}
                  className="mr-3"
                  id="sidebarCollapse"
                />
              </div>
              <Navbar.Brand href="#home">
                <Image
                  src={
                    window.location.origin + "/assets/img/base-product/logo.png"
                  }
                  className="base-logo"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse
                id="responsive-navbar-nav"
                className="resp-padding-sm"
              >
                <ul className="navbar-nav mx-auto resp-padding-xs">
                  <InputGroup className="width-half-search">
                    <FormControl placeholder="Search" className="search-info" />
                    <InputGroup.Append>
                      <InputGroup.Text
                        id="basic-addon2"
                        className="search-info-1"
                      >
                        <FontAwesomeIcon
                          icon={["fas", "search"]}
                          className="mr-3"
                        />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </ul>
                <Dropdown className="resp-padding-xs video-create">
                  <Dropdown.Toggle className="button" id="dropdown-basic">
                    <FontAwesomeIcon icon={["fas", "video"]} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">
                      <FontAwesomeIcon
                        icon={["fas", "upload"]}
                        className="icon"
                      />
                      Upload Video
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      <i className="icofont-youtube-play"></i>Go Live
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="resp-padding-xs video-create">
                  <Dropdown.Toggle className="button" id="dropdown-basic">
                    <FontAwesomeIcon icon={["fas", "th"]} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="border-bottom-1">
                      <Dropdown.Item href="#/action-1">
                        <i className="icofont-youtube-play"></i>
                        StreamTube TV
                      </Dropdown.Item>
                    </div>
                    <div className="border-bottom-1">
                      <Dropdown.Item href="#/action-2">
                        <i className="icofont-youtube-play"></i>StreamTube Music
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        <i className="icofont-youtube-play"></i>StreamTube Kids
                      </Dropdown.Item>
                    </div>
                    <Dropdown.Item href="#/action-4">
                      <i className="icofont-youtube-play"></i>Creator Academy
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-5">
                      <i className="icofont-youtube-play"></i>StreamTube for
                      Artists
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="video-settings resp-padding-xs">
                  <Dropdown.Toggle className="button" id="dropdown-basic">
                    <FontAwesomeIcon icon={["fas", "bell"]} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">
                      <FontAwesomeIcon
                        icon={["fas", "upload"]}
                        className="icon"
                      />
                      Upload Video
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      <i className="icofont-youtube-play"></i>Go Live
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="video-settings resp-padding-xs">
                  <Dropdown.Toggle className="button" id="dropdown-basic">
                    <Image
                      src={
                        window.location.origin +
                        "/assets/img/base-product/profile-img.jpg"
                      }
                      className="nav-user-img"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="navbar-profile-content border-bottom-1">
                      <div className="user-profile-img">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/profile-img.jpg"
                          }
                          className="profile-img"
                        />
                      </div>
                      <div className="profile-info">
                        <h4 className="title">Beno P</h4>
                        <h5 className="desc">beno@codegama.com</h5>
                        <a href="#" className="link">
                          Manage your account
                        </a>
                      </div>
                    </div>
                    <div className="dorpdown-scroll">
                      <Dropdown.Item href="#/action-1">
                        <FontAwesomeIcon
                          icon={["fas", "cog"]}
                          className="icon"
                        />
                        Your Channel
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        <FontAwesomeIcon
                          icon={["fas", "cog"]}
                          className="icon"
                        />
                        Your Channel
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        <FontAwesomeIcon
                          icon={["fas", "cog"]}
                          className="icon"
                        />
                        Your Channel
                      </Dropdown.Item>
                      <div className="flex-content">
                        <Dropdown.Item href="#/action-4">
                          Switch Account
                          <span className="align-right">
                            <FontAwesomeIcon
                              icon={["fas", "chevron-right"]}
                              className="icon"
                            />
                          </span>
                        </Dropdown.Item>
                      </div>
                      <div className="border-bottom-1">
                        <Dropdown.Item href="#/action-5">
                          <FontAwesomeIcon
                            icon={["fas", "cog"]}
                            className="icon"
                          />
                          Your Channel
                        </Dropdown.Item>
                      </div>
                      <div className="flex-content">
                        <Dropdown.Item href="#/action-6">
                          <FontAwesomeIcon
                            icon={["fas", "sun"]}
                            className="icon"
                          />
                          Dark Theme: Off
                          <span className="align-right">
                            <FontAwesomeIcon
                              icon={["fas", "chevron-right"]}
                              className="icon"
                            />
                          </span>
                        </Dropdown.Item>
                      </div>
                      <div className="flex-content">
                        <Dropdown.Item href="#/action-7">
                          <FontAwesomeIcon
                            icon={["fas", "language"]}
                            className="icon"
                          />
                          Language: English
                          <span className="align-right">
                            <FontAwesomeIcon
                              icon={["fas", "chevron-right"]}
                              className="icon"
                            />
                          </span>
                        </Dropdown.Item>
                      </div>
                      <div className="flex-content">
                        <Dropdown.Item href="#/action-8">
                          <FontAwesomeIcon
                            icon={["fas", "globe"]}
                            className="icon"
                          />
                          Location: India
                          <span className="align-right">
                            <FontAwesomeIcon
                              icon={["fas", "chevron-right"]}
                              className="icon"
                            />
                          </span>
                        </Dropdown.Item>
                      </div>
                      <div className="flex-content">
                        <Dropdown.Item href="#/action-9">
                          <FontAwesomeIcon
                            icon={["fas", "cog"]}
                            className="icon"
                          />
                          Settings
                        </Dropdown.Item>
                      </div>
                      <div className="flex-content">
                        <Dropdown.Item href="#/action-10">
                          <FontAwesomeIcon
                            icon={["fas", "user-shield"]}
                            className="icon"
                          />
                          Your data in StreamTube
                        </Dropdown.Item>
                      </div>
                      <div className="flex-content">
                        <Dropdown.Item href="#/action-11">
                          <FontAwesomeIcon
                            icon={["fas", "question-circle"]}
                            className="icon"
                          />
                          Help
                        </Dropdown.Item>
                      </div>
                      <div className="flex-content">
                        <Dropdown.Item href="#/action-12">
                          <i className="icofont-ui-text-loading"></i>Send
                          feedback
                        </Dropdown.Item>
                      </div>
                      <div className="border-bottom-1">
                        <Dropdown.Item href="#/action-13">
                          <FontAwesomeIcon
                            icon={["fas", "keyboard"]}
                            className="icon"
                          />
                          keyboard shorcuts
                        </Dropdown.Item>
                      </div>
                      <div className="flex-content">
                        <Dropdown.Item href="#/action-14">
                          Restricted Mode: Off
                          <span className="align-right">
                            <FontAwesomeIcon
                              icon={["fas", "chevron-right"]}
                              className="icon"
                            />
                          </span>
                        </Dropdown.Item>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </Navbar.Collapse>
            </Navbar>
          </header>
          <div className="wrapper main-wrapper">
            <div className="left-side" id="sidebar" style={{
              display: this.state.mobileSidebar ? "block" : "none",
            }}>
              <div className="side-wrapper">
                <div className="side-menu">
                  <ul className="list-unstyled side-menu-item">
                    <Media as="li" className="active">
                      <i className="icofont-home"></i>Home
                    </Media>
                    <Media as="li">
                      <i className="material-icons playlist-icon-1">whatshot</i>
                      Trending
                    </Media>
                    <Media as="li">
                      <i className="material-icons playlist-icon-1">
                        subscriptions
                      </i>
                      Subscriptions
                    </Media>
                    <Media as="li">
                      <i className="material-icons playlist-icon-1">
                        video_library
                      </i>
                      Library
                    </Media>
                    <Media as="li">
                      <i className="icofont-history"></i>History
                    </Media>
                    <Media as="li">
                      <i className="material-icons playlist-icon-1">
                        slideshow
                      </i>
                      Your videos
                    </Media>
                    <Media as="li">
                      <i className="material-icons playlist-icon-1">
                        watch_later
                      </i>
                      Watch later
                    </Media>
                    <Media as="li">
                      <i className="material-icons playlist-icon-1">thumb_up</i>
                      Liked videos
                    </Media>
                  </ul>
                  <hr></hr>
                  <div className="best-video-sec">
                    <h3 className="best-title-1">SUBSCRIPTIONS</h3>
                    <ul className="list-unstyled side-menu-item">
                      <Media as="li">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/channel-img-1.jpg"
                          }
                          className="base-logo"
                        />
                        Cheetah Media
                      </Media>
                      <Media as="li">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/valis-kitchen.jpg"
                          }
                          className="base-logo"
                        />
                        Valli's Kitchen
                      </Media>
                      <Media as="li">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/channel-img-2.jpg"
                          }
                          className="base-logo"
                        />
                        Gaming
                      </Media>
                      <Media as="li">
                        <i className="icofont-plus-circle"></i>Browse Channels
                      </Media>
                    </ul>
                  </div>
                  <div className="best-video-sec">
                    <h3 className="best-title-1">More From Streamtube</h3>
                    <ul className="list-unstyled side-menu-item">
                      <Media as="li">
                        <i className="icofont-youtube-play"></i>Streamtube
                        Premium
                      </Media>
                      <Media as="li">
                        <i className="material-icons playlist-icon-1">
                          surround_sound
                        </i>
                        Live
                      </Media>
                      <hr></hr>
                      <Media as="li">
                        <i className="icofont-options"></i>Settings
                      </Media>
                      <Media as="li">
                        <i className="icofont-flag"></i>Report History
                      </Media>
                      <Media as="li">
                        <i className="icofont-question-circle"></i>Help
                      </Media>
                      <Media as="li">
                        <i className="icofont-ui-text-loading"></i>Send feedback
                      </Media>
                    </ul>
                  </div>
                  <hr></hr>
                  <div className="sidebar-footer">
                    <Link to="#">About</Link>
                    <Link to="#">Press</Link>
                    <Link to="#">Copyright</Link>
                    <Link to="#">Contact us</Link>
                    <Link to="#">Creators</Link>
                    <Link to="#">Advertise</Link>
                    <Link to="#">Developers</Link>
                  </div>
                  <div className="sidebar-subfooter">
                    <Link to="#">Terms</Link>
                    <Link to="#">Privacy</Link>
                    <Link to="#">Policy & Safety</Link>
                    <Link to="#">How YouTube works</Link>
                    <Link to="#">Test new features</Link>
                  </div>
                  <div className="copyright">
                    <p>© 2020 Codegama LLP</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-container video-single-base-sec">
              <Row>
                <Col sm={12} md={8}>
                  <div className="video-ratio">
                    <Image
                      src={
                        window.location.origin +
                        "/assets/img/base-product/video-4.jpg"
                      }
                      className="video-sec-img"
                    />
                  </div>
                  <div className="video-details">
                    <h3 className="title">
                      Maroon 5, Rihanna, Katy Perry, Bruno mars, Ed Sheeran,
                      Charlie Puth, Ariana Grande Pop Hits 2020
                    </h3>
                    <div className="flex-content resp-flex-content">
                      <div className="video-info">
                        <p className="desc">
                          <span className="view-count">1,419,047 view</span>
                          <span className="upload-date">May 3, 2020</span>
                        </p>
                      </div>
                      <div className="post-footer">
                        <div className="like-dislike-sec">
                          <span className="flex-info">
                            <i className="material-icons playlist-icon">
                              thumb_up
                            </i>
                            <span className=" like-count">3.3k</span>
                          </span>
                          <span className="flex-info">
                            <i className="material-icons playlist-icon">
                              thumb_down
                            </i>
                            <span className="dis-like-count">289</span>
                          </span>
                          <Link
                            className="modal-link color-icon-grey"
                            onClick={this.showShareModal}
                          >
                            <span className="flex-info">
                              <FontAwesomeIcon
                                icon={["fas", "share"]}
                                className="playlist-icon mr-2"
                              />
                              <span className="share">Share</span>
                            </span>
                          </Link>
                          <Link className="modal-link color-icon-grey" 
                            onClick={this.showSaveToModal}>
                            <span className="flex-info">
                              <i className="material-icons playlist-icon">
                                playlist_add
                              </i>
                              <span className="share">Save</span>
                            </span>
                          </Link>
                        </div>
                        <div className="video-single-base-comment-report-sec">
                          <span>
                            <Dropdown className="video-single-base-comment-dropdown resp-padding-xs">
                              <Dropdown.Toggle
                                className="button"
                                id="dropdown-basic"
                              >
                                <FontAwesomeIcon icon={["fas", "ellipsis-h"]} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-3">
                                  <i className="icofont-flag icon"></i>Report
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="video-sub-details">
                    <div className="video-sub-img-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/base-product/profile-img.jpg"
                        }
                        className="img-fluid video-sub-img"
                      />
                    </div>
                    <div className="video-sub-desc">
                      <div>
                        <Link to="#" className="video-cat-title">
                          Latin Songs
                        </Link>
                        <p className="sub-count">368K subscribers</p>
                      </div>
                      <div class="sub-btn-sec">
                        <Button className="btn btn-subscribe">Subscribe</Button>
                      </div>
                    </div>
                  </div>
                  <div className="video-display-content">
                    <p>
                      Maroon 5, Rihanna, Katy Perry, Bruno mars, Ed Sheeran,
                      Charlie Puth, Ariana Grande Pop Hits 2020 You are bothered
                      by ads when listening to music or surfing the web quickly.
                      No ads to surf the web, no ads to listen to music,
                      download right here.
                      <Link to="#" className="ml-2">
                        https://bit.ly/3dikA8s
                      </Link>
                    </p>
                    <Row>
                      <Col sm={12} md={3}>
                        <h4>Suggested by SME</h4>
                      </Col>
                      <Col sm={12} md={9}>
                        <Link to="#" className="link">
                          The Chainsmokers - Don't Let Me Down ft. Daya
                          (Official Music Video)
                        </Link>
                      </Col>
                    </Row>
                    <div class="border-bottom-1"></div>
                    <Row>
                      <Col sm="12" md="12">
                        <h3 className="video-cat-title">Music in this video</h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" md="12">
                        <Link to="#">
                          <h3 class="video-cat-title">Learn more</h3>
                        </Link>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" md="12">
                        <Link to="#">
                          <h3 class="video-cat-title">
                            Listen ad-free with StreamTube Premium
                          </h3>
                        </Link>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} md={3}>
                        <h4>Song</h4>
                      </Col>
                      <Col sm={12} md={9}>
                        <Link to="#" className="link">
                          Girls Like You
                        </Link>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} md={3}>
                        <h4>Artist</h4>
                      </Col>
                      <Col sm={12} md={9}>
                        <Link to="#" className="link">
                          Maroon 5
                        </Link>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} md={3}>
                        <h4>Licensed to YouTube by</h4>
                      </Col>
                      <Col sm={12} md={9}>
                        <h3 class="video-cat-title">
                          UMG (on behalf of Interscope Records*); UNIAO
                          BRASILEIRA DE EDITORAS DE MUSICA UBEM, UMPG
                          Publishing, LatinAutor - UMPG, ASCAP, PEDL, AMRA,
                          UMPI, CMRRA, IMPEL, ARESA, BMI - Broadcast Music Inc.,
                          LatinAutor, Pulse Recording (music publishing),
                          LatinAutor - Warner Chappell, G Rights Management
                          (US), LLC, LatinAutor - PeerMusic, LatinAutor -
                          SonyATV, Abramus Digital, Kobalt Music Publishing, and
                          21 Music Rights Societies
                        </h3>
                      </Col>
                    </Row>
                  </div>
                  <div class="border-bottom-1"></div>
                  <div className="video-comments-sec">
                    <Row>
                      <Col sm={12} md={3}>
                        <h2>61 Comments</h2>
                      </Col>
                      <Col sm={12} md={9}>
                        <p className="sort-by-txt">
                          <Dropdown className="channel-base-sortby-dropdown resp-padding-xs">
                            <Dropdown.Toggle
                              className="button"
                              id="dropdown-basic"
                            >
                              <i className="material-icons playlist-icon-1 mr-2">
                                sort
                              </i>
                              SORT BY
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                Top comments
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                Newest first
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </p>
                      </Col>
                    </Row>
                    <Row className="user-typed-comments-sec">
                      <Col sm={12} md={12}>
                        <div className="user-name-sec">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/img/base-product/profile-img.jpg"
                            }
                            className="user-img-display"
                          />
                        </div>
                        <div className="user-typed-sec">
                          <Form>
                            <Form.Group>
                              <Form.Control
                                type="text"
                                placeholder="Add a public comment...."
                              />
                            </Form.Group>
                            <div className="text-right">
                              <Button className="btn btn-cancel">Cancel</Button>
                              <Button className="btn btn-comment">
                                Comment
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </Col>
                      <Col sm={12} md={12}>
                        <div className="user-reply-box">
                          <div className="user-name-sec">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/img/base-product/profile-img.jpg"
                              }
                              className="user-img-display"
                            />
                          </div>
                          <div className="user-typed-sec">
                            <div className="flex-content">
                              <div>
                                <h3 className="comments-printed">
                                  <FontAwesomeIcon
                                    icon={["fas", "thumbtack"]}
                                    className="mr-1"
                                  />
                                  <span>Pinned by</span>
                                  <span>Latin Songs</span>
                                </h3>
                                <p className="comments-printed-desc">
                                  <span className="badge badge-comment">
                                    Latin Songs
                                  </span>
                                  <span className="comment-time">
                                    3 Weeks ago
                                  </span>
                                </p>
                              </div>
                              <div>
                                <Dropdown className="video-sing-base-comment-dropdown resp-padding-xs">
                                  <Dropdown.Toggle
                                    className="button"
                                    id="dropdown-basic"
                                  >
                                    <FontAwesomeIcon
                                      icon={["fas", "ellipsis-v"]}
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">
                                      <i className="icofont-flag icon"></i>
                                      Report
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                            <p class="sub-title">
                              Maroon 5, Rihanna, Katy Perry, Bruno mars, Ed
                              Sheeran, Charlie Puth, Ariana Grande Pop Hits 2020
                              You are bothered by ads when listening to music or
                              surfing the web quickly. No ads to surf the web,
                              no ads to listen to music, download right here.
                              <a href="#" class="ml-2">
                                https://bit.ly/3dikA8s
                              </a>
                            </p>
                            <div class="like-dislike-sec">
                              <span class="flex-info">
                                <i class="material-icons playlist-icon">
                                  thumb_up
                                </i>
                                <span class=" like-count">3.3k</span>
                              </span>
                              <span class="flex-info">
                                <i class="material-icons playlist-icon">
                                  thumb_down
                                </i>
                                <span class="dis-like-count">289</span>
                              </span>
                              <span class="flex-info">
                                <span class="share">Reply</span>
                              </span>
                            </div>
                            <div class="reply-typed">
                              <div className="user-name-sec">
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/img/base-product/profile-img.jpg"
                                  }
                                  className="user-img-display"
                                />
                              </div>
                              <div className="user-typed-sec">
                                <Form>
                                  <Form.Group>
                                    <Form.Control
                                      type="text"
                                      placeholder="Add a public comment...."
                                    />
                                  </Form.Group>
                                  <div className="text-right">
                                    <Button className="btn btn-cancel">
                                      Cancel
                                    </Button>
                                    <Button className="btn btn-comment">
                                      Comment
                                    </Button>
                                  </div>
                                </Form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col sm={12} md={4} className="pd-left-zero">
                  <div className="up-next-base-sec">
                    <div className="flex-content">
                      <h4 className="title-head">Up next</h4>
                      <Form className="auto-play-form">
                        <span className="txt-auto">AUTOPLAY</span>
                        <Form.Label className="switch">
                          <input type="checkbox" />
                          <span className="slider round"></span>
                        </Form.Label>
                      </Form>
                    </div>
                    <div className="up-next-base-sec-box">
                      <div className="up-next-base-sec-card">
                        <Row>
                          <Col sm={12} md={6}>
                            <div className="hover-content">
                              <ul className="list-unstyled">
                                <Media as="li">
                                  <Link to="#">
                                    <i className="material-icons playlist-icon">
                                      watch_later
                                    </i>
                                  </Link>
                                </Media>
                                <Media as="li">
                                  <Link to="#">
                                    <i className="material-icons playlist-icon">
                                      playlist_play
                                    </i>
                                  </Link>
                                </Media>
                              </ul>
                            </div>
                            <div className="video-list-img">
                              <Link to="#">
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/img/base-product/video-1.jpg"
                                  }
                                  className="img-fluid video-img"
                                />
                              </Link>
                              <div className="hover-play">
                                <Link to="#">
                                  <i className="icofont-ui-play"></i>
                                </Link>
                              </div>
                              <div className="time-count">
                                <h6>16:30</h6>
                              </div>
                            </div>
                          </Col>
                          <Col sm={12} md={6}>
                            <div className="video-list-content">
                              <div className="video-middle-content">
                                <Link to="#">
                                  <h3 className="title">
                                    Vathikkalu Vellaripravu Video Song | Sufiyum
                                    Sujatayum | M Jayachandran | Vijay Babu
                                  </h3>
                                </Link>
                                <Link to="#">
                                  <h4 className="sub-title">
                                    Friday Music Company
                                  </h4>
                                </Link>
                                <Link to="#">
                                  <p className="desc">
                                    <span className="view-count">5M views</span>
                                    <span className="upload-time">
                                      2 weeks ago
                                    </span>
                                  </p>
                                </Link>
                              </div>
                              <div className="up-next-action-sec">
                                <Dropdown className="up-next-action-dropdown resp-padding-xs">
                                  <Dropdown.Toggle
                                    className="button"
                                    id="dropdown-basic"
                                  >
                                    <FontAwesomeIcon
                                      icon={["fas", "ellipsis-v"]}
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">
                                      <i className="material-icons icon">
                                        playlist_play
                                      </i>
                                      Add to queue
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">
                                      <i className="material-icons icon">
                                        watch_later
                                      </i>
                                      Save to Watch later
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      <i className="material-icons icon">
                                        playlist_add
                                      </i>
                                      Save to playlist
                                    </Dropdown.Item>
                                    <hr></hr>
                                    <Dropdown.Item href="#/action-4">
                                      <i className="icofont-not-allowed icon"></i>
                                      Not interested
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-5">
                                      <i className="icofont-flag icon"></i>
                                      Report
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="up-next-base-sec-card">
                        <Row>
                          <Col sm={12} md={6}>
                            <div className="video-list-img-1">
                              <Link to="#">
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/img/base-product/video-1.jpg"
                                  }
                                  className="img-fluid video-img"
                                />
                              </Link>
                              <div class="img-overlay">
                                <p class="overlay-txt">50+</p>
                                <p class="overlay-txt">
                                  <i class="material-icons playlist-icon">
                                    playlist_play
                                  </i>
                                </p>
                              </div>
                              <div className="hover-play-all">
                                <Link to="#">
                                  <i class="icofont-ui-play"></i>
                                  <span class="play-all-txt">Play All</span>
                                </Link>
                              </div>
                            </div>
                          </Col>
                          <Col sm={12} md={6}>
                            <div className="video-list-content">
                              <div className="video-middle-content">
                                <Link to="#">
                                  <h3 className="title">
                                    Vathikkalu Vellaripravu Video Song | Sufiyum
                                    Sujatayum | M Jayachandran | Vijay Babu
                                  </h3>
                                </Link>
                                <Link to="#">
                                  <h4 className="sub-title">
                                    Friday Music Company
                                  </h4>
                                </Link>
                                <Link to="#">
                                  <p className="desc">
                                    <span className="view-count">5M views</span>
                                    <span className="upload-time">
                                      2 weeks ago
                                    </span>
                                  </p>
                                </Link>
                              </div>
                              <div className="up-next-action-sec">
                                <Dropdown className="up-next-action-dropdown resp-padding-xs">
                                  <Dropdown.Toggle
                                    className="button"
                                    id="dropdown-basic"
                                  >
                                    <FontAwesomeIcon
                                      icon={["fas", "ellipsis-v"]}
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">
                                      <i className="material-icons icon">
                                        playlist_play
                                      </i>
                                      Add to queue
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">
                                      <i className="material-icons icon">
                                        watch_later
                                      </i>
                                      Save to Watch later
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      <i className="material-icons icon">
                                        playlist_add
                                      </i>
                                      Save to playlist
                                    </Dropdown.Item>
                                    <hr></hr>
                                    <Dropdown.Item href="#/action-4">
                                      <i className="icofont-not-allowed icon"></i>
                                      Not interested
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-5">
                                      <i className="icofont-flag icon"></i>
                                      Report
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <Modal
          className="share-base-modal"
          size="md"
          centered
          show={this.state.shareModal}
          onHide={this.closeShareModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Share</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul className="list-unstyled resp-social-share">
              <Media as="li">
                <Link to="#" className="facebook-bg">
                  <FontAwesomeIcon icon={["fab", "facebook-f"]} />
                </Link>
                <span className="share-list-title">Facebook</span>
              </Media>
              <Media as="li">
                <Link to="#" className="twitter-bg">
                  <FontAwesomeIcon icon={["fab", "twitter"]} />
                </Link>
                <span className="share-list-title">Twitter</span>
              </Media>
              <Media as="li">
                <Link to="#" className="google-bg">
                  <FontAwesomeIcon icon={["fab", "google-plus-g"]} />
                </Link>
                <span className="share-list-title">Google Plus</span>
              </Media>
            </ul>
            <div className="mobile-copy-link-sec">
              <Form className="resp-share-form">
                <Form.Group>
                  <Form.Control
                    type="email"
                    placeholder="http://circle.be/oasdu4asdeJQ"
                  />
                  <Button className="btn-copy">Copy</Button>
                </Form.Group>
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Form className="full-width">
              <Row>
                <Col md={4}>
                  <Form.Group>
                    {["checkbox"].map((type) => (
                      <div key={`custom-inline-${type}`} className="sm-pd">
                        <Form.Check
                          custom
                          inline
                          label="Start at"
                          type={type}
                          id={`custom-inline-${type}-2`}
                        />
                      </div>
                    ))}
                  </Form.Group>
                </Col>
                <Col md={5}>
                  <Form.Group>
                    <Form.Control type="text" placeholder="0.11" />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Modal.Footer>
        </Modal>
        
        <Modal
          className="save-to-modal"
          size="sm"
          centered
          show={this.state.savetoModal}
          onHide={this.closeSaveToModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Save to</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul className="list-unstyled add-to-check">
              <Media as="li">
                <Form.Check
                  type="checkbox"
                  id="customControlAutosizing"
                  label="Watch Later"
                  custom
                />
              </Media>
              <Media as="li">
                <Form.Check
                  type="checkbox"
                  id="customControlAutosizing"
                  label="Test"
                  custom
                />
              </Media>
              <Media as="li">
                <Form.Check
                  type="checkbox"
                  id="customControlAutosizing"
                  label="Nice Songs"
                  custom
                />
              </Media>
            </ul>
          </Modal.Body>
          <ModalFooter>
            <div className="add-footer-content">
              <label onClick={
                this
                  .handleToggleCreateForm
              }>
              {this.state
                .createForm
                ? ""
                : ""}
                <FontAwesomeIcon
                  icon={["fas", "plus"]}
                  className="modal-icon mr-2"
                />
                Create Playlist
              </label>
            </div>
          </ModalFooter>
          {this.state.createForm && (
          <div className="save-to-form">
                  <Form>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control type="name" placeholder="Enter playlist name..." />
                    </Form.Group>
                    <Button className="btn create-btn">Create</Button>
                  </Form>
            </div>
          )}
        </Modal>

      </>
    );
  }
}

export default VideoSingle;
