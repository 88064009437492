import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Container, Row, Col, Image } from "react-bootstrap";
import "./CreateChannel.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BannnerAddImage from "./banner-bg.jpg";
import { connect } from "react-redux";
import { createChannelStart } from "../../store/actions/ChannelAction";

class CreateChannel extends Component {
  state = {
    loading: true,
    inputData: {},
    coverImagePreviewUrl: null,
    profileImagePreviewUrl: null,
  };

  handleChange = ({ currentTarget: input }) => {
    const inputData = { ...this.state.inputData };
    inputData[input.name] = input.value;
    this.setState({ inputData });
  };
  handleChangeImage = ({ currentTarget: input }) => {
    const inputData = { ...this.state.inputData };
    if (input.type === "file") {
      inputData[input.name] = input.files[0];
      this.setState({ inputData });
    }
    let reader = new FileReader();
    let file = input.files[0];

    reader.onloadend = () => {
      if (input.name == "cover")
        this.setState({
          coverImagePreviewUrl: reader.result,
        });
      if (input.name == "picture")
        this.setState({ profileImagePreviewUrl: reader.result });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.dispatch(createChannelStart(this.state.inputData));
  };

  render() {
    const {
      inputData,
      coverImagePreviewUrl,
      profileImagePreviewUrl,
    } = this.state;
    return (
      <>
        <div
          className="create-channel-sec"
          style={{
            backgroundImage: `url(${
              coverImagePreviewUrl != null
                ? coverImagePreviewUrl
                : BannnerAddImage
            })`,
          }}
        >
          <div className="upload-action-btn">
            <Form>
              <Form.Group className="upload-btn-wrapper">
                <button className="btn-upload">
                  <FontAwesomeIcon
                    icon={["fas", "plus-circle"]}
                    className="mr-2"
                  />
                  Add Cover Photo
                </button>
                <Form.File
                  id="exampleFormControlFile1"
                  label=""
                  onChange={this.handleChangeImage}
                  name="cover"
                  accept="image/*"
                />
              </Form.Group>
            </Form>
          </div>
          <div className="upload-action-img-btn">
            <Form>
              <Form.Group className="upload-btn-wrapper">
                <Image
                  src={
                    profileImagePreviewUrl != null
                      ? profileImagePreviewUrl
                      : window.location.origin + "/assets/img/avatar.png"
                  }
                  className="create-channel-user-img"
                />
                <Form.File
                  id="exampleFormControlFile1"
                  label=""
                  onChange={this.handleChangeImage}
                  name="picture"
                  accept="image/*"
                />
              </Form.Group>
            </Form>
          </div>
        </div>
        <Container>
          <Row>
            <Col sm={12} md={12}>
              <div className="create-channel-card">
                <div className="create-channel-content-sec">
                  <p className="desc-note">
                    Note : Profile Picture - Upload Square Images 2:2 Ratio Ex:
                    300 * 300, Cover Picture - Upload Rectangle Images 4:3 Ratio
                    Ex: 400 * 300
                  </p>
                  <Form
                    className="create-channel-form"
                    onSubmit={this.handleSubmit}
                  >
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="name"
                        name="name"
                        value={inputData.name}
                        onChange={this.handleChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Descriptions</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="3"
                        name="description"
                        value={inputData.description}
                        onChange={this.handleChange}
                      />
                    </Form.Group>
                    <div className="action-btn">
                      <Button className="reset-btn">Reset</Button>
                      <Button
                        className="submit-btn"
                        type="submit"
                        disabled={this.props.createChannel.buttonDisable}
                      >
                        {this.props.createChannel.loadingButtonContent != null
                          ? this.props.createChannel.loadingButtonContent
                          : "Submit"}
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToPros = (state) => ({
  createChannel: state.channel.createChannel,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CreateChannel);
