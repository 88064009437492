import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  fetchViewerPlaylistSuccess,
  fetchViewerPlaylistFailure,
  createViewerPlaylistSuccess,
  createViewerPlaylistFailure,
  addVideoViewerPlaylistSuccess,
  addVideoViewerPlaylistFailure,
  removeVideoViewerPlaylistSuccess,
  removeVideoViewerPlaylistFailure,
  fetchSingleViewerPlaylistSuccess,
  fetchSingleViewerPlaylistFailure,
  deleteViewerPlaylistSuccess,
  deleteViewerPlaylistFailure,
  fetchViewerPlaylistStart,
  fetchSingleViewerPlaylistStart,
} from "../actions/ViewerPlayListAction";
import {
  FETCH_VIEWER_PLAYLIST_START,
  CREATE_VIEWER_PLAYLIST_START,
  ADD_VIDEO_VIEWER_PLAYLIST_START,
  FETCH_SINGLE_VIEWER_PLAYLIST_START,
  DELETE_VIEWER_PLAYLIST_START,
  REMOVE_VIDEO_VIEWER_PLAYLIST_START,
} from "../actions/ActionConstant";

function* fetchViewerPlaylistAPI() {
  try {
    const response = yield api.postMethod("user_playlists");
    if (response.data.success) {
      yield put(fetchViewerPlaylistSuccess(response.data.data));
    } else {
      yield put(fetchViewerPlaylistFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchViewerPlaylistFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* createViewerPlaylistAPI() {
  try {
    const inputData = yield select(
      (state) => state.viewerPlaylist.createVP.inputData
    );
    const response = yield api.postMethod("playlists_save", inputData);
    if (response.data.success) {
      yield put(createViewerPlaylistSuccess(response.data.data));
      yield put(fetchViewerPlaylistStart());
      if (inputData.playlist_id !== undefined) {
        yield put(
          fetchSingleViewerPlaylistStart({
            playlist_id: inputData.playlist_id,
          })
        );
      }
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(createViewerPlaylistFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(createViewerPlaylistFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* addVideoVPApi() {
  try {
    const inputData = yield select(
      (state) => state.viewerPlaylist.addVideoVP.inputData
    );
    const response = yield api.postMethod("playlists_video_status", inputData);

    if (response.data.success) {
      yield put(addVideoViewerPlaylistSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(addVideoViewerPlaylistFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(addVideoViewerPlaylistFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* removeVideoVPApi() {
  try {
    const inputData = yield select(
      (state) => state.viewerPlaylist.removeVideoVP.inputData
    );
    const response = yield api.postMethod("playlists_video_remove", inputData);
    if (response.data.success) {
      yield put(
        fetchSingleViewerPlaylistStart({
          playlist_id: inputData.playlist_id,
        })
      );
      yield put(removeVideoViewerPlaylistSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(removeVideoViewerPlaylistFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(removeVideoViewerPlaylistFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchSingleVPApi() {
  try {
    const inputData = yield select(
      (state) => state.viewerPlaylist.singleVP.inputData
    );
    const response = yield api.postMethod("user_playlists_view", inputData);
    if (response.data.success) {
      yield put(fetchSingleViewerPlaylistSuccess(response.data.data));
    } else {
      yield put(fetchSingleViewerPlaylistFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSingleViewerPlaylistFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* deleteVPApi() {
  try {
    const inputData = yield select(
      (state) => state.viewerPlaylist.deleteVP.inputData
    );
    const response = yield api.postMethod("user_playlists_delete", inputData);
    if (response.data.success) {
      yield put(deleteViewerPlaylistSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(deleteViewerPlaylistFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(deleteViewerPlaylistFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_VIEWER_PLAYLIST_START, fetchViewerPlaylistAPI),
  ]);
  yield all([
    yield takeLatest(CREATE_VIEWER_PLAYLIST_START, createViewerPlaylistAPI),
  ]);
  yield all([yield takeLatest(ADD_VIDEO_VIEWER_PLAYLIST_START, addVideoVPApi)]);
  yield all([
    yield takeLatest(FETCH_SINGLE_VIEWER_PLAYLIST_START, fetchSingleVPApi),
  ]);
  yield all([yield takeLatest(DELETE_VIEWER_PLAYLIST_START, deleteVPApi)]);
  yield all([
    yield takeLatest(REMOVE_VIDEO_VIEWER_PLAYLIST_START, removeVideoVPApi),
  ]);
}
