import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Container, Row, Col, Image, Badge } from "react-bootstrap";
import "./SubscribedChannels.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import {
  fetchSubscribedChannelStart,
  subUnsubChannelStart,
} from "../../store/actions/ChannelAction";
import SubscribedChannelLoader from "../Loader/SubscribedChannelLoader";

const SubscribedChannels = (props) => {
  useEffect(() => {
    if (props.subChannel.loading) props.dispatch(fetchSubscribedChannelStart());
  }, []);

  const subChannel = props.subChannel;

  const unSubscribe = (event, channel) => {
    event.preventDefault();
    props.dispatch(subUnsubChannelStart({ channel_id: channel.channel_id }));
  };

  return (
    <>
      <div className="subscribed-channels-sec">
        <Container>
          <Row>
            <Col sm={12} md={12}>
              <h2 className="section-title">Subscribed Channels</h2>
            </Col>
          </Row>
          {subChannel.loading ? (
            <SubscribedChannelLoader />
          ) : subChannel.data.length > 0 ? (
            <div className="">
              {subChannel.data.map((channel) => (
                <div className="subscribed-channels-card">
                  <Row>
                    <Col xs={5} sm={5} md={4} xl={3}>
                      <div className="subscribed-channels-card-img">
                        <Link to="/channel/channel-name">
                          <Image
                            src={channel.channel_image}
                            className="subscribed-channels-img"
                          />
                        </Link>
                      </div>
                      <div className="video-count">
                        <h6>{channel.no_of_videos} Videos</h6>
                      </div>
                    </Col>
                    <Col
                      xs={5}
                      sm={5}
                      md={5}
                      xl={5}
                      className="resp-pd-left-zero"
                    >
                      <div className="subscribed-channels-card-details">
                        <h4 className="title">{channel.channel_name}</h4>
                        <p class="desc">
                          <span class="view-count">
                            {channel.no_of_subscribers} Subscribers
                          </span>
                          <span class="view-count">
                            . {new Date(channel.created_at).toDateString()}
                          </span>
                        </p>
                      </div>
                    </Col>
                    <Col xs={2} sm={2} md={3} xl={4} className="align-right">
                      <Link
                        to="#"
                        onClick={(event) => unSubscribe(event, channel)}
                      >
                        <Button className="unsubscribe-btn">
                          <FontAwesomeIcon
                            icon={["fas", "times"]}
                            className="unsubscribed-icon mr-2"
                          />
                          Unsubscribe
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          ) : (
            <div className="no-data-found-sec">
              <Row>
                <Col sm={12} md={12}>
                  <div className="no-data-found-img-sec">
                    <Image
                      src={
                        window.location.origin +
                        "/assets/img/no-result-found.svg"
                      }
                      className="no-data-found-img"
                    />
                    <p className="text-no-data">No Result Found</p>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  subChannel: state.channel.subChannel,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SubscribedChannels);
