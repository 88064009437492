import {
  FETCH_VIDEO_HISTORY_START,
  FETCH_VIDEO_HISTORY_SUCCESS,
  FETCH_VIDEO_HISTORY_FAILURE,
  ADD_VIDEO_HISTORY_START,
  ADD_VIDEO_HISTORY_SUCCESS,
  ADD_VIDEO_HISTORY_FAILURE,
  REMOVE_VIDEO_HISTORY_START,
  REMOVE_VIDEO_HISTORY_SUCCESS,
  REMOVE_VIDEO_HISTORY_FAILURE,
} from "./ActionConstant";

// fetch videos history action.

export function fetchVideoHistoryStart(data) {
  return {
    type: FETCH_VIDEO_HISTORY_START,
    data,
  };
}

export function fetchVideoHistorySuccess(data) {
  return {
    type: FETCH_VIDEO_HISTORY_SUCCESS,
    data,
  };
}

export function fetchVideoHistoryFailure(error) {
  return {
    type: FETCH_VIDEO_HISTORY_FAILURE,
    error,
  };
}

// Add to Video history action.

export function addVideoHistoryStart(data) {
  return {
    type: ADD_VIDEO_HISTORY_START,
    data,
  };
}

export function addVideoHistorySuccess(data) {
  return {
    type: ADD_VIDEO_HISTORY_SUCCESS,
    data,
  };
}

export function addVideoHistoryFailure(error) {
  return {
    type: ADD_VIDEO_HISTORY_FAILURE,
    error,
  };
}

// Remove video history action.

export function removeVideoHistoryStart(data) {
  return {
    type: REMOVE_VIDEO_HISTORY_START,
    data,
  };
}

export function removeVideoHistorySuccess(data) {
  return {
    type: REMOVE_VIDEO_HISTORY_SUCCESS,
    data,
  };
}

export function removeVideoHistoryFailure(error) {
  return {
    type: REMOVE_VIDEO_HISTORY_FAILURE,
    error,
  };
}
