import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Nav,
  Container,
  Row,
  Col,
  Tab,
  Dropdown,
  Image,
  ListGroup,
  Media,
} from "react-bootstrap";
import "./Home.css";
import { connect } from "react-redux";
import VideoCard from "../helper/VideoCard";
import PlayListCard from "../helper/PlayListCard";
import PopularChannelCard from "../helper/PopularChannelCard";
import {
  fetchHomePageDataStart,
  fetchSuggestionDataStart,
  fetchTrendingDataStart,
} from "../../store/actions/CoreDataAction";
import HomeLoader from "../Loader/HomeLoader";
import AddtoPlaylistModal from "../helper/AddtoPlaylistModal";

const HomeIndex = (props) => {
  useEffect(() => {
    if (props.home.loading) props.dispatch(fetchHomePageDataStart());
    if (props.trending.loading) props.dispatch(fetchTrendingDataStart());
    if (props.suggestion.loading) props.dispatch(fetchSuggestionDataStart());
  }, []);

  const [addtoModal, setAddtoModal] = useState(false);
  const [createForm, setCreateForm] = useState(false);
  const [video, setVideo] = useState("");

  const showAddToModal = (video) => {
    setAddtoModal(true);
    console.log("video", video);
    setVideo(video);
  };

  const closeAddToModal = () => {
    setAddtoModal(false);
  };

  const handleToggleCreateForm = () => {
    setCreateForm(!createForm);
  };

  const { home, trending, suggestion } = props;

  return (
    <>
      <div className="updates-subscriptions-sec"></div>

      {home.loading ? (
        <HomeLoader />
      ) : home.data.length > 0 ? (
        <div className="features-sec">
          <Container>
            <Row>
              <Col xs={12} md={12}>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Row>
                    <Col md={12}>
                      <Nav variant="pills">
                        <Nav.Item>
                          <Nav.Link eventKey="first">
                            Recent Uploaded videos
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col md={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <div className="section-1">
                            <Row>
                              {home.data.map((video) => (
                                <VideoCard
                                  video={video}
                                  showAddToModal={showAddToModal}
                                  {...props}
                                />
                              ))}
                            </Row>
                            {/*<div className="space-lg"></div>*/}
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Col>
            </Row>
            <div className="d-flex d-sm-none resp-arrow-bottom-sec">
              <ul className="list-unstyled">
                <Media as="li">
                  <Image
                    src="assets/img/mobile-arrow.png"
                    className="resp-arrow-bottom"
                  />
                </Media>
              </ul>
            </div>
            <div className="border-bottom-1"></div>
          </Container>
        </div>
      ) : (
        <div className="no-data-found-sec">
          <Row>
            <Col sm={12} md={12}>
              <div className="no-data-found-img-sec">
                <Image
                  src={
                    window.location.origin + "/assets/img/no-result-found.svg"
                  }
                  className="no-data-found-img"
                />
                <p className="text-no-data">No Result Found</p>
              </div>
            </Col>
          </Row>
        </div>
      )}

      {trending.loading ? (
        "Loading..."
      ) : trending.data.length > 0 ? (
        <div className="features-sec">
          <Container>
            <Row>
              <Col xs={12} md={12}>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Row>
                    <Col md={12}>
                      <Nav variant="pills">
                        <Nav.Item>
                          <Nav.Link eventKey="first">Trending</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col md={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <div className="section-1">
                            <Row>
                              {trending.data.map((video) => (
                                <VideoCard
                                  video={video}
                                  showAddToModal={showAddToModal}
                                  {...props}
                                />
                              ))}
                            </Row>
                            {/*<div className="space-lg"></div>*/}
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Col>
            </Row>
            <div className="d-flex d-sm-none resp-arrow-bottom-sec">
              <ul className="list-unstyled">
                <Media as="li">
                  <Image
                    src="assets/img/mobile-arrow.png"
                    className="resp-arrow-bottom"
                  />
                </Media>
              </ul>
            </div>
            <div className="border-bottom-1 d-block d-sm-none"></div>
          </Container>
        </div>
      ) : (
        <div className="no-data-found-sec">
          <Row>
            <Col sm={12} md={12}>
              <div className="no-data-found-img-sec">
                <Image
                  src={
                    window.location.origin + "/assets/img/no-result-found.svg"
                  }
                  className="no-data-found-img"
                />
                <p className="text-no-data">No Result Found</p>
              </div>
            </Col>
          </Row>
        </div>
      )}

      {suggestion.loading ? (
        "Loading..."
      ) : suggestion.data.length > 0 ? (
        <div className="features-sec">
          <Container>
            <Row>
              <Col xs={12} md={12}>
                <Tab.Container id="home-suggestions" defaultActiveKey="first">
                  <Row>
                    <Col md={12}>
                      <Nav variant="pills">
                        <Nav.Item>
                          <Nav.Link eventKey="first">Suggesstions</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col md={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <div className="section-1">
                            <Row>
                              {suggestion.data.map((video) => (
                                <VideoCard
                                  video={video}
                                  showAddToModal={showAddToModal}
                                  {...props}
                                />
                              ))}
                            </Row>
                            {/*<div className="space-lg"></div>*/}
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Col>
            </Row>
            <div className="d-flex d-sm-none resp-arrow-bottom-sec">
              <ul className="list-unstyled">
                <Media as="li">
                  <Image
                    src="assets/img/mobile-arrow.png"
                    className="resp-arrow-bottom"
                  />
                </Media>
              </ul>
            </div>
            <div className="border-bottom-1"></div>
          </Container>
        </div>
      ) : (
        ""
      )}

      {/* <div className="popular-playlist-sec">
        <Container>
          <Row>
            <Col xs={12} md={12}>
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                  <Col md={12}>
                    <Nav variant="pills">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Popular Playlists</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col md={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <div className="section-2">
                          <Row>
                            <PlayListCard />
                          </Row>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
          </Row>
          <div className="d-flex d-sm-none resp-arrow-bottom-sec">
            <ul className="list-unstyled">
              <Media as="li">
                <Image
                  src="assets/img/mobile-arrow.png"
                  className="resp-arrow-bottom"
                />
              </Media>
            </ul>
          </div>
          <div className="border-bottom-1"></div>
        </Container>
      </div> */}

      {/* <div className="popular-channels-sec">
        <Container>
          <Row>
            <Col xs={12} md={12}>
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                  <Col xs={12} md={12}>
                    <Nav variant="pills">
                      <Nav.Item>
                        <Nav.Link className="title-head">
                          Popular Channels
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col md={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <div className="section-1">
                          <Row>
                            <PopularChannelCard />
                            <PopularChannelCard />
                            <PopularChannelCard />
                            <PopularChannelCard />
                          </Row>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </div>*/}
      <AddtoPlaylistModal
        showAddToModal={showAddToModal}
        closeAddToModal={closeAddToModal}
        addtoModal={addtoModal}
        handleToggleCreateForm={handleToggleCreateForm}
        createForm={createForm}
        video={video}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  home: state.core.home,
  trending: state.core.trending,
  suggestion: state.core.suggestion,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(HomeIndex);
