import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Tab,
  Image,
  Media,
  Modal,
} from "react-bootstrap";
import "./SingleChannel.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BannnerAddImage from "./banner-bg.jpg";
import {
  fetchChannelVideosStart,
  fetchSingleChannelOwnerStart,
  fetchSingleVideoChannelOwnerStart,
} from "../../store/actions/ChannelAction";
import { connect } from "react-redux";
import SingleChannelNav from "./SingleChannelNav";
import VideoCard from "../helper/VideoCard";
import PlayListCard from "../helper/PlayListCard";
import GoLiveModal from "../helper/GoLiveModal";
import { fetchChannelPlaylistStart } from "../../store/actions/ChannelPlaylistAction";
import ChannelOwnerPlaylistModal from "../helper/ChannelOwnerPlaylistModal";

class SingleChannelOwner extends Component {
  constructor(props) {
    super(props);
    this.handleToggleAddPlayListForm = this.handleToggleAddPlayListForm.bind(
      this
    );
    this.state = {
      goliveModal: false,
      addplaylistModal: false,
      addplaylistForm: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(
      fetchSingleChannelOwnerStart({
        channel_id: this.props.match.params.unique_id,
      })
    );
  }

  showGoliveModal = () => {
    this.setState({ goliveModal: true });
  };

  closeGoliveModal = () => {
    this.setState({ goliveModal: false });
  };

  showAddPlayListModal = () => {
    this.setState({ addplaylistModal: true });
  };

  closeAddPlayListModal = () => {
    this.setState({ addplaylistModal: false });
  };

  handleToggleAddPlayListForm() {
    this.setState({ addplaylistForm: !this.state.addplaylistForm });
  }

  getChannelVideos = () => {
    this.props.dispatch(
      fetchSingleVideoChannelOwnerStart({
        channel_id: this.props.match.params.unique_id,
        view_type: "viewer",
      })
    );
  };

  getChannelPlaylists = () => {
    this.props.dispatch(
      fetchChannelPlaylistStart({
        channel_id: this.props.match.params.unique_id,
      })
    );
  };

  render() {
    const {
      singleChannelOwner,
      singleChannelOwnerVideo,
      channelPlaylists,
    } = this.props;
    if (singleChannelOwner.loading) {
      return "Loading...";
    } else
      return (
        <>
          <div
            className="single-channel-addsec"
            style={{
              backgroundImage: `url(${singleChannelOwner.data.details.channel_cover})`,
            }}
          >
            <div className="social-share d-none d-sm-block">
              <ul className="list-unstyled share-links">
                <Media as="li" className="title">
                  Social
                </Media>
                <Media as="li" className="facebook-bg">
                  <Link to="#">
                    <FontAwesomeIcon icon={["fab", "facebook-f"]} />
                  </Link>
                </Media>
                <Media as="li" className="twitter-bg">
                  <Link to="#">
                    <FontAwesomeIcon icon={["fab", "twitter"]} />
                  </Link>
                </Media>
                <Media as="li" className="google-bg">
                  <Link to="#">
                    <FontAwesomeIcon icon={["fab", "google-plus-g"]} />
                  </Link>
                </Media>
              </ul>
            </div>
          </div>

          <div className="single-channel-sec">
            <Container>
              <div className="single-channel-tabs">
                <Tab.Container id="left-tabs-example" defaultActiveKey="home">
                  <SingleChannelNav
                    showGoliveModal={this.showGoliveModal}
                    getChannelVideos={this.getChannelVideos}
                    data={singleChannelOwner.data.details}
                    getChannelPlaylists={this.getChannelPlaylists}
                    channelType="owner"
                  />

                  <Row>
                    <Col sm={12} md={12}>
                      <Tab.Content>
                        {singleChannelOwner.data.video_tapes.length > 0 ? (
                          <Tab.Pane eventKey="home">
                            <div className="section-1">
                              <Row>
                                {singleChannelOwner.data.video_tapes.map(
                                  (video) => (
                                    <VideoCard video={video} {...this.props} />
                                  )
                                )}
                              </Row>
                              <div className="space-lg"></div>
                            </div>
                          </Tab.Pane>
                        ) : (
                          <div className="no-data-found-sec">
                            <Row>
                              <Col sm={12} md={12}>
                                <div className="no-data-found-img-sec">
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/img/no-result-found.svg"
                                    }
                                    className="no-data-found-img"
                                  />
                                  <p className="text-no-data">
                                    No Result Found
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )}
                        {singleChannelOwnerVideo.loading ? null : singleChannelOwnerVideo
                            .data.length > 0 ? (
                          <Tab.Pane eventKey="single-channel-videos">
                            <div className="section-1">
                              <Row>
                                {singleChannelOwnerVideo.data.map((video) => (
                                  <VideoCard video={video} {...this.props} />
                                ))}
                              </Row>
                              <div className="space-lg"></div>
                            </div>
                          </Tab.Pane>
                        ) : (
                          <div className="no-data-found-sec">
                            <Row>
                              <Col sm={12} md={12}>
                                <div className="no-data-found-img-sec">
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/img/no-result-found.svg"
                                    }
                                    className="no-data-found-img"
                                  />
                                  <p className="text-no-data">
                                    No Result Found
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )}
                        {singleChannelOwner.data.video_tapes.length > 0 ? (
                          <Tab.Pane eventKey="live-videos">
                            <div className="section-1">
                              <Row>
                                {singleChannelOwner.data.video_tapes.map(
                                  (video) => (
                                    <VideoCard video={video} {...this.props} />
                                  )
                                )}
                              </Row>
                              <div className="space-lg"></div>
                            </div>
                          </Tab.Pane>
                        ) : (
                          <div className="no-data-found-sec">
                            <Row>
                              <Col sm={12} md={12}>
                                <div className="no-data-found-img-sec">
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/img/no-result-found.svg"
                                    }
                                    className="no-data-found-img"
                                  />
                                  <p className="text-no-data">
                                    No Result Found
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )}

                        <Tab.Pane eventKey="about">
                          <div className="about-sec">
                            <p>
                              A new music service with official albums, singles,
                              videos, remixes, live performances and more for
                              Android,{" "}
                            </p>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="single-channel-playlist">
                          {channelPlaylists.loading ? null : channelPlaylists
                              .data.length > 0 ? (
                            <div className="section-2">
                              <Row>
                                <Col sm={12} md={12}>
                                  <div className="create-playlist-action-sec">
                                    <Button
                                      className="btn add-btn"
                                      onClick={this.showAddPlayListModal}
                                    >
                                      <FontAwesomeIcon
                                        icon={["fas", "plus"]}
                                        className="modal-icon mr-2"
                                      />
                                      Playlist
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                {channelPlaylists.data.map((playlist) => (
                                  <PlayListCard playlist={playlist} />
                                ))}
                              </Row>
                              <div className="space-xl"></div>
                            </div>
                          ) : (
                            <div className="no-data-found-sec">
                              <Row>
                                <Col sm={12} md={12}>
                                  <div className="no-data-found-img-sec">
                                    <Image
                                      src={
                                        window.location.origin +
                                        "/assets/img/no-result-found.svg"
                                      }
                                      className="no-data-found-img"
                                    />
                                    <p className="text-no-data">
                                      No Result Found
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </Container>
          </div>

          <ChannelOwnerPlaylistModal
            {...this.props}
            closeAddPlayListModal={this.closeAddPlayListModal}
            addplaylistModal={this.state.addplaylistModal}
            handleToggleAddPlayListForm={this.handleToggleAddPlayListForm}
            addplaylistForm={this.state.addplaylistForm}
          />

          <GoLiveModal
            {...this.props}
            goliveModal={this.state.goliveModal}
            showGoliveModal={this.showGoliveModal}
            closeGoliveModal={this.closeGoliveModal}
          />
        </>
      );
  }
}

const mapStateToPros = (state) => ({
  singleChannelOwner: state.channel.singleChannelOwner,
  singleChannelOwnerVideo: state.channel.singleChannelOwnerVideo,
  channelPlaylists: state.channelPlaylist.channelPlaylist,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SingleChannelOwner);
