import React, { useState } from "react";
import { Col, Dropdown, Image, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageLoader from "./ImageLoader";
import { connect } from "react-redux";
import { wishlistOperationStart } from "../../store/actions/WishListAction";

const VideoCard = (props) => {
  const [wishlistButton, setWishlistButton] = useState("");

  const addWishlist = (event) => {
    event.preventDefault();
    setWishlistButton("added");
    props.dispatch(
      wishlistOperationStart({
        video_tape_id: props.video.video_tape_id,
      })
    );
  };

  const removeWishlist = (event) => {
    event.preventDefault();
    setWishlistButton("removed");
    props.dispatch(
      wishlistOperationStart({
        video_tape_id: props.video.video_tape_id,
        clear_all_status: 0,
      })
    );
  };

  const checkPayment = (event) => {
    event.preventDefault();
    if (video.should_display_ppv === 1)
      props.history.push(`/ppv-invoice/${props.video.video_tape_id}`);
    else props.history.push(`/single-video/${props.video.video_tape_id}`);
  };

  const { video } = props;

  return (
    <Col xs={12} xl={3} md={6} lg={4}>
      <div className="features-card" id={`video-card-${video.video_tape_id}`}>
        <Link
          to={`/single-video/${video.video_tape_id}`}
          onClick={checkPayment}
        >
          <div className="features-card-img">
            <ImageLoader image={video.video_image} className="video-img" />
          </div>
          <div className="features-card-details">
            <h4 className="title">{video.title}</h4>
            <p className="desc">
              <span className="view-count">{video.watch_count} views</span>
              <span className="percentage-count green-clr">
                <FontAwesomeIcon icon="clock" className="mr-1" />
                {video.publish_time}
              </span>
            </p>
          </div>
          {video.should_display_ppv ? (
            <div className="video-watched">
              <h6>Pay - {video.ppv_amount_formatted}</h6>
            </div>
          ) : (
            ""
          )}
          <div className="time-count">
            <h6>{video.duration}</h6>
          </div>
        </Link>
        <div className="hover-content">
          <Dropdown className="video-dropdown-sec">
            <Dropdown.Toggle className="video-dropdown" id="dropdown-basic">
              <Image src={window.location.origin + "/assets/img/plus.png"} />
            </Dropdown.Toggle>
            <Dropdown.Menu className="video-dropdown-menu">
              {wishlistButton !== "" ? (
                <>
                  <>
                    {wishlistButton === "added" ? (
                      <Dropdown.Item href="#/action-1" onClick={removeWishlist}>
                        Remove from wishlist
                      </Dropdown.Item>
                    ) : null}
                  </>
                  <>
                    {wishlistButton === "removed" ? (
                      <Dropdown.Item href="#/action-1" onClick={addWishlist}>
                        Add to Wishlist
                      </Dropdown.Item>
                    ) : null}
                  </>
                </>
              ) : video.wishlist_status ? (
                <Dropdown.Item href="#/action-1" onClick={removeWishlist}>
                  Remove from wishlist
                </Dropdown.Item>
              ) : (
                <Dropdown.Item href="#/action-1" onClick={addWishlist}>
                  Add to Wishlist
                </Dropdown.Item>
              )}

              <Dropdown.Item
                href="#"
                onClick={() => props.showAddToModal(video)}
              >
                Add to Playlist
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </Col>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(VideoCard);
