import React from "react";
import {
  Nav,
  Container,
  Row,
  Col,
  Tab,
  Dropdown,
  Image,
  ListGroup,
  Media,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PlayListCard = (props) => {
  const { playlist } = props;

  return (
    <Col xs={12} md={3}>
      <Link to={`/playlist/${playlist.playlist_id}`}>
        <div className="popular-playlist-card">
          <div className="popular-playlist-card-img">
            <div>
              {/* <Image src={playlist.picture} className="video-img-1" /> */}
              <Image src={playlist.picture} className="video-img-2" />
              <Image src={playlist.picture} className="video-img" />
            </div>
            <div className="playlist-count">
              <h6>{playlist.total_videos}</h6>
            </div>
          </div>
          <div className="popular-playlist-card-details">
            <h4 className="title">{playlist.title}</h4>
            <p className="desc">
              {/* <span className="view-count">27,548 views</span>
              <span className="percentage-count green-clr">
                <FontAwesomeIcon icon="circle" className="mr-1" />
                93%
              </span> */}
            </p>
          </div>
        </div>
      </Link>
    </Col>
  );
};

export default PlayListCard;
