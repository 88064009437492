import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Container, Row, Col, Media } from "react-bootstrap";
import "./SignIn.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UseForm from "../helper/UseForm";
import {
  userLoginStart,
  userRegisterStart,
} from "../../store/actions/UserAction";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../helper/NotificationMessage";

const SignIn = (props) => {
  const { values, handleChange, handleSubmit } = UseForm(login);

  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");

  function login() {
    console.log(values);
    props.dispatch(userLoginStart(values));
  }

  const responseGoogle = (response) => {
    const path = props.location;
    const googleLoginInput = {
      social_unique_id: response.profileObj.googleId,
      login_by: "google",
      email: response.profileObj.email,
      name: response.profileObj.name,
      picture: response.profileObj.imageUrl,
      device_type: "web",
      device_token: "123466",
    };
    props.dispatch(userRegisterStart(googleLoginInput));
  };

  const responseFacebook = (response) => {
    const { path } = props.location;
    if (response) {
      if (response.status == "unknown") {
        const notificationMessage = getErrorNotificationMessage("Cancelled");
        props.dispatch(createNotification(notificationMessage));
        return false;
      }
      const emailAddress =
        response.email === undefined || response.email === null
          ? response.id + "@facebook.com"
          : response.email;

      const facebookLoginInput = {
        social_unique_id: response.id,
        login_by: "facebook",
        email: emailAddress,
        name: response.name,
        device_type: "web",
        device_token: "123466",
      };
      props.dispatch(userRegisterStart(facebookLoginInput));
    }
  };

  return (
    <>
      <div className="signin-sec">
        <Container>
          <Row>
            <Col sm={12} md={5} xl={6} className="d-none d-sm-block">
              <div className="signin-left-content">
                <h1 className="left-border"></h1>
                <h2 className="left-title">
                  Watch
                  <span className="highlighted-txt">
                    millions of videos{" "}
                  </span>{" "}
                  for free.
                </h2>
                <p className="desc">Over 6000 videos uploaded Daily</p>
              </div>
            </Col>
            <Col sm={12} md={7} xl={6}>
              <div className="signin-right-content">
                <div className="signin-box">
                  <div className="signin-header">
                    <h3 className="title">
                      Log Into Your {configuration.get("configData.site_name")}{" "}
                      Account
                    </h3>
                  </div>
                  <div className="signin-body">
                    <Form onSubmit={handleSubmit}>
                      <Form.Group controlId="formGroupEmail">
                        <Form.Label className="label-title">Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="sample@gmail.com"
                          name="email"
                          // onChange={(event) => setEmail(event.target.value)}
                          onChange={handleChange}
                          value={values.email}
                        />
                      </Form.Group>
                      <Form.Group controlId="formGroupPassword">
                        <Form.Label className="label-title">
                          Password
                        </Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="*******"
                          name="password"
                          onChange={handleChange}
                          value={values.password}
                          // onChange={(event) => setPassword(event.target.value)}
                        />
                      </Form.Group>
                      {/* <Form.Group className="check-box-flex">
                        <Form.Check
                          type="checkbox"
                          id="customControlAutosizing"
                          label=""
                          custom
                        />
                        <Form.Text className="small-txt">
                          <h4 className="check-box-title">
                            Remember me on this computer
                          </h4>
                          <div className="d-none d-sm-block">(not recomended on public or shared computers)</div>
                        </Form.Text>
                      </Form.Group> */}
                      <Row>
                        <Col sm={12} md={6} xl={7}>
                          <div>
                            <Button
                              className="login-btn btn-block"
                              type="submit"
                              disabled={props.users.buttonDisable}
                            >
                              {props.users.loadingButtonContent != null
                                ? props.users.loadingButtonContent
                                : "Login"}
                            </Button>
                          </div>
                        </Col>
                        <Col
                          sm={12}
                          md={6}
                          xl={5}
                          className="d-none d-sm-block"
                        >
                          <div className="signin-flex-center">
                            <h4 className="or-txt">Or</h4>
                            <Link to="/signup" className="login-link">
                              Sign Up
                            </Link>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={12}>
                          <ul className="list-unstyled social-link">
                            {configuration.get(
                              "configData.social_logins.GOOGLE_CLIENT_ID"
                            ) ? (
                              <Media as="li">
                                <GoogleLogin
                                  clientId={configuration.get(
                                    "configData.social_logins.GOOGLE_CLIENT_ID"
                                  )}
                                  render={(renderProps) => (
                                    <Link
                                      to="#"
                                      className="color-google"
                                      onClick={renderProps.onClick}
                                      disabled={renderProps.disabled}
                                    >
                                      <FontAwesomeIcon
                                        icon={["fab", "google"]}
                                      />
                                    </Link>
                                  )}
                                  buttonText="Login"
                                  onSuccess={responseGoogle}
                                  onFailure={responseGoogle}
                                  cookiePolicy={"single_host_origin"}
                                />
                              </Media>
                            ) : (
                              ""
                            )}

                            {configuration.get(
                              "configData.social_logins.FB_CLIENT_ID"
                            ) ? (
                              <Media as="li">
                                <FacebookLogin
                                  appId={configuration.get(
                                    "configData.social_logins.FB_CLIENT_ID"
                                  )}
                                  fields="name,email,picture"
                                  scope="public_profile"
                                  callback={responseFacebook}
                                  render={(renderProps) => (
                                    <Link
                                      to="#"
                                      className="color-facebook"
                                      onClick={renderProps.onClick}
                                      disabled={renderProps.disabled}
                                    >
                                      <FontAwesomeIcon
                                        icon={["fab", "facebook"]}
                                      />
                                    </Link>
                                  )}
                                />
                              </Media>
                            ) : (
                              ""
                            )}
                            {/* <Media as="li">
                              <Link to="#" className="color-twitter">
                                <FontAwesomeIcon icon={["fab", "twitter"]} />
                              </Link>
                            </Media> */}
                          </ul>
                        </Col>
                      </Row>
                      <Link to="/forgot-password" className="d-none d-sm-block">
                        <p className="desc">Forgot Username or Password?</p>
                      </Link>
                      <div className="mobile-footer-sec d-flex d-sm-none">
                        <Link to="/forgot-password">
                          <p className="desc">Forgot Password?</p>
                        </Link>
                        <Link to="/signup">
                          <p className="desc">Sign Up</p>
                        </Link>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  users: state.users,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SignIn);
