import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Tabs,
  Tab,
  Dropdown,
  DropdownButton,
  Image,
} from "react-bootstrap";
import "./PaymentFailure.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class PaymentFailure extends Component {
  render() {
    return (
      <>
        <div className="payment-failure">
          <Container>
            <Row>
              <Col sm={12} md={12}>
                <div className="payment-failure-box">
                  <div className="payment-failure-card">
                  <Image
                  src={
                    window.location.origin +
                    "/assets/img/payment-failure.svg"
                  }
                  className="payment-failure-icon"
                  />
                  <h3 className="title">Payment Failed</h3>
                  <p className="desc">Please check your internet connection</p>
                    <Button className="btn-failed-back">Back</Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default PaymentFailure;
