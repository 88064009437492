import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Tabs,
  Tab,
  Dropdown,
  DropdownButton,
  Image,
} from "react-bootstrap";
import "./ErrorPage2.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ErrorPage2 extends Component {
  render() {
    return (
      <>
        <div className="error-page-500-sec">
          <Container>
            <Row>
              <Col sm={12} md={12}>
                <div className="error-page-500-box">
                  <div className="error-page-500-card">
                    <div className="error-page-500-img-sec">
                      <Image
                        src="assets/img/500.svg"
                        className="error-page-500-img"
                      />
                    </div>
                    <div className="text-caption">
                      <h4>500 Error</h4>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default ErrorPage2;
