import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./LiveTvIndex.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { fetchLiveTvStart } from "../../store/actions/LiveTvAction";
import ImageLoader from "../helper/ImageLoader";
import LiveTvLoader from "../Loader/LiveTvLoader";
import NoResultFound from "../NoResultFound/NoResultFound";

const LiveTvIndex = (props) => {
  useEffect(() => {
    if (props.livetv.loading) props.dispatch(fetchLiveTvStart());
  }, []);
  return (
    <>
      <div className="features-sec live-tv-sec">
        <Container>
          <Row>
            <Col sm={12} md={12}>
              <h2 className="section-title">Live Tv</h2>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12}>
              <div className="section-1 no-padding-btm">
                <Row>
                  {props.livetv.loading
                    ? <LiveTvLoader />
                    : props.livetv.data.length > 0
                    ? props.livetv.data.map((video) => (
                        <Col xs={12} md={3}>
                          <div className="features-card">
                            <Link to={`/livetv/${video.custom_live_video_id}`}>
                              <div className="features-card-img">
                                <ImageLoader
                                  image={video.image}
                                  className="video-img"
                                />
                              </div>
                              <div className="features-card-details">
                                <h4 className="title">{video.title}</h4>
                                <p className="desc">
                                  <span className="percentage-count green-clr">
                                    <FontAwesomeIcon
                                      icon="clock"
                                      className="mr-1"
                                    />
                                    {video.created_time}
                                  </span>
                                </p>
                              </div>
                              <div className="time-count">
                                <h6>Live</h6>
                              </div>
                            </Link>
                          </div>
                        </Col>
                      ))
                    : <NoResultFound/>}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  livetv: state.livetv.livetv,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(LiveTvIndex);
