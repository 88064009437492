import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Tabs,
  Tab,
  Dropdown,
  DropdownButton,
  Image,
} from "react-bootstrap";
import "./Settings.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Settings extends Component {
  render() {
    return (
      <>
        <div className="setting-sec">
          <Container>
            <Row>
              <Col sm={12} md={6} xl={4} className="resp-mrg-btm-md-device">
                <div className="setting-sec-card">
                  <div className="user-img-sec">
                    <Image
                      src={
                        window.location.origin + "/assets/img/profile-img.jpg"
                      }
                      className="user-img"
                    />
                    <h4 className="user-name">UserDemo</h4>
                    <p className="user-email">user@tubenow.com</p>
                    <Link to={"/profile"} className="setting-link">
                      View Profile
                    </Link>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={6} xl={4} className="resp-mrg-btm-xs">
                <Link to={"/referrals"}>
                  <div className="setting-sec-box">
                    <div className="setting-icon-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/setting-referal.svg"
                        }
                        className="setting-icon"
                      />
                    </div>
                    <div className="setting-title-sec">
                      <h4 className="setting-title">Referrals</h4>
                    </div>
                  </div>
                </Link>
                <div className="space-sm"></div>
                <Link to={"/plans"}>
                  <div className="setting-sec-box">
                    <div className="setting-icon-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/setting-subscription.svg"
                        }
                        className="setting-icon"
                      />
                    </div>
                    <div className="setting-title-sec">
                      <h4 className="setting-title">Subscriptions</h4>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col sm={12} md={12} xl={4}>
                <Link to={"/wishlist"}>
                  <div className="setting-sec-box">
                    <div className="setting-icon-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/setting-wishlist.svg"
                        }
                        className="setting-icon"
                      />
                    </div>
                    <div className="setting-title-sec">
                      <h4 className="setting-title">Wishlists</h4>
                    </div>
                  </div>
                </Link>
                <div className="space-sm"></div>
                <Link to={"/spam-videos"}>
                  <div className="setting-sec-box">
                    <div className="setting-icon-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/setting-spam.svg"
                        }
                        className="setting-icon"
                      />
                    </div>
                    <div className="setting-title-sec">
                      <h4 className="setting-title">Spam</h4>
                    </div>
                  </div>
                </Link>
              </Col>
            </Row>
            <div className="space-sm"></div>
            <Row>
              <Col sm={12} md={6} xl={4} className="resp-mrg-btm-xs">
                <Link to={"/my-history"}>
                  <div className="setting-sec-box">
                    <div className="setting-icon-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/setting-history.svg"
                        }
                        className="setting-icon"
                      />
                    </div>
                    <div className="setting-title-sec">
                      <h4 className="setting-title">History</h4>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col sm={12} md={6} xl={4} className="resp-mrg-btm-md-device">
                <Link to={"/my-plans"}>
                  <div className="setting-sec-box">
                    <div className="setting-icon-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/setting-myplan.svg"
                        }
                        className="setting-icon"
                      />
                    </div>
                    <div className="setting-title-sec">
                      <h4 className="setting-title">My Plans</h4>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col sm={12} md={12} xl={4}>
                <Link to={"/subscribed-channels"}>
                  <div className="setting-sec-box">
                    <div className="setting-icon-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/setting-channel.svg"
                        }
                        className="setting-icon"
                      />
                    </div>
                    <div className="setting-title-sec">
                      <h4 className="setting-title">Subscribed Channels</h4>
                    </div>
                  </div>
                </Link>
              </Col>
            </Row>
            <div className="space-sm"></div>
            <Row>
              <Col sm={12} md={6} xl={4} className="resp-mrg-btm-xs">
                <Link to={"/purchased-videos"}>
                  <div className="setting-sec-box">
                    <div className="setting-icon-sec">
                      <Image
                        src={
                          window.location.origin + "/assets/img/setting-ppv.svg"
                        }
                        className="setting-icon"
                      />
                    </div>
                    <div className="setting-title-sec">
                      <h4 className="setting-title">Purchased Videos</h4>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col sm={12} md={6} xl={4} className="resp-mrg-btm-md-device">
                <Link to={"/cards-list"}>
                  <div className="setting-sec-box">
                    <div className="setting-icon-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/setting-card.svg"
                        }
                        className="setting-icon"
                      />
                    </div>
                    <div className="setting-title-sec">
                      <h4 className="setting-title">Cards</h4>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col sm={12} md={12} xl={4}>
                <Link to={"/redeems"}>
                  <div className="setting-sec-box">
                    <div className="setting-icon-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/setting-redeems.svg"
                        }
                        className="setting-icon"
                      />
                    </div>
                    <div className="setting-title-sec">
                      <h4 className="setting-title">Redeems</h4>
                    </div>
                  </div>
                </Link>
              </Col>
            </Row>
            <div className="space-sm"></div>
            <Row>
              <Col sm={12} md={6} xl={4} className="resp-mrg-btm-xs">
                <Link to={"/channel-list"}>
                  <div className="setting-sec-box">
                    <div className="setting-icon-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/setting-change-password.svg"
                        }
                        className="setting-icon"
                      />
                    </div>
                    <div className="setting-title-sec">
                      <h4 className="setting-title">My Channels</h4>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col sm={12} md={6} xl={4} className="resp-mrg-btm-md-device">
                <Link to={"/change-password"}>
                  <div className="setting-sec-box">
                    <div className="setting-icon-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/setting-change-password.svg"
                        }
                        className="setting-icon"
                      />
                    </div>
                    <div className="setting-title-sec">
                      <h4 className="setting-title">Change Password</h4>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col sm={12} md={12} xl={4}>
                <Link to={"/delete-account"}>
                  <div className="setting-sec-box">
                    <div className="setting-icon-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/setting-delete.svg"
                        }
                        className="setting-icon"
                      />
                    </div>
                    <div className="setting-title-sec">
                      <h4 className="setting-title">Delete Account</h4>
                    </div>
                  </div>
                </Link>
              </Col>
            </Row>

            <div className="space-sm"></div>

            <Row>
              <Col sm={12} md={12} xl={4}>
                <Link to={"/logout"}>
                  <div className="setting-sec-box">
                    <div className="setting-icon-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/setting-logout.svg"
                        }
                        className="setting-icon"
                      />
                    </div>
                    <div className="setting-title-sec">
                      <h4 className="setting-title">Logout</h4>
                    </div>
                  </div>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default Settings;
