import {
  FETCH_CATEGORIES_START,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
  FETCH_CATEGORY_VIDEOS_START,
  FETCH_CATEGORY_VIDEOS_SUCCESS,
  FETCH_CATEGORY_VIDEOS_FAILURE,
  FETCH_SINGLE_CATEGORY_START,
  FETCH_SINGLE_CATEGORY_SUCCESS,
  FETCH_SINGLE_CATEGORY_FAILURE,
} from "./ActionConstant";

// fetch Category action.

export function fetchCategoriesStart(data) {
  return {
    type: FETCH_CATEGORIES_START,
    data,
  };
}

export function fetchCategoriesSuccess(data) {
  return {
    type: FETCH_CATEGORIES_SUCCESS,
    data,
  };
}

export function fetchCategoriesFailure(error) {
  return {
    type: FETCH_CATEGORIES_FAILURE,
    error,
  };
}

// single category video action.

export function fetchCategoryVideoStart(data) {
  return {
    type: FETCH_CATEGORY_VIDEOS_START,
    data,
  };
}

export function fetchCategoryVideoSuccess(data) {
  return {
    type: FETCH_CATEGORY_VIDEOS_SUCCESS,
    data,
  };
}

export function fetchCategoryVideoFailure(error) {
  return {
    type: FETCH_CATEGORY_VIDEOS_FAILURE,
    error,
  };
}

// fetch single category action.

export function fetchSingleCategoryStart(data) {
  return {
    type: FETCH_SINGLE_CATEGORY_START,
    data,
  };
}

export function fetchSingleCategorySuccess(data) {
  return {
    type: FETCH_SINGLE_CATEGORY_SUCCESS,
    data,
  };
}

export function fetchSingleCategoryFailure(error) {
  return {
    type: FETCH_SINGLE_CATEGORY_FAILURE,
    error,
  };
}
