import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Dropdown, Media, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import configuration from "react-global-configuration";

class FooterIndex extends Component {
  state = { footer_pages1: [], footer_pages2: [] };
  componentDidMount() {
    if (configuration.get("configData.footer_pages1")) {
      this.setState({
        footer_pages1: configuration.get("configData.footer_pages1"),
      });
    }
    if (configuration.get("configData.footer_pages2")) {
      this.setState({
        footer_pages2: configuration.get("configData.footer_pages2"),
      });
    }
  }
  render() {
    return (
      <>
        <section className="footer-sec">
          <Container>
            <Row>
              <Col md={1} className="resp-align-center resp-mrg-btm-sm">
                <Link to="/" className="d-none d-sm-block">
                  <Image
                    src={configuration.get("configData.site_icon")}
                    className="logo mt-3"
                  />
                </Link>
                <Link to="/" className="mobile-logo d-block d-sm-none">
                  <Image
                    src={configuration.get("configData.site_icon")}
                    className="logo"
                  />
                </Link>
              </Col>
              <Col md={7}>
                <ul className="list-unstyled footer-link">
                  {this.state.footer_pages2.length > 0
                    ? this.state.footer_pages2.map((static_page) => (
                        <li className="list-link">
                          <Link
                            to={`/page/${static_page.page_unique_id}`}
                            key={static_page.page_unique_id}
                          >
                            {static_page.title}
                          </Link>
                        </li>
                      ))
                    : ""}
                </ul>
                <div className="sub-footer d-none d-sm-flex">
                  <ul className="list-unstyled footer-link-1">
                    {this.state.footer_pages1.length > 0
                      ? this.state.footer_pages1.map((static_page) => (
                          <li className="list-link">
                            <Link
                              to={`/page/${static_page.page_unique_id}`}
                              key={static_page.page_unique_id}
                            >
                              {static_page.title}
                            </Link>
                          </li>
                        ))
                      : ""}
                  </ul>
                  <p className="copyright">
                    &copy; {new Date().getFullYear()} Copyrights{" "}
                    <Link to="/">
                      {configuration.get("configData.site_name")}
                    </Link>
                  </p>
                </div>
              </Col>
              <Col md={4}>
                <div className="social-link-sec">
                  <ul className="list-unstyled social-link">
                    <Media as="li">
                      <Link
                        to={
                          configuration.get("configData.facebook_link")
                            ? configuration.get("configData.facebook_link")
                            : "#"
                        }
                      >
                        <FontAwesomeIcon icon={["fab", "facebook-square"]} />
                      </Link>
                    </Media>
                    <Media as="li">
                      <Link
                        to={
                          configuration.get("configData.twitter_link")
                            ? configuration.get("configData.twitter_link")
                            : "#"
                        }
                      >
                        <FontAwesomeIcon icon={["fab", "twitter"]} />
                      </Link>
                    </Media>
                    <Media as="li">
                      <Link
                        to={
                          configuration.get("configData.instagram_link")
                            ? configuration.get("configData.instagram_link")
                            : "#"
                        }
                      >
                        <FontAwesomeIcon icon={["fab", "instagram"]} />
                      </Link>
                    </Media>
                    <Media as="li">
                      <Link
                        to={
                          configuration.get("configData.linkedin_link")
                            ? configuration.get("configData.linkedin_link")
                            : "#"
                        }
                      >
                        <FontAwesomeIcon icon={["fab", "linkedin"]} />
                      </Link>
                    </Media>
                    <Media as="li">
                      <Link
                        to={
                          configuration.get("configData.pinterest_link")
                            ? configuration.get("configData.pinterest_link")
                            : "#"
                        }
                      >
                        <FontAwesomeIcon icon={["fab", "pinterest"]} />
                      </Link>
                    </Media>
                  </ul>
                  <div className="language-sec">
                    <Dropdown className="dropdown-main-sec">
                      <Dropdown.Toggle
                        className="dropdown-main resp-language-btn"
                        id="dropdown-basic"
                      >
                        Language - EN
                        <FontAwesomeIcon icon="chevron-down" className="ml-2" />
                      </Dropdown.Toggle>
                      {/* <Dropdown.Menu className="main-dropdown">
                        <Dropdown.Item href="#/Linkction-1">
                          English
                        </Dropdown.Item>
                        <Dropdown.Item href="#/Linkction-2">
                          Spanish
                        </Dropdown.Item>
                      </Dropdown.Menu> */}
                    </Dropdown>
                  </div>
                </div>
                <div className="sub-footer d-flex d-sm-none">
                  <ul className="list-unstyled footer-link-1">
                    {this.state.footer_pages2.length > 0
                      ? this.state.footer_pages2.map((static_page) => (
                          <li className="list-link">
                            <Link
                              to={`/page/${static_page.page_unique_id}`}
                              key={static_page.page_unique_id}
                            >
                              {static_page.title}
                            </Link>
                          </li>
                        ))
                      : ""}
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default FooterIndex;
