import React, { Component, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Container, Row, Col, Image } from "react-bootstrap";
import "./Profile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import ProfileLoader from "../../Loader/ProfileLoader";
import { fetchUserDetailsStart } from "../../../store/actions/UserAction";

const ProfileIndex = (props) => {
  useEffect(() => {
    if (props.users.profile.loading) props.dispatch(fetchUserDetailsStart());
  }, []);

  const userData = props.users.profile;

  return (
    <>
      <div className="profile-sec">
        <Container>
          <div className="profile-box">
            <Row>
              <Col sm={12} md={12}>
                <h2 className="section-title">Profile</h2>
                <div className="profile">
                  {userData.loading ? (
                    <ProfileLoader/>
                  ) : (
                    <Row>
                      <Col sm={12} md={6}>
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/profile-img.svg"
                          }
                          className="profile-img"
                        />
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="profile-details-sec">
                          <div className="user-img-sec">
                            <Image
                              src={userData.data.picture}
                              className="user-img"
                            />
                          </div>
                          <div className="user-info">
                            <h4 className="title">
                              {" "}
                              <FontAwesomeIcon
                                icon={["fas", "user"]}
                                className="icons mr-2"
                              />
                              {userData.data.name}
                            </h4>
                            <h4 className="title">
                              {" "}
                              <FontAwesomeIcon
                                icon={["fas", "envelope"]}
                                className="icons mr-2"
                              />
                              {userData.data.email}
                            </h4>
                            <h4 className="title">
                              {" "}
                              <FontAwesomeIcon
                                icon={["fas", "phone-alt"]}
                                className="icons mr-2"
                              />
                              {userData.data.mobile
                                ? userData.data.mobile
                                : "-"}
                            </h4>
                            <h4 className="title">
                              {" "}
                              <FontAwesomeIcon
                                icon={["fas", "calendar-alt"]}
                                className="icons mr-2"
                              />
                              {userData.data.dob ? userData.data.dob : "-"}
                            </h4>
                            <h4 className="title">
                              {" "}
                              <FontAwesomeIcon
                                icon={["fas", "wallet"]}
                                className="icons mr-2"
                              />
                              {userData.data.paypal_email
                                ? userData.data.paypal_email
                                : "-"}
                            </h4>
                            {userData.data.description ? (
                              <h4 className="title">
                                {" "}
                                <FontAwesomeIcon
                                  icon={["fas", "file"]}
                                  className="icons mr-2"
                                />
                                {userData.data.description}
                              </h4>
                            ) : (
                              " "
                            )}
                            <Link to="/edit-profile">
                              <Button className="edit-profile-btn">
                                Edit Profile
                              </Button>
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  users: state.users,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ProfileIndex);
