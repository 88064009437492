import {
  FETCH_REFERRALS_START,
  FETCH_REFERRALS_SUCCESS,
  FETCH_REFERRALS_FAILURE,
  CHECK_REFERRAL_START,
  CHECK_REFERRAL_SUCCESS,
  CHECK_REFERRAL_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  referral: {
    data: {},
    loading: true,
    error: false,
  },
  checkReferral: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  buttonDisable: false,
  loadingButtonContent: null,
};

const ReferralReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REFERRALS_START:
      return {
        ...state,
        referral: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_REFERRALS_SUCCESS:
      return {
        ...state,
        referral: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_REFERRALS_FAILURE:
      return {
        ...state,
        referral: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case CHECK_REFERRAL_START:
      return {
        ...state,
        checkReferral: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
        buttonDisable: true,
        loadingButtonContent: "Loading...",
      };
    case CHECK_REFERRAL_SUCCESS:
      return {
        ...state,
        checkReferral: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
        buttonDisable: false,
        loadingButtonContent: null,
      };
    case CHECK_REFERRAL_FAILURE:
      return {
        ...state,
        checkReferral: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
        buttonDisable: false,
        loadingButtonContent: null,
      };

    default:
      return state;
  }
};

export default ReferralReducer;
