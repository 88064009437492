import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  VimePlayer,
  VimeVideo,
  VimeDefaultUi,
  VimeDefaultControls,
  VimeSettings,
  VimeMenuItem,
  VimeSubmenu,
  VimeMenuRadio,
  VimeMenuRadioGroup,
  VimeYoutube,
  VimeEmbed,
} from "@vime/react";

const LiveTvVideoPlayerSec = (props) => {
  const [value, setValue] = useState("1");
  const onCheck = (event) => {
    console.log("Chedk.....");
  };

  const onMenuItem1Click = (event) => {
    console.log("chagsdfsdf");
  };

  const onMenuItem2Click = (event) => {
    console.log("chagsdfsdf");
  };

  return (
    <div className="video-ratio-sec-card">
      <div className="main-video-sec">
        <VimePlayer controls>
          <VimeVideo poster={props.video.default_image}>
            {/* These are passed directly to the underlying HTML5 `<video>` element. */}
            {/* Why `data-src`? Lazy loading, you can always use `src` if you prefer.  */}
            <source data-src={props.video.video} />
          </VimeVideo>
          <VimeDefaultUi noSettings>
            <VimeSettings>
              <VimeMenuItem
                label="Menu Item 1"
                badge="BADGE"
                onClick={onMenuItem1Click}
              />

              <VimeMenuItem
                label="Menu Item 2"
                hint="Hint"
                onClick={onMenuItem2Click}
              />

              <VimeSubmenu label="Submenu 1" hint={value}>
                <VimeMenuRadioGroup value={value} onVCheck={onCheck}>
                  <VimeMenuRadio label="Option 1" value="1" />
                  <VimeMenuRadio label="Option 2" value="2" />
                  <VimeMenuRadio label="Option 3" value="3" />
                </VimeMenuRadioGroup>
              </VimeSubmenu>

              <VimeSubmenu label="Submenu 2">
                Random content in here.
              </VimeSubmenu>
            </VimeSettings>
          </VimeDefaultUi>
        </VimePlayer>
      </div>
    </div>
  );
};

export default LiveTvVideoPlayerSec;
