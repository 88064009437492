import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  adEnableDisableVideoVMFailure,
  adEnableDisableVideoVMSuccess,
  approveDeclineVideoVMFailure,
  approveDeclineVideoVMSuccess,
  deleteVideoVMFailure,
  deleteVideoVMSuccess,
  fetchSingleVideoVMFailure,
  fetchSingleVideoVMSuccess,
  fetchVideosVMFailure,
  fetchVideosVMSuccess,
  saveVideoVMFailure,
  saveVideoVMSuccess,
  setRemovePPVVideoVMFailure,
  setRemovePPVVideoVMSuccess,
  singleVideoRevenueFailure,
  singleVideoRevenueSuccess,
} from "../actions/VideoManagementAction";
import {
  AD_ENABLE_DISABLE_VM_START,
  APPROVE_DECLINE_VIDEO_VM_START,
  DELETE_VIDEO_VM_START,
  FETCH_SINGLE_VIDEO_VM_START,
  FETCH_VIDEOS_VM_START,
  SAVE_VIDEO_VM_START,
  SET_REMOVE_PPV_VIDEO_VM_START,
  SINGLE_VIDEO_REVENUE_START,
} from "../actions/ActionConstant";
import { fetchChannelVideosStart } from "../actions/ChannelAction";

function* fetchVideosVMAPI() {
  try {
    const response = yield api.postMethod("spam_videos");
    if (response.data.success) {
      yield put(fetchVideosVMSuccess(response.data.data));
    } else {
      yield put(fetchVideosVMFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchVideosVMFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchSingleVideoVMAPI() {
  try {
    const inputData = yield select(
      (state) => state.videoVM.singleVideoVM.inputData
    );
    const response = yield api.postMethod("spam_videos_add", inputData);
    if (response.data.success) {
      yield put(fetchSingleVideoVMSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(fetchSingleVideoVMFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSingleVideoVMFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* saveVideoVMAPI() {
  try {
    const inputData = yield select(
      (state) => state.videoVM.saveVideoVM.inputData
    );
    const response = yield api.postMethod("spam_videos_remove", inputData);
    if (response.data.success) {
      yield put(saveVideoVMSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(saveVideoVMFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(saveVideoVMFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* singleVideoRevenueVMAPI() {
  try {
    const inputData = yield select(
      (state) => state.videoVM.singleVideoRevenueVM.inputData
    );
    const response = yield api.postMethod("channels_subscribed", inputData);
    if (response.data.success) {
      yield put(singleVideoRevenueSuccess(response.data.data));
    } else {
      yield put(singleVideoRevenueFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(singleVideoRevenueFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* approveDeclineVMAPI() {
  try {
    const inputData = yield select(
      (state) => state.videoVM.appDeclineVM.inputData
    );
    const response = yield api.postMethod("video_tapes_status", inputData);
    if (response.data.success) {
      yield put(approveDeclineVideoVMSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      yield put(fetchChannelVideosStart({ channel_id: inputData.channel_id }));
    } else {
      yield put(approveDeclineVideoVMFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(approveDeclineVideoVMFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* setRemovePPVVMAPI() {
  try {
    const inputData = yield select(
      (state) => state.videoVM.setRemovePPVVM.inputData
    );
    const response = yield api.postMethod("video_tapes_ppv_status", inputData);
    if (response.data.success) {
      yield put(setRemovePPVVideoVMSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      yield put(fetchChannelVideosStart({ channel_id: inputData.channel_id }));
    } else {
      yield put(setRemovePPVVideoVMFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(setRemovePPVVideoVMFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* deleteVideoVMAPI() {
  try {
    const inputData = yield select(
      (state) => state.videoVM.deleteVideoVM.inputData
    );
    const response = yield api.postMethod("video_tapes_delete", inputData);
    if (response.data.success) {
      yield put(deleteVideoVMSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      yield put(fetchChannelVideosStart({ channel_id: inputData.channel_id }));
    } else {
      yield put(deleteVideoVMFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(deleteVideoVMFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* adEnableDisableVMAPI() {
  try {
    const inputData = yield select(
      (state) => state.videoVM.adEnableDisableVM.inputData
    );
    const response = yield api.postMethod("spam_videos_remove", inputData);
    if (response.data.success) {
      yield put(adEnableDisableVideoVMSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      yield put(fetchChannelVideosStart({ channel_id: inputData.channel_id }));
    } else {
      yield put(adEnableDisableVideoVMFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(adEnableDisableVideoVMFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_VIDEOS_VM_START, fetchVideosVMAPI)]);
  yield all([
    yield takeLatest(FETCH_SINGLE_VIDEO_VM_START, fetchSingleVideoVMAPI),
  ]);
  yield all([yield takeLatest(SAVE_VIDEO_VM_START, saveVideoVMAPI)]);
  yield all([
    yield takeLatest(APPROVE_DECLINE_VIDEO_VM_START, approveDeclineVMAPI),
  ]);
  yield all([
    yield takeLatest(SET_REMOVE_PPV_VIDEO_VM_START, setRemovePPVVMAPI),
  ]);

  yield all([yield takeLatest(DELETE_VIDEO_VM_START, deleteVideoVMAPI)]);
  yield all([
    yield takeLatest(SINGLE_VIDEO_REVENUE_START, singleVideoRevenueVMAPI),
  ]);
  yield all([
    yield takeLatest(AD_ENABLE_DISABLE_VM_START, adEnableDisableVMAPI),
  ]);
}
