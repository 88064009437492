export const FETCH_USER_DETAILS_START = "FETCH_USER_DETAILS_START";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAILURE = "FETCH_USER_DETAILS_FAILURE";

export const EDIT_USER_DETAILS = "EDIT_USER_DETAILS";

export const UPDATE_USER_DETAILS_START = "UPDATE_USER_DETAILS_START";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAILURE = "UPDATE_USER_DETAILS_FAILURE";

export const EDIT_CHANGE_PASSWORD = "EDIT_CHANGE_PASSWORD";

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const DELETE_ACCOUNT_START = "DELETE_ACCOUNT_START";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";

export const GET_LOGIN_DETAILS = "GET_LOGIN_DETAILS";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const GET_REGISTER_DETAILS = "GET_REGISTER_DETAILS";

export const DISPLAY_FORGOT_PASSWORD = "DISPLAY_FORGOT_PASSWORD";
export const DISPLAY_LOGIN = "DISPLAY_LOGIN";
export const DISPLAY_SIGNUP = "DISPLAY_SIGNUP";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const GET_FORGOT_PASSWORD_DETAILS = "GET_FORGOT_PASSWORD_DETAILS";

export const FETCH_SUBSCRIPTION_START = "FETCH_SUBSCRIPTION_START";
export const FETCH_SUBSCRIPTION_SUCCESS = "FETCH_SUBSCRIPTION_SUCCESS";
export const FETCH_SUBSCRIPTION_FAILURE = "FETCH_SUBSCRIPTION_FAILURE";

export const FETCH_MY_SUBSCRIPTION_START = "FETCH_MY_SUBSCRIPTION_START";
export const FETCH_MY_SUBSCRIPTION_SUCCESS = "FETCH_MY_SUBSCRIPTION_SUCCESS";
export const FETCH_MY_SUBSCRIPTION_FAILURE = "FETCH_MY_SUBSCRIPTION_FAILURE";

export const FETCH_SINGLE_SUBSCRIPTION_START =
  "FETCH_SINGLE_SUBSCRIPTION_START";
export const FETCH_SINGLE_SUBSCRIPTION_SUCCESS =
  "FETCH_SINGLE_SUBSCRIPTION_SUCCESS";
export const FETCH_SINGLE_SUBSCRIPTION_FAILURE =
  "FETCH_SINGLE_SUBSCRIPTION_FAILURE";

export const SUBSCRIPTION_PAYMENT_START = "SUBSCRIPTION_PAYMENT_START";
export const SUBSCRIPTION_PAYMENT_SUCCESS = "SUBSCRIPTION_PAYMENT_SUCCESS";
export const SUBSCRIPTION_PAYMENT_FAILURE = "SUBSCRIPTION_PAYMENT_FAILURE";

export const SUBSCRIPTION_PAYMENT_PAYPAL_START =
  "SUBSCRIPTION_PAYMENT_PAYPAL_START";
export const SUBSCRIPTION_PAYMENT_PAYPAL_SUCCESS =
  "SUBSCRIPTION_PAYMENT_PAYPAL_SUCCESS";
export const SUBSCRIPTION_PAYMENT_PAYPAL_FAILURE =
  "SUBSCRIPTION_PAYMENT_PAYPAL_FAILURE";

export const ENABLE_SUBSCRIPTION_AUTORENEWAL_START =
  "ENABLE_SUBSCRIPTION_AUTORENEWAL_START";
export const ENABLE_SUBSCRIPTION_AUTORENEWAL_SUCCESS =
  "ENABLE_SUBSCRIPTION_AUTORENEWAL_SUCCESS";
export const ENABLE_SUBSCRIPTION_AUTORENEWAL_FAILURE =
  "ENABLE_SUBSCRIPTION_AUTORENEWAL_FAILURE";

export const DISABLE_SUBSCRIPTION_AUTORENEWAL_START =
  "DISABLE_SUBSCRIPTION_AUTORENEWAL_START";
export const DISABLE_SUBSCRIPTION_AUTORENEWAL_SUCCESS =
  "DISABLE_SUBSCRIPTION_AUTORENEWAL_SUCCESS";
export const DISABLE_SUBSCRIPTION_AUTORENEWAL_FAILURE =
  "DISABLE_SUBSCRIPTION_AUTORENEWAL_FAILURE";

export const FETCH_CARD_DETAILS_START = "FETCH_CARD_DETAILS_START";
export const FETCH_CARD_DETAILS_SUCCESS = "FETCH_CARD_DETAILS_SUCCESS";
export const FETCH_CARD_DETAILS_FAILURE = "FETCH_CARD_DETAILS_FAILURE";

export const DELETE_CARD_START = "DELETE_CARD_START";
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS";
export const DELETE_CARD_FAILURE = "DELETE_CARD_FAILURE";

export const SELECT_DEFAULT_CARD_START = "SELECT_DEFAULT_CARD_START";
export const SELECT_DEFAULT_CARD_SUCCESS = "SELECT_DEFAULT_CARD_SUCCESS";
export const SELECT_DEFAULT_CARD_FAILURE = "SELECT_DEFAULT_CARD_FAILURE";

export const SEND_CONNECTION_ID_START = "SEND_CONNECTION_ID_START";
export const SEND_CONNECTION_ID_SUCCESS = "SEND_CONNECTION_ID_SUCCESS";
export const SEND_CONNECTION_ID_FAILURE = "SEND_CONNECTION_ID_FAILURE";

// Error Handling for logout

export const ERROR_LOGOUT_CHECK = "ERROR_LOGOUT_CHECK";

// Pages

export const FETCH_SINGLE_PAGE_START = "FETCH_SINGLE_PAGE_START";
export const FETCH_SINGLE_PAGE_SUCCESS = "FETCH_SINGLE_PAGE_SUCCESS";
export const FETCH_SINGLE_PAGE_FAILURE = "FETCH_SINGLE_PAGE_FAILURE";

// Check coupon code

export const CHECK_COUPON_CODE_START = "CHECK_COUPON_CODE_START";
export const CHECK_COUPON_CODE_SUCCESS = "CHECK_COUPON_CODE_SUCCESS";
export const CHECK_COUPON_CODE_FAILURE = "CHECK_COUPON_CODE_FAILURE";

// Redeems

export const FETCH_REDEEMS_START = "FETCH_REDEEMS_START";
export const FETCH_REDEEMS_SUCCESS = "FETCH_REDEEMS_SUCCESS";
export const FETCH_REDEEMS_FAILURE = "FETCH_REDEEMS_FAILURE";

export const SEND_REDEEM_START = "SEND_REDEEM_START";
export const SEND_REDEEM_SUCCESS = "SEND_REDEEM_SUCCESS";
export const SEND_REDEEM_FAILURE = "SEND_REDEEM_FAILURE";

export const CANCEL_REDEEM_START = "CANCEL_REDEEM_START";
export const CANCEL_REDEEM_SUCCESS = "CANCEL_REDEEM_SUCCESS";
export const CANCEL_REDEEM_FAILURE = "CANCEL_REDEEM_FAILURE";

// referrals

export const FETCH_REFERRALS_START = "FETCH_REFERRALS_START";
export const FETCH_REFERRALS_SUCCESS = "FETCH_REFERRALS_SUCCESS";
export const FETCH_REFERRALS_FAILURE = "FETCH_REFERRALS_FAILURE";

export const CHECK_REFERRAL_START = "CHECK_REFERRAL_START";
export const CHECK_REFERRAL_SUCCESS = "CHECK_REFERRAL_SUCCESS";
export const CHECK_REFERRAL_FAILURE = "CHECK_REFERRAL_FAILURE";

// WishList

export const FETCH_WISHLIST_START = "FETCH_WISHLIST_START";
export const FETCH_WISHLIST_SUCCESS = "FETCH_WISHLIST_SUCCESS";
export const FETCH_WISHLIST_FAILURE = "FETCH_WISHLIST_FAILURE";

export const WISHLIST_OPERATION_START = "WISHLIST_OPERATION_START";
export const WISHLIST_OPERATION_SUCCESS = "WISHLIST_OPERATION_SUCCESS";
export const WISHLIST_OPERATION_FAILURE = "WISHLIST_OPERATION_FAILURE";

// Spams

export const FETCH_SPAM_VIDEOS_START = "FETCH_SPAM_VIDEOS_START";
export const FETCH_SPAM_VIDEOS_SUCCESS = "FETCH_SPAM_VIDEOS_SUCCESS";
export const FETCH_SPAM_VIDEOS_FAILURE = "FETCH_SPAM_VIDEOS_FAILURE";

export const ADD_TO_SPAM_START = "ADD_TO_SPAM_START";
export const ADD_TO_SPAM_SUCCESS = "ADD_TO_SPAM_SUCCESS";
export const ADD_TO_SPAM_FAILURE = "ADD_TO_SPAM_FAILURE";

export const REMOVE_SPAM_START = "REMOVE_SPAM_START";
export const REMOVE_SPAM_SUCCESS = "REMOVE_SPAM_SUCCESS";
export const REMOVE_SPAM_FAILURE = "REMOVE_SPAM_FAILURE";

export const FETCH_SPAM_REASON_START = "FETCH_SPAM_REASON_START";
export const FETCH_SPAM_REASON_SUCCESS = "FETCH_SPAM_REASON_SUCCESS";
export const FETCH_SPAM_REASON_FAILURE = "FETCH_SPAM_REASON_FAILURE";

// Video History

export const FETCH_VIDEO_HISTORY_START = "FETCH_VIDEO_HISTORY_START";
export const FETCH_VIDEO_HISTORY_SUCCESS = "FETCH_VIDEO_HISTORY_SUCCESS";
export const FETCH_VIDEO_HISTORY_FAILURE = "FETCH_VIDEO_HISTORY_FAILURE";

export const ADD_VIDEO_HISTORY_START = "ADD_VIDEO_HISTORY_START";
export const ADD_VIDEO_HISTORY_SUCCESS = "ADD_VIDEO_HISTORY_SUCCESS";
export const ADD_VIDEO_HISTORY_FAILURE = "ADD_VIDEO_HISTORY_FAILURE";

export const REMOVE_VIDEO_HISTORY_START = "REMOVE_VIDEO_HISTORY_START";
export const REMOVE_VIDEO_HISTORY_SUCCESS = "REMOVE_VIDEO_HISTORY_SUCCESS";
export const REMOVE_VIDEO_HISTORY_FAILURE = "REMOVE_VIDEO_HISTORY_FAILURE";

// Channels

export const FETCH_CHANNELS_START = "FETCH_CHANNELS_START";
export const FETCH_CHANNELS_SUCCESS = "FETCH_CHANNELS_SUCCESS";
export const FETCH_CHANNELS_FAILURE = "FETCH_CHANNELS_FAILURE";

export const FETCH_SINGLE_CHANNEL_START = "FETCH_SINGLE_CHANNEL_START";
export const FETCH_SINGLE_CHANNEL_SUCCESS = "FETCH_SINGLE_CHANNEL_SUCCESS";
export const FETCH_SINGLE_CHANNEL_FAILURE = "FETCH_SINGLE_CHANNEL_FAILURE";

export const FETCH_CHANNEL_VIDEOS_START = "FETCH_CHANNEL_VIDEOS_START";
export const FETCH_CHANNEL_VIDEOS_SUCCESS = "FETCH_CHANNEL_VIDEOS_SUCCESS";
export const FETCH_CHANNEL_VIDEOS_FAILURE = "FETCH_CHANNEL_VIDEOS_FAILURE";

export const FETCH_CHANNEL_PLAYLISTS_START = "FETCH_CHANNEL_PLAYLISTS_START";
export const FETCH_CHANNEL_PLAYLISTS_SUCCESS =
  "FETCH_CHANNEL_PLAYLISTS_SUCCESS";
export const FETCH_CHANNEL_PLAYLISTS_FAILURE =
  "FETCH_CHANNEL_PLAYLISTS_FAILURE";

export const FETCH_SUBSCRIBED_CHANNEL_START = "FETCH_SUBSCRIBED_CHANNEL_START";
export const FETCH_SUBSCRIBED_CHANNEL_SUCCESS =
  "FETCH_SUBSCRIBED_CHANNEL_SUCCESS";
export const FETCH_SUBSCRIBED_CHANNEL_FAILURE =
  "FETCH_SUBSCRIBED_CHANNEL_FAILURE";

export const SUBSCRIBE_UNSUBS_CHANNEL_START = "SUBSCRIBE_UNSUBS_CHANNEL_START";
export const SUBSCRIBE_UNSUBS_CHANNEL_SUCCESS =
  "SUBSCRIBE_UNSUBS_CHANNEL_SUCCESS";
export const SUBSCRIBE_UNSUBS_CHANNEL_FAILURE =
  "SUBSCRIBE_UNSUBS_CHANNEL_FAILURE";

export const CREATE_CHANNEL_START = "CREATE_CHANNEL_START";
export const CREATE_CHANNEL_SUCCESS = "CREATE_CHANNEL_SUCCESS";
export const CREATE_CHANNEL_FAILURE = "CREATE_CHANNEL_FAILURE";

export const DELETE_CHANNEL_START = "DELETE_CHANNEL_START";
export const DELETE_CHANNEL_SUCCESS = "DELETE_CHANNEL_SUCCESS";
export const DELETE_CHANNEL_FAILURE = "DELETE_CHANNEL_FAILURE";

export const FETCH_OWNER_CHANNEL_LIST_START = "FETCH_OWNER_CHANNEL_LIST_START";
export const FETCH_OWNER_CHANNEL_LIST_SUCCESS =
  "FETCH_OWNER_CHANNEL_LIST_SUCCESS";
export const FETCH_OWNER_CHANNEL_LIST_FAILURE =
  "FETCH_OWNER_CHANNEL_LIST_FAILURE";

export const FETCH_SINGLE_CHANNEL_OWNER_START =
  "FETCH_SINGLE_CHANNEL_OWNER_START";
export const FETCH_SINGLE_CHANNEL_OWNER_SUCCESS =
  "FETCH_SINGLE_CHANNEL_OWNER_SUCCESS";
export const FETCH_SINGLE_CHANNEL_OWNER_FAILURE =
  "FETCH_SINGLE_CHANNEL_OWNER_FAILURE";

export const FETCH_SINGLE_VIDEO_CHANNEL_OWNER_START =
  "FETCH_SINGLE_VIDEO_CHANNEL_OWNER_START";
export const FETCH_SINGLE_VIDEO_CHANNEL_OWNER_SUCCESS =
  "FETCH_SINGLE_VIDEO_CHANNEL_OWNER_SUCCESS";
export const FETCH_SINGLE_VIDEO_CHANNEL_OWNER_FAILURE =
  "FETCH_SINGLE_VIDEO_CHANNEL_OWNER_FAILURE";

// Bell Notification

export const FETCH_BELL_NOTIFICATION_START = "FETCH_BELL_NOTIFICATION_START";
export const FETCH_BELL_NOTIFICATION_SUCCESS =
  "FETCH_BELL_NOTIFICATION_SUCCESS";
export const FETCH_BELL_NOTIFICATION_FAILURE =
  "FETCH_BELL_NOTIFICATION_FAILURE";

export const UPDATE_BELL_NOTIFICATION_START = "UPDATE_BELL_NOTIFICATION_START";
export const UPDATE_BELL_NOTIFICATION_SUCCESS =
  "UPDATE_BELL_NOTIFICATION_SUCCESS";
export const UPDATE_BELL_NOTIFICATION_FAILURE =
  "UPDATE_BELL_NOTIFICATION_FAILURE";

export const FETCH_NOTIFICATION_COUNT_START = "FETCH_NOTIFICATION_COUNT_START";
export const FETCH_NOTIFICATION_COUNT_SUCCESS =
  "FETCH_NOTIFICATION_COUNT_SUCCESS";
export const FETCH_NOTIFICATION_COUNT_FAILURE =
  "FETCH_NOTIFICATION_COUNT_FAILURE";

// Channel playlists

export const FETCH_CHANNEL_PLAYLIST_START = "FETCH_CHANNEL_PLAYLIST_START";
export const FETCH_CHANNEL_PLAYLIST_SUCCESS = "FETCH_CHANNEL_PLAYLIST_SUCCESS";
export const FETCH_CHANNEL_PLAYLIST_FAILURE = "FETCH_CHANNEL_PLAYLIST_FAILURE";

export const CREATE_CHANNEL_PLAYLIST_START = "CREATE_CHANNEL_PLAYLIST_START";
export const CREATE_CHANNEL_PLAYLIST_SUCCESS =
  "CREATE_CHANNEL_PLAYLIST_SUCCESS";
export const CREATE_CHANNEL_PLAYLIST_FAILURE =
  "CREATE_CHANNEL_PLAYLIST_FAILURE";

export const ADD_VIDEO_CHANNEL_PLAYLIST_START =
  "ADD_VIDEO_CHANNEL_PLAYLIST_START";
export const ADD_VIDEO_CHANNEL_PLAYLIST_SUCCESS =
  "ADD_VIDEO_CHANNEL_PLAYLIST_SUCCESS";
export const ADD_VIDEO_CHANNEL_PLAYLIST_FAILURE =
  "ADD_VIDEO_CHANNEL_PLAYLIST_FAILURE";

export const REMOVE_VIDEO_CHANNEL_PLAYLIST_START =
  "REMOVE_VIDEO_CHANNEL_PLAYLIST_START";
export const REMOVE_VIDEO_CHANNEL_PLAYLIST_SUCCESS =
  "REMOVE_VIDEO_CHANNEL_PLAYLIST_SUCCESS";
export const REMOVE_VIDEO_CHANNEL_PLAYLIST_FAILURE =
  "REMOVE_VIDEO_CHANNEL_PLAYLIST_FAILURE";

export const FETCH_SINGLE_CHANNEL_PLAYLIST_START =
  "FETCH_SINGLE_CHANNEL_PLAYLIST_START";
export const FETCH_SINGLE_CHANNEL_PLAYLIST_SUCCESS =
  "FETCH_SINGLE_CHANNEL_PLAYLIST_SUCCESS";
export const FETCH_SINGLE_CHANNEL_PLAYLIST_FAILURE =
  "FETCH_SINGLE_CHANNEL_PLAYLIST_FAILURE";

export const DELETE_CHANNEL_PLAYLIST_START = "DELETE_CHANNEL_PLAYLIST_START";
export const DELETE_CHANNEL_PLAYLIST_SUCCESS =
  "DELETE_CHANNEL_PLAYLIST_SUCCESS";
export const DELETE_CHANNEL_PLAYLIST_FAILURE =
  "DELETE_CHANNEL_PLAYLIST_FAILURE";

// Viewer playlists

export const FETCH_VIEWER_PLAYLIST_START = "FETCH_VIEWER_PLAYLIST_START";
export const FETCH_VIEWER_PLAYLIST_SUCCESS = "FETCH_VIEWER_PLAYLIST_SUCCESS";
export const FETCH_VIEWER_PLAYLIST_FAILURE = "FETCH_VIEWER_PLAYLIST_FAILURE";

export const CREATE_VIEWER_PLAYLIST_START = "CREATE_VIEWER_PLAYLIST_START";
export const CREATE_VIEWER_PLAYLIST_SUCCESS = "CREATE_VIEWER_PLAYLIST_SUCCESS";
export const CREATE_VIEWER_PLAYLIST_FAILURE = "CREATE_VIEWER_PLAYLIST_FAILURE";

export const ADD_VIDEO_VIEWER_PLAYLIST_START =
  "ADD_VIDEO_VIEWER_PLAYLIST_START";
export const ADD_VIDEO_VIEWER_PLAYLIST_SUCCESS =
  "ADD_VIDEO_VIEWER_PLAYLIST_SUCCESS";
export const ADD_VIDEO_VIEWER_PLAYLIST_FAILURE =
  "ADD_VIDEO_VIEWER_PLAYLIST_FAILURE";

export const REMOVE_VIDEO_VIEWER_PLAYLIST_START =
  "REMOVE_VIDEO_VIEWER_PLAYLIST_START";
export const REMOVE_VIDEO_VIEWER_PLAYLIST_SUCCESS =
  "REMOVE_VIDEO_VIEWER_PLAYLIST_SUCCESS";
export const REMOVE_VIDEO_VIEWER_PLAYLIST_FAILURE =
  "REMOVE_VIDEO_VIEWER_PLAYLIST_FAILURE";

export const FETCH_SINGLE_VIEWER_PLAYLIST_START =
  "FETCH_SINGLE_VIEWER_PLAYLIST_START";
export const FETCH_SINGLE_VIEWER_PLAYLIST_SUCCESS =
  "FETCH_SINGLE_VIEWER_PLAYLIST_SUCCESS";
export const FETCH_SINGLE_VIEWER_PLAYLIST_FAILURE =
  "FETCH_SINGLE_VIEWER_PLAYLIST_FAILURE";

export const DELETE_VIEWER_PLAYLIST_START = "DELETE_VIEWER_PLAYLIST_START";
export const DELETE_VIEWER_PLAYLIST_SUCCESS = "DELETE_VIEWER_PLAYLIST_SUCCESS";
export const DELETE_VIEWER_PLAYLIST_FAILURE = "DELETE_VIEWER_PLAYLIST_FAILURE";

// Video management System

export const FETCH_VIDEOS_VM_START = "FETCH_VIDEOS_VM_START";
export const FETCH_VIDEOS_VM_SUCCESS = "FETCH_VIDEOS_VM_SUCCESS";
export const FETCH_VIDEOS_VM_FAILURE = "FETCH_VIDEOS_VM_FAILURE";

export const FETCH_SINGLE_VIDEO_VM_START = "FETCH_SINGLE_VIDEO_VM_START";
export const FETCH_SINGLE_VIDEO_VM_SUCCESS = "FETCH_SINGLE_VIDEO_VM_SUCCESS";
export const FETCH_SINGLE_VIDEO_VM_FAILURE = "FETCH_SINGLE_VIDEO_VM_FAILURE";

export const SAVE_VIDEO_VM_START = "SAVE_VIDEO_VM_START";
export const SAVE_VIDEO_VM_SUCCESS = "SAVE_VIDEO_VM_SUCCESS";
export const SAVE_VIDEO_VM_FAILURE = "SAVE_VIDEO_VM_FAILURE";

export const APPROVE_DECLINE_VIDEO_VM_START = "APPROVE_DECLINE_VIDEO_VM_START";
export const APPROVE_DECLINE_VIDEO_VM_SUCCESS =
  "APPROVE_DECLINE_VIDEO_VM_SUCCESS";
export const APPROVE_DECLINE_VIDEO_VM_FAILURE =
  "APPROVE_DECLINE_VIDEO_VM_FAILURE";

export const SET_REMOVE_PPV_VIDEO_VM_START = "SET_REMOVE_PPV_VIDEO_VM_START";
export const SET_REMOVE_PPV_VIDEO_VM_SUCCESS =
  "SET_REMOVE_PPV_VIDEO_VM_SUCCESS";
export const SET_REMOVE_PPV_VIDEO_VM_FAILURE =
  "SET_REMOVE_PPV_VIDEO_VM_FAILURE";

export const DELETE_VIDEO_VM_START = "DELETE_VIDEO_VM_START";
export const DELETE_VIDEO_VM_SUCCESS = "DELETE_VIDEO_VM_SUCCESS";
export const DELETE_VIDEO_VM_FAILURE = "DELETE_VIDEO_VM_FAILURE";

export const SINGLE_VIDEO_REVENUE_START = "SINGLE_VIDEO_REVENUE_START";
export const SINGLE_VIDEO_REVENUE_SUCCESS = "SINGLE_VIDEO_REVENUE_SUCCESS";
export const SINGLE_VIDEO_REVENUE_FAILURE = "SINGLE_VIDEO_REVENUE_FAILURE";

export const AD_ENABLE_DISABLE_VM_START = "AD_ENABLE_DISABLE_VM_START";
export const AD_ENABLE_DISABLE_VM_SUCCESS = "AD_ENABLE_DISABLE_VM_SUCCESS";
export const AD_ENABLE_DISABLE_VM_FAILURE = "AD_ENABLE_DISABLE_VM_FAILURE";

// PPV constants

export const FETCH_PPV_HISTORY_START = "FETCH_PPV_HISTORY_START";
export const FETCH_PPV_HISTORY_SUCCESS = "FETCH_PPV_HISTORY_SUCCESS";
export const FETCH_PPV_HISTORY_FAILURE = "FETCH_PPV_HISTORY_FAILURE";

export const PPV_PAY_STRIPE_START = "PPV_PAY_STRIPE_START";
export const PPV_PAY_STRIPE_SUCCESS = "PPV_PAY_STRIPE_SUCCESS";
export const PPV_PAY_STRIPE_FAILURE = "PPV_PAY_STRIPE_FAILURE";

export const PPV_PAY_PAYPAL_START = "PPV_PAY_PAYPAL_START";
export const PPV_PAY_PAYPAL_SUCCESS = "PPV_PAY_PAYPAL_SUCCESS";
export const PPV_PAY_PAYPAL_FAILURE = "PPV_PAY_PAYPAL_FAILURE";

// Single Video Details constants

export const FETCH_SINGLE_VIDEO_START = "FETCH_SINGLE_VIDEO_START";
export const FETCH_SINGLE_VIDEO_SUCCESS = "FETCH_SINGLE_VIDEO_SUCCESS";
export const FETCH_SINGLE_VIDEO_FAILURE = "FETCH_SINGLE_VIDEO_FAILURE";

export const FETCH_VIDEO_COMMENTS_START = "FETCH_VIDEO_COMMENTS_START";
export const FETCH_VIDEO_COMMENTS_SUCCESS = "FETCH_VIDEO_COMMENTS_SUCCESS";
export const FETCH_VIDEO_COMMENTS_FAILURE = "FETCH_VIDEO_COMMENTS_FAILURE";

export const ADD_COMMENTS_START = "ADD_COMMENTS_START";
export const ADD_COMMENTS_SUCCESS = "ADD_COMMENTS_SUCCESS";
export const ADD_COMMENTS_FAILURE = "ADD_COMMENTS_FAILURE";

export const LIKE_VIDEO_START = "LIKE_VIDEO_START";
export const LIKE_VIDEO_SUCCESS = "LIKE_VIDEO_SUCCESS";
export const LIKE_VIDEO_FAILURE = "LIKE_VIDEO_FAILURE";

export const DISLIKE_VIDEO_START = "DISLIKE_VIDEO_START";
export const DISLIKE_VIDEO_SUCCESS = "DISLIKE_VIDEO_SUCCESS";
export const DISLIKE_VIDEO_FAILURE = "DISLIKE_VIDEO_FAILURE";

export const UPLOAD_VIDEO_START = "UPLOAD_VIDEO_START";
export const UPLOAD_VIDEO_SUCCESS = "UPLOAD_VIDEO_SUCCESS";
export const UPLOAD_VIDEO_FAILURE = "UPLOAD_VIDEO_FAILURE";


export const SET_DEFAULT_IMAGE_START = "SET_DEFAULT_IMAGE_START";
export const SET_DEFAULT_IMAGE_SUCCESS = "SET_DEFAULT_IMAGE_SUCCESS";
export const SET_DEFAULT_IMAGE_FAILURE = "SET_DEFAULT_IMAGE_FAILURE";

export const FETCH_VIDEO_IMAGES_START = "FETCH_VIDEO_IMAGES_START";
export const FETCH_VIDEO_IMAGES_SUCCESS = "FETCH_VIDEO_IMAGES_SUCCESS";
export const FETCH_VIDEO_IMAGES_FAILURE = "FETCH_VIDEO_IMAGES_FAILURE";

// Tags constants

export const FETCH_TAGS_START = "FETCH_TAGS_START";
export const FETCH_TAGS_SUCCESS = "FETCH_TAGS_SUCCESS";
export const FETCH_TAGS_FAILURE = "FETCH_TAGS_FAILURE";

export const FETCH_TAG_VIDEOS_START = "FETCH_TAG_VIDEOS_START";
export const FETCH_TAG_VIDEOS_SUCCESS = "FETCH_TAG_VIDEOS_SUCCESS";
export const FETCH_TAG_VIDEOS_FAILURE = "FETCH_TAG_VIDEOS_FAILURE";

export const FETCH_SINGLE_TAG_START = "FETCH_SINGLE_TAG_START";
export const FETCH_SINGLE_TAG_SUCCESS = "FETCH_SINGLE_TAG_SUCCESS";
export const FETCH_SINGLE_TAG_FAILURE = "FETCH_SINGLE_TAG_FAILURE";

// Category constants

export const FETCH_CATEGORIES_START = "FETCH_CATEGORIES_START";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_FAILURE = "FETCH_CATEGORIES_FAILURE";

export const FETCH_CATEGORY_VIDEOS_START = "FETCH_CATEGORY_VIDEOS_START";
export const FETCH_CATEGORY_VIDEOS_SUCCESS = "FETCH_CATEGORY_VIDEOS_SUCCESS";
export const FETCH_CATEGORY_VIDEOS_FAILURE = "FETCH_CATEGORY_VIDEOS_FAILURE";

export const FETCH_SINGLE_CATEGORY_START = "FETCH_SINGLE_CATEGORY_START";
export const FETCH_SINGLE_CATEGORY_SUCCESS = "FETCH_SINGLE_CATEGORY_SUCCESS";
export const FETCH_SINGLE_CATEGORY_FAILURE = "FETCH_SINGLE_CATEGORY_FAILURE";

// Core Api's

export const FETCH_HOMEPAGE_DATA_START = "FETCH_HOMEPAGE_DATA_START";
export const FETCH_HOMEPAGE_DATA_SUCCESS = "FETCH_HOMEPAGE_DATA_SUCCESS";
export const FETCH_HOMEPAGE_DATA_FAILURE = "FETCH_HOMEPAGE_DATA_FAILURE";

export const FETCH_TRENDING_DATA_START = "FETCH_TRENDING_DATA_START";
export const FETCH_TRENDING_DATA_SUCCESS = "FETCH_TRENDING_DATA_SUCCESS";
export const FETCH_TRENDING_DATA_FAILURE = "FETCH_TRENDING_DATA_FAILURE";

export const FETCH_TAGS_DATA_START = "FETCH_TAGS_DATA_START";
export const FETCH_TAGS_DATA_SUCCESS = "FETCH_TAGS_DATA_SUCCESS";
export const FETCH_TAGS_DATA_FAILURE = "FETCH_TAGS_DATA_FAILURE";

export const FETCH_CATEGORY_DATA_START = "FETCH_CATEGORY_DATA_START";
export const FETCH_CATEGORY_DATA_SUCCESS = "FETCH_CATEGORY_DATA_SUCCESS";
export const FETCH_CATEGORY_DATA_FAILURE = "FETCH_CATEGORY_DATA_FAILURE";

export const FETCH_SUGGESTION_DATA_START = "FETCH_SUGGESTION_DATA_START";
export const FETCH_SUGGESTION_DATA_SUCCESS = "FETCH_SUGGESTION_DATA_SUCCESS";
export const FETCH_SUGGESTION_DATA_FAILURE = "FETCH_SUGGESTION_DATA_FAILURE";

export const FETCH_SEARCH_DATA_START = "FETCH_SEARCH_DATA_START";
export const FETCH_SEARCH_DATA_SUCCESS = "FETCH_SEARCH_DATA_SUCCESS";
export const FETCH_SEARCH_DATA_FAILURE = "FETCH_SEARCH_DATA_FAILURE";

export const FETCH_PREMIUM_DATA_START = "FETCH_PREMIUM_DATA_START";
export const FETCH_PREMIUM_DATA_SUCCESS = "FETCH_PREMIUM_DATA_SUCCESS";
export const FETCH_PREMIUM_DATA_FAILURE = "FETCH_PREMIUM_DATA_FAILURE";

// Live tv constants

export const FETCH_LIVETV_START = "FETCH_LIVETV_START";
export const FETCH_LIVETV_SUCCESS = "FETCH_LIVETV_SUCCESS";
export const FETCH_LIVETV_FAILURE = "FETCH_LIVETV_FAILURE";

export const FETCH_SINGLE_LIVETV_START = "FETCH_SINGLE_LIVETV_START";
export const FETCH_SINGLE_LIVETV_SUCCESS = "FETCH_SINGLE_LIVETV_SUCCESS";
export const FETCH_SINGLE_LIVETV_FAILURE = "FETCH_SINGLE_LIVETV_FAILURE";

// Live Streaming owner constants - LS - Live Streaming

export const FETCH_LS_OWNER_START = "FETCH_LS_OWNER_START";
export const FETCH_LS_OWNER_SUCCESS = "FETCH_LS_OWNER_SUCCESS";
export const FETCH_LS_OWNER_FAILURE = "FETCH_LS_OWNER_FAILURE";

export const FETCH_SINGLE_LS_OWNER_START = "FETCH_SINGLE_LS_OWNER_START";
export const FETCH_SINGLE_LS_OWNER_SUCCESS = "FETCH_SINGLE_LS_OWNER_SUCCESS";
export const FETCH_SINGLE_LS_OWNER_FAILURE = "FETCH_SINGLE_LS_OWNER_FAILURE";

export const SAVE_LS_OWNER_START = "SAVE_LS_OWNER_START";
export const SAVE_LS_OWNER_SUCCESS = "SAVE_LS_OWNER_SUCCESS";
export const SAVE_LS_OWNER_FAILURE = "SAVE_LS_OWNER_FAILURE";

export const END_LS_OWNER_START = "END_LS_OWNER_START";
export const END_LS_OWNER_SUCCESS = "END_LS_OWNER_SUCCESS";
export const END_LS_OWNER_FAILURE = "END_LS_OWNER_FAILURE";

export const ERASE_OLD_LS_START = "ERASE_OLD_LS_START";
export const ERASE_OLD_LS_SUCCESS = "ERASE_OLD_LS_SUCCESS";
export const ERASE_OLD_LS_FAILURE = "ERASE_OLD_LS_FAILURE";

export const CHECK_LS_STATUS_START = "CHECK_LS_STATUS_START";
export const CHECK_LS_STATUS_SUCCESS = "CHECK_LS_STATUS_SUCCESS";
export const CHECK_LS_STATUS_FAILURE = "CHECK_LS_STATUS_FAILURE";

export const FETCH_LS_USER_START = "FETCH_LS_USER_START";
export const FETCH_LS_USER_SUCCESS = "FETCH_LS_USER_SUCCESS";
export const FETCH_LS_USER_FAILURE = "FETCH_LS_USER_FAILURE";

export const FETCH_SINGLE_LS_USER_START = "FETCH_SINGLE_LS_USER_START";
export const FETCH_SINGLE_LS_USER_SUCCESS = "FETCH_SINGLE_LS_USER_SUCCESS";
export const FETCH_SINGLE_LS_USER_FAILURE = "FETCH_SINGLE_LS_USER_FAILURE";
