import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Tabs,
  Tab,
  Dropdown,
  DropdownButton,
  Image,
} from "react-bootstrap";
import "./LiveVideo.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class LiveVideo extends Component {
  render() {
    return (
      <>
        <div className="features-sec live-video-sec">
          <Container>
            <Row>
              <Col sm={12} md={12}>
                <h2 className="section-title">Live Video</h2>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12}>
                <div className="section-1">
                  <Row>
                    <Col xs={12} md={3}>
                      <div className="features-card">
                        <Link to="#">
                          <div className="features-card-img">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/img/video-1.jpg"
                              }
                              className="video-img"
                            />
                          </div>
                          <div className="features-card-details">
                            <h4 className="title">Testing</h4>
                            <p className="desc">
                              <span className="view-count">0 views</span>
                              <span>12 second ago</span>
                            </p>
                          </div>
                          <div className="time-count">
                            <h6>Free</h6>
                          </div>
                        </Link>
                      </div>
                    </Col>
                    <Col xs={12} md={3}>
                      <div className="features-card">
                        <Link to="#">
                          <div className="features-card-img">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/img/video-2.jpg"
                              }
                              className="video-img"
                            />
                          </div>
                          <div className="features-card-details">
                            <h4 className="title">Testing</h4>
                            <p className="desc">
                              <span className="view-count">0 views</span>
                              <span>12 second ago</span>
                            </p>
                          </div>
                          <div className="time-count">
                            <h6>Free</h6>
                          </div>
                        </Link>
                      </div>
                    </Col>
                    <Col xs={12} md={3}>
                      <div className="features-card">
                        <Link to="#">
                          <div className="features-card-img">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/img/video-3.jpg"
                              }
                              className="video-img"
                            />
                          </div>
                          <div className="features-card-details">
                            <h4 className="title">Testing</h4>
                            <p className="desc">
                              <span className="view-count">0 views</span>
                              <span>12 second ago</span>
                            </p>
                          </div>
                          <div className="time-count">
                            <h6>Free</h6>
                          </div>
                        </Link>
                      </div>
                    </Col>
                    <Col xs={12} md={3}>
                      <div className="features-card">
                        <Link to="#">
                          <div className="features-card-img">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/img/video-4.jpg"
                              }
                              className="video-img"
                            />
                          </div>
                          <div className="features-card-details">
                            <h4 className="title">Testing</h4>
                            <p className="desc">
                              <span className="view-count">0 views</span>
                              <span>12 second ago</span>
                            </p>
                          </div>
                          <div className="time-count">
                            <h6>Free</h6>
                          </div>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                  <div className="space-lg"></div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default LiveVideo;
