import {
  FETCH_BELL_NOTIFICATION_START,
  FETCH_BELL_NOTIFICATION_SUCCESS,
  FETCH_BELL_NOTIFICATION_FAILURE,
  UPDATE_BELL_NOTIFICATION_START,
  UPDATE_BELL_NOTIFICATION_SUCCESS,
  UPDATE_BELL_NOTIFICATION_FAILURE,
  FETCH_NOTIFICATION_COUNT_START,
  FETCH_NOTIFICATION_COUNT_SUCCESS,
  FETCH_NOTIFICATION_COUNT_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  notification: {
    data: {},
    loading: true,
    error: false,
  },
  updateNot: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  notiCount: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
};

const BellNotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BELL_NOTIFICATION_START:
      return {
        ...state,
        notification: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_BELL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_BELL_NOTIFICATION_FAILURE:
      return {
        ...state,
        notification: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case UPDATE_BELL_NOTIFICATION_START:
      return {
        ...state,
        updateNot: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case UPDATE_BELL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        updateNot: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case UPDATE_BELL_NOTIFICATION_FAILURE:
      return {
        ...state,
        updateNot: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case FETCH_NOTIFICATION_COUNT_START:
      return {
        ...state,
        notiCount: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_NOTIFICATION_COUNT_SUCCESS:
      return {
        ...state,
        notiCount: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_NOTIFICATION_COUNT_FAILURE:
      return {
        ...state,
        notiCount: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
      };

    default:
      return state;
  }
};

export default BellNotificationReducer;
