import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Tabs,
  Tab,
  Dropdown,
  DropdownButton,
  Image,
  Badge,
  Media,
} from "react-bootstrap";
import "./SingleVideoSimplified.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class SingleVideoSimplified extends Component {
  constructor(props) {
    super(props);
    this.handleToggleVisib = this.handleToggleVisib.bind(this);
    this.state = {
      visib: false,
    };
  }

  handleToggleVisib() {
    this.setState({ visib: !this.state.visib });
  }

  render() {
    return (
      <>
        <section className="single-video-simplified-sec">
          <Container>
            <Row>
              <Col xs={12} md={8}>
                <div className="video-ratio-sec-card">
                  <div className="main-video-sec">
                    <Image
                      src={window.location.origin + "/assets/img/video-1.jpg"}
                      className="main-video"
                    />
                    <div className="play-btn">
                      <FontAwesomeIcon
                        icon={["fas", "play"]}
                        className="play-icon"
                      />
                    </div>
                  </div>
                </div>
                <h3 className="video-caption-title">
                  Analyzing the Mass Effect: Andromeda E3 2020 Trailer
                </h3>
                <div className="video-bottom-toolbar d-none d-sm-block">
                  <div className="video-user-details">
                    <Image
                      src={
                        window.location.origin + "/assets/img/profile-img.jpg"
                      }
                      className="user-img"
                    />
                  </div>
                  <div className="video-user-info-sec">
                    <div>
                      <p className="video-info">
                        <span className="title">NaughtyDog</span>
                        <span className="video-count">52 Videos</span>
                      </p>
                      <div className="subscribe-details">
                        <h5 className="subscribe-btn">Subscribe</h5>
                        <p className="subscribe-count">22,548,145</p>
                      </div>
                    </div>
                    <div className="column-half">
                      <h3 className="view-head-count">
                        2,729,347 <span className="small">views</span>
                      </h3>
                      <div className="video-views-details">
                        <span className="view-percentage-count pink-clr">
                          95%
                        </span>
                        <span className="view-count green-clr">
                          <FontAwesomeIcon icon="circle" className="mr-1" />
                          39,852
                        </span>
                        <span className="view-count-1 green-grey">
                          <FontAwesomeIcon icon="circle" className="mr-1" />
                          852
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="resp-toolbar-about">
                  <div className="video-bottom-mobile-toolbar">
                    <div className="video-user-details">
                      <Image
                        src={
                          window.location.origin + "/assets/img/profile-img.jpg"
                        }
                        className="user-img"
                      />
                      <div className="video-user-info-sec">
                        <div className="">
                          <p className="video-info">
                            <span className="title">NaughtyDog</span>
                          </p>
                          <p className="video-info">
                            <span className="video-count">52 Videos</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="resp-add-subscription">
                      <FontAwesomeIcon
                        icon={["fas", "plus"]}
                        className="add-icon"
                      />
                    </div>
                  </div>
                  <div className="column-half d-block d-sm-none">
                    <h3 className="view-head-count">
                      2,729,347 <span className="small">views</span>
                    </h3>
                    <div className="video-views-details">
                      <span className="view-percentage-count pink-clr">
                        95%
                      </span>
                      <span className="view-count green-clr">
                        <FontAwesomeIcon icon="circle" className="mr-1" />
                        39,852
                      </span>
                      <span className="view-count-1 green-grey">
                        <FontAwesomeIcon icon="circle" className="mr-1" />
                        852
                      </span>
                    </div>
                  </div>
                </div>
                <div className="video-menu-list-sec">
                  <div className="video-description-sec">
                    <Row>
                      <Col xs={12} md={9} className="padding-right-zero">
                        <div className="cast-description-details">
                          <h4 className="title">Cast :</h4>
                          <p className="desc">
                            Nathan Drake, Victor Sullivan, Sam Darke, Elena
                            Fisher
                          </p>
                          <h4 className="title">Category :</h4>
                          <p className="desc">
                            Gaming, PS4 Exclusive, Gameplay, 1080p
                          </p>
                          <h4 className="title">About :</h4>
                          <p className="desc">
                            Three years after the events of Uncharted 3: Darke's
                            Deception, Nathan Drake, now retired as a fortune
                            hunter, has settled into a normal life with his wife
                            Elena Fisher. His world is then turned upside down
                            when his older brother Sam, long belived to be dead,
                            suddenly reappears seeking Drake's help
                          </p>
                          <h4 className="title">Tags :</h4>
                          <div className="desc-badge">
                            <Badge className="badge badge-tag">
                              Uncharted 4
                            </Badge>
                            <Badge className="badge badge-tag">
                              Playstation 4
                            </Badge>
                            <Badge className="badge badge-tag">Gameplay</Badge>
                            <Badge className="badge badge-tag">1080P</Badge>
                            <Badge className="badge badge-tag">ps4Share</Badge>
                            <Badge className="badge badge-tag">+6</Badge>
                          </div>
                          <div className="releases-details">
                            <h3 className="title">
                              Release Date :{" "}
                              <span className="sub-desc">2 Days ago</span>
                            </h3>
                            <h3 className="title">
                              License :{" "}
                              <span className="sub-desc">Standard</span>
                            </h3>
                          </div>
                          <div className="show-less-btn">
                            <Button className="btn-show-less">Show Less</Button>
                          </div>
                        </div>
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        className="padding-left-zero d-none d-sm-block"
                      >
                        <div className="purchase-card">
                          <h4 className="title">Game :</h4>
                          <p className="desc">Uncharted 4</p>
                          <div className="purchase-img-sec">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/img/video-1.jpg"
                              }
                              className="purchase-video-img"
                            />
                          </div>
                          <Button className="btn btn-purchase btn-block">
                            Purchase
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="d-flex d-sm-none resp-arrow-bottom-sec">
                    <ul className="list-unstyled">
                      <Media as="li">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/mobile-arrow-1.png"
                          }
                          className="resp-arrow-bottom-1"
                        />
                      </Media>
                    </ul>
                  </div>
                  <div className="google-adsense-add-sec">
                    <Image
                      src={window.location.origin + "/assets/img/video-1.jpg"}
                      className="addsense-add-img"
                    />
                  </div>
                  <div className="mobile-similar-comment-sec">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="similar-videos"
                    >
                      <Row>
                        <Col sm={12}>
                          <Nav variant="pills">
                            <Nav.Item>
                              <Nav.Link eventKey="similar-videos">
                                <FontAwesomeIcon
                                  icon={["fas", "play-circle"]}
                                  className="mr-3 mobile-menu"
                                />{" "}
                                Similar Videos
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="comments-sec">
                                <FontAwesomeIcon
                                  icon={["fas", "comment-alt"]}
                                  className="mr-3 mobile-menu"
                                />{" "}
                                236 Comments
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Col>
                        <Col sm={12}>
                          <Tab.Content>
                            <Tab.Pane
                              eventKey="similar-videos"
                              className="up-next-right-sec"
                            >
                              <div className="up-next-right-video-sec">
                                <Link to="#">
                                  <div className="up-next-right-video-card">
                                    <Row>
                                      <Col xs={6} sm={6} md={6}>
                                        <div className="up-next-right-video-card-img">
                                          <Image
                                            src={
                                              window.location.origin +
                                              "/assets/img/video-1.jpg"
                                            }
                                            className="list-video-img"
                                          />
                                        </div>
                                        <div className="time-count">
                                          <h6>15:19</h6>
                                        </div>
                                      </Col>
                                      <Col xs={6} sm={6} md={6}>
                                        <div className="up-next-video-card-details">
                                          <h4 className="title">
                                            No Man's Sky: 21 Minutes of New
                                            Gameplay - IGN First
                                          </h4>
                                          <p className="desc">
                                            <span className="view-count">
                                              27,548 views
                                            </span>
                                          </p>
                                          <p className="desc">
                                            <span className="percentage-count green-clr">
                                              <FontAwesomeIcon
                                                icon="circle"
                                                className="mr-2"
                                              />
                                              93%
                                            </span>
                                          </p>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </Link>
                                <Link to="#">
                                  <div className="up-next-right-video-card">
                                    <Row>
                                      <Col xs={6} sm={6} md={6}>
                                        <div className="up-next-right-video-card-img">
                                          <Image
                                            src={
                                              window.location.origin +
                                              "/assets/img/video-1.jpg"
                                            }
                                            className="list-video-img"
                                          />
                                        </div>
                                        <div className="time-count">
                                          <h6>15:19</h6>
                                        </div>
                                      </Col>
                                      <Col xs={6} sm={6} md={6}>
                                        <div className="up-next-video-card-details">
                                          <h4 className="title">
                                            No Man's Sky: 21 Minutes of New
                                            Gameplay - IGN First
                                          </h4>
                                          <p className="desc">
                                            <span className="view-count">
                                              27,548 views
                                            </span>
                                          </p>
                                          <p className="desc">
                                            <span className="percentage-count green-clr">
                                              <FontAwesomeIcon
                                                icon="circle"
                                                className="mr-2"
                                              />
                                              93%
                                            </span>
                                          </p>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </Link>
                                <Link to="#">
                                  <div className="up-next-right-video-card">
                                    <Row>
                                      <Col xs={6} sm={6} md={6}>
                                        <div className="up-next-right-video-card-img">
                                          <Image
                                            src={
                                              window.location.origin +
                                              "/assets/img/video-1.jpg"
                                            }
                                            className="list-video-img"
                                          />
                                        </div>
                                        <div className="time-count">
                                          <h6>15:19</h6>
                                        </div>
                                      </Col>
                                      <Col xs={6} sm={6} md={6}>
                                        <div className="up-next-video-card-details">
                                          <h4 className="title">
                                            No Man's Sky: 21 Minutes of New
                                            Gameplay - IGN First
                                          </h4>
                                          <p className="desc">
                                            <span className="view-count">
                                              27,548 views
                                            </span>
                                          </p>
                                          <p className="desc">
                                            <span className="percentage-count green-clr">
                                              <FontAwesomeIcon
                                                icon="circle"
                                                className="mr-2"
                                              />
                                              93%
                                            </span>
                                          </p>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </Link>
                                <Link to="#">
                                  <div className="up-next-right-video-card">
                                    <Row>
                                      <Col xs={6} sm={6} md={6}>
                                        <div className="up-next-right-video-card-img">
                                          <Image
                                            src={
                                              window.location.origin +
                                              "/assets/img/video-1.jpg"
                                            }
                                            className="list-video-img"
                                          />
                                        </div>
                                        <div className="time-count">
                                          <h6>15:19</h6>
                                        </div>
                                      </Col>
                                      <Col xs={6} sm={6} md={6}>
                                        <div className="up-next-video-card-details">
                                          <h4 className="title">
                                            No Man's Sky: 21 Minutes of New
                                            Gameplay - IGN First
                                          </h4>
                                          <p className="desc">
                                            <span className="view-count">
                                              27,548 views
                                            </span>
                                          </p>
                                          <p className="desc">
                                            <span className="percentage-count green-clr">
                                              <FontAwesomeIcon
                                                icon="circle"
                                                className="mr-2"
                                              />
                                              93%
                                            </span>
                                          </p>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </Link>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="comments-sec">
                              <div className="single-video-about-comments-sec resp-mobile-comments-sec">
                                <div className="single-video-comments-body">
                                  <Row>
                                    <Col xs={3} md={2}>
                                      <div className="video-user-details">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/profile-img.jpg"
                                          }
                                          className="user-img"
                                        />
                                      </div>
                                    </Col>
                                    <Col xs={9} md={10}>
                                      <Form className="comments-form">
                                        <Form.Group controlId="exampleForm.ControlTextarea1">
                                          <Form.Control
                                            as="textarea"
                                            rows="1"
                                          />
                                        </Form.Group>
                                      </Form>
                                    </Col>
                                  </Row>
                                </div>
                                <div className="single-video-comments-sec-tabs resp-mobile-comment-btn">
                                  <Tab.Container
                                    id="left-tabs-example"
                                    defaultActiveKey="popular-comments"
                                  >
                                    <Row>
                                      <Col sm={12} md={12}>
                                        <Nav variant="pills">
                                          <Nav.Item>
                                            <Nav.Link
                                              eventKey="popular-comments"
                                              className="padding-left-zero"
                                            >
                                              Popular
                                            </Nav.Link>
                                          </Nav.Item>
                                          <Nav.Item>
                                            <Nav.Link eventKey="newest-comments">
                                              Newest
                                            </Nav.Link>
                                          </Nav.Item>
                                        </Nav>
                                      </Col>
                                      <Col sm={12} md={12}>
                                        <Tab.Content>
                                          <Tab.Pane eventKey="popular-comments">
                                            <div className="comment-tabs-sec">
                                              <div className="comments-info">
                                                <Row>
                                                  <Col xs={3} sm={3} md={2}>
                                                    <div className="comments-user-details">
                                                      <Image
                                                        src={
                                                          window.location
                                                            .origin +
                                                          "/assets/img/profile-img.jpg"
                                                        }
                                                        className="user-img"
                                                      />
                                                      <div className="resp-align-center">
                                                        <Link to="#">
                                                          <Image
                                                            src="assets/img/flag.png"
                                                            className="menu-icons"
                                                          />
                                                        </Link>
                                                      </div>
                                                    </div>
                                                  </Col>
                                                  <Col xs={9} sm={9} md={10}>
                                                    <p className="user-details">
                                                      <span className="user-name">
                                                        NaughtyDog .
                                                      </span>
                                                      <span className="post-date">
                                                        1 Weeks ago
                                                      </span>
                                                    </p>
                                                    <p className="user-description">
                                                      Really great story. You're
                                                      doing a great job. Keep it
                                                      up pal.
                                                    </p>
                                                    <p className="user-details-count">
                                                      <span className="view-count mr-2 green-clr">
                                                        <FontAwesomeIcon
                                                          icon="circle"
                                                          className="mr-1"
                                                        />
                                                        39,852
                                                      </span>
                                                      <span className="view-count-1 mr-2 green-grey">
                                                        <FontAwesomeIcon
                                                          icon="circle"
                                                          className="mr-1"
                                                        />
                                                        852
                                                      </span>
                                                      .
                                                      <span
                                                        className="reply-link"
                                                        onClick={
                                                          this.handleToggleVisib
                                                        }
                                                      >
                                                        Reply
                                                        {this.state.visib
                                                          ? ""
                                                          : ""}
                                                      </span>
                                                    </p>
                                                  </Col>
                                                </Row>
                                              </div>
                                              <div
                                                className="comments-info reply-show"
                                                id="dialog_1"
                                              >
                                                {this.state.visib && (
                                                  <Row>
                                                    <Col
                                                      xs={2}
                                                      sm={2}
                                                      md={2}
                                                      className="resp-column-none"
                                                    ></Col>
                                                    <Col
                                                      xs={10}
                                                      sm={10}
                                                      md={10}
                                                    >
                                                      <Row>
                                                        <Col
                                                          xs={3}
                                                          sm={3}
                                                          md={3}
                                                        >
                                                          <div className="comments-user-details">
                                                            <Image
                                                              src={
                                                                window.location
                                                                  .origin +
                                                                "/assets/img/profile-img.jpg"
                                                              }
                                                              className="user-img"
                                                            />
                                                          </div>
                                                        </Col>
                                                        <Col
                                                          xs={9}
                                                          sm={9}
                                                          md={9}
                                                        >
                                                          <p className="user-details">
                                                            <span className="user-name">
                                                              NaughtyDog .
                                                            </span>
                                                            <span className="post-date">
                                                              1 Weeks ago
                                                            </span>
                                                          </p>
                                                          <p className="user-description">
                                                            Really great story.
                                                            You're doing a great
                                                            job. Keep it up pal.
                                                          </p>
                                                          <p className="user-details-count">
                                                            <span className="view-count mr-2 green-clr">
                                                              <FontAwesomeIcon
                                                                icon="circle"
                                                                className="mr-1"
                                                              />
                                                              39,852
                                                            </span>
                                                            <span className="view-count-1 mr-2 green-grey">
                                                              <FontAwesomeIcon
                                                                icon="circle"
                                                                className="mr-1"
                                                              />
                                                              852
                                                            </span>
                                                            .
                                                            <span className="reply-link">
                                                              Reply
                                                            </span>
                                                          </p>
                                                        </Col>
                                                      </Row>
                                                    </Col>
                                                  </Row>
                                                )}
                                              </div>
                                            </div>
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="newest-comments">
                                            <div className="comment-tabs-sec">
                                              <div className="comments-info">
                                                <Row>
                                                  <Col xs={3} sm={3} md={2}>
                                                    <div className="comments-user-details">
                                                      <Image
                                                        src={
                                                          window.location
                                                            .origin +
                                                          "/assets/img/profile-img.jpg"
                                                        }
                                                        className="user-img"
                                                      />
                                                      <div className="resp-align-center">
                                                        <Link to="#">
                                                          <Image
                                                            src="assets/img/flag.png"
                                                            className="menu-icons"
                                                          />
                                                        </Link>
                                                      </div>
                                                    </div>
                                                  </Col>
                                                  <Col xs={9} sm={9} md={10}>
                                                    <p className="user-details">
                                                      <span className="user-name">
                                                        NaughtyDog .
                                                      </span>
                                                      <span className="post-date">
                                                        1 Weeks ago
                                                      </span>
                                                    </p>
                                                    <p className="user-description">
                                                      Really great story. You're
                                                      doing a great job. Keep it
                                                      up pal.
                                                    </p>
                                                    <p className="user-details-count">
                                                      <span className="view-count mr-2 green-clr">
                                                        <FontAwesomeIcon
                                                          icon="circle"
                                                          className="mr-1"
                                                        />
                                                        39,852
                                                      </span>
                                                      <span className="view-count-1 mr-2 green-grey">
                                                        <FontAwesomeIcon
                                                          icon="circle"
                                                          className="mr-1"
                                                        />
                                                        852
                                                      </span>
                                                      .
                                                      <span
                                                        className="reply-link"
                                                        onClick={
                                                          this.handleToggleVisib
                                                        }
                                                      >
                                                        Reply
                                                        {this.state.visib
                                                          ? ""
                                                          : ""}
                                                      </span>
                                                    </p>
                                                  </Col>
                                                </Row>
                                              </div>
                                              <div
                                                className="comments-info reply-show"
                                                id="dialog_1"
                                              >
                                                {this.state.visib && (
                                                  <Row>
                                                    <Col
                                                      xs={2}
                                                      sm={2}
                                                      md={2}
                                                      className="resp-column-none"
                                                    ></Col>
                                                    <Col
                                                      xs={10}
                                                      sm={10}
                                                      md={10}
                                                    >
                                                      <Row>
                                                        <Col
                                                          xs={3}
                                                          sm={3}
                                                          md={3}
                                                        >
                                                          <div className="comments-user-details">
                                                            <Image
                                                              src={
                                                                window.location
                                                                  .origin +
                                                                "/assets/img/profile-img.jpg"
                                                              }
                                                              className="user-img"
                                                            />
                                                          </div>
                                                        </Col>
                                                        <Col
                                                          xs={9}
                                                          sm={9}
                                                          md={9}
                                                        >
                                                          <p className="user-details">
                                                            <span className="user-name">
                                                              NaughtyDog .
                                                            </span>
                                                            <span className="post-date">
                                                              1 Weeks ago
                                                            </span>
                                                          </p>
                                                          <p className="user-description">
                                                            Really great story.
                                                            You're doing a great
                                                            job. Keep it up pal.
                                                          </p>
                                                          <p className="user-details-count">
                                                            <span className="view-count mr-2 green-clr">
                                                              <FontAwesomeIcon
                                                                icon="circle"
                                                                className="mr-1"
                                                              />
                                                              39,852
                                                            </span>
                                                            <span className="view-count-1 mr-2 green-grey">
                                                              <FontAwesomeIcon
                                                                icon="circle"
                                                                className="mr-1"
                                                              />
                                                              852
                                                            </span>
                                                            .
                                                            <span className="reply-link">
                                                              Reply
                                                            </span>
                                                          </p>
                                                        </Col>
                                                      </Row>
                                                    </Col>
                                                  </Row>
                                                )}
                                              </div>
                                            </div>
                                          </Tab.Pane>
                                        </Tab.Content>
                                      </Col>
                                    </Row>
                                  </Tab.Container>
                                </div>
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </div>
                  <div className="resp-load-more-bottom d-flex d-sm-none">
                    <ul className="list-unstyled">
                      <Media as="li">
                        <Image
                          src="assets/img/mobile-arrow.png"
                          className="resp-arrow-bottom-2"
                        />
                      </Media>
                    </ul>
                  </div>
                  <div className="border-top-1"></div>
                  <div className="resp-mobile-up-next-right-add-sec d-flex d-sm-none">
                    <div>
                      <Image
                        src={window.location.origin + "/assets/img/video-1.jpg"}
                        className="add-sec-img"
                      />
                    </div>
                  </div>
                  <div className="similar-video-sec d-none d-sm-block">
                    <h6 className="head-title">Similar Videos</h6>
                    <Row>
                      <Col xs={12} md={3}>
                        <Link to="#">
                          <div className="similar-video-card">
                            <div className="similar-video-card-img">
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/img/video-1.jpg"
                                }
                                className="video-img"
                              />
                            </div>
                            <div className="similar-video-card-details">
                              <h4 className="title">
                                No Man's Sky: 21 Minutes of New Gameplay - IGN
                                First
                              </h4>
                              <p className="desc">
                                <span className="view-count">27,548 views</span>
                              </p>
                              <p className="desc">
                                <span className="percentage-count green-clr">
                                  <FontAwesomeIcon
                                    icon="circle"
                                    className="mr-2"
                                  />
                                  93%
                                </span>
                              </p>
                            </div>
                            <div className="time-count">
                              <h6>15:19</h6>
                            </div>
                          </div>
                        </Link>
                      </Col>
                      <Col xs={12} md={3}>
                        <Link to="#">
                          <div className="similar-video-card">
                            <div className="similar-video-card-img">
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/img/video-1.jpg"
                                }
                                className="video-img"
                              />
                            </div>
                            <div className="similar-video-card-details">
                              <h4 className="title">
                                No Man's Sky: 21 Minutes of New Gameplay - IGN
                                First
                              </h4>
                              <p className="desc">
                                <span className="view-count">27,548 views</span>
                              </p>
                              <p className="desc">
                                <span className="percentage-count green-clr">
                                  <FontAwesomeIcon
                                    icon="circle"
                                    className="mr-2"
                                  />
                                  93%
                                </span>
                              </p>
                            </div>
                            <div className="time-count">
                              <h6>15:19</h6>
                            </div>
                          </div>
                        </Link>
                      </Col>
                      <Col xs={12} md={3}>
                        <Link to="#">
                          <div className="similar-video-card">
                            <div className="similar-video-card-img">
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/img/video-1.jpg"
                                }
                                className="video-img"
                              />
                            </div>
                            <div className="similar-video-card-details">
                              <h4 className="title">
                                No Man's Sky: 21 Minutes of New Gameplay - IGN
                                First
                              </h4>
                              <p className="desc">
                                <span className="view-count">27,548 views</span>
                              </p>
                              <p className="desc">
                                <span className="percentage-count green-clr">
                                  <FontAwesomeIcon
                                    icon="circle"
                                    className="mr-2"
                                  />
                                  93%
                                </span>
                              </p>
                            </div>
                            <div className="time-count">
                              <h6>15:19</h6>
                            </div>
                          </div>
                        </Link>
                      </Col>
                      <Col xs={12} md={3}>
                        <Link to="#">
                          <div className="similar-video-card">
                            <div className="similar-video-card-img">
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/img/video-1.jpg"
                                }
                                className="video-img"
                              />
                            </div>
                            <div className="similar-video-card-details">
                              <h4 className="title">
                                No Man's Sky: 21 Minutes of New Gameplay - IGN
                                First
                              </h4>
                              <p className="desc">
                                <span className="view-count">27,548 views</span>
                              </p>
                              <p className="desc">
                                <span className="percentage-count green-clr">
                                  <FontAwesomeIcon
                                    icon="circle"
                                    className="mr-2"
                                  />
                                  93%
                                </span>
                              </p>
                            </div>
                            <div className="time-count">
                              <h6>15:19</h6>
                            </div>
                          </div>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                  <div className="single-video-about-comments-sec d-none d-sm-block">
                    <div className="single-video-comments-header">
                      <h4 className="head-title">
                        <Image
                          src="assets/img/comment.png"
                          className="comments-icons"
                        />
                        236
                        <span className="small-title">Comments</span>
                      </h4>
                    </div>
                    <div className="single-video-comments-body">
                      <Row>
                        <Col xs={12} md={2}>
                          <div className="video-user-details">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/img/profile-img.jpg"
                              }
                              className="user-img"
                            />
                          </div>
                        </Col>
                        <Col xs={12} md={10}>
                          <Form className="comments-form">
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                              <Form.Control as="textarea" rows="3" />
                            </Form.Group>
                          </Form>
                        </Col>
                      </Row>
                    </div>
                    <div className="single-video-comments-sec-tabs">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="popular-comments"
                      >
                        <Row>
                          <Col sm={12} md={12}>
                            <Nav variant="pills">
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="popular-comments"
                                  className="padding-left-zero"
                                >
                                  Popular Comments
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="newest-comments">
                                  Newest Comments
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </Col>
                          <Col sm={12} md={12}>
                            <Tab.Content>
                              <Tab.Pane eventKey="popular-comments">
                                <div className="comment-tabs-sec">
                                  <div className="comments-info">
                                    <Row>
                                      <Col sm={12} md={2}>
                                        <div className="comments-user-details">
                                          <Image
                                            src={
                                              window.location.origin +
                                              "/assets/img/profile-img.jpg"
                                            }
                                            className="user-img"
                                          />
                                        </div>
                                      </Col>
                                      <Col sm={12} md={9}>
                                        <p className="user-details">
                                          <span className="user-name">
                                            NaughtyDog .
                                          </span>
                                          <span className="post-date">
                                            1 Weeks ago
                                          </span>
                                        </p>
                                        <p className="user-description">
                                          Really great story. You're doing a
                                          great job. Keep it up pal.
                                        </p>
                                        <p className="user-details-count">
                                          <span className="view-count mr-2 green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-1"
                                            />
                                            39,852
                                          </span>
                                          <span className="view-count-1 mr-2 green-grey">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-1"
                                            />
                                            852
                                          </span>
                                          .
                                          <span className="reply-link">
                                            Reply
                                          </span>
                                        </p>
                                        <Button
                                          className="view-all-reply reply-btn-click"
                                          onClick={this.handleToggleVisib}
                                        >
                                          View all 5 replies
                                          <FontAwesomeIcon
                                            icon="chevron-down"
                                            className="ml-1"
                                          />
                                          {this.state.visib ? "" : ""}
                                        </Button>
                                      </Col>
                                      <Col sm={12} md={1}>
                                        <Link to="#">
                                          <Image
                                            src="assets/img/flag.png"
                                            className="menu-icons"
                                          />
                                        </Link>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div
                                    className="comments-info reply-show"
                                    id="dialog_1"
                                  >
                                    {this.state.visib && (
                                      <Row>
                                        <Col
                                          sm={12}
                                          md={2}
                                          className="resp-column-none"
                                        ></Col>
                                        <Col sm={12} md={10}>
                                          <Row>
                                            <Col sm={12} md={2}>
                                              <div className="comments-user-details">
                                                <Image
                                                  src={
                                                    window.location.origin +
                                                    "/assets/img/profile-img.jpg"
                                                  }
                                                  className="user-img"
                                                />
                                              </div>
                                            </Col>
                                            <Col sm={12} md={10}>
                                              <p className="user-details">
                                                <span className="user-name">
                                                  NaughtyDog .
                                                </span>
                                                <span className="post-date">
                                                  1 Weeks ago
                                                </span>
                                              </p>
                                              <p className="user-description">
                                                Really great story. You're doing
                                                a great job. Keep it up pal.
                                              </p>
                                              <p className="user-details-count">
                                                <span className="view-count mr-2 green-clr">
                                                  <FontAwesomeIcon
                                                    icon="circle"
                                                    className="mr-1"
                                                  />
                                                  39,852
                                                </span>
                                                <span className="view-count-1 mr-2 green-grey">
                                                  <FontAwesomeIcon
                                                    icon="circle"
                                                    className="mr-1"
                                                  />
                                                  852
                                                </span>
                                                .
                                                <span className="reply-link">
                                                  Reply
                                                </span>
                                              </p>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    )}
                                  </div>
                                  <Row>
                                    <Col sm={12} md={12}>
                                      <div className="load-more-btn">
                                        <Button className="btn-load-more">
                                          Load more Comments
                                        </Button>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="newest-comments">
                                <div className="comment-tabs-sec">
                                  <div className="comments-info">
                                    <Row>
                                      <Col sm={12} md={2}>
                                        <div className="comments-user-details">
                                          <Image
                                            src={
                                              window.location.origin +
                                              "/assets/img/profile-img.jpg"
                                            }
                                            className="user-img"
                                          />
                                        </div>
                                      </Col>
                                      <Col sm={12} md={9}>
                                        <p className="user-details">
                                          <span className="user-name">
                                            NaughtyDog .
                                          </span>
                                          <span className="post-date">
                                            1 Weeks ago
                                          </span>
                                        </p>
                                        <p className="user-description">
                                          Really great story. You're doing a
                                          great job. Keep it up pal.
                                        </p>
                                        <p className="user-details-count">
                                          <span className="view-count mr-2 green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-1"
                                            />
                                            39,852
                                          </span>
                                          <span className="view-count-1 mr-2 green-grey">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-1"
                                            />
                                            852
                                          </span>
                                          .
                                          <span className="reply-link">
                                            Reply
                                          </span>
                                        </p>
                                        <Button
                                          className="view-all-reply reply-btn-click"
                                          onClick={this.handleToggleVisib}
                                        >
                                          View all 5 replies
                                          <FontAwesomeIcon
                                            icon="chevron-down"
                                            className="ml-1"
                                          />
                                          {this.state.visib ? "" : ""}
                                        </Button>
                                      </Col>
                                      <Col sm={12} md={1}>
                                        <Link to="#">
                                          <Image
                                            src="assets/img/flag.png"
                                            className="menu-icons"
                                          />
                                        </Link>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div
                                    className="comments-info reply-show"
                                    id="dialog_1"
                                  >
                                    {this.state.visib && (
                                      <Row>
                                        <Col
                                          sm={12}
                                          md={2}
                                          className="resp-column-none"
                                        ></Col>
                                        <Col sm={12} md={10}>
                                          <Row>
                                            <Col sm={12} md={2}>
                                              <div className="comments-user-details">
                                                <Image
                                                  src={
                                                    window.location.origin +
                                                    "/assets/img/profile-img.jpg"
                                                  }
                                                  className="user-img"
                                                />
                                              </div>
                                            </Col>
                                            <Col sm={12} md={10}>
                                              <p className="user-details">
                                                <span className="user-name">
                                                  NaughtyDog .
                                                </span>
                                                <span className="post-date">
                                                  1 Weeks ago
                                                </span>
                                              </p>
                                              <p className="user-description">
                                                Really great story. You're doing
                                                a great job. Keep it up pal.
                                              </p>
                                              <p className="user-details-count">
                                                <span className="view-count mr-2 green-clr">
                                                  <FontAwesomeIcon
                                                    icon="circle"
                                                    className="mr-1"
                                                  />
                                                  39,852
                                                </span>
                                                <span className="view-count-1 mr-2 green-grey">
                                                  <FontAwesomeIcon
                                                    icon="circle"
                                                    className="mr-1"
                                                  />
                                                  852
                                                </span>
                                                .
                                                <span className="reply-link">
                                                  Reply
                                                </span>
                                              </p>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    )}
                                  </div>
                                  <Row>
                                    <Col sm={12} md={12}>
                                      <div className="load-more-btn">
                                        <Button className="btn-load-more">
                                          Load more Comments
                                        </Button>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={4} className="d-none d-sm-block">
                <div className="up-next-right-sec">
                  <div className="up-next-right-header">
                    <h4 className="side-title">Up Next</h4>
                    {/* <Form className="toggle-switch">
                      <span className="mr-2 switch-title">Auto Play</span>
                      <Form.Check type="switch" id="custom-switch" label="" />
                    </Form> */}
                  </div>
                  <div className="up-next-right-video-sec">
                    <Link to="#">
                      <div className="up-next-right-video-card">
                        <Row>
                          <Col sm={12} md={6}>
                            <div className="up-next-right-video-card-img">
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/img/video-1.jpg"
                                }
                                className="list-video-img"
                              />
                            </div>
                            <div className="time-count">
                              <h6>15:19</h6>
                            </div>
                          </Col>
                          <Col sm={12} md={6}>
                            <div className="up-next-video-card-details">
                              <h4 className="title">
                                No Man's Sky: 21 Minutes of New Gameplay - IGN
                                First
                              </h4>
                              <p className="desc">
                                <span className="view-count">27,548 views</span>
                              </p>
                              <p className="desc">
                                <span className="percentage-count green-clr">
                                  <FontAwesomeIcon
                                    icon="circle"
                                    className="mr-2"
                                  />
                                  93%
                                </span>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Link>
                    <Link to="#">
                      <div className="up-next-right-video-card">
                        <Row>
                          <Col sm={12} md={6}>
                            <div className="up-next-right-video-card-img">
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/img/video-1.jpg"
                                }
                                className="list-video-img"
                              />
                            </div>
                            <div className="time-count">
                              <h6>15:19</h6>
                            </div>
                          </Col>
                          <Col sm={12} md={6}>
                            <div className="up-next-video-card-details">
                              <h4 className="title">
                                No Man's Sky: 21 Minutes of New Gameplay - IGN
                                First
                              </h4>
                              <p className="desc">
                                <span className="view-count">27,548 views</span>
                              </p>
                              <p className="desc">
                                <span className="percentage-count green-clr">
                                  <FontAwesomeIcon
                                    icon="circle"
                                    className="mr-2"
                                  />
                                  93%
                                </span>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Link>
                    <Link to="#">
                      <div className="up-next-right-video-card">
                        <Row>
                          <Col sm={12} md={6}>
                            <div className="up-next-right-video-card-img">
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/img/video-1.jpg"
                                }
                                className="list-video-img"
                              />
                            </div>
                            <div className="time-count">
                              <h6>15:19</h6>
                            </div>
                          </Col>
                          <Col sm={12} md={6}>
                            <div className="up-next-video-card-details">
                              <h4 className="title">
                                No Man's Sky: 21 Minutes of New Gameplay - IGN
                                First
                              </h4>
                              <p className="desc">
                                <span className="view-count">27,548 views</span>
                              </p>
                              <p className="desc">
                                <span className="percentage-count green-clr">
                                  <FontAwesomeIcon
                                    icon="circle"
                                    className="mr-2"
                                  />
                                  93%
                                </span>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Link>
                    <Link to="#">
                      <div className="up-next-right-video-card">
                        <Row>
                          <Col sm={12} md={6}>
                            <div className="up-next-right-video-card-img">
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/img/video-1.jpg"
                                }
                                className="list-video-img"
                              />
                            </div>
                            <div className="time-count">
                              <h6>15:19</h6>
                            </div>
                          </Col>
                          <Col sm={12} md={6}>
                            <div className="up-next-video-card-details">
                              <h4 className="title">
                                No Man's Sky: 21 Minutes of New Gameplay - IGN
                                First
                              </h4>
                              <p className="desc">
                                <span className="view-count">27,548 views</span>
                              </p>
                              <p className="desc">
                                <span className="percentage-count green-clr">
                                  <FontAwesomeIcon
                                    icon="circle"
                                    className="mr-2"
                                  />
                                  93%
                                </span>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Link>
                  </div>
                  <div className="up-next-right-add-sec">
                    <div>
                      <Image
                        src={window.location.origin + "/assets/img/video-1.jpg"}
                        className="add-sec-img"
                      />
                    </div>
                  </div>
                </div>
                <div className="up-next-right-sec">
                  <div className="up-next-right-header">
                    <h4 className="side-title">Recomended Videos</h4>
                    {/* <Form className="toggle-switch">
                      <span className="mr-2 switch-title">Auto Play</span>
                      <Form.Check type="switch" id="custom-switch" label="" />
                    </Form> */}
                  </div>
                  <div className="up-next-right-video-sec border-bottom-2">
                    <Link to="#">
                      <div className="up-next-right-video-card">
                        <Row>
                          <Col sm={12} md={6}>
                            <div className="up-next-right-video-card-img">
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/img/video-1.jpg"
                                }
                                className="list-video-img"
                              />
                            </div>
                            <div className="time-count">
                              <h6>15:19</h6>
                            </div>
                          </Col>
                          <Col sm={12} md={6}>
                            <div className="up-next-video-card-details">
                              <h4 className="title">
                                No Man's Sky: 21 Minutes of New Gameplay - IGN
                                First
                              </h4>
                              <p className="desc">
                                <span className="view-count">27,548 views</span>
                              </p>
                              <p className="desc">
                                <span className="percentage-count green-clr">
                                  <FontAwesomeIcon
                                    icon="circle"
                                    className="mr-2"
                                  />
                                  93%
                                </span>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Link>
                    <Link to="#">
                      <div className="up-next-right-video-card">
                        <Row>
                          <Col sm={12} md={6}>
                            <div className="up-next-right-video-card-img">
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/img/video-1.jpg"
                                }
                                className="list-video-img"
                              />
                            </div>
                            <div className="time-count">
                              <h6>15:19</h6>
                            </div>
                          </Col>
                          <Col sm={12} md={6}>
                            <div className="up-next-video-card-details">
                              <h4 className="title">
                                No Man's Sky: 21 Minutes of New Gameplay - IGN
                                First
                              </h4>
                              <p className="desc">
                                <span className="view-count">27,548 views</span>
                              </p>
                              <p className="desc">
                                <span className="percentage-count green-clr">
                                  <FontAwesomeIcon
                                    icon="circle"
                                    className="mr-2"
                                  />
                                  93%
                                </span>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Link>
                    <Link to="#">
                      <div className="up-next-right-video-card">
                        <Row>
                          <Col sm={12} md={6}>
                            <div className="up-next-right-video-card-img">
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/img/video-1.jpg"
                                }
                                className="list-video-img"
                              />
                            </div>
                            <div className="time-count">
                              <h6>15:19</h6>
                            </div>
                          </Col>
                          <Col sm={12} md={6}>
                            <div className="up-next-video-card-details">
                              <h4 className="title">
                                No Man's Sky: 21 Minutes of New Gameplay - IGN
                                First
                              </h4>
                              <p className="desc">
                                <span className="view-count">27,548 views</span>
                              </p>
                              <p className="desc">
                                <span className="percentage-count green-clr">
                                  <FontAwesomeIcon
                                    icon="circle"
                                    className="mr-2"
                                  />
                                  93%
                                </span>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Link>
                    <Link to="#">
                      <div className="up-next-right-video-card">
                        <Row>
                          <Col sm={12} md={6}>
                            <div className="up-next-right-video-card-img">
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/img/video-1.jpg"
                                }
                                className="list-video-img"
                              />
                            </div>
                            <div className="time-count">
                              <h6>15:19</h6>
                            </div>
                          </Col>
                          <Col sm={12} md={6}>
                            <div className="up-next-video-card-details">
                              <h4 className="title">
                                No Man's Sky: 21 Minutes of New Gameplay - IGN
                                First
                              </h4>
                              <p className="desc">
                                <span className="view-count">27,548 views</span>
                              </p>
                              <p className="desc">
                                <span className="percentage-count green-clr">
                                  <FontAwesomeIcon
                                    icon="circle"
                                    className="mr-2"
                                  />
                                  93%
                                </span>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Link>
                  </div>
                  <div className="show-more-sec">
                    <Link to="#" className="btn btn-show-more">
                      Show more videos
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default SingleVideoSimplified;
