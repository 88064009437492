import {
  FETCH_PPV_HISTORY_START,
  FETCH_PPV_HISTORY_SUCCESS,
  FETCH_PPV_HISTORY_FAILURE,
  PPV_PAY_STRIPE_START,
  PPV_PAY_STRIPE_SUCCESS,
  PPV_PAY_STRIPE_FAILURE,
  PPV_PAY_PAYPAL_START,
  PPV_PAY_PAYPAL_SUCCESS,
  PPV_PAY_PAYPAL_FAILURE,
} from "./ActionConstant";

// fetch PPV history action.

export function fetchPPVHistoryStart(data) {
  return {
    type: FETCH_PPV_HISTORY_START,
    data,
  };
}

export function fetchPPVHistorySuccess(data) {
  return {
    type: FETCH_PPV_HISTORY_SUCCESS,
    data,
  };
}

export function fetchPPVHistoryFailure(error) {
  return {
    type: FETCH_PPV_HISTORY_FAILURE,
    error,
  };
}

// ppv pay by stripe action.

export function PPVPayStripeStart(data) {
  return {
    type: PPV_PAY_STRIPE_START,
    data,
  };
}

export function PPVPayStripeSuccess(data) {
  return {
    type: PPV_PAY_STRIPE_SUCCESS,
    data,
  };
}

export function PPVPayStripeFailure(error) {
  return {
    type: PPV_PAY_STRIPE_FAILURE,
    error,
  };
}

// PPV pay paypal action.

export function PPVPayPayPalStart(data) {
  return {
    type: PPV_PAY_PAYPAL_START,
    data,
  };
}

export function PPVPayPayPalSuccess(data) {
  return {
    type: PPV_PAY_PAYPAL_SUCCESS,
    data,
  };
}

export function PPVPayPayPalFailure(error) {
  return {
    type: PPV_PAY_PAYPAL_FAILURE,
    error,
  };
}
