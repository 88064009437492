import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "./Trending.css";
import "./Responsive.css";
import {
  Image,
  Navbar,
  Nav,
  Form,
  FormControl,
  Button,
  NavDropdown,
  InputGroup,
  Dropdown,
  Media,
  Row,
  Col,
} from "react-bootstrap";
import configuration from "react-global-configuration";

class Trending extends Component {

  constructor(props) {
    super(props);
  }
  state = {
    mobileSidebar: true,
  };

  toggleMobileSidebar = () => {
    this.setState({
      mobileSidebar: !this.state.mobileSidebar,
    });
  };


  render() {
    return (
      <div className="video-app">
        <header>
          <Navbar
            collapseOnSelect
            expand="lg"
            className="navbar-expand-lg default-nav"
          >
            <div className="nav-icon" onClick={() => this.toggleMobileSidebar()}>
              <FontAwesomeIcon
                icon={["fas", "align-left"]}
                className="mr-3"
                id="sidebarCollapse"
              />
            </div>
            <Navbar.Brand href="#home">
              <Image
                src={configuration.get("configData.site_icon")}
                className="base-logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="resp-padding-sm"
            >
              <ul className="navbar-nav mx-auto resp-padding-xs">
                <InputGroup className="width-half-search">
                  <FormControl placeholder="Search" className="search-info" />
                  <InputGroup.Append>
                    <InputGroup.Text
                      id="basic-addon2"
                      className="search-info-1"
                    >
                      <FontAwesomeIcon
                        icon={["fas", "search"]}
                        className="mr-3"
                      />
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </ul>
              <Dropdown className="resp-padding-xs video-create">
                <Dropdown.Toggle className="button" id="dropdown-basic">
                  <FontAwesomeIcon icon={["fas", "video"]} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">
                    <FontAwesomeIcon
                      icon={["fas", "upload"]}
                      className="icon"
                    />
                    Upload Video
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    <i className="icofont-youtube-play"></i>Go Live
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="resp-padding-xs video-create">
                <Dropdown.Toggle className="button" id="dropdown-basic">
                  <FontAwesomeIcon icon={["fas", "th"]} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="border-bottom-1">
                    <Dropdown.Item href="#/action-1">
                      <i className="icofont-youtube-play"></i>
                      StreamTube TV
                    </Dropdown.Item>
                  </div>
                  <div className="border-bottom-1">
                    <Dropdown.Item href="#/action-2">
                      <i className="icofont-youtube-play"></i>StreamTube Music
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      <i className="icofont-youtube-play"></i>StreamTube Kids
                    </Dropdown.Item>
                  </div>
                  <Dropdown.Item href="#/action-4">
                    <i className="icofont-youtube-play"></i>Creator Academy
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-5">
                    <i className="icofont-youtube-play"></i>StreamTube for
                    Artists
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="video-settings resp-padding-xs">
                <Dropdown.Toggle className="button" id="dropdown-basic">
                  <FontAwesomeIcon icon={["fas", "bell"]} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">
                    <FontAwesomeIcon
                      icon={["fas", "upload"]}
                      className="icon"
                    />
                    Upload Video
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    <i className="icofont-youtube-play"></i>Go Live
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="video-settings resp-padding-xs">
                <Dropdown.Toggle className="button" id="dropdown-basic">
                  <Image
                    src={
                      window.location.origin +
                      "/assets/img/base-product/profile-img.jpg"
                    }
                    className="nav-user-img"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="navbar-profile-content border-bottom-1">
                    <div className="user-profile-img">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/base-product/profile-img.jpg"
                        }
                        className="profile-img"
                      />
                    </div>
                    <div className="profile-info">
                      <h4 className="title">Beno P</h4>
                      <h5 className="desc">beno@codegama.com</h5>
                      <a href="#" className="link">
                        Manage your account
                      </a>
                    </div>
                  </div>
                  <div className="dorpdown-scroll">
                    <Dropdown.Item href="#/action-1">
                      <FontAwesomeIcon icon={["fas", "cog"]} className="icon" />
                      Your Channel
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      <FontAwesomeIcon icon={["fas", "cog"]} className="icon" />
                      Your Channel
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      <FontAwesomeIcon icon={["fas", "cog"]} className="icon" />
                      Your Channel
                    </Dropdown.Item>
                    <div className="flex-content">
                      <Dropdown.Item href="#/action-4">
                        Switch Account
                        <span className="align-right">
                          <FontAwesomeIcon
                            icon={["fas", "chevron-right"]}
                            className="icon"
                          />
                        </span>
                      </Dropdown.Item>
                    </div>
                    <div className="border-bottom-1">
                      <Dropdown.Item href="#/action-5">
                        <FontAwesomeIcon
                          icon={["fas", "cog"]}
                          className="icon"
                        />
                        Your Channel
                      </Dropdown.Item>
                    </div>
                    <div className="flex-content">
                      <Dropdown.Item href="#/action-6">
                        <FontAwesomeIcon
                          icon={["fas", "sun"]}
                          className="icon"
                        />
                        Dark Theme: Off
                        <span className="align-right">
                          <FontAwesomeIcon
                            icon={["fas", "chevron-right"]}
                            className="icon"
                          />
                        </span>
                      </Dropdown.Item>
                    </div>
                    <div className="flex-content">
                      <Dropdown.Item href="#/action-7">
                        <FontAwesomeIcon
                          icon={["fas", "language"]}
                          className="icon"
                        />
                        Language: English
                        <span className="align-right">
                          <FontAwesomeIcon
                            icon={["fas", "chevron-right"]}
                            className="icon"
                          />
                        </span>
                      </Dropdown.Item>
                    </div>
                    <div className="flex-content">
                      <Dropdown.Item href="#/action-8">
                        <FontAwesomeIcon
                          icon={["fas", "globe"]}
                          className="icon"
                        />
                        Location: India
                        <span className="align-right">
                          <FontAwesomeIcon
                            icon={["fas", "chevron-right"]}
                            className="icon"
                          />
                        </span>
                      </Dropdown.Item>
                    </div>
                    <div className="flex-content">
                      <Dropdown.Item href="#/action-9">
                        <FontAwesomeIcon
                          icon={["fas", "cog"]}
                          className="icon"
                        />
                        Settings
                      </Dropdown.Item>
                    </div>
                    <div className="flex-content">
                      <Dropdown.Item href="#/action-10">
                        <FontAwesomeIcon
                          icon={["fas", "user-shield"]}
                          className="icon"
                        />
                        Your data in StreamTube
                      </Dropdown.Item>
                    </div>
                    <div className="flex-content">
                      <Dropdown.Item href="#/action-11">
                        <FontAwesomeIcon
                          icon={["fas", "question-circle"]}
                          className="icon"
                        />
                        Help
                      </Dropdown.Item>
                    </div>
                    <div className="flex-content">
                      <Dropdown.Item href="#/action-12">
                        <i className="icofont-ui-text-loading"></i>Send feedback
                      </Dropdown.Item>
                    </div>
                    <div className="border-bottom-1">
                      <Dropdown.Item href="#/action-13">
                        <FontAwesomeIcon
                          icon={["fas", "keyboard"]}
                          className="icon"
                        />
                        keyboard shorcuts
                      </Dropdown.Item>
                    </div>
                    <div className="flex-content">
                      <Dropdown.Item href="#/action-14">
                        Restricted Mode: Off
                        <span className="align-right">
                          <FontAwesomeIcon
                            icon={["fas", "chevron-right"]}
                            className="icon"
                          />
                        </span>
                      </Dropdown.Item>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </Navbar.Collapse>
          </Navbar>
        </header>
        <div className="wrapper main-wrapper">
          <div className="left-side" id="sidebar" style={{
            display: this.state.mobileSidebar ? "block" : "none",
          }}>
            <div className="side-wrapper">
              <div className="side-menu">
                <ul className="list-unstyled side-menu-item">
                  <Media as="li" className="active">
                    <i className="icofont-home"></i>Home
                  </Media>
                  <Media as="li">
                    <i className="material-icons playlist-icon-1">whatshot</i>
                    Trending
                  </Media>
                  <Media as="li">
                    <i className="material-icons playlist-icon-1">
                      subscriptions
                    </i>
                    Subscriptions
                  </Media>
                  <Media as="li">
                    <i className="material-icons playlist-icon-1">
                      video_library
                    </i>
                    Library
                  </Media>
                  <Media as="li">
                    <i className="icofont-history"></i>History
                  </Media>
                  <Media as="li">
                    <i className="material-icons playlist-icon-1">slideshow</i>
                    Your videos
                  </Media>
                  <Media as="li">
                    <i className="material-icons playlist-icon-1">
                      watch_later
                    </i>
                    Watch later
                  </Media>
                  <Media as="li">
                    <i className="material-icons playlist-icon-1">thumb_up</i>
                    Liked videos
                  </Media>
                </ul>
                <hr></hr>
                <div className="best-video-sec">
                  <h3 className="best-title-1">SUBSCRIPTIONS</h3>
                  <ul className="list-unstyled side-menu-item">
                    <Media as="li">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/base-product/channel-img-1.jpg"
                        }
                        className="base-logo"
                      />
                      Cheetah Media
                    </Media>
                    <Media as="li">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/base-product/valis-kitchen.jpg"
                        }
                        className="base-logo"
                      />
                      Valli's Kitchen
                    </Media>
                    <Media as="li">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/base-product/channel-img-2.jpg"
                        }
                        className="base-logo"
                      />
                      Gaming
                    </Media>
                    <Media as="li">
                      <i className="icofont-plus-circle"></i>Browse Channels
                    </Media>
                  </ul>
                </div>
                <div className="best-video-sec">
                  <h3 className="best-title-1">More From Streamtube</h3>
                  <ul className="list-unstyled side-menu-item">
                    <Media as="li">
                      <i className="icofont-youtube-play"></i>Streamtube Premium
                    </Media>
                    <Media as="li">
                      <i className="material-icons playlist-icon-1">
                        surround_sound
                      </i>
                      Live
                    </Media>
                    <hr></hr>
                    <Media as="li">
                      <i className="icofont-options"></i>Settings
                    </Media>
                    <Media as="li">
                      <i className="icofont-flag"></i>Report History
                    </Media>
                    <Media as="li">
                      <i className="icofont-question-circle"></i>Help
                    </Media>
                    <Media as="li">
                      <i className="icofont-ui-text-loading"></i>Send feedback
                    </Media>
                  </ul>
                </div>
                <hr></hr>
                <div className="sidebar-footer">
                  <Link to="#">About</Link>
                  <Link to="#">Press</Link>
                  <Link to="#">Copyright</Link>
                  <Link to="#">Contact us</Link>
                  <Link to="#">Creators</Link>
                  <Link to="#">Advertise</Link>
                  <Link to="#">Developers</Link>
                </div>
                <div className="sidebar-subfooter">
                  <Link to="#">Terms</Link>
                  <Link to="#">Privacy</Link>
                  <Link to="#">Policy & Safety</Link>
                  <Link to="#">How YouTube works</Link>
                  <Link to="#">Test new features</Link>
                </div>
                <div className="copyright">
                  <p>© 2020 Codegama LLP</p>
                </div>
              </div>
            </div>
          </div>
          <div className="main-container">
            <div className="trending-video-sec">
              <Row>
                <Col sm={12} md={12} lg={10}>
                  <div className="trending-header">
                    <div className="trending-content">
                      <div className="trending-img">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/channels-1.jpg"
                          }
                          className="trend-img"
                        />
                        <h3>Music</h3>
                      </div>
                    </div>
                    <div className="trending-content">
                      <div className="trending-img">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/channels-2.jpg"
                          }
                          className="trend-img"
                        />
                        <h3>Sports</h3>
                      </div>
                    </div>
                    <div className="trending-content">
                      <div className="trending-img">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/channels-3.jpg"
                          }
                          className="trend-img"
                        />
                        <h3>Gaming</h3>
                      </div>
                    </div>
                    <div className="trending-content">
                      <div className="trending-img">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/channels-4.jpg"
                          }
                          className="trend-img"
                        />
                        <h3>News</h3>
                      </div>
                    </div>
                    <div className="trending-content">
                      <div className="trending-img">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/channels-5.jpg"
                          }
                          className="trend-img"
                        />
                        <h3>Movies</h3>
                      </div>
                    </div>
                    <div className="trending-content">
                      <div className="trending-img">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/channels-7.jpg"
                          }
                          className="trend-img"
                        />
                        <h3>Fashion & Beauty</h3>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="border-bottom-2"></div>
              <Row>
                <Col sm={12} md={12} lg={10}>
                  <div className="trending-sec">
                    <div className="trending-box">
                      <div className="trending-card">
                        <Row>
                          <Col sm={12} md={4}>
                            <div className="hover-content">
                              <ul className="list-unstyled">
                                <Media as="li">
                                  <Link to="#">
                                    <i className="material-icons playlist-icon">
                                      watch_later
                                    </i>
                                  </Link>
                                </Media>
                                <Media as="li">
                                  <Link to="#">
                                    <i className="material-icons playlist-icon">
                                      playlist_play
                                    </i>
                                  </Link>
                                </Media>
                              </ul>
                            </div>
                            <div className="video-list-img">
                              <Link to="#">
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/img/base-product/video-1.jpg"
                                  }
                                  className="img-fluid video-img"
                                />
                              </Link>
                              <div className="hover-play">
                                <Link to="#">
                                  <i className="icofont-ui-play"></i>
                                </Link>
                              </div>
                              <div className="time-count">
                                <h6>16:30</h6>
                              </div>
                            </div>
                          </Col>
                          <Col sm={10} md={7} xs={10} className="padding-left-zero">
                            <div className="video-list-content">
                              <Link to="#">
                                <h3 className="title">
                                  French Biriyani - Official Trailer | Danish
                                  Sait | Amazon Prime Video | July 24
                                </h3>
                              </Link>
                              <Link to="#">
                                <p className="desc">
                                  <span>Amazon Prime Video India</span>
                                  <span class="view-count">2.7M views</span>
                                  <span class="upload-time">22 hours ago</span>
                                </p>
                              </Link>
                              <Link to="#">
                                <h4 className="sub-title">
                                  Presenting the Video Song 'The Bengaluru Song'
                                  From the Film 'French Biriyani' Premiering on
                                  Amazon Prime Video on 24th July. Listen to
                                  "The Bengaluru Song - French Biriyani" Single
                                  on your
                                </h4>
                              </Link>
                            </div>
                          </Col>
                          <Col sm={2} md={1} xs={2}>
                            <Dropdown className="trend-action-dropdown resp-padding-xs">
                              <Dropdown.Toggle
                                className="button"
                                id="dropdown-basic"
                              >
                                <FontAwesomeIcon icon={["fas", "ellipsis-v"]} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  <i className="material-icons icon">
                                    playlist_play
                                  </i>
                                  Add to queue
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  <i className="material-icons icon">
                                    watch_later
                                  </i>
                                  Save to Watch later
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3">
                                  <i className="material-icons icon">
                                    playlist_add
                                  </i>
                                  Save to playlist
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="trending-box">
                      <div className="trending-card">
                        <Row>
                          <Col sm={12} md={4}>
                            <div className="hover-content">
                              <ul className="list-unstyled">
                                <Media as="li">
                                  <Link to="#">
                                    <i className="material-icons playlist-icon">
                                      watch_later
                                    </i>
                                  </Link>
                                </Media>
                                <Media as="li">
                                  <Link to="#">
                                    <i className="material-icons playlist-icon">
                                      playlist_play
                                    </i>
                                  </Link>
                                </Media>
                              </ul>
                            </div>
                            <div className="video-list-img">
                              <Link to="#">
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/img/base-product/video-2.jpg"
                                  }
                                  className="img-fluid video-img"
                                />
                              </Link>
                              <div className="hover-play">
                                <Link to="#">
                                  <i className="icofont-ui-play"></i>
                                </Link>
                              </div>
                              <div className="time-count">
                                <h6>16:30</h6>
                              </div>
                            </div>
                          </Col>
                          <Col sm={10} md={7} xs={10} className="padding-left-zero">
                            <div className="video-list-content">
                              <Link to="#">
                                <h3 className="title">
                                  French Biriyani - Official Trailer | Danish
                                  Sait | Amazon Prime Video | July 24
                                </h3>
                              </Link>
                              <Link to="#">
                                <p className="desc">
                                  <span>Amazon Prime Video India</span>
                                  <span class="view-count">2.7M views</span>
                                  <span class="upload-time">22 hours ago</span>
                                </p>
                              </Link>
                              <Link to="#">
                                <h4 className="sub-title">
                                  Presenting the Video Song 'The Bengaluru Song'
                                  From the Film 'French Biriyani' Premiering on
                                  Amazon Prime Video on 24th July. Listen to
                                  "The Bengaluru Song - French Biriyani" Single
                                  on your
                                </h4>
                              </Link>
                            </div>
                          </Col>
                          <Col sm={2} md={1} xs={2}>
                            <Dropdown className="trend-action-dropdown resp-padding-xs">
                              <Dropdown.Toggle
                                className="button"
                                id="dropdown-basic"
                              >
                                <FontAwesomeIcon icon={["fas", "ellipsis-v"]} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  <i className="material-icons icon">
                                    playlist_play
                                  </i>
                                  Add to queue
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  <i className="material-icons icon">
                                    watch_later
                                  </i>
                                  Save to Watch later
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3">
                                  <i className="material-icons icon">
                                    playlist_add
                                  </i>
                                  Save to playlist
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="trending-box">
                      <div className="trending-card">
                        <Row>
                          <Col sm={12} md={4}>
                            <div className="hover-content">
                              <ul className="list-unstyled">
                                <Media as="li">
                                  <Link to="#">
                                    <i className="material-icons playlist-icon">
                                      watch_later
                                    </i>
                                  </Link>
                                </Media>
                                <Media as="li">
                                  <Link to="#">
                                    <i className="material-icons playlist-icon">
                                      playlist_play
                                    </i>
                                  </Link>
                                </Media>
                              </ul>
                            </div>
                            <div className="video-list-img">
                              <Link to="#">
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/img/base-product/video-3.jpg"
                                  }
                                  className="img-fluid video-img"
                                />
                              </Link>
                              <div className="hover-play">
                                <Link to="#">
                                  <i className="icofont-ui-play"></i>
                                </Link>
                              </div>
                              <div className="time-count">
                                <h6>16:30</h6>
                              </div>
                            </div>
                          </Col>
                          <Col sm={10} md={7} xs={10} className="padding-left-zero">
                            <div className="video-list-content">
                              <Link to="#">
                                <h3 className="title">
                                  French Biriyani - Official Trailer | Danish
                                  Sait | Amazon Prime Video | July 24
                                </h3>
                              </Link>
                              <Link to="#">
                                <p className="desc">
                                  <span>Amazon Prime Video India</span>
                                  <span class="view-count">2.7M views</span>
                                  <span class="upload-time">22 hours ago</span>
                                </p>
                              </Link>
                              <Link to="#">
                                <h4 className="sub-title">
                                  Presenting the Video Song 'The Bengaluru Song'
                                  From the Film 'French Biriyani' Premiering on
                                  Amazon Prime Video on 24th July. Listen to
                                  "The Bengaluru Song - French Biriyani" Single
                                  on your
                                </h4>
                              </Link>
                            </div>
                          </Col>
                          <Col sm={2} md={1} xs={2}>
                            <Dropdown className="trend-action-dropdown resp-padding-xs">
                              <Dropdown.Toggle
                                className="button"
                                id="dropdown-basic"
                              >
                                <FontAwesomeIcon icon={["fas", "ellipsis-v"]} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  <i className="material-icons icon">
                                    playlist_play
                                  </i>
                                  Add to queue
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  <i className="material-icons icon">
                                    watch_later
                                  </i>
                                  Save to Watch later
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3">
                                  <i className="material-icons icon">
                                    playlist_add
                                  </i>
                                  Save to playlist
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="trending-box">
                      <div className="trending-card">
                        <Row>
                          <Col sm={12} md={4}>
                            <div className="hover-content">
                              <ul className="list-unstyled">
                                <Media as="li">
                                  <Link to="#">
                                    <i className="material-icons playlist-icon">
                                      watch_later
                                    </i>
                                  </Link>
                                </Media>
                                <Media as="li">
                                  <Link to="#">
                                    <i className="material-icons playlist-icon">
                                      playlist_play
                                    </i>
                                  </Link>
                                </Media>
                              </ul>
                            </div>
                            <div className="video-list-img">
                              <Link to="#">
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/img/base-product/video-4.jpg"
                                  }
                                  className="img-fluid video-img"
                                />
                              </Link>
                              <div className="hover-play">
                                <Link to="#">
                                  <i className="icofont-ui-play"></i>
                                </Link>
                              </div>
                              <div className="time-count">
                                <h6>16:30</h6>
                              </div>
                            </div>
                          </Col>
                          <Col sm={10} md={7} xs={10} className="padding-left-zero">
                            <div className="video-list-content">
                              <Link to="#">
                                <h3 className="title">
                                  French Biriyani - Official Trailer | Danish
                                  Sait | Amazon Prime Video | July 24
                                </h3>
                              </Link>
                              <Link to="#">
                                <p className="desc">
                                  <span>Amazon Prime Video India</span>
                                  <span class="view-count">2.7M views</span>
                                  <span class="upload-time">22 hours ago</span>
                                </p>
                              </Link>
                              <Link to="#">
                                <h4 className="sub-title">
                                  Presenting the Video Song 'The Bengaluru Song'
                                  From the Film 'French Biriyani' Premiering on
                                  Amazon Prime Video on 24th July. Listen to
                                  "The Bengaluru Song - French Biriyani" Single
                                  on your
                                </h4>
                              </Link>
                            </div>
                          </Col>
                          <Col sm={2} md={1} xs={2}>
                            <Dropdown className="trend-action-dropdown resp-padding-xs">
                              <Dropdown.Toggle
                                className="button"
                                id="dropdown-basic"
                              >
                                <FontAwesomeIcon icon={["fas", "ellipsis-v"]} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  <i className="material-icons icon">
                                    playlist_play
                                  </i>
                                  Add to queue
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  <i className="material-icons icon">
                                    watch_later
                                  </i>
                                  Save to Watch later
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3">
                                  <i className="material-icons icon">
                                    playlist_add
                                  </i>
                                  Save to playlist
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Trending;
