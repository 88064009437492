import {
  FETCH_TAGS_START,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_FAILURE,
  FETCH_TAG_VIDEOS_START,
  FETCH_TAG_VIDEOS_SUCCESS,
  FETCH_TAG_VIDEOS_FAILURE,
  FETCH_SINGLE_TAG_START,
  FETCH_SINGLE_TAG_SUCCESS,
  FETCH_SINGLE_TAG_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  tags: {
    data: {},
    loading: true,
    error: false,
  },
  tagVideo: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  singleTag: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
};

const TagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TAGS_START:
      return {
        ...state,
        tags: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_TAGS_SUCCESS:
      return {
        ...state,
        tags: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_TAGS_FAILURE:
      return {
        ...state,
        tags: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case FETCH_TAG_VIDEOS_START:
      return {
        ...state,
        tagVideo: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_TAG_VIDEOS_SUCCESS:
      return {
        ...state,
        tagVideo: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_TAG_VIDEOS_FAILURE:
      return {
        ...state,
        tagVideo: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
      };

    case FETCH_SINGLE_TAG_START:
      return {
        ...state,
        singleTag: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_SINGLE_TAG_SUCCESS:
      return {
        ...state,
        singleTag: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_SINGLE_TAG_FAILURE:
      return {
        ...state,
        singleTag: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
      };

    default:
      return state;
  }
};

export default TagsReducer;
