import React from "react"
import ContentLoader from "react-content-loader"

const LiveTvLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1350}
    height={700}
    viewBox="0 0 1200 700"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="30" rx="5" ry="5" width="1200" height="58" /> 
    <rect x="0" y="513" rx="5" ry="5" width="1200" height="58" /> 
    <rect x="540" y="118" rx="0" ry="0" width="142" height="11" /> 
    <rect x="0" y="154" rx="5" ry="5" width="180" height="93" /> 
    <rect x="0" y="261" rx="0" ry="0" width="112" height="6" /> 
    <rect x="0" y="275" rx="0" ry="0" width="81" height="6" /> 
    <rect x="0" y="290" rx="0" ry="0" width="70" height="6" /> 
    <rect x="320" y="153" rx="5" ry="5" width="180" height="93" /> 
    <rect x="320" y="260" rx="0" ry="0" width="112" height="6" /> 
    <rect x="320" y="274" rx="0" ry="0" width="81" height="6" /> 
    <rect x="320" y="289" rx="0" ry="0" width="70" height="6" /> 
    <rect x="670" y="154" rx="5" ry="5" width="180" height="93" /> 
    <rect x="670" y="261" rx="0" ry="0" width="112" height="6" /> 
    <rect x="670" y="275" rx="0" ry="0" width="81" height="6" /> 
    <rect x="670" y="290" rx="0" ry="0" width="70" height="6" /> 
    <rect x="1020" y="154" rx="5" ry="5" width="180" height="93" /> 
    <rect x="1020" y="261" rx="0" ry="0" width="112" height="6" /> 
    <rect x="1020" y="275" rx="0" ry="0" width="81" height="6" /> 
    <rect x="1020" y="290" rx="0" ry="0" width="70" height="6" /> 
    <rect x="0" y="321" rx="5" ry="5" width="180" height="93" /> 
    <rect x="0" y="428" rx="0" ry="0" width="112" height="6" /> 
    <rect x="0" y="442" rx="0" ry="0" width="81" height="6" /> 
    <rect x="0" y="457" rx="0" ry="0" width="70" height="6" /> 
    <rect x="320" y="320" rx="5" ry="5" width="180" height="93" /> 
    <rect x="320" y="427" rx="0" ry="0" width="112" height="6" /> 
    <rect x="320" y="441" rx="0" ry="0" width="81" height="6" /> 
    <rect x="320" y="456" rx="0" ry="0" width="70" height="6" /> 
    <rect x="670" y="321" rx="5" ry="5" width="180" height="93" /> 
    <rect x="670" y="428" rx="0" ry="0" width="112" height="6" /> 
    <rect x="670" y="442" rx="0" ry="0" width="81" height="6" /> 
    <rect x="670" y="457" rx="0" ry="0" width="70" height="6" /> 
    <rect x="1020" y="321" rx="5" ry="5" width="180" height="93" /> 
    <rect x="1020" y="428" rx="0" ry="0" width="112" height="6" /> 
    <rect x="1020" y="442" rx="0" ry="0" width="81" height="6" /> 
    <rect x="1020" y="457" rx="0" ry="0" width="70" height="6" />
  </ContentLoader>
)

export default LiveTvLoader;