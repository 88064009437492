import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import "./SearchedVideos.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchSearchDataStart } from "../../store/actions/CoreDataAction";
import { connect } from "react-redux";
import VideoCard from "../helper/VideoCard";
import AddtoPlaylistModal from "../helper/AddtoPlaylistModal";
import NoResultFound from "../NoResultFound/NoResultFound";

const SearchedVideos = (props) => {
  const [addtoModal, setAddtoModal] = useState(false);
  const [createForm, setCreateForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingContent, setLoadingContent] = useState("Loading...");

  useEffect(() => {
    if (props.location.state !== undefined) {
      setLoading(false);
      props.dispatch(
        fetchSearchDataStart({ key: props.location.state.searchKey })
      );
    } else {
      setLoadingContent("No data found...");
    }
  }, []);

  const showAddToModal = () => {
    setAddtoModal(true);
  };

  const closeAddToModal = () => {
    setAddtoModal(false);
  };

  const handleToggleCreateForm = () => {
    setCreateForm(!createForm);
  };

  return (
    <>
      {loading ? (
        <div className="searched-videos-sec">
          <Container>
            <Row>
              <Col sm={12} md={12} className="resp-col-no-padding">
                <div className="searched-videos-header 	d-none d-sm-flex">
                  <h4 className="title-head-txt">{loadingContent}</h4>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <div className="searched-video-content-sec">
          <Container>
            <Row>
              <Col sm={12} md={12}>
                <div className="searched-video-content-header">
                  <h4 className="title-head-main">
                    Search Result
                    <span className="highlight-black">
                      "{props.location.state.searchKey}"
                    </span>
                  </h4>
                </div>
                <div className="section-1">
                  <Row>
                    {props.searchData.loading
                      ? "Loading..."
                      : props.searchData.data.length > 0
                      ? props.searchData.data.map((video) => (
                          <VideoCard
                            video={video}
                            showAddToModal={showAddToModal}
                            {...this.props}
                          />
                        ))
                      : <NoResultFound/>}
                  </Row>
                  <div className="space-lg"></div>
                  <div className="border-bottom-1 d-flex d-sm-none"></div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
      <AddtoPlaylistModal
        showAddToModal={showAddToModal}
        closeAddToModal={closeAddToModal}
        addtoModal={addtoModal}
        handleToggleCreateForm={handleToggleCreateForm}
        createForm={createForm}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  searchData: state.core.search,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SearchedVideos);
