import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Container, Row, Col, Media } from "react-bootstrap";
import "./SignUp.css";
import UseForm from "../helper/UseForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { userRegisterStart } from "../../store/actions/UserAction";
import configuration from "react-global-configuration";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../helper/NotificationMessage";

const SignUp = (props) => {
  const { values, handleChange, handleSubmit } = UseForm(signup);

  function signup() {
    console.log(values);
    props.dispatch(userRegisterStart(values));
  }

  const responseGoogle = (response) => {
    const path = props.location;
    const googleLoginInput = {
      social_unique_id: response.profileObj.googleId,
      login_by: "google",
      email: response.profileObj.email,
      name: response.profileObj.name,
      picture: response.profileObj.imageUrl,
      device_type: "web",
      device_token: "123466",
    };
    props.dispatch(userRegisterStart(googleLoginInput));
  };

  const responseFacebook = (response) => {
    const { path } = props.location;
    if (response) {
      if (response.status == "unknown") {
        const notificationMessage = getErrorNotificationMessage("Cancelled");
        props.dispatch(createNotification(notificationMessage));
        return false;
      }
      const emailAddress =
        response.email === undefined || response.email === null
          ? response.id + "@facebook.com"
          : response.email;

      const facebookLoginInput = {
        social_unique_id: response.id,
        login_by: "facebook",
        email: emailAddress,
        name: response.name,
        device_type: "web",
        device_token: "123466",
      };
      props.dispatch(userRegisterStart(facebookLoginInput));
    }
  };

  return (
    <>
      <div className="signup-sec">
        <Container>
          <Row>
            <Col sm={12} md={5} xl={6} className="d-none d-sm-block">
              <div className="signup-left-content">
                <h1 className="left-border"></h1>
                <h2 className="left-title">
                  Watch
                  <span className="highlighted-txt">
                    millions of videos{" "}
                  </span>{" "}
                  for free.
                </h2>
                <p className="desc">Over 6000 videos uploaded Daily</p>
              </div>
            </Col>
            <Col sm={12} md={7} xl={6}>
              <div className="signup-right-content">
                <div className="signup-box">
                  <div className="signup-header">
                    <h3 className="title">Sign Up for Free</h3>
                  </div>
                  <div className="signup-body">
                    <Form onSubmit={handleSubmit}>
                      <Form.Group controlId="formGroupEmail">
                        <Form.Label className="label-title">Name</Form.Label>
                        <Form.Control
                          type="name"
                          placeholder="Name"
                          name="name"
                          onChange={handleChange}
                          value={values.name}
                        />
                      </Form.Group>
                      <Form.Group controlId="formGroupEmail">
                        <Form.Label className="label-title">Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="sample@gmail.com"
                          name="email"
                          onChange={handleChange}
                          value={values.email}
                        />
                      </Form.Group>
                      <Form.Group controlId="formGroupPassword">
                        <Form.Label className="label-title">
                          Password
                        </Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="*******"
                          name="password"
                          onChange={handleChange}
                          value={values.password}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Link to="/signup" className="d-none d-sm-block">
                          <p className="desc">
                            By clicking "Sign Up" I agree to{" "}
                            {configuration.get("configData.site_name")} Terms of
                            Service.
                          </p>
                        </Link>
                      </Form.Group>

                      <Row>
                        <Col sm={12} md={6} xl={7}>
                          <Button
                            className="login-btn btn-block"
                            disabled={props.users.buttonDisable}
                            type="submit"
                          >
                            {props.users.loadingButtonContent != null
                              ? props.users.loadingButtonContent
                              : "Sign Up"}
                          </Button>
                        </Col>
                        <Col sm={12} md={6} xl={5} className="d-none d-sm-flex">
                          <div className="signup-flex-center">
                            <h4 className="or-txt">Or</h4>
                            <Link to="/signin" className="login-link">
                              Log In
                            </Link>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={12}>
                          <ul className="list-unstyled social-link">
                            {configuration.get(
                              "configData.social_logins.GOOGLE_CLIENT_ID"
                            ) ? (
                              <Media as="li">
                                <GoogleLogin
                                  clientId={configuration.get(
                                    "configData.social_logins.GOOGLE_CLIENT_ID"
                                  )}
                                  render={(renderProps) => (
                                    <Link
                                      to="#"
                                      className="color-google"
                                      onClick={renderProps.onClick}
                                      disabled={renderProps.disabled}
                                    >
                                      <FontAwesomeIcon
                                        icon={["fab", "google"]}
                                      />
                                    </Link>
                                  )}
                                  buttonText="Login"
                                  onSuccess={responseGoogle}
                                  onFailure={responseGoogle}
                                  cookiePolicy={"single_host_origin"}
                                />
                              </Media>
                            ) : (
                              ""
                            )}

                            {configuration.get(
                              "configData.social_logins.FB_CLIENT_ID"
                            ) ? (
                              <Media as="li">
                                <FacebookLogin
                                  appId={configuration.get(
                                    "configData.social_logins.FB_CLIENT_ID"
                                  )}
                                  fields="name,email,picture"
                                  scope="public_profile"
                                  callback={responseFacebook}
                                  render={(renderProps) => (
                                    <Link
                                      to="#"
                                      className="color-facebook"
                                      onClick={renderProps.onClick}
                                      disabled={renderProps.disabled}
                                    >
                                      <FontAwesomeIcon
                                        icon={["fab", "facebook"]}
                                      />
                                    </Link>
                                  )}
                                />
                              </Media>
                            ) : (
                              ""
                            )}

                            {/* <Media as="li">
                              <Link to="#" className="color-twitter">
                                <FontAwesomeIcon icon={["fab", "twitter"]} />
                              </Link>
                            </Media> */}
                          </ul>
                        </Col>
                      </Row>

                      <div className="mobile-footer-sec-1 d-flex d-sm-none">
                        <Link to="/signin">
                          <p className="desc">
                            Already have an account?{" "}
                            <strong className="ml-2">Login here</strong>
                          </p>
                        </Link>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  users: state.users,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SignUp);
