import {
  FETCH_VIDEOS_VM_START,
  FETCH_VIDEOS_VM_SUCCESS,
  FETCH_VIDEOS_VM_FAILURE,
  FETCH_SINGLE_VIDEO_VM_START,
  FETCH_SINGLE_VIDEO_VM_SUCCESS,
  FETCH_SINGLE_VIDEO_VM_FAILURE,
  SAVE_VIDEO_VM_START,
  SAVE_VIDEO_VM_SUCCESS,
  SAVE_VIDEO_VM_FAILURE,
  APPROVE_DECLINE_VIDEO_VM_START,
  APPROVE_DECLINE_VIDEO_VM_SUCCESS,
  APPROVE_DECLINE_VIDEO_VM_FAILURE,
  SET_REMOVE_PPV_VIDEO_VM_START,
  SET_REMOVE_PPV_VIDEO_VM_SUCCESS,
  SET_REMOVE_PPV_VIDEO_VM_FAILURE,
  DELETE_VIDEO_VM_START,
  DELETE_VIDEO_VM_SUCCESS,
  DELETE_VIDEO_VM_FAILURE,
  SINGLE_VIDEO_REVENUE_START,
  SINGLE_VIDEO_REVENUE_SUCCESS,
  SINGLE_VIDEO_REVENUE_FAILURE,
  AD_ENABLE_DISABLE_VM_START,
  AD_ENABLE_DISABLE_VM_SUCCESS,
  AD_ENABLE_DISABLE_VM_FAILURE,
} from "./ActionConstant";

// fetch video vm action.

export function fetchVideosVMStart(data) {
  return {
    type: FETCH_VIDEOS_VM_START,
    data,
  };
}

export function fetchVideosVMSuccess(data) {
  return {
    type: FETCH_VIDEOS_VM_SUCCESS,
    data,
  };
}

export function fetchVideosVMFailure(error) {
  return {
    type: FETCH_VIDEOS_VM_FAILURE,
    error,
  };
}

// fetch single video details  action.

export function fetchSingleVideoVMStart(data) {
  return {
    type: FETCH_SINGLE_VIDEO_VM_START,
    data,
  };
}

export function fetchSingleVideoVMSuccess(data) {
  return {
    type: FETCH_SINGLE_VIDEO_VM_SUCCESS,
    data,
  };
}

export function fetchSingleVideoVMFailure(error) {
  return {
    type: FETCH_SINGLE_VIDEO_VM_FAILURE,
    error,
  };
}

// save video action.

export function saveVideoVMStart(data) {
  return {
    type: SAVE_VIDEO_VM_START,
    data,
  };
}

export function saveVideoVMSuccess(data) {
  return {
    type: SAVE_VIDEO_VM_SUCCESS,
    data,
  };
}

export function saveVideoVMFailure(error) {
  return {
    type: SAVE_VIDEO_VM_FAILURE,
    error,
  };
}

// approve decline video action.

export function approveDeclineVideoVMStart(data) {
  return {
    type: APPROVE_DECLINE_VIDEO_VM_START,
    data,
  };
}

export function approveDeclineVideoVMSuccess(data) {
  return {
    type: APPROVE_DECLINE_VIDEO_VM_SUCCESS,
    data,
  };
}

export function approveDeclineVideoVMFailure(error) {
  return {
    type: APPROVE_DECLINE_VIDEO_VM_FAILURE,
    error,
  };
}

// set remove PPV video  action.

export function setRemovePPVVideoVMStart(data) {
  return {
    type: SET_REMOVE_PPV_VIDEO_VM_START,
    data,
  };
}

export function setRemovePPVVideoVMSuccess(data) {
  return {
    type: SET_REMOVE_PPV_VIDEO_VM_SUCCESS,
    data,
  };
}

export function setRemovePPVVideoVMFailure(error) {
  return {
    type: SET_REMOVE_PPV_VIDEO_VM_FAILURE,
    error,
  };
}

// delete video action.

export function deleteVideoVMStart(data) {
  return {
    type: DELETE_VIDEO_VM_START,
    data,
  };
}

export function deleteVideoVMSuccess(data) {
  return {
    type: DELETE_VIDEO_VM_SUCCESS,
    data,
  };
}

export function deleteVideoVMFailure(error) {
  return {
    type: DELETE_VIDEO_VM_FAILURE,
    error,
  };
}

// single video revenue action.

export function singleVideoRevenueStart(data) {
  return {
    type: SINGLE_VIDEO_REVENUE_START,
    data,
  };
}

export function singleVideoRevenueSuccess(data) {
  return {
    type: SINGLE_VIDEO_REVENUE_SUCCESS,
    data,
  };
}

export function singleVideoRevenueFailure(error) {
  return {
    type: SINGLE_VIDEO_REVENUE_FAILURE,
    error,
  };
}

// ad set or remove action.

export function adEnableDisableVideoVMStart(data) {
  return {
    type: AD_ENABLE_DISABLE_VM_START,
    data,
  };
}

export function adEnableDisableVideoVMSuccess(data) {
  return {
    type: AD_ENABLE_DISABLE_VM_SUCCESS,
    data,
  };
}

export function adEnableDisableVideoVMFailure(error) {
  return {
    type: AD_ENABLE_DISABLE_VM_FAILURE,
    error,
  };
}
