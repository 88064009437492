import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Tabs,
  Tab,
  Dropdown,
  DropdownButton,
  Image,
  ListGroup,
  Media,
} from "react-bootstrap";
import "./BrowseCategoriesSideMenu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class BrowseCategoriesSideMenu extends Component {
  render() {
    return (
      <>
        <div className="browse-categories-side-menu-sec">
          <Container>
            <Row>
              <Col sm={12} md={12}>
                <div className="browse-channels-header">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="browse-all-categories"
                  >
                    <Row>
                      <Col sm={12} md={12}>
                        <Nav variant="pills">
                          <Nav.Item>
                            <Nav.Link eventKey="browse-all-categories">
                              All Categories
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="browse-most-popular">
                              Most Popular
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="browse-trending">
                              Trending
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="browse-most-recent">
                              Most Recent
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="browse-atoz">A - Z</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col sm={12} md={12}>
                        <Tab.Content>
                          <Tab.Pane eventKey="browse-all-categories">
                            <Row>
                              <Col sm={12} md={3} xl={2}>
                                <div className="categories-side-menu-sec">
                                  <ListGroup
                                    as="ul"
                                    className="categories-side-menu-list"
                                  >
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Fair</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Vest</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Wobblet
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Train</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Cake</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Difficult
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Ignore
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Slow</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Extra-large
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Team</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Unusual
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Grandmother
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Scissors
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Chilly
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Muddle
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Rake</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Letters
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Relax</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Tooth</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Simple
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Bathe</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Wave</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Word</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Measure
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Furtive
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Spotted
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Window
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Hose</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Dogs</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Feeling
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Hundrum
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Homeless
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Harbor
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Deliver
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Happy</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Imported
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Opposite
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Nail</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Squeak
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Quiver
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Dilgent
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Preach
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Waggish
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Verse</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Bury</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Shade</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Side</Link>
                                    </ListGroup.Item>
                                  </ListGroup>
                                </div>
                              </Col>
                              <Col sm={12} md={9} xl={10}>
                                <div className="categories-video-side-menu-info">
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-1.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-2.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-3.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-4.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-5.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                  <div className="space-lg"></div>
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-6.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-8.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-9.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-13.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                  <div className="space-lg"></div>
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-12.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-13.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-14.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-1.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                  <div className="space-lg"></div>
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-2.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-3.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                  <div className="space-lg"></div>
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                  <div className="space-lg"></div>
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-12.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-14.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-1.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-2.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          </Tab.Pane>
                          <Tab.Pane eventKey="browse-most-popular">
                            <Row>
                              <Col sm={12} md={3} xl={2}>
                                <div className="categories-side-menu-sec">
                                  <ListGroup
                                    as="ul"
                                    className="categories-side-menu-list"
                                  >
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Fair</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Vest</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Wobblet
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Train</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Cake</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Difficult
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Ignore
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Slow</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Extra-large
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Team</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Unusual
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Grandmother
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Scissors
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Chilly
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Muddle
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Rake</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Letters
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Relax</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Tooth</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Simple
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Bathe</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Wave</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Word</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Measure
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Furtive
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Spotted
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Window
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Hose</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Dogs</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Feeling
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Hundrum
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Homeless
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Harbor
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Deliver
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Happy</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Imported
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Opposite
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Nail</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Squeak
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Quiver
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Dilgent
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Preach
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Waggish
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Verse</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Bury</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Shade</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Side</Link>
                                    </ListGroup.Item>
                                  </ListGroup>
                                </div>
                              </Col>
                              <Col sm={12} md={9} xl={10}>
                                <div className="categories-video-side-menu-info">
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-1.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-2.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-3.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                  <div className="space-lg"></div>
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                  <div className="space-lg"></div>
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-12.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-14.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-1.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                  <div className="space-lg"></div>
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-2.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-3.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                  <div className="space-lg"></div>
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                  <div className="space-lg"></div>
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-12.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-14.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-1.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-2.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          </Tab.Pane>
                          <Tab.Pane eventKey="browse-trending">
                            <Row>
                              <Col sm={12} md={3} xl={2}>
                                <div className="categories-side-menu-sec">
                                  <ListGroup
                                    as="ul"
                                    className="categories-side-menu-list"
                                  >
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Fair</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Vest</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Wobblet
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Train</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Cake</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Difficult
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Ignore
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Slow</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Extra-large
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Team</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Unusual
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Grandmother
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Scissors
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Chilly
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Muddle
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Rake</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Letters
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Relax</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Tooth</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Simple
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Bathe</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Wave</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Word</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Measure
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Furtive
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Spotted
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Window
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Hose</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Dogs</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Feeling
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Hundrum
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Homeless
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Harbor
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Deliver
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Happy</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Imported
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Opposite
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Nail</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Squeak
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Quiver
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Dilgent
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Preach
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Waggish
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Verse</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Bury</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Shade</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Side</Link>
                                    </ListGroup.Item>
                                  </ListGroup>
                                </div>
                              </Col>
                              <Col sm={12} md={9} xl={10}>
                                <div className="categories-video-side-menu-info">
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-1.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-2.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-3.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                  <div className="space-lg"></div>
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                  <div className="space-lg"></div>
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-12.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-14.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-1.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                  <div className="space-lg"></div>
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-2.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-3.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                  <div className="space-lg"></div>
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                  <div className="space-lg"></div>
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-12.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-14.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-1.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-2.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          </Tab.Pane>
                          <Tab.Pane eventKey="browse-most-recent">
                            <Row>
                              <Col sm={12} md={3} xl={2}>
                                <div className="categories-side-menu-sec">
                                  <ListGroup
                                    as="ul"
                                    className="categories-side-menu-list"
                                  >
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Fair</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Vest</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Wobblet
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Train</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Cake</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Difficult
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Ignore
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Slow</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Extra-large
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Team</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Unusual
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Grandmother
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Scissors
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Chilly
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Muddle
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Rake</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Letters
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Relax</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Tooth</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Simple
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Bathe</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Wave</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Word</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Measure
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Furtive
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Spotted
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Window
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Hose</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Dogs</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Feeling
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Hundrum
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Homeless
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Harbor
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Deliver
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Happy</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Imported
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Opposite
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Nail</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Squeak
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Quiver
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Dilgent
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Preach
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Waggish
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Verse</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Bury</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Shade</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Side</Link>
                                    </ListGroup.Item>
                                  </ListGroup>
                                </div>
                              </Col>
                              <Col sm={12} md={9} xl={10}>
                                <div className="categories-video-side-menu-info">
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-1.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-2.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-3.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                  <div className="space-lg"></div>
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                  <div className="space-lg"></div>
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-12.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-14.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-1.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                  <div className="space-lg"></div>
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-2.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-3.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                  <div className="space-lg"></div>
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                  <div className="space-lg"></div>
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-12.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-14.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-1.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-2.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          </Tab.Pane>
                          <Tab.Pane eventKey="browse-atoz">
                            <Row>
                              <Col sm={12} md={3} xl={2}>
                                <div className="categories-side-menu-sec">
                                  <ListGroup
                                    as="ul"
                                    className="categories-side-menu-list"
                                  >
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Fair</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Vest</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Wobblet
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Train</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Cake</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Difficult
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Ignore
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Slow</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Extra-large
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Team</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Unusual
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Grandmother
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Scissors
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Chilly
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Muddle
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Rake</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Letters
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Relax</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Tooth</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Simple
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Bathe</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Wave</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Word</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Measure
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Furtive
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Spotted
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Window
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Hose</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Dogs</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Feeling
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Hundrum
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Homeless
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Harbor
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Deliver
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Happy</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Imported
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Opposite
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Nail</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Squeak
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Quiver
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Dilgent
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Preach
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">
                                        Waggish
                                      </Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Verse</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Bury</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Shade</Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                      <Link to="/browse-categories">Side</Link>
                                    </ListGroup.Item>
                                  </ListGroup>
                                </div>
                              </Col>
                              <Col sm={12} md={9} xl={10}>
                                <div className="categories-video-side-menu-info">
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-1.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-2.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-3.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                  <div className="space-lg"></div>
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                  <div className="space-lg"></div>
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-12.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-14.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-1.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                  <div className="space-lg"></div>
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-2.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-3.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                  <div className="space-lg"></div>
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                  <div className="space-lg"></div>
                                  <Row>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-12.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-7.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/img/video-14.jpg"
                                              }
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-1.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="custom-column">
                                      <Link to="/single-video-simplified">
                                        <div className="categories-video-card">
                                          <div className="cartegories-video-img-sec">
                                            <Image
                                              src="assets/img/video-2.jpg"
                                              className="categories-vertical-img"
                                            />
                                          </div>
                                          <div className="categories-video-details">
                                            <h4 className="title">Pumped</h4>
                                            <p className="desc">1235 Videos</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
              </Col>
            </Row>
            <div className="d-flex d-sm-none resp-arrow-bottom-sec">
              <ul className="list-unstyled">
                <Media as="li">
                  <Image
                    src={
                      window.location.origin + "/assets/img/mobile-arrow.png"
                    }
                    className="resp-arrow-bottom"
                  />
                </Media>
              </ul>
            </div>
            <div className="border-bottom-1 d-flex d-sm-none"></div>
          </Container>
        </div>
      </>
    );
  }
}

export default BrowseCategoriesSideMenu;
