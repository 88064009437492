import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./CardsList.css";
import AddCardSec from "./AddCardSec";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import {
  fetchCardDetailsStart,
  selectDefaultCardStart,
  deleteCardStart,
} from "../../../store/actions/CardsAction";

const stripePromise = loadStripe("pk_test_uDYrTXzzAuGRwDYtu7dkhaF3");

class CardsList extends Component {
  constructor() {
    super();
    this.state = { stripe: null, displayAddCardSection: false };
  }
  componentDidMount() {
    if (this.props.cards.cardDetails.loading)
      this.props.dispatch(fetchCardDetailsStart());
  }

  cardAddedStatusChange = () => {
    this.setState({ displayAddCardSection: false });
  };

  addCardSection = (event) => {
    event.preventDefault();
    this.setState({ displayAddCardSection: true });
  };

  changeDefaultCard = (event, card) => {
    event.preventDefault();
    this.props.dispatch(selectDefaultCardStart(card));
  };

  deleteCard = (event, card) => {
    event.preventDefault();
    this.props.dispatch(deleteCardStart(card));
  };
  render() {
    const cardsList = this.props.cards.cardDetails;
    return (
      <>
        <div className="cards-list-sec">
          <Container>
            <Row>
              <Col sm={12} md={12}>
                <h2 className="section-title">Cards Management</h2>
              </Col>
            </Row>
            <div className="cards-list-box">
              <Row>
                <Col sm={12} md={12}>
                  <div className="cards-list">
                    <Row>
                      <Col sm={12} md={6}>
                        <div className="add-cards-form">
                          <Elements stripe={stripePromise}>
                            <AddCardSec />
                          </Elements>
                        </div>
                      </Col>
                      <Col sm={12} md={6} className="padding-left-zero-card">
                        {cardsList.loading ? (
                          "Loading..."
                        ) : (
                          <>
                            {cardsList.data.cards.length > 0 ? (
                              cardsList.data.cards.map((data) => (
                                <div className="cards-list-card">
                                  <div className="cards-details">
                                    <h3 className="title">
                                      XXXX XXXX XXXX {data.last_four}
                                    </h3>
                                  </div>

                                  <div className="cards-details-footer">
                                    <div>
                                      <p>{data.card_name}</p>

                                      {data.is_default == 1 ? (
                                        <p className="desc">Default Card</p>
                                      ) : (
                                        <Link
                                          to="#"
                                          onClick={(event) =>
                                            this.changeDefaultCard(event, data)
                                          }
                                        >
                                          <h4 className="sub-title">
                                            Set As Default
                                          </h4>
                                        </Link>
                                      )}

                                      <Link
                                        to="#"
                                        onClick={(event) =>
                                          this.deleteCard(event, data)
                                        }
                                      >
                                        <p className="sub-desc">Remove</p>
                                      </Link>
                                    </div>
                                    <Image
                                      src={
                                        window.location.origin +
                                        "/assets/img/setting-card.svg"
                                      }
                                      className="cards-icon"
                                    />
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="no-data-found-sec">
                                <Row>
                                  <Col sm={12} md={12}>
                                    <div className="no-data-found-img-sec">
                                      <Image
                                        src={
                                          window.location.origin +
                                          "/assets/img/no-result-found.svg"
                                        }
                                        className="no-data-found-img"
                                      />
                                      <p className="text-no-data">
                                        No Result Found
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToPros = (state) => ({
  cards: state.cards,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CardsList);
