import React, { Component } from "react";
import {
  Image,
  Navbar,
  Nav,
  Form,
  FormControl,
  Button,
  NavDropdown,
  InputGroup,
  Dropdown,
  Media,
  Row,
  Col,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./LandingPage.css";
import "./Responsive.css";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";

class LandingPage extends Component {

  constructor(props) {
    super(props);
  }
  state = {
    mobileSidebar: true,
  };

  toggleMobileSidebar = () => {
    this.setState({
      mobileSidebar: !this.state.mobileSidebar,
    });
  };

  render() {
    return (
      <div className="video-app">
        <header>
          <Navbar
            collapseOnSelect
            expand="lg"
            className="navbar-expand-lg default-nav"
          >
            <div className="nav-icon" onClick={() => this.toggleMobileSidebar()}>
              <FontAwesomeIcon
                icon={["fas", "align-left"]}
                className="mr-3"
                id="sidebarCollapse"
              />
            </div>
            <Navbar.Brand href="#home">
              <Image
                src={configuration.get("configData.site_icon")}
                className="base-logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="resp-padding-sm"
            >
              <ul className="navbar-nav mx-auto resp-padding-xs">
                <InputGroup className="width-half-search">
                  <FormControl placeholder="Search" className="search-info" />
                  <InputGroup.Append>
                    <InputGroup.Text
                      id="basic-addon2"
                      className="search-info-1"
                    >
                      <FontAwesomeIcon
                        icon={["fas", "search"]}
                        className="mr-3"
                      />
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </ul>
              <Dropdown className="resp-padding-xs video-create">
                <Dropdown.Toggle className="button" id="dropdown-basic">
                  <FontAwesomeIcon icon={["fas", "video"]} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">
                    <FontAwesomeIcon
                      icon={["fas", "upload"]}
                      className="icon"
                    />
                    Upload Video
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    <i className="icofont-youtube-play"></i>Go Live
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="resp-padding-xs video-create">
                <Dropdown.Toggle className="button" id="dropdown-basic">
                  <FontAwesomeIcon icon={["fas", "th"]} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="border-bottom-1">
                    <Dropdown.Item href="#/action-1">
                      <i className="icofont-youtube-play"></i>
                      StreamTube TV
                    </Dropdown.Item>
                  </div>
                  <div className="border-bottom-1">
                    <Dropdown.Item href="#/action-2">
                      <i className="icofont-youtube-play"></i>StreamTube Music
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      <i className="icofont-youtube-play"></i>StreamTube Kids
                    </Dropdown.Item>
                  </div>
                  <Dropdown.Item href="#/action-4">
                    <i className="icofont-youtube-play"></i>Creator Academy
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-5">
                    <i className="icofont-youtube-play"></i>StreamTube for
                    Artists
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="video-settings notification-menu resp-padding-xs">
                <Dropdown.Toggle className="button" id="dropdown-basic">
                  <FontAwesomeIcon icon={["fas", "bell"]} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="notification-menu-sec">
                    <div className="notification-menu-header">
                       <h3>Notification</h3>
                       <Link to="#" className="setting-icon"><FontAwesomeIcon icon={["fas", "cog"]} className="icon" /></Link>
                    </div>
                    <div className="notification-menu-body">
                      <div className="notification-flex-details">
                        <Image
                        src={
                          window.location.origin +
                          "/assets/img/base-product/profile-img.jpg"
                        }
                        className="nav-user-img"
                        />
                        <div className="notification-menu-content">
                          <h4>Recommended: BLACKPINK - Pretty Savage M/V</h4>
                          <p>14 hours ago</p>
                        </div>
                        <Image
                        src={
                          window.location.origin +
                          "/assets/img/base-product/video-1.jpg"
                        }
                        className="notification-video-img"
                        />
                        <Dropdown className="video-single-base-comment-dropdown resp-padding-xs">
                          <Dropdown.Toggle
                            className="button"
                            id="dropdown-basic"
                          >
                            <FontAwesomeIcon icon={["fas", "ellipsis-h"]} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="notification-sub-menu">
                            <Dropdown.Item href="#/action-3">
                              <FontAwesomeIcon icon={["fas", "eye-slash"]}  className="mr-2"/>Hide this notification
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-3">
                              <FontAwesomeIcon icon={["fas", "eye-slash"]} className="mr-2"/>Turn off all from ELLIZ
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="video-settings resp-padding-xs">
                <Dropdown.Toggle className="button" id="dropdown-basic">
                  <Image
                    src={
                      window.location.origin +
                      "/assets/img/base-product/profile-img.jpg"
                    }
                    className="nav-user-img"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="navbar-profile-content border-bottom-1">
                    <div className="user-profile-img">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/base-product/profile-img.jpg"
                        }
                        className="profile-img"
                      />
                    </div>
                    <div className="profile-info">
                      <h4 className="title">Beno P</h4>
                      <h5 className="desc">beno@codegama.com</h5>
                      <a href="#" className="link">
                        Manage your account
                      </a>
                    </div>
                  </div>
                  <div className="dorpdown-scroll">
                    <Dropdown.Item href="#/action-1">
                      <FontAwesomeIcon icon={["fas", "cog"]} className="icon" />
                      Your Channel
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      <FontAwesomeIcon icon={["fas", "cog"]} className="icon" />
                      Your Channel
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      <FontAwesomeIcon icon={["fas", "cog"]} className="icon" />
                      Your Channel
                    </Dropdown.Item>
                    <div className="flex-content">
                      <Dropdown.Item href="#/action-4">
                        Switch Account
                        <span className="align-right">
                          <FontAwesomeIcon
                            icon={["fas", "chevron-right"]}
                            className="icon"
                          />
                        </span>
                      </Dropdown.Item>
                    </div>
                    <div className="border-bottom-1">
                      <Dropdown.Item href="#/action-5">
                        <FontAwesomeIcon
                          icon={["fas", "cog"]}
                          className="icon"
                        />
                        Your Channel
                      </Dropdown.Item>
                    </div>
                    <div className="flex-content">
                      <Dropdown.Item href="#/action-6">
                        <FontAwesomeIcon
                          icon={["fas", "sun"]}
                          className="icon"
                        />
                        Dark Theme: Off
                        <span className="align-right">
                          <FontAwesomeIcon
                            icon={["fas", "chevron-right"]}
                            className="icon"
                          />
                        </span>
                      </Dropdown.Item>
                    </div>
                    <div className="flex-content">
                      <Dropdown.Item href="#/action-7">
                        <FontAwesomeIcon
                          icon={["fas", "language"]}
                          className="icon"
                        />
                        Language: English
                        <span className="align-right">
                          <FontAwesomeIcon
                            icon={["fas", "chevron-right"]}
                            className="icon"
                          />
                        </span>
                      </Dropdown.Item>
                    </div>
                    <div className="flex-content">
                      <Dropdown.Item href="#/action-8">
                        <FontAwesomeIcon
                          icon={["fas", "globe"]}
                          className="icon"
                        />
                        Location: India
                        <span className="align-right">
                          <FontAwesomeIcon
                            icon={["fas", "chevron-right"]}
                            className="icon"
                          />
                        </span>
                      </Dropdown.Item>
                    </div>
                    <div className="flex-content">
                      <Dropdown.Item href="#/action-9">
                        <FontAwesomeIcon
                          icon={["fas", "cog"]}
                          className="icon"
                        />
                        Settings
                      </Dropdown.Item>
                    </div>
                    <div className="flex-content">
                      <Dropdown.Item href="#/action-10">
                        <FontAwesomeIcon
                          icon={["fas", "user-shield"]}
                          className="icon"
                        />
                        Your data in StreamTube
                      </Dropdown.Item>
                    </div>
                    <div className="flex-content">
                      <Dropdown.Item href="#/action-11">
                        <FontAwesomeIcon
                          icon={["fas", "question-circle"]}
                          className="icon"
                        />
                        Help
                      </Dropdown.Item>
                    </div>
                    <div className="flex-content">
                      <Dropdown.Item href="#/action-12">
                        <i className="icofont-ui-text-loading"></i>Send feedback
                      </Dropdown.Item>
                    </div>
                    <div className="border-bottom-1">
                      <Dropdown.Item href="#/action-13">
                        <FontAwesomeIcon
                          icon={["fas", "keyboard"]}
                          className="icon"
                        />
                        keyboard shorcuts
                      </Dropdown.Item>
                    </div>
                    <div className="flex-content">
                      <Dropdown.Item href="#/action-14">
                        Restricted Mode: Off
                        <span className="align-right">
                          <FontAwesomeIcon
                            icon={["fas", "chevron-right"]}
                            className="icon"
                          />
                        </span>
                      </Dropdown.Item>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </Navbar.Collapse>
          </Navbar>
        </header>
        <div className="wrapper main-wrapper">
          <div className="left-side" id="sidebar"  style={{
            display: this.state.mobileSidebar ? "block" : "none",
          }}>
            <div className="side-wrapper">
              <div className="side-menu">
                <ul className="list-unstyled side-menu-item">
                  <Media as="li" className="active">
                    <i className="icofont-home"></i>Home
                  </Media>
                  <Media as="li">
                    <i className="material-icons playlist-icon-1">whatshot</i>
                    Trending
                  </Media>
                  <Media as="li">
                    <i className="material-icons playlist-icon-1">
                      subscriptions
                    </i>
                    Subscriptions
                  </Media>
                  <Media as="li">
                    <i className="material-icons playlist-icon-1">
                      video_library
                    </i>
                    Library
                  </Media>
                  <Media as="li">
                    <i className="icofont-history"></i>History
                  </Media>
                  <Media as="li">
                    <i className="material-icons playlist-icon-1">slideshow</i>
                    Your videos
                  </Media>
                  <Media as="li">
                    <i className="material-icons playlist-icon-1">
                      watch_later
                    </i>
                    Watch later
                  </Media>
                  <Media as="li">
                    <i className="material-icons playlist-icon-1">thumb_up</i>
                    Liked videos
                  </Media>
                </ul>
                <hr></hr>
                <div className="best-video-sec">
                  <h3 className="best-title-1">SUBSCRIPTIONS</h3>
                  <ul className="list-unstyled side-menu-item">
                    <Media as="li">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/base-product/channel-img-1.jpg"
                        }
                        className="base-logo"
                      />
                      Cheetah Media
                    </Media>
                    <Media as="li">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/base-product/valis-kitchen.jpg"
                        }
                        className="base-logo"
                      />
                      Valli's Kitchen
                    </Media>
                    <Media as="li">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/base-product/channel-img-2.jpg"
                        }
                        className="base-logo"
                      />
                      Gaming
                    </Media>
                    <Media as="li">
                      <i className="icofont-plus-circle"></i>Browse Channels
                    </Media>
                  </ul>
                </div>
                <div className="best-video-sec">
                  <h3 className="best-title-1">More From Streamtube</h3>
                  <ul className="list-unstyled side-menu-item">
                    <Media as="li">
                      <i className="icofont-youtube-play"></i>Streamtube Premium
                    </Media>
                    <Media as="li">
                      <i className="material-icons playlist-icon-1">
                        surround_sound
                      </i>
                      Live
                    </Media>
                    <hr></hr>
                    <Media as="li">
                      <i className="icofont-options"></i>Settings
                    </Media>
                    <Media as="li">
                      <i className="icofont-flag"></i>Report History
                    </Media>
                    <Media as="li">
                      <i className="icofont-question-circle"></i>Help
                    </Media>
                    <Media as="li">
                      <i className="icofont-ui-text-loading"></i>Send feedback
                    </Media>
                  </ul>
                </div>
                <hr></hr>
                <div className="sidebar-footer">
                  <Link to="#">About</Link>
                  <Link to="#">Press</Link>
                  <Link to="#">Copyright</Link>
                  <Link to="#">Contact us</Link>
                  <Link to="#">Creators</Link>
                  <Link to="#">Advertise</Link>
                  <Link to="#">Developers</Link>
                </div>
                <div className="sidebar-subfooter">
                  <Link to="#">Terms</Link>
                  <Link to="#">Privacy</Link>
                  <Link to="#">Policy & Safety</Link>
                  <Link to="#">How YouTube works</Link>
                  <Link to="#">Test new features</Link>
                </div>
                <div className="copyright">
                  <p>© 2020 Codegama LLP</p>
                </div>
              </div>
            </div>
          </div>
          <div className="main-container">
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="filter-sec">
                  <ul className="list-unstyled">
                    <Media as="li">
                      <Link to="#" className="active">
                        All recommendations
                      </Link>
                    </Media>
                    <Media as="li">
                      <Link to="#">Top 40</Link>
                    </Media>
                    <Media as="li">
                      <Link to="#">Electronic Music</Link>
                    </Media>
                    <Media as="li">
                      <Link to="#">Pop Music</Link>
                    </Media>
                    <Media as="li">
                      <Link to="#">Live</Link>
                    </Media>
                    <Media as="li">
                      <Link to="#">Photography</Link>
                    </Media>
                    <Media as="li">
                      <Link to="#">Tourist Destinations</Link>
                    </Media>
                    <Media as="li">
                      <Link to="#">Bollywood Music</Link>
                    </Media>
                    <Media as="li">
                      <Link to="#">Adobe Illustrator</Link>
                    </Media>
                    <Media as="li">
                      <Link to="#">Cricket</Link>
                    </Media>
                    <Media as="li">
                      <Link to="#">Tamil cinema</Link>
                    </Media>
                    <Media as="li">
                      <Link to="#">Cooking</Link>
                    </Media>
                    <Media as="li">
                      <Link to="#">Rock music</Link>
                    </Media>
                    <Media as="li">
                      <Link to="#">Recently uploaded</Link>
                    </Media>
                    <Media as="li">
                      <Link to="#">Video editing</Link>
                    </Media>
                  </ul>
                </div>
              </Col>
            </Row>
            <div className="video-listing-sec">
              <Row>
                <Col sm={12} md={12}>
                  <h2 className="section-title">Recommended</h2>
                </Col>
              </Row>
              <Row>
                <Col sm={12} xs={12} xl={3} md={6}>
                  <div className="video-list-box">
                    <div className="hover-content">
                      <ul className="list-unstyled">
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              watch_later
                            </i>
                          </Link>
                        </Media>
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              playlist_play
                            </i>
                          </Link>
                        </Media>
                      </ul>
                    </div>
                    <div className="video-list-img">
                      <Link to="#">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/video-1.jpg"
                          }
                          className="img-fluid video-img"
                        />
                      </Link>
                      <div className="hover-play">
                        <Link to="#">
                          <i className="icofont-ui-play"></i>
                        </Link>
                      </div>
                      <div className="time-count">
                        <h6>16:30</h6>
                      </div>
                    </div>
                    <div className="video-list-info">
                      <div className="user-img-sec">
                        <Link to="#">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/img/base-product/profile-img.jpg"
                            }
                            className="user-img"
                          />
                        </Link>
                      </div>
                      <div className="video-list-content">
                        <div className="video-middle-content">
                          <Link to="#">
                            <h3 className="title">
                              Vathikkalu Vellaripravu Video Song | Sufiyum
                              Sujatayum | M Jayachandran | Vijay Babu
                            </h3>
                          </Link>
                          <Link to="#">
                            <h4 className="sub-title">Friday Music Company</h4>
                          </Link>
                          <Link to="#">
                            <p className="desc">
                              <span className="view-count">5M views</span>
                              <span className="upload-time">2 weeks ago</span>
                            </p>
                          </Link>
                        </div>
                        <div className="video-action-sec">
                          <Dropdown className="video-action-dropdown resp-padding-xs">
                            <Dropdown.Toggle
                              className="button"
                              id="dropdown-basic"
                            >
                              <FontAwesomeIcon icon={["fas", "ellipsis-v"]} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                <i className="material-icons icon">
                                  playlist_play
                                </i>
                                Add to queue
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                <i className="material-icons icon">
                                  watch_later
                                </i>
                                Save to Watch later
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="material-icons icon">
                                  playlist_add
                                </i>
                                Save to playlist
                              </Dropdown.Item>
                              <hr></hr>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-not-allowed icon"></i>Not
                                interested
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-minus-circle icon"></i>
                                Don't recommend posts form channels
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-flag icon"></i>Report
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={12} xs={12} xl={3} md={6}>
                  <div className="video-list-box">
                    <div className="hover-content">
                      <ul className="list-unstyled">
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              watch_later
                            </i>
                          </Link>
                        </Media>
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              playlist_play
                            </i>
                          </Link>
                        </Media>
                      </ul>
                    </div>
                    <div className="video-list-img">
                      <Link to="#">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/video-1.jpg"
                          }
                          className="img-fluid video-img"
                        />
                      </Link>
                      <div className="hover-play">
                        <Link to="#">
                          <i className="icofont-ui-play"></i>
                        </Link>
                      </div>
                      <div className="time-count">
                        <h6>16:30</h6>
                      </div>
                    </div>
                    <div className="video-list-info">
                      <div className="user-img-sec">
                        <Link to="#">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/img/base-product/profile-img.jpg"
                            }
                            className="user-img"
                          />
                        </Link>
                      </div>
                      <div className="video-list-content">
                        <div className="video-middle-content">
                          <Link to="#">
                            <h3 className="title">
                              Vathikkalu Vellaripravu Video Song | Sufiyum
                              Sujatayum | M Jayachandran | Vijay Babu
                            </h3>
                          </Link>
                          <Link to="#">
                            <h4 className="sub-title">Friday Music Company</h4>
                          </Link>
                          <Link to="#">
                            <p className="desc">
                              <span className="view-count">5M views</span>
                              <span className="upload-time">2 weeks ago</span>
                            </p>
                          </Link>
                        </div>
                        <div className="video-action-sec">
                          <Dropdown className="video-action-dropdown resp-padding-xs">
                            <Dropdown.Toggle
                              className="button"
                              id="dropdown-basic"
                            >
                              <FontAwesomeIcon icon={["fas", "ellipsis-v"]} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                <i className="material-icons icon">
                                  playlist_play
                                </i>
                                Add to queue
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                <i className="material-icons icon">
                                  watch_later
                                </i>
                                Save to Watch later
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="material-icons icon">
                                  playlist_add
                                </i>
                                Save to playlist
                              </Dropdown.Item>
                              <hr></hr>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-not-allowed icon"></i>Not
                                interested
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-minus-circle icon"></i>
                                Don't recommend posts form channels
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-flag icon"></i>Report
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={12} xs={12} xl={3} md={6}>
                  <div className="video-list-box">
                    <div className="hover-content">
                      <ul className="list-unstyled">
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              watch_later
                            </i>
                          </Link>
                        </Media>
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              playlist_play
                            </i>
                          </Link>
                        </Media>
                      </ul>
                    </div>
                    <div className="video-list-img">
                      <Link to="#">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/video-1.jpg"
                          }
                          className="img-fluid video-img"
                        />
                      </Link>
                      <div className="hover-play">
                        <Link to="#">
                          <i className="icofont-ui-play"></i>
                        </Link>
                      </div>
                      <div className="time-count">
                        <h6>16:30</h6>
                      </div>
                    </div>
                    <div className="video-list-info">
                      <div className="user-img-sec">
                        <Link to="#">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/img/base-product/profile-img.jpg"
                            }
                            className="user-img"
                          />
                        </Link>
                      </div>
                      <div className="video-list-content">
                        <div className="video-middle-content">
                          <Link to="#">
                            <h3 className="title">
                              Vathikkalu Vellaripravu Video Song | Sufiyum
                              Sujatayum | M Jayachandran | Vijay Babu
                            </h3>
                          </Link>
                          <Link to="#">
                            <h4 className="sub-title">Friday Music Company</h4>
                          </Link>
                          <Link to="#">
                            <p className="desc">
                              <span className="view-count">5M views</span>
                              <span className="upload-time">2 weeks ago</span>
                            </p>
                          </Link>
                        </div>
                        <div className="video-action-sec">
                          <Dropdown className="video-action-dropdown resp-padding-xs">
                            <Dropdown.Toggle
                              className="button"
                              id="dropdown-basic"
                            >
                              <FontAwesomeIcon icon={["fas", "ellipsis-v"]} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                <i className="material-icons icon">
                                  playlist_play
                                </i>
                                Add to queue
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                <i className="material-icons icon">
                                  watch_later
                                </i>
                                Save to Watch later
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="material-icons icon">
                                  playlist_add
                                </i>
                                Save to playlist
                              </Dropdown.Item>
                              <hr></hr>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-not-allowed icon"></i>Not
                                interested
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-minus-circle icon"></i>
                                Don't recommend posts form channels
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-flag icon"></i>Report
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={12} xs={12} xl={3} md={6}>
                  <div className="video-list-box">
                    <div className="hover-content">
                      <ul className="list-unstyled">
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              watch_later
                            </i>
                          </Link>
                        </Media>
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              playlist_play
                            </i>
                          </Link>
                        </Media>
                      </ul>
                    </div>
                    <div className="video-list-img">
                      <Link to="#">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/video-1.jpg"
                          }
                          className="img-fluid video-img"
                        />
                      </Link>
                      <div className="hover-play">
                        <Link to="#">
                          <i className="icofont-ui-play"></i>
                        </Link>
                      </div>
                      <div className="time-count">
                        <h6>16:30</h6>
                      </div>
                    </div>
                    <div className="video-list-info">
                      <div className="user-img-sec">
                        <Link to="#">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/img/base-product/profile-img.jpg"
                            }
                            className="user-img"
                          />
                        </Link>
                      </div>
                      <div className="video-list-content">
                        <div className="video-middle-content">
                          <Link to="#">
                            <h3 className="title">
                              Vathikkalu Vellaripravu Video Song | Sufiyum
                              Sujatayum | M Jayachandran | Vijay Babu
                            </h3>
                          </Link>
                          <Link to="#">
                            <h4 className="sub-title">Friday Music Company</h4>
                          </Link>
                          <Link to="#">
                            <p className="desc">
                              <span className="view-count">5M views</span>
                              <span className="upload-time">2 weeks ago</span>
                            </p>
                          </Link>
                        </div>
                        <div className="video-action-sec">
                          <Dropdown className="video-action-dropdown resp-padding-xs">
                            <Dropdown.Toggle
                              className="button"
                              id="dropdown-basic"
                            >
                              <FontAwesomeIcon icon={["fas", "ellipsis-v"]} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                <i className="material-icons icon">
                                  playlist_play
                                </i>
                                Add to queue
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                <i className="material-icons icon">
                                  watch_later
                                </i>
                                Save to Watch later
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="material-icons icon">
                                  playlist_add
                                </i>
                                Save to playlist
                              </Dropdown.Item>
                              <hr></hr>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-not-allowed icon"></i>Not
                                interested
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-minus-circle icon"></i>
                                Don't recommend posts form channels
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-flag icon"></i>Report
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="space-mg"></div>
              <Row>
                <Col sm={12} xs={12} xl={3} md={6}>
                  <div className="video-list-box">
                    <div className="hover-content">
                      <ul className="list-unstyled">
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              watch_later
                            </i>
                          </Link>
                        </Media>
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              playlist_play
                            </i>
                          </Link>
                        </Media>
                      </ul>
                    </div>
                    <div className="video-list-img">
                      <Link to="#">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/video-1.jpg"
                          }
                          className="img-fluid video-img"
                        />
                      </Link>
                      <div className="hover-play">
                        <Link to="#">
                          <i className="icofont-ui-play"></i>
                        </Link>
                      </div>
                      <div className="time-count">
                        <h6>16:30</h6>
                      </div>
                    </div>
                    <div className="video-list-info">
                      <div className="user-img-sec">
                        <Link to="#">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/img/base-product/profile-img.jpg"
                            }
                            className="user-img"
                          />
                        </Link>
                      </div>
                      <div className="video-list-content">
                        <div className="video-middle-content">
                          <Link to="#">
                            <h3 className="title">
                              Vathikkalu Vellaripravu Video Song | Sufiyum
                              Sujatayum | M Jayachandran | Vijay Babu
                            </h3>
                          </Link>
                          <Link to="#">
                            <h4 className="sub-title">Friday Music Company</h4>
                          </Link>
                          <Link to="#">
                            <p className="desc">
                              <span className="view-count">5M views</span>
                              <span className="upload-time">2 weeks ago</span>
                            </p>
                          </Link>
                        </div>
                        <div className="video-action-sec">
                          <Dropdown className="video-action-dropdown resp-padding-xs">
                            <Dropdown.Toggle
                              className="button"
                              id="dropdown-basic"
                            >
                              <FontAwesomeIcon icon={["fas", "ellipsis-v"]} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                <i className="material-icons icon">
                                  playlist_play
                                </i>
                                Add to queue
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                <i className="material-icons icon">
                                  watch_later
                                </i>
                                Save to Watch later
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="material-icons icon">
                                  playlist_add
                                </i>
                                Save to playlist
                              </Dropdown.Item>
                              <hr></hr>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-not-allowed icon"></i>Not
                                interested
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-minus-circle icon"></i>
                                Don't recommend posts form channels
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-flag icon"></i>Report
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={12} xs={12} xl={3} md={6}>
                  <div className="video-list-box">
                    <div className="hover-content">
                      <ul className="list-unstyled">
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              watch_later
                            </i>
                          </Link>
                        </Media>
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              playlist_play
                            </i>
                          </Link>
                        </Media>
                      </ul>
                    </div>
                    <div className="video-list-img">
                      <Link to="#">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/video-1.jpg"
                          }
                          className="img-fluid video-img"
                        />
                      </Link>
                      <div className="hover-play">
                        <Link to="#">
                          <i className="icofont-ui-play"></i>
                        </Link>
                      </div>
                      <div className="time-count">
                        <h6>16:30</h6>
                      </div>
                    </div>
                    <div className="video-list-info">
                      <div className="user-img-sec">
                        <Link to="#">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/img/base-product/profile-img.jpg"
                            }
                            className="user-img"
                          />
                        </Link>
                      </div>
                      <div className="video-list-content">
                        <div className="video-middle-content">
                          <Link to="#">
                            <h3 className="title">
                              Vathikkalu Vellaripravu Video Song | Sufiyum
                              Sujatayum | M Jayachandran | Vijay Babu
                            </h3>
                          </Link>
                          <Link to="#">
                            <h4 className="sub-title">Friday Music Company</h4>
                          </Link>
                          <Link to="#">
                            <p className="desc">
                              <span className="view-count">5M views</span>
                              <span className="upload-time">2 weeks ago</span>
                            </p>
                          </Link>
                        </div>
                        <div className="video-action-sec">
                          <Dropdown className="video-action-dropdown resp-padding-xs">
                            <Dropdown.Toggle
                              className="button"
                              id="dropdown-basic"
                            >
                              <FontAwesomeIcon icon={["fas", "ellipsis-v"]} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                <i className="material-icons icon">
                                  playlist_play
                                </i>
                                Add to queue
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                <i className="material-icons icon">
                                  watch_later
                                </i>
                                Save to Watch later
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="material-icons icon">
                                  playlist_add
                                </i>
                                Save to playlist
                              </Dropdown.Item>
                              <hr></hr>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-not-allowed icon"></i>Not
                                interested
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-minus-circle icon"></i>
                                Don't recommend posts form channels
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-flag icon"></i>Report
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={12} xs={12} xl={3} md={6}>
                  <div className="video-list-box">
                    <div className="hover-content">
                      <ul className="list-unstyled">
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              watch_later
                            </i>
                          </Link>
                        </Media>
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              playlist_play
                            </i>
                          </Link>
                        </Media>
                      </ul>
                    </div>
                    <div className="video-list-img">
                      <Link to="#">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/video-1.jpg"
                          }
                          className="img-fluid video-img"
                        />
                      </Link>
                      <div className="hover-play">
                        <Link to="#">
                          <i className="icofont-ui-play"></i>
                        </Link>
                      </div>
                      <div className="time-count">
                        <h6>16:30</h6>
                      </div>
                    </div>
                    <div className="video-list-info">
                      <div className="user-img-sec">
                        <Link to="#">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/img/base-product/profile-img.jpg"
                            }
                            className="user-img"
                          />
                        </Link>
                      </div>
                      <div className="video-list-content">
                        <div className="video-middle-content">
                          <Link to="#">
                            <h3 className="title">
                              Vathikkalu Vellaripravu Video Song | Sufiyum
                              Sujatayum | M Jayachandran | Vijay Babu
                            </h3>
                          </Link>
                          <Link to="#">
                            <h4 className="sub-title">Friday Music Company</h4>
                          </Link>
                          <Link to="#">
                            <p className="desc">
                              <span className="view-count">5M views</span>
                              <span className="upload-time">2 weeks ago</span>
                            </p>
                          </Link>
                        </div>
                        <div className="video-action-sec">
                          <Dropdown className="video-action-dropdown resp-padding-xs">
                            <Dropdown.Toggle
                              className="button"
                              id="dropdown-basic"
                            >
                              <FontAwesomeIcon icon={["fas", "ellipsis-v"]} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                <i className="material-icons icon">
                                  playlist_play
                                </i>
                                Add to queue
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                <i className="material-icons icon">
                                  watch_later
                                </i>
                                Save to Watch later
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="material-icons icon">
                                  playlist_add
                                </i>
                                Save to playlist
                              </Dropdown.Item>
                              <hr></hr>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-not-allowed icon"></i>Not
                                interested
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-minus-circle icon"></i>
                                Don't recommend posts form channels
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-flag icon"></i>Report
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={12} xs={12} xl={3} md={6}>
                  <div className="video-list-box">
                    <div className="hover-content">
                      <ul className="list-unstyled">
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              watch_later
                            </i>
                          </Link>
                        </Media>
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              playlist_play
                            </i>
                          </Link>
                        </Media>
                      </ul>
                    </div>
                    <div className="video-list-img">
                      <Link to="#">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/video-1.jpg"
                          }
                          className="img-fluid video-img"
                        />
                      </Link>
                      <div className="hover-play">
                        <Link to="#">
                          <i className="icofont-ui-play"></i>
                        </Link>
                      </div>
                      <div className="time-count">
                        <h6>16:30</h6>
                      </div>
                    </div>
                    <div className="video-list-info">
                      <div className="user-img-sec">
                        <Link to="#">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/img/base-product/profile-img.jpg"
                            }
                            className="user-img"
                          />
                        </Link>
                      </div>
                      <div className="video-list-content">
                        <div className="video-middle-content">
                          <Link to="#">
                            <h3 className="title">
                              Vathikkalu Vellaripravu Video Song | Sufiyum
                              Sujatayum | M Jayachandran | Vijay Babu
                            </h3>
                          </Link>
                          <Link to="#">
                            <h4 className="sub-title">Friday Music Company</h4>
                          </Link>
                          <Link to="#">
                            <p className="desc">
                              <span className="view-count">5M views</span>
                              <span className="upload-time">2 weeks ago</span>
                            </p>
                          </Link>
                        </div>
                        <div className="video-action-sec">
                          <Dropdown className="video-action-dropdown resp-padding-xs">
                            <Dropdown.Toggle
                              className="button"
                              id="dropdown-basic"
                            >
                              <FontAwesomeIcon icon={["fas", "ellipsis-v"]} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                <i className="material-icons icon">
                                  playlist_play
                                </i>
                                Add to queue
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                <i className="material-icons icon">
                                  watch_later
                                </i>
                                Save to Watch later
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="material-icons icon">
                                  playlist_add
                                </i>
                                Save to playlist
                              </Dropdown.Item>
                              <hr></hr>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-not-allowed icon"></i>Not
                                interested
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-minus-circle icon"></i>
                                Don't recommend posts form channels
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-flag icon"></i>Report
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="space-sm"></div>
              <Row>
                <Col sm={12} md={12}>
                  <div className="border-top-1"></div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12}>
                  <h2 className="section-title">Latest YouTube posts</h2>
                </Col>
              </Row>
              <Row className="video-post">
                <Col sm={12} md={4} className="resp-mrg-btm-sm">
                  <div className="video-post-box">
                    <Link to="#">
                      <div className="post-details">
                        <div className="post-user-img-sec">
                          <Link to="#">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/img/base-product/post-user-img.jpg"
                              }
                              className="post-user-img"
                            />
                          </Link>
                        </div>
                        <div className="post-info">
                          <Link to="#">
                            <p className="desc">
                              <span className="view-count">
                                My Music Playlist
                              </span>
                              <span className="upload-time">3 days ago</span>
                            </p>
                          </Link>
                        </div>
                      </div>
                      <div className="post-content">
                        <div>
                          <p className="sub-desc">
                            Hello Everyone Have a nice week,Thank for watching
                            my video , Have a nice songs .
                          </p>
                          <p className="link">https://www.streamtube.com</p>
                        </div>
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/post-img.jpg"
                          }
                          className="post-img"
                        />
                      </div>
                      <div className="post-footer">
                        <div className="like-dislike-sec">
                          <span className="flex-info">
                            <i className="material-icons playlist-icon">
                              thumb_up
                            </i>
                            <span className=" like-count">3.3k</span>
                          </span>
                          <span className="flex-info">
                            <i className="material-icons playlist-icon">
                              thumb_down
                            </i>
                            <span className="dis-like-count">289</span>
                          </span>
                        </div>
                        <div className="comment-report-sec">
                          <span className="flex-info">
                            <i className="material-icons playlist-icon">
                              comment
                            </i>
                            <span className="share">20</span>
                          </span>
                          <span>
                            <div className="video-action-sec">
                              <Dropdown className="video-post-dropdown resp-padding-xs">
                                <Dropdown.Toggle
                                  className="button"
                                  id="dropdown-basic"
                                >
                                  <FontAwesomeIcon
                                    icon={["fas", "ellipsis-v"]}
                                  />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item href="#/action-3">
                                    <i className="icofont-not-allowed icon"></i>
                                    Not interested
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-3">
                                    <i className="icofont-minus-circle icon"></i>
                                    Don't recommend posts form channels
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-3">
                                    <i className="icofont-flag icon"></i>Report
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </Col>
                <Col sm={12} md={4} className="resp-mrg-btm-sm">
                  <div className="video-post-box">
                    <Link to="#">
                      <div className="post-details">
                        <div className="post-user-img-sec">
                          <Link to="#">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/img/base-product/post-user-img.jpg"
                              }
                              className="post-user-img"
                            />
                          </Link>
                        </div>
                        <div className="post-info">
                          <Link to="#">
                            <p className="desc">
                              <span className="view-count">
                                My Music Playlist
                              </span>
                              <span className="upload-time">3 days ago</span>
                            </p>
                          </Link>
                        </div>
                      </div>
                      <div className="post-content">
                        <div>
                          <p className="sub-desc">
                            Hello Everyone Have a nice week,Thank for watching
                            my video , Have a nice songs .
                          </p>
                          <p className="link">https://www.streamtube.com</p>
                        </div>
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/post-img.jpg"
                          }
                          className="post-img"
                        />
                      </div>
                      <div className="post-footer">
                        <div className="like-dislike-sec">
                          <span className="flex-info">
                            <i className="material-icons playlist-icon">
                              thumb_up
                            </i>
                            <span className=" like-count">3.3k</span>
                          </span>
                          <span className="flex-info">
                            <i className="material-icons playlist-icon">
                              thumb_down
                            </i>
                            <span className="dis-like-count">289</span>
                          </span>
                        </div>
                        <div className="comment-report-sec">
                          <span className="flex-info">
                            <i className="material-icons playlist-icon">
                              comment
                            </i>
                            <span className="share">20</span>
                          </span>
                          <span>
                            <div className="video-action-sec">
                              <Dropdown className="video-post-dropdown resp-padding-xs">
                                <Dropdown.Toggle
                                  className="button"
                                  id="dropdown-basic"
                                >
                                  <FontAwesomeIcon
                                    icon={["fas", "ellipsis-v"]}
                                  />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item href="#/action-3">
                                    <i className="icofont-not-allowed icon"></i>
                                    Not interested
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-3">
                                    <i className="icofont-minus-circle icon"></i>
                                    Don't recommend posts form channels
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-3">
                                    <i className="icofont-flag icon"></i>Report
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </Col>
                <Col sm={12} md={4}>
                  <div className="video-post-box">
                    <Link to="#">
                      <div className="post-details">
                        <div className="post-user-img-sec">
                          <Link to="#">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/img/base-product/post-user-img.jpg"
                              }
                              className="post-user-img"
                            />
                          </Link>
                        </div>
                        <div className="post-info">
                          <Link to="#">
                            <p className="desc">
                              <span className="view-count">
                                My Music Playlist
                              </span>
                              <span className="upload-time">3 days ago</span>
                            </p>
                          </Link>
                        </div>
                      </div>
                      <div className="post-content">
                        <div>
                          <p className="sub-desc">
                            Hello Everyone Have a nice week,Thank for watching
                            my video , Have a nice songs .
                          </p>
                          <p className="link">https://www.streamtube.com</p>
                        </div>
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/post-img.jpg"
                          }
                          className="post-img"
                        />
                      </div>
                      <div className="post-footer">
                        <div className="like-dislike-sec">
                          <span className="flex-info">
                            <i className="material-icons playlist-icon">
                              thumb_up
                            </i>
                            <span className=" like-count">3.3k</span>
                          </span>
                          <span className="flex-info">
                            <i className="material-icons playlist-icon">
                              thumb_down
                            </i>
                            <span className="dis-like-count">289</span>
                          </span>
                        </div>
                        <div className="comment-report-sec">
                          <span className="flex-info">
                            <i className="material-icons playlist-icon">
                              comment
                            </i>
                            <span className="share">20</span>
                          </span>
                          <span>
                            <div className="video-action-sec">
                              <Dropdown className="video-post-dropdown resp-padding-xs">
                                <Dropdown.Toggle
                                  className="button"
                                  id="dropdown-basic"
                                >
                                  <FontAwesomeIcon
                                    icon={["fas", "ellipsis-v"]}
                                  />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item href="#/action-3">
                                    <i className="icofont-not-allowed icon"></i>
                                    Not interested
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-3">
                                    <i className="icofont-minus-circle icon"></i>
                                    Don't recommend posts form channels
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-3">
                                    <i className="icofont-flag icon"></i>Report
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </Col>
              </Row>
              <div className="space-sm"></div>
              <Row>
                <Col sm={12} md={12}>
                  <div className="show-more">
                    <Link to="#">
                      <i className="icofont-rounded-down"></i>
                    </Link>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12}>
                  <div class="flex-content features-sec padding-lg">
                    <div class="features-left">
                      <div class="features-img">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/features-youtube.png"
                          }
                          className="img-fluid"
                        />
                      </div>
                      <div class="features-info">
                        <h3 class="title">
                          Music #WithMe
                          <span class="badge badge-featured ml-2">
                            Featured
                          </span>
                        </h3>
                        <p class="desc">
                          Enjoy great music with your favourite artists &
                          musicians
                        </p>
                      </div>
                    </div>
                    <div class="features-right">
                      <FontAwesomeIcon icon={["fas", "times"]} />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} xs={12} xl={3} md={6}>
                  <div className="video-list-box">
                    <div className="hover-content">
                      <ul className="list-unstyled">
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              watch_later
                            </i>
                          </Link>
                        </Media>
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              playlist_play
                            </i>
                          </Link>
                        </Media>
                      </ul>
                    </div>
                    <div className="video-list-img">
                      <Link to="#">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/video-1.jpg"
                          }
                          className="img-fluid video-img"
                        />
                      </Link>
                      <div className="hover-play">
                        <Link to="#">
                          <i className="icofont-ui-play"></i>
                        </Link>
                      </div>
                      <div className="time-count">
                        <h6>16:30</h6>
                      </div>
                    </div>
                    <div className="video-list-info">
                      <div className="user-img-sec">
                        <Link to="#">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/img/base-product/profile-img.jpg"
                            }
                            className="user-img"
                          />
                        </Link>
                      </div>
                      <div className="video-list-content">
                        <div className="video-middle-content">
                          <Link to="#">
                            <h3 className="title">
                              Vathikkalu Vellaripravu Video Song | Sufiyum
                              Sujatayum | M Jayachandran | Vijay Babu
                            </h3>
                          </Link>
                          <Link to="#">
                            <h4 className="sub-title">Friday Music Company</h4>
                          </Link>
                          <Link to="#">
                            <p className="desc">
                              <span className="view-count">5M views</span>
                              <span className="upload-time">2 weeks ago</span>
                            </p>
                          </Link>
                        </div>
                        <div className="video-action-sec">
                          <Dropdown className="video-action-dropdown resp-padding-xs">
                            <Dropdown.Toggle
                              className="button"
                              id="dropdown-basic"
                            >
                              <FontAwesomeIcon icon={["fas", "ellipsis-v"]} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                <i className="material-icons icon">
                                  playlist_play
                                </i>
                                Add to queue
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                <i className="material-icons icon">
                                  watch_later
                                </i>
                                Save to Watch later
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="material-icons icon">
                                  playlist_add
                                </i>
                                Save to playlist
                              </Dropdown.Item>
                              <hr></hr>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-not-allowed icon"></i>Not
                                interested
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-minus-circle icon"></i>
                                Don't recommend posts form channels
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-flag icon"></i>Report
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={12} xs={12} xl={3} md={6}>
                  <div className="video-list-box">
                    <div className="hover-content">
                      <ul className="list-unstyled">
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              watch_later
                            </i>
                          </Link>
                        </Media>
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              playlist_play
                            </i>
                          </Link>
                        </Media>
                      </ul>
                    </div>
                    <div className="video-list-img">
                      <Link to="#">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/video-1.jpg"
                          }
                          className="img-fluid video-img"
                        />
                      </Link>
                      <div className="hover-play">
                        <Link to="#">
                          <i className="icofont-ui-play"></i>
                        </Link>
                      </div>
                      <div className="time-count">
                        <h6>16:30</h6>
                      </div>
                    </div>
                    <div className="video-list-info">
                      <div className="user-img-sec">
                        <Link to="#">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/img/base-product/profile-img.jpg"
                            }
                            className="user-img"
                          />
                        </Link>
                      </div>
                      <div className="video-list-content">
                        <div className="video-middle-content">
                          <Link to="#">
                            <h3 className="title">
                              Vathikkalu Vellaripravu Video Song | Sufiyum
                              Sujatayum | M Jayachandran | Vijay Babu
                            </h3>
                          </Link>
                          <Link to="#">
                            <h4 className="sub-title">Friday Music Company</h4>
                          </Link>
                          <Link to="#">
                            <p className="desc">
                              <span className="view-count">5M views</span>
                              <span className="upload-time">2 weeks ago</span>
                            </p>
                          </Link>
                        </div>
                        <div className="video-action-sec">
                          <Dropdown className="video-action-dropdown resp-padding-xs">
                            <Dropdown.Toggle
                              className="button"
                              id="dropdown-basic"
                            >
                              <FontAwesomeIcon icon={["fas", "ellipsis-v"]} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                <i className="material-icons icon">
                                  playlist_play
                                </i>
                                Add to queue
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                <i className="material-icons icon">
                                  watch_later
                                </i>
                                Save to Watch later
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="material-icons icon">
                                  playlist_add
                                </i>
                                Save to playlist
                              </Dropdown.Item>
                              <hr></hr>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-not-allowed icon"></i>Not
                                interested
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-minus-circle icon"></i>
                                Don't recommend posts form channels
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-flag icon"></i>Report
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={12} xs={12} xl={3} md={6}>
                  <div className="video-list-box">
                    <div className="hover-content">
                      <ul className="list-unstyled">
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              watch_later
                            </i>
                          </Link>
                        </Media>
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              playlist_play
                            </i>
                          </Link>
                        </Media>
                      </ul>
                    </div>
                    <div className="video-list-img">
                      <Link to="#">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/video-1.jpg"
                          }
                          className="img-fluid video-img"
                        />
                      </Link>
                      <div className="hover-play">
                        <Link to="#">
                          <i className="icofont-ui-play"></i>
                        </Link>
                      </div>
                      <div className="time-count">
                        <h6>16:30</h6>
                      </div>
                    </div>
                    <div className="video-list-info">
                      <div className="user-img-sec">
                        <Link to="#">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/img/base-product/profile-img.jpg"
                            }
                            className="user-img"
                          />
                        </Link>
                      </div>
                      <div className="video-list-content">
                        <div className="video-middle-content">
                          <Link to="#">
                            <h3 className="title">
                              Vathikkalu Vellaripravu Video Song | Sufiyum
                              Sujatayum | M Jayachandran | Vijay Babu
                            </h3>
                          </Link>
                          <Link to="#">
                            <h4 className="sub-title">Friday Music Company</h4>
                          </Link>
                          <Link to="#">
                            <p className="desc">
                              <span className="view-count">5M views</span>
                              <span className="upload-time">2 weeks ago</span>
                            </p>
                          </Link>
                        </div>
                        <div className="video-action-sec">
                          <Dropdown className="video-action-dropdown resp-padding-xs">
                            <Dropdown.Toggle
                              className="button"
                              id="dropdown-basic"
                            >
                              <FontAwesomeIcon icon={["fas", "ellipsis-v"]} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                <i className="material-icons icon">
                                  playlist_play
                                </i>
                                Add to queue
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                <i className="material-icons icon">
                                  watch_later
                                </i>
                                Save to Watch later
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="material-icons icon">
                                  playlist_add
                                </i>
                                Save to playlist
                              </Dropdown.Item>
                              <hr></hr>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-not-allowed icon"></i>Not
                                interested
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-minus-circle icon"></i>
                                Don't recommend posts form channels
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-flag icon"></i>Report
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={12} xs={12} xl={3} md={6}>
                  <div className="video-list-box">
                    <div className="hover-content">
                      <ul className="list-unstyled">
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              watch_later
                            </i>
                          </Link>
                        </Media>
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              playlist_play
                            </i>
                          </Link>
                        </Media>
                      </ul>
                    </div>
                    <div className="video-list-img">
                      <Link to="#">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/video-1.jpg"
                          }
                          className="img-fluid video-img"
                        />
                      </Link>
                      <div className="hover-play">
                        <Link to="#">
                          <i className="icofont-ui-play"></i>
                        </Link>
                      </div>
                      <div className="time-count">
                        <h6>16:30</h6>
                      </div>
                    </div>
                    <div className="video-list-info">
                      <div className="user-img-sec">
                        <Link to="#">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/img/base-product/profile-img.jpg"
                            }
                            className="user-img"
                          />
                        </Link>
                      </div>
                      <div className="video-list-content">
                        <div className="video-middle-content">
                          <Link to="#">
                            <h3 className="title">
                              Vathikkalu Vellaripravu Video Song | Sufiyum
                              Sujatayum | M Jayachandran | Vijay Babu
                            </h3>
                          </Link>
                          <Link to="#">
                            <h4 className="sub-title">Friday Music Company</h4>
                          </Link>
                          <Link to="#">
                            <p className="desc">
                              <span className="view-count">5M views</span>
                              <span className="upload-time">2 weeks ago</span>
                            </p>
                          </Link>
                        </div>
                        <div className="video-action-sec">
                          <Dropdown className="video-action-dropdown resp-padding-xs">
                            <Dropdown.Toggle
                              className="button"
                              id="dropdown-basic"
                            >
                              <FontAwesomeIcon icon={["fas", "ellipsis-v"]} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                <i className="material-icons icon">
                                  playlist_play
                                </i>
                                Add to queue
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                <i className="material-icons icon">
                                  watch_later
                                </i>
                                Save to Watch later
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="material-icons icon">
                                  playlist_add
                                </i>
                                Save to playlist
                              </Dropdown.Item>
                              <hr></hr>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-not-allowed icon"></i>Not
                                interested
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-minus-circle icon"></i>
                                Don't recommend posts form channels
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-flag icon"></i>Report
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="space-mg"></div>
              <Row>
                <Col sm={12} xs={12} xl={3} md={6}>
                  <div className="video-list-box">
                    <div className="hover-content">
                      <ul className="list-unstyled">
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              watch_later
                            </i>
                          </Link>
                        </Media>
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              playlist_play
                            </i>
                          </Link>
                        </Media>
                      </ul>
                    </div>
                    <div className="video-list-img">
                      <Link to="#">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/video-1.jpg"
                          }
                          className="img-fluid video-img"
                        />
                      </Link>
                      <div className="hover-play">
                        <Link to="#">
                          <i className="icofont-ui-play"></i>
                        </Link>
                      </div>
                      <div className="time-count">
                        <h6>16:30</h6>
                      </div>
                    </div>
                    <div className="video-list-info">
                      <div className="user-img-sec">
                        <Link to="#">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/img/base-product/profile-img.jpg"
                            }
                            className="user-img"
                          />
                        </Link>
                      </div>
                      <div className="video-list-content">
                        <div className="video-middle-content">
                          <Link to="#">
                            <h3 className="title">
                              Vathikkalu Vellaripravu Video Song | Sufiyum
                              Sujatayum | M Jayachandran | Vijay Babu
                            </h3>
                          </Link>
                          <Link to="#">
                            <h4 className="sub-title">Friday Music Company</h4>
                          </Link>
                          <Link to="#">
                            <p className="desc">
                              <span className="view-count">5M views</span>
                              <span className="upload-time">2 weeks ago</span>
                            </p>
                          </Link>
                        </div>
                        <div className="video-action-sec">
                          <Dropdown className="video-action-dropdown resp-padding-xs">
                            <Dropdown.Toggle
                              className="button"
                              id="dropdown-basic"
                            >
                              <FontAwesomeIcon icon={["fas", "ellipsis-v"]} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                <i className="material-icons icon">
                                  playlist_play
                                </i>
                                Add to queue
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                <i className="material-icons icon">
                                  watch_later
                                </i>
                                Save to Watch later
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="material-icons icon">
                                  playlist_add
                                </i>
                                Save to playlist
                              </Dropdown.Item>
                              <hr></hr>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-not-allowed icon"></i>Not
                                interested
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-minus-circle icon"></i>
                                Don't recommend posts form channels
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-flag icon"></i>Report
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={12} xs={12} xl={3} md={6}>
                  <div className="video-list-box">
                    <div className="video-list-img-1">
                      <Link to="#">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/video-1.jpg"
                          }
                          className="img-fluid video-img"
                        />
                      </Link>
                      <div class="img-overlay">
                        <p class="overlay-txt">50+</p>
                        <p class="overlay-txt">
                          <i class="material-icons playlist-icon">
                            playlist_play
                          </i>
                        </p>
                      </div>
                      <div className="hover-play-all">
                        <Link to="#">
                          <i class="icofont-ui-play"></i>
                          <span class="play-all-txt">Play All</span>
                        </Link>
                      </div>
                    </div>
                    <div className="video-list-info">
                      <div className="user-img-sec">
                        <Link to="#">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/img/base-product/profile-img.jpg"
                            }
                            className="user-img"
                          />
                        </Link>
                      </div>
                      <div className="video-list-content">
                        <div className="video-middle-content">
                          <Link to="#">
                            <h3 className="title">
                              Vathikkalu Vellaripravu Video Song | Sufiyum
                              Sujatayum | M Jayachandran | Vijay Babu
                            </h3>
                          </Link>
                          <Link to="#">
                            <h4 className="sub-title">Friday Music Company</h4>
                          </Link>
                          <Link to="#">
                            <p className="desc">
                              <span className="view-count">5M views</span>
                              <span className="upload-time">2 weeks ago</span>
                            </p>
                          </Link>
                        </div>
                        <div className="video-action-sec">
                          <Dropdown className="video-action-dropdown resp-padding-xs">
                            <Dropdown.Toggle
                              className="button"
                              id="dropdown-basic"
                            >
                              <FontAwesomeIcon icon={["fas", "ellipsis-v"]} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                <i className="material-icons icon">
                                  playlist_play
                                </i>
                                Add to queue
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                <i className="material-icons icon">
                                  watch_later
                                </i>
                                Save to Watch later
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="material-icons icon">
                                  playlist_add
                                </i>
                                Save to playlist
                              </Dropdown.Item>
                              <hr></hr>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-not-allowed icon"></i>Not
                                interested
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-minus-circle icon"></i>
                                Don't recommend posts form channels
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-flag icon"></i>Report
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={12} xs={12} xl={3} md={6}>
                  <div className="video-list-box">
                    <div className="hover-content">
                      <ul className="list-unstyled">
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              watch_later
                            </i>
                          </Link>
                        </Media>
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              playlist_play
                            </i>
                          </Link>
                        </Media>
                      </ul>
                    </div>
                    <div className="video-list-img">
                      <Link to="#">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/video-1.jpg"
                          }
                          className="img-fluid video-img"
                        />
                      </Link>
                      <div className="hover-play">
                        <Link to="#">
                          <i className="icofont-ui-play"></i>
                        </Link>
                      </div>
                      <div className="time-count">
                        <h6>16:30</h6>
                      </div>
                    </div>
                    <div className="video-list-info">
                      <div className="user-img-sec">
                        <Link to="#">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/img/base-product/profile-img.jpg"
                            }
                            className="user-img"
                          />
                        </Link>
                      </div>
                      <div className="video-list-content">
                        <div className="video-middle-content">
                          <Link to="#">
                            <h3 className="title">
                              Vathikkalu Vellaripravu Video Song | Sufiyum
                              Sujatayum | M Jayachandran | Vijay Babu
                            </h3>
                          </Link>
                          <Link to="#">
                            <h4 className="sub-title">Friday Music Company</h4>
                          </Link>
                          <Link to="#">
                            <p className="desc">
                              <span className="view-count">5M views</span>
                              <span className="upload-time">2 weeks ago</span>
                            </p>
                          </Link>
                        </div>
                        <div className="video-action-sec">
                          <Dropdown className="video-action-dropdown resp-padding-xs">
                            <Dropdown.Toggle
                              className="button"
                              id="dropdown-basic"
                            >
                              <FontAwesomeIcon icon={["fas", "ellipsis-v"]} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                <i className="material-icons icon">
                                  playlist_play
                                </i>
                                Add to queue
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                <i className="material-icons icon">
                                  watch_later
                                </i>
                                Save to Watch later
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="material-icons icon">
                                  playlist_add
                                </i>
                                Save to playlist
                              </Dropdown.Item>
                              <hr></hr>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-not-allowed icon"></i>Not
                                interested
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-minus-circle icon"></i>
                                Don't recommend posts form channels
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-flag icon"></i>Report
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={12} xs={12} xl={3} md={6}>
                  <div className="video-list-box">
                    <div className="hover-content">
                      <ul className="list-unstyled">
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              watch_later
                            </i>
                          </Link>
                        </Media>
                        <Media as="li">
                          <Link to="#">
                            <i className="material-icons playlist-icon">
                              playlist_play
                            </i>
                          </Link>
                        </Media>
                      </ul>
                    </div>
                    <div className="video-list-img">
                      <Link to="#">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/base-product/video-1.jpg"
                          }
                          className="img-fluid video-img"
                        />
                      </Link>
                      <div className="hover-play">
                        <Link to="#">
                          <i className="icofont-ui-play"></i>
                        </Link>
                      </div>
                      <div className="time-count">
                        <h6>16:30</h6>
                      </div>
                    </div>
                    <div className="video-list-info">
                      <div className="user-img-sec">
                        <Link to="#">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/img/base-product/profile-img.jpg"
                            }
                            className="user-img"
                          />
                        </Link>
                      </div>
                      <div className="video-list-content">
                        <div className="video-middle-content">
                          <Link to="#">
                            <h3 className="title">
                              Vathikkalu Vellaripravu Video Song | Sufiyum
                              Sujatayum | M Jayachandran | Vijay Babu
                            </h3>
                          </Link>
                          <Link to="#">
                            <h4 className="sub-title">Friday Music Company</h4>
                          </Link>
                          <Link to="#">
                            <p className="desc">
                              <span className="view-count">5M views</span>
                              <span className="upload-time">2 weeks ago</span>
                            </p>
                          </Link>
                        </div>
                        <div className="video-action-sec">
                          <Dropdown className="video-action-dropdown resp-padding-xs">
                            <Dropdown.Toggle
                              className="button"
                              id="dropdown-basic"
                            >
                              <FontAwesomeIcon icon={["fas", "ellipsis-v"]} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                <i className="material-icons icon">
                                  playlist_play
                                </i>
                                Add to queue
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                <i className="material-icons icon">
                                  watch_later
                                </i>
                                Save to Watch later
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="material-icons icon">
                                  playlist_add
                                </i>
                                Save to playlist
                              </Dropdown.Item>
                              <hr></hr>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-not-allowed icon"></i>Not
                                interested
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-minus-circle icon"></i>
                                Don't recommend posts form channels
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                <i className="icofont-flag icon"></i>Report
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="space-sm"></div>
              <Row>
                <Col sm={12} md={12}>
                  <div className="show-more">
                    <Link to="#">
                      <i className="icofont-rounded-down"></i>
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LandingPage;
