import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Dropdown,
  Image,
  Media,
  Badge,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import configuration from "react-global-configuration";
import { connect } from "react-redux";
import {
  fetchBellNotificationStart,
  fetchNotificationCountStart,
} from "../../../store/actions/BellNotificationAction";
import ImageLoader from "../../helper/ImageLoader";
import { fetchSearchDataStart } from "../../../store/actions/CoreDataAction";
import NoResultFoundSmall from "../../NoResultFoundSmall/NoResultFoundSmall";

class HeaderIndex extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    mobileSidebar: false,
    searchDropdown: false,
    searchKey: "",
  };

  componentDidMount() {
    if (localStorage.getItem("userId") && localStorage.getItem("accessToken"))
      if (this.props.notification.notiCount.loading) {
        this.props.dispatch(fetchNotificationCountStart());
      }
  }

  fetchNotification = (event) => {
    if (this.props.notification.notification.loading)
      this.props.dispatch(fetchBellNotificationStart());
  };

  toggleMobileSidebar = () => {
    this.setState({
      mobileSidebar: !this.state.mobileSidebar,
    });
  };

  toggleSearchDropdown = () => {
    this.setState({
      searchDropdown: !this.state.searchDropdown,
    });
  };

  handleSearch = ({ currentTarget: input }) => {
    this.setState({
      searchKey: input.value,
      searchDropdown: true,
    });
    this.props.dispatch(fetchSearchDataStart({ key: input.value }));
    setTimeout(() => {
      if (this.state.searchKey == "") this.setState({ searchDropdown: false });
    }, 500);
  };

  searchSubmit = (event) => {
    event.preventDefault();
    this.props.history.push("/search", { searchKey: this.state.searchKey });
    this.setState({
      searchDropdown: !this.state.searchDropdown,
    });
  };

  render() {
    const { notification } = this.props;
    return (
      <>
        <div className="header">
          <Container>
            <Navbar expand="lg">
              <Link to="/" className="lg-logo">
                <Image
                  src={configuration.get("configData.site_icon")}
                  className="logo"
                />
              </Link>
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                onClick={() => this.toggleMobileSidebar()}
              />
              <Link className="resp-sm-md-block mobile-logo" to="/">
                <Image
                  src={configuration.get("configData.site_icon")}
                  className="logo"
                />
              </Link>

              <Navbar.Collapse
                id="basic-navbar-nav"
                className="display-sm-md-none"
              >
                <Nav className="mr-auto">
                  <Nav.Link>
                    <Link to="/premium">Premium</Link>
                  </Nav.Link>
                  <Nav.Link>
                    <Link to="/browse-channels">Channels</Link>
                  </Nav.Link>
                  <Form
                    className="header-form-search-bar"
                    onSubmit={this.searchSubmit}
                  >
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon="search" />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        aria-label=""
                        placeholder="Search"
                        name="search"
                        onChange={this.handleSearch}
                        value={this.state.searchKey}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text>
                          <Image
                            src={
                              window.location.origin + "/assets/img/video.png"
                            }
                            className="mr-3 video-icon"
                          />

                          <FontAwesomeIcon icon="chevron-down" />
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form>
                  <div
                    className="search-dropdown-sec"
                    style={{
                      display: this.state.searchDropdown ? "block" : "none",
                    }}
                  >
                    <ul className="list-unstyled search-dropdown-list-sec">
                      {this.props.searchData.loading ? (
                        "Loading..."
                      ) : this.props.searchData.data.length > 0 ? (
                        this.props.searchData.data.map((result) => (
                          <Media as="li">
                            <Link
                              to={`/single-video/${result.video_tape_id}`}
                              onClick={this.toggleSearchDropdown}
                            >
                              <div className="search-body">
                                <div className="user-img-sec">
                                  <ImageLoader
                                    image={result.video_image}
                                    className="user-img"
                                  />
                                </div>
                                <div className="search-content">
                                  <h5>{result.title}</h5>
                                  <p>{result.publish_time}</p>
                                </div>
                              </div>
                            </Link>
                          </Media>
                        ))
                      ) : (
                        <NoResultFoundSmall />
                      )}
                    </ul>
                  </div>
                </Nav>

                {localStorage.getItem("userId") &&
                localStorage.getItem("accessToken") ? (
                  <Nav className="ml-auto">
                    <div className="notify-bell-sec">
                      <Dropdown className="notify-bell-dropdown">
                        <Dropdown.Toggle
                          className="notify-bell-dropdown"
                          id="dropdown-basic"
                        >
                          <FontAwesomeIcon
                            icon={["fas", "bell"]}
                            className="bell-icon"
                            onClick={this.fetchNotification}
                          />
                          <Badge pill variant="danger" className="notify-count">
                            {" "}
                            {notification.notiCount.loading
                              ? ""
                              : notification.notiCount.data.count}
                          </Badge>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="notify-bell-dropdown-menu">
                          <div className="notify-header">
                            <h4>Notification</h4>
                          </div>
                          {notification.notification.loading ? (
                            " Loading..."
                          ) : notification.notification.data.length > 0 ? (
                            notification.notification.data.map((noti) => (
                              <Link to="#">
                                <div className="notify-body">
                                  <div className="user-img-sec">
                                    <ImageLoader
                                      image={noti.picture}
                                      className="user-img"
                                    />
                                  </div>
                                  <div className="notify-content">
                                    <h5>{noti.message}</h5>
                                    <p>{noti.created}</p>
                                  </div>
                                </div>
                              </Link>
                            ))
                          ) : (
                            <NoResultFoundSmall />
                          )}

                          <Dropdown.Item
                            className="text-center btn-view-all"
                            href="#"
                          >
                            <Link to={"/notification/viewall"}>View All</Link>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="user-img-sec">
                      <Image
                        src={localStorage.getItem("user_picture")}
                        className="user-img"
                      />
                      <div className="status-online"></div>
                    </div>
                    <NavDropdown
                      className="user-dropdown"
                      title={localStorage.getItem("username")}
                      id="basic-nav-dropdown"
                    >
                      <NavDropdown.Item>
                        <Link to={"/settings"}>
                          <i class="icofont-settings mr-2"></i>Settings
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#">
                        <Link to={"/profile"}>
                          <i class="icofont-user-alt-4 mr-2"></i>Profile
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#">
                        <Link to={"/logout"}>
                          <i class="icofont-logout mr-2"></i>Logout
                        </Link>
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                ) : (
                  <Nav className="ml-auto">
                    <Link to="/signup" className="btn btn-sm signup-btn mr-2">
                      Signup
                    </Link>
                    <Link to="/signin" className="btn btn-sm signin-btn">
                      Signin
                    </Link>
                  </Nav>
                )}
              </Navbar.Collapse>
            </Navbar>
          </Container>
          <div
            className="mobile-side-nav"
            style={{
              display: this.state.mobileSidebar ? "block" : "none",
            }}
          >
            <div className="side-nav-header">
              <Link to="#" className="back-home">
                <FontAwesomeIcon icon="chevron-left" />
              </Link>
              <div className="side-nav-mobile-logo" href="/">
                <Image
                  src={configuration.get("configData.site_icon")}
                  className="logo"
                />
              </div>
            </div>
            {localStorage.getItem("userLoginStatus") ? 
            <Nav className="side-nav-sub-header">
              <div className="user-img-sec">
                <Image
                  src={localStorage.getItem("user_picture")}
                  className="user-img"
                />
                <div className="status-online"></div>
                <h4 className="user-name">
                  {localStorage.getItem("user_name")}
                </h4>
              </div>
              <div className="mobile-dropdown-icon">
                <FontAwesomeIcon icon="chevron-down" className="ml-2" />
              </div>
            </Nav>
            : ''}
            {/* <div className="mobile-video-upload-btn">
              <Link to="/upload-video">
                <Button className="mobile-upload-btn btn-block">
                  <Image
                    src={window.location.origin + "/assets/img/video-2.png"}
                    className="mr-3"
                  />
                  Upload Video
                </Button>
              </Link>
            </div> */}
            {localStorage.getItem("userLoginStatus") ? 
            <>
            <ul className="list-unstyled mobile-menu-sec">
              <Media as="Media" active>
                <Link to="#">
                  <FontAwesomeIcon
                    icon={["fas", "play-circle"]}
                    className="mr-3 mobile-menu"
                  />
                  Popular Videos
                </Link>
              </Media>
              <Media as="Media">
                <Link to="/browse-categories">
                  <FontAwesomeIcon
                    icon={["fas", "bars"]}
                    className="mr-3 mobile-menu"
                  />
                  Browse Categories{" "}
                  <FontAwesomeIcon
                    icon="chevron-down"
                    className="mobile-right"
                  />
                </Link>
              </Media>
              <Media as="Media">
                <Link to="/wishlist" className="active">
                  <FontAwesomeIcon
                    icon={["fas", "heart"]}
                    className="mr-3 mobile-menu"
                  />
                  Liked Videos
                </Link>
              </Media>
              <Media as="Media">
                <Link to="#">
                  <FontAwesomeIcon
                    icon={["fas", "hourglass-end"]}
                    className="mr-3 mobile-menu"
                  />
                  History
                </Link>
              </Media>
              <Media as="Media">
                <Link to="#">
                  <FontAwesomeIcon
                    icon={["fab", "product-hunt"]}
                    className="mr-3 mobile-menu"
                  />
                  Purchased Videos
                </Link>
              </Media>
            </ul>
            <div className="logout-btn-sec">
              <Link to="/signin" className="logout-btn btn-block">
                Logout
              </Link>
            </div>
            </>
            : 
            <div className="logout-btn-sec">
              <Link to="/signin" className="logout-btn btn-block">
                Signin
              </Link>

            </div>
            }
          </div>
          {/* <div className="top-video-fixed">
            <Link to="/upload-video">
              <Image src={window.location.origin + "/assets/img/video-2.png"} />
            </Link>
          </div> */}
        </div>
        <div className="sub-header">
          <Container>
            <Row>
              <Col xs={12} md={6} xl={5}>
                <div className="sub-header-content">
                  <div className="sub-header-title d-none d-sm-block">
                    <h4>Go to:</h4>
                  </div>
                  <div className="sub-header-list-icons-sec">
                    <ul className="list-unstyled sub-header-list-icons">
                      <Media as="li">
                        <Link to="/wishlist">
                          <Image
                            src={
                              window.location.origin + "/assets/img/like.png"
                            }
                            className="icons"
                          />
                        </Link>
                      </Media>

                      <Media as="li">
                        <Link to="/playlists">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/img/playlist.png"
                            }
                            className="icons"
                          />
                        </Link>
                      </Media>
                      <Media as="li">
                        <Link to="/livetv">
                          <FontAwesomeIcon
                            icon={["fas", "tv"]}
                            className="icons text-muted fa-lg"
                          />
                        </Link>
                      </Media>
                      <Media as="li">
                        <Link to="/broadcasts">
                          <FontAwesomeIcon
                            icon={["fas", "podcast"]}
                            className="icons text-muted fa-2x"
                          />
                        </Link>
                      </Media>
                      <Media as="li">
                        <Link to="/purchased-videos">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/img/purchased.png"
                            }
                            className="icons"
                          />
                        </Link>
                      </Media>
                      <Media as="li">
                        <Link to="/my-history">
                          <Image
                            src={
                              window.location.origin + "/assets/img/history.png"
                            }
                            className="icons"
                          />
                        </Link>
                      </Media>
                    </ul>
                  </div>
                </div>
              </Col>
              {/* <Col xs={12} md={5} xl={7} className="d-none d-sm-block">
                <div className="resume-play-sec">
                  <Link to="/single-video-simplified">
                    <h4>
                      <Image
                        src={window.location.origin + "/assets/img/play.png"}
                        className="mr-3 icons"
                      />
                      Resume: <span>Daredevil Season 2 : Episode 6</span>
                    </h4>
                  </Link>
                </div>
              </Col> */}
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToPros = (state) => ({
  notification: state.notification,
  searchData: state.core.search,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(HeaderIndex);
