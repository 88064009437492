import React from "react";
import { Col, InputGroup, Tab, Image, Media, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import ImageLoader from "./ImageLoader";

const ChannelCard = (props) => {
  const { channel } = props;
  return (
    <Col sm={12} md={6} xl={3}>
      <Link to={`channel/${channel.channel_id}`}>
        <div className="browse-channels-card">
          <div className="browse-channels-bg">
            <ImageLoader
              image={channel.channel_cover}
              className="browse-bg-img"
            />
            {/* <Image src={channel.channel_cover} className="browse-bg-img" /> */}
          </div>
          <div className="browse-channels-card-info">
            <ImageLoader
              image={channel.channel_image}
              className="browse-user-img"
            />
            {/* <Image src={channel.channel_image} className="browse-user-img" /> */}
            <h4 className="title">{channel.channel_name}</h4>
            <p className="desc">{channel.no_of_subscribers} Subscribers</p>
            <p className="desc">{channel.no_of_videos} Videos</p>
            {/* <p className="desc">10 Million Views</p> */}
            <p className="desc">
              <Button className="subscribe-btn">Subscribe</Button>
              {/* <span className="percentage-count green-clr">
                <FontAwesomeIcon icon="circle" className="mr-2" />
                93%
              </span> */}
            </p>
          </div>
        </div>
      </Link>
    </Col>
  );
};

export default ChannelCard;
