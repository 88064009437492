import {
  FETCH_VIDEO_HISTORY_START,
  FETCH_VIDEO_HISTORY_SUCCESS,
  FETCH_VIDEO_HISTORY_FAILURE,
  ADD_VIDEO_HISTORY_START,
  ADD_VIDEO_HISTORY_SUCCESS,
  ADD_VIDEO_HISTORY_FAILURE,
  REMOVE_VIDEO_HISTORY_START,
  REMOVE_VIDEO_HISTORY_SUCCESS,
  REMOVE_VIDEO_HISTORY_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  historyVideo: {
    data: {},
    loading: true,
    error: false,
  },
  addVideoHistory: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  removeVideoHistory: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
};

const VideoHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VIDEO_HISTORY_START:
      return {
        ...state,
        historyVideo: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_VIDEO_HISTORY_SUCCESS:
      return {
        ...state,
        historyVideo: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_VIDEO_HISTORY_FAILURE:
      return {
        ...state,
        historyVideo: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case ADD_VIDEO_HISTORY_START:
      return {
        ...state,
        addVideoHistory: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case ADD_VIDEO_HISTORY_SUCCESS:
      return {
        ...state,
        addVideoHistory: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case ADD_VIDEO_HISTORY_FAILURE:
      return {
        ...state,
        addVideoHistory: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case REMOVE_VIDEO_HISTORY_START:
      return {
        ...state,
        removeVideoHistory: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case REMOVE_VIDEO_HISTORY_SUCCESS:
      return {
        ...state,
        removeVideoHistory: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case REMOVE_VIDEO_HISTORY_FAILURE:
      return {
        ...state,
        removeVideoHistory: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    default:
      return state;
  }
};

export default VideoHistoryReducer;
