import {
  FETCH_SPAM_VIDEOS_START,
  FETCH_SPAM_VIDEOS_SUCCESS,
  FETCH_SPAM_VIDEOS_FAILURE,
  ADD_TO_SPAM_START,
  ADD_TO_SPAM_SUCCESS,
  ADD_TO_SPAM_FAILURE,
  REMOVE_SPAM_START,
  REMOVE_SPAM_SUCCESS,
  REMOVE_SPAM_FAILURE,
  FETCH_SPAM_REASON_START,
  FETCH_SPAM_REASON_SUCCESS,
  FETCH_SPAM_REASON_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  spamVideos: {
    data: {},
    loading: true,
    error: false,
  },
  addSpam: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  removeSpam: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  spamReason: {
    data: {},
    loading: true,
    error: false,
  },
  buttonDisable: false,
  loadingButtonContent: null,
};

const SpamReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SPAM_VIDEOS_START:
      return {
        ...state,
        spamVideos: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_SPAM_VIDEOS_SUCCESS:
      return {
        ...state,
        spamVideos: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_SPAM_VIDEOS_FAILURE:
      return {
        ...state,
        spamVideos: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case ADD_TO_SPAM_START:
      return {
        ...state,
        addSpam: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
        buttonDisable: true,
        loadingButtonContent: "Loading...",
      };
    case ADD_TO_SPAM_SUCCESS:
      return {
        ...state,
        addSpam: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
        buttonDisable: false,
        loadingButtonContent: null,
      };
    case ADD_TO_SPAM_FAILURE:
      return {
        ...state,
        addSpam: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
        buttonDisable: false,
        loadingButtonContent: null,
      };

    case REMOVE_SPAM_START:
      return {
        ...state,
        removeSpam: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
        buttonDisable: true,
        loadingButtonContent: "Loading...",
      };
    case REMOVE_SPAM_SUCCESS:
      return {
        ...state,
        removeSpam: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
        buttonDisable: false,
        loadingButtonContent: null,
      };
    case REMOVE_SPAM_FAILURE:
      return {
        ...state,
        removeSpam: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
        buttonDisable: false,
        loadingButtonContent: null,
      };

    case FETCH_SPAM_REASON_START:
      return {
        ...state,
        spamReason: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_SPAM_REASON_SUCCESS:
      return {
        ...state,
        spamReason: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_SPAM_REASON_FAILURE:
      return {
        ...state,
        spamReason: {
          data: {},
          loading: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default SpamReducer;
