import React from "react"
import ContentLoader from "react-content-loader"

const SubscriptionListLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1350}
    height={1000}
    viewBox="0 0 1200 1000"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="15" rx="5" ry="5" width="1200" height="56" /> 
    <rect x="0" y="97" rx="5" ry="5" width="380" height="380" /> 
    <rect x="410" y="97" rx="5" ry="5" width="380" height="380" /> 
    <rect x="820" y="97" rx="5" ry="5" width="380" height="380" /> 
    <rect x="0" y="493" rx="5" ry="5" width="380" height="380" /> 
    <rect x="410" y="493" rx="5" ry="5" width="380" height="380" /> 
    <rect x="820" y="493" rx="5" ry="5" width="380" height="380" /> 
    <rect x="0" y="902" rx="10" ry="10" width="1200" height="73" />
  </ContentLoader>
)

export default SubscriptionListLoader;