import { all, fork } from "redux-saga/effects";

import UserSaga from "./UserSaga";
import ChangePasswordSaga from "./ChangePasswordSaga";
import SubscriptionSaga from "./SubscriptionSaga";
import CardsSaga from "./CardsSaga";
import ErrorSaga from "./ErrorSaga";
import PageSaga from "./PageSaga";
import ChannelPlayListSaga from "./ChannelPlayListSaga";
import ReferralSaga from "./ReferralSaga";
import WishListSaga from "./WishListSaga";
import SpamSaga from "./SpamSaga";
import VideoHistorySaga from "./VideoHistorySaga";
import ChannelSaga from "./ChannelSaga";
import BellNotificationSaga from "./BellNotificationSaga";
import ViewerPlayListSaga from "./ViewerPlayListSaga";
import PPVSaga from "./PPVSaga";
import TagSaga from "./TagSaga";
import CategorySaga from "./CategorySaga";
import CoreDataSaga from "./CoreDataSaga";
import RedeemSaga from "./RedeemSaga";
import LiveTvSaga from "./LiveTvSaga";
import SingleVideoSaga from "./SingleVideoSaga";
import LiveStreamingSaga from "./LiveStreamingSaga";
import VideoManagementSaga from "./VideoManagementSaga";

export default function* rootSaga() {
  yield all([fork(UserSaga)]);
  yield all([fork(ChangePasswordSaga)]);
  yield all([fork(SubscriptionSaga)]);
  yield all([fork(CardsSaga)]);
  yield all([fork(ErrorSaga)]);
  yield all([fork(PageSaga)]);
  yield all([fork(ReferralSaga)]);
  yield all([fork(WishListSaga)]);
  yield all([fork(SpamSaga)]);
  yield all([fork(VideoHistorySaga)]);
  yield all([fork(ChannelSaga)]);
  yield all([fork(BellNotificationSaga)]);
  yield all([fork(RedeemSaga)]);
  yield all([fork(CoreDataSaga)]);
  yield all([fork(CategorySaga)]);
  yield all([fork(TagSaga)]);
  yield all([fork(PPVSaga)]);
  yield all([fork(ViewerPlayListSaga)]);
  yield all([fork(ChannelPlayListSaga)]);
  yield all([fork(LiveTvSaga)]);
  yield all([fork(LiveStreamingSaga)]);
  yield all([fork(SingleVideoSaga)]);
  yield all([fork(VideoManagementSaga)]);
}
