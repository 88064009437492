import React, { Component } from "react";
import { createBrowserHistory as createHistory } from "history";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import HomeIndex from "../Home/HomeIndex";
import SingleVideoAbout from "../SingleVideoAbout/SingleVideoAbout";
import SingleVideoSimplified from "../backups/BrowseCategoriesSideMenu/SingleVideoSimplified/SingleVideoSimplified";
import VideoWithPlaylist from "../VideoWithPlaylist/VideoWithPlaylist";
import UploadVideo from "../UploadVideo/UploadVideo";
import UploadVideoEdit from "../UploadVideoEdit/UploadVideoEdit";
import BrowseChannels from "../BrowseChannels/BrowseChannels";
import SearchedVideos from "../SearchedVideos/SearchedVideos";
import SingleChannel from "../SingleChannel/SingleChannel";
import MyHistory from "../MyHistory/MyHistory";
import BrowseCategories from "../BrowseCategories/BrowseCategories";
import BrowseCategoriesSideMenu from "../backups/BrowseCategoriesSideMenu/BrowseCategoriesSideMenu";
import Subscription from "../Subscription/Subscription";
import SignUp from "../SignUp/SignUp";
import SignIn from "../SignIn/SignIn";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import NewHeader from "../layouts/header/NewHeader";
import StaticPage from "../StaticPage/StaticPage";
import PaymentFailure from "../PaymentFailure/PaymentFailure";
import PaymentSuccess from "../PaymentSuccess/PaymentSuccess";
import SubscriptionList from "../SubscriptionList/SubscriptionList";
import PPVInvoicePage from "../InvoicePage/PPVInvoicePage";
import MyPlan from "../MyPlan/MyPlan";
import Settings from "../Settings/Settings";
import Referrals from "../Referrals/Referrals";
import CardsList from "../Accounts/Cards/CardsList";
import ChangePasswordIndex from "../Accounts/ChangePassword/ChangePasswordIndex";
import DeleteAccountIndex from "../Accounts/DeleteAccount/DeleteAccountIndex";
import ProfileIndex from "../Accounts/Profile/ProfileIndex";
import EditProfile from "../Accounts/Profile/EditProfile";
import Redeems from "../Redeems/Redeems";
import VideoLists from "../VideoLists/VideoLists";
import Logout from "../Auth/Logout";
import PPVHistory from "../PPVHistory/PPVHistory";
import SubscribedChannels from "../SubscribedChannels/SubscribedChannels";
import ErrorPage1 from "../backups/BrowseCategoriesSideMenu/ErrorPage1/ErrorPage1";
import ErrorPage2 from "../backups/BrowseCategoriesSideMenu/ErrorPage2/ErrorPage2";
import NoResultFound from "../NoResultFound/NoResultFound";
import ErrorPage from "../ErrorPage/ErrorPage";
import SubscriptionInvoiceNew from "../SubscriptionInvoiceNew/SubscriptionInvoiceNew";
import Welcome from "../Welcome/Welcome";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import { apiConstants } from "../Constant/constants";
import SubscriptionInvoice from "../InvoicePage/SubscriptionInvoice";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import WishList from "../WishList/WishList";
import SpamVideos from "../SpamVideos/SpamVideos";
import CreateChannel from "../CreateChannel/CreateChannel";
import PlayList from "../Playlist/Playlist";
import PlayListSingle from "../Playlist/PlayListSingle";
import PlayListView from "../PlayListView/PlayListView";
import LiveTvIndex from "../LiveTV/LiveTVIndex";
import BroadCasting from "../BroadCasting/BroadCasting";
import SingleLiveTv from "../LiveTV/SingleLiveTv";
import ChannelStudio from "../Studio/ChannelStudio";
import NotificationViewAll from "../NotificationViewAll/NotificationViewAll";
import ChannelList from "../SingleChannel/ChannelList";
import LiveVideo from "../LiveVideo/LiveVideo";
import SingleLiveVideo from "../LiveVideo/SingleLiveVideo";
import LandingPage from "../BaseProduct/LandingPage";
import Trending from "../BaseProduct/Trending";
import SubscriptionVideo from "../BaseProduct/SubscriptionVideo";
import Premium from "../Home/Premium";
import PlayListNew from "../BaseProduct/PlayListNew";
import HistoryNew from "../BaseProduct/HistoryNew";
import ChannelView from "../BaseProduct/ChannelView";
import VideoSingle from "../BaseProduct/VideoSingle";
import SingleChannelOwner from "../SingleChannel/SingleChannelOwner";
import LSConfirmation from "../BroadCasting/LSConfirmation";
import LiveVideosUserIndex from "../BroadCasting/Users/LiveVideosUserIndex";
import OwnerChannelView from "../Channels/Owner/OwnerChannelView";
import UserChannelView from "../Channels/User/UserChannelView";
import OwnerChannelList from "../Channels/Owner/OwnerChannelList";
import LiveStreamingIndex from "../BroadCasting/LiveStreamingIndex";
import BroadCastPublish from "../BroadCasting/BroadCastPublish";
import BroadCastPlay from "../BroadCasting/BroadCastPlay";

const history = createHistory();
const $ = window.$;

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout screenProps={ScreenProps} {...props}>
        <Component {...props} />
      </Layout>
    )}
    isAuthed
  />
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  authentication,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      authentication === true ? (
        <Layout screenProps={ScreenProps}>
          <Component {...props} authRoute={true} />
        </Layout>
      ) : (
        <Redirect
          to={{ pathname: "/signin", state: { from: props.location } }}
        />
      )
    }
  />
);

class App extends Component {
  constructor(props) {
    super(props);
    let userId = localStorage.getItem("userId");
    let accessToken = localStorage.getItem("accessToken");
    this.state = {
      loading: true,
      configLoading: true,
      authentication: userId && accessToken ? true : false,
    };

    history.listen((location, action) => {
      userId = localStorage.getItem("userId");

      accessToken = localStorage.getItem("accessToken");

      this.setState({
        loading: true,
        authentication: userId && accessToken ? true : false,
      });

      document.body.scrollTop = 0;
    });
  }

  componentDidMount() {
    this.fetchConfig();
  }

  async fetchConfig() {
    try {
      const response = await fetch(apiConstants.settingsUrl);
      const configValue = await response.json();

      configuration.set({ configData: configValue.data }, { freeze: false });
      this.setState({ configLoading: false });
    } catch (error) {
      configuration.set({ configData: [] }, { freeze: false });
      this.setState({ configLoading: false });
    }

    // $("#google_analytics").html(
    //   configuration.get("configData.google_analytics")
    // );

    // $("#header_scripts").html(configuration.get("configData.header_scripts"));

    // $("#body_scripts").html(configuration.get("configData.body_scripts"));
  }

  render() {
    const isLoading = this.state.configLoading;

    if (isLoading) {
      return (
        // Place content loadder here
        <div>{/* <HomeLoader></HomeLoader> */}</div>
      );
    }

    return (
      <>
        <Helmet>
          <title>{configuration.get("configData.site_name")}</title>
          <link
            rel="icon"
            type="image/png"
            href={configuration.get("configData.site_icon")}
            sizes="16x16"
          />
          <link
            rel="apple-touch-icon"
            href={configuration.get("configData.site_icon")}
          />
          <meta
            name="description"
            content={configuration.get("configData.meta_description")}
          ></meta>
          <meta
            name="keywords"
            content={configuration.get("configData.meta_keywords")}
          ></meta>
          <meta
            name="author"
            content={configuration.get("configData.meta_author")}
          ></meta>
        </Helmet>

        <Switch>
          <AppRoute path={"/signup"} component={SignUp} layout={MainLayout} />
          <AppRoute path={"/signin"} component={SignIn} layout={MainLayout} />
          <AppRoute
            path={"/forgot-password"}
            component={ForgotPassword}
            layout={MainLayout}
          />

          <AppRoute
            path={"/"}
            component={HomeIndex}
            exact
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/settings"}
            component={Settings}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/profile"}
            component={ProfileIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/edit-profile"}
            component={EditProfile}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/change-password"}
            component={ChangePasswordIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/delete-account"}
            component={DeleteAccountIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/cards-list"}
            component={CardsList}
            layout={MainLayout}
          />

          <AppRoute
            path={"/browse-channels"}
            component={BrowseChannels}
            layout={MainLayout}
          />
          <AppRoute
            path={"/search"}
            component={SearchedVideos}
            layout={MainLayout}
          />
          <AppRoute
            path={"/channel/:unique_id"}
            component={UserChannelView}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/my-channel/:unique_id"}
            component={OwnerChannelView}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/my-history"}
            component={MyHistory}
            layout={MainLayout}
          />
          <AppRoute
            path={"/browse-categories"}
            component={BrowseCategories}
            layout={MainLayout}
          />
          <AppRoute path={"/premium"} component={Premium} layout={MainLayout} />
          <AppRoute
            path={"/browse-categories-side-menu"}
            component={BrowseCategoriesSideMenu}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/subscription-todo"}
            component={Subscription}
            layout={MainLayout}
          />
          <AppRoute
            path={"/payment-failure"}
            component={PaymentFailure}
            layout={MainLayout}
          />
          <AppRoute
            path={"/payment-success"}
            component={PaymentSuccess}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/plans"}
            component={SubscriptionList}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/plan-invoice/:id"}
            component={SubscriptionInvoice}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/my-plans"}
            component={MyPlan}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/ppv-invoice/:video_tape_id"}
            component={PPVInvoicePage}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/referrals"}
            component={Referrals}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/redeems"}
            component={Redeems}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/purchased-videos"}
            component={PPVHistory}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/subscribed-channels"}
            component={SubscribedChannels}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/wishlist"}
            component={WishList}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/spam-videos"}
            component={SpamVideos}
            layout={MainLayout}
          />

          <AppRoute
            path={"/channelowner-video-lists/:channel_id"}
            component={VideoLists}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/create-channel"}
            component={CreateChannel}
            layout={MainLayout}
          />
          <AppRoute
            path={"/livetv/:unique_id"}
            component={SingleLiveTv}
            layout={MainLayout}
          />

          <AppRoute
            path={"/livetv"}
            component={LiveTvIndex}
            layout={MainLayout}
          />

          <AppRoute
            path={"/broadcast-videos"}
            component={BroadCasting}
            layout={MainLayout}
          />

          <AppRoute
            path={"/broadcasts"}
            component={LiveVideosUserIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/broadcasting/:live_video_id"}
            component={BroadCastPublish}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/broadcast-view/:live_video_id"}
            component={BroadCastPlay}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/livestreaming-updated/:live_video_id"}
            component={LiveStreamingIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/livestreaming-confirmation"}
            component={LSConfirmation}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/playlists"}
            component={PlayList}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/playlist/:id"}
            component={PlayListSingle}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/channel-studio"}
            component={ChannelStudio}
            layout={MainLayout}
          />

          <AppRoute
            path={"/page/:title"}
            component={StaticPage}
            layout={MainLayout}
          />

          <AppRoute path={"/logout"} component={Logout} layout={MainLayout} />

          <AppRoute path={"/404"} component={ErrorPage1} layout={MainLayout} />
          <AppRoute path={"/500"} component={ErrorPage2} layout={MainLayout} />

          <AppRoute
            path={"/new-header"}
            component={NewHeader}
            layout={MainLayout}
          />
          <AppRoute
            path={"/single-video/:id"}
            component={SingleVideoAbout}
            layout={MainLayout}
          />
          <AppRoute
            path={"/single-video-simplified"}
            component={SingleVideoSimplified}
            layout={MainLayout}
          />
          <AppRoute
            path={"/video-with-playlist"}
            component={VideoWithPlaylist}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/upload-video/:channel_id"}
            component={UploadVideo}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/upload-video-edit"}
            component={UploadVideoEdit}
            layout={MainLayout}
          />

          <AppRoute
            path={"/notification/viewall"}
            component={NotificationViewAll}
            layout={MainLayout}
          />

          <AppRoute
            path={"/channel-list"}
            component={OwnerChannelList}
            layout={MainLayout}
          />

          <AppRoute
            path={"/live-video"}
            component={LiveVideo}
            layout={MainLayout}
          />

          <AppRoute
            path={"/single-live-video/:unique_id"}
            component={SingleLiveVideo}
            layout={MainLayout}
          />

          <AppRoute
            path={"/playlist/view"}
            component={PlayListView}
            layout={MainLayout}
          />

          <AppRoute
            path={"/landing-page"}
            component={LandingPage}
            layout={MainLayout}
          />

          <AppRoute
            path={"/trending"}
            component={Trending}
            layout={MainLayout}
          />

          <AppRoute
            path={"/subscription-video"}
            component={SubscriptionVideo}
            layout={MainLayout}
          />

          <AppRoute
            path={"/playlist-new"}
            component={PlayListNew}
            layout={MainLayout}
          />

          <AppRoute
            path={"/history-new"}
            component={HistoryNew}
            layout={MainLayout}
          />

          <AppRoute
            path={"/channel-view"}
            component={ChannelView}
            layout={MainLayout}
          />

          <AppRoute
            path={"/video-single"}
            component={VideoSingle}
            layout={MainLayout}
          />

          <Route path="*" component={NotFoundPage} />
        </Switch>
      </>
    );
  }
}

export default App;
