import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  fetchVideoHistorySuccess,
  fetchVideoHistoryFailure,
  addVideoHistorySuccess,
  addVideoHistoryFailure,
  removeVideoHistoryFailure,
  removeVideoHistorySuccess,
  fetchVideoHistoryStart,
} from "../actions/VideoHistoryAction";
import {
  REMOVE_VIDEO_HISTORY_START,
  ADD_VIDEO_HISTORY_START,
  FETCH_VIDEO_HISTORY_START,
} from "../actions/ActionConstant";

function* fetchVideoHistoryAPI() {
  try {
    const response = yield api.postMethod("video_tapes_history");
    if (response.data.success) {
      yield put(fetchVideoHistorySuccess(response.data.data));
    } else {
      yield put(fetchVideoHistoryFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchVideoHistoryFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* addVideoHistoryAPI() {
  try {
    const inputData = yield select(
      (state) => state.videoHistory.addVideoHistory.inputData
    );
    const response = yield api.postMethod("video_tapes_history_add", inputData);
    if (response.data.success) {
      yield put(addVideoHistorySuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(addVideoHistoryFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(addVideoHistoryFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* removeVideoHistoryAPI() {
  try {
    const inputData = yield select(
      (state) => state.videoHistory.removeVideoHistory.inputData
    );
    const response = yield api.postMethod(
      "video_tapes_history_remove",
      inputData
    );
    if (response.data.success) {
      yield put(removeVideoHistorySuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      yield put(fetchVideoHistoryStart());
    } else {
      yield put(removeVideoHistoryFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(removeVideoHistoryFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_VIDEO_HISTORY_START, fetchVideoHistoryAPI),
  ]);
  yield all([yield takeLatest(ADD_VIDEO_HISTORY_START, addVideoHistoryAPI)]);
  yield all([
    yield takeLatest(REMOVE_VIDEO_HISTORY_START, removeVideoHistoryAPI),
  ]);
}
