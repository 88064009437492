import {
  FETCH_USER_DETAILS_START,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
  EDIT_USER_DETAILS,
  UPDATE_USER_DETAILS_START,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAILURE,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTER_START,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  GET_LOGIN_DETAILS,
  GET_REGISTER_DETAILS,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  DELETE_ACCOUNT_START,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
  GET_FORGOT_PASSWORD_DETAILS,
} from "../actions/ActionConstant";

const initialState = {
  profile: {
    data: {},
    loading: true,
    error: false,
  },
  profileInputData: {
    data: {},
    loading: true,
    error: false,
  },
  buttonDisable: false,
  loadingButtonContent: null,
  loginInputData: {
    data: {},
    loading: true,
    error: false,
  },
  registerInputData: {
    data: {},
    loading: true,
    error: false,
  },
  forgotPasswordInputData: {
    data: {},
    loading: true,
    error: false,
  },
  deleteAccount: {
    data: {},
    loading: true,
    error: false,
  },
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_DETAILS_START:
      return {
        ...state,
        profile: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_USER_DETAILS_SUCCESS:
      return {
        ...state,
        profile: {
          data: action.data.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_USER_DETAILS_FAILURE:
      return {
        ...state,
        profile: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case EDIT_USER_DETAILS:
      return {
        ...state,
        profile: {
          loading: false,
          error: false,
          data: {
            ...state.profile.data,
            [action.name]: action.value,
          },
        },
      };
    case UPDATE_USER_DETAILS_START:
      console.log("profile data ", state.profile.data);
      return {
        ...state,
        profileInputData: {
          data: {
            name: state.profile.data.name,
            email: state.profile.data.email,
            description: state.profile.data.description,
            mobile:
              state.profile.data.mobile != null
                ? state.profile.data.mobile
                : "",
            dob: state.profile.data.dob != null ? state.profile.data.dob : "",
            address: state.profile.data.address,
            picture: action.data != null ? action.data : "",
          },
        },
        buttonDisable: true,
        loadingButtonContent: "Loading please wait",
      };

    case UPDATE_USER_DETAILS_SUCCESS:
      return {
        ...state,
        profile: {
          data: action.data.data,
        },
        buttonDisable: false,
        loadingButtonContent: null,
      };
    case UPDATE_USER_DETAILS_FAILURE:
      return {
        ...state,
        profile: {
          data: state.profile.data,
          loading: false,
          error: action.error,
        },
        buttonDisable: false,
        loadingButtonContent: null,
      };

    case GET_LOGIN_DETAILS:
      return {
        ...state,
        loginInputData: {
          loading: false,
          error: false,
          data: {
            ...state.loginInputData.data,
            [action.name]: action.value,
          },
        },
      };
    case LOGIN_START:
      return {
        ...state,
        loginInputData: {
          data: action.data,
        },
        buttonDisable: true,
        loadingButtonContent: "Loading please wait",
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        profile: {
          data: action.data.data,
        },
        buttonDisable: false,
        loadingButtonContent: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        profile: {
          data: {},
          loading: false,
          error: action.error,
        },
        buttonDisable: false,
        loadingButtonContent: null,
      };
    case GET_REGISTER_DETAILS:
      return {
        ...state,
        registerInputData: {
          loading: false,
          error: false,
          data: {
            ...state.registerInputData.data,
            [action.name]: action.value,
          },
        },
      };
    case REGISTER_START:
      return {
        ...state,
        registerInputData: {
          data: action.data,
        },
        buttonDisable: true,
        loadingButtonContent: "Loading please wait",
      };

    case REGISTER_SUCCESS:
      return {
        ...state,
        profile: {
          data: action.data.data,
        },
        buttonDisable: false,
        loadingButtonContent: null,
      };
    case REGISTER_FAILURE:
      return {
        ...state,
        profile: {
          data: {},
          loading: false,
          error: action.error,
        },
        buttonDisable: false,
        loadingButtonContent: null,
      };
    case GET_FORGOT_PASSWORD_DETAILS:
      return {
        ...state,
        forgotPasswordInputData: {
          loading: false,
          error: false,
          data: {
            ...state.forgotPasswordInputData.data,
            [action.name]: action.value,
          },
        },
      };
    case FORGOT_PASSWORD_START:
      return {
        ...state,
        forgotPasswordInputData: {
          data: action.data,
        },
        buttonDisable: true,
        loadingButtonContent: "Loading please wait",
      };

    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        buttonDisable: false,
        loadingButtonContent: null,
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        buttonDisable: false,
        loadingButtonContent: null,
      };
    case DELETE_ACCOUNT_START:
      return {
        ...state,
        deleteAccount: {
          data: action.data,
        },
        buttonDisable: true,
        loadingButtonContent: "Loading please wait",
      };

    case DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        buttonDisable: false,
        loadingButtonContent: null,
      };
    case DELETE_ACCOUNT_FAILURE:
      return {
        ...state,
        buttonDisable: false,
        loadingButtonContent: null,
      };
    default:
      return state;
  }
};

export default userReducer;
