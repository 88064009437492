import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Form,
  FormControl,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Image,
  Badge,
  Media,
} from "react-bootstrap";
import "./Referrals.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchReferralStart } from "../../store/actions/ReferralAction";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReferralsLoader from "../Loader/ReferralsLoader";

import {
  FacebookIcon,
  FacebookShareButton,
  TelegramShareButton,
  TelegramIcon,
  PinterestIcon,
  PinterestShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

const Referrals = (props) => {
  useEffect(() => {
    if (props.referral.loading) props.dispatch(fetchReferralStart());
  }, []);
  const onCopy = (event) => {
    alert("Meeting Link Copied!!");
  };

  const referral = props.referral;
  return (
    <>
      <div className="referrals-sec">
        {referral.loading ? (
          <ReferralsLoader />
        ) : (
          <Container>
            <Row>
              <Col sm={12} md={12}>
                <h2 className="section-title">Refer & Earn</h2>
              </Col>
            </Row>
            <div className="referrals-box">
              <Row>
                <Col sm={12} md={8}>
                  <h2 className="title">Spread the word and earn rewards</h2>
                  <p className="desc">{referral.data.share_message}</p>
                  <h3 className="sub-title">
                    <CopyToClipboard
                      text={
                        window.location.origin +
                        "/rc/" +
                        referral.data.referral_code
                      }
                      onCopy={onCopy}
                    >
                      <button
                        className="btn subscribe-btn btn-block"
                        type="button"
                      >
                        Click To Copy
                      </button>
                    </CopyToClipboard>
                  </h3>
                  <Form className="referrals-form">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control
                        type="text"
                        readOnly
                        className="shareurl-input"
                        placeholder={
                          window.location.origin +
                          "/rc/" +
                          referral.data.referral_code
                        }
                      />
                    </Form.Group>
                  </Form>
                  <ul className="list-unstyled social-share-sec">
                    <Media as="li">
                      <FacebookShareButton
                        quote={referral.data.share_message}
                        url={
                          window.location.origin +
                          "/rc/" +
                          referral.data.referral_code
                        }
                      >
                        <FacebookIcon size={36} round={true} />
                      </FacebookShareButton>
                    </Media>
                    <Media as="li">
                      <Link to="#">
                        <TwitterShareButton
                          url={
                            window.location.origin +
                            "/rc/" +
                            referral.data.referral_code
                          }
                          title={referral.data.share_message}
                        >
                          <TwitterIcon size={32} round />
                        </TwitterShareButton>
                      </Link>
                    </Media>
                    <Media as="li">
                      <TelegramShareButton
                        url={
                          window.location.origin +
                          "/rc/" +
                          referral.data.referral_code
                        }
                        title={referral.data.share_message}
                      >
                        <TelegramIcon size={32} round />
                      </TelegramShareButton>
                    </Media>
                    <Media as="li">
                      <WhatsappShareButton
                        url={
                          window.location.origin +
                          "/rc/" +
                          referral.data.referral_code
                        }
                        title={referral.data.share_message}
                        separator=":: "
                      >
                        <WhatsappIcon size={32} round />
                      </WhatsappShareButton>
                    </Media>
                  </ul>
                </Col>
                <Col sm={12} md={4}>
                  <div className="referrals-card">
                    <div className="referrals-header">
                      <h1 className="header-title">Referrals</h1>
                    </div>
                    <div className="referrals-body">
                      <p className="body-text">
                        Total Referrals
                        <span className="align-right">
                          {referral.data.total_referrals}
                        </span>
                      </p>
                      <p className="body-text">
                        Earnings{" "}
                        <span className="align-right">
                          {referral.data.formatted_total_referrals_earnings}
                        </span>
                      </p>
                    </div>
                    <div className="referrals-footer">
                      <Link to="/redeems" className="redeems-btn">
                        <FontAwesomeIcon
                          icon={["fas", "money-bill-alt"]}
                          className="mr-2"
                        />
                        CHECK REDEEMS
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            {referral.data.referrals.length > 0 ? (
              <div className="referrals-table-sec">
                <div className="referrals-sec-card">
                  {referral.data.referrals.map((refer) => (
                    <Row>
                      <Col sm={12} md={2}>
                        <div className="user-img-sec">
                          <Image src={refer.picture} className="user-img" />
                        </div>
                      </Col>
                      <Col sm={12} md={3}>
                        <div className="amount-details">
                          <h4 className="amount-title">Username</h4>
                          <p className="amount-desc">{refer.username}</p>
                        </div>
                      </Col>
                      <Col sm={12} md={3}>
                        <div className="amount-details">
                          <h4 className="amount-title">Referral Code</h4>
                          <p className="amount-desc">{refer.referral_code}</p>
                        </div>
                      </Col>
                      <Col sm={12} md={2}>
                        <div className="amount-details">
                          <h4 className="amount-title">Joined</h4>
                          <p className="amount-desc">
                            {new Date(refer.updated_at).toDateString()}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}
          </Container>
        )}
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  referral: state.referral.referral,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Referrals);
