import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "./ChannelView.css";
import {
  Image,
  Navbar,
  Nav,
  Form,
  FormControl,
  Button,
  NavDropdown,
  InputGroup,
  Dropdown,
  Media,
  Row,
  Col,
  Tab,
} from "react-bootstrap";
import ChannelBgImage from "./banner-img.png";

class ChannelView extends Component {

  constructor(props) {
    super(props);
  }
  state = {
    mobileSidebar: true,
  };

  toggleMobileSidebar = () => {
    this.setState({
      mobileSidebar: !this.state.mobileSidebar,
    });
  };

  render() {
    return (
      <div className="video-app">
        <header>
          <Navbar
            collapseOnSelect
            expand="lg"
            className="navbar-expand-lg default-nav"
          >
            <div className="nav-icon" onClick={() => this.toggleMobileSidebar()}>
              <FontAwesomeIcon
                icon={["fas", "align-left"]}
                className="mr-3"
                id="sidebarCollapse"
              />
            </div>
            <Navbar.Brand href="#home">
              <Image
                src={
                  window.location.origin + "/assets/img/base-product/logo.png"
                }
                className="base-logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="resp-padding-sm"
            >
              <ul className="navbar-nav mx-auto resp-padding-xs">
                <InputGroup className="width-half-search">
                  <FormControl placeholder="Search" className="search-info" />
                  <InputGroup.Append>
                    <InputGroup.Text
                      id="basic-addon2"
                      className="search-info-1"
                    >
                      <FontAwesomeIcon
                        icon={["fas", "search"]}
                        className="mr-3"
                      />
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </ul>
              <Dropdown className="resp-padding-xs video-create">
                <Dropdown.Toggle className="button" id="dropdown-basic">
                  <FontAwesomeIcon icon={["fas", "video"]} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">
                    <FontAwesomeIcon
                      icon={["fas", "upload"]}
                      className="icon"
                    />
                    Upload Video
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    <i className="icofont-youtube-play"></i>Go Live
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="resp-padding-xs video-create">
                <Dropdown.Toggle className="button" id="dropdown-basic">
                  <FontAwesomeIcon icon={["fas", "th"]} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="border-bottom-1">
                    <Dropdown.Item href="#/action-1">
                      <i className="icofont-youtube-play"></i>
                      StreamTube TV
                    </Dropdown.Item>
                  </div>
                  <div className="border-bottom-1">
                    <Dropdown.Item href="#/action-2">
                      <i className="icofont-youtube-play"></i>StreamTube Music
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      <i className="icofont-youtube-play"></i>StreamTube Kids
                    </Dropdown.Item>
                  </div>
                  <Dropdown.Item href="#/action-4">
                    <i className="icofont-youtube-play"></i>Creator Academy
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-5">
                    <i className="icofont-youtube-play"></i>StreamTube for
                    Artists
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="video-settings resp-padding-xs">
                <Dropdown.Toggle className="button" id="dropdown-basic">
                  <FontAwesomeIcon icon={["fas", "bell"]} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">
                    <FontAwesomeIcon
                      icon={["fas", "upload"]}
                      className="icon"
                    />
                    Upload Video
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    <i className="icofont-youtube-play"></i>Go Live
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="video-settings resp-padding-xs">
                <Dropdown.Toggle className="button" id="dropdown-basic">
                  <Image
                    src={
                      window.location.origin +
                      "/assets/img/base-product/profile-img.jpg"
                    }
                    className="nav-user-img"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="navbar-profile-content border-bottom-1">
                    <div className="user-profile-img">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/base-product/profile-img.jpg"
                        }
                        className="profile-img"
                      />
                    </div>
                    <div className="profile-info">
                      <h4 className="title">Beno P</h4>
                      <h5 className="desc">beno@codegama.com</h5>
                      <a href="#" className="link">
                        Manage your account
                      </a>
                    </div>
                  </div>
                  <div className="dorpdown-scroll">
                    <Dropdown.Item href="#/action-1">
                      <FontAwesomeIcon icon={["fas", "cog"]} className="icon" />
                      Your Channel
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      <FontAwesomeIcon icon={["fas", "cog"]} className="icon" />
                      Your Channel
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      <FontAwesomeIcon icon={["fas", "cog"]} className="icon" />
                      Your Channel
                    </Dropdown.Item>
                    <div className="flex-content">
                      <Dropdown.Item href="#/action-4">
                        Switch Account
                        <span className="align-right">
                          <FontAwesomeIcon
                            icon={["fas", "chevron-right"]}
                            className="icon"
                          />
                        </span>
                      </Dropdown.Item>
                    </div>
                    <div className="border-bottom-1">
                      <Dropdown.Item href="#/action-5">
                        <FontAwesomeIcon
                          icon={["fas", "cog"]}
                          className="icon"
                        />
                        Your Channel
                      </Dropdown.Item>
                    </div>
                    <div className="flex-content">
                      <Dropdown.Item href="#/action-6">
                        <FontAwesomeIcon
                          icon={["fas", "sun"]}
                          className="icon"
                        />
                        Dark Theme: Off
                        <span className="align-right">
                          <FontAwesomeIcon
                            icon={["fas", "chevron-right"]}
                            className="icon"
                          />
                        </span>
                      </Dropdown.Item>
                    </div>
                    <div className="flex-content">
                      <Dropdown.Item href="#/action-7">
                        <FontAwesomeIcon
                          icon={["fas", "language"]}
                          className="icon"
                        />
                        Language: English
                        <span className="align-right">
                          <FontAwesomeIcon
                            icon={["fas", "chevron-right"]}
                            className="icon"
                          />
                        </span>
                      </Dropdown.Item>
                    </div>
                    <div className="flex-content">
                      <Dropdown.Item href="#/action-8">
                        <FontAwesomeIcon
                          icon={["fas", "globe"]}
                          className="icon"
                        />
                        Location: India
                        <span className="align-right">
                          <FontAwesomeIcon
                            icon={["fas", "chevron-right"]}
                            className="icon"
                          />
                        </span>
                      </Dropdown.Item>
                    </div>
                    <div className="flex-content">
                      <Dropdown.Item href="#/action-9">
                        <FontAwesomeIcon
                          icon={["fas", "cog"]}
                          className="icon"
                        />
                        Settings
                      </Dropdown.Item>
                    </div>
                    <div className="flex-content">
                      <Dropdown.Item href="#/action-10">
                        <FontAwesomeIcon
                          icon={["fas", "user-shield"]}
                          className="icon"
                        />
                        Your data in StreamTube
                      </Dropdown.Item>
                    </div>
                    <div className="flex-content">
                      <Dropdown.Item href="#/action-11">
                        <FontAwesomeIcon
                          icon={["fas", "question-circle"]}
                          className="icon"
                        />
                        Help
                      </Dropdown.Item>
                    </div>
                    <div className="flex-content">
                      <Dropdown.Item href="#/action-12">
                        <i className="icofont-ui-text-loading"></i>Send feedback
                      </Dropdown.Item>
                    </div>
                    <div className="border-bottom-1">
                      <Dropdown.Item href="#/action-13">
                        <FontAwesomeIcon
                          icon={["fas", "keyboard"]}
                          className="icon"
                        />
                        keyboard shorcuts
                      </Dropdown.Item>
                    </div>
                    <div className="flex-content">
                      <Dropdown.Item href="#/action-14">
                        Restricted Mode: Off
                        <span className="align-right">
                          <FontAwesomeIcon
                            icon={["fas", "chevron-right"]}
                            className="icon"
                          />
                        </span>
                      </Dropdown.Item>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </Navbar.Collapse>
          </Navbar>
        </header>
        <div className="wrapper main-wrapper">
          <div className="left-side" id="sidebar" style={{
            display: this.state.mobileSidebar ? "block" : "none",
          }}>
            <div className="side-wrapper">
              <div className="side-menu">
                <ul className="list-unstyled side-menu-item">
                  <Media as="li" className="active">
                    <i className="icofont-home"></i>Home
                  </Media>
                  <Media as="li">
                    <i className="material-icons playlist-icon-1">whatshot</i>
                    Trending
                  </Media>
                  <Media as="li">
                    <i className="material-icons playlist-icon-1">
                      subscriptions
                    </i>
                    Subscriptions
                  </Media>
                  <Media as="li">
                    <i className="material-icons playlist-icon-1">
                      video_library
                    </i>
                    Library
                  </Media>
                  <Media as="li">
                    <i className="icofont-history"></i>History
                  </Media>
                  <Media as="li">
                    <i className="material-icons playlist-icon-1">slideshow</i>
                    Your videos
                  </Media>
                  <Media as="li">
                    <i className="material-icons playlist-icon-1">
                      watch_later
                    </i>
                    Watch later
                  </Media>
                  <Media as="li">
                    <i className="material-icons playlist-icon-1">thumb_up</i>
                    Liked videos
                  </Media>
                </ul>
                <hr></hr>
                <div className="best-video-sec">
                  <h3 className="best-title-1">SUBSCRIPTIONS</h3>
                  <ul className="list-unstyled side-menu-item">
                    <Media as="li">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/base-product/channel-img-1.jpg"
                        }
                        className="base-logo"
                      />
                      Cheetah Media
                    </Media>
                    <Media as="li">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/base-product/valis-kitchen.jpg"
                        }
                        className="base-logo"
                      />
                      Valli's Kitchen
                    </Media>
                    <Media as="li">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/base-product/channel-img-2.jpg"
                        }
                        className="base-logo"
                      />
                      Gaming
                    </Media>
                    <Media as="li">
                      <i className="icofont-plus-circle"></i>Browse Channels
                    </Media>
                  </ul>
                </div>
                <div className="best-video-sec">
                  <h3 className="best-title-1">More From Streamtube</h3>
                  <ul className="list-unstyled side-menu-item">
                    <Media as="li">
                      <i className="icofont-youtube-play"></i>Streamtube Premium
                    </Media>
                    <Media as="li">
                      <i className="material-icons playlist-icon-1">
                        surround_sound
                      </i>
                      Live
                    </Media>
                    <hr></hr>
                    <Media as="li">
                      <i className="icofont-options"></i>Settings
                    </Media>
                    <Media as="li">
                      <i className="icofont-flag"></i>Report History
                    </Media>
                    <Media as="li">
                      <i className="icofont-question-circle"></i>Help
                    </Media>
                    <Media as="li">
                      <i className="icofont-ui-text-loading"></i>Send feedback
                    </Media>
                  </ul>
                </div>
                <hr></hr>
                <div className="sidebar-footer">
                  <Link to="#">About</Link>
                  <Link to="#">Press</Link>
                  <Link to="#">Copyright</Link>
                  <Link to="#">Contact us</Link>
                  <Link to="#">Creators</Link>
                  <Link to="#">Advertise</Link>
                  <Link to="#">Developers</Link>
                </div>
                <div className="sidebar-subfooter">
                  <Link to="#">Terms</Link>
                  <Link to="#">Privacy</Link>
                  <Link to="#">Policy & Safety</Link>
                  <Link to="#">How YouTube works</Link>
                  <Link to="#">Test new features</Link>
                </div>
                <div className="copyright">
                  <p>© 2020 Codegama LLP</p>
                </div>
              </div>
            </div>
          </div>
          <div className="main-container">
            <div
              className="banner-channel-base-view-bg"
              style={{
                backgroundImage: `url(${ChannelBgImage})`,
              }}
            >
              <div className="channel-base-social-share d-none d-sm-block">
                <ul className="list-unstyled channel-base-share-links">
                  <Media as="li">
                    <Link to="#">
                      <Image
                        src={
                          window.location.origin + "/assets/img/insta-fav.png"
                        }
                        className="channel-fav-icon"
                      />
                      <span className="ml-2">Instagram</span>
                    </Link>
                  </Media>
                  <div className="flex-channel">
                    <Media as="li">
                      <Link to="#">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/twitter-fav.png"
                          }
                          className="channel-fav-icon"
                        />
                      </Link>
                    </Media>
                    <Media as="li">
                      <Link to="#">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/img/youtube-fav.png"
                          }
                          className="channel-fav-icon"
                        />
                      </Link>
                    </Media>
                  </div>
                </ul>
              </div>
            </div>
            <div className="channel-base-view-sec">
              <div className="channel-base-view-header">
                <div className="user-info">
                  <div className="user-photo-sec">
                    <Image
                      src={
                        window.location.origin +
                        "/assets/img/base-product/profile-img.jpg"
                      }
                      className="user-photo-img"
                    />
                  </div>
                  <div className="user-info-content">
                    <div className="verified-details">
                      <h4>Beno Darry</h4>
                      <span className="verified-icon">
                        <FontAwesomeIcon
                          icon={["fas", "check-circle"]}
                          className="icon"
                        />
                      </span>
                    </div>
                    <p>4.05M subscribers</p>
                  </div>
                </div>
                <div className="user-action-sec">
                  <Button className="btn btn-join">Join</Button>
                  <Button className="btn btn-subscribe">SUBSCRIBE</Button>
                </div>
              </div>
              <div className="channel-base-view-body">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Row>
                    <Col sm={12} md={9} className="pd-right-zero">
                      <Nav variant="pills">
                        <Nav.Item>
                          <Nav.Link eventKey="first">HOME</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="second">VIDEOS</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="third">PLAYLISTS</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="fourth">COMMUNITY</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="fifth">CHANNELS</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="sixth">ABOUT</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col sm={12} md={3} className="pd-left-zero">
                    <div className="search-channel-base-sec">
                    <Form className="channel-base-search">
                      <InputGroup>
                          <InputGroup.Prepend>
                          <InputGroup.Text><i class="material-icons playlist-icon-1">search</i></InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl aria-label="Amount (to the nearest dollar)" placeholder="Search" />
                      </InputGroup>
                    </Form>
                    </div>
                    </Col>
                    <Col sm={12} md={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <div className="channel-base-view-tab-body">
                            <Row>
                              <Col sm={12} md={5}>
                                <div className="channel-base-home-video-img">
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/img/base-product/video-1.jpg"
                                    }
                                    className="channel-video-img"
                                  />
                                </div>
                              </Col>
                              <Col sm={12} md={5} className="resp-mrg-btm-sm">
                                <div className="channel-base-home-video-details">
                                  <Link to="#">
                                    <h4 className="title">
                                      Trump: Mad Bigg Boss | Tamil | Madan Gowri
                                      | MG
                                    </h4>
                                  </Link>
                                  <p className="desc">
                                    <span className="view-count">5M views</span>
                                    <span className="upload-time">
                                      2 weeks ago
                                    </span>
                                  </p>
                                  <h6 className="sub-title">
                                    Join MG Squad Membership:
                                  </h6>
                                  <Link
                                    className="channel-base-home-link"
                                    to="https://www.youtube.com/channel/UCY6KjrDBN_tIRFT_QNqQbRQ/join"
                                  >
                                    https://www.youtube.com/channel/UCY6KjrDBN_tIRFT_QNqQbRQ/join
                                  </Link>
                                  ---------------
                                  <h6 className="sub-title">Irwog:</h6>
                                  <Link
                                    className="channel-base-home-link"
                                    to="https://www.youtube.com/channel/UC9pRPRlo6wIOakEOi_2RWwA"
                                  >
                                    https://www.youtube.com/channel/UC9pRPRlo6wIOakEOi_2RWwA
                                  </Link>
                                  ---------------
                                  <h6 className="sub-title">
                                    In this latest Madan Gowri video we will be
                                    discussing about,
                                  </h6>
                                  <Link to="#" className="read-more">
                                    READ MORE
                                  </Link>
                                </div>
                              </Col>
                              <Col sm={12} md={2}>
                                <h3 className="title-sub">MG SQUARD</h3>
                                <div className="more-channel">
                                  <div className="more-channel-img-sec">
                                    <Image
                                      src={
                                        window.location.origin +
                                        "/assets/img/base-product/profile-img.jpg"
                                      }
                                      className="user-more-channel-img"
                                    />
                                  </div>
                                  <h6>Ir Wog</h6>
                                </div>
                                <Button className="subscribe-btn">
                                  SUBSCRIBE
                                </Button>
                              </Col>
                            </Row>
                          </div>
                          <div className="channel-base-view-tab-slider-sec subscription-new-video-sec">
                            <div className="channel-base-view-tab-title">
                              <h4 className="title">
                                Madan Gowri Latest Videos
                              </h4>
                              <h4 className="sub-title">
                                <i className="icofont-ui-play"></i>PLAY ALL
                              </h4>
                            </div>
                            <div className="channel-base-view-tab-slider">
                              <Row>
                                <div className="col-xs-15">
                                  <div className="video-list-box">
                                    <div className="hover-content">
                                      <ul className="list-unstyled">
                                        <Media as="li">
                                          <Link to="#">
                                            <i className="material-icons playlist-icon">
                                              watch_later
                                            </i>
                                          </Link>
                                        </Media>
                                        <Media as="li">
                                          <Link to="#">
                                            <i className="material-icons playlist-icon">
                                              playlist_play
                                            </i>
                                          </Link>
                                        </Media>
                                      </ul>
                                    </div>
                                    <div className="video-list-img">
                                      <Link to="#">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/base-product/video-1.jpg"
                                          }
                                          className="img-fluid video-img"
                                        />
                                      </Link>
                                      <div className="hover-play">
                                        <Link to="#">
                                          <i className="icofont-ui-play"></i>
                                        </Link>
                                      </div>
                                      <div className="time-count">
                                        <h6>16:30</h6>
                                      </div>
                                    </div>
                                    <div className="video-list-info">
                                      <div className="video-list-content">
                                        <div className="video-middle-content">
                                          <Link to="#">
                                            <h3 className="title">
                                              Vathikkalu Vellaripravu Video Song
                                              | Sufiyum Sujatayum | M
                                              Jayachandran | Vijay Babu
                                            </h3>
                                          </Link>
                                          <Link to="#">
                                            <h4 className="sub-title">
                                              Friday Music Company
                                            </h4>
                                          </Link>
                                          <Link to="#">
                                            <p className="desc">
                                              <span className="view-count">
                                                5M views
                                              </span>
                                              <span className="upload-time">
                                                2 weeks ago
                                              </span>
                                            </p>
                                          </Link>
                                        </div>
                                        <div className="video-sup-sec">
                                          <Dropdown className="video-sup-dropdown resp-padding-xs">
                                            <Dropdown.Toggle
                                              className="button"
                                              id="dropdown-basic"
                                            >
                                              <FontAwesomeIcon
                                                icon={["fas", "ellipsis-v"]}
                                              />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item href="#/action-1">
                                                <i className="material-icons icon">
                                                  playlist_play
                                                </i>
                                                Add to queue
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-2">
                                                <i className="material-icons icon">
                                                  watch_later
                                                </i>
                                                Save to Watch later
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-3">
                                                <i className="material-icons icon">
                                                  playlist_add
                                                </i>
                                                Save to playlist
                                              </Dropdown.Item>
                                              <hr></hr>
                                              <Dropdown.Item href="#/action-4">
                                                <i className="icofont-not-allowed icon"></i>
                                                Hide
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xs-15">
                                  <div className="video-list-box">
                                    <div className="hover-content">
                                      <ul className="list-unstyled">
                                        <Media as="li">
                                          <Link to="#">
                                            <i className="material-icons playlist-icon">
                                              watch_later
                                            </i>
                                          </Link>
                                        </Media>
                                        <Media as="li">
                                          <Link to="#">
                                            <i className="material-icons playlist-icon">
                                              playlist_play
                                            </i>
                                          </Link>
                                        </Media>
                                      </ul>
                                    </div>
                                    <div className="video-list-img">
                                      <Link to="#">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/base-product/video-1.jpg"
                                          }
                                          className="img-fluid video-img"
                                        />
                                      </Link>
                                      <div className="hover-play">
                                        <Link to="#">
                                          <i className="icofont-ui-play"></i>
                                        </Link>
                                      </div>
                                      <div className="time-count">
                                        <h6>16:30</h6>
                                      </div>
                                    </div>
                                    <div className="video-list-info">
                                      <div className="video-list-content">
                                        <div className="video-middle-content">
                                          <Link to="#">
                                            <h3 className="title">
                                              Vathikkalu Vellaripravu Video Song
                                              | Sufiyum Sujatayum | M
                                              Jayachandran | Vijay Babu
                                            </h3>
                                          </Link>
                                          <Link to="#">
                                            <h4 className="sub-title">
                                              Friday Music Company
                                            </h4>
                                          </Link>
                                          <Link to="#">
                                            <p className="desc">
                                              <span className="view-count">
                                                5M views
                                              </span>
                                              <span className="upload-time">
                                                2 weeks ago
                                              </span>
                                            </p>
                                          </Link>
                                        </div>
                                        <div className="video-sup-sec">
                                          <Dropdown className="video-sup-dropdown resp-padding-xs">
                                            <Dropdown.Toggle
                                              className="button"
                                              id="dropdown-basic"
                                            >
                                              <FontAwesomeIcon
                                                icon={["fas", "ellipsis-v"]}
                                              />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item href="#/action-1">
                                                <i className="material-icons icon">
                                                  playlist_play
                                                </i>
                                                Add to queue
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-2">
                                                <i className="material-icons icon">
                                                  watch_later
                                                </i>
                                                Save to Watch later
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-3">
                                                <i className="material-icons icon">
                                                  playlist_add
                                                </i>
                                                Save to playlist
                                              </Dropdown.Item>
                                              <hr></hr>
                                              <Dropdown.Item href="#/action-4">
                                                <i className="icofont-not-allowed icon"></i>
                                                Hide
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xs-15">
                                  <div className="video-list-box">
                                    <div className="hover-content">
                                      <ul className="list-unstyled">
                                        <Media as="li">
                                          <Link to="#">
                                            <i className="material-icons playlist-icon">
                                              watch_later
                                            </i>
                                          </Link>
                                        </Media>
                                        <Media as="li">
                                          <Link to="#">
                                            <i className="material-icons playlist-icon">
                                              playlist_play
                                            </i>
                                          </Link>
                                        </Media>
                                      </ul>
                                    </div>
                                    <div className="video-list-img">
                                      <Link to="#">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/base-product/video-1.jpg"
                                          }
                                          className="img-fluid video-img"
                                        />
                                      </Link>
                                      <div className="hover-play">
                                        <Link to="#">
                                          <i className="icofont-ui-play"></i>
                                        </Link>
                                      </div>
                                      <div className="time-count">
                                        <h6>16:30</h6>
                                      </div>
                                    </div>
                                    <div className="video-list-info">
                                      <div className="video-list-content">
                                        <div className="video-middle-content">
                                          <Link to="#">
                                            <h3 className="title">
                                              Vathikkalu Vellaripravu Video Song
                                              | Sufiyum Sujatayum | M
                                              Jayachandran | Vijay Babu
                                            </h3>
                                          </Link>
                                          <Link to="#">
                                            <h4 className="sub-title">
                                              Friday Music Company
                                            </h4>
                                          </Link>
                                          <Link to="#">
                                            <p className="desc">
                                              <span className="view-count">
                                                5M views
                                              </span>
                                              <span className="upload-time">
                                                2 weeks ago
                                              </span>
                                            </p>
                                          </Link>
                                        </div>
                                        <div className="video-sup-sec">
                                          <Dropdown className="video-sup-dropdown resp-padding-xs">
                                            <Dropdown.Toggle
                                              className="button"
                                              id="dropdown-basic"
                                            >
                                              <FontAwesomeIcon
                                                icon={["fas", "ellipsis-v"]}
                                              />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item href="#/action-1">
                                                <i className="material-icons icon">
                                                  playlist_play
                                                </i>
                                                Add to queue
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-2">
                                                <i className="material-icons icon">
                                                  watch_later
                                                </i>
                                                Save to Watch later
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-3">
                                                <i className="material-icons icon">
                                                  playlist_add
                                                </i>
                                                Save to playlist
                                              </Dropdown.Item>
                                              <hr></hr>
                                              <Dropdown.Item href="#/action-4">
                                                <i className="icofont-not-allowed icon"></i>
                                                Hide
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xs-15">
                                  <div className="video-list-box">
                                    <div className="hover-content">
                                      <ul className="list-unstyled">
                                        <Media as="li">
                                          <Link to="#">
                                            <i className="material-icons playlist-icon">
                                              watch_later
                                            </i>
                                          </Link>
                                        </Media>
                                        <Media as="li">
                                          <Link to="#">
                                            <i className="material-icons playlist-icon">
                                              playlist_play
                                            </i>
                                          </Link>
                                        </Media>
                                      </ul>
                                    </div>
                                    <div className="video-list-img">
                                      <Link to="#">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/base-product/video-1.jpg"
                                          }
                                          className="img-fluid video-img"
                                        />
                                      </Link>
                                      <div className="hover-play">
                                        <Link to="#">
                                          <i className="icofont-ui-play"></i>
                                        </Link>
                                      </div>
                                      <div className="time-count">
                                        <h6>16:30</h6>
                                      </div>
                                    </div>
                                    <div className="video-list-info">
                                      <div className="video-list-content">
                                        <div className="video-middle-content">
                                          <Link to="#">
                                            <h3 className="title">
                                              Vathikkalu Vellaripravu Video Song
                                              | Sufiyum Sujatayum | M
                                              Jayachandran | Vijay Babu
                                            </h3>
                                          </Link>
                                          <Link to="#">
                                            <h4 className="sub-title">
                                              Friday Music Company
                                            </h4>
                                          </Link>
                                          <Link to="#">
                                            <p className="desc">
                                              <span className="view-count">
                                                5M views
                                              </span>
                                              <span className="upload-time">
                                                2 weeks ago
                                              </span>
                                            </p>
                                          </Link>
                                        </div>
                                        <div className="video-sup-sec">
                                          <Dropdown className="video-sup-dropdown resp-padding-xs">
                                            <Dropdown.Toggle
                                              className="button"
                                              id="dropdown-basic"
                                            >
                                              <FontAwesomeIcon
                                                icon={["fas", "ellipsis-v"]}
                                              />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item href="#/action-1">
                                                <i className="material-icons icon">
                                                  playlist_play
                                                </i>
                                                Add to queue
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-2">
                                                <i className="material-icons icon">
                                                  watch_later
                                                </i>
                                                Save to Watch later
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-3">
                                                <i className="material-icons icon">
                                                  playlist_add
                                                </i>
                                                Save to playlist
                                              </Dropdown.Item>
                                              <hr></hr>
                                              <Dropdown.Item href="#/action-4">
                                                <i className="icofont-not-allowed icon"></i>
                                                Hide
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Row>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          <div className="channel-view-video-sec">
                            <div className="channel-base-view-tab-video-header">
                              <div className="flex-content">
                                <Dropdown className="video-channel-dropdown resp-padding-xs">
                                  <Dropdown.Toggle
                                    className="button"
                                    id="dropdown-basic"
                                  >
                                    Upload
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">
                                      <i className="material-icons icon">
                                        playlist_play
                                      </i>
                                      Add to queue
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">
                                      <i className="material-icons icon">
                                        watch_later
                                      </i>
                                      Save to Watch later
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      <i className="material-icons icon">
                                        playlist_add
                                      </i>
                                      Save to playlist
                                    </Dropdown.Item>
                                    <hr></hr>
                                    <Dropdown.Item href="#/action-4">
                                      <i className="icofont-not-allowed icon"></i>
                                      Hide
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                                <h4 className="sub-title">PLAY ALL</h4>
                              </div>
                              <p className="sort-by-txt">
                                <Dropdown className="channel-base-sortby-dropdown resp-padding-xs">
                                  <Dropdown.Toggle
                                    className="button"
                                    id="dropdown-basic"
                                  >
                                    <i className="material-icons playlist-icon-1 mr-2">
                                      sort
                                    </i>
                                    SORT BY
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">
                                      Date added (newest)
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">
                                      Date added (oldest)
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      Most popular
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-4">
                                      Date published (newest)
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-5">
                                      Date published (oldest)
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </p>
                            </div>
                            <div className="channel-base-video-sup-sec subscription-new-video-sec">
                              <Row>
                                <div className="col-xs-15">
                                  <div className="video-list-box">
                                    <div className="hover-content">
                                      <ul className="list-unstyled">
                                        <Media as="li">
                                          <Link to="#">
                                            <i className="material-icons playlist-icon">
                                              watch_later
                                            </i>
                                          </Link>
                                        </Media>
                                        <Media as="li">
                                          <Link to="#">
                                            <i className="material-icons playlist-icon">
                                              playlist_play
                                            </i>
                                          </Link>
                                        </Media>
                                      </ul>
                                    </div>
                                    <div className="video-list-img">
                                      <Link to="#">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/base-product/video-1.jpg"
                                          }
                                          className="img-fluid video-img"
                                        />
                                      </Link>
                                      <div className="hover-play">
                                        <Link to="#">
                                          <i className="icofont-ui-play"></i>
                                        </Link>
                                      </div>
                                      <div className="time-count">
                                        <h6>16:30</h6>
                                      </div>
                                    </div>
                                    <div className="video-list-info">
                                      <div className="video-list-content">
                                        <div className="video-middle-content">
                                          <Link to="#">
                                            <h3 className="title">
                                              Vathikkalu Vellaripravu Video Song
                                              | Sufiyum Sujatayum | M
                                              Jayachandran | Vijay Babu
                                            </h3>
                                          </Link>
                                          <Link to="#">
                                            <h4 className="sub-title">
                                              Friday Music Company
                                            </h4>
                                          </Link>
                                          <Link to="#">
                                            <p className="desc">
                                              <span className="view-count">
                                                5M views
                                              </span>
                                              <span className="upload-time">
                                                2 weeks ago
                                              </span>
                                            </p>
                                          </Link>
                                        </div>
                                        <div className="video-sup-sec">
                                          <Dropdown className="video-sup-dropdown resp-padding-xs">
                                            <Dropdown.Toggle
                                              className="button"
                                              id="dropdown-basic"
                                            >
                                              <FontAwesomeIcon
                                                icon={["fas", "ellipsis-v"]}
                                              />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item href="#/action-1">
                                                <i className="material-icons icon">
                                                  playlist_play
                                                </i>
                                                Add to queue
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-2">
                                                <i className="material-icons icon">
                                                  watch_later
                                                </i>
                                                Save to Watch later
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-3">
                                                <i className="material-icons icon">
                                                  playlist_add
                                                </i>
                                                Save to playlist
                                              </Dropdown.Item>
                                              <hr></hr>
                                              <Dropdown.Item href="#/action-4">
                                                <i className="icofont-not-allowed icon"></i>
                                                Hide
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xs-15">
                                  <div className="video-list-box">
                                    <div className="hover-content">
                                      <ul className="list-unstyled">
                                        <Media as="li">
                                          <Link to="#">
                                            <i className="material-icons playlist-icon">
                                              watch_later
                                            </i>
                                          </Link>
                                        </Media>
                                        <Media as="li">
                                          <Link to="#">
                                            <i className="material-icons playlist-icon">
                                              playlist_play
                                            </i>
                                          </Link>
                                        </Media>
                                      </ul>
                                    </div>
                                    <div className="video-list-img">
                                      <Link to="#">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/base-product/video-1.jpg"
                                          }
                                          className="img-fluid video-img"
                                        />
                                      </Link>
                                      <div className="hover-play">
                                        <Link to="#">
                                          <i className="icofont-ui-play"></i>
                                        </Link>
                                      </div>
                                      <div className="time-count">
                                        <h6>16:30</h6>
                                      </div>
                                    </div>
                                    <div className="video-list-info">
                                      <div className="video-list-content">
                                        <div className="video-middle-content">
                                          <Link to="#">
                                            <h3 className="title">
                                              Vathikkalu Vellaripravu Video Song
                                              | Sufiyum Sujatayum | M
                                              Jayachandran | Vijay Babu
                                            </h3>
                                          </Link>
                                          <Link to="#">
                                            <h4 className="sub-title">
                                              Friday Music Company
                                            </h4>
                                          </Link>
                                          <Link to="#">
                                            <p className="desc">
                                              <span className="view-count">
                                                5M views
                                              </span>
                                              <span className="upload-time">
                                                2 weeks ago
                                              </span>
                                            </p>
                                          </Link>
                                        </div>
                                        <div className="video-sup-sec">
                                          <Dropdown className="video-sup-dropdown resp-padding-xs">
                                            <Dropdown.Toggle
                                              className="button"
                                              id="dropdown-basic"
                                            >
                                              <FontAwesomeIcon
                                                icon={["fas", "ellipsis-v"]}
                                              />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item href="#/action-1">
                                                <i className="material-icons icon">
                                                  playlist_play
                                                </i>
                                                Add to queue
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-2">
                                                <i className="material-icons icon">
                                                  watch_later
                                                </i>
                                                Save to Watch later
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-3">
                                                <i className="material-icons icon">
                                                  playlist_add
                                                </i>
                                                Save to playlist
                                              </Dropdown.Item>
                                              <hr></hr>
                                              <Dropdown.Item href="#/action-4">
                                                <i className="icofont-not-allowed icon"></i>
                                                Hide
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xs-15">
                                  <div className="video-list-box">
                                    <div className="hover-content">
                                      <ul className="list-unstyled">
                                        <Media as="li">
                                          <Link to="#">
                                            <i className="material-icons playlist-icon">
                                              watch_later
                                            </i>
                                          </Link>
                                        </Media>
                                        <Media as="li">
                                          <Link to="#">
                                            <i className="material-icons playlist-icon">
                                              playlist_play
                                            </i>
                                          </Link>
                                        </Media>
                                      </ul>
                                    </div>
                                    <div className="video-list-img">
                                      <Link to="#">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/base-product/video-1.jpg"
                                          }
                                          className="img-fluid video-img"
                                        />
                                      </Link>
                                      <div className="hover-play">
                                        <Link to="#">
                                          <i className="icofont-ui-play"></i>
                                        </Link>
                                      </div>
                                      <div className="time-count">
                                        <h6>16:30</h6>
                                      </div>
                                    </div>
                                    <div className="video-list-info">
                                      <div className="video-list-content">
                                        <div className="video-middle-content">
                                          <Link to="#">
                                            <h3 className="title">
                                              Vathikkalu Vellaripravu Video Song
                                              | Sufiyum Sujatayum | M
                                              Jayachandran | Vijay Babu
                                            </h3>
                                          </Link>
                                          <Link to="#">
                                            <h4 className="sub-title">
                                              Friday Music Company
                                            </h4>
                                          </Link>
                                          <Link to="#">
                                            <p className="desc">
                                              <span className="view-count">
                                                5M views
                                              </span>
                                              <span className="upload-time">
                                                2 weeks ago
                                              </span>
                                            </p>
                                          </Link>
                                        </div>
                                        <div className="video-sup-sec">
                                          <Dropdown className="video-sup-dropdown resp-padding-xs">
                                            <Dropdown.Toggle
                                              className="button"
                                              id="dropdown-basic"
                                            >
                                              <FontAwesomeIcon
                                                icon={["fas", "ellipsis-v"]}
                                              />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item href="#/action-1">
                                                <i className="material-icons icon">
                                                  playlist_play
                                                </i>
                                                Add to queue
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-2">
                                                <i className="material-icons icon">
                                                  watch_later
                                                </i>
                                                Save to Watch later
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-3">
                                                <i className="material-icons icon">
                                                  playlist_add
                                                </i>
                                                Save to playlist
                                              </Dropdown.Item>
                                              <hr></hr>
                                              <Dropdown.Item href="#/action-4">
                                                <i className="icofont-not-allowed icon"></i>
                                                Hide
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xs-15">
                                  <div className="video-list-box">
                                    <div className="hover-content">
                                      <ul className="list-unstyled">
                                        <Media as="li">
                                          <Link to="#">
                                            <i className="material-icons playlist-icon">
                                              watch_later
                                            </i>
                                          </Link>
                                        </Media>
                                        <Media as="li">
                                          <Link to="#">
                                            <i className="material-icons playlist-icon">
                                              playlist_play
                                            </i>
                                          </Link>
                                        </Media>
                                      </ul>
                                    </div>
                                    <div className="video-list-img">
                                      <Link to="#">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/base-product/video-1.jpg"
                                          }
                                          className="img-fluid video-img"
                                        />
                                      </Link>
                                      <div className="hover-play">
                                        <Link to="#">
                                          <i className="icofont-ui-play"></i>
                                        </Link>
                                      </div>
                                      <div className="time-count">
                                        <h6>16:30</h6>
                                      </div>
                                    </div>
                                    <div className="video-list-info">
                                      <div className="video-list-content">
                                        <div className="video-middle-content">
                                          <Link to="#">
                                            <h3 className="title">
                                              Vathikkalu Vellaripravu Video Song
                                              | Sufiyum Sujatayum | M
                                              Jayachandran | Vijay Babu
                                            </h3>
                                          </Link>
                                          <Link to="#">
                                            <h4 className="sub-title">
                                              Friday Music Company
                                            </h4>
                                          </Link>
                                          <Link to="#">
                                            <p className="desc">
                                              <span className="view-count">
                                                5M views
                                              </span>
                                              <span className="upload-time">
                                                2 weeks ago
                                              </span>
                                            </p>
                                          </Link>
                                        </div>
                                        <div className="video-sup-sec">
                                          <Dropdown className="video-sup-dropdown resp-padding-xs">
                                            <Dropdown.Toggle
                                              className="button"
                                              id="dropdown-basic"
                                            >
                                              <FontAwesomeIcon
                                                icon={["fas", "ellipsis-v"]}
                                              />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item href="#/action-1">
                                                <i className="material-icons icon">
                                                  playlist_play
                                                </i>
                                                Add to queue
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-2">
                                                <i className="material-icons icon">
                                                  watch_later
                                                </i>
                                                Save to Watch later
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-3">
                                                <i className="material-icons icon">
                                                  playlist_add
                                                </i>
                                                Save to playlist
                                              </Dropdown.Item>
                                              <hr></hr>
                                              <Dropdown.Item href="#/action-4">
                                                <i className="icofont-not-allowed icon"></i>
                                                Hide
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xs-15">
                                  <div className="video-list-box">
                                    <div className="hover-content">
                                      <ul className="list-unstyled">
                                        <Media as="li">
                                          <Link to="#">
                                            <i className="material-icons playlist-icon">
                                              watch_later
                                            </i>
                                          </Link>
                                        </Media>
                                        <Media as="li">
                                          <Link to="#">
                                            <i className="material-icons playlist-icon">
                                              playlist_play
                                            </i>
                                          </Link>
                                        </Media>
                                      </ul>
                                    </div>
                                    <div className="video-list-img">
                                      <Link to="#">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/base-product/video-1.jpg"
                                          }
                                          className="img-fluid video-img"
                                        />
                                      </Link>
                                      <div className="hover-play">
                                        <Link to="#">
                                          <i className="icofont-ui-play"></i>
                                        </Link>
                                      </div>
                                      <div className="time-count">
                                        <h6>16:30</h6>
                                      </div>
                                    </div>
                                    <div className="video-list-info">
                                      <div className="video-list-content">
                                        <div className="video-middle-content">
                                          <Link to="#">
                                            <h3 className="title">
                                              Vathikkalu Vellaripravu Video Song
                                              | Sufiyum Sujatayum | M
                                              Jayachandran | Vijay Babu
                                            </h3>
                                          </Link>
                                          <Link to="#">
                                            <h4 className="sub-title">
                                              Friday Music Company
                                            </h4>
                                          </Link>
                                          <Link to="#">
                                            <p className="desc">
                                              <span className="view-count">
                                                5M views
                                              </span>
                                              <span className="upload-time">
                                                2 weeks ago
                                              </span>
                                            </p>
                                          </Link>
                                        </div>
                                        <div className="video-sup-sec">
                                          <Dropdown className="video-sup-dropdown resp-padding-xs">
                                            <Dropdown.Toggle
                                              className="button"
                                              id="dropdown-basic"
                                            >
                                              <FontAwesomeIcon
                                                icon={["fas", "ellipsis-v"]}
                                              />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item href="#/action-1">
                                                <i className="material-icons icon">
                                                  playlist_play
                                                </i>
                                                Add to queue
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-2">
                                                <i className="material-icons icon">
                                                  watch_later
                                                </i>
                                                Save to Watch later
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-3">
                                                <i className="material-icons icon">
                                                  playlist_add
                                                </i>
                                                Save to playlist
                                              </Dropdown.Item>
                                              <hr></hr>
                                              <Dropdown.Item href="#/action-4">
                                                <i className="icofont-not-allowed icon"></i>
                                                Hide
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xs-15">
                                  <div className="video-list-box">
                                    <div className="hover-content">
                                      <ul className="list-unstyled">
                                        <Media as="li">
                                          <Link to="#">
                                            <i className="material-icons playlist-icon">
                                              watch_later
                                            </i>
                                          </Link>
                                        </Media>
                                        <Media as="li">
                                          <Link to="#">
                                            <i className="material-icons playlist-icon">
                                              playlist_play
                                            </i>
                                          </Link>
                                        </Media>
                                      </ul>
                                    </div>
                                    <div className="video-list-img">
                                      <Link to="#">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/base-product/video-1.jpg"
                                          }
                                          className="img-fluid video-img"
                                        />
                                      </Link>
                                      <div className="hover-play">
                                        <Link to="#">
                                          <i className="icofont-ui-play"></i>
                                        </Link>
                                      </div>
                                      <div className="time-count">
                                        <h6>16:30</h6>
                                      </div>
                                    </div>
                                    <div className="video-list-info">
                                      <div className="video-list-content">
                                        <div className="video-middle-content">
                                          <Link to="#">
                                            <h3 className="title">
                                              Vathikkalu Vellaripravu Video Song
                                              | Sufiyum Sujatayum | M
                                              Jayachandran | Vijay Babu
                                            </h3>
                                          </Link>
                                          <Link to="#">
                                            <h4 className="sub-title">
                                              Friday Music Company
                                            </h4>
                                          </Link>
                                          <Link to="#">
                                            <p className="desc">
                                              <span className="view-count">
                                                5M views
                                              </span>
                                              <span className="upload-time">
                                                2 weeks ago
                                              </span>
                                            </p>
                                          </Link>
                                        </div>
                                        <div className="video-sup-sec">
                                          <Dropdown className="video-sup-dropdown resp-padding-xs">
                                            <Dropdown.Toggle
                                              className="button"
                                              id="dropdown-basic"
                                            >
                                              <FontAwesomeIcon
                                                icon={["fas", "ellipsis-v"]}
                                              />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item href="#/action-1">
                                                <i className="material-icons icon">
                                                  playlist_play
                                                </i>
                                                Add to queue
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-2">
                                                <i className="material-icons icon">
                                                  watch_later
                                                </i>
                                                Save to Watch later
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-3">
                                                <i className="material-icons icon">
                                                  playlist_add
                                                </i>
                                                Save to playlist
                                              </Dropdown.Item>
                                              <hr></hr>
                                              <Dropdown.Item href="#/action-4">
                                                <i className="icofont-not-allowed icon"></i>
                                                Hide
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Row>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                          <div className="channel-base-playlist-sec">
                            <div className="channel-base-playlist-header">
                              <h3 className="title">Created playlist</h3>
                              <p className="sort-by-txt">
                                <Dropdown className="channel-base-sortby-dropdown resp-padding-xs">
                                  <Dropdown.Toggle
                                    className="button"
                                    id="dropdown-basic"
                                  >
                                    <i className="material-icons playlist-icon-1 mr-2">
                                      sort
                                    </i>
                                    SORT BY
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">
                                      Date added (newest)
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">
                                      Date added (oldest)
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      Most popular
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-4">
                                      Date published (newest)
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-5">
                                      Date published (oldest)
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </p>
                            </div>
                            <div className="channel-base-playlist-video-sec">
                              <Row>
                                <div className="col-xs-15">
                                  <div className="video-list-box">
                                    <div className="video-list-img-1">
                                      <Link to="#">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/base-product/video-1.jpg"
                                          }
                                          className="img-fluid video-img"
                                        />
                                      </Link>
                                      <div class="img-overlay">
                                        <p class="overlay-txt">50+</p>
                                        <p class="overlay-txt">
                                          <i class="material-icons playlist-icon">
                                            playlist_play
                                          </i>
                                        </p>
                                      </div>
                                      <div className="hover-play-all">
                                        <Link to="#">
                                          <i class="icofont-ui-play"></i>
                                          <span class="play-all-txt">
                                            Play All
                                          </span>
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="video-list-info">
                                      <div className="video-list-content">
                                        <div className="video-middle-content">
                                          <Link to="#">
                                            <h3 className="title">
                                              Bigg Boss Tamil Season 4
                                            </h3>
                                          </Link>
                                          <Link to="#">
                                            <h4 className="sub-title">
                                              Update today
                                            </h4>
                                          </Link>
                                          <Link to="#">
                                            <p className="desc">
                                              VIEW FULL PLAYLIST
                                            </p>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xs-15">
                                  <div className="video-list-box">
                                    <div className="video-list-img-1">
                                      <Link to="#">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/base-product/video-1.jpg"
                                          }
                                          className="img-fluid video-img"
                                        />
                                      </Link>
                                      <div class="img-overlay">
                                        <p class="overlay-txt">50+</p>
                                        <p class="overlay-txt">
                                          <i class="material-icons playlist-icon">
                                            playlist_play
                                          </i>
                                        </p>
                                      </div>
                                      <div className="hover-play-all">
                                        <Link to="#">
                                          <i class="icofont-ui-play"></i>
                                          <span class="play-all-txt">
                                            Play All
                                          </span>
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="video-list-info">
                                      <div className="video-list-content">
                                        <div className="video-middle-content">
                                          <Link to="#">
                                            <h3 className="title">
                                              Bigg Boss Tamil Season 4
                                            </h3>
                                          </Link>
                                          <Link to="#">
                                            <h4 className="sub-title">
                                              Update today
                                            </h4>
                                          </Link>
                                          <Link to="#">
                                            <p className="desc">
                                              VIEW FULL PLAYLIST
                                            </p>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xs-15">
                                  <div className="video-list-box">
                                    <div className="video-list-img-1">
                                      <Link to="#">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/base-product/video-1.jpg"
                                          }
                                          className="img-fluid video-img"
                                        />
                                      </Link>
                                      <div class="img-overlay">
                                        <p class="overlay-txt">50+</p>
                                        <p class="overlay-txt">
                                          <i class="material-icons playlist-icon">
                                            playlist_play
                                          </i>
                                        </p>
                                      </div>
                                      <div className="hover-play-all">
                                        <Link to="#">
                                          <i class="icofont-ui-play"></i>
                                          <span class="play-all-txt">
                                            Play All
                                          </span>
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="video-list-info">
                                      <div className="video-list-content">
                                        <div className="video-middle-content">
                                          <Link to="#">
                                            <h3 className="title">
                                              Bigg Boss Tamil Season 4
                                            </h3>
                                          </Link>
                                          <Link to="#">
                                            <h4 className="sub-title">
                                              Update today
                                            </h4>
                                          </Link>
                                          <Link to="#">
                                            <p className="desc">
                                              VIEW FULL PLAYLIST
                                            </p>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xs-15">
                                  <div className="video-list-box">
                                    <div className="video-list-img-1">
                                      <Link to="#">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/base-product/video-1.jpg"
                                          }
                                          className="img-fluid video-img"
                                        />
                                      </Link>
                                      <div class="img-overlay">
                                        <p class="overlay-txt">50+</p>
                                        <p class="overlay-txt">
                                          <i class="material-icons playlist-icon">
                                            playlist_play
                                          </i>
                                        </p>
                                      </div>
                                      <div className="hover-play-all">
                                        <Link to="#">
                                          <i class="icofont-ui-play"></i>
                                          <span class="play-all-txt">
                                            Play All
                                          </span>
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="video-list-info">
                                      <div className="video-list-content">
                                        <div className="video-middle-content">
                                          <Link to="#">
                                            <h3 className="title">
                                              Bigg Boss Tamil Season 4
                                            </h3>
                                          </Link>
                                          <Link to="#">
                                            <h4 className="sub-title">
                                              Update today
                                            </h4>
                                          </Link>
                                          <Link to="#">
                                            <p className="desc">
                                              VIEW FULL PLAYLIST
                                            </p>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xs-15">
                                  <div className="video-list-box">
                                    <div className="video-list-img-1">
                                      <Link to="#">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/base-product/video-1.jpg"
                                          }
                                          className="img-fluid video-img"
                                        />
                                      </Link>
                                      <div class="img-overlay">
                                        <p class="overlay-txt">50+</p>
                                        <p class="overlay-txt">
                                          <i class="material-icons playlist-icon">
                                            playlist_play
                                          </i>
                                        </p>
                                      </div>
                                      <div className="hover-play-all">
                                        <Link to="#">
                                          <i class="icofont-ui-play"></i>
                                          <span class="play-all-txt">
                                            Play All
                                          </span>
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="video-list-info">
                                      <div className="video-list-content">
                                        <div className="video-middle-content">
                                          <Link to="#">
                                            <h3 className="title">
                                              Bigg Boss Tamil Season 4
                                            </h3>
                                          </Link>
                                          <Link to="#">
                                            <h4 className="sub-title">
                                              Update today
                                            </h4>
                                          </Link>
                                          <Link to="#">
                                            <p className="desc">
                                              VIEW FULL PLAYLIST
                                            </p>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Row>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="fourth"></Tab.Pane>
                        <Tab.Pane eventKey="fifth">
                          <div className="channel-base-video-channel-sec">
                            <div className="channel-base-channel-header">
                              <h3 className="title">Featured channels</h3>
                            </div>
                            <div className="channel-base-channel-body">
                              <Row>
                                <div className="col-xs-15 text-center">
                                  <div className="channel-base-channel-box">
                                    <div className="channel-base-user-img-sec">
                                      <Link to="#">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/base-product/profile-img.jpg"
                                          }
                                          className="user-img"
                                        />
                                      </Link>
                                    </div>
                                    <div className="video-middle-content">
                                      <Link to="#">
                                        <h3 className="title">Ir Wog</h3>
                                      </Link>
                                      <Link to="#">
                                        <p className="desc">
                                          <span className="subscribe-count">
                                            5M
                                          </span>
                                          subscribers
                                        </p>
                                      </Link>
                                      <button
                                        type="button"
                                        class="subscribe-btn btn btn-primary"
                                      >
                                        SUBSCRIBE
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xs-15 text-center">
                                  <div className="channel-base-channel-box">
                                    <div className="channel-base-user-img-sec">
                                      <Link to="#">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/base-product/profile-img.jpg"
                                          }
                                          className="user-img"
                                        />
                                      </Link>
                                    </div>
                                    <div className="video-middle-content">
                                      <Link to="#">
                                        <h3 className="title">Ir Wog</h3>
                                      </Link>
                                      <Link to="#">
                                        <p className="desc">
                                          <span className="subscribe-count">
                                            5M
                                          </span>
                                          subscribers
                                        </p>
                                      </Link>
                                      <button
                                        type="button"
                                        class="subscribe-btn btn btn-primary"
                                      >
                                        SUBSCRIBE
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xs-15 text-center">
                                  <div className="channel-base-channel-box">
                                    <div className="channel-base-user-img-sec">
                                      <Link to="#">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/base-product/profile-img.jpg"
                                          }
                                          className="user-img"
                                        />
                                      </Link>
                                    </div>
                                    <div className="video-middle-content">
                                      <Link to="#">
                                        <h3 className="title">Ir Wog</h3>
                                      </Link>
                                      <Link to="#">
                                        <p className="desc">
                                          <span className="subscribe-count">
                                            5M
                                          </span>
                                          subscribers
                                        </p>
                                      </Link>
                                      <button
                                        type="button"
                                        class="subscribe-btn btn btn-primary"
                                      >
                                        SUBSCRIBE
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xs-15 text-center">
                                  <div className="channel-base-channel-box">
                                    <div className="channel-base-user-img-sec">
                                      <Link to="#">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/base-product/profile-img.jpg"
                                          }
                                          className="user-img"
                                        />
                                      </Link>
                                    </div>
                                    <div className="video-middle-content">
                                      <Link to="#">
                                        <h3 className="title">Ir Wog</h3>
                                      </Link>
                                      <Link to="#">
                                        <p className="desc">
                                          <span className="subscribe-count">
                                            5M
                                          </span>
                                          subscribers
                                        </p>
                                      </Link>
                                      <button
                                        type="button"
                                        class="subscribe-btn btn btn-primary"
                                      >
                                        SUBSCRIBE
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xs-15 text-center">
                                  <div className="channel-base-channel-box">
                                    <div className="channel-base-user-img-sec">
                                      <Link to="#">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/base-product/profile-img.jpg"
                                          }
                                          className="user-img"
                                        />
                                      </Link>
                                    </div>
                                    <div className="video-middle-content">
                                      <Link to="#">
                                        <h3 className="title">Ir Wog</h3>
                                      </Link>
                                      <Link to="#">
                                        <p className="desc">
                                          <span className="subscribe-count">
                                            5M
                                          </span>
                                          subscribers
                                        </p>
                                      </Link>
                                      <button
                                        type="button"
                                        class="subscribe-btn btn btn-primary"
                                      >
                                        SUBSCRIBE
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xs-15 text-center">
                                  <div className="channel-base-channel-box">
                                    <div className="channel-base-user-img-sec">
                                      <Link to="#">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/base-product/profile-img.jpg"
                                          }
                                          className="user-img"
                                        />
                                      </Link>
                                    </div>
                                    <div className="video-middle-content">
                                      <Link to="#">
                                        <h3 className="title">Ir Wog</h3>
                                      </Link>
                                      <Link to="#">
                                        <p className="desc">
                                          <span className="subscribe-count">
                                            5M
                                          </span>
                                          subscribers
                                        </p>
                                      </Link>
                                      <button
                                        type="button"
                                        class="subscribe-btn btn btn-primary"
                                      >
                                        SUBSCRIBE
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </Row>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="sixth">
                          <div className="channel-base-about-sec">
                            <div className="channel-base-about-header-sec">
                              <Row>
                                <Col sm={12} md={7}>
                                  <div className="channel-base-about-description-sec">
                                    <h3 className="title">Description</h3>
                                    <h4 className="sub-title">
                                      Just a common man
                                    </h4>
                                  </div>
                                  <div className="channel-base-about-description-sec">
                                    <h3 className="title">Details</h3>
                                    <div className="channel-base-about-email-sec">
                                      <h4 className="sub-desc">
                                        For business inquiries:
                                      </h4>
                                      <Button className="subscribe-btn">
                                        VIEW EMAIL ADDRESS
                                      </Button>
                                    </div>
                                    <div className="channel-base-about-location-sec">
                                      <h4 className="sub-desc-1">Location:</h4>
                                      <h4 className="sub-desc">India</h4>
                                    </div>
                                  </div>
                                  <div className="channel-base-about-links-sec">
                                    <h3 className="title">Links</h3>
                                    <div className="channel-social-share-link-sec">
                                      <Link to="#" className="right-padding">
                                        Instagram
                                      </Link>
                                      <Link to="#">Twitter</Link>
                                    </div>
                                    <Link to="#" className="membership-link">
                                      YouTube channel membership
                                    </Link>
                                  </div>
                                </Col>
                                <Col sm={12} md={3}>
                                  <div className="channel-base-about-status-sec">
                                    <h3 className="title">Status</h3>
                                    <h4 className="desc">Joined Dec 5, 2013</h4>
                                    <h4 className="desc">643,941,281 views</h4>
                                    <i className="icofont-flag"></i>
                                  </div>
                                </Col>
                                <Col sm={12} md={2}>
                                  <div className="channel-base-more-channel-list">
                                    {" "}
                                    <h3 className="title-sub">MG SQUARD</h3>
                                    <div className="more-channel">
                                      <div className="more-channel-img-sec">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/img/base-product/profile-img.jpg"
                                          }
                                          className="user-more-channel-img"
                                        />
                                      </div>
                                      <h6>Ir Wog</h6>
                                    </div>
                                    <Button className="subscribe-btn">
                                      SUBSCRIBE
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChannelView;
