import {
  FETCH_LIVETV_START,
  FETCH_LIVETV_SUCCESS,
  FETCH_LIVETV_FAILURE,
  FETCH_SINGLE_LIVETV_START,
  FETCH_SINGLE_LIVETV_SUCCESS,
  FETCH_SINGLE_LIVETV_FAILURE,
} from "./ActionConstant";

// fetch live tv action.

export function fetchLiveTvStart(data) {
  return {
    type: FETCH_LIVETV_START,
    data,
  };
}

export function fetchLiveTvSuccess(data) {
  return {
    type: FETCH_LIVETV_SUCCESS,
    data,
  };
}

export function fetchLiveTvFailure(error) {
  return {
    type: FETCH_LIVETV_FAILURE,
    error,
  };
}

// single live tv action.

export function fetchSingleLiveTvStart(data) {
  return {
    type: FETCH_SINGLE_LIVETV_START,
    data,
  };
}

export function fetchSingleLiveTvSuccess(data) {
  return {
    type: FETCH_SINGLE_LIVETV_SUCCESS,
    data,
  };
}

export function fetchSingleLiveTvFailure(error) {
  return {
    type: FETCH_SINGLE_LIVETV_FAILURE,
    error,
  };
}
