import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Tabs,
  Tab,
  Dropdown,
  DropdownButton,
  Image,
  ListGroup,
  Table,
  Media,
} from "react-bootstrap";
import "./LiveVideo.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class SingleLiveVideo extends Component {
  render() {
    return (
      <>
        <div className="single-live-video-sec">
          <Container>
          <Row>
            <Col sm={12} md={12}>
              <h2 className="section-title">Single Live Video</h2>
            </Col>
          </Row>
            <Row>
              <Col sm={12} md={12}>
                <div className="single-live-video-card">
                  <Row>
                    <Col sm={12} md={7}>
                    <div className="video-casting-sec">
                      <div className="video-image-sec">
                        <Image
                          src="assets/img/video-1.jpg"
                          className="video-img"
                        />
                      </div>
                      <div className="border-bottom-2"></div>
                      <div className="video-flex-data">
                        <div className="video-info">
                          <Button className="free-btn">Free</Button>
                          <Button className="view-btn">
                            <FontAwesomeIcon
                              icon={["fas", "eye"]}
                              className="menu-icons clear-icons"
                            />
                            &nbsp;<span id="viewers_cnt">0</span> Views
                          </Button>
                          <div className="bg-facebook">
                          <Link
                            to="#"
                            target="_blank"
                            className=""
                          >
                            <FontAwesomeIcon
                              icon={["fab", "facebook"]}
                              className="menu-icons clear-icons"
                            />
                          </Link>
                          </div>
                          <div className="bg-twitter">
                          <Link to="#" >
                            <FontAwesomeIcon
                              icon={["fab", "twitter"]}
                              className="menu-icons clear-icons"
                            />
                          </Link>
                          </div>
                        </div>
                        <div className="video-action-btn">
                          <Button type="button" className="stop-btn">
                            Stop
                          </Button>
                        </div>
                      </div>
                      </div>
                      <div className="video-content">
                        <h4 className="title">Testing</h4>
                        <div className="small">Streaming By User From  18-09-2020 01:13 PM </div>
                        <p className="desc">testing</p>
                      </div>
                    </Col>
                    <Col sm={12} md={5} className="padding-left-sm">
                      <div className="chat-card">
                        <div className="chat-header">
                          <FontAwesomeIcon
                            icon={["fas", "comments"]}
                            className="menu-icons clear-icons"
                          />
                          &nbsp;&nbsp; Group Chat
                        </div>
                        <div class="chat-content">
                          <div className="chat-box-scroll">
                            <div class="chat-box">
                              <div className="user-img-sec">
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/img/profile-img.jpg"
                                  }
                                  className="user-img"
                                />
                                <p className="time-right">11:00</p>
                              </div>
                              <p className="text-align-left">
                                Hello. How are you today?
                              </p>
                            </div>

                            <div class="chat-box darker">
                              <div className="user-img-sec">
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/img/profile-img.jpg"
                                  }
                                  className="user-img"
                                />
                                <p className="time-left">11:01</p>
                              </div>
                              <p className="text-align-right">
                                Hey! I'm fine. Thanks for asking!
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="chat-footer">
                          <InputGroup className="">
                            <FormControl
                              placeholder="Enter the message...."
                              aria-describedby="basic-addon2"
                            />
                            <InputGroup.Append>
                              <InputGroup.Text id="basic-addon2">
                                <Link to="#">
                                  <FontAwesomeIcon
                                    icon={["fas", "paper-plane"]}
                                    className="menu-icons clear-icons"
                                  />
                                </Link>
                              </InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default SingleLiveVideo;
