import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";

class SideBarIndex extends Component {
  state = {};
  render() {
    return <>Sidebar</>;
  }
}

export default SideBarIndex;
