import React from "react"
import ContentLoader from "react-content-loader"

const RedeemsLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1350}
    height={750}
    viewBox="0 0 1200 750"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="15" rx="5" ry="5" width="1200" height="56" /> 
    <rect x="540" y="103" rx="0" ry="0" width="162" height="9" /> 
    <rect x="9" y="130" rx="5" ry="5" width="580" height="166" /> 
    <rect x="620" y="132" rx="5" ry="5" width="580" height="141" /> 
    <rect x="9" y="314" rx="5" ry="5" width="580" height="166" /> 
    <rect x="620" y="286" rx="5" ry="5" width="580" height="141" /> 
    <rect x="620" y="442" rx="5" ry="5" width="580" height="141" /> 
    <rect x="1050" y="599" rx="5" ry="5" width="150" height="36" /> 
    <rect x="0" y="657" rx="10" ry="10" width="1200" height="65" />
  </ContentLoader>
)

export default RedeemsLoader;