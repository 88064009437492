import React, { Component } from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import Base from "./components/Base";
import store from "./store";
import { createBrowserHistory as createHistory } from "history";
import "react-redux-notify/dist/ReactReduxNotify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faCheckSquare,
  faCoffee,
  fas,
} from "@fortawesome/free-solid-svg-icons";

library.add(fab, faCheckSquare, faCoffee, fas);

const history = createHistory();
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <Base />
        </Router>
      </Provider>
    );
  }
}

export default App;
