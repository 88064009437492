import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Tabs,
  Tab,
  Dropdown,
  DropdownButton,
  Image,
  ListGroup,
  Table,
  Media,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class BroadcastChat extends Component {
  state = {};
  render() {
    const {
      loadMore,
      loadingContent,
      loadMoreButtonDisable,
      chatData,
      loadingChatData,
      handleChatSubmit,
      chatInputChange,
    } = this.props;
    return (
      <Col sm={12} md={5} className="padding-left-sm">
        <div className="chat-card">
          <div className="chat-header">
            <FontAwesomeIcon
              icon={["fas", "comments"]}
              className="menu-icons clear-icons"
            />
            &nbsp;&nbsp; Group Chat
          </div>
          <div class="chat-content">
            <div className="chat-box-scroll">
              {loadingChatData
                ? ""
                : chatData.length > 0
                ? chatData.map((chatDetails) => (
                    <div class="chat-box darker">
                      <div className="user-img-sec">
                        <Image
                          src={chatDetails.user_picture}
                          className="user-img"
                        />
                        <p className="time-left">{chatDetails.user_name}</p>
                      </div>
                      <p className="text-align-right">{chatDetails.message}</p>
                    </div>
                  ))
                : ""}
            </div>
          </div>
          <div className="chat-footer">
            <Form onSubmit={handleChatSubmit}>
              <InputGroup className="">
                <FormControl
                  placeholder="Enter the message...."
                  aria-describedby="basic-addon2"
                  value={this.props.chatInputMessage}
                  onChange={chatInputChange}
                />
                <InputGroup.Append>
                  <InputGroup.Text id="basic-addon2">
                    <Link to="#" onClick={handleChatSubmit}>
                      <FontAwesomeIcon
                        icon={["fas", "paper-plane"]}
                        className="menu-icons clear-icons"
                      />
                    </Link>
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form>
          </div>
        </div>
      </Col>
    );
  }
}

export default BroadcastChat;
