import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Tabs,
  Tab,
  Dropdown,
  DropdownButton,
  Image,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import configuration from "react-global-configuration";

class NewHeader extends Component {
  state = {};
  render() {
    return (
      <>
        <div className="header">
          <Container>
            <Navbar expand="lg">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Brand href="#home">
                <Link to="/">
                  <Image
                    src={configuration.get("configData.site_icon")}
                    className="logo"
                  />
                </Link>
              </Navbar.Brand>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  <Nav.Link href="#home" active>
                    Popular
                  </Nav.Link>
                  <Nav.Link href="/browse-categories">Categories</Nav.Link>
                  <Nav.Link href="/browse-channels">Channels</Nav.Link>
                  <Form className="header-form-search-bar">
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <Link to="/searched-video">
                            <FontAwesomeIcon icon="search" />
                          </Link>
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl aria-label="" placeholder="Search" />
                      <InputGroup.Append>
                        <InputGroup.Text>
                          <Link to="/searched-video">
                            <Image
                              src="assets/img/video.png"
                              className="mr-3 video-icon"
                            />
                          </Link>
                          <Link to="/searched-video">
                            <FontAwesomeIcon icon="chevron-down" />
                          </Link>
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form>
                </Nav>
                <Nav className="ml-auto">
                  <Nav.Link href="/signin">Login</Nav.Link>
                  <Nav.Link href="/signup">Signup</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Container>
        </div>
      </>
    );
  }
}

export default NewHeader;
