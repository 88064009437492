import React, { useEffect, useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  eraseOldLSStart,
  saveLSOwnerStart,
} from "../../store/actions/LiveStreamingAction";

const LSConfirmation = (props) => {
  const [inputData, setInputData] = useState({});

  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(saveLSOwnerStart(inputData));
  };

  const handleConfirm = () => {
    props.dispatch(eraseOldLSStart());
    setShow(false);
  };

  const handleClose = () => {
    setShow(false);
    if (Object.keys(props.saveLSOwner.inputData).length === 0)
      props.history.push("/channel-list");
    else
      props.history.push(
        `/my-channel/${props.saveLSOwner.inputData.channel_id}`
      );
  };

  const handleShow = () => setShow(true);

  return (
    <>
      <Modal show={show} onHide={handleClose} className="golive-modal">
        <Modal.Header closeButton>
          <Modal.Title>Stop Live Streaming</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you want to stop all the old Live streaming</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  saveLSOwner: state.liveStreaming.saveLSOwner,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(LSConfirmation);
