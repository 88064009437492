import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Container, Row, Col } from "react-bootstrap";
import "./BroadCasting.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VideoConnecting from "./VideoConnecting";
import api from "../../Environment";
import io from "socket.io-client";
import config from "react-global-configuration";
import { Redirect } from "react-router-dom";
import BroadcastChat from "./BroadcastChat";
import { connect } from "react-redux";
import {
  endLSOwnerStart,
  fetchSingleLSOwnerStart,
} from "../../store/actions/LiveStreamingAction";



const $ = window.$;
let chatSocket;

class LiveStreamingIndex extends Component {
  state = {
    subscribers: [],
    chatSocket: false,
    customizeData: {
      audioMute: true,
      audioUnmute: false,
      videoMute: true,
      videoUnmute: false,
      recordStatus: false,
    },
    snapshot: null,
    startBroadcastInputData: {},
    modelLoadingContent: null,
    modelButtonDisabled: false,
    liveVideoData: {
      live_video_id: 242452,
    },
    loadingLiveVideo: true,
    liveVideoSuggesstion: null,
    loadingLiveVideoSuggesstion: true,
    chatData: null,
    loadingChatData: true,
    chatInputMessage: "",
    loadMoreButtonDisable: false,
    loadingContent: null,
    skipCount: 0,
    viewerCount: 0,
    streamingStatus: false,
    viewerProfileRedirect: false,
    streamingType: null,
    mainStreamer: false,
    loadingStreamingPage: true,
    apiCallstatus: false,
    startBroadcastButtonDisable: false,
    startBroadcastButtonLoadingContent: null,
  };

  async componentDidMount() {

    // await this.props.dispatch(
    //   fetchSingleLSOwnerStart({
    //     live_video_id: this.props.match.params.live_video_id,
    //   })
    // );
    
    // if (this.props.location.state !== undefined) {
    //   this.watchStreaming();
    // } else this.startStreaming();

  }


  stopBroadcastAPI = () => {
    this.props.dispatch(
      endLSOwnerStart({
        live_video_id: this.props.match.params.live_video_id,
      })
    );
  };

  viewerUpdateAPI = () => {
    const inputData = {
      live_video_id: this.props.singleLSOwner.data.live_video_id,
    };
    api.postMethod("live_videos_viewer_update", inputData).then((response) => {
      if (response.data.success) {
        //Notificaiton
      } else {
        //Notificaiton
        if (
          response.data.error_code == 169 ||
          response.data.error_code == 170 ||
          response.data.error_code == 171
        ) {
          this.props.history.push("/");
        }
      }
    });
  };

  getLiveVideoChatDetails = () => {
    const inputData = {
      live_video_id: this.props.singleLSOwner.data.live_video_id,
      skip: this.state.skipCount,
    };
    this.getLiveVideoChatAPI(inputData);
  };

  loadMore = (event) => {
    event.preventDefault();
    this.setState({
      loadMoreButtonDisable: true,
      loadingContent: "Loading...",
    });
    const inputData = {
      live_video_id: this.props.singleLSOwner.data.live_video_id,
      skip: this.state.skipCount,
    };

    this.getLiveVideoChatAPI(inputData);
  };

  getLiveVideoChatAPI = (inputData) => {
    let items;
    api.postMethod("live_videos_messages", inputData).then((response) => {

      if (response.data.success) {
        if (this.state.chatData != null) {
          items = [...this.state.chatData, ...response.data.data.messages];
        } else {
          items = [...response.data.data.messages];
        }
        items = items.reverse();
        this.setState({
          chatData: items,
          loadingChatData: false,
          skipCount: response.data.data.length + this.state.skipCount,
          loadMoreButtonDisable: false,
          loadingContent: null,
        });
      } else {
      }
    });
  };

  chatSocketConnect = () => {
    
    // check the socket url is configured
    let chatSocketUrl = config.get("configData.chat_socket_url");
    console.log("chatSocketUrl" + chatSocketUrl);
    if (chatSocketUrl && Object.keys(this.props.singleLSOwner.data).length > 0) {
      chatSocket = io(chatSocketUrl, {
        query: `room: '` + this.props.singleLSOwner.data.unique_id + `'`,
      });

      chatSocket.emit("update sender", {
        room: this.props.singleLSOwner.data.unique_id,
      });
      let chatContent;
      chatSocket.on("message", (newData) => {
        let content = [];
        content.push(newData);
        chatContent = [...this.state.chatData, ...content];
        this.setState({ chatData: chatContent });
      });
    }
  };

  handleChatSubmit = (event) => {
    console.log(this.props.singleLSOwner.data);
    event.preventDefault();
    const type =
      this.props.singleLSOwner.data.user_id == localStorage.getItem("userId")
        ? "uv"
        : "vu";
    let chatData = [
      {
        live_video_id: this.props.singleLSOwner.data.live_video_id,
        user_id: this.props.singleLSOwner.data.user_id,
        live_video_viewer_id: localStorage.getItem("userId"),
        message: this.state.chatInputMessage,
        type: type,
        user_name: localStorage.getItem("username"),
        user_picture: localStorage.getItem("user_picture"),
        username: localStorage.getItem("username"),
        userpicture: localStorage.getItem("user_picture"),
      },
    ];
    chatSocket.emit("message", chatData[0]);
    let messages;
    if (this.state.chatData != null) {
      messages = [...this.state.chatData, ...chatData];
    } else {
      messages = [...chatData];
    }
    this.setState({
      chatData: messages,
      chatInputMessage: "",
    });
  };

  chatInputChange = ({ currentTarget: input }) => {
    this.setState({ chatInputMessage: input.value });
  };

  render() {
    const {
      loadingContent,
      loadMoreButtonDisable,
      chatData,
      loadingChatData,
    } = this.state;
    return (
      <>
        <div className="LiveStreamingIndex-sec">
          <Container>
            <Row>
              <Col sm={12} md={12}>
                <h2 className="section-title">Broadcast Video</h2>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12}>
                <div className="LiveStreamingIndex-card">
                  <Row>
                    {this.props.singleLSOwner.loading ? (
                      "Loading...."
                    ) : (
                      <Col sm={12} md={7}>
                        <div className="video-casting-sec">
                          <div className="video-image-sec">
                            {this.state.subscribers.length > 0 ? (
                              <div className="relative">
                                {this.state.subscribers.map((sub, i) => (
                                  <div key={i}>
                                  {/* Live streaming html code needs to added here.*/}
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div className="relative">
                                <img
                                  src="/assets/img/stream.jpg"
                                  className="img-responsive broadcast-img"
                                />
                                <div className="broadcast-btn">
                                  <button
                                    className="btn"
                                    type="button"
                                    // onClick={this.startStreaming}
                                    data-toggle="modal"
                                    data-target="#start_broadcast"
                                    disabled={
                                      this.state.startBroadcastButtonDisable
                                    }
                                  >
                                    {this.state
                                      .startBroadcastButtonLoadingContent !=
                                    null
                                      ? this.state
                                          .startBroadcastButtonLoadingContent
                                      : "START LiveStreamingIndex"}
                                  </button>
                                </div>
                              </div>
                            )}
                            {/* <Image
                            src="assets/img/video-1.jpg"
                            className="video-img"
                          /> */}
                          </div>
                          <div className="border-bottom-2"></div>
                          <div className="video-flex-data">
                            <div className="video-info">
                              <Button className="free-btn">Free</Button>
                              <Button className="view-btn">
                                <FontAwesomeIcon
                                  icon={["fas", "eye"]}
                                  className="menu-icons clear-icons"
                                />
                                &nbsp;<span id="viewers_cnt">0</span> Views
                              </Button>
                              <div className="bg-facebook">
                                <Link to="#" target="_blank" className="">
                                  <FontAwesomeIcon
                                    icon={["fab", "facebook"]}
                                    className="menu-icons clear-icons"
                                  />
                                </Link>
                              </div>
                              <div className="bg-twitter">
                                <Link to="#">
                                  <FontAwesomeIcon
                                    icon={["fab", "twitter"]}
                                    className="menu-icons clear-icons"
                                  />
                                </Link>
                              </div>
                            </div>
                            <div className="video-action-btn">
                              <Button
                                type="button"
                                className="stop-btn"
                                onClick={this.stopStreaming}
                              >
                                Stop
                              </Button>
                            </div>
                          </div>
                        </div>

                        <div className="video-content">
                          <h4 className="title">
                            {this.props.singleLSOwner.data.title}
                          </h4>
                          <div className="small">
                            Streaming By User From{" "}
                            {this.props.singleLSOwner.data.start_time}
                          </div>
                          <p className="desc">
                            {this.props.singleLSOwner.data.description}
                          </p>
                        </div>
                      </Col>
                    )}
                    <BroadcastChat
                      loadMore={this.loadMore}
                      chatData={chatData}
                      loadingChatData={loadingChatData}
                      loadingContent={loadingContent}
                      loadMoreButtonDisable={loadMoreButtonDisable}
                      chatInputMessage={this.state.chatInputMessage}
                      chatInputChange={this.chatInputChange}
                      handleChatSubmit={this.handleChatSubmit}
                    />
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToPros = (state) => ({
  singleLSOwner: state.liveStreaming.singleLSOwner,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(LiveStreamingIndex);
