import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Tabs,
  Tab,
  Dropdown,
  DropdownButton,
  Image,
  ListGroup,
} from "react-bootstrap";
import "./Subscription.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Subscription extends Component {
  render() {
    return (
      <>
        <div className="video-subscription-sec">
            <Container>
                <Row>
                    <Col sm={12} md={12}>
                      <div className="video-subscriptions-content">
                        <h4 className="title">Updates from Subscriptions</h4>
                        <FontAwesomeIcon icon="chevron-up" />
                      </div>
                      <div className="video-subscription-info">
                        <Row>
                          <Col sm={12} md={12}>
                            <div className="video-subscription-header">
                              <div className="user-img-sec">
                                <img
                                  src="assets/img/profile-img.jpg"
                                  className="user-img"
                                />
                                <h4 className="user-name">Naughty Dog .<span className="active-txt">Last active 3 hours ago</span></h4>
                              </div>
                              <div className="cancel-subscription">
                                <FontAwesomeIcon
                                  icon={["fas", "times"]}
                                  className="menu-icons"
                                />
                              </div>
                            </div>
                            <div className="video-subscription-body">
                              <Row>
                                <Col sm={12} md={3}>
                                  <Link to="#">
                                    <div className="video-subscription-card">
                                      <div className="video-subscription-card-img">
                                        <Image
                                          src="assets/img/video-1.jpg"
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="video-subscription-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New Gameplay
                                          - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-1"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                      <div className="hover-content">
                                        <Dropdown className="video-dropdown-sec">
                                          <Dropdown.Toggle
                                            className="video-dropdown"
                                            id="dropdown-basic"
                                          >
                                            <Image src="assets/img/plus.png" />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu className="video-dropdown-menu">
                                            <Dropdown.Item href="#/action-1">
                                              Watch Later
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">
                                              Add to Playlist
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                                <Col sm={12} md={3}>
                                  <Link to="#">
                                    <div className="video-subscription-card">
                                      <div className="video-subscription-card-img">
                                        <Image
                                          src="assets/img/video-2.jpg"
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="video-subscription-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New Gameplay
                                          - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-1"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                      <div className="hover-content">
                                        <Dropdown className="video-dropdown-sec">
                                          <Dropdown.Toggle
                                            className="video-dropdown"
                                            id="dropdown-basic"
                                          >
                                            <Image src="assets/img/plus.png" />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu className="video-dropdown-menu">
                                            <Dropdown.Item href="#/action-1">
                                              Watch Later
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">
                                              Add to Playlist
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                                <Col sm={12} md={3}>
                                  <Link to="#">
                                    <div className="video-subscription-card">
                                      <div className="video-subscription-card-img">
                                        <Image
                                          src="assets/img/video-3.jpg"
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="video-subscription-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New Gameplay
                                          - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-1"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                      <div className="hover-content">
                                        <Dropdown className="video-dropdown-sec">
                                          <Dropdown.Toggle
                                            className="video-dropdown"
                                            id="dropdown-basic"
                                          >
                                            <Image src="assets/img/plus.png" />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu className="video-dropdown-menu">
                                            <Dropdown.Item href="#/action-1">
                                              Watch Later
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">
                                              Add to Playlist
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={12}>
                            <div className="video-subscription-header">
                              <div className="user-img-sec">
                                <img
                                  src="assets/img/profile-img.jpg"
                                  className="user-img"
                                />
                                <h4 className="user-name">Naughty Dog .<span className="active-txt">Last active 6 hours ago</span></h4>
                              </div>
                              <div className="cancel-subscription">
                                <FontAwesomeIcon
                                  icon={["fas", "times"]}
                                  className="menu-icons"
                                />
                              </div>
                            </div>
                            <div className="video-subscription-body">
                              <Row>
                                <Col sm={12} md={3}>
                                  <Link to="#">
                                    <div className="video-subscription-card">
                                      <div className="video-subscription-card-img">
                                        <Image
                                          src="assets/img/video-1.jpg"
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="video-subscription-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New Gameplay
                                          - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-1"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                      <div className="hover-content">
                                        <Dropdown className="video-dropdown-sec">
                                          <Dropdown.Toggle
                                            className="video-dropdown"
                                            id="dropdown-basic"
                                          >
                                            <Image src="assets/img/plus.png" />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu className="video-dropdown-menu">
                                            <Dropdown.Item href="#/action-1">
                                              Watch Later
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">
                                              Add to Playlist
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                                <Col sm={12} md={3}>
                                  <Link to="#">
                                    <div className="video-subscription-card">
                                      <div className="video-subscription-card-img">
                                        <Image
                                          src="assets/img/video-2.jpg"
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="video-subscription-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New Gameplay
                                          - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-1"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                      <div className="hover-content">
                                        <Dropdown className="video-dropdown-sec">
                                          <Dropdown.Toggle
                                            className="video-dropdown"
                                            id="dropdown-basic"
                                          >
                                            <Image src="assets/img/plus.png" />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu className="video-dropdown-menu">
                                            <Dropdown.Item href="#/action-1">
                                              Watch Later
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">
                                              Add to Playlist
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                                <Col sm={12} md={3}>
                                  <Link to="#">
                                    <div className="video-subscription-card">
                                      <div className="video-subscription-card-img">
                                        <Image
                                          src="assets/img/video-3.jpg"
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="video-subscription-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New Gameplay
                                          - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-1"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                      <div className="hover-content">
                                        <Dropdown className="video-dropdown-sec">
                                          <Dropdown.Toggle
                                            className="video-dropdown"
                                            id="dropdown-basic"
                                          >
                                            <Image src="assets/img/plus.png" />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu className="video-dropdown-menu">
                                            <Dropdown.Item href="#/action-1">
                                              Watch Later
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">
                                              Add to Playlist
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                                <Col sm={12} md={3}>
                                  <Link to="#">
                                    <div className="video-subscription-card">
                                      <div className="video-subscription-card-img">
                                        <Image
                                          src="assets/img/video-2.jpg"
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="video-subscription-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New Gameplay
                                          - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-1"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                      <div className="hover-content">
                                        <Dropdown className="video-dropdown-sec">
                                          <Dropdown.Toggle
                                            className="video-dropdown"
                                            id="dropdown-basic"
                                          >
                                            <Image src="assets/img/plus.png" />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu className="video-dropdown-menu">
                                            <Dropdown.Item href="#/action-1">
                                              Watch Later
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">
                                              Add to Playlist
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={12}>
                            <div className="video-subscription-header">
                              <div className="user-img-sec">
                                <img
                                  src="assets/img/profile-img.jpg"
                                  className="user-img"
                                />
                                <h4 className="user-name">Naughty Dog .<span className="active-txt">Last active 4 days ago</span></h4>
                              </div>
                              <div className="cancel-subscription">
                                <FontAwesomeIcon
                                  icon={["fas", "times"]}
                                  className="menu-icons"
                                />
                              </div>
                            </div>
                            <div className="video-subscription-body">
                              <Row>
                                <Col sm={12} md={3}>
                                  <Link to="#">
                                    <div className="video-subscription-card">
                                      <div className="video-subscription-card-img">
                                        <Image
                                          src="assets/img/video-1.jpg"
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="video-subscription-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New Gameplay
                                          - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-1"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                      <div className="hover-content">
                                        <Dropdown className="video-dropdown-sec">
                                          <Dropdown.Toggle
                                            className="video-dropdown"
                                            id="dropdown-basic"
                                          >
                                            <Image src="assets/img/plus.png" />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu className="video-dropdown-menu">
                                            <Dropdown.Item href="#/action-1">
                                              Watch Later
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">
                                              Add to Playlist
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                                <Col sm={12} md={3}>
                                  <Link to="#">
                                    <div className="video-subscription-card">
                                      <div className="video-subscription-card-img">
                                        <Image
                                          src="assets/img/video-2.jpg"
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="video-subscription-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New Gameplay
                                          - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-1"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                      <div className="hover-content">
                                        <Dropdown className="video-dropdown-sec">
                                          <Dropdown.Toggle
                                            className="video-dropdown"
                                            id="dropdown-basic"
                                          >
                                            <Image src="assets/img/plus.png" />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu className="video-dropdown-menu">
                                            <Dropdown.Item href="#/action-1">
                                              Watch Later
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">
                                              Add to Playlist
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={12}>
                            <div className="video-subscription-header">
                              <div className="user-img-sec">
                                <img
                                  src="assets/img/profile-img.jpg"
                                  className="user-img"
                                />
                                <h4 className="user-name">Naughty Dog .<span className="active-txt">Last active 20 min ago</span></h4>
                              </div>
                              <div className="cancel-subscription">
                                <FontAwesomeIcon
                                  icon={["fas", "times"]}
                                  className="menu-icons"
                                />
                              </div>
                            </div>
                            <div className="video-subscription-body">
                              <Row>
                                <Col sm={12} md={3}>
                                  <Link to="#">
                                    <div className="video-subscription-card">
                                      <div className="video-subscription-card-img">
                                        <Image
                                          src="assets/img/video-1.jpg"
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="video-subscription-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New Gameplay
                                          - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-1"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                      <div className="hover-content">
                                        <Dropdown className="video-dropdown-sec">
                                          <Dropdown.Toggle
                                            className="video-dropdown"
                                            id="dropdown-basic"
                                          >
                                            <Image src="assets/img/plus.png" />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu className="video-dropdown-menu">
                                            <Dropdown.Item href="#/action-1">
                                              Watch Later
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">
                                              Add to Playlist
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                                <Col sm={12} md={3}>
                                  <Link to="#">
                                    <div className="video-subscription-card">
                                      <div className="video-subscription-card-img">
                                        <Image
                                          src="assets/img/video-2.jpg"
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="video-subscription-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New Gameplay
                                          - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-1"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                      <div className="hover-content">
                                        <Dropdown className="video-dropdown-sec">
                                          <Dropdown.Toggle
                                            className="video-dropdown"
                                            id="dropdown-basic"
                                          >
                                            <Image src="assets/img/plus.png" />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu className="video-dropdown-menu">
                                            <Dropdown.Item href="#/action-1">
                                              Watch Later
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">
                                              Add to Playlist
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                                <Col sm={12} md={3}>
                                  <Link to="#">
                                    <div className="video-subscription-card">
                                      <div className="video-subscription-card-img">
                                        <Image
                                          src="assets/img/video-3.jpg"
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="video-subscription-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New Gameplay
                                          - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-1"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                      <div className="hover-content">
                                        <Dropdown className="video-dropdown-sec">
                                          <Dropdown.Toggle
                                            className="video-dropdown"
                                            id="dropdown-basic"
                                          >
                                            <Image src="assets/img/plus.png" />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu className="video-dropdown-menu">
                                            <Dropdown.Item href="#/action-1">
                                              Watch Later
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">
                                              Add to Playlist
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={12}>
                            <div className="video-subscription-header">
                              <div className="user-img-sec">
                                <img
                                  src="assets/img/profile-img.jpg"
                                  className="user-img"
                                />
                                <h4 className="user-name">Naughty Dog .<span className="active-txt">Last active 30 min ago</span></h4>
                              </div>
                              <div className="cancel-subscription">
                                <FontAwesomeIcon
                                  icon={["fas", "times"]}
                                  className="menu-icons"
                                />
                              </div>
                            </div>
                            <div className="video-subscription-body last-child">
                              <Row>
                                <Col sm={12} md={3}>
                                  <Link to="#">
                                    <div className="video-subscription-card">
                                      <div className="video-subscription-card-img">
                                        <Image
                                          src="assets/img/video-1.jpg"
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="video-subscription-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New Gameplay
                                          - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-1"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                      <div className="hover-content">
                                        <Dropdown className="video-dropdown-sec">
                                          <Dropdown.Toggle
                                            className="video-dropdown"
                                            id="dropdown-basic"
                                          >
                                            <Image src="assets/img/plus.png" />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu className="video-dropdown-menu">
                                            <Dropdown.Item href="#/action-1">
                                              Watch Later
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">
                                              Add to Playlist
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                                <Col sm={12} md={3}>
                                  <Link to="#">
                                    <div className="video-subscription-card">
                                      <div className="video-subscription-card-img">
                                        <Image
                                          src="assets/img/video-2.jpg"
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="video-subscription-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New Gameplay
                                          - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-1"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                      <div className="hover-content">
                                        <Dropdown className="video-dropdown-sec">
                                          <Dropdown.Toggle
                                            className="video-dropdown"
                                            id="dropdown-basic"
                                          >
                                            <Image src="assets/img/plus.png" />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu className="video-dropdown-menu">
                                            <Dropdown.Item href="#/action-1">
                                              Watch Later
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">
                                              Add to Playlist
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                                <Col sm={12} md={3}>
                                  <Link to="#">
                                    <div className="video-subscription-card">
                                      <div className="video-subscription-card-img">
                                        <Image
                                          src="assets/img/video-3.jpg"
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="video-subscription-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New Gameplay
                                          - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-1"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                      <div className="hover-content">
                                        <Dropdown className="video-dropdown-sec">
                                          <Dropdown.Toggle
                                            className="video-dropdown"
                                            id="dropdown-basic"
                                          >
                                            <Image src="assets/img/plus.png" />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu className="video-dropdown-menu">
                                            <Dropdown.Item href="#/action-1">
                                              Watch Later
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">
                                              Add to Playlist
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                                <Col sm={12} md={3}>
                                  <Link to="#">
                                    <div className="video-subscription-card">
                                      <div className="video-subscription-card-img">
                                        <Image
                                          src="assets/img/video-3.jpg"
                                          className="video-img"
                                        />
                                      </div>
                                      <div className="video-subscription-card-details">
                                        <h4 className="title">
                                          No Man's Sky: 21 Minutes of New Gameplay
                                          - IGN First
                                        </h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            27,548 views
                                          </span>
                                          <span className="percentage-count green-clr">
                                            <FontAwesomeIcon
                                              icon="circle"
                                              className="mr-1"
                                            />
                                            93%
                                          </span>
                                        </p>
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                      <div className="hover-content">
                                        <Dropdown className="video-dropdown-sec">
                                          <Dropdown.Toggle
                                            className="video-dropdown"
                                            id="dropdown-basic"
                                          >
                                            <Image src="assets/img/plus.png" />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu className="video-dropdown-menu">
                                            <Dropdown.Item href="#/action-1">
                                              Watch Later
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">
                                              Add to Playlist
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                </Row>
                <Row>
              <Col md={12}>
                <div className="pagination-sec d-none d-sm-block">
                <ListGroup as="ul" className="list-unstyled pagination-list">
                        <ListGroup.Item as="li" action href="#link0">
                          <FontAwesomeIcon icon="arrow-left" />
                        </ListGroup.Item>
                        <ListGroup.Item as="li" action href="#link1">
                          1
                        </ListGroup.Item>
                        <ListGroup.Item as="li" action href="#link2">
                          2
                        </ListGroup.Item>
                        <ListGroup.Item as="li" action href="#link3" active>
                          3
                        </ListGroup.Item>
                        <ListGroup.Item as="li" action href="#link4">
                          4
                        </ListGroup.Item>
                        <ListGroup.Item as="li" action href="#link5">
                          5
                        </ListGroup.Item>
                        <ListGroup.Item as="li" action href="#link6">
                          ...
                        </ListGroup.Item>
                        <ListGroup.Item as="li" action href="#link7">
                          10
                        </ListGroup.Item>
                        <ListGroup.Item as="li" action href="#link8">
                          <FontAwesomeIcon icon="arrow-right" />
                        </ListGroup.Item>
                    </ListGroup>
                </div>
                <div className="mobile-pagination-sec d-block d-sm-none">
                  <ListGroup as="ul" className="list-unstyled mobile-pagination-list">
                    <ListGroup.Item as="li" action href="#link0">
                      <FontAwesomeIcon icon="arrow-left" />
                    </ListGroup.Item>
                    <ListGroup.Item as="li" action href="#link1" active>
                      1
                    </ListGroup.Item>
                    <ListGroup.Item as="li" action href="#link2" className="full-space">
                      Skip 5 Pages
                    </ListGroup.Item>
                    <ListGroup.Item as="li" action href="#link3">
                      <FontAwesomeIcon icon="arrow-right" />
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </Col>
            </Row>
            </Container>
        </div>
      </>
    );
  }
}

export default Subscription;
