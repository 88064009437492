import React from "react";
import {
  Nav,
  Form,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Tabs,
  Tab,
  Dropdown,
  DropdownButton,
  Image,
  Media,
  ListGroup,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./SingleChannel.css";
import ImageLoader from "../helper/ImageLoader";
import { connect } from "react-redux";
import { subUnsubChannelStart } from "../../store/actions/ChannelAction";

const SingleChannelNav = (props) => {
  const subscribeUnsubscribe = () => {
    props.dispatch(
      subUnsubChannelStart({
        channel_id: props.data.channel_id,
      })
    );
  };

  return (
    <Row>
      <Col sm={12} md={12} xl={2} className="d-none d-sm-block">
        <ImageLoader
          image={props.data.channel_image}
          className="single-channel-user-img"
        />
      </Col>
      <Col
        sm={12}
        md={12}
        xl={10}
        className="d-none d-sm-block left-no-padding"
      >
        <Row>
          <Col sm={12} md={12}>
            <Nav variant="pills">
              <h4 className="single-channel-title">
                {props.data.channel_name}
              </h4>
              <br />
              <h6 className="single-channel-title">
                {props.data.no_of_subscribers
                  ? props.data.no_of_subscribers
                  : 0}{" "}
                Subscribers
              </h6>
              <div className="align-center">
                <Nav.Item className="first-child">
                  <Nav.Link eventKey="home">Home</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="single-channel-videos"
                    onClick={props.getChannelVideos}
                  >
                    Videos
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="single-channel-playlist"
                    onClick={props.getChannelPlaylists}
                  >
                    Playlists
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="about">About</Nav.Link>
                </Nav.Item>

                {props.channelType === "owner" ? (
                  <Nav.Item>
                    <Nav.Link onClick={props.showGoliveModal}>Go Live</Nav.Link>
                  </Nav.Item>
                ) : null}
              </div>
              {props.data.is_my_channel === 0 ? (
                <div className="subscribe-details">
                    {props.data.is_user_subscribed_the_channel === 1 ? (
                      <Button className="unsubscribe-btn" onClick={subscribeUnsubscribe}>
                        Unsubscribe
                      </Button>
                    ) : (
                      <Button className="subscribe-btn" onClick={subscribeUnsubscribe}>Subscribe</Button>
                    )}
                </div>
              ) : null}
            </Nav>
          </Col>
        </Row>
      </Col>

      <Col sm={12} md={12} className="d-block d-sm-none">
        <Image
          src={window.location.origin + "/assets/img/profile-img.jpg"}
          className="single-channel-user-img"
        />
        <div className="mobile-single-channel-show">
          <h4 className="single-channel-title">Naughty Dog</h4>
          <div className="resp-subscribe-btn-sec">
            <FontAwesomeIcon icon={["fas", "plus"]} className="" />
          </div>
        </div>
        <div className="mobile-single-channel-list-show">
          <Nav variant="pills">
            <Nav.Item className="first-child">
              <Nav.Link eventKey="home">Home</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="single-channel-videos">Videos</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="single-channel-playlist">Playlist</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="about">About</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={props.showGoliveModal}>Go Live</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
      </Col>
    </Row>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(SingleChannelNav);
