import {
  FETCH_SPAM_VIDEOS_START,
  FETCH_SPAM_VIDEOS_SUCCESS,
  FETCH_SPAM_VIDEOS_FAILURE,
  ADD_TO_SPAM_START,
  ADD_TO_SPAM_SUCCESS,
  ADD_TO_SPAM_FAILURE,
  REMOVE_SPAM_START,
  REMOVE_SPAM_SUCCESS,
  REMOVE_SPAM_FAILURE,
  FETCH_SPAM_REASON_START,
  FETCH_SPAM_REASON_SUCCESS,
  FETCH_SPAM_REASON_FAILURE,
} from "./ActionConstant";

// fetch Spam videos action.

export function fetchSpamVideosStart(data) {
  return {
    type: FETCH_SPAM_VIDEOS_START,
    data,
  };
}

export function fetchSpamVideosSuccess(data) {
  return {
    type: FETCH_SPAM_VIDEOS_SUCCESS,
    data,
  };
}

export function fetchSpamVideosFailure(error) {
  return {
    type: FETCH_SPAM_VIDEOS_FAILURE,
    error,
  };
}

// Add to Spam action.

export function addToSpamStart(data) {
  return {
    type: ADD_TO_SPAM_START,
    data,
  };
}

export function addToSpamSuccess(data) {
  return {
    type: ADD_TO_SPAM_SUCCESS,
    data,
  };
}

export function addToSpamFailure(error) {
  return {
    type: ADD_TO_SPAM_FAILURE,
    error,
  };
}

// Remove Spam action.

export function removeSpamStart(data) {
  return {
    type: REMOVE_SPAM_START,
    data,
  };
}

export function removeSpamSuccess(data) {
  return {
    type: REMOVE_SPAM_SUCCESS,
    data,
  };
}

export function removeSpamFailure(error) {
  return {
    type: REMOVE_SPAM_FAILURE,
    error,
  };
}

// Spam Reason action.

export function fetchSpamReasonStart(data) {
  return {
    type: FETCH_SPAM_REASON_START,
    data,
  };
}

export function fetchSpamReasonSuccess(data) {
  return {
    type: FETCH_SPAM_REASON_SUCCESS,
    data,
  };
}

export function fetchSpamReasonFailure(error) {
  return {
    type: FETCH_SPAM_REASON_FAILURE,
    error,
  };
}
