import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Nav,Form,Button,Container,Row,Col,Tab,Image,Media,
} from "react-bootstrap";
import Select from 'react-select';
import "./UploadVideo.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { connect } from "react-redux";
import { fetchCategoriesStart } from "../../store/actions/CategoryAction";
import { fetchTagsStart } from "../../store/actions/TagsAction";

import { uploadVideoStart,setDefaultImageStart,fetchVideoImagesStart } from "../../store/actions/SingleVideoAction";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";

const UploadVideo = (props) => {
  const [inputData, setInputData] = useState({});

  useEffect(() => {
    if (props.category.loading) props.dispatch(fetchCategoriesStart());
    if (props.tags.loading) props.dispatch(fetchTagsStart());
    setInputData({
      ...inputData,
      channel_id: props.match.params.channel_id,
      video_type: 1,
      video_publish_type : 1,
    });
  }, []);

  const [activeTab, setActiveTab] = useState("video-type");

  const [selectedTags, setSelectedTags] = useState('');

  const [image, setImage] = useState({ preview: "", raw: "" });

  const handleChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
      setInputData({
        ...inputData,
        other_image: e.target.files[0],
      });
    }
  };

  const handleChangeSubTitle = (e) => {
    if (e.target.files.length) {
      setInputData({
        ...inputData,
        subtitle: e.target.files[0],
      });
    }
  };

  const setDefaultImage = (event, video_tape_details) => {
    console.log(video_tape_details);
    event.preventDefault();
    props.dispatch(setDefaultImageStart({ 
      video_tape_id: video_tape_details.video_tape_id, 
      video_tape_image_id: video_tape_details.id,
      status:1,
    }));
  };

  const handleChangeImage = (event, video_tape_details) => {
    console.log(video_tape_details);
    event.preventDefault();
    if (event.currentTarget.type === "file") {
      props.dispatch(setDefaultImageStart({ 
        video_tape_id: video_tape_details.video_tape_id, 
        video_tape_image_id: video_tape_details.id,
        image: event.currentTarget.files[0],
        status:0,
      }));
    }
  };


  const handleSubmit = (event,status) => {
    event.preventDefault();
  
    if(inputData.video_type == 1 && activeTab == "upload-video") {
      setActiveTab("upload-video");
    }
    console.log(activeTab);
    if(activeTab == "upload-video" && status == 'submit') {
      setActiveTab("select-image");
      props.dispatch(uploadVideoStart(inputData));
    }

    if(activeTab == "select-image" && status == 'submit') {
      const notificationMessage = getSuccessNotificationMessage(
        'Video update success'
      );
      props.dispatch(createNotification(notificationMessage));
      window.location.assign("/my-channel/" + props.match.params.channel_id);
    }

  };

  const handleSelect = (eventKey) => alert(`selected ${eventKey}`);
  const handleTagsChange = (inputValue, { action }) => {
    let array = [];
    inputValue.map((value, i) => {     
      console.log(value.id);
      array.push(value.id);
    });
    setInputData({ ...inputData, tag_id: array });
  };
  const handleChangeVideo = (event, fileType) => {

    setInputData({
      ...inputData,
      video: event.target.files[0],
    })
    if (event.currentTarget.type === "file") {
      let reader = new FileReader();
      let file = event.currentTarget.files[0];

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  return (
    <>
      <div className="video-upload-wizard-sec">
        <Container>
          <div className="wizard-box">
            <div className="wizard-header">
              <h2 className="wizard-header-title">Upload Video</h2>
              <p className="wizard-header-desc">
                By submitting your videos to Tubenow , you acknowledge that you
                agree to Tubenow Terms of Service and Privacy Policy
              </p>
            </div>
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="video-type"
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k)}
            >
              <Row>
                <Col sm={12}>
                  <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link eventKey="video-type">Video Type</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="video-details">
                        Video Details
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="upload-video">Upload Video</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="select-image">Select Image</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={12}>
                  <Tab.Content>
                    <Tab.Pane eventKey="video-type">
                      <div className="video-type-sec">
                        <Container>
                          <Row>
                            <Col sm={12} md={4}>
                              <Link
                                to="#"
                                onClick={() => {
                                  setActiveTab("video-details");
                                  setInputData({ ...inputData, video_type: 1 });
                                }}
                              >
                                <div className="video-type-box">
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/img/file-upload.svg"
                                    }
                                    className="video-type-icons"
                                  />
                                  <h5 className="video-type-title">
                                    File Upload
                                  </h5>
                                </div>
                              </Link>
                            </Col>
                            <Col sm={12} md={4}>
                              <Link
                                to="#"
                                onClick={() => {
                                  setActiveTab("video-details");
                                  setInputData({ ...inputData, video_type: 3 });
                                }}
                              >
                                <div className="video-type-box">
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/img/youtube-url-link.svg"
                                    }
                                    className="video-type-icons"
                                  />
                                  <h5 className="video-type-title">
                                    YouTube Link
                                  </h5>
                                </div>
                              </Link>
                            </Col>
                            <Col sm={12} md={4}>
                              <Link
                                to="#"
                                onClick={() => {
                                  setActiveTab("video-details");
                                  setInputData({ ...inputData, video_type: 4 });
                                }}
                              >
                                <div className="video-type-box">
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/img/url.svg"
                                    }
                                    className="video-type-icons"
                                  />
                                  <h5 className="video-type-title">Others</h5>
                                </div>
                              </Link>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="video-details">
                      <div className="video-details-sec">
                        <Container>
                          <Row>
                            <Col sm={12} md={12}>
                              <h4 className="video-details-title">
                                Lets start with the basic details
                              </h4>
                              <Form onSubmit={handleSubmit} className="video-details-form">
                                <Form.Row className="align-items-center">
                                  <Col sm={12} md={6}>
                                    <Form.Group as={Col}>
                                      <Form.Label>Title*</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Video Title"
                                        name="title"
                                        value={inputData.title}
                                        onChange={(event) =>
                                          setInputData({
                                            ...inputData,
                                            title: event.target.value,
                                          })
                                        }
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col sm={12} md={6}>
                                    <Form.Group as={Col}>
                                      <Form.Label>Sub Title</Form.Label>
                                      <div className="sub-title-file-upload">
                                        <div class="upload-btn-wrapper">
                                          <button class="btn">Upload sub title</button>
                                          <input type="file" name="myfile" />
                                        </div>
                                      </div>
                                      <small>
                                        The video subtitle must be a file of
                                        type: srt.
                                      </small>
                                    </Form.Group>
                                  </Col>
                                </Form.Row>
                                <Form.Row className="align-items-center">
                                  <Col sm={12} md={6}>
                                    <Form.Group as={Col}>
                                      <Form.Label>18+ Users Only</Form.Label>

                                      <Form.Check
                                        custom
                                        inline
                                        label="Yes"
                                        type="checkbox"
                                        id="age"
                                        className="margin-btm-sm"
                                        onChange={(event) =>
                                          setInputData({
                                            ...inputData,
                                            age_limit: 18,
                                          })
                                        }
                                      />
                                      <small>
                                        Above this age users can view the video
                                      </small>
                                    </Form.Group>
                                  </Col>
                                  <Col sm={12} md={6}>
                                    <Form.Group as={Col}>
                                      <Form.Label>Publish Type</Form.Label>
                                      <ul className="list-unstyled payment-type-list">
                                        <Media as="li">
                                          <Form.Check
                                            custom
                                            inline
                                            label="Now"
                                            type="radio"
                                            id="now"
                                            name="video_publish_type"
                                            defaultChecked={true}
                                            onChange={(event) =>
                                              setInputData({
                                                ...inputData,
                                                video_publish_type: 1,
                                              })
                                            }
                                          />
                                        </Media>
                                        <Media as="li">
                                          <Form.Check
                                            custom
                                            inline
                                            label="Later"
                                            type="radio"
                                            id="later"
                                            name="video_publish_type"
                                            onChange={(event) =>
                                              setInputData({
                                                ...inputData,
                                                video_publish_type: 2,
                                              })
                                            }
                                          />
                                        </Media>
                                      </ul>
                                    </Form.Group>
                                  </Col>
                                  {inputData.video_publish_type === 2 ? (
                                    <Col sm={12} md={4}>
                                      <Form.Group as={Col}>
                                        <Form.Label>Publish Time*</Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="dd-mm-yyyy hh:ii"
                                        />
                                      </Form.Group>
                                    </Col>
                                  ) : null}
                                </Form.Row>

                                <Form.Row className="align-items-center">
                                  {props.category.loading ? (
                                    "Loading..."
                                  ) : (
                                    <Col sm={12} md={6}>
                                      <Form.Group as={Col}>
                                        <Form.Label>Category*</Form.Label>
                                        <Form.Group controlId="exampleForm.SelectCustom">
                                          <Form.Control
                                            as="select"
                                            custom
                                            onChange={(event) =>
                                              setInputData({
                                                ...inputData,
                                                category_id: event.target.value,
                                              })
                                            }
                                          >
                                            <option defaultChecked>
                                              Select
                                            </option>
                                            {props.category.data.map((cate) => (
                                              <option
                                                key={cate.category_name}
                                                value={cate.category_id}
                                              >
                                                {cate.category_name}
                                              </option>
                                            ))}
                                          </Form.Control>
                                        </Form.Group>
                                      </Form.Group>
                                    </Col>
                                  )}

                                  {props.tags.loading ? (
                                    "Loading..."
                                  ) : props.tags.data.tags_list.length > 0 ? (
                                    <Col sm={12} md={6}>
                                      <Form.Group as={Col}>
                                        <Form.Label>Tags</Form.Label>
                                        <Select
                                            isMulti
                                            name="tags"
                                            isClearable
                                            isSearchable
                                            // inputValue={selectedTags}
                                            // value={setSelectedTags}
                                            onChange={handleTagsChange}
                                            options={props.tags.data.tags_list}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                          />
                                      </Form.Group>
                                    </Col>
                                  ) : (
                                    "No Tags found"
                                  )}
                                </Form.Row>

                                <Form.Row className="align-items-center">
                                  <Col sm={12} md={12}>
                                    <Form.Group
                                      as={Col}
                                      controlId="exampleForm.ControlTextarea1"
                                    >
                                      <Form.Label>Description*</Form.Label>
                                      <Form.Control
                                        as="textarea"
                                        rows="5"
                                        name="description"
                                        value={inputData.description}
                                        onChange={(event) =>
                                          setInputData({
                                            ...inputData,
                                            description: event.target.value,
                                          })
                                        }
                                      />
                                    </Form.Group>
                                  </Col>
                                </Form.Row>
                                <Form.Row>
                                  <Col sm={12} md={12}>
                                    <Form.Group as={Col}>
                                      <div className="wizard-prev-next-btn-sec">
                                        <button
                                          className="previous-btn"
                                          onClick={() =>
                                            setActiveTab("video-type")
                                          }
                                        >
                                          Previous
                                        </button>
                                        <button
                                          className="next-btn"
                                          onClick={() =>
                                            setActiveTab("upload-video")
                                          }
                                        >
                                          Next
                                        </button>
                                      </div>
                                    </Form.Group>
                                  </Col>
                                </Form.Row>
                              </Form>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="upload-video">
                      <div className="upload-video-sec">
                        <Container>
                          <Row>
                            <Col sm={12} md={12}>
                              <div className="upload-video-card">
                                <div className="upload-video-icon">
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/img/upload-video.png"
                                    }
                                    className="upload-video-img"
                                  />
                                </div>
                                {inputData.video_type === 3 ? (
                                  <div className="upload-video-content">
                                    <h5 className="title">
                                      Please add youtube link
                                    </h5>
                                    <Form>
                                      <Form.Group as={Col}>
                                        <Form.Label>
                                          Add Youtube Link
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="Youtube link"
                                          name="other_video"
                                          value={inputData.other_video}
                                          onChange={(event) =>
                                            setInputData({
                                              ...inputData,
                                              other_video: event.target.value,
                                            })
                                          }
                                        />
                                      </Form.Group>
                                      <Form.Group className="upload-btn-wrapper">
                                        <button className="btn-upload">
                                          Upload image
                                        </button>
                                        <Form.File
                                          id="exampleFormControlFile1"
                                          label=""
                                          accept="image/*"
                                          onChange={(event) =>
                                            setInputData({
                                              ...inputData,
                                              other_image:
                                                event.target.files[0],
                                            })
                                          }
                                        />
                                      </Form.Group>
                                    </Form>
                                  </div>
                                ) : null}
                                {inputData.video_type === 4 ? (
                                  <div className="upload-video-content">
                                    <h5 className="title">
                                      Please add Other link
                                    </h5>
                                    <Form>
                                      <Form.Group as={Col}>
                                        <Form.Label>
                                          Add other video link
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="Other video link"
                                          name="other_video"
                                          value={inputData.other_video}
                                          onChange={(event) =>
                                            setInputData({
                                              ...inputData,
                                              other_video: event.target.value,
                                            })
                                          }
                                        />
                                      </Form.Group>
                                      <Form.Group className="upload-btn-wrapper">
                                        <button className="btn-upload">
                                          Upload image
                                        </button>
                                        <Form.File
                                          id="exampleFormControlFile1"
                                          label=""
                                          accept="image/*"
                                          onChange={(event) =>
                                            setInputData({
                                              ...inputData,
                                              other_image:
                                                event.target.files[0],
                                            })
                                          }
                                        />
                                      </Form.Group>
                                    </Form>
                                  </div>
                                ) : null}
                                {inputData.video_type === 1 ? (
                                  <div className="upload-video-content">
                                    <h5 className="title">
                                      Select Video files to upload
                                    </h5>
                                    <p className="desc">
                                      or drag and drop video files
                                    </p>
                                    <Form>
                                      <Form.Group className="upload-btn-wrapper">
                                        <button className="btn-upload">
                                          Upload a file
                                        </button>
                                        <Form.File
                                          id="exampleFormControlFile1"
                                          label=""
                                          accept="video/*"
                                          accept="video/mp4,video/x-m4v,video/*"
                                          onChange={(event) => handleChangeVideo(event, "video")}
                                          // onChange={(event) =>
                                          //   setInputData({
                                          //     ...inputData,
                                          //     video: event.target.files[0],
                                          //   })
                                          // }
                                        />
                                      </Form.Group>
                                    </Form>
                                  </div>
                                ) : null}
                              </div>
                              <div className="upload-video-footer">
                                <p className="desc">
                                  By submitting your videos to circle, you
                                  acknowledge that you agree to circle's
                                  <a href="#">Terms of Service</a> and{" "}
                                  <a href="#">Community Guidelines</a>. Please
                                  be sure not to violate other's copyright or
                                  privacy rights. Learn more
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                      <Container>
                        <Row>
                          <Col sm={12} md={12}>
                            <div className="wizard-prev-next-btn-sec padding-btm-sm">
                              <button
                                className="previous-btn"
                                onClick={() => setActiveTab("video-details")}
                              >
                                Previous
                              </button>
                              {inputData.video_type == 1 ?
                                <button
                                  className="next-btn"
                                  onClick={(event) => handleSubmit(event,'submit')}
                                >
                                  Finish
                                </button>
                              : 
                                <button
                                  className="next-btn"
                                  onClick={() => setActiveTab("select-image")}
                                >
                                  Next
                                </button>
                              }
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </Tab.Pane>
                    <Tab.Pane eventKey="select-image">
                      <div className="select-img-sec">
                        {inputData.video_type == 1 ?
                        <Container>
                          <h4 className="select-img-sec-title">
                            Select any one of the images which is given below.
                            The selected Image will reflect as a thumbnail of
                            your video
                          </h4>
                          <Row>
                          {props.videoImages.loading && props.videoImages.data ? (
                            "Loading...") : props.videoImages.data.video_tape_images.length > 0 ? (
                              props.videoImages.data.video_tape_images.map((video_tape_image) => (
                            
                              <Col sm={12} md={4} className="resp-mrg-btm-xs">
                                <div className="select-img-sec-box">
                                  <div className="preview-img-sec">
                                    <div className="upload-btn-wrapper">
                                      <button className="btn">
                                      <Image
                                      src={video_tape_image.image}
                                      className="preview-img"
                                    />
                                      </button>
                                      <input type="file" name="myfile" />
                                      <Form.Control
                                        className="hidden-input"
                                        id="changePicture"
                                        type="file"
                                        accept="image/*"
                                        name="picture"
                                        onChange={(event) => handleChangeImage(event, video_tape_image)}
                                      />
                                    </div>
                                  </div>
                                  {video_tape_image.is_default == 0 ?
                                    <Button className="make-default-btn-1"
                                    onClick={(event) => setDefaultImage(event, video_tape_image)}>
                                    Make Default
                                    </Button>
                                  :
                                    <Button className="make-default-btn">
                                      Default
                                    </Button>
                                  }
                                  
                                </div>
                              </Col>
                            ))
                          ) : ''}
                          </Row>
                        </Container>
                        : "" }
                      </div>
                      <Container>
                        <Row>
                          <Col sm={12} md={12}>
                            <div className="wizard-prev-next-btn-sec padding-btm-sm">
                              <button
                                className="previous-btn"
                                onClick={() => setActiveTab("upload-video")}
                              >
                                Previous
                              </button>
                              <button
                                className="next-btn"
                                onClick={(event) => handleSubmit(event,'submit')}
                                disabled={props.video.buttonDisable}
                              >
                                {props.video.loadingButtonContent != null
                                  ? props.video.loadingButtonContent
                                  : "Finish"}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  video: state.singleVideo.uploadVideo,
  category: state.category.categories,
  tags: state.tag.tags,
  videoImages: state.singleVideo.videoImages,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(UploadVideo);
