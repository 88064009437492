import React, { Component } from "react";
import { connect } from "react-redux";

class Welcome extends Component {
  render() {
    return (
      <div className="main-wrapper welcome xs-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
                <div className="welcome-banner-sec">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="banner-content">
                                <h2 className="banner-title">Insta Cash Loan</h2>
                                <h4 className="banner-subtitle">Get your mini cash loan approved within minutes</h4>
                                <p className="banner-desc">If you're looking for instant cash loan to meet your urgent needs,
                                We are here! Get loan without any physical verification and
                                documentation in 1 hour with our AI-based system</p>
                                <button className="btn btn-primary">Apply Now</button>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img
                            src={
                            window.location.origin + "/assets/img/welcome-banner.svg"
                            }
                            className="full-img resp-pt-50"
                            />
                        </div>
                    </div>
                </div>
                <div className="welcome-features-sec">
                    <div className="row">
                        <div className="col-md-3 resp-mrg-btm-rem">
                            <div className="welcome-feature-box">
                                <img
                                src={
                                window.location.origin + "/assets/img/welcome-visitor.svg"
                                }
                                className="welcome-feature-img"
                                />
                                <h4 className="title">3993255</h4>
                                <p className="desc">Visitors</p>
                            </div>
                        </div>
                        <div className="col-md-3 resp-mrg-btm-rem">
                            <div className="welcome-feature-box">
                                <img
                                src={
                                window.location.origin + "/assets/img/welcome-avg_limit.svg"
                                }
                                className="welcome-feature-img"
                                />
                                <h4 className="title">798668</h4>
                                <p className="desc">Borrowers</p>
                            </div>
                        </div>
                        <div className="col-md-3 resp-mrg-btm-rem">
                            <div className="welcome-feature-box">
                                <img
                                src={
                                window.location.origin + "/assets/img/welcome-time.svg"
                                }
                                className="welcome-feature-img"
                                />
                                <h4 className="title">10 min</h4>
                                <p className="desc">Average Credit Time</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="welcome-feature-box">
                                <img
                                src={
                                window.location.origin + "/assets/img/welcome-money.svg"
                                }
                                className="welcome-feature-img"
                                />
                                <h4 className="title">4445.9 Million</h4>
                                <p className="desc">Loan Disbursed (INR)</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="welcome-feature-sub">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="flex-feature-sub">
                                <h5>Loan on the go </h5>
                                <h5>Hard copy documentation not required</h5>
                                <h5>Get instantly</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="welcome-steps-followed">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3 className="main-title">Follow simple steps</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 resp-mrg-btm-rem">
                            <div className="feature-sub-box">
                                <div className="feature-icons">
                                    <img
                                    src={
                                    window.location.origin + "/assets/img/step-1.svg"
                                    }
                                    className="welcome-feature-steps-img"
                                    />
                                </div>
                                <div className="feature-steps-content">
                                    <h4 className="title">Paperless Registration</h4>
                                    <p className="desc">Modern life can come with a lot of problems, 
                                    but we make sure our paperless registration is not one. Login to
                                    our instant loan app to avail loan effortlessly.</p>
                                    <button className="btn btn-primary">Apply</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 resp-mrg-btm-rem">
                            <div className="feature-sub-box">
                                <div className="feature-icons">
                                    <img
                                    src={
                                    window.location.origin + "/assets/img/step-2.svg"
                                    }
                                    className="welcome-feature-steps-img"
                                    />
                                </div>
                                <div className="feature-steps-content">
                                    <h4 className="title">Quick Approval</h4>
                                    <p className="desc">Onboarding you is a pleasure for us. With our AI-Based algorithm, get your desired amount approved and
                                    credited in your bank account in 1 hour without any document.</p>
                                    <button className="btn btn-primary">Apply</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="feature-sub-box">
                                <div className="feature-icons">
                                    <img
                                    src={
                                    window.location.origin + "/assets/img/step-3.svg"
                                    }
                                    className="welcome-feature-steps-img"
                                    />
                                </div>
                                <div className="feature-steps-content">
                                    <h4 className="title">Instant Fund Transfer</h4>
                                    <p className="desc">We can’t wait to see you enjoying with the instant money you’ve got. 
                                    Count on us and we are there. Leave some of your amazing messages on our social networking channels.</p>
                                    <button className="btn btn-primary">Apply</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="welcome-call-to-action">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="action-sec">
                                <h6 className="title">Get instant Loan Apply online</h6>
                                <button className="btn btn-primary">Click Here</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="welcome-testimonial-sec">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3 className="main-title">Customers Reviews</h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-10 col-lg-offset-2 mx-auto">
                            <div id="testimonial-slider" class="owl-carousel">
                                <div class="testimonial">
                                    <div class="profile-picture">
                                        <img 
                                        src={
                                        window.location.origin + "/assets/img/client-1.jpg"
                                        } alt="" class="img-responsive"/>
                                    </div>
                                    <h3 class="testimonial-info">
                                        Jerif Daniel
                                        <small>Web Developer</small>
                                    </h3>
                                    <p class="description"><i class="fas fa-quote-left mr-2"></i>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi facilisis.<i class="fas fa-quote-right ml-2"></i></p>
                                </div>
                                <div class="testimonial">
                                    <div class="profile-picture">
                                        <img 
                                        src={
                                            window.location.origin + "/assets/img/client-2.jpg"
                                        } alt="" class="img-responsive"/>
                                    </div>
                                    <h3 class="testimonial-info">
                                        Beno Darry
                                        <small>Web Designer</small>
                                    </h3>
                                    <p class="description"><i class="fas fa-quote-left mr-2"></i>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi facilisis.<i class="fas fa-quote-right ml-2"></i></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="client-wizard">
                    <div className="row">
                        <div className="col-md-3 text-center resp-mrg-btm-rem">
                        <h3 className="title">Numbers Speak For Themselves!</h3>
                        </div>
                        <div className="col-md-3 text-center resp-mrg-btm-rem">
                            <h3 className="title">2,000+</h3>
                            <p className="desc">Agents</p>
                        </div>
                        <div className="col-md-3 text-center resp-mrg-btm-rem">
                            <h3 className="title">2,500+</h3>
                            <p className="desc">Loan Approved</p>
                        </div>
                        <div className="col-md-3 text-center">
                            <h3 className="title">150+</h3>
                            <p className="desc">Branches</p>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Welcome;
