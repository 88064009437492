import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Nav, Container, Row, Col, Tab, Image, Media } from "react-bootstrap";
import "../MyHistory/MyHistory.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import {
  fetchWishlistStart,
  wishlistOperationStart,
} from "../../store/actions/WishListAction";
import "./WishList.css";
import WishlistLoader from "../Loader/WishlistLoader";

const WishList = (props) => {
  useEffect(() => {
    if (props.wishlist.loading) props.dispatch(fetchWishlistStart());
  }, []);

  const clearSingleVideo = (event, data) => {
    event.preventDefault();
    props.dispatch(
      wishlistOperationStart({
        video_tape_id: data.video_tape_id,
        clear_all_status: 0,
      })
    );
  };

  const clearAll = (event, data) => {
    event.preventDefault();
    props.dispatch(
      wishlistOperationStart({
        clear_all_status: 1,
      })
    );
  };

  const wishlist = props.wishlist;
  return (
    <>
      <div className="my-history-sec wishlist-sec">
        <Container>
          <Row>
            <Col sm={12} md={12}>
              <h2 className="section-title">WishList</h2>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12}>
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="watch-history"
              >
                <Row>
                  {wishlist.loading ? (
                    <WishlistLoader />
                  ) : wishlist.data.length > 0 ? (
                    <Col sm={12} md={12}>
                      <div className="clear-sec">
                        <Link to="#" onClick={clearAll}>
                          <h4 className="clear-txt">
                            <FontAwesomeIcon
                              icon={["fas", "times"]}
                              className="menu-icons mr-2"
                            />
                            Clear all
                          </h4>
                        </Link>
                      </div>
                    </Col>
                  ) : (
                    ""
                  )}
                  {wishlist.loading ? (
                    "Loading..."
                  ) : (
                    <Col sm={12} md={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="watch-history">
                          <div className="my-history-video-sec">
                            {wishlist.data.length > 0 ? (
                              <>
                                {wishlist.data.map((data) => (
                                  <div className="my-history-video-card">
                                    <Row>
                                      <Col xs={5} sm={5} md={4} xl={2}>
                                        <div className="my-history-video-card-img">
                                          <Image
                                            src={data.video_image}
                                            className="list-video-img"
                                          />
                                        </div>
                                        <div className="time-count">
                                          <h6>{data.duration}</h6>
                                        </div>
                                      </Col>
                                      <Col
                                        xs={5}
                                        sm={5}
                                        md={5}
                                        xl={6}
                                        className="resp-pd-left-zero"
                                      >
                                        <div className="my-history-video-card-details">
                                          <h4 className="title">
                                            {data.title}
                                          </h4>
                                          <p className="desc">
                                            <span className="view-count">
                                              {data.watch_count} views
                                            </span>
                                            <span className="view-count">
                                              . {data.created}
                                            </span>
                                          </p>
                                        </div>
                                      </Col>
                                      <Col xs={2} sm={2} md={3} xl={4}>
                                        <Link
                                          to="#"
                                          onClick={(event) =>
                                            clearSingleVideo(event, data)
                                          }
                                        >
                                          <div className="clear-icon-sec">
                                            <FontAwesomeIcon
                                              icon={["fas", "times"]}
                                              className="menu-icons clear-icons mr-2"
                                            />
                                          </div>
                                        </Link>
                                      </Col>
                                    </Row>
                                  </div>
                                ))}
                                <Row className="d-none d-sm-block">
                                  <Col sm={12} md={12}>
                                    <div className="load-more-btn">
                                      <button className="btn-load-more">
                                        Load more Videos
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              <div className="no-data-found-sec">
                                <Row>
                                  <Col sm={12} md={12}>
                                    <div className="no-data-found-img-sec">
                                      <Image
                                        src={
                                          window.location.origin +
                                          "/assets/img/no-result-found.svg"
                                        }
                                        className="no-data-found-img"
                                      />
                                      <p className="text-no-data">No Result Found</p>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            )}
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  )}
                </Row>
              </Tab.Container>
            </Col>
          </Row>
          <div className="d-flex d-sm-none resp-arrow-bottom-sec">
            <ul className="list-unstyled">
              <Media as="li">
                <Image
                  src="assets/img/mobile-arrow.png"
                  className="resp-arrow-bottom"
                />
              </Media>
            </ul>
          </div>
          <div className="border-bottom-1 d-flex d-sm-none"></div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  wishlist: state.wishlist.wishlist,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(WishList);
