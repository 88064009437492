import React from "react"
import ContentLoader from "react-content-loader"

const SingleVideoAboutLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1200}
    height={1200}
    viewBox="0 0 1200 1200"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="8" rx="5" ry="5" width="1200" height="56" /> 
    <rect x="0" y="106" rx="0" ry="0" width="700" height="240" /> 
    <rect x="750" y="108" rx="0" ry="0" width="88" height="10" /> 
    <rect x="1150" y="108" rx="0" ry="0" width="50" height="10" /> 
    <rect x="750" y="137" rx="0" ry="0" width="450" height="1" /> 
    <rect x="750" y="149" rx="0" ry="0" width="180" height="59" /> 
    <rect x="950" y="185" rx="0" ry="0" width="21" height="10" /> 
    <rect x="950" y="153" rx="0" ry="0" width="88" height="10" /> 
    <rect x="950" y="170" rx="0" ry="0" width="60" height="9" /> 
    <rect x="750" y="219" rx="0" ry="0" width="180" height="59" /> 
    <rect x="950" y="255" rx="0" ry="0" width="21" height="10" /> 
    <rect x="950" y="223" rx="0" ry="0" width="88" height="10" /> 
    <rect x="950" y="240" rx="0" ry="0" width="60" height="9" /> 
    <rect x="750" y="290" rx="0" ry="0" width="180" height="59" /> 
    <rect x="950" y="326" rx="0" ry="0" width="21" height="10" /> 
    <rect x="950" y="294" rx="0" ry="0" width="88" height="10" /> 
    <rect x="950" y="311" rx="0" ry="0" width="60" height="9" /> 
    <rect x="750" y="361" rx="0" ry="0" width="180" height="59" /> 
    <rect x="950" y="397" rx="0" ry="0" width="21" height="10" /> 
    <rect x="950" y="365" rx="0" ry="0" width="88" height="10" /> 
    <rect x="950" y="382" rx="0" ry="0" width="60" height="9" /> 
    <rect x="0" y="355" rx="0" ry="0" width="200" height="11" /> 
    <rect x="0" y="373" rx="0" ry="0" width="700" height="74" /> 
    <rect x="0" y="448" rx="0" ry="0" width="700" height="33" /> 
    <rect x="0" y="490" rx="0" ry="0" width="420" height="170" /> 
    <rect x="450" y="490" rx="0" ry="0" width="2" height="182" /> 
    <rect x="480" y="493" rx="0" ry="0" width="50" height="6" /> 
    <rect x="480" y="504" rx="0" ry="0" width="70" height="7" /> 
    <rect x="480" y="517" rx="5" ry="5" width="200" height="104" /> 
    <rect x="480" y="628" rx="5" ry="5" width="200" height="22" /> 
    <rect x="0" y="671" rx="0" ry="0" width="452" height="2" /> 
    <rect x="0" y="690" rx="0" ry="0" width="700" height="40" /> 
    <rect x="0" y="743" rx="0" ry="0" width="100" height="5" /> 
    <rect x="0" y="759" rx="0" ry="0" width="700" height="2" /> 
    <rect x="0" y="775" rx="5" ry="5" width="120" height="54" /> 
    <rect x="580" y="775" rx="5" ry="5" width="120" height="54" /> 
    <rect x="380" y="775" rx="5" ry="5" width="120" height="54" /> 
    <rect x="190" y="775" rx="5" ry="5" width="120" height="54" /> 
    <rect x="0" y="842" rx="0" ry="0" width="74" height="8" /> 
    <rect x="0" y="856" rx="0" ry="0" width="45" height="8" /> 
    <rect x="0" y="871" rx="0" ry="0" width="30" height="8" /> 
    <rect x="190" y="842" rx="0" ry="0" width="74" height="8" /> 
    <rect x="190" y="856" rx="0" ry="0" width="45" height="8" /> 
    <rect x="190" y="871" rx="0" ry="0" width="30" height="8" /> 
    <rect x="380" y="842" rx="0" ry="0" width="74" height="8" /> 
    <rect x="380" y="856" rx="0" ry="0" width="45" height="8" /> 
    <rect x="380" y="871" rx="0" ry="0" width="30" height="8" /> 
    <rect x="580" y="842" rx="0" ry="0" width="74" height="8" /> 
    <rect x="580" y="856" rx="0" ry="0" width="45" height="8" /> 
    <rect x="580" y="871" rx="0" ry="0" width="30" height="8" /> 
    <rect x="0" y="895" rx="0" ry="0" width="700" height="2" /> 
    <rect x="0" y="912" rx="0" ry="0" width="100" height="9" /> 
    <circle cx="30" cy="967" r="26" /> 
    <rect x="104" y="941" rx="5" ry="5" width="600" height="69" /> 
    <rect x="102" y="1026" rx="0" ry="0" width="68" height="8" /> 
    <rect x="0" y="1026" rx="0" ry="0" width="68" height="8" /> 
    <rect x="0" y="1043" rx="0" ry="0" width="700" height="1" /> 
    <rect x="0" y="1040" rx="0" ry="0" width="85" height="4" /> 
    <circle cx="30" cy="1079" r="26" /> 
    <rect x="85" y="1062" rx="0" ry="0" width="100" height="8" /> 
    <rect x="85" y="1079" rx="0" ry="0" width="200" height="8" /> 
    <rect x="85" y="1096" rx="0" ry="0" width="120" height="8" /> 
    <rect x="85" y="1111" rx="0" ry="0" width="80" height="8" /> 
    <rect x="675" y="1068" rx="0" ry="0" width="25" height="7" /> 
    <rect x="300" y="1136" rx="5" ry="5" width="145" height="43" /> 
    <rect x="750" y="437" rx="0" ry="0" width="280" height="190" /> 
    <rect x="750" y="648" rx="0" ry="0" width="150" height="10" /> 
    <rect x="950" y="648" rx="0" ry="0" width="50" height="10" /> 
    <rect x="750" y="677" rx="0" ry="0" width="420" height="1" /> 
    <rect x="750" y="689" rx="0" ry="0" width="180" height="59" /> 
    <rect x="950" y="725" rx="0" ry="0" width="21" height="10" /> 
    <rect x="950" y="692" rx="0" ry="0" width="88" height="10" /> 
    <rect x="950" y="710" rx="0" ry="0" width="60" height="9" /> 
    <rect x="750" y="759" rx="0" ry="0" width="180" height="59" /> 
    <rect x="950" y="795" rx="0" ry="0" width="21" height="10" /> 
    <rect x="950" y="763" rx="0" ry="0" width="88" height="10" /> 
    <rect x="950" y="780" rx="0" ry="0" width="60" height="9" /> 
    <rect x="750" y="830" rx="0" ry="0" width="180" height="59" /> 
    <rect x="950" y="866" rx="0" ry="0" width="21" height="10" /> 
    <rect x="950" y="834" rx="0" ry="0" width="88" height="10" /> 
    <rect x="950" y="851" rx="0" ry="0" width="60" height="9" /> 
    <rect x="750" y="901" rx="0" ry="0" width="180" height="59" /> 
    <rect x="950" y="937" rx="0" ry="0" width="21" height="10" /> 
    <rect x="950" y="903" rx="0" ry="0" width="88" height="10" /> 
    <rect x="950" y="922" rx="0" ry="0" width="60" height="9" /> 
    <rect x="750" y="971" rx="0" ry="0" width="450" height="1" /> 
    <rect x="930" y="987" rx="0" ry="0" width="100" height="6" />
  </ContentLoader>
)

export default SingleVideoAboutLoader;