import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Container, Row, Col, Image } from "react-bootstrap";
import "./ChangePassword.css";
import UseForm from "../../helper/UseForm";
import { connect } from "react-redux";
import { changePasswordStart } from "../../../store/actions/UserAction";

const ChangePasswordIndex = (props) => {
  const { values, handleChange, handleSubmit } = UseForm(changePassword);

  function changePassword() {
    console.log(values);
    props.dispatch(changePasswordStart(values));
  }
  return (
    <>
      <div className="change-password-sec">
        <Container>
          <div className="change-password-box">
            <Row>
              <Col sm={12} md={12}>
                <h2 className="section-title">Change Password</h2>
                <div className="change-password">
                  <Row>
                    <Col sm={12} md={6}>
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/change-password-img.svg"
                        }
                        className="change-password-img"
                      />
                    </Col>
                    <Col sm={12} md={6}>
                      <div className="change-password-form-sec">
                        <Form
                          className="change-password-form"
                          onSubmit={handleSubmit}
                          autoComplete="new-password"
                        >
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Old Password</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Old Password"
                              name="old_password"
                              onChange={handleChange}
                              value={values.old_password}
                            />
                          </Form.Group>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="New Password"
                              name="password"
                              onChange={handleChange}
                              value={values.password}
                            />
                          </Form.Group>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Confirm Password"
                              name="password_confirmation"
                              onChange={handleChange}
                              value={values.password_confirmation}
                            />
                          </Form.Group>
                          <div className="action-btn">
                            <Button
                              className="submit-btn"
                              type="submit"
                              disabled={props.changePassword.buttonDisable}
                            >
                              {props.changePassword.loadingButtonContent != null
                                ? props.changePassword.loadingButtonContent
                                : "Submit"}
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  changePassword: state.changePassword,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ChangePasswordIndex);
