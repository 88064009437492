import React, { useEffect, useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const AuthAlertModal = (props) => {
  const [show, setShow] = useState(props.show);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} className="golive-modal">
        <Modal.Header closeButton>
          <Modal.Title>Login/Signup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Only authenticated users can access this action. Please login/signup
            to continue the page.{" "}
          </p>
          <Link
            to={`/signin`}
            className="btn btn-sm btn-outline-primary text-uppercase mb-2"
          >
            Click Here Signin
          </Link>
          <p></p>
          <Link
            to={`/signup`}
            className="btn btn-sm btn-outline-secondary text-uppercase"
          >
            Click Here Signup
          </Link>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AuthAlertModal);
