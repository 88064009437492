import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  fetchWishlistSuccess,
  fetchWishlistFailure,
  wishlistOperationSuccess,
  wishlistOperationFailure,
  fetchWishlistStart,
} from "../actions/WishListAction";
import {
  FETCH_WISHLIST_START,
  WISHLIST_OPERATION_START,
} from "../actions/ActionConstant";

function* fetchWishlistAPI() {
  try {
    const response = yield api.postMethod("wishlist");
    if (response.data.success) {
      yield put(fetchWishlistSuccess(response.data.data));
    } else {
      yield put(fetchWishlistFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchWishlistFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* wishlistOperationAPI() {
  try {
    const inputData = yield select(
      (state) => state.wishlist.wishListOperation.inputData
    );
    if(localStorage.getItem("userLoginStatus") != 'true') {
      yield put(wishlistOperationFailure('Login to Continue'));
      const notificationMessage = getErrorNotificationMessage(
        'Login to Continue'
      );
      yield put(createNotification(notificationMessage)); 
    } else {
      const response = yield api.postMethod("wishlist_operations", inputData);
      if (response.data.success) {
        yield put(wishlistOperationSuccess(response.data.data));
        const notificationMessage = getSuccessNotificationMessage(
          response.data.message
        );
        yield put(createNotification(notificationMessage));
        yield put(fetchWishlistStart(0));
      } else {
        yield put(wishlistOperationFailure(response.data.error));
        const notificationMessage = getErrorNotificationMessage(
          response.data.error
        );
        yield put(createNotification(notificationMessage));
      }
    }
  } catch (error) {
    yield put(wishlistOperationFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_WISHLIST_START, fetchWishlistAPI)]);
  yield all([yield takeLatest(WISHLIST_OPERATION_START, wishlistOperationAPI)]);
}
