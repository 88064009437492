import React, { useEffect, useState } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Image,
  Media,
  Badge,
  Modal,
  ModalFooter,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import {
  addVideoViewerPlaylistStart,
  createViewerPlaylistStart,
  fetchViewerPlaylistStart,
} from "../../store/actions/ViewerPlayListAction";
import {
  addToSpamStart,
  fetchSpamReasonStart,
} from "../../store/actions/SpamAction";
import NoResultFoundSmall from "../NoResultFoundSmall/NoResultFoundSmall"

const SpamModal = (props) => {
  useEffect(() => {
    if (props.spamReason.loading) props.dispatch(fetchSpamReasonStart());
  }, []);

  const [reason, setReason] = useState("");

  const addToSpam = (event) => {
    event.preventDefault();
    props.dispatch(
      addToSpamStart({
        reason: reason,
        video_tape_id: props.video_tape_id,
      })
    );
    setTimeout(() => {
      props.closeSpamModal();
    }, 1000);
  };

  const { spamReason } = props;

  return (
    <Modal
      className="add-to-modal"
      size="md"
      centered
      show={props.displaySpamModal}
      onHide={props.closeSpamModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Spam Reason</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul className="list-unstyled add-to-check">
          {spamReason.data.length > 0
            ? spamReason.data.map((reason) => (
                <Media as="li" key={reason.id}>
                  <Form.Check
                    type="radio"
                    id={reason.id}
                    label={reason.value}
                    custom
                    name="spamvideo"
                    value={reason.value}
                    onChange={(event) => setReason(event.currentTarget.value)}
                  />
                </Media>
              ))
            : <NoResultFoundSmall/>}
        </ul>
      </Modal.Body>
      <ModalFooter>
        <div className="add-footer-content">
          <Button className="btn create-btn" type="submit" onClick={addToSpam}>
            Submit
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToPros = (state) => ({
  spamReason: state.spam.spamReason,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SpamModal);
