import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Nav, Container, Row, Col, Tab, Image, Media } from "react-bootstrap";
import "./MyHistory.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import {
  fetchVideoHistoryStart,
  removeVideoHistoryStart,
} from "../../store/actions/VideoHistoryAction";
import MyHistoryLoader from "../Loader/MyHistoryLoader";

const MyHistory = (props) => {
  useEffect(() => {
    if (props.historyVideo.loading) props.dispatch(fetchVideoHistoryStart());
  }, []);

  const clearSingleVideoHistory = (event, data) => {
    event.preventDefault();
    props.dispatch(
      removeVideoHistoryStart({
        video_tape_id: data.video_tape_id,
        clear_all_status: 1,
      })
    );
  };

  const clearAll = (event) => {
    event.preventDefault();
    props.dispatch(
      removeVideoHistoryStart({
        clear_all_status: 1,
      })
    );
  };

  const historyVideo = props.historyVideo;

  return (
    <>
      <div className="my-history-sec">
        <Container>
          <Row>
            <Col sm={12} md={12}>
              <h2 className="section-title">Watch History</h2>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12}>
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="watch-history"
              >
                <Row>
                  {historyVideo.loading ? (
                    <MyHistoryLoader/>
                  ) : historyVideo.data.length > 0 ? (
                    <Col sm={12} md={12}>
                      <div className="clear-sec">
                        <Link to="#" onClick={clearAll}>
                          <h4 className="clear-txt">
                            <FontAwesomeIcon
                              icon={["fas", "times"]}
                              className="menu-icons mr-2"
                            />
                            Clear all
                          </h4>
                        </Link>
                      </div>
                    </Col>
                  ) : (
                    ""
                  )}
                  <Col sm={12} md={12}>
                    {historyVideo.loading ? (
                      "Loading..."
                    ) : (
                      <Tab.Content>
                        <Tab.Pane eventKey="watch-history">
                          {historyVideo.data.length > 0 ? (
                            <div className="my-history-video-sec">
                              {historyVideo.data.map((video) => (
                                <div className="my-history-video-card">
                                  <Row>
                                    <Col xs={5} sm={5} md={4} xl={2}>
                                      <div className="my-history-video-card-img">
                                        <Image
                                          src={video.video_image}
                                          className="list-video-img"
                                        />
                                      </div>
                                      <div className="time-count">
                                        <h6>15:19</h6>
                                      </div>
                                    </Col>
                                    <Col
                                      xs={5}
                                      sm={5}
                                      md={5}
                                      xl={6}
                                      className="resp-pd-left-zero"
                                    >
                                      <div className="my-history-video-card-details">
                                        <h4 className="title">{video.title}</h4>
                                        <p className="desc">
                                          <span className="view-count">
                                            {video.watch_count} views
                                          </span>
                                          <span className="view-count">
                                            . {video.created}
                                          </span>
                                        </p>
                                      </div>
                                    </Col>
                                    <Col xs={2} sm={2} md={3} xl={4}>
                                      <Link
                                        to="#"
                                        onClick={(event) =>
                                          clearSingleVideoHistory(event, video)
                                        }
                                      >
                                        <div className="clear-icon-sec">
                                          <FontAwesomeIcon
                                            icon={["fas", "times"]}
                                            className="menu-icons clear-icons mr-2"
                                          />
                                        </div>
                                      </Link>
                                    </Col>
                                  </Row>
                                </div>
                              ))}

                              <Row className="d-none d-sm-block">
                                <Col sm={12} md={12}>
                                  <div className="load-more-btn">
                                    <button className="btn-load-more">
                                      Load more Videos
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          ) : (
                            <div className="no-data-found-sec">
                              <Row>
                                <Col sm={12} md={12}>
                                  <div className="no-data-found-img-sec">
                                    <Image
                                      src={
                                        window.location.origin +
                                        "/assets/img/no-result-found.svg"
                                      }
                                      className="no-data-found-img"
                                    />
                                    <p className="text-no-data">
                                      No Result Found
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </Tab.Pane>
                      </Tab.Content>
                    )}
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
          </Row>
          <div className="d-flex d-sm-none resp-arrow-bottom-sec">
            <ul className="list-unstyled">
              <Media as="li">
                <Image
                  src={window.location.origin + "/assets/img/mobile-arrow.png"}
                  className="resp-arrow-bottom"
                />
              </Media>
            </ul>
          </div>
          <div className="border-bottom-1 d-flex d-sm-none"></div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  historyVideo: state.videoHistory.historyVideo,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(MyHistory);
