import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Nav,
  FormControl,
  Container,
  Row,
  Col,
  InputGroup,
  Tab,
  Image,
  Media,
  Button,
} from "react-bootstrap";
import "./BrowseChannels.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChannelCard from "../helper/ChannelCard";
import { fetchChannelsStart } from "../../store/actions/ChannelAction";
import { connect } from "react-redux";
import BrowseChannelLoader from "../Loader/BrowseChannelLoader";

const BrowseChannels = (props) => {
  useEffect(() => {
    if (props.channel.loading)
      props.dispatch(fetchChannelsStart({ view_type: "viewer" }));
  }, []);
  return (
    <>
      <div className="browse-channels-sec">
        <Container>
          <Row>
            <Col sm={12} md={12}>
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="browse-all-channels"
              >
                <Row>
                  <Col sm={12} md={12}>
                    <Nav variant="pills">
                      <Nav.Item>
                        <Nav.Link eventKey="browse-all-channels">
                          Browse All Channels
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item className="ml-auto d-none d-sm-block">
                        <div className="search-bar-sec">
                          <InputGroup>
                            <InputGroup.Prepend>
                              <InputGroup.Text id="basic-addon1">
                                <FontAwesomeIcon icon="search" />
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              placeholder="Search Channels ..."
                              className="browse-channel-form"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                            />
                          </InputGroup>
                        </div>
                      </Nav.Item>
                      <Nav.Item className="ml-5">
                        <Link to="/create-channel">
                          <Button className="submit-btn">Create Channel <FontAwesomeIcon icon="plus" className="ml-1" /></Button>
                        </Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={12} md={12}>
                    {props.channel.loading ? (
                      <BrowseChannelLoader />
                    ) : props.channel.data.length > 0 ? (
                      <Tab.Content>
                        <Tab.Pane eventKey="browse-all-channels">
                          <div className="browse-channels-sub-header">
                            <h4 className="card-title-main">Channels</h4>
                          </div>
                          <Row>
                            {props.channel.data.map((channel) => (
                              <ChannelCard channel={channel} />
                            ))}
                          </Row>
                          <div className="space-row"></div>
                        </Tab.Pane>
                      </Tab.Content>
                    ) : (
                      <div className="no-data-found-sec">
                        <Row>
                          <Col sm={12} md={12}>
                            <div className="no-data-found-img-sec">
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/img/no-result-found.svg"
                                }
                                className="no-data-found-img"
                              />
                              <p className="text-no-data">No Result Found</p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Col>
                </Row>
              </Tab.Container>
              <div className="resp-load-more-bottom d-flex d-sm-none">
                <ul className="list-unstyled">
                  <Media as="li">
                    <Image
                      src={
                        window.location.origin + "/assets/img/mobile-arrow.png"
                      }
                      className="resp-arrow-bottom-2"
                    />
                  </Media>
                </ul>
                <div className="border-top-1 resp-mrg-btm-lg d-flex d-sm-none"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  channel: state.channel.channels,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(BrowseChannels);
