import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Tabs,
  Tab,
  Dropdown,
  DropdownButton,
  Image,
  Table,
  Badge,
} from "react-bootstrap";
import "./EditProfile.css";

import { connect } from "react-redux";
import {
  fetchUserDetailsStart,
  editUserDetails,
  updateUserDetailsStart,
} from "../../../store/actions/UserAction";
import EditProfileLoader from "../../Loader/EditProfileLoader";

class EditProfile extends Component {
  state = {
    loading: true,
    inputData: {},
    imagePreviewUrl: null,
  };
  componentDidMount() {
    if (this.props.userData.profile.loading)
      this.props.dispatch(fetchUserDetailsStart());
  }
  handleChange = ({ currentTarget: input }) => {
    this.props.dispatch(editUserDetails(input.name, input.value));
  };
  handleChangeImage = ({ currentTarget: input }) => {
    const inputData = { ...this.state.inputData };
    if (input.type === "file") {
      inputData[input.name] = input.files[0];
      this.setState({ inputData });
    }
    let reader = new FileReader();
    let file = input.files[0];

    reader.onloadend = () => {
      if (input.name == "picture")
        this.setState({
          imagePreviewUrl: reader.result,
        });
    };
    if (file) {
      reader.readAsDataURL(file);
      setTimeout(() => {
        this.props.dispatch(updateUserDetailsStart(input.files[0]));
      }, 1000);
    }
  };
  updateProfile = (event) => {
    event.preventDefault();
    this.props.dispatch(updateUserDetailsStart());
  };

  render() {
    const userData = this.props.userData.profile;
    if (userData.loading === true) {
      return <EditProfileLoader/>;
    } else {
      return (
        <>
          <div className="edit-profile-sec">
            <Container>
              <div className="edit-profile-box">
                <Row>
                  <Col sm={12} md={12}>
                    <h2 className="section-title">Edit Profile</h2>
                    <div className="edit-profile">
                      <Row>
                        <Col sm={12} md={6}>
                          <Image
                            src={
                              window.location.origin +
                              "/assets/img/edit-profile-img.svg"
                            }
                            className="edit-profile-img"
                          />
                        </Col>
                        <Col sm={12} md={6}>
                          <div className="edit-profile-form-sec">
                            <Form
                              className="edit-profile-form"
                              onSubmit={this.updateProfile}
                            >
                              <div className="user-img-sec">
                                <Image
                                  src={
                                    this.state.imagePreviewUrl != null
                                      ? this.state.imagePreviewUrl
                                      : userData.data.picture
                                  }
                                  className="user-img"
                                />
                              </div>
                              <Form.Group>
                                <Form.File
                                  id="custom-file-translate-scss"
                                  label="Upload Image"
                                  lang="en"
                                  custom
                                  className="custom-file"
                                  name="picture"
                                  onChange={this.handleChangeImage}
                                  accept="image/*"
                                />
                              </Form.Group>
                              <Form.Text className="text-muted text-format-desc">
                                Please enter .png .jpeg .jpg images only. Upload
                                Square images 2:2 Ratio Ex: 300 * 300
                              </Form.Text>
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label>Username*</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="UserDemo"
                                  value={userData.data.name}
                                  name="name"
                                  onChange={this.handleChange}
                                />
                              </Form.Group>
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email*</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="user@tubenow.com"
                                  name="email"
                                  value={userData.data.email}
                                  onChange={this.handleChange}
                                />
                              </Form.Group>
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label>Mobile</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Mobile"
                                  name="mobile"
                                  value={userData.data.mobile}
                                  onChange={this.handleChange}
                                />
                                <Form.Text className="text-muted text-note-desc">
                                  Note : The mobile must be between 6 and 13
                                  digits.
                                </Form.Text>
                              </Form.Group>
                              <Form.Group controlId="PayPalEmail">
                                <Form.Label>PayPal Email</Form.Label>
                                <Form.Control
                                  type="email"
                                  placeholder="abcd@mail.com"
                                  name="paypal_email"
                                  value={userData.data.paypal_email}
                                  onChange={this.handleChange}
                                ></Form.Control>
                              </Form.Group>
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label>DOB</Form.Label>
                                <Form.Control
                                  type="date"
                                  placeholder="03-02-2020"
                                  name="dob"
                                  value={userData.data.dob}
                                  onChange={this.handleChange}
                                />
                              </Form.Group>
                              <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Label>About Me</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows="3"
                                  name="description"
                                  value={userData.data.description}
                                  onChange={this.handleChange}
                                />
                              </Form.Group>
                              <div className="action-btn">
                                <Button
                                  className="submit-btn"
                                  type="submit"
                                  disabled={this.props.userData.buttonDisable}
                                >
                                  {" "}
                                  {this.props.userData.loadingButtonContent !=
                                  null
                                    ? this.props.userData.loadingButtonContent
                                    : "Update Profile"}
                                </Button>
                              </div>
                            </Form>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </>
      );
    }
  }
}

const mapStateToPros = (state) => ({
  userData: state.users,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(EditProfile);
