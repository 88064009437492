import {
  FETCH_SINGLE_VIDEO_START,
  FETCH_SINGLE_VIDEO_SUCCESS,
  FETCH_SINGLE_VIDEO_FAILURE,
  FETCH_VIDEO_COMMENTS_START,
  FETCH_VIDEO_COMMENTS_SUCCESS,
  FETCH_VIDEO_COMMENTS_FAILURE,
  ADD_COMMENTS_START,
  ADD_COMMENTS_SUCCESS,
  ADD_COMMENTS_FAILURE,
  LIKE_VIDEO_START,
  LIKE_VIDEO_SUCCESS,
  LIKE_VIDEO_FAILURE,
  DISLIKE_VIDEO_START,
  DISLIKE_VIDEO_SUCCESS,
  DISLIKE_VIDEO_FAILURE,
  UPLOAD_VIDEO_START,
  UPLOAD_VIDEO_SUCCESS,
  UPLOAD_VIDEO_FAILURE,
  SET_DEFAULT_IMAGE_START,
  SET_DEFAULT_IMAGE_SUCCESS,
  SET_DEFAULT_IMAGE_FAILURE,
  FETCH_VIDEO_IMAGES_START,
  FETCH_VIDEO_IMAGES_SUCCESS,
  FETCH_VIDEO_IMAGES_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  video: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  comments: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  addComment: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  likeVideo: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  dislikeVideo: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  uploadVideo: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  setdefault: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  videoImages: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
};

const SingleVideoReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SINGLE_VIDEO_START:
      return {
        ...state,
        video: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_SINGLE_VIDEO_SUCCESS:
      return {
        ...state,
        video: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_SINGLE_VIDEO_FAILURE:
      return {
        ...state,
        video: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
      };
    case FETCH_VIDEO_COMMENTS_START:
      return {
        ...state,
        comments: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_VIDEO_COMMENTS_SUCCESS:
      return {
        ...state,
        comments: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_VIDEO_COMMENTS_FAILURE:
      return {
        ...state,
        comments: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
      };

    case ADD_COMMENTS_START:
      return {
        ...state,
        addComment: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case ADD_COMMENTS_SUCCESS:
      return {
        ...state,
        addComment: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case ADD_COMMENTS_FAILURE:
      return {
        ...state,
        addComment: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case LIKE_VIDEO_START:
      return {
        ...state,
        likeVideo: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case LIKE_VIDEO_SUCCESS:
      return {
        ...state,
        likeVideo: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
          inputData: {},
        },
      };
    case LIKE_VIDEO_FAILURE:
      return {
        ...state,
        likeVideo: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
          inputData: {},
        },
      };

    case DISLIKE_VIDEO_START:
      return {
        ...state,
        dislikeVideo: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case DISLIKE_VIDEO_SUCCESS:
      return {
        ...state,
        dislikeVideo: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case DISLIKE_VIDEO_FAILURE:
      return {
        ...state,
        dislikeVideo: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
      case UPLOAD_VIDEO_START:
      return {
        ...state,
        uploadVideo: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case UPLOAD_VIDEO_SUCCESS:
      return {
        ...state,
        uploadVideo: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case UPLOAD_VIDEO_FAILURE:
      return {
        ...state,
        uploadVideo: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case SET_DEFAULT_IMAGE_START:
      return {
        ...state,
        setdefault: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case SET_DEFAULT_IMAGE_SUCCESS:
      return {
        ...state,
        setdefault: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case SET_DEFAULT_IMAGE_FAILURE:
      return {
        ...state,
        setdefault: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
      case FETCH_VIDEO_IMAGES_START:
      return {
        ...state,
        videoImages: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_VIDEO_IMAGES_SUCCESS:
      return {
        ...state,
        videoImages: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_VIDEO_IMAGES_FAILURE:
      return {
        ...state,
        videoImages: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
      };
    default:
      return state;
  }
};

export default SingleVideoReducer;
