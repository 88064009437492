import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  fetchChannelsSuccess,
  fetchChannelsFailure,
  fetchSingleChannelSuccess,
  fetchSingleChannelFailure,
  fetchChannelVideosFailure,
  fetchSubscribedChannelSuccess,
  fetchSubscribedChannelFailure,
  subUnsubChannelSuccess,
  subUnsubChannelFailure,
  fetchChannelVideosSuccess,
  createChannelSuccess,
  createChannelFailure,
  deleteChannelSuccess,
  deleteChannelFailure,
  fetchSubscribedChannelStart,
  fetchOwnerChannelListSuccess,
  fetchOwnerChannelListFailure,
  fetchSingleChannelOwnerSuccess,
  fetchSingleChannelOwnerFailure,
  fetchSingleVideoChannelOwnerSuccess,
  fetchSingleVideoChannelOwnerFailure,
  fetchSingleChannelStart,
} from "../actions/ChannelAction";
import {
  FETCH_CHANNELS_START,
  FETCH_SINGLE_CHANNEL_START,
  FETCH_CHANNEL_VIDEOS_START,
  FETCH_CHANNEL_PLAYLISTS_START,
  SUBSCRIBE_UNSUBS_CHANNEL_START,
  CREATE_CHANNEL_START,
  DELETE_CARD_START,
  FETCH_SUBSCRIBED_CHANNEL_START,
  FETCH_SINGLE_CHANNEL_OWNER_START,
  FETCH_OWNER_CHANNEL_LIST_START,
  FETCH_SINGLE_VIDEO_CHANNEL_OWNER_START,
} from "../actions/ActionConstant";

function* fetchChannelAPI() {
  try {
    const inputData = yield select((state) => state.channel.channels.inputData);
    const response = yield api.postMethod("channels_index", inputData);
    if (response.data.success) {
      yield put(fetchChannelsSuccess(response.data.data));
    } else {
      yield put(fetchChannelsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchChannelsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchSingleChannelAPI() {
  try {
    const inputData = yield select(
      (state) => state.channel.singleChannel.inputData
    );
    const response = yield api.postMethod("channels_view", inputData);
    if (response.data.success) {
      yield put(fetchSingleChannelSuccess(response.data.data));
    } else {
      yield put(fetchSingleChannelFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSingleChannelFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchChannelVideoAPI() {
  try {
    const inputData = yield select(
      (state) => state.channel.channelVideos.inputData
    );
    const response = yield api.postMethod("channel_based_videos", inputData);
    if (response.data.success) {
      yield put(fetchChannelVideosSuccess(response.data.data));
    } else {
      yield put(fetchChannelVideosFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchChannelVideosFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}
function* fetchChannelPlaylistsApi() {
  try {
    const inputData = yield select(
      (state) => state.channel.channelVideos.inputData
    );
    const response = yield api.postMethod("channel_playlists", inputData);
    if (response.data.success) {
      yield put(fetchChannelVideosSuccess(response.data.data));
    } else {
      yield put(fetchChannelVideosFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchChannelVideosFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchSubscribedChannelAPI() {
  try {
    const skipCount = yield select((state) => state.channel.subChannel.skip);
    const response = yield api.postMethod("channels_subscribed", {
      skip: skipCount,
    });
    if (response.data.success) {
      yield put(fetchSubscribedChannelSuccess(response.data.data));
    } else {
      yield put(fetchSubscribedChannelFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSubscribedChannelFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* subUnsubChannelAPI() {
  try {
    if(localStorage.getItem("userLoginStatus") != 'true') {
      yield put(subUnsubChannelFailure('Login to Continue'));
      const notificationMessage = getErrorNotificationMessage(
        'Login to Continue'
      );
      yield put(createNotification(notificationMessage)); 
    } else {

      const inputData = yield select(
        (state) => state.channel.subUnsubChannel.inputData
      );
      const response = yield api.postMethod(
        "channels_unsubscribe_subscribe",
        inputData
      );
      if (response.data.success) {
        yield put(subUnsubChannelSuccess(response.data.data));
        const notificationMessage = getSuccessNotificationMessage(
          response.data.message
        );
        yield put(createNotification(notificationMessage));
        yield put(
          fetchSingleChannelStart({
            channel_id: inputData.channel_id,
            view_type: "viewer",
          })
        );
      } else {
        yield put(subUnsubChannelFailure(response.data.error));
        const notificationMessage = getErrorNotificationMessage(
          response.data.error
        );
        yield put(createNotification(notificationMessage));
      }

    }
  } catch (error) {
    yield put(subUnsubChannelFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* createChannelAPI() {
  try {
    const inputData = yield select(
      (state) => state.channel.createChannel.inputData
    );
    const response = yield api.postMethod("channels_save", inputData);
    if (response.data.success) {
      yield put(createChannelSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign("/channel-list");
    } else {
      yield put(createChannelFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(createChannelFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* deleteChannelAPI() {
  try {
    const inputData = yield select(
      (state) => state.channel.channelVideos.inputData
    );
    const response = yield api.postMethod("spam_videos_remove", inputData);
    if (response.data.success) {
      yield put(deleteChannelSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(deleteChannelFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(deleteChannelFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchChannelOwnerListAPI() {
  try {
    const response = yield api.postMethod("channels_index_for_owner");
    if (response.data.success) {
      yield put(fetchOwnerChannelListSuccess(response.data.data));
    } else {
      yield put(fetchOwnerChannelListFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchOwnerChannelListFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* singleChannelOwnerAPI() {
  try {
    const inputData = yield select(
      (state) => state.channel.singleChannelOwner.inputData
    );
    const response = yield api.postMethod("channels_view_for_owner", inputData);
    if (response.data.success) {
      yield put(fetchSingleChannelOwnerSuccess(response.data.data));
    } else {
      yield put(fetchSingleChannelOwnerFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSingleChannelOwnerFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* singleChannelOwnerVideoAPI() {
  try {
    const inputData = yield select(
      (state) => state.channel.singleChannelOwnerVideo.inputData
    );
    const response = yield api.postMethod(
      "channel_based_videos_for_owner",
      inputData
    );
    if (response.data.success) {
      yield put(fetchSingleVideoChannelOwnerSuccess(response.data.data));
    } else {
      yield put(fetchSingleVideoChannelOwnerFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSingleVideoChannelOwnerFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_CHANNELS_START, fetchChannelAPI)]);
  yield all([
    yield takeLatest(FETCH_SINGLE_CHANNEL_START, fetchSingleChannelAPI),
  ]);
  yield all([
    yield takeLatest(FETCH_CHANNEL_VIDEOS_START, fetchChannelVideoAPI),
  ]);
  yield all([
    yield takeLatest(FETCH_CHANNEL_PLAYLISTS_START, fetchChannelPlaylistsApi),
  ]);
  yield all([
    yield takeLatest(SUBSCRIBE_UNSUBS_CHANNEL_START, subUnsubChannelAPI),
  ]);
  yield all([yield takeLatest(CREATE_CHANNEL_START, createChannelAPI)]);
  yield all([yield takeLatest(DELETE_CARD_START, deleteChannelAPI)]);
  yield all([
    yield takeLatest(FETCH_SUBSCRIBED_CHANNEL_START, fetchSubscribedChannelAPI),
  ]);
  yield all([
    yield takeLatest(FETCH_SINGLE_CHANNEL_OWNER_START, singleChannelOwnerAPI),
  ]);
  yield all([
    yield takeLatest(FETCH_OWNER_CHANNEL_LIST_START, fetchChannelOwnerListAPI),
  ]);
  yield all([
    yield takeLatest(
      FETCH_SINGLE_VIDEO_CHANNEL_OWNER_START,
      singleChannelOwnerVideoAPI
    ),
  ]);
}
