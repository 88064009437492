import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  fetchSingleCategoryFailure,
  fetchSingleCategorySuccess,
  fetchCategoriesFailure,
  fetchCategoriesSuccess,
  fetchCategoryVideoFailure,
  fetchCategoryVideoSuccess,
} from "../actions/CategoryAction";
import {
  FETCH_SINGLE_CATEGORY_START,
  FETCH_CATEGORIES_START,
  FETCH_CATEGORY_VIDEOS_START,
} from "../actions/ActionConstant";

function* fetchCategoriesAPI() {
  try {
    const response = yield api.postMethod("categories_index");
    if (response.data.success) {
      yield put(fetchCategoriesSuccess(response.data.data));
    } else {
      yield put(fetchCategoriesFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchCategoriesFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchCategoryVideoAPI() {
  try {
    const inputData = yield select(
      (state) => state.category.categoryVideo.inputData
    );
    const response = yield api.postMethod("video_tapes_history_add", inputData);
    if (response.data.success) {
      yield put(fetchCategoryVideoSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(fetchCategoryVideoFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchCategoryVideoFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchSingleCategoryAPI() {
  try {
    const inputData = yield select(
      (state) => state.category.singleCategory.inputData
    );
    const response = yield api.postMethod(
      "video_tapes_history_remove",
      inputData
    );
    if (response.data.success) {
      yield put(fetchSingleCategorySuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(fetchSingleCategoryFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSingleCategoryFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_CATEGORIES_START, fetchCategoriesAPI)]);
  yield all([
    yield takeLatest(FETCH_CATEGORY_VIDEOS_START, fetchCategoryVideoAPI),
  ]);
  yield all([
    yield takeLatest(FETCH_SINGLE_CATEGORY_START, fetchSingleCategoryAPI),
  ]);
}
