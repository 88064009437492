import React, { Component } from "react";
import { Form, Button, Container, Row, Col, Image } from "react-bootstrap";
import "./DeleteAccount.css";
import UseForm from "../../helper/UseForm";
import { deleteAccountStart } from "../../../store/actions/UserAction";
import { connect } from "react-redux";

const DeleteAccountIndex = (props) => {
  const { values, handleChange, handleSubmit } = UseForm(deleteAccount);

  function deleteAccount() {
    console.log(values);
    props.dispatch(deleteAccountStart(values));
  }
  return (
    <>
      <div className="delete-account-sec">
        <Container>
          <div className="delete-account-box">
            <Row>
              <Col sm={12} md={12}>
                <h2 className="section-title">Hope, see you soon</h2>
                <div className="delete-account">
                  <Row>
                    <Col sm={12} md={6}>
                      <Image
                        src={
                          window.location.origin +
                          "/assets/img/delete-account-img.svg"
                        }
                        className="delete-account-img"
                      />
                    </Col>
                    <Col sm={12} md={6}>
                      <div className="delete-account-form-sec">
                        <h3 className="section-note">
                          <strong className="text-highlight">Note:</strong> Once
                          you deleted account, you will lose your history and
                          wishlist details.
                        </h3>
                        <Form
                          className="delete-account-form"
                          onSubmit={handleSubmit}
                        >
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Password"
                              name="password"
                              onChange={handleChange}
                              value={values.password}
                            />
                          </Form.Group>
                          <div className="action-btn">
                            <Button
                              className="reset-btn"
                              type="submit"
                              disabled={props.users.buttonDisable}
                            >
                              {props.users.loadingButtonContent != null
                                ? props.users.loadingButtonContent
                                : "Delete"}
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  users: state.users,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(DeleteAccountIndex);
