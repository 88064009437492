import {
  FETCH_SINGLE_VIDEO_START,
  FETCH_SINGLE_VIDEO_SUCCESS,
  FETCH_SINGLE_VIDEO_FAILURE,
  FETCH_VIDEO_COMMENTS_START,
  FETCH_VIDEO_COMMENTS_SUCCESS,
  FETCH_VIDEO_COMMENTS_FAILURE,
  ADD_COMMENTS_START,
  ADD_COMMENTS_SUCCESS,
  ADD_COMMENTS_FAILURE,
  LIKE_VIDEO_START,
  LIKE_VIDEO_SUCCESS,
  LIKE_VIDEO_FAILURE,
  DISLIKE_VIDEO_START,
  DISLIKE_VIDEO_SUCCESS,
  DISLIKE_VIDEO_FAILURE,
  UPLOAD_VIDEO_START,
  UPLOAD_VIDEO_SUCCESS,
  UPLOAD_VIDEO_FAILURE,
  SET_DEFAULT_IMAGE_START,
  SET_DEFAULT_IMAGE_SUCCESS,
  SET_DEFAULT_IMAGE_FAILURE,
  FETCH_VIDEO_IMAGES_START,
  FETCH_VIDEO_IMAGES_SUCCESS,
  FETCH_VIDEO_IMAGES_FAILURE,
} from "./ActionConstant";

// fetch single video action.

export function fetchSingleVideoStart(data) {
  return {
    type: FETCH_SINGLE_VIDEO_START,
    data,
  };
}

export function fetchSingleVideoSuccess(data) {
  return {
    type: FETCH_SINGLE_VIDEO_SUCCESS,
    data,
  };
}

export function fetchSingleVideoFailure(error) {
  return {
    type: FETCH_SINGLE_VIDEO_FAILURE,
    error,
  };
}

// fetch video comments action.

export function fetchVideoCommentsStart(data) {
  return {
    type: FETCH_VIDEO_COMMENTS_START,
    data,
  };
}

export function fetchVideoCommentsSuccess(data) {
  return {
    type: FETCH_VIDEO_COMMENTS_SUCCESS,
    data,
  };
}

export function fetchVideoCommentsFailure(error) {
  return {
    type: FETCH_VIDEO_COMMENTS_FAILURE,
    error,
  };
}

// add comments action.

export function addCommentsStart(data) {
  return {
    type: ADD_COMMENTS_START,
    data,
  };
}

export function addCommentsSuccess(data) {
  return {
    type: ADD_COMMENTS_SUCCESS,
    data,
  };
}

export function addCommentsFailure(error) {
  return {
    type: ADD_COMMENTS_FAILURE,
    error,
  };
}

// like video action.

export function likeVideoStart(data) {
  return {
    type: LIKE_VIDEO_START,
    data,
  };
}

export function likeVideoSuccess(data) {
  return {
    type: LIKE_VIDEO_SUCCESS,
    data,
  };
}

export function likeVideoFailure(error) {
  return {
    type: LIKE_VIDEO_FAILURE,
    error,
  };
}

// dislike video action.

export function dislikeVideoStart(data) {
  return {
    type: DISLIKE_VIDEO_START,
    data,
  };
}

export function dislikeVideoSuccess(data) {
  return {
    type: DISLIKE_VIDEO_SUCCESS,
    data,
  };
}

export function dislikeVideoFailure(error) {
  return {
    type: DISLIKE_VIDEO_FAILURE,
    error,
  };
}

// UPload video

export function uploadVideoStart(data) {
  return {
    type: UPLOAD_VIDEO_START,
    data,
  };
}

export function uploadVideoSuccess(data) {
  return {
    type: UPLOAD_VIDEO_SUCCESS,
    data,
  };
}

export function uploadVideoFailure(error) {
  return {
    type: UPLOAD_VIDEO_FAILURE,
    error,
  };
}


export function setDefaultImageStart(data) {
  return {
    type: SET_DEFAULT_IMAGE_START,
    data,
  };
}

export function setDefaultImageSuccess(data) {
  return {
    type:  SET_DEFAULT_IMAGE_SUCCESS,
    data,
  };
}

export function setDefaultImageFailure(error) {
  return {
    type:  SET_DEFAULT_IMAGE_FAILURE,
    error,
  };
}

export function fetchVideoImagesStart(data) {
  return {
    type: FETCH_VIDEO_IMAGES_START,
    data,
  };
}

export function fetchVideoImagesSuccess(data) {
  return {
    type: FETCH_VIDEO_IMAGES_SUCCESS,
    data,
  };
}

export function fetchVideoImagesFailure(error) {
  return {
    type: FETCH_VIDEO_IMAGES_FAILURE,
    error,
  };
}