import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./SingleLiveTv.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import {
  fetchLiveTvStart,
  fetchSingleLiveTvStart,
} from "../../store/actions/LiveTvAction";
import LiveTvVideoPlayerSec from "./LiveTvVideoPlayerSec";
import ImageLoader from "../helper/ImageLoader";
import SingleVideoAboutLoader from "../Loader/SingleVideoAboutLoader";
import NoResultFound from "../NoResultFound/NoResultFound";

const SingleLiveTv = (props) => {
  const [singleLiveTvId, setSingleLiveTvId] = useState(0);

  useEffect(() => {
    props.dispatch(
      fetchSingleLiveTvStart({
        custom_live_video_id: props.match.params.unique_id,
      })
    );
    if (props.livetv.loading)
      props.dispatch(
        fetchLiveTvStart({ custom_live_video_id: props.match.params.unique_id })
      );
  }, [singleLiveTvId]);

  return (
    <>
      <div className="single-livetv-sec">
        <Container>
          <Row>
            <Col sm={12} md={12}>
              <h2 className="section-title">Live Tv</h2>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12}>
              <div className="single-live-tv-box">
                {props.singleLivetv.loading ? (
                  <SingleVideoAboutLoader />
                ) : (
                  <Row>
                    <Col sm={12} md={8}>
                      <div className="video-image-sec">
                        <LiveTvVideoPlayerSec video={props.singleLivetv.data} />
                      </div>

                      <h5 className="video-title">
                        {props.singleLivetv.data.title}
                      </h5>
                      <p>{props.singleLivetv.data.created_time}</p>

                      <p>{props.singleLivetv.data.description}</p>
                    </Col>

                    <Col sm={12} md={4}>
                      <div className="up-next-right-sec">
                        <div className="up-next-right-header">
                          <h4 className="side-title">Suggestions</h4>
                        </div>
                        <div className="up-next-right-video-sec">
                          {props.livetv.loading
                            ? "Loading...."
                            : props.livetv.data.length > 0
                            ? props.livetv.data.map((video) => (
                                <Link
                                  to={`/livetv/${video.custom_live_video_id}`}
                                  onClick={() =>
                                    setSingleLiveTvId(
                                      video.custom_live_video_id
                                    )
                                  }
                                >
                                  <div className="up-next-right-video-card">
                                    <Row>
                                      <Col sm={12} md={6}>
                                        <div className="up-next-right-video-card-img">
                                          <ImageLoader
                                            image={video.image}
                                            className="list-video-img"
                                          />
                                        </div>
                                        <div className="time-count">
                                          <h6>Live</h6>
                                        </div>
                                      </Col>
                                      <Col sm={12} md={6}>
                                        <div className="up-next-video-card-details">
                                          <h4 className="title">
                                            {video.title}
                                          </h4>
                                          <p className="desc">
                                            {video.publish_time}
                                          </p>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </Link>
                              ))
                            : <NoResultFound/>}
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  singleLivetv: state.livetv.singleLivetv,
  livetv: state.livetv.livetv,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SingleLiveTv);
