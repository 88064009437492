import {
  FETCH_TAGS_START,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_FAILURE,
  FETCH_TAG_VIDEOS_START,
  FETCH_TAG_VIDEOS_SUCCESS,
  FETCH_TAG_VIDEOS_FAILURE,
  FETCH_SINGLE_TAG_START,
  FETCH_SINGLE_TAG_SUCCESS,
  FETCH_SINGLE_TAG_FAILURE,
} from "./ActionConstant";

// fetch Tags action.

export function fetchTagsStart(data) {
  return {
    type: FETCH_TAGS_START,
    data,
  };
}

export function fetchTagsSuccess(data) {
  return {
    type: FETCH_TAGS_SUCCESS,
    data,
  };
}

export function fetchTagsFailure(error) {
  return {
    type: FETCH_TAGS_FAILURE,
    error,
  };
}

// single tag video action.

export function fetchTagVideoStart(data) {
  return {
    type: FETCH_TAG_VIDEOS_START,
    data,
  };
}

export function fetchTagVideoSuccess(data) {
  return {
    type: FETCH_TAG_VIDEOS_SUCCESS,
    data,
  };
}

export function fetchTagVideoFailure(error) {
  return {
    type: FETCH_TAG_VIDEOS_FAILURE,
    error,
  };
}

// fetch single tag action.

export function fetchSingleTagStart(data) {
  return {
    type: FETCH_SINGLE_TAG_START,
    data,
  };
}

export function fetchSingleTagSuccess(data) {
  return {
    type: FETCH_SINGLE_TAG_SUCCESS,
    data,
  };
}

export function fetchSingleTagFailure(error) {
  return {
    type: FETCH_SINGLE_TAG_FAILURE,
    error,
  };
}
