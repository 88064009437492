import React, { useEffect, useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";

const SetPPVModal = (props) => {
  const [data, setData] = useState({});

  useEffect(() => {
    setData({
      type_of_subscription: props.ppvData.type_of_subscription,
      video_tape_id: props.ppvData.video_tape_id,
      ppv_amount: props.ppvData.ppv_amount,
    });
  }, []);

  return (
    <Modal
      className="ppv-modal"
      size="lg"
      centered
      show={props.ppvModal}
      onHide={props.closePPVModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Set PPV</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="add-play-list-form">
          <Form>
            <div className="payment-row">
              <div className="payment-label">
                <Form.Label>Payment Type</Form.Label>
              </div>
              <div className="payment-btn">
                <Form.Check
                  custom
                  inline
                  label="One time Payment"
                  type="radio"
                  id="one_time"
                  value={1}
                  defaultChecked={
                    props.ppvData.type_of_subscription === 1 ? true : false
                  }
                  name="type_of_subscription"
                  onChange={() =>
                    props.setPpvData({
                      ...props.ppvData,
                      type_of_subscription: 1,
                    })
                  }
                />
                <Form.Check
                  custom
                  inline
                  label="Recurring Payment"
                  type="radio"
                  id="recurring"
                  value={2}
                  name="type_of_subscription"
                  defaultChecked={
                    props.ppvData.type_of_subscription === 2 ? true : false
                  }
                  onChange={() =>
                    props.setPpvData({
                      ...props.ppvData,
                      type_of_subscription: 2,
                    })
                  }
                />
              </div>
            </div>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                min="0"
                step="any"
                value={props.ppvData.ppv_amount}
                onChange={(event) =>
                  props.setPpvData({
                    ...props.ppvData,
                    ppv_amount: event.currentTarget.value,
                  })
                }
              />
            </Form.Group>
            <Button
              className="btn create-btn"
              disabled={props.setRemovePPVVM.buttonDisable}
              onClick={(event) => {
                props.setPPVSubmit(event);
              }}
            >
              {props.setRemovePPVVM.loadingButtonContent !== null
                ? props.setRemovePPVVM.loadingButtonContent
                : "Set"}
            </Button>
            <Button
              className="btn create-btn"
              disabled={props.setRemovePPVVM.buttonDisable}
              onClick={props.removePPVSubmit}
            >
              {props.setRemovePPVVM.loadingButtonContent !== null
                ? props.setRemovePPVVM.loadingButtonContent
                : "Remove"}
            </Button>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SetPPVModal;
