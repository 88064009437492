import {
  FETCH_LS_OWNER_START,
  FETCH_LS_OWNER_SUCCESS,
  FETCH_LS_OWNER_FAILURE,
  FETCH_SINGLE_LS_OWNER_START,
  FETCH_SINGLE_LS_OWNER_SUCCESS,
  FETCH_SINGLE_LS_OWNER_FAILURE,
  END_LS_OWNER_START,
  END_LS_OWNER_SUCCESS,
  END_LS_OWNER_FAILURE,
  ERASE_OLD_LS_START,
  ERASE_OLD_LS_SUCCESS,
  ERASE_OLD_LS_FAILURE,
  CHECK_LS_STATUS_START,
  CHECK_LS_STATUS_SUCCESS,
  CHECK_LS_STATUS_FAILURE,
  SAVE_LS_OWNER_START,
  SAVE_LS_OWNER_SUCCESS,
  SAVE_LS_OWNER_FAILURE,
  FETCH_LS_USER_START,
  FETCH_LS_USER_SUCCESS,
  FETCH_LS_USER_FAILURE,
  FETCH_SINGLE_LS_USER_START,
  FETCH_SINGLE_LS_USER_SUCCESS,
  FETCH_SINGLE_LS_USER_FAILURE,
} from "./ActionConstant";

// fetch live streaming owner video list action.

export function fetchLSOwnerStart(data) {
  return {
    type: FETCH_LS_OWNER_START,
    data,
  };
}

export function fetchLSOwnerSuccess(data) {
  return {
    type: FETCH_LS_OWNER_SUCCESS,
    data,
  };
}

export function fetchLSOwnerFailure(error) {
  return {
    type: FETCH_LS_OWNER_FAILURE,
    error,
  };
}

// fetch single live streaming owner  action.

export function fetchSingleLSOwnerStart(data) {
  return {
    type: FETCH_SINGLE_LS_OWNER_START,
    data,
  };
}

export function fetchSingleLSOwnerSuccess(data) {
  return {
    type: FETCH_SINGLE_LS_OWNER_SUCCESS,
    data,
  };
}

export function fetchSingleLSOwnerFailure(error) {
  return {
    type: FETCH_SINGLE_LS_OWNER_FAILURE,
    error,
  };
}

// End live streaming  action.

export function endLSOwnerStart(data) {
  return {
    type: END_LS_OWNER_START,
    data,
  };
}

export function endLSOwnerSuccess(data) {
  return {
    type: END_LS_OWNER_SUCCESS,
    data,
  };
}

export function endLSOwnerFailure(error) {
  return {
    type: END_LS_OWNER_FAILURE,
    error,
  };
}

// Erase old live streaming action.

export function eraseOldLSStart(data) {
  return {
    type: ERASE_OLD_LS_START,
    data,
  };
}

export function eraseOldLSSuccess(data) {
  return {
    type: ERASE_OLD_LS_SUCCESS,
    data,
  };
}

export function eraseOldLSFailure(error) {
  return {
    type: ERASE_OLD_LS_FAILURE,
    error,
  };
}

// check live streaming status action.

export function checkLSStatusStart(data) {
  return {
    type: CHECK_LS_STATUS_START,
    data,
  };
}

export function checkLSStatusSuccess(data) {
  return {
    type: CHECK_LS_STATUS_SUCCESS,
    data,
  };
}

export function checkLSStatusFailure(error) {
  return {
    type: CHECK_LS_STATUS_FAILURE,
    error,
  };
}

// Save live streaming action.

export function saveLSOwnerStart(data) {
  return {
    type: SAVE_LS_OWNER_START,
    data,
  };
}

export function saveLSOwnerSuccess(data) {
  return {
    type: SAVE_LS_OWNER_SUCCESS,
    data,
  };
}

export function saveLSOwnerFailure(error) {
  return {
    type: SAVE_LS_OWNER_FAILURE,
    error,
  };
}

// fetch live streaming user video list action.

export function fetchLSUserStart(data) {
  return {
    type: FETCH_LS_USER_START,
    data,
  };
}

export function fetchLSUserSuccess(data) {
  return {
    type: FETCH_LS_USER_SUCCESS,
    data,
  };
}

export function fetchLSUserFailure(error) {
  return {
    type: FETCH_LS_USER_FAILURE,
    error,
  };
}

// fetch single live streaming owner  action.

export function fetchSingleLSUserStart(data) {
  return {
    type: FETCH_SINGLE_LS_USER_START,
    data,
  };
}

export function fetchSingleLSUserSuccess(data) {
  return {
    type: FETCH_SINGLE_LS_USER_SUCCESS,
    data,
  };
}

export function fetchSingleLSUserFailure(error) {
  return {
    type: FETCH_SINGLE_LS_USER_FAILURE,
    error,
  };
}
