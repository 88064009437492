import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  fetchSpamVideosSuccess,
  fetchSpamVideosFailure,
  addToSpamSuccess,
  addToSpamFailure,
  removeSpamSuccess,
  removeSpamFailure,
  fetchSpamReasonSuccess,
  fetchSpamReasonFailure,
  fetchSpamVideosStart,
} from "../actions/SpamAction";
import {
  FETCH_SPAM_VIDEOS_START,
  REMOVE_SPAM_START,
  ADD_TO_SPAM_START,
  FETCH_SPAM_REASON_START,
} from "../actions/ActionConstant";

function* fetchSpamVideosAPI() {
  try {
    const response = yield api.postMethod("spam_videos");
    if (response.data.success) {
      yield put(fetchSpamVideosSuccess(response.data.data));
    } else {
      yield put(fetchSpamVideosFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSpamVideosFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* addToSpamAPI() {
  try {
    if(localStorage.getItem("userLoginStatus") != 'true') {
      yield put(addToSpamFailure('Login to Continue'));
      const notificationMessage = getErrorNotificationMessage(
        'Login to Continue'
      );
      yield put(createNotification(notificationMessage)); 
    } else {
      const inputData = yield select((state) => state.spam.addSpam.inputData);
      const response = yield api.postMethod("spam_videos_add", inputData);
      if (response.data.success) {
        yield put(addToSpamSuccess(response.data.data));
        const notificationMessage = getSuccessNotificationMessage(
          response.data.message
        );
        yield put(createNotification(notificationMessage));
        window.location.assign("/");
      } else {
        yield put(addToSpamFailure(response.data.error));
        const notificationMessage = getErrorNotificationMessage(
          response.data.error
        );
        yield put(createNotification(notificationMessage));
      }
    }
  } catch (error) {
    yield put(addToSpamFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* removeSpamAPI() {
  try {
    const inputData = yield select((state) => state.spam.removeSpam.inputData);
    const response = yield api.postMethod("spam_videos_remove", inputData);
    if (response.data.success) {
      yield put(removeSpamSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      yield put(fetchSpamVideosStart(0));
    } else {
      yield put(removeSpamFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(removeSpamFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchSpamReasonAPI() {
  try {
    const response = yield api.postMethod("spam_reasons");
    if (response.data.success) {
      yield put(fetchSpamReasonSuccess(response.data.data));
    } else {
      yield put(fetchSpamReasonFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSpamReasonFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_SPAM_VIDEOS_START, fetchSpamVideosAPI)]);
  yield all([yield takeLatest(ADD_TO_SPAM_START, addToSpamAPI)]);
  yield all([yield takeLatest(REMOVE_SPAM_START, removeSpamAPI)]);
  yield all([yield takeLatest(FETCH_SPAM_REASON_START, fetchSpamReasonAPI)]);
}
