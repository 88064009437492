import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Form,
  FormControl,
  Container,
  Row,
  Col,
  InputGroup,
  Image,
  Table,
} from "react-bootstrap";
import "./InvoicePage.css";
import {
  fetchSingleSubscriptionStart,
  subscriptionPaymentStart,
  checkCouponCodeStart,
  subscriptionPaymentPayPalStart,
} from "../../store/actions/SubscriptionAction";
import { fetchCardDetailsStart } from "../../store/actions/CardsAction";
import { connect } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../helper/NotificationMessage";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import PaypalExpressBtn from "react-paypal-express-checkout";
import AddCardSec from "../Accounts/Cards/AddCardSec";

const stripePromise = loadStripe("pk_test_uDYrTXzzAuGRwDYtu7dkhaF3");

class SubscriptionInvoice extends Component {
  state = {
    loading: true,
    couponInputData: {},
    showPayPal: false,
    showAddCardButton: false,
  };

  componentDidMount() {
    if (this.props.location.state != null) {
      this.setState({ loading: false });
    } else {
      this.props.dispatch(
        fetchSingleSubscriptionStart({
          subscription_id: this.props.match.params.id,
        })
      );
    }

    const couponInputData = { ...this.state.couponInputData };
    couponInputData["subscription_id"] = this.props.match.params.id;
    this.setState({ couponInputData });
  }

  handleChange = ({ currentTarget: input }) => {
    const couponInputData = { ...this.state.couponInputData };
    couponInputData[input.name] = input.value;
    this.setState({ couponInputData });
  };

  checkCouponCode = (event) => {
    event.preventDefault();
    this.props.dispatch(checkCouponCodeStart(this.state.couponInputData));
  };

  removeCouponCode = (event) => {
    event.preventDefault();
    const couponInputData = { ...this.state.couponInputData };
    couponInputData["coupon_code"] = "";
    this.setState({ couponInputData });
    const notificationMessage = getErrorNotificationMessage(
      "Coupon Code Removed"
    );
    this.props.dispatch(createNotification(notificationMessage));
  };

  // Change Payment

  choosePaymentOption = ({ currentTarget: input }) => {
    this.setState({ paymentMode: input.value });
    if (input.value == "card") {
      if (this.props.cards.cardDetails.loading) {
        this.props.dispatch(fetchCardDetailsStart());
        const notificationMessage = getSuccessNotificationMessage(
          "Getting Card Details.."
        );
        this.props.dispatch(createNotification(notificationMessage));
      } else if (this.props.cards.cardDetails.data.cards.length === 0) {
        this.setState({ showAddCardButton: true });
      }
      this.setState({ showPayPal: false });
    }
    if (input.value == "paypal") {
      this.setState({ showPayPal: true, showAddCardButton: false });
    }
  };

  cardAddedStatusChange = () => {
    this.setState({ showAddCardButton: !this.state.showAddCardButton });
  };

  payNow = (event) => {
    event.preventDefault();
    if (this.props.cards.cardDetails.data.cards.length > 0) {
      this.props.dispatch(subscriptionPaymentStart(this.state.couponInputData));
    } else {
      this.cardAddedStatusChange();
      const notificationMessage = getSuccessNotificationMessage(
        "Please add card to make payment"
      );
      this.props.dispatch(createNotification(notificationMessage));
    }
  };

  paypalOnSuccess = (payment) => {
    this.setState({
      paynowButtonDisable: true,
      paynowButtonLoadingContent: "Payment Processing...",
    });
    const couponInputData = { ...this.state.couponInputData };
    couponInputData["payment_id"] = payment.payment_id;
    this.setState({ couponInputData });
    setTimeout(() => {
      this.props.dispatch(
        subscriptionPaymentPayPalStart(this.state.couponInputData)
      );
    }, 1000);
  };

  paypalOnError = (err) => {
    const notificationMessage = getErrorNotificationMessage(err);
    this.props.dispatch(createNotification(notificationMessage));
  };

  paypalOnCancel = (data) => {
    const notificationMessage = getErrorNotificationMessage(
      "Payment cancelled please try again.."
    );
    this.props.dispatch(createNotification(notificationMessage));
  };

  handleSubmit = (event, subscription) => {
    this.props.dispatch(
      subscriptionPaymentStart({
        subscription_id: subscription.subscription_id,
      })
    );
  };

  render() {
    let subscriptionData;
    if (this.props.location.state != null) {
      subscriptionData = this.props.location.state;
    } else {
      subscriptionData = this.props.subscriptions.singleSubscription.data;
    }
    const subscriptions = this.props.subscriptions;
    let env = "sandbox"; // you can set here to 'production' for production
    let currency = "USD"; // or you can set this value from your props or state
    let total; // same as above, this is the total amount (based on currency) to be paid by using Paypal express checkout
    if (!subscriptions.checkCoupon.loading) {
      total = subscriptions.checkCoupon.success.remaining_amount;
    } else {
      total = subscriptionData.amount;
    }
    const client = {
      sandbox:
        "AaXkweZD5g9s0X3BsO0Y4Q-kNzbmLZaog0mbmVGrTT5IX0O73LoLVcHp17e6pkG7Vm04JEUuG6up30LD",
    };
    return (
      <>
        <div className="invoice-sec">
          <Container>
            <div className="invoice-sec-box">
              {subscriptionData != null ? (
                <Row>
                  <Col sm={12} md={12}>
                    <div className="invoice-icon-sec">
                      <Image
                        src={window.location.origin + "/assets/img/invoice.svg"}
                        className="invoice-icon"
                      />
                    </div>
                    <p className="invoice-text">
                      <b>Plan</b>&nbsp;-&nbsp;{subscriptionData.title}
                    </p>
                    <p className="invoice-text">
                      <b>Plan Type</b>&nbsp;-&nbsp;
                      {subscriptionData.plan_formatted}
                    </p>
                    <Form className="invoice-form">
                      <Form.Label>Coupon</Form.Label>
                      <InputGroup className="mb-3">
                        <FormControl
                          placeholder="Coupon code"
                          aria-describedby="basic-addon2"
                          type="text"
                          name="coupon_code"
                          onChange={this.handleChange}
                          value={this.state.couponInputData.coupon_code}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text
                            id="basic-addon2"
                            onClick={this.checkCouponCode}
                          >
                            {subscriptions.checkCoupon.loadingButtonContent !=
                            null
                              ? subscriptions.checkCoupon.loadingButtonContent
                              : "Apply"}
                          </InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                      <Form.Label>Price Details</Form.Label>
                      <div className="amount-details">
                        <Table striped responsive="sm">
                          <tbody>
                            <tr>
                              <td>Amount</td>
                              <td>{subscriptionData.amount_formatted}</td>
                            </tr>
                            {subscriptions.checkCoupon
                              .loading ? null : subscriptions.checkCoupon
                                .error == false ? (
                              <tr>
                                <td>Coupon Amount</td>
                                <td>
                                  {
                                    subscriptions.checkCoupon.success
                                      .original_coupon_amount
                                  }
                                </td>
                              </tr>
                            ) : null}
                            <tr>
                              <td>Total</td>
                              <td>
                                {subscriptions.checkCoupon.loading ? (
                                  subscriptionData.amount_formatted
                                ) : subscriptions.checkCoupon.error == false ? (
                                  <>
                                    {" "}
                                    $
                                    {
                                      subscriptions.checkCoupon.success
                                        .remaining_amount
                                    }
                                  </>
                                ) : (
                                  subscriptionData.amount_formatted
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <Form.Label>Choose Your Payment Method</Form.Label>
                      <ul className="list-unstyled payment-type-list">
                        <Form.Check
                          type="radio"
                          custom
                          name="payment_type"
                          label="Card"
                          id="card"
                          value="card"
                          onChange={this.choosePaymentOption}
                        />
                        <Form.Check
                          type="radio"
                          custom
                          name="payment_type"
                          label="PayPal"
                          id="paypal"
                          value="paypal"
                          onChange={this.choosePaymentOption}
                        />
                      </ul>
                      {this.state.showAddCardButton ? (
                        <div className="form-group size-16 mb-zero">
                          <div className="row">
                            <Col sm={12} md={6}>
                              <div className="add-cards-form">
                                <Elements stripe={stripePromise}>
                                  <AddCardSec />
                                </Elements>
                              </div>
                            </Col>
                            <div className="col-md-4"></div>
                          </div>
                        </div>
                      ) : null}

                      {this.state.showPayPal && total != 0 ? (
                        <PaypalExpressBtn
                          env={env}
                          client={client}
                          currency={currency}
                          total={total}
                          onError={this.paypalOnError}
                          onSuccess={this.paypalOnSuccess}
                          onCancel={this.paypalOnCancel}
                        />
                      ) : null}
                      {this.state.showPayPal != true ? (
                        <div className="paynow-btn-sec">
                          <button
                            className="paynow-btn"
                            onClick={this.payNow}
                            disabled={subscriptions.buttonDisable}
                          >
                            {subscriptions.loadingButtonContent != null
                              ? subscriptions.loadingButtonContent
                              : "Pay Now"}
                          </button>
                        </div>
                      ) : null}
                    </Form>
                  </Col>
                </Row>
              ) : (
                "loading..."
              )}
            </div>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToPros = (state) => ({
  subscriptions: state.subscriptions,
  cards: state.cards,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SubscriptionInvoice);
