import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  checkLSStatusFailure,
  checkLSStatusSuccess,
  endLSOwnerFailure,
  endLSOwnerSuccess,
  eraseOldLSFailure,
  eraseOldLSSuccess,
  fetchLSOwnerFailure,
  fetchLSOwnerSuccess,
  fetchSingleLSOwnerFailure,
  fetchSingleLSOwnerSuccess,
  saveLSOwnerFailure,
  saveLSOwnerStart,
  saveLSOwnerSuccess,
  fetchLSUserStart,
  fetchLSUserFailure,
  fetchLSUserSuccess,
  fetchSingleLSUserStart,
  fetchSingleLSUserFailure,
  fetchSingleLSUserSuccess,
} from "../actions/LiveStreamingAction";
import {
  CHECK_LS_STATUS_START,
  END_LS_OWNER_START,
  ERASE_OLD_LS_START,
  FETCH_LS_OWNER_START,
  FETCH_SINGLE_LS_OWNER_START,
  SAVE_LS_OWNER_START,
  FETCH_LS_USER_START,
  FETCH_SINGLE_LS_USER_START,
} from "../actions/ActionConstant";

function* fetchLSOwnerAPI() {
  try {
    const response = yield api.postMethod("live_videos_for_owner");
    if (response.data.success) {
      yield put(fetchLSOwnerSuccess(response.data.data));
    } else {
      yield put(fetchLSOwnerFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchLSOwnerFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchSingleLSOwnerAPI() {
  try {
    const inputData = yield select(
      (state) => state.liveStreaming.singleLSOwner.inputData
    );
    const response = yield api.postMethod(
      "live_videos_view_for_owner",
      inputData
    );
    if (response.data.success) {
      yield put(fetchSingleLSOwnerSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(fetchSingleLSOwnerFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSingleLSOwnerFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* endLSOwnerAPI() {
  try {
    const inputData = yield select(
      (state) => state.liveStreaming.endLSOwner.inputData
    );
    const response = yield api.postMethod("live_videos_end", inputData);
    if (response.data.success) {
      yield put(endLSOwnerSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign("/");
    } else {
      yield put(endLSOwnerFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(endLSOwnerFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* eraseLSOwnerAPI() {
  try {
    const inputData = yield select(
      (state) => state.liveStreaming.eraseLSOwner.inputData
    );
    const saveInputData = yield select(
      (state) => state.liveStreaming.saveLSOwner.inputData
    );
    const response = yield api.postMethod("live_videos_erase_ongoing");
    if (response.data.success) {
      yield put(eraseOldLSSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      yield put(saveLSOwnerStart(saveInputData));
    } else {
      yield put(eraseOldLSFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(eraseOldLSFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* checkLSStatusAPI() {
  try {
    const inputData = yield select(
      (state) => state.liveStreaming.lsStatus.inputData
    );
    const response = yield api.postMethod(
      "live_videos_check_streaming",
      inputData
    );
    if (response.data.success) {
      yield put(checkLSStatusSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(checkLSStatusFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(checkLSStatusFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* saveLSOwnerAPI() {
  try {
    const inputData = yield select(
      (state) => state.liveStreaming.saveLSOwner.inputData
    );
    const response = yield api.postMethod("live_videos_save", inputData);
    if (response.data.success) {
      yield put(saveLSOwnerSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign(
        `/broadcasting/${response.data.data.live_video_id}`
      );
    } else {
      yield put(saveLSOwnerFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      if (response.data.error_code === 170) {
        inputData.history.push("/livestreaming-confirmation");
      } else {
        window.location.assign("/channel-list");
      }
    }
  } catch (error) {
    yield put(saveLSOwnerFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchLSUserAPI() {
  try {
    const response = yield api.postMethod("live_videos_for_users");
    if (response.data.success) {
      yield put(fetchLSUserSuccess(response.data.data));
    } else {
      yield put(fetchLSUserFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchLSUserFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchSingleLSUserAPI() {
  try {
    const inputData = yield select(
      (state) => state.liveStreaming.singleLSOwner.inputData
    );
    const response = yield api.postMethod(
      "live_videos_view_for_users",
      inputData
    );
    if (response.data.success) {
      yield put(fetchSingleLSUserSuccess(response.data.data));
    } else {
      yield put(fetchSingleLSUserFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSingleLSUserFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_LS_OWNER_START, fetchLSOwnerAPI)]);
  yield all([
    yield takeLatest(FETCH_SINGLE_LS_OWNER_START, fetchSingleLSOwnerAPI),
  ]);
  yield all([yield takeLatest(END_LS_OWNER_START, endLSOwnerAPI)]);
  yield all([yield takeLatest(CHECK_LS_STATUS_START, checkLSStatusAPI)]);
  yield all([yield takeLatest(SAVE_LS_OWNER_START, saveLSOwnerAPI)]);
  yield all([yield takeLatest(ERASE_OLD_LS_START, eraseLSOwnerAPI)]);
  yield all([yield takeLatest(FETCH_LS_USER_START, fetchLSUserAPI)]);
  yield all([
    yield takeLatest(FETCH_SINGLE_LS_USER_START, fetchSingleLSUserAPI),
  ]);
}
