import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Tabs,
  Tab,
  Dropdown,
  DropdownButton,
  Image,
} from "react-bootstrap";
import "./ChannelStudio.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ChannelStudio extends Component {
  render() {
    return (
      <>
        <div className="studio-sec">
          <Container>
            <Row>
              <Col sm={12} md={12}>
                <h2 className="section-title"> Channel Studio Dashboard</h2>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12}>
                <div className="studio-sec-card">
                  <Row>
                    <Col sm={12} md={5}>
                      <div className="studio-card">
                        <div className="studio-icons">
                          <Image
                            src="assets/img/upload-video.svg"
                            className="studio-img"
                          />
                        </div>
                        <p className="card-desc">
                          Want to see metrics on your recent video? Upload and
                          publish a video to get started
                        </p>
                        <Form>
                          <Form.Group className="upload-btn-wrapper">
                            <button className="btn-upload">
                              Upload a file
                            </button>
                            <Form.File
                              id="exampleFormControlFile1"
                              label=""
                              accept="image/*"
                            />
                          </Form.Group>
                        </Form>
                      </div>
                    </Col>
                    <Col sm={12} md={7}>
                      <div className="studio-main-card">
                        <div className="studio-analytics">
                          <h3 className="title">Channel analytics</h3>
                          <p className="sub-title">Current Subscribers</p>
                          <p className="count">5</p>
                        </div>
                        <hr></hr>
                        <div className="studio-summary">
                          <h4>Summary</h4>
                          <p className="desc">Last 28 days</p>
                          <p className="desc">
                            <span>Views</span>
                            <strong>
                              <span className="float-right">0 0%</span>
                            </strong>
                          </p>
                          <p className="desc">
                            <span>Watch time (hours)</span>
                            <strong>
                              <span className="float-right">0.0 0%</span>
                            </strong>
                          </p>
                        </div>
                        <hr></hr>
                        <div className="studio-top-videos">
                          <h4>Top Videos</h4>
                          <p className="desc">Last 48 hours . Views</p>
                          <Link to="#" className="link">
                            Go To Channel Analytics
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="space-lg"></div>
                  <Row>
                    <Col sm={12} md={4}>
                      <div className="studio-main-card">
                        <div className="studio-news">
                          <h3 className="title">News</h3>
                          <p className="desc">
                            No new updates, but check back regularly to see
                            announcements geared specifically for the YouTube
                            creator community. Also check out:
                          </p>
                        </div>
                        <div className="studio-news-details">
                          <div className="news-icons">
                            <FontAwesomeIcon
                              icon={["fab", "youtube"]}
                              className="studio-icon-brand"
                            />
                          </div>
                          <div className="news-title">
                            <h6>YouTube Creators channel</h6>
                          </div>
                        </div>
                        <div className="studio-news-details">
                          <div className="news-icons">
                            <FontAwesomeIcon
                              icon={["fab", "blogger"]}
                              className="studio-icon-brand"
                            />
                          </div>
                          <div className="news-title">
                            <h6>YouTube Creators channel</h6>
                          </div>
                        </div>
                        <div className="studio-news-details">
                          <div className="news-icons">
                            <FontAwesomeIcon
                              icon={["fab", "twitter"]}
                              className="studio-icon-brand"
                            />
                          </div>
                          <div className="news-title">
                            <h6>YouTube Creators channel</h6>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col sm={12} md={4}>
                      <div className="studio-main-card">
                        <div className="studio-new-in">
                            <h3 className="title">What's new in Studio</h3>
                            <div className="studio-link-sec">
                              <Link to="#" className="new-link-to">
                                Find out when your viewers are on YouTube
                              </Link>
                              <FontAwesomeIcon
                              icon={["fas", "external-link-alt"]}
                              className="studio-icon-external"
                              />
                            </div>
                            <hr></hr>
                            <div className="studio-link-sec">
                              <Link to="#" className="new-link-to">
                              Upload multiple videos with batch uploads
                              </Link>
                              <FontAwesomeIcon
                              icon={["fas", "external-link-alt"]}
                              className="studio-icon-external"
                              />
                            </div>
                            <hr></hr>
                            <div className="studio-link-sec">
                              <Link to="#" className="new-link-to">
                              See more Studio updates
                              </Link>
                              <FontAwesomeIcon
                              icon={["fas", "external-link-alt"]}
                              className="studio-icon-external"
                              />
                            </div>
                        </div>
                      </div>
                    </Col>
                    <Col sm={12} md={4}>
                      <div className="studio-main-card">
                        <div className="studio-latest">
                          <h3 className="title">Creator Insider</h3>
                        <div className="studio-latest-new-img">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/img/video-1.jpg"
                            }
                            className="latest-img"
                          />
                        </div>
                        <h4>Hello Insiders!</h4>
                        <p>Today we're talking about the NEW "Checking" Monetization Icon, 
                          Smart Replies in Spanish, and Unlisted Video Review Insights!
                        </p>
                        <Link to="#" className="link">Watch On YouTube</Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default ChannelStudio;
