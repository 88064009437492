import React from "react";

const NotFoundPage = () => {
  return (
    <>
      <h1>Not found. Please check the URL</h1>
    </>
  );
};

export default NotFoundPage;
