import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import api from "../../../Environment";
import { connect } from "react-redux";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../helper/NotificationMessage";
import { fetchCardDetailsStart } from "../../../store/actions/CardsAction";
import { Button, Form } from "react-bootstrap";

const $ = window.$;

const AddCardModel = ({ dispatch }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [addCardButtonDisable, setAddCardButtonDisable] = useState(false);
  const [addCardLoadingContent, setAddCardLoadingContent] = useState(null);

  const addCard = async (ev) => {
    ev.preventDefault();
    setAddCardButtonDisable(true);
    setAddCardLoadingContent("Loading... Please wait");
    if (stripe) {
      await stripe
        .createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
        })
        .then((payload) => {
          // console.log("Payload", payload);
          const inputData = {
            card_token: payload.paymentMethod.id,
          };
          api
            .postMethod("cards_add", inputData)
            .then((response) => {
              if (response.data.success) {
                const notificationMessage = getSuccessNotificationMessage(
                  response.data.message
                );
                dispatch(createNotification(notificationMessage));
                dispatch(fetchCardDetailsStart());

                setAddCardButtonDisable(false);
                setAddCardLoadingContent(null);
              } else {
                const notificationMessage = getErrorNotificationMessage(
                  response.data.error
                );
                dispatch(createNotification(notificationMessage));
              }
              $("#add-card").modal("hide");
            })
            .catch((error) => {
              console.log("Error", error);
              setAddCardButtonDisable(false);
              setAddCardLoadingContent(null);
              // const notificationMessage = getErrorNotificationMessage(
              //   "Error Please try again"
              // );
              // dispatch(createNotification(notificationMessage));
            });
        })
        .catch((error) => {
          console.log("Eroor", error);
          setAddCardButtonDisable(false);
          setAddCardLoadingContent(null);
          const notificationMessage = getErrorNotificationMessage(
            "Please check your card details and try again.."
          );
          dispatch(createNotification(notificationMessage));
        });
    } else {
      setAddCardButtonDisable(false);
      setAddCardLoadingContent(null);
      const notificationMessage = getErrorNotificationMessage(
        "Stripe is not configured"
      );
      dispatch(createNotification(notificationMessage));
    }
  };

  return (
    <Form className="cards-form" onSubmit={addCard}>
      <CardElement />
      <Button
        className="submit-btn"
        disabled={addCardButtonDisable}
        type="submit"
      >
        {addCardLoadingContent != null ? addCardLoadingContent : "Add"}
      </Button>
    </Form>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(AddCardModel);
