import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  addCommentsFailure,
  addCommentsSuccess,
  dislikeVideoFailure,
  dislikeVideoSuccess,
  fetchSingleVideoFailure,
  fetchSingleVideoSuccess,
  fetchVideoCommentsFailure,
  fetchVideoCommentsStart,
  fetchVideoCommentsSuccess,
  likeVideoFailure,
  likeVideoSuccess,
  uploadVideoFailure,
  uploadVideoSuccess,
  fetchVideoImagesSuccess,
  fetchVideoImagesFailure,
  setDefaultImageSuccess,
  setDefaultImageFailure,
  fetchVideoImagesStart,
} from "../actions/SingleVideoAction";
import {
  ADD_COMMENTS_START,
  DISLIKE_VIDEO_START,
  FETCH_SINGLE_VIDEO_START,
  FETCH_VIDEO_COMMENTS_START,
  LIKE_VIDEO_START,
  UPLOAD_VIDEO_START,
  SET_DEFAULT_IMAGE_START,
  FETCH_VIDEO_IMAGES_START,
} from "../actions/ActionConstant";

function* fetchSingleVideoAPI() {
  try {
    const inputData = yield select(
      (state) => state.singleVideo.video.inputData
    );
    const response = yield api.postMethod("video_tapes_user_view", inputData);
    if (response.data.success) {
      yield put(fetchSingleVideoSuccess(response.data.data));
      if (
        response.data.data.should_display_ppv === 1 &&
        inputData.pathname !==
          `/ppv-invoice/${response.data.data.video_tape_id}`
      ) {
        window.location.assign(
          `/ppv-invoice/${response.data.data.video_tape_id}`
        );
      }
    } else {
      yield put(fetchSingleVideoFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSingleVideoFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchVideoCommentsAPI() {
  try {
    const inputData = yield select(
      (state) => state.singleVideo.comments.inputData
    );
    const response = yield api.postMethod("video_tapes_comments", inputData);
    if (response.data.success) {
      yield put(fetchVideoCommentsSuccess(response.data.data));
    } else {
      yield put(fetchVideoCommentsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchVideoCommentsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* addCommentAPI() {
  try {
    const inputData = yield select(
      (state) => state.singleVideo.addComment.inputData
    );
    const response = yield api.postMethod(
      "video_tapes_comments_save",
      inputData
    );
    if (response.data.success) {
      yield put(addCommentsSuccess(response.data.data));
      yield put(
        fetchVideoCommentsStart({ video_tape_id: inputData.video_tape_id })
      );
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(addCommentsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(addCommentsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* likeVideoAPI() {
  try {
    const inputData = yield select(
      (state) => state.singleVideo.likeVideo.inputData
    );
    const response = yield api.postMethod("video_tapes_like",inputData);
    if (response.data.success) {
      yield put(likeVideoSuccess(response.data.data));
      yield put(dislikeVideoSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(likeVideoFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(likeVideoFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* dislikeVideoAPI() {
  try {
    const inputData = yield select(
      (state) => state.singleVideo.dislikeVideo.inputData
    );
    const likeResponse = yield api.postMethod("video_tapes_like",inputData);
    const response = yield api.postMethod("video_tapes_dislike", inputData);
    if (response.data.success) {
      yield put(dislikeVideoSuccess(response.data.data));
      yield put(likeVideoSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(dislikeVideoFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(dislikeVideoFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* uploadVideoAPI() {
  try {
    const inputData = yield select(
      (state) => state.singleVideo.uploadVideo.inputData
    );
    const response = yield api.postMethod("video_tapes_save", inputData);
    if (response.data.success) {
      yield put(uploadVideoSuccess(response.data.data));
      yield put(fetchVideoImagesStart({video_tape_id:response.data.data.video_tapes.id}));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      if(response.data.data.video_tapes.video_type != 1) {
        window.location.assign("/my-channel/" + response.data.data.video_tapes.channel_id);
      }
    } else {
      yield put(uploadVideoFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(uploadVideoFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* setDefaultImageAPI() {
  try {
    const inputData = yield select(
      (state) => state.singleVideo.setdefault.inputData
    );
    const response = yield api.postMethod("video_tapes_save_default_img", inputData);
    if (response.data.success) {
      yield put(uploadVideoSuccess(response.data.data));
      yield put(fetchVideoImagesStart({video_tape_id:response.data.data.video_tape_id}));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(uploadVideoFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(uploadVideoFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchVideoImagesAPI() {
  try {
    const inputData = yield select(
      (state) => state.singleVideo.videoImages.inputData
    );
    const response = yield api.postMethod("video_tapes_images", inputData);
    if (response.data.success) {
      yield put(fetchVideoImagesSuccess(response.data.data));
    } else {
      yield put(fetchVideoImagesFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchVideoImagesFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_SINGLE_VIDEO_START, fetchSingleVideoAPI)]);
  yield all([
    yield takeLatest(FETCH_VIDEO_COMMENTS_START, fetchVideoCommentsAPI),
  ]);
  yield all([yield takeLatest(ADD_COMMENTS_START, addCommentAPI)]);
  yield all([yield takeLatest(DISLIKE_VIDEO_START, dislikeVideoAPI)]);
  yield all([yield takeLatest(LIKE_VIDEO_START, likeVideoAPI)]);
  yield all([yield takeLatest(UPLOAD_VIDEO_START, uploadVideoAPI)]);
  yield all([yield takeLatest(SET_DEFAULT_IMAGE_START, setDefaultImageAPI)]);
  yield all([yield takeLatest(FETCH_VIDEO_IMAGES_START, fetchVideoImagesAPI)]);
}
