import {
  FETCH_LS_OWNER_START,
  FETCH_LS_OWNER_SUCCESS,
  FETCH_LS_OWNER_FAILURE,
  FETCH_SINGLE_LS_OWNER_START,
  FETCH_SINGLE_LS_OWNER_SUCCESS,
  FETCH_SINGLE_LS_OWNER_FAILURE,
  END_LS_OWNER_START,
  END_LS_OWNER_SUCCESS,
  END_LS_OWNER_FAILURE,
  ERASE_OLD_LS_START,
  ERASE_OLD_LS_SUCCESS,
  ERASE_OLD_LS_FAILURE,
  CHECK_LS_STATUS_START,
  CHECK_LS_STATUS_SUCCESS,
  CHECK_LS_STATUS_FAILURE,
  SAVE_LS_OWNER_START,
  SAVE_LS_OWNER_SUCCESS,
  SAVE_LS_OWNER_FAILURE,
  FETCH_LS_USER_START,
  FETCH_LS_USER_SUCCESS,
  FETCH_LS_USER_FAILURE,
  FETCH_SINGLE_LS_USER_START,
  FETCH_SINGLE_LS_USER_SUCCESS,
  FETCH_SINGLE_LS_USER_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  liveStreaming: {
    data: {},
    loading: true,
    error: false,
  },
  singleLSOwner: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  endLSOwner: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  eraseLSOwner: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  lsStatus: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  saveLSOwner: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  liveVideos: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  liveVideo: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
};

const LiveStreamingReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LS_OWNER_START:
      return {
        ...state,
        liveStreaming: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_LS_OWNER_SUCCESS:
      return {
        ...state,
        liveStreaming: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_LS_OWNER_FAILURE:
      return {
        ...state,
        liveStreaming: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case FETCH_SINGLE_LS_OWNER_START:
      return {
        ...state,
        singleLSOwner: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_SINGLE_LS_OWNER_SUCCESS:
      return {
        ...state,
        singleLSOwner: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_SINGLE_LS_OWNER_FAILURE:
      return {
        ...state,
        singleLSOwner: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
      };

    case END_LS_OWNER_START:
      return {
        ...state,
        endLSOwner: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case END_LS_OWNER_SUCCESS:
      return {
        ...state,
        endLSOwner: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case END_LS_OWNER_FAILURE:
      return {
        ...state,
        endLSOwner: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case ERASE_OLD_LS_START:
      return {
        ...state,
        eraseLSOwner: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case ERASE_OLD_LS_SUCCESS:
      return {
        ...state,
        eraseLSOwner: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case ERASE_OLD_LS_FAILURE:
      return {
        ...state,
        eraseLSOwner: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case CHECK_LS_STATUS_START:
      return {
        ...state,
        lsStatus: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case CHECK_LS_STATUS_SUCCESS:
      return {
        ...state,
        lsStatus: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case CHECK_LS_STATUS_FAILURE:
      return {
        ...state,
        lsStatus: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
      };

    case SAVE_LS_OWNER_START:
      return {
        ...state,
        saveLSOwner: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case SAVE_LS_OWNER_SUCCESS:
      return {
        ...state,
        saveLSOwner: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case SAVE_LS_OWNER_FAILURE:
      return {
        ...state,
        saveLSOwner: {
          data: {},
          loading: false,
          error: action.error,
          inputData: state.saveLSOwner.inputData,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case FETCH_LS_USER_START:
      return {
        ...state,
        liveVideos: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_LS_USER_SUCCESS:
      return {
        ...state,
        liveVideos: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_LS_USER_FAILURE:
      return {
        ...state,
        liveVideos: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case FETCH_SINGLE_LS_USER_START:
      return {
        ...state,
        liveVideo: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_SINGLE_LS_USER_SUCCESS:
      return {
        ...state,
        liveVideo: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_SINGLE_LS_USER_FAILURE:
      return {
        ...state,
        liveVideo: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
      };

    default:
      return state;
  }
};

export default LiveStreamingReducer;
