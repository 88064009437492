import {
  FETCH_WISHLIST_START,
  FETCH_WISHLIST_SUCCESS,
  FETCH_WISHLIST_FAILURE,
  WISHLIST_OPERATION_START,
  WISHLIST_OPERATION_SUCCESS,
  WISHLIST_OPERATION_FAILURE,
} from "./ActionConstant";

// fetch referral action.

export function fetchWishlistStart(data) {
  return {
    type: FETCH_WISHLIST_START,
    data,
  };
}

export function fetchWishlistSuccess(data) {
  return {
    type: FETCH_WISHLIST_SUCCESS,
    data,
  };
}

export function fetchWishlistFailure(error) {
  return {
    type: FETCH_WISHLIST_FAILURE,
    error,
  };
}

// Wishlist operation action.

export function wishlistOperationStart(data) {
  return {
    type: WISHLIST_OPERATION_START,
    data,
  };
}

export function wishlistOperationSuccess(data) {
  return {
    type: WISHLIST_OPERATION_SUCCESS,
    data,
  };
}

export function wishlistOperationFailure(error) {
  return {
    type: WISHLIST_OPERATION_FAILURE,
    error,
  };
}
