import {
  FETCH_VIEWER_PLAYLIST_START,
  FETCH_VIEWER_PLAYLIST_SUCCESS,
  FETCH_VIEWER_PLAYLIST_FAILURE,
  CREATE_VIEWER_PLAYLIST_START,
  CREATE_VIEWER_PLAYLIST_SUCCESS,
  CREATE_VIEWER_PLAYLIST_FAILURE,
  ADD_VIDEO_VIEWER_PLAYLIST_START,
  ADD_VIDEO_VIEWER_PLAYLIST_SUCCESS,
  ADD_VIDEO_VIEWER_PLAYLIST_FAILURE,
  REMOVE_VIDEO_VIEWER_PLAYLIST_START,
  REMOVE_VIDEO_VIEWER_PLAYLIST_SUCCESS,
  REMOVE_VIDEO_VIEWER_PLAYLIST_FAILURE,
  FETCH_SINGLE_VIEWER_PLAYLIST_START,
  FETCH_SINGLE_VIEWER_PLAYLIST_SUCCESS,
  FETCH_SINGLE_VIEWER_PLAYLIST_FAILURE,
  DELETE_VIEWER_PLAYLIST_START,
  DELETE_VIEWER_PLAYLIST_SUCCESS,
  DELETE_VIEWER_PLAYLIST_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  viewerPlaylist: {
    data: {},
    loading: true,
    error: false,
  },
  createVP: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  addVideoVP: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  removeVideoVP: {
    data: {},
    inputData: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  singleVP: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  deleteVP: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
};

const ViewerPlayListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VIEWER_PLAYLIST_START:
      return {
        ...state,
        viewerPlaylist: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_VIEWER_PLAYLIST_SUCCESS:
      return {
        ...state,
        viewerPlaylist: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_VIEWER_PLAYLIST_FAILURE:
      return {
        ...state,
        viewerPlaylist: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case CREATE_VIEWER_PLAYLIST_START:
      return {
        ...state,
        createVP: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case CREATE_VIEWER_PLAYLIST_SUCCESS:
      return {
        ...state,
        createVP: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case CREATE_VIEWER_PLAYLIST_FAILURE:
      return {
        ...state,
        createVP: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case ADD_VIDEO_VIEWER_PLAYLIST_START:
      return {
        ...state,
        addVideoVP: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case ADD_VIDEO_VIEWER_PLAYLIST_SUCCESS:
      return {
        ...state,
        addVideoVP: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case ADD_VIDEO_VIEWER_PLAYLIST_FAILURE:
      return {
        ...state,
        addVideoVP: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case REMOVE_VIDEO_VIEWER_PLAYLIST_START:
      return {
        ...state,
        removeVideoVP: {
          inputData: action.data,
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case REMOVE_VIDEO_VIEWER_PLAYLIST_SUCCESS:
      return {
        ...state,
        removeVideoVP: {
          inputData: {},
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case REMOVE_VIDEO_VIEWER_PLAYLIST_FAILURE:
      return {
        ...state,
        removeVideoVP: {
          inputData: {},
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case FETCH_SINGLE_VIEWER_PLAYLIST_START:
      return {
        ...state,
        singleVP: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_SINGLE_VIEWER_PLAYLIST_SUCCESS:
      return {
        ...state,
        singleVP: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_SINGLE_VIEWER_PLAYLIST_FAILURE:
      return {
        ...state,
        singleVP: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
        },
      };

    case DELETE_VIEWER_PLAYLIST_START:
      return {
        ...state,
        deleteVP: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case DELETE_VIEWER_PLAYLIST_SUCCESS:
      return {
        ...state,
        deleteVP: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case DELETE_VIEWER_PLAYLIST_FAILURE:
      return {
        ...state,
        deleteVP: {
          data: {},
          loading: false,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    default:
      return state;
  }
};

export default ViewerPlayListReducer;
