import React from "react"
import ContentLoader from "react-content-loader"

const HomeLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1350}
    height={1330}
    viewBox="0 0 1200 1350"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="96" rx="0" ry="0" width="154" height="10" /> 
    <rect x="1175" y="95" rx="0" ry="0" width="23" height="8" /> 
    <circle cx="26" cy="150" r="26" /> 
    <circle cx="200" cy="150" r="26" /> 
    <circle cx="400" cy="150" r="26" /> 
    <circle cx="600" cy="150" r="26" /> 
    <circle cx="800" cy="150" r="26" /> 
    <circle cx="1000" cy="150" r="26" /> 
    <circle cx="1174" cy="150" r="26" /> 
    <rect x="0" y="194" rx="0" ry="0" width="96" height="10" /> 
    <rect x="119" y="194" rx="0" ry="0" width="96" height="10" /> 
    <rect x="229" y="194" rx="0" ry="0" width="96" height="10" /> 
    <rect x="0" y="234" rx="10" ry="10" width="200" height="100" /> 
    <rect x="1000" y="234" rx="10" ry="10" width="200" height="100" /> 
    <rect x="320" y="234" rx="10" ry="10" width="200" height="100" /> 
    <rect x="650" y="234" rx="10" ry="10" width="200" height="100" /> 
    <rect x="0" y="363" rx="10" ry="10" width="200" height="100" /> 
    <rect x="1000" y="363" rx="10" ry="10" width="200" height="100" /> 
    <rect x="320" y="363" rx="10" ry="10" width="200" height="100" /> 
    <rect x="650" y="363" rx="10" ry="10" width="200" height="100" /> 
    <rect x="1170" y="194" rx="0" ry="0" width="34" height="10" /> 
    <rect x="0" y="491" rx="0" ry="0" width="96" height="10" /> 
    <rect x="123" y="491" rx="0" ry="0" width="96" height="10" /> 
    <rect x="233" y="491" rx="0" ry="0" width="96" height="10" /> 
    <rect x="0" y="531" rx="10" ry="10" width="200" height="100" /> 
    <rect x="1000" y="531" rx="10" ry="10" width="200" height="100" /> 
    <rect x="320" y="531" rx="10" ry="10" width="200" height="100" /> 
    <rect x="650" y="531" rx="10" ry="10" width="200" height="100" /> 
    <rect x="0" y="660" rx="10" ry="10" width="200" height="100" /> 
    <rect x="1000" y="660" rx="10" ry="10" width="200" height="100" /> 
    <rect x="320" y="660" rx="10" ry="10" width="200" height="100" /> 
    <rect x="650" y="660" rx="10" ry="10" width="200" height="100" /> 
    <rect x="1170" y="491" rx="0" ry="0" width="34" height="10" /> 
    <rect x="0" y="791" rx="0" ry="0" width="96" height="10" /> 
    <rect x="126" y="791" rx="0" ry="0" width="96" height="10" /> 
    <rect x="236" y="791" rx="0" ry="0" width="96" height="10" /> 
    <rect x="0" y="831" rx="10" ry="10" width="200" height="100" /> 
    <rect x="1000" y="831" rx="10" ry="10" width="200" height="100" /> 
    <rect x="320" y="831" rx="10" ry="10" width="200" height="100" /> 
    <rect x="650" y="831" rx="10" ry="10" width="200" height="100" /> 
    <rect x="1170" y="791" rx="0" ry="0" width="34" height="10" /> 
    <rect x="0" y="955" rx="0" ry="0" width="96" height="10" /> 
    <rect x="0" y="995" rx="10" ry="10" width="200" height="150" /> 
    <rect x="1000" y="995" rx="10" ry="10" width="200" height="150" /> 
    <rect x="320" y="995" rx="10" ry="10" width="200" height="150" /> 
    <rect x="650" y="995" rx="10" ry="10" width="200" height="150" /> 
    <rect x="1170" y="955" rx="0" ry="0" width="34" height="10" /> 
    <circle cx="300" cy="1200" r="29" /> 
    <circle cx="420" cy="1200" r="29" /> 
    <circle cx="560" cy="1200" r="29" /> 
    <circle cx="710" cy="1200" r="29" /> 
    <circle cx="850" cy="1200" r="29" /> 
    <rect x="0" y="8" rx="5" ry="5" width="1200" height="56" /> 
    <rect x="0" y="1274" rx="5" ry="5" width="1200" height="92" />
  </ContentLoader>
)

export default HomeLoader;